import React, { useContext, useEffect, useState } from "react";

import { Form, Formik } from "formik";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { MyButton } from "components";
import { PageTemplate } from "templates";
import {
  IconTypeEnum,
  PackageCreateOrderPayloadInterface,
  PackagePurchaseType,
  PackageTimeType,
  ParamsStringEnum,
  QueryStringEnum,
} from "interfaces";

import {
  BillingDetailsContainer,
  PaymentMethodContainer,
  SelectSubscriptionContainer,
} from "../container";
import { CheckoutFormikValues } from "../helpers";

import { SubscriptionContext } from "../context";
import { getPackageHandlerReturnInterface } from "../interfaces";
import { PageLinks } from "routes";
import getIconHelpers from "helpers/utils/getIconHelpers";
import { AppContext, BillingAddressContext } from "context";
import { BillingAddressCard } from "../components";
import { BillingAddressModal } from "../modal";
function CheckoutPage() {
  const params = useParams<ParamsStringEnum>();
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const {
    state: { appSettings },
  } = useContext(AppContext);
  const subscription = query.get(QueryStringEnum.ID);
  const timeUnit: any = query.get(QueryStringEnum.TYPE);
  const purchaseType: any = params.type || PackagePurchaseType.SUBSCRIPTION;
  const [isBillingAddressModal, setBillingAddressModal] = useState(false);
  const {
    subscriptionPackages,
    mySubscriptionStats,
    validateOrderDetails,
    activeSubscription,
    validationError,

    contextHandlers: {
      getMySubscriptionStatsHandler,
      getStripePaymentMethodHandler,
      getSubscriptionPackagesHandler,
      checkoutOrderHandler,
      getActiveSubscriptionHandler,
    },
  } = useContext(SubscriptionContext);
  const { getAllDataHandlers, allData: billingAddresses } = useContext(
    BillingAddressContext
  );
  useEffect(() => {
    getAllDataHandlers();
    getMySubscriptionStatsHandler();
    getStripePaymentMethodHandler();
    getSubscriptionPackagesHandler();
    getActiveSubscriptionHandler();
  }, [purchaseType]);

  const getPackageStatusHandler = (
    packageID: string,
    values: PackageCreateOrderPayloadInterface
  ): getPackageHandlerReturnInterface => {
    let canHaveUnlimitedProperty: boolean = false;
    let maxExtraProperty: number = 0;
    let minExtraProperty: number = 0;
    let isMonthly = values?.timeUnit === PackageTimeType.MONTHLY;
    let packageDetails = subscriptionPackages?.find(
      (e) => e?._id === packageID
    );
    const isPurchaseTypeProperty =
      values?.purchaseType === PackagePurchaseType.PROPERTY;

    if (!packageDetails) {
      return {
        canSelect: false,
        maxExtraProperty,
        minExtraProperty,
        canHaveUnlimitedProperty,
      };
    }

    // for specific timeUnit
    let isPackageEnable;
    if (isMonthly) {
      isPackageEnable = packageDetails?.monthlyCost?.enable;
    } else {
      isPackageEnable = packageDetails?.annualCost?.enable;
    }
    if (packageDetails?.isFreeForever) {
      isPackageEnable = true;
    }
    if (packageDetails?.purchaseDisable) {
      isPackageEnable = false;
    }

    const minRequiredProperty = mySubscriptionStats?.propertyCount || 0;

    // for upgrade property
    if (isPurchaseTypeProperty) {
      minExtraProperty = 1;
    } else {
      minExtraProperty =
        minRequiredProperty -
        (packageDetails?.propertyIncludedInBasePrice || 0);

      if (packageDetails?.propertyIncludedInBasePrice === 0) {
        minExtraProperty = Math.max(1, minExtraProperty);
      }
      if (minExtraProperty < 0) {
        minExtraProperty = 0;
      }
    }
    if (packageDetails?.isMaxPropertyUnlimited) {
      return {
        canSelect: isPackageEnable,
        maxExtraProperty: 0,
        minExtraProperty,
        canHaveUnlimitedProperty: true,
      };
    }
    if (packageDetails?.maxProperty >= minRequiredProperty) {
      maxExtraProperty =
        packageDetails?.maxProperty -
        packageDetails?.propertyIncludedInBasePrice;
      if (isPurchaseTypeProperty) {
        maxExtraProperty =
          packageDetails?.maxProperty -
          mySubscriptionStats?.activePackage?.purchasedProperty;
        if (maxExtraProperty === 0) {
          isPackageEnable = false;
        }
      }
      return {
        canSelect: isPackageEnable,
        maxExtraProperty,
        minExtraProperty,
        canHaveUnlimitedProperty: false,
      };
    }
    return {
      canSelect: false,
      maxExtraProperty: 0,
      minExtraProperty: 0,
      canHaveUnlimitedProperty: false,
    };
  };
  const AddIcon = getIconHelpers(IconTypeEnum.ADD);
  return (
    <PageTemplate scrollAll title={"Payment Details"}>
      <Formik
        enableReinitialize
        onSubmit={checkoutOrderHandler}
        initialValues={CheckoutFormikValues.initialValues({
          purchaseType: purchaseType,
          subscription,
          timeUnit,
          billingAddress: activeSubscription?.lastUsedBilling,
        })}
        validationSchema={CheckoutFormikValues.validationSchema}
      >
        {({ isValid, values }) => {
          const { canSelect } = getPackageStatusHandler(
            values?.subscription,
            values
          );
          const isPurchaseTypeProperty =
            values?.purchaseType === PackagePurchaseType.PROPERTY;
          const shouldUpgradePlan = !canSelect && isPurchaseTypeProperty;
          const billingAddressDetails = billingAddresses?.find(
            (e) => e?._id === values?.billingAddress
          );
          return (
            <Form>
              {!billingAddressDetails && (
                <div
                  onClick={() => {
                    setBillingAddressModal(true);
                  }}
                  className={
                    "flex items-center gap-2 p-5 mx-5 text-[14px] rounded-md bg-gray-100 cursor-pointer"
                  }
                >
                  <AddIcon />
                  <b>Add New Billing Address</b>
                </div>
              )}

              <div
                className={
                  "grid grid-cols-8 md:grid-cols-1 sm:grid-cols-1 gap-10 pt-5 px-5"
                }
              >
                <div className={"col-span-5 md:col-span-1 sm:col-span-1"}>
                  <SelectSubscriptionContainer
                    getPackageStatusHandler={getPackageStatusHandler}
                  />
                </div>
                <div
                  className={
                    "col-span-3 md:col-span-1 sm:col-span-1 flex flex-col flex-1 gap-5"
                  }
                >
                  <PaymentMethodContainer />
                  {billingAddressDetails && !shouldUpgradePlan && (
                    <BillingAddressCard
                      details={billingAddressDetails}
                      onChangeHandler={() => setBillingAddressModal(true)}
                    />
                  )}
                  {!shouldUpgradePlan && (
                    <BillingDetailsContainer
                      getPackageStatusHandler={getPackageStatusHandler}
                    />
                  )}
                  {isBillingAddressModal && (
                    <BillingAddressModal
                      closeHandler={() => setBillingAddressModal(false)}
                    />
                  )}
                  {validationError ? (
                    <>
                      <p
                        className={
                          "bg-red-50 border-2 border-red-300 p-2 rounded-md"
                        }
                      >
                        {validationError}
                      </p>
                    </>
                  ) : shouldUpgradePlan ? (
                    <>
                      <p
                        className={
                          "bg-yellow-50 border-2 border-yellow-300 p-2 rounded-md"
                        }
                      >
                        You've reached your property limit. To continue, upgrade
                        your plan.
                      </p>
                      <div className={"mt-5"}>
                        <MyButton
                          colorType={"blue"}
                          type={"button"}
                          className={"w-full py-2"}
                          onClick={() =>
                            navigate(
                              PageLinks.subscription.checkout(
                                PackagePurchaseType.SUBSCRIPTION
                              )
                            )
                          }
                          name={"Upgrade Plan"}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={"mt-5"}>
                      <MyButton
                        type={"submit"}
                        disabled={!isValid}
                        className={"w-full py-2"}
                        name={
                          validateOrderDetails?.payableAmount > 0
                            ? "Process Payment"
                            : "Subscribe"
                        }
                      />
                    </div>
                  )}
                  {!validationError && !shouldUpgradePlan && (
                    <div className={"text-[14px]"}>
                      By proceeding you agree to the{" "}
                      {appSettings?.termsAndConditionURL ? (
                        <a
                          href={appSettings?.termsAndConditionURL}
                          target={"_blank"}
                          className={"text-primary font-bold underline"}
                        >
                          Terms & Conditions
                        </a>
                      ) : (
                        <b className={"text-primary font-bold"}>
                          Terms & Conditions
                        </b>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Outlet />
    </PageTemplate>
  );
}

export default CheckoutPage;
