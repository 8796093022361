import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { QueryStringEnum } from "../interfaces";
function UsePageQueryHooks() {
  const [query, setQuery] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const unitID = query.get(QueryStringEnum?.UNIT_ID);
  const propertyID = query.get(QueryStringEnum?.PROPERTY_ID);
  const status = query.get(QueryStringEnum?.STATUS);
  const type = query.get(QueryStringEnum?.TYPE);
  const itemID = query.get(QueryStringEnum?.ITEM_ID);
  const returnURL = query.get(QueryStringEnum?.RETURN_URL);
  const activeTab = query.get(QueryStringEnum?.ACTIVE_TAB);
  const date = query.get(QueryStringEnum?.DATE);
  let searchKeyword = query.get(QueryStringEnum?.Search);
  const startFrom = query.get(QueryStringEnum?.START_DATE);
  const endTo = query.get(QueryStringEnum?.END_DATE);
  if (searchKeyword) {
    searchKeyword = decodeURIComponent(searchKeyword);
  }
  const navigateWithReturnUrlHandler = (
    pathName: string,
    queryStrings?: string,
    skipActiveTab?: boolean
  ) => {
    //this function will have return url
    navigate({
      pathname: pathName,
      search: `${QueryStringEnum.RETURN_URL}=${location.pathname}${
        activeTab && !skipActiveTab
          ? `&${QueryStringEnum.ACTIVE_TAB}=${activeTab}`
          : ""
      }${queryStrings ? `&${queryStrings}` : ""}`,
    });
  };
  const navigateBackHandler = (pathName: string, queryStrings?: string) => {
    navigate({
      pathname: pathName,
      search: `${
        activeTab ? `${QueryStringEnum.ACTIVE_TAB}=${activeTab}` : ""
      }${queryStrings ? `&${queryStrings}` : ""}`,
    });
  };
  const setDateQueryHandler = (date) => {
    query?.set(QueryStringEnum.DATE, date);
    setQuery(query);
  };
  return {
    unitID,
    propertyID,
    returnURL,
    status,
    date,
    itemID,
    type,
    searchKeyword,
    startFrom,
    endTo,
    setDateQueryHandler,
    navigateBackHandler,
    navigateWithReturnUrlHandler,
  };
}

export default UsePageQueryHooks;
