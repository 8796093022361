import React, { useContext, useEffect } from "react";
import {
  MyButton,
  MyRadioInput,
  MySelectField,
  MyTextAreaInput,
  MyTextField,
  MyUploadDocument,
} from "components";
import {
  decodeDynamicFieldHandler,
  decodePropertyOptionsHandler,
  decodeUnitOptionsHandler,
  decodeUserOptionsHandler,
  getCurrency,
} from "helpers";
import {
  FieldTypeEnum,
  IconTypeEnum,
  TaxTypeEnum,
  UserRoleEnum,
} from "interfaces";
import { FormWrapper } from "assets";
import { useFormikContext } from "formik";
import { OptionsContext } from "context";
import { SignatureForm } from "content";
import { usePageQueryHooks } from "../../../useHooks";
import { PageLinks } from "../../../routes";

function BookingForm() {
  const { values, setFieldValue, errors } = useFormikContext<any>();
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const {
    propertyOptions,
    unitOptions,
    userOptions,
    fieldTypeOptions,
    getUnitOptionsHandler,
  } = useContext(OptionsContext);
  useEffect(() => {
    if (
      fieldTypeOptions?.[FieldTypeEnum.CHARGE_TYPE]?.length > 0 &&
      !values?.chargeType
    ) {
      setFieldValue(
        "chargeType",
        fieldTypeOptions?.[FieldTypeEnum.CHARGE_TYPE][0]?._id,
        false
      );
    }
  }, [fieldTypeOptions, values]);

  return (
    <div className={"flex flex-col gap-10"}>
      <div
        className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start`}
      >
        <MySelectField
          name={"property"}
          label={"Property"}
          isRequired
          option={{
            selectOptions: decodePropertyOptionsHandler(propertyOptions),
            onChangeCallback(currentValue?: any) {
              setFieldValue("unit", "");
            },
          }}
        />
        <MySelectField
          isClearable
          disabled={!values?.property}
          name={"unit"}
          label={"Unit"}
          fetchOptionsHandler={() => getUnitOptionsHandler(values?.property)}
          isRequired
          option={{
            selectOptions: decodeUnitOptionsHandler(unitOptions),
          }}
        />
      </div>
      <div className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1  gap-5`}>
        <MySelectField
          name={"tenant"}
          label={"Tenant"}
          isRequired
          option={{
            selectOptions: decodeUserOptionsHandler(userOptions),
          }}
        />
        <div className={"mt-8 sm:mt-0"}>
          <MyButton
            isOutline
            name={"Add New"}
            onClick={() =>
              navigateWithReturnUrlHandler(
                PageLinks.spaceUser.new,
                `type=${UserRoleEnum.TENANT}`
              )
            }
            iconType={IconTypeEnum.ADD}
          />
        </div>
      </div>
      <div className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1  gap-5`}>
        <MyTextField
          isRequired
          label={"Arrival Date"}
          name={"arrivalDate"}
          type={"datetime-local"}
        />
        <MyTextField
          isRequired
          label={"Departure Date"}
          name={"departureDate"}
          type={"datetime-local"}
        />
      </div>
      <div className={`grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1  gap-5`}>
        <div className={"col-span-2"}>
          <MyTextAreaInput label={"Description"} name={"description"} />
        </div>
      </div>
      {/*Rent*/}
      <FormWrapper
        header={{
          title: {
            name: "Rent",
          },
        }}
      >
        <div className={"flex flex-col gap-5"}>
          <div
            className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1  gap-5`}
          >
            <MyTextField
              name={"rentCost"}
              placeholder={"Eg.5000"}
              label={`Rent Amount (${getCurrency()}) (Per Day)`}
              isRequired
            />
          </div>
          <div
            className={"flex items-center gap-5 md:grid-cols-2 sm:grid-cols-1 "}
          >
            <MyRadioInput
              radioButtonType={"radio"}
              defaultValue={TaxTypeEnum.EXEMPTED}
              label={"Exempted"}
              name={"taxType"}
            />
            <MyRadioInput
              radioButtonType={"radio"}
              defaultValue={TaxTypeEnum.EXCLUSIVE}
              label={"Exclusive"}
              name={"taxType"}
            />
            <MyRadioInput
              radioButtonType={"radio"}
              defaultValue={TaxTypeEnum.INCLUSIVE}
              label={"Inclusive"}
              name={"taxType"}
            />
          </div>
          <div
            className={
              "grid items-start grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-5 "
            }
          >
            {values?.taxType !== TaxTypeEnum.EXEMPTED && (
              <MySelectField
                label={"Tax Rate"}
                isRequired
                name={"taxRate"}
                option={{
                  selectOptions: decodeDynamicFieldHandler(
                    fieldTypeOptions?.[FieldTypeEnum.TAX]
                  ),
                }}
              />
            )}
          </div>
          <div
            className={
              "grid items-start grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-5 "
            }
          >
            <div className={"col-span-2"}>
              <MyTextField
                name={"remarks"}
                placeholder={"Eg.remarks"}
                label={`Remarks`}
              />
            </div>
          </div>
        </div>
      </FormWrapper>

      {/*  Security Deposit */}
      <FormWrapper
        header={{
          title: {
            name: "Security Deposit",
          },
        }}
      >
        <div
          className={
            "grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1 items-start gap-5"
          }
        >
          <MySelectField
            label={"Charges Type"}
            name={"chargeType"}
            disabled
            isCreatable={{
              type: FieldTypeEnum.CHARGE_TYPE,
            }}
            isRequired
            option={{
              selectOptions: decodeDynamicFieldHandler(
                fieldTypeOptions[FieldTypeEnum.CHARGE_TYPE]
              ),
            }}
          />
          <MyTextField
            isNumberOnly
            name={"securityDepositedAmount"}
            placeholder={"Eg.5000"}
            label={`Amount (${getCurrency()})`}
            isRequired
          />
          <MyTextField
            name={"securityDepositedOn"}
            label={`Deposit Date`}
            type={"date"}
            isRequired
          />
          <div className={"col-span-2"}>
            <MyTextAreaInput
              name={"securityDepositedNote"}
              label={`Deposit Note`}
            />
          </div>
        </div>
      </FormWrapper>
      {/*  Addition Document*/}
      <FormWrapper
        header={{
          title: {
            name: "Additional Documents",
          },
        }}
      >
        <div
          className={"grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-5"}
        >
          <div className={"col-span-2"}>
            <MyUploadDocument
              name={"agreementDocuments"}
              fileContainerStyle={
                "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1"
              }
            />
          </div>
        </div>
      </FormWrapper>
      {/*  signature */}
      <FormWrapper
        header={{
          title: {
            name: "Signature",
          },
        }}
      >
        <>
          <div
            className={"grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <MyTextField
              name={"tenantSignature.signedDate"}
              type={"date"}
              label={"Date"}
              isRequired
            />
          </div>
          <div
            className={"grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <SignatureForm
              errorMessage={!values?.tenantSignature?.signature && "required"}
              populateFieldValue={values?.tenantSignature?.signature}
              populateFieldName={"tenantSignature.signature"}
              customerID={values?.tenant}
              // shouldSetParticipant={"tenantSignature.participant"}
            />
          </div>
        </>
      </FormWrapper>
    </div>
  );
}

export default BookingForm;
