import React from "react";
import { BankStatementInterface, TransactionTypeEnum } from "interfaces";
import { NameValueText } from "assets";
import { useDateHook } from "useHooks";
import { getCurrency } from "helpers";
import "./styles.css";
import Popup from "reactjs-popup";

interface PropsInterface {
  statement: BankStatementInterface;
  type: "bank" | "invoice";
  setBankTransfer?(state: boolean);
  isBankTransfer?: boolean;
  setFindMatchComponent?(state: boolean);
  isLoading?: boolean;
}
function StatementDetails({
  statement,
  type,
  isBankTransfer,
  setBankTransfer,
  setFindMatchComponent,
}: PropsInterface) {
  const isBankStatement = type === "bank";
  const name = isBankStatement
    ? statement?.merchantName || "N/A"
    : statement?.invoiceMatched?.invoiceID;
  const date = isBankStatement
    ? statement?.transactionDate
    : statement?.invoiceMatched?.invoiceDate;

  const desc = isBankStatement
    ? statement?.remarks
    : statement?.invoiceMatched?.description;
  const { getDateHandler } = useDateHook();
  return (
    <div className={"flex items-start w-full "}>
      <table
        className={
          "table w-full  border-collapse statementTable  border border-slate-400 "
        }
      >
        <thead>
          <tr>
            {!isBankStatement && (
              <td
                onClick={() => setBankTransfer(false)}
                className={`${
                  isBankTransfer ? "text-gray-200" : "text-primary"
                } cursor-pointer`}
              >
                Match
              </td>
            )}
            <th className={"w-[60%] text-left px-2"}>Details</th>
            <th className={"w-[20%]"}>Spent</th>
            <th className={"w-[20%]"}>Received</th>
          </tr>
        </thead>
        <tbody>
          <tr className={""}>
            {!isBankStatement && (
              <td
                onClick={() => setBankTransfer(true)}
                className={`${
                  !isBankTransfer ? "text-gray-200" : "text-primary"
                } cursor-pointer`}
              >
                Bank Transfer
              </td>
            )}
            <td>
              <div className={"flex flex-col gap-2 py-2 items-start h-full "}>
                {date && (
                  <NameValueText
                    valueStyle={"text-[12px]"}
                    nameStyle={"text-[12px]"}
                    name={"Date"}
                    alignDirection={"row"}
                    value={getDateHandler({ date })}
                  />
                )}
                {name && (
                  <NameValueText
                    valueStyle={"text-[12px]"}
                    nameStyle={"text-[12px]"}
                    alignDirection={"row"}
                    name={isBankStatement ? "Reference" : "Name"}
                    value={name}
                  />
                )}
                {desc && (
                  <NameValueText
                    valueStyle={"text-[12px]"}
                    nameStyle={"text-[12px]"}
                    alignDirection={"row"}
                    name={"Description"}
                    value={desc}
                  />
                )}
                <div className={"flex items-center gap-5"}>
                  {(isBankStatement || statement?.invoiceMatched) && (
                    <ViewMoreDetails statement={statement} type={type} />
                  )}
                  {!isBankStatement && (
                    <span
                      onClick={() => setFindMatchComponent(true)}
                      className={"text-tBlue underline cursor-pointer"}
                    >
                      Find Match
                    </span>
                  )}
                </div>
              </div>
            </td>
            {isBankStatement ? (
              <>
                <td className={"min-w-[20px] text-right"}>
                  {statement?.transactionType === TransactionTypeEnum.CR && (
                    <>
                      {statement?.currency || getCurrency()}{""}
                      {statement?.balance}
                    </>
                  )}
                </td>
                <td className={"min-w-[20px] text-right"}>
                  {statement?.transactionType === TransactionTypeEnum.DR && (
                    <>
                      {statement?.currency || getCurrency()}{""}
                      {statement?.balance}
                    </>
                  )}
                </td>
              </>
            ) : (
              <>
                <td className={"min-w-[20px] text-right"}>
                  {statement?.invoiceMatched &&
                    statement?.transactionType === TransactionTypeEnum.CR && (
                      <>
                        {statement?.currency || getCurrency()}{""}
                        {statement?.invoiceMatched?.due}
                      </>
                    )}
                </td>
                <td className={"min-w-[20px] text-right"}>
                  {statement?.invoiceMatched &&
                    statement?.transactionType === TransactionTypeEnum.DR && (
                      <>
                        {statement?.currency || getCurrency()}{""}
                        {statement?.invoiceMatched?.due}
                      </>
                    )}
                </td>
              </>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

interface ViewMorePropsInterface {
  statement: BankStatementInterface;
  type: "bank" | "invoice";
}

function ViewMoreDetails({ statement, type }: ViewMorePropsInterface) {
  const { getDateHandler } = useDateHook();
  const isBankStatement = type === "bank";
  const name = isBankStatement
    ? statement?.merchantName || "Unknown Payee"
    : statement?.invoiceMatched?.invoiceID;
  const date = isBankStatement
    ? statement?.transactionDate
    : statement?.invoiceMatched?.invoiceDate;
  const desc = isBankStatement
    ? statement?.remarks
    : statement?.invoiceMatched?.description;
  const transactionType =
    statement?.transactionType === TransactionTypeEnum.DR ? "Debit" : "Credit";
  const transactionAmount = isBankStatement
    ? statement?.balance
    : statement?.invoiceMatched?.due;
  const reference = isBankStatement
    ? statement?.merchantName || "N/A"
    : statement?.invoiceMatched?.reference;
  const category = statement?.transactionCategory;

  const detailsObj = {
    "Transaction Date": date ? getDateHandler({ date }) : "-",
    ...(!isBankStatement && { Name: name }),
    Reference: reference,
    Description: desc,
    "Transaction Amount": `${
      statement?.currency || getCurrency()
    } ${transactionAmount}`,
    "Transaction Type": transactionType,
    "Transaction Category": category,
  };
  return (
    <Popup
      position="bottom right"
      closeOnDocumentClick
      contentStyle={{ width: "370px" }}
      trigger={
        <button
          type={"button"}
          className={`flex items-center gap-2 text-[12px] underline text-tBlue `}
        >
          <span>View More</span>
        </button>
      }
    >
      <div className={"flex flex-col gap-2 text-[12px] p-2"}>
        <b>Statement Details</b>
        <div className={"flex flex-col border-2"}>
          {Object.keys(detailsObj)?.map((e, key) => {
            return (
              <div
                key={key}
                className={"grid grid-cols-2 gap-5 border-b-2 py-2"}
              >
                <div className={"text-right text-gray-500"}>{e}</div>
                <span>{detailsObj?.[e] || "-"}</span>
              </div>
            );
          })}
        </div>
      </div>
    </Popup>
  );
}

export default StatementDetails;
