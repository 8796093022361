import React, { useContext, useState } from "react";
import { Form, Formik } from "formik";

import { MyButton } from "components";
import { BankReconciliationContext, OptionsContext } from "context";
import {
  BankStatementInterface,
  FieldTypeEnum,
  ReconciliationInvoiceEnum,
} from "interfaces";

import {
  StatementDetails,
  BankTransferDetails,
  FindMatchInvoiceComponent,
  AddInvoiceComponent,
} from "../components";
import { ReconcileMatchFormik } from "../helpers";
import { useConfirmHook, usePageQueryHooks } from "useHooks";
import { PageLinks } from "../../../routes";

interface propsInterface {
  statementDetails: BankStatementInterface;
}
function ReconciliationCardContainer({ statementDetails }: propsInterface) {
  const { confirm } = useConfirmHook();

  const {
    matchStatementHandler,
    setReconciliationStatementsHandler,
    reconciliationStatements,
    transferBankStatementHandler,
  } = useContext(BankReconciliationContext);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { fieldTypeOptions } = useContext(OptionsContext);
  const [isBankTransfer, setBankTransfer] = useState(false);
  const [isFindMatchComponent, setFindMatchComponent] = useState(false);
  const onSubmitHandler = async (values) => {
    let payload;
    const isConfirm = await confirm("Are you sure?");
    if (!isConfirm) {
      return;
    }
    const onSuccessHandler = (successPayload) => {
      //  remove the items from list
      let tempReconciliationStatements = [...reconciliationStatements];
      let index = tempReconciliationStatements?.findIndex(
        (e) => e?._id === values?.statement
      );
      if (index < 0) {
        return;
      }
      if (!isBankTransfer) {
        navigateWithReturnUrlHandler(
          PageLinks.reconciliation.details(successPayload?._id)
        );
      }
      tempReconciliationStatements?.splice(index, 1);
      setReconciliationStatementsHandler(tempReconciliationStatements);
    };
    // case 3: for account transfer
    if (isBankTransfer && values?.bank) {
      payload = {
        bank: values?.bank,
        remarks: values?.remarks,
        statement: values?.statement,
      };
      transferBankStatementHandler(payload, {
        onSuccess: onSuccessHandler,
      });
      return;
    }
    // case 1 : for findMatch
    else if (
      isFindMatchComponent &&
      (values?.matchInvoices?.length > 0 ||
        values?.newMatch?.invoiceData?.billItems?.length > 0)
    ) {
      const getTaxID = (value) => {
        let taxID = fieldTypeOptions[FieldTypeEnum.TAX]?.find(
          (f) => parseInt(f?.value) == value
        )?._id;
        return taxID;
      };
      payload = ReconcileMatchFormik.initialValues({
        ...values,
        bank: null,
        remarks: null,
        newMatch: {
          invoiceType: values?.newMatch?.invoiceType,
          invoiceData: {
            ...values?.newMatch?.invoiceData,
            billItems: values?.newMatch?.invoiceData?.billItems?.map((e) => {
              return {
                ...e,
                taxRate: getTaxID(e?.taxRate),
              };
            }),
          },
        },
      });
    }
    // case 2 : for invoiceMatched
    else if (
      statementDetails?.invoiceMatched?.due === statementDetails?.balance
    ) {
      payload = ReconcileMatchFormik.initialValues({
        statement: values?.statement,
        matchInvoices: [
          ReconcileMatchFormik.matchInvoiceValues({
            invoice: statementDetails?.invoiceMatched?._id,
            amount: statementDetails?.invoiceMatched?.due,
          }),
        ],
        bank: null,
        remarks: null,
        newMatch: {
          invoiceType: ReconciliationInvoiceEnum.NONE,
          invoiceData: null,
        },
      });
    }

    matchStatementHandler(payload, {
      onSuccess: onSuccessHandler,
    });
  };
  return (
    <Formik
      initialValues={ReconcileMatchFormik?.initialValues({
        statement: statementDetails?._id,
      })}
      validationSchema={ReconcileMatchFormik?.validationSchema}
      onSubmit={onSubmitHandler}
    >
      {({ values }) => {
        return (
          <Form noValidate>
            <div className={"grid grid-cols-10 gap-10"}>
              <div className={"col-span-4"}>
                <StatementDetails type={"bank"} statement={statementDetails} />
              </div>
              <div className={"col-span-1 flex items-center justify-center"}>
                <div>
                  {((!isFindMatchComponent &&
                    statementDetails?.invoiceMatched?.due ===
                      statementDetails?.balance) ||
                    (isBankTransfer && values?.bank)) && (
                    <MyButton
                      size={"md"}
                      name={isBankTransfer ? "Transfer" : "Match"}
                      isOutline
                      type={"submit"}
                    />
                  )}
                </div>
              </div>
              <div className={"col-span-5"}>
                {isBankTransfer ? (
                  <BankTransferDetails
                    isBankTransfer={isBankTransfer}
                    setBankTransfer={setBankTransfer}
                    statement={statementDetails}
                  />
                ) : isFindMatchComponent ? (
                  <FindMatchInvoiceComponent
                    setFindMatchInvoice={setFindMatchComponent}
                    statement={statementDetails}
                  />
                ) : (
                  <StatementDetails
                    type={"invoice"}
                    isBankTransfer={isBankTransfer}
                    setBankTransfer={setBankTransfer}
                    setFindMatchComponent={setFindMatchComponent}
                    statement={statementDetails}
                  />
                )}
              </div>
            </div>
            {values?.newMatch?.invoiceType !==
              ReconciliationInvoiceEnum.NONE && (
              <AddInvoiceComponent statementDetails={statementDetails} />
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default ReconciliationCardContainer;
