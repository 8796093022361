import React, { useContext, useEffect } from "react";
import { FileViewCard, MyButton } from "components";
import { NameValueText, StatusText } from "assets";

import { PageTemplate } from "templates";
import { useNavigate, useParams } from "react-router-dom";
import { MaintenanceContext } from "context";
import {
  IconTypeEnum,
  ParamsStringEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { useAuthorizationHook, useDateHook } from "useHooks";
import { PageLinks } from "routes";

function MaintenanceDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const navigate = useNavigate();
  const { getDateHandler, getStaffFullName } = useDateHook();
  const { details, isDetailsLoading, getDetailsHandler } =
    useContext(MaintenanceContext);
  useEffect(() => {
    getDetailsHandler(params?.id);
  }, [params?.id]);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_EDIT_REPAIR_AND_MAINTENANCE,
  ]);
  return (
    <PageTemplate
      scrollAll
      title={"Maintenance"}
      backNavigation={{
        title: "Maintenance Details",
        path: PageLinks.maintenance.list,
      }}
      breadCrumb={[
        {
          name: "Maintenance",
        },
      ]}
      titleRightChildren={
        canEdit &&
        details?._id && (
          <div>
            <MyButton
              isOutline
              iconType={IconTypeEnum.EDIT}
              name={"Edit Details"}
              onClick={() => {
                navigate(PageLinks.maintenance.edit(details?._id));
              }}
            />
          </div>
        )
      }
    >
      <div className={"flex flex-col gap-10"}>
        <div className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1 gap-5"}>
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"Property"}
            value={
              <div className={"flex gap-2 items-center"}>
                ({details?.property?.propertyID}){" "}
                {details?.property?.address?.address1}
              </div>
            }
          />
          {/*unit*/}
          <NameValueText
            alignDirection={"col"}
            name={"Unit"}
            isLoading={isDetailsLoading}
            value={
              <div className={"flex items-center gap-2"}>
                <div className={"flex gap-2 items-center"}>
                  ({details?.unit?.unitID}) {details?.unit?.name}
                </div>
              </div>
            }
          />

          <NameValueText
            alignDirection={"col"}
            isLoading={isDetailsLoading}
            name={"Assign To"}
            value={getStaffFullName(details?.supplier?.name) || "-"}
          />
        </div>{" "}
        <div
          className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1  gap-5"}
        >
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Item"}
            alignDirection={"col"}
            value={details?.inventoryItemID?.item?.label}
          />{" "}
          <NameValueText
            alignDirection={"col"}
            isLoading={isDetailsLoading}
            name={"Unit"}
            value={details?.inventoryItemID?.unit?.label}
          />
          <NameValueText
            alignDirection={"col"}
            isLoading={isDetailsLoading}
            name={"Quantity"}
            value={details?.inventoryItemID?.unitQuantity}
          />
          <NameValueText
            alignDirection={"col"}
            isLoading={isDetailsLoading}
            name={"Condition"}
            value={details?.inventoryItemID?.inventoryItemCondition?.label}
          />
          <NameValueText
            alignDirection={"col"}
            isLoading={isDetailsLoading}
            name={"Notes"}
            value={details?.inventoryItemID?.notes}
          />
        </div>
        {details?.inventoryItemID?.images?.length > 0 && (
          <div className={"flex flex-col gap-2"}>
            <NameValueText name={"Inventory Images"} value={""} />
            <div
              className={"grid grid-cols-5 gap-5 sm:grid-cols-2 md:grid-cols-3"}
            >
              {details?.inventoryItemID?.images?.map((e, key) => {
                return <FileViewCard canDownload filePath={e} key={key} />;
              })}
            </div>
          </div>
        )}
        <div
          className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1  gap-5"}
        >
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Priority"}
            alignDirection={"col"}
            value={
              <div>
                <StatusText
                  label={details?.repairPriority?.label}
                  status={details?.repairPriority?.value}
                />
              </div>
            }
          />
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Status"}
            alignDirection={"col"}
            value={
              <div>
                <StatusText
                  label={details?.repairStatus?.label}
                  status={details?.repairStatus?.value}
                />
              </div>
            }
          />
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Target Date"}
            alignDirection={"col"}
            value={getDateHandler({ date: details?.targetDate })}
          />
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Unit to repair"}
            alignDirection={"col"}
            value={details?.unitToRepair}
          />
        </div>
        <div
          className={"grid grid-cols-2 md:grid-cols-3 sm:grid-cols-1  gap-5"}
        >
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Description"}
            alignDirection={"col"}
            value={details?.description}
          />
        </div>
        <div className={"grid grid-cols-5 gap-5 sm:grid-cols-2 md:grid-cols-3"}>
          {details?.documents?.map((e, key) => (
            <FileViewCard key={key} filePath={e} canDownload />
          ))}
        </div>
      </div>
    </PageTemplate>
  );
}

export default MaintenanceDetailsPage;
