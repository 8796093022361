import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MyButton, TableCardComponent } from "components";

import { UnitDataContext } from "context";
import {
  IconTypeEnum,
  ParamsStringEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { UnitTableColumn } from "content";
import { PageLinks } from "routes";
import { useAuthorizationHook, usePageQueryHooks } from "useHooks";
import { FormWrapper } from "../../../assets";

function UnitsDetails() {
  const params = useParams<ParamsStringEnum>();
  const propertyID = params?.propertyId;
  const { allData, getAllDataHandlers, isLoading } =
    useContext(UnitDataContext);
  useEffect(() => {
    getAllDataHandlers({ property: propertyID });
  }, [propertyID]);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const canAdd = checkCanAccessHandler([SpaceAccessListEnum.RENTAL_ADD_UNIT]);

  const tableCardProps = {
    column: UnitTableColumn(false, { propertyId: propertyID }),
    loading: {
      isLoading: isLoading,
      loadingRow: 5,
    },
    dataSource: allData,
  };

  return (
    <div>
      <FormWrapper
        header={{
          title: { name: "List of Units" },
          rightChildren: canAdd && (
            <div>
              <MyButton
                iconType={IconTypeEnum.ADD}
                onClick={() => {
                  navigateWithReturnUrlHandler(
                    PageLinks.unit.new,
                    `${QueryStringEnum.PROPERTY_ID}=${propertyID}`
                  );
                }}
                name={"Add Unit"}
              />
            </div>
          ),
        }}
      >
        <TableCardComponent {...tableCardProps} />
      </FormWrapper>
    </div>
  );
}

export default UnitsDetails;
