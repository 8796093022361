import React from "react";
import { NameValueTextInterface } from "./interfaces";
import Skeleton from "react-loading-skeleton";

function NameValueText({
  name,
  nameStyle,
  valueStyle,
  value,
  containerStyle,
  isLoading,
  alignDirection,
  onValueClickHandler,
}: NameValueTextInterface) {
  const isClickable = typeof onValueClickHandler === "function";

  return (
    <div
      className={`flex  gap-4 text-[14px] ${
        alignDirection === "row" ? " items-center" : "flex-col"
      } ${containerStyle} `}
    >
      <span className={`font-bold text-black whitespace-nowrap ${nameStyle}`}>
        {name}
        {alignDirection === "row" && ":"}
      </span>
      {isLoading ? (
        <Skeleton width={100} />
      ) : (
        <p
          onClick={
            typeof onValueClickHandler === "function"
              ? onValueClickHandler
              : undefined
          }
          className={`flex items-start text-[16px] ${
            isClickable ? "text-tBlue" : "text-gray-900"
          } cursor-pointer truncate ${valueStyle}`}
        >
          {value}
        </p>
      )}
    </div>
  );
}

export default NameValueText;
