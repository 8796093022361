import React, { useContext, useEffect } from "react";

import { Outlet, useParams } from "react-router-dom";

import {
  IconTypeEnum,
  ParamsStringEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { MyButton, TableCardComponent } from "components";
import { PropertyDataContext } from "context";
import { useAuthorizationHook, usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";

import { OwnershipTableColumns } from "../components";
import { FormWrapper } from "../../../assets";

function OwnersDetails() {
  const params = useParams<ParamsStringEnum>();
  const pId = params.propertyId;
  const { ownership, isLoading, getOwnershipHandler, totalOwnerDocs } =
    useContext(PropertyDataContext);
  useEffect(() => {
    getOwnershipHandler(pId);
  }, [pId]);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_EDIT_PROPERTY,
    SpaceAccessListEnum.RENTAL_ADD_PROPERTY,
  ]);
  const tableCardProps = {
    column: OwnershipTableColumns(),
    loading: {
      isLoading: isLoading,
      loadingRow: 5,
    },
    dataSource: ownership,
  };
  return (
    <div className={"mt-5"}>
      <FormWrapper
        header={{
          title: { name: "List of Owners" },
          rightChildren: (
            <div className={"flex gap-5 items-center"}>
              {canEdit && (
                <MyButton
                  isOutline
                  onClick={() => {
                    navigateWithReturnUrlHandler(
                      PageLinks.property.assignOwner(pId)
                    );
                  }}
                  iconType={IconTypeEnum.EDIT}
                  name={"Assign Owner"}
                />
              )}
              {canEdit && (
                <MyButton
                  iconType={IconTypeEnum.ADD}
                  onClick={() => {
                    navigateWithReturnUrlHandler(
                      PageLinks.property.addOwner(pId)
                    );
                  }}
                  name={"Add Owner"}
                />
              )}
            </div>
          ),
        }}
      >
        <TableCardComponent
          {...tableCardProps}
          havePagination={{
            totalDocs: totalOwnerDocs,
          }}
        />
      </FormWrapper>

      <Outlet />
    </div>
  );
}

export default OwnersDetails;
