import React, { createContext, useContext, useState } from "react";

import { AppContext } from "context";
import { Api } from "helpers";
import { ApiUrl } from "services";
import {
  DynamicListInterface,
  DataContextInterface,
  DynamicConstantInterface,
} from "interfaces";
import {
  useConfirmHook,
  useDataContextHooks,
  usePaginationQueryHooks,
} from "useHooks";

import { HandlerCallbackInterface } from "interfaces";
import { FormikHelpers } from "formik/dist/types";
interface DynamicListContextInterface
  extends DataContextInterface<DynamicListInterface> {
  getConstantListHandler();
  constantLists: DynamicConstantInterface[];
}
export const DynamicListContext = createContext<DynamicListContextInterface>({
  isLoading: false,
  allData: [],
  constantLists: [],
  isDetailsLoading: false,
  totalArchiveDocs: 0,
  details: null,
  totalDocs: 0,
  getConstantListHandler() {},
  getAllDataHandlers(query?: any) {},
  getDetailsHandler(itemId: string, isFromEdit?: boolean) {},

  deleteHandler(itemId: string, callback?: HandlerCallbackInterface) {},

  editDetailsHandler(
    values,
    actions: FormikHelpers<unknown>,
    callback?: HandlerCallbackInterface
  ) {},
});

function DataContextProvider({ children }) {
  const { confirm } = useConfirmHook();
  const { isArchive, paginationQuery } = usePaginationQueryHooks();
  const [constantLists, setConstantLists] = useState([]);

  const {
    isArchiveLoaded,
    totalArchiveDocs,
    setTotalArchiveDocs,
    isDetailsLoading,
    setDetailsLoading,
    details,
    setLoading,
    isLoading,
    setTotalDocs,
    totalDocs,
    setDetails,
    allData,
    setAllData,
  } = useDataContextHooks();

  const { handlers } = useContext(AppContext);
  const { getApi, postApi, putApi, deleteApi } = Api();

  //  handler
  const contextHandlers = {
    getConstantListHandler: async () => {
      try {
        setLoading(true);
        let res: any;
        res = await getApi(ApiUrl.dynamicList.get_constants);
        setConstantLists(res);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getAllDataHandlers: async (query) => {
      try {
        setLoading(true);
        let res: any;
        res = await getApi(ApiUrl.dynamicList.get_listFromFieldType + query);
        // if (isArchive) {
        //   setTotalArchiveDocs(res?.data?.totalDocs);
        // } else {
        //   setTotalDocs(res?.data?.totalDocs);
        // }
        setAllData(res?.data?.docs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getDetailsHandler: async (itemID: string, isFromEdit) => {
      try {
        if (!isFromEdit) {
          setDetailsLoading(true);
          setDetails(null);
        }
        const res: any = await getApi(
          ApiUrl.dynamicList.get_itemDetail + itemID
        );
        setDetails(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
        handlers.setPageErrorHandler(true, err?.message);
      } finally {
        setDetailsLoading(false);
      }
    },
    editDetailsHandler: async (
      values,
      props,
      callback: HandlerCallbackInterface
    ) => {
      try {
        typeof props?.setSubmitting === "function"
          ? props?.setSubmitting(true)
          : handlers?.setLoading(true);
        const shouldEdit = values?._id;
        let res;
        // if (shouldEdit) {
        //   res = await putApi(ApiUrl.dynamicList.post_addItem, values);
        // } else {
        res = await postApi(ApiUrl.dynamicList.post_addItem, values);
        // }
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        typeof props?.setSubmitting === "function"
          ? props?.setSubmitting(false)
          : handlers?.setLoading(false);
      }
    },
    deleteHandler: async (itemsId, callback: HandlerCallbackInterface) => {
      try {
        const isConfirm = await confirm(
          "Are you sure you want to delete this item?",
          "Delete",
          "Cancel"
        );
        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res: any = await deleteApi(
          ApiUrl.dynamicList.delete_item + itemsId,
          {}
        );
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
  };

  const contextValue = {
    totalDocs,
    totalArchiveDocs,
    constantLists,
    allData,
    details,
    isDetailsLoading,
    isLoading,
    ...contextHandlers,
  };
  return (
    <DynamicListContext.Provider value={contextValue}>
      {children}
    </DynamicListContext.Provider>
  );
}

export default DataContextProvider;
