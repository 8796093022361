import React, { useState } from "react";
import { getIconHelper } from "helpers";
import { IconTypeEnum } from "interfaces";

const ToggleComponent = ({ title, children }) => {
  const [isOpen, setOpen] = useState(true);
  const DownIcon = getIconHelper(IconTypeEnum.DOWN);
  const UpIcon = getIconHelper(IconTypeEnum.RIGHT_BOLD);
  return (
    <div className={"flex flex-col gap-4 p-2"}>
      <div
        className={"flex items-center gap-1 cursor-pointer"}
        onClick={() => setOpen((e) => !e)}
      >
        {!isOpen ? <UpIcon /> : <DownIcon />}{" "}
        <b className={"text-tBlue"}>{title}</b>
      </div>
      {isOpen && <div className={"flex flex-col gap-2 px-3"}>{children}</div>}
    </div>
  );
};
export default ToggleComponent;
