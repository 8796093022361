import React, { useContext, useEffect, useState } from "react";

import Select from "react-select";
import { useSearchParams } from "react-router-dom";

import { PageTemplate } from "templates";
import { usePageQueryHooks } from "useHooks";
import { AccountingContext, AppContext, OptionsContext } from "context";
import {
  decodeLedgerNameOptionsHandler,
  getBalanceSheetTitleHandler,
  getCurrency,
  isDR,
  separateWithComma,
} from "helpers";
import { PageLinks } from "routes";
import { useDateHook } from "useHooks";
import { TableFilterCard } from "content";
import { MyButton, MyToggleButton } from "components";
import { useGetStartEndDateRangeHooks } from "useHooks";
import { IconTypeEnum, InputSizeEnum, QueryStringEnum } from "interfaces";

import { MessageComponent } from "../../assets";
import { LedgerBalanceReport } from "../../reports";

function LedgerPage() {
  const [query] = useSearchParams();
  const [hideZBA, setHideZBA] = useState(true);

  const { ledgers, isDetailsLoading, generateLedgersHandler } =
    useContext(AccountingContext);
  const { getLedgerOptionsHandler, ledgerNameOptions } =
    useContext(OptionsContext);
  const {
    handlers: { setLoading, setError },
  } = useContext(AppContext);
  const [selectedAccounts, setAccounts] = useState([]);
  const { startDate, endDate, rangeType, isAllTime, fiscalYear } =
    useGetStartEndDateRangeHooks();
  const property = query.get(QueryStringEnum.PROPERTY_ID);
  const unit = query.get(QueryStringEnum.UNIT_ID);
  const { getDateHandler } = useDateHook();

  useEffect(() => {
    generateLedgersHandler({
      accounts: selectedAccounts?.map((e) => e?.value),
      ...(startDate ? { startDate } : {}),
      ...(property ? { property } : {}),
      ...(unit ? { unit } : {}),
    });
  }, [selectedAccounts, startDate, endDate, property, unit]);
  const [data, setDate] = useState(ledgers?.ledgers);
  useEffect(() => {
    if (hideZBA) {
      setDate(ledgers?.ledgers?.filter((e) => e?.balance !== 0));
    } else {
      setDate(ledgers?.ledgers);
    }
  }, [hideZBA, ledgers]);

  useEffect(() => {
    getLedgerOptionsHandler();
  }, []);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const getQueryProps = (id) => {
    return `${QueryStringEnum.ACCOUNT_TYPE}=${id}&${
      QueryStringEnum.START_DATE
    }=${startDate}&${QueryStringEnum.END_DATE}=${endDate}&${
      QueryStringEnum.RANGE_TYPE
    }=${rangeType}&${QueryStringEnum.FISCAL_YEAR}=${fiscalYear || ""}`;
  };
  const { accountDateTitle } = getBalanceSheetTitleHandler({
    startDate,
    endDate,
    rangeType,
    isAllTime,
  });
  const generateReportHandler = async () => {
    try {
      setLoading(true);
      await LedgerBalanceReport({
        data: { ...ledgers, ledgers: data },
        dateHeader: accountDateTitle,
        dateFormatter: getDateHandler,
      });
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <PageTemplate
      title={"Accounting"}
      breadCrumb={[
        {
          name: "Ledger",
        },
      ]}
    >
      <div className={"flex flex-col gap-5 overflow-x-scroll"}>
        <div className={"flex items-center justify-between"}>
          <TableFilterCard haveLabel haveDateRange haveProperty haveUnit>
            <MultiSelectInput
              value={selectedAccounts}
              options={decodeLedgerNameOptionsHandler(ledgerNameOptions)}
              onChangeHandler={(e) => setAccounts(e)}
            />
          </TableFilterCard>
          <div className={"flex items-center gap-5 mt-8"}>
            <MyToggleButton
              label={"Hide Zero Balance"}
              changeHandler={(e) => {
                setHideZBA(e);
              }}
              buttonType={"toggle"}
              type={"button"}
              value={hideZBA}
            />
            <MyButton
              iconType={IconTypeEnum.DOWNLOAD}
              isOutline
              onClick={generateReportHandler}
              name={"Export .xlsx"}
            />
          </div>
        </div>
        <hr />
        <div className={"overflow-x-scroll"}>
          <table className={"table_container"}>
            <thead className="table_head whitespace-nowrap sticky top-0  shadow-sm">
              <tr className={"table_head bg-white"}>
                <th>SN.</th>
                <th>Date</th>
                <th>Ledger Account</th>
                <th>Sub-Group</th>
                <th className={"text-right"}>Dr</th>
                <th className={"text-right"}>Cr</th>
              </tr>
              <tr className="table_head bg-gray-100">
                <th></th>
                <th>Total</th>
                <th></th>
                <th></th>
                <th className={"text-right"}>
                  {getCurrency()}{separateWithComma(ledgers?.totalDebit)}
                </th>
                <th className={"text-right"}>
                  {getCurrency()}{separateWithComma(ledgers?.totalCredit)}
                </th>
              </tr>
              <tr className="table_head bg-white">
                <th></th>
                <th
                  scope="col"
                  style={{
                    whiteSpace: "normal",
                  }}
                  className=" max-w-[80px] "
                >
                  Closing Balance
                </th>
                <th></th>
                <th></th>
                <th className={"text-right"}>
                  {getCurrency()}{""}
                  {ledgers?.closingBalance < 0
                    ? Math.abs(ledgers?.closingBalance)
                    : "0.00"}
                </th>
                <th className={"text-right"}>
                  {getCurrency()}{""}
                  {ledgers?.closingBalance > 0
                    ? Math.abs(ledgers?.closingBalance)
                    : "0.00"}
                </th>
              </tr>
            </thead>
            {!isDetailsLoading &&
              data?.map((e, key) => {
                return (
                  <tr className={"table_row"} key={key}>
                    <td>{key + 1}.</td>
                    <td
                      scope="col"
                      style={{
                        whiteSpace: "normal",
                      }}
                      className=" max-w-[80px] "
                    >
                      {getDateHandler({ date: e?.ledgerDate })}
                    </td>
                    <td
                      scope="col"
                      style={{
                        whiteSpace: "normal",
                      }}
                      className=" max-w-[120px] "
                    >
                      {e?.account?.name}
                    </td>
                    <td
                      scope="col"
                      style={{
                        whiteSpace: "normal",
                      }}
                      className=" max-w-[120px] "
                    >
                      {e?.account?.subgroup?.name}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <span
                        onClick={() =>
                          isDR(e?.balance) &&
                          navigateWithReturnUrlHandler(
                            PageLinks.ledger.listLedgerTransaction,
                            getQueryProps(e?.account?._id)
                          )
                        }
                        className={`${
                          isDR(e?.balance) &&
                          "font-semibold text-primary cursor-pointer hover:underline"
                        }`}
                      >
                        {getCurrency()}{""}
                        {isDR(e?.balance)
                          ? separateWithComma(Math.abs(e?.balance))
                          : "0.00"}
                      </span>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <span
                        onClick={() =>
                          !isDR(e?.balance) &&
                          navigateWithReturnUrlHandler(
                            PageLinks.ledger.listLedgerTransaction,
                            getQueryProps(e?.account?._id)
                          )
                        }
                        className={`${
                          !isDR(e?.balance) &&
                          "font-semibold text-primary cursor-pointer hover:underline"
                        }`}
                      >
                        {getCurrency()}{""}
                        {!isDR(e?.balance)
                          ? separateWithComma(Math.abs(e?.balance))
                          : "0.00"}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </table>
          {isDetailsLoading && <MessageComponent message={"Please wait..."} />}{" "}
          {!isDetailsLoading && ledgers?.ledgers?.length < 1 && (
            <MessageComponent message={"No Ledger found"} />
          )}
        </div>
      </div>
    </PageTemplate>
  );
}
const MultiSelectInput = ({ value, options, onChangeHandler }) => {
  return (
    <div className={"input_container"}>
      <label className={"input_label"}>Accounts</label>
      <Select
        isMulti
        isClearable={true}
        className={"react-select-container"}
        classNamePrefix={"custom-react-select"}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral50: "#C0C0C0",
          },
        })}
        styles={{
          control: (provided, state) => ({
            ...provided,
            border: "1px solid #C0C0C0",
            borderRadius: "30px",
            boxShadow: "none",
            minHeight: "48px",
            transition: "none",
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            padding: "0 0px 0px 20px",
            fontSize: "16px",
            transition: "none",
          }),

          input: (provided, state) => ({
            ...provided,
            margin: "0px",
            outline: "none",
            boxShadow: "none",
            transition: "none",
          }),
          placeholder: (provided, state) => ({
            ...provided,
            whiteSpace: "nowrap",
          }),
          indicatorSeparator: (state) => ({
            ...state,
            display: "none",
            padding: "0px 0px",
          }),
          clearIndicator: (state) => ({
            ...state,
            padding: "0px 0px",
          }),
          dropdownIndicator: (state) => ({
            ...state,
            padding: "0px  10px 0px 0px",
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided,
            minHeight: "48px",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 999,
            fontSize: "16px",
          }),
          option: (base, { isSelected }) => ({
            ...base,
            borderBottom: "1px solid #D0D0D0",
            borderRadius: "5px 5px 0px 0px",
            color: isSelected ? "white" : "#00040E",
            backgroundColor: isSelected ? "#2680EB" : "",
          }),
          menuList: (base) => ({
            ...base,
            padding: 5,
          }),
          menu: (base, {}) => ({
            ...base,
            borderRadius: 20,
            border: "1px solid #C0C0C0",
            overflow: "hidden",
            padding: 5,
            boxShadow: "none",
            backgroundColor: "white",
          }),
        }}
        value={value}
        options={options}
        placeholder={"Select Account"}
        onChange={onChangeHandler}
      />
    </div>
  );
};

export default LedgerPage;
