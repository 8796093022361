import React, { useContext } from "react";
import { PageTemplate } from "templates";
import { Form, Formik } from "formik";
import { SpaceSettingFormikValues } from "../helpers";
import { AppContext } from "context";
import { SpaceSettingForm } from "../components";
import { FormConcludeButtons } from "assets";

function EditSpaceSettingPage() {
  const {
    state: { spaceSettings },
    handlers: { editSpaceSettingsHandler },
  } = useContext(AppContext);
  const submitHandler = (payload) => {
    editSpaceSettingsHandler(payload);
  };

  return (
    <PageTemplate
      backNavigation={{
        title: "Exit Settings",
      }}
      title={"Settings"}
      scrollAll
      breadCrumb={[
        {
          path: "/",
          name: "Settings",
        },
      ]}
    >
      <Formik
        enableReinitialize
        initialValues={SpaceSettingFormikValues.initialValues(spaceSettings)}
        validationSchema={SpaceSettingFormikValues.validationSchema}
        onSubmit={submitHandler}
      >
        <Form>
          <SpaceSettingForm />
          <FormConcludeButtons
            submitButton={{
              title: "Save ",
            }}
            cancelButton={{
              title: "Cancel",
            }}
          />
        </Form>
      </Formik>
    </PageTemplate>
  );
}

export default EditSpaceSettingPage;
