import React from "react";
import { MyTextAreaInput, MyTextField, MyUploadDocument } from "components";

function BankForm() {
  return (
    <>
      <div className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1  gap-5`}>
        <MyTextField
          name={"name"}
          placeholder={"eg.John Doe"}
          label={"Account Holder Name"}
          isRequired
        />
        <MyTextField
          name={"label"}
          placeholder={"eg.Nabil Bank"}
          label={"Bank Name"}
          isRequired
        />{" "}
        <MyTextField
          name={"accountNumber"}
          placeholder={"***********"}
          label={"Account Number"}
          isRequired
        />
        <MyTextField
          name={"branch"}
          label={"Branch"}
          isRequired
          placeholder={"eg.Kathmandu"}
        />
        <MyTextField
          name={"bankCode"}
          label={"Bank Code"}
          isRequired
          placeholder={"*******"}
        />
        <MyTextField
          name={"ifscCode"}
          label={"IFSC Code"}
          isRequired
          placeholder={"*******"}
        />{" "}
        <MyTextField
          name={"swiftCode"}
          label={"Swift Code"}
          isRequired
          placeholder={"******"}
        />
      </div>
      <div className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5`}>
        <MyUploadDocument
          fileType={"image"}
          name={"logo"}
          label={"Bank Logo"}
        />
        <MyUploadDocument
          fileType={"image"}
          name={"qrImage"}
          label={"QR Image"}
          fileContainerStyle={"grid grid-cols-1"}
        />
      </div>
      <div className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1`}>
        <div className={"col-span-2"}>
          <MyUploadDocument
            name={"documents"}
            label={"Document"}
            fileContainerStyle={"grid grid-cols-2 gap-5"}
          />
        </div>
      </div>
      <div className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1`}>
        <div className={"col-span-2"}>
          <MyTextAreaInput name={"description"} label={"Description"} />
        </div>
      </div>
    </>
  );
}

export default BankForm;
