import React, { createContext, useContext, useState } from "react";

import { AppContext } from "context";
import { Api } from "helpers";
import { ApiUrl } from "services";
import {
  HandlerCallbackInterface,
  PropertyInterface,
  PropertyOwnerInterface,
} from "interfaces";

import { DataContextInterface } from "interfaces";
import {
  useConfirmHook,
  useDataContextHooks,
  usePaginationQueryHooks,
} from "useHooks";
interface PropsInterface extends DataContextInterface<PropertyInterface> {
  ownership: PropertyOwnerInterface[];
  ownershipDetails: PropertyOwnerInterface;
  totalOwnerDocs: number;
  getOwnershipHandler(propertyID: string);
  getOwnershipDetailHandler(itemId: string, propertyID: string);
  addOwnerAndAssignOwnershipHandler(
    values,
    actions,
    callback?: HandlerCallbackInterface
  );
  editOwnershipHandler(
    values,
    actions: any,
    callback?: HandlerCallbackInterface
  );
  revokeOwnershipHandler(
    itemID: string,
    propertyID: string,
    callback?: HandlerCallbackInterface
  );
}
export const PropertyDataContext = createContext<PropsInterface>({
  isArchiveLoaded: false,
  isDetailsLoading: false,
  isLoading: false,
  allData: [],
  details: undefined,
  totalDocs: 0,
  totalArchiveDocs: 0,
  totalOwnerDocs: 0,
  ownership: [],
  ownershipDetails: undefined,
  getAllDataHandlers(query) {},
  getDetailsHandler(itemId: string) {},
  editDetailsHandler(values, actions, callback) {},
  archiveHandler(itemId, status, callback) {},
  deleteHandler(itemId, callback) {},

  getOwnershipHandler(itemId) {},
  getOwnershipDetailHandler: function (itemID, propertyID) {},
  editOwnershipHandler(values, actions: any, callback) {},
  addOwnerAndAssignOwnershipHandler: function (
    values,
    actions,
    callbackHandler
  ) {},
  revokeOwnershipHandler: function (itemID, propertyID, callbackHandler) {},
});

function DataContextProvider({ children }) {
  const { confirm } = useConfirmHook();
  const { isArchive, paginationQuery } = usePaginationQueryHooks();

  const {
    isArchiveLoaded,
    allData,
    setAllData,
    totalArchiveDocs,
    setTotalArchiveDocs,
    totalDocs,
    setTotalDocs,
    setDetails,
    isDetailsLoading,
    setDetailsLoading,
    isLoading,
    setLoading,
    details,
  } = useDataContextHooks();

  const [totalOwnerDocs, setTotalOwnerDocs] = useState(0);
  const [ownership, setOwnership] = useState([]);
  const [ownershipDetails, setOwnershipDetails] = useState(null);

  const { handlers } = useContext(AppContext);
  const { getApi, postApi, putApi, deleteApi } = Api();
  const contextHandlers = {
    getAllDataHandlers: async (query) => {
      try {
        setLoading(true);
        let res = await getApi(ApiUrl.property.get_property, {
          ...paginationQuery,
          ...query,
        });
        if (isArchive) {
          setTotalArchiveDocs(res?.data?.totalDocs);
        } else {
          setTotalDocs(res?.data?.totalDocs);
        }
        setAllData(res?.data?.docs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getDetailsHandler: async (itemId: string, isFromEdit?: boolean) => {
      try {
        if (!isFromEdit) {
          setDetailsLoading(true);
          setDetails(null);
        }
        const res: any = await getApi(
          ApiUrl.property.get_propertyDetails + itemId
        );
        setDetails(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
        handlers.setPageErrorHandler(true, err?.message);
      } finally {
        setDetailsLoading(false);
      }
    },
    getOwnershipHandler: async (itemID) => {
      try {
        setLoading(true);
        const res = await getApi(
          ApiUrl.ownership.get_ownershipFromPropertyId + itemID,
          {
            ...paginationQuery,
          }
        );
        setOwnership(res?.data?.docs);
        setTotalOwnerDocs(res?.data?.totalDocs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getOwnershipDetailHandler: async (itemID, propertyID) => {
      try {
        handlers?.setLoading(true);
        setOwnershipDetails({});

        const res = await getApi(
          ApiUrl.ownership.get_ownershipDetails + itemID + "/" + propertyID
        );
        setOwnershipDetails(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
    addOwnerAndAssignOwnershipHandler: async (
      values,
      props,
      callback: HandlerCallbackInterface
    ) => {
      try {
        typeof props?.setSubmitting === "function"
          ? props?.setSubmitting(true)
          : handlers?.setLoading(true);
        const res: any = await postApi(
          ApiUrl.ownership?.post_addOwnerAndAssignOwner,
          values
        );
        if (typeof callback?.onSuccess === "function") {
          await callback?.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback?.onError === "function") {
          await callback?.onError(err);
        }
      } finally {
        typeof props?.setSubmitting === "function"
          ? props?.setSubmitting(false)
          : handlers?.setLoading(false);
      }
    },
    editOwnershipHandler: async (
      values,
      props,
      callback: HandlerCallbackInterface
    ) => {
      try {
        typeof props?.setSubmitting === "function"
          ? props?.setSubmitting(true)
          : handlers?.setLoading(true);
        let res;
        if (values?._id) {
          res = await putApi(ApiUrl.ownership?.put_ownership, values);
        } else {
          res = await postApi(
            ApiUrl.ownership?.post_assignOwnerInProperty,
            values
          );
        }

        if (typeof callback?.onSuccess === "function") {
          await callback?.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        typeof props?.setSubmitting === "function"
          ? props?.setSubmitting(false)
          : handlers?.setLoading(false);
      }
    },
    editDetailsHandler: async (
      values,
      props,
      callback: HandlerCallbackInterface
    ) => {
      try {
        typeof props?.setSubmitting === "function"
          ? props?.setSubmitting(true)
          : handlers?.setLoading(true);
        let res;
        const shouldEdit = values?._id;
        if (shouldEdit) {
          res = await putApi(ApiUrl.property.put_editProperty, values);
        } else {
          res = await postApi(ApiUrl.property.post_addProperty, values);
        }
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        typeof props?.setSubmitting === "function"
          ? props?.setSubmitting(false)
          : handlers?.setLoading(false);
      }
    },
    deleteHandler: async (itemsId, callback: HandlerCallbackInterface) => {
      try {
        const isConfirm = await confirm(
          "Are you sure you want to delete this item?",
          "Delete",
          "Cancel"
        );
        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res: any = await deleteApi(
          ApiUrl.property.delete_Property + itemsId,
          {}
        );
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
    revokeOwnershipHandler: async (
      itemID: string,
      propertyID: string,
      callback: HandlerCallbackInterface
    ) => {
      try {
        const isConfirm = await confirm("Are you sure?");
        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res: any = await deleteApi(
          ApiUrl.ownership.delete_revokeOwnership + `${propertyID}/${itemID}`,
          {}
        );
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
    archiveHandler: async (
      itemsId,
      status: boolean,
      callback: HandlerCallbackInterface
    ) => {
      try {
        const isConfirm = await confirm(
          `Are you sure you want to ${
            !status ? "archive" : "unarchive"
          } the selected items?`,
          !status ? "Archive" : "Unarchive",
          "Cancel"
        );
        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res: any = await putApi(
          ApiUrl.property.put_archiveUnArchiveProperty +
            `${itemsId}/${status ? "off" : "on"}`,
          {}
        );
        if (isArchiveLoaded) {
          // isAlready archived
          if (status) {
            setTotalArchiveDocs(totalArchiveDocs - 1);
            setTotalDocs(totalDocs + 1);
          } else {
            setTotalArchiveDocs(totalArchiveDocs + 1);
          }
        }
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }

        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
  };
  const contextValue = {
    allData,
    details,
    totalDocs,
    totalOwnerDocs,
    totalArchiveDocs,
    ownership,
    ownershipDetails,
    isLoading,
    isDetailsLoading,
    ...contextHandlers,
  };
  return (
    <PropertyDataContext.Provider value={contextValue}>
      {children}
    </PropertyDataContext.Provider>
  );
}

export default DataContextProvider;
