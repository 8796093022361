import React from "react";
import { LeftRightHeaderInterface } from "./interfaces";

function LeftRightHeader({
  rightChildren,
  leftChildren,
  title,
  titleStyle,
  containerStyle,
}: LeftRightHeaderInterface) {
  return (
    <div className={`flex items-start justify-between ${containerStyle}`}>
      <div className={"flex items-center gap-2"}>
        <div
          className={`font-bold text-[22px] whitespace-nowrap ${titleStyle}`}
        >
          {title}
        </div>
        <div>{leftChildren}</div>
      </div>

      <div>{rightChildren}</div>
    </div>
  );
}

export default LeftRightHeader;
