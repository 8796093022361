import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";

import { PageLinks } from "routes";
import { PageTemplate } from "templates";
import { FormConcludeButtons } from "assets";
import { FieldTypeEnum, UserRoleEnum } from "interfaces";
import { MaintenanceContext, OptionsContext } from "context";

import { MaintenanceFormikValues } from "../helpers";
import { MaintenanceForm } from "../components";
import { usePageQueryHooks } from "useHooks";
import { Loader } from "components";

function NewMaintenancePage() {
  const navigate = useNavigate();
  const [enableReinitialize, setEnableReinitialize] = useState(true);
  const {
    getPropertyOptionsHandler,
    getOptionsHandler,
    getUserOptionsHandler,
  } = useContext(OptionsContext);

  const { editDetailsHandler } = useContext(MaintenanceContext);
  useEffect(() => {
    (async () => {
      setEnableReinitialize(true);
      await Promise.all([
        getPropertyOptionsHandler(),
        getUserOptionsHandler(UserRoleEnum.SUPPLIER),
        getOptionsHandler(FieldTypeEnum.INVENTORY_STATUS),
        getOptionsHandler(FieldTypeEnum.INVENTORY_ITEM),
        getOptionsHandler(FieldTypeEnum.INVENTORY_UNIT),
        getOptionsHandler(FieldTypeEnum.INVENTORY_ITEM_CONDITION),
        getOptionsHandler(FieldTypeEnum.REPAIR_PRIORITY),
        getOptionsHandler(FieldTypeEnum.REPAIR_STATUS),
      ]);

      setEnableReinitialize(false);
    })();
  }, []);
  const submitHandler = async (values, formikHelpers) => {
    await editDetailsHandler(values, formikHelpers, {
      onSuccess: async (payload) => {
        // if (returnURL) {
        //   navigate(returnURL);
        // } else {
        navigate(PageLinks.maintenance.list);
        // }
      },
    });
  };
  return (
    <PageTemplate
      title={"Maintenance"}
      scrollAll
      breadCrumb={[
        {
          name: "Maintenance",
        },
      ]}
      backNavigation={{
        title: `New Maintenance`,
        path: PageLinks.maintenance.list,
      }}
    >
      <Formik
        enableReinitialize={enableReinitialize}
        initialValues={MaintenanceFormikValues?.initialValues()}
        validationSchema={MaintenanceFormikValues?.validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting }) => {
          return (
            <Form
              className={"flex flex-col gap-5 overflow-x-scroll hideScroll"}
            >
              <MaintenanceForm isLoading={enableReinitialize} />
              {enableReinitialize && <Loader />}
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default NewMaintenancePage;
