import React, { useContext, useEffect, useState } from "react";
import { useFormikContext } from "formik";

import {
  FileViewCard,
  MySelectField,
  MyTextAreaInput,
  MyTextField,
  MyUploadDocument,
} from "components";
import {
  Api,
  decodeDynamicFieldHandler,
  decodePropertyOptionsHandler,
  decodeUserOptionsHandler,
  decodeUnitOptionsHandler,
  getCurrency,
} from "helpers";
import { FieldTypeEnum, RepairAndMaintenanceInterface } from "interfaces";
import { AppContext, OptionsContext } from "context";
import { NameValueText } from "assets";
import { ApiUrl } from "services";

function EditTaskForm({ isLoading }: { isLoading: boolean }) {
  const { values } = useFormikContext<any>();
  const [maintenanceItems, setMaintenanceItems] = useState<
    RepairAndMaintenanceInterface[]
  >([]);
  const {
    propertyOptions,
    unitOptions,
    userOptions,
    fieldTypeOptions,
    getUnitOptionsHandler,
  } = useContext(OptionsContext);
  const {
    handlers: { setLoading, setError },
  } = useContext(AppContext);
  const { getApi } = Api();
  useEffect(() => {
    if (values?.property) {
      getUnitOptionsHandler(values?.property);
    }
  }, [values?.property]);

  useEffect(() => {
    (async () => {
      try {
        if (
          !values?.unit ||
          !values?.property ||
          !values?.isRepairAndMaintenanceTask
        ) {
          return;
        }

        setLoading(true);
        const res = await getApi(ApiUrl.maintenance.get_maintenance, {
          property: values.property,
          unit: values?.unit,
        });
        setMaintenanceItems(res?.data?.docs || []);
      } catch (err) {
        setError(true, err);
      } finally {
        setLoading(false);
      }
    })();
  }, [values?.unit, values?.property, values?.isRepairAndMaintenanceTask]);

  const getRepairOptions = () => {
    const repairItems: RepairAndMaintenanceInterface[] =
      maintenanceItems?.reduce((acc, current) => {
        const valuesItems: any = values?.items;
        const isUsed = valuesItems?.some((e) => e?.repair === current?._id);
        if (!isUsed) {
          acc?.push(current);
        }
        return acc;
      }, []);

    return repairItems?.map((e) => {
      return {
        label: `${e?.inventoryItemID?.roomItemName?.label || e?.name} (${
          e?.repairID
        })`,
        value: e?._id,
      };
    });
  };
  const getItemDetails = (repairItemId) => {
    if (!repairItemId) {
      return;
    }
    return maintenanceItems?.find((e) => e?._id === repairItemId);
  };
  const repairItemOptions = getRepairOptions();
  const repairItemDetails: RepairAndMaintenanceInterface = getItemDetails(
    values?.repair
  );
  return (
    <div className={"flex flex-col gap-10 md:gap-5 sm:gap-5"}>
      <div
        className={`grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start`}
      >
        <MySelectField
          name={"property"}
          label={"Property"}
          disabled
          isRequired
          option={{
            selectOptions: decodePropertyOptionsHandler(propertyOptions),
          }}
        />
        {values?.property && (
          <MySelectField
            name={"unit"}
            disabled
            label={"Select Unit"}
            fetchOptionsHandler={() => getUnitOptionsHandler(values?.property)}
            isRequired
            option={{
              selectOptions: decodeUnitOptionsHandler(unitOptions),
            }}
          />
        )}
        <MySelectField
          name={"assignee"}
          label={"Assign To"}
          isRequired
          option={{
            selectOptions: decodeUserOptionsHandler(userOptions),
          }}
        />
        <MyTextField
          name={"chargeAmount"}
          label={`Charge Amount (${getCurrency()})`}
          type={"number"}
          placeholder={`${getCurrency()}00.00`}
        />
      </div>
      <div
        className={`grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1  gap-5 items-start`}
      >
        <MyTextField
          name={"name"}
          label={`Subject/Title`}
          isRequired
          placeholder={`eg. Paint living room`}
        />
        <MyTextField
          name={"targetDate"}
          label={`Due Date`}
          isRequired
          type={"date"}
        />
        <MySelectField
          name={"taskPriority"}
          label={"Priority"}
          isRequired
          option={{
            selectOptions: decodeDynamicFieldHandler(
              fieldTypeOptions[FieldTypeEnum.TASK_PRIORITY]
            ),
          }}
        />
        <MySelectField
          name={"taskStatus"}
          label={"Status"}
          isRequired
          option={{
            selectOptions: decodeDynamicFieldHandler(
              fieldTypeOptions[FieldTypeEnum.TASK_STATUS]
            ),
          }}
        />
      </div>
      {values?.isRepairAndMaintenanceTask && (
        <div className={"flex flex-col gap-5 "}>
          <div
            className={
              "grid grid-cols-5 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
            }
          >
            <MySelectField
              disabled
              isRequired
              label={"Items"}
              option={{
                selectOptions: repairItemOptions,
                defaultLabel: "Select Item",
              }}
              name={`repair`}
            />
          </div>
          {repairItemDetails && (
            <div
              className={
                "grid grid-cols-5 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start "
              }
            >
              <NameValueText
                alignDirection={"col"}
                name={"Item"}
                value={repairItemDetails?.inventoryItemID?.item?.label}
              />{" "}
              <NameValueText
                alignDirection={"col"}
                name={"Unit"}
                value={repairItemDetails?.inventoryItemID?.unit?.label}
              />
              <NameValueText
                alignDirection={"col"}
                name={"Unit to repair"}
                value={repairItemDetails?.unitToRepair}
              />
              <NameValueText
                alignDirection={"col"}
                name={"Condition"}
                value={
                  repairItemDetails?.inventoryItemID?.inventoryItemCondition
                    ?.label
                }
              />
              <NameValueText
                alignDirection={"col"}
                name={"Notes"}
                value={repairItemDetails?.inventoryItemID?.notes}
              />
            </div>
          )}
          <>
            {repairItemDetails?.inventoryItemID?.images?.length > 0 && (
              <div className={"input_container"}>
                <NameValueText name={"Images"} value={""} />
                <div
                  className={
                    "grid grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-5"
                  }
                >
                  {repairItemDetails?.inventoryItemID?.images?.map((e, key) => {
                    return <FileViewCard key={key} filePath={e} canDownload />;
                  })}
                </div>
              </div>
            )}
          </>
        </div>
      )}
      <div className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1 gap-5"}>
        <div className={"col-span-2"}>
          <MyTextAreaInput
            isRequired
            name={"description"}
            label={"Overall Comments"}
          />
        </div>
      </div>
      <div className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1  gap-5"}>
        <MyUploadDocument
          fileType={"image"}
          label={"Images"}
          fileContainerStyle={"grid grid-cols-1"}
          name={"documents"}
        />
      </div>
    </div>
  );
}

export default EditTaskForm;
