import React from "react";

import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { CustomerInterface } from "interfaces";

import { getAddressNameHandler, getStaffFullName } from "../HandlerHelpers";

interface PropsInterface {
  data?: CustomerInterface[];
}
async function generateUserReport({ data }: PropsInterface) {
  try {
    //    create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("User Report", {});
    worksheet.properties.defaultRowHeight = 20;
    worksheet.properties.defaultColWidth = 20;
    let tableHeaders = [
      {
        name: "SN.",
      },
      {
        name: "User ID",
      },
      {
        name: "Name",
      },
      {
        name: "Role",
      },
      {
        name: "Property",
      },
      {
        name: "Unit",
      },
      {
        name: "Contact Number",
      },
      {
        name: "Email",
      },
      {
        name: "Address",
      },
    ];
    let tableRows = data?.map((e, index) => {
      let addressName = getAddressNameHandler(e?.address);
      return [
        index + 1,
        e?.userID,
        getStaffFullName(e?.name),
        e?.role,
        e?.properties?.map((e) => e?.propertyID).join(","),
        e?.units?.map((e) => e?.unitID).join(","),
        e?.phone,
        e?.email,
        addressName,
      ];
    });
    // add a table to a sheet
    worksheet.addTable({
      name: "User Report",
      ref: "A1",
      headerRow: true,
      totalsRow: false,
      style: {
        showRowStripes: false,
      },
      columns: tableHeaders,
      rows: tableRows,
    });
    //    save the workbook to a file
    const bufferFile = await workbook.xlsx.writeBuffer();
    await FileSaver.saveAs(
      new Blob([bufferFile], { type: "application/octet-stream" }),
      `user.xlsx`
    );
  } catch (err) {
    throw err;
  }
}

export default generateUserReport;
