import React, { useContext, useEffect } from "react";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { FormConcludeButtons } from "assets";
import { PageTemplate } from "templates";
import { ParamsStringEnum } from "interfaces";
import { AppContext, NotesContext, OptionsContext } from "context";

import { NotesFormikValues } from "../../helpers";
import { NoteForm } from "../../components";
import { PageLinks } from "routes";

function NewNotePage() {
  const navigate = useNavigate();

  const { getPropertyOptionsHandler, getUserOptionsHandler } =
    useContext(OptionsContext);
  const params = useParams<ParamsStringEnum>();

  const {
    handlers: { setLoading },
  } = useContext(AppContext);
  const {
    details: notesDetails,
    getDetailsHandler,
    editDetailsHandler,
  } = useContext(NotesContext);
  const isEdit = params?.id;
  useEffect(() => {
    (async () => {
      setLoading(true);
      if (isEdit) {
        await getDetailsHandler(isEdit);
      }
      await getPropertyOptionsHandler();
      await getUserOptionsHandler();
      setLoading(false);
    })();
  }, [isEdit]);
  const submitHandler = async (values, formikHelpers) => {
    await editDetailsHandler(values, formikHelpers, {
      onSuccess: async (payload) => {
        navigate(PageLinks.notes.list);
      },
    });
  };
  return (
    <PageTemplate
      scrollAll
      title={"Notes"}
      breadCrumb={[
        {
          name: "Notes",
        },
        {
          name: `${isEdit ? "Edit" : "New"} Note`,
        },
      ]}
      backNavigation={{
        title: `${isEdit ? "Edit" : "New"} Note`,
        path: PageLinks.notes.list,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={NotesFormikValues?.initialValues(isEdit && notesDetails)}
        validationSchema={NotesFormikValues?.validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={"flex flex-col gap-5"}>
              <NoteForm />
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                  handler: () => {
                    navigate(PageLinks.notes.list);
                  },
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default NewNotePage;
