import React, { useContext, useEffect } from "react";
import { OptionsContext } from "context";
import { MySelectField, MyTextAreaInput, MyTextField } from "components";
import {
  decodeDynamicFieldHandler,
  decodePropertyOptionsHandler,
  decodeUnitOptionsHandler,
  getCurrency,
} from "helpers";
import { FormWrapper, NameValueText } from "assets";
import { FieldTypeEnum, MeterReadingInterface } from "interfaces";
import { useFormikContext } from "formik";
import { SelectExistingDocumentForm } from "../../assets";

function MeterReadingForm() {
  const { values } = useFormikContext<MeterReadingInterface<string>>();
  const {
    propertyOptions,
    unitOptions,
    fieldTypeOptions,
    getUnitOptionsHandler,
  } = useContext(OptionsContext);
  useEffect(() => {
    if (values?.property) {
      getUnitOptionsHandler(values?.property);
    }
  }, [values?.property]);
  const consumption =
    (values?.newReading ?? 0) - (values?.previousReading ?? 0);
  const totalPrice = consumption * values?.unitCost ?? 0;
  return (
    <div className={"flex flex-col gap-5"}>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <MySelectField
          label={"Property"}
          isRequired
          name={"property"}
          option={{
            selectOptions: decodePropertyOptionsHandler(propertyOptions),
            defaultLabel: "Select Property",
          }}
        />
        <MySelectField
          disabled={!values?.property}
          label={"Unit"}
          isRequired
          name={"unit"}
          option={{
            selectOptions: decodeUnitOptionsHandler(unitOptions),
            defaultLabel: "Select Unit",
          }}
        />
      </div>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <MyTextField
          placeholder={"eg. 1234-1234HK"}
          label={"Meter Number"}
          isRequired
          name={"meterNumber"}
        />
      </div>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <MyTextField
          label={"Name"}
          isRequired
          placeholder={"eg: Uttam"}
          name={"name"}
        />
        <MySelectField
          label={"Type"}
          isRequired
          name={"meterType"}
          isCreatable={{
            type: FieldTypeEnum.METER_TYPE,
          }}
          option={{
            selectOptions: decodeDynamicFieldHandler(
              fieldTypeOptions[FieldTypeEnum.METER_TYPE]
            ),
            defaultLabel: "Select One",
          }}
        />
      </div>

      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <MyTextField
          type={"date"}
          label={"Date"}
          isRequired
          name={"readingDate"}
        />
      </div>
      <div className={"flex flex-col gap-2"}>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <MyTextField
            type={"date"}
            label={"From"}
            isRequired
            name={"consumptionStartDate"}
          />
          <MyTextField
            type={"date"}
            label={"To"}
            isRequired
            name={"consumptionEndDate"}
          />
        </div>
        <span className={"text-[12px] text-gray-500"}>
          Enter the period for the service charge reconciliation.
        </span>
      </div>

      <FormWrapper
        header={{
          title: {
            name: "Description.",
          },
        }}
      >
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <MyTextField
            placeholder={"eg.250"}
            label={"Previous"}
            isRequired
            isNumberOnly
            name={"previousReading"}
          />
          <MyTextField
            placeholder={"eg.250"}
            label={"New Reading"}
            isRequired
            isNumberOnly
            name={"newReading"}
          />
          <NameValueText
            name={"Consumption"}
            valueStyle={"mt-3"}
            value={consumption > 0 ? consumption : 0}
          />
          <MyTextField
            placeholder={"eg.250"}
            label={`Unit Price (${getCurrency()})`}
            isRequired
            type={"number"}
            name={"unitCost"}
          />
          <NameValueText
            name={`Meter Bill (${getCurrency()})`}
            value={totalPrice}
            valueStyle={"mt-3"}
          />
          <div></div>
          <div className={"col-span-2"}>
            <MyTextAreaInput label={"Description"} name={"description"} />
          </div>
        </div>
      </FormWrapper>
      <FormWrapper
        header={{
          title: {
            name: "Documents",
          },
        }}
      >
        <SelectExistingDocumentForm />
      </FormWrapper>
    </div>
  );
}

export default MeterReadingForm;
