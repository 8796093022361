import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  SpaceAccessListEnum,
  TableColumnInterface,
  TripInterface,
} from "interfaces";
import { MyMoreOptionButton } from "components";

import { TripContext } from "context";
import { useAuthorizationHook, useDateHook } from "useHooks";
import { getCurrency } from "helpers";
import { usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";
import { PropertyUnitTableText } from "assets";

function TripsTableColumns(query): TableColumnInterface<TripInterface>[] {
  const navigate = useNavigate();
  const { deleteHandler, archiveHandler, getAllDataHandlers } =
    useContext(TripContext);
  const { calcSNHandler } = usePaginationQueryHooks();
  const { getDateHandler } = useDateHook();

  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([SpaceAccessListEnum.TOOLS_EDIT_TRIP]);
  const canDelete = checkCanAccessHandler([SpaceAccessListEnum.TOOLS_DEL_TRIP]);
  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <span>{calcSNHandler(key)}</span>;
      },
    },

    {
      title: "Property",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.property?.propertyID}
            address={renderData?.property?.address}
            propertyID={renderData?.property?._id}
          />
        );
      },
    },
    {
      title: "Unit",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.unit?.unitID}
            type={"unit"}
            name={renderData?.unit?.name}
            propertyID={renderData?.property?._id}
            unitID={renderData?.unit?._id}
          />
        );
      },
    },
    {
      title: "Date",
      render(renderData) {
        return (
          <div>
            <span>{getDateHandler({ date: renderData?.tripDate })}</span>
          </div>
        );
      },
    },
    {
      title: "Distance",
      render(renderData) {
        return (
          <div>
            <span>{renderData?.distanceCovered}</span>
          </div>
        );
      },
    },
    {
      title: `Price (${getCurrency()})`,
      render(renderData) {
        return (
          <div>
            <span>{renderData?.totalAmount}</span>
          </div>
        );
      },
    },
    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigate(PageLinks.trips.details(renderData?._id));
                  },
                },
                canEdit &&
                  !renderData?.isArchived && {
                    name: "Edit",
                    handler: () => {
                      navigate(PageLinks.trips.edit(renderData?._id));
                    },
                  },
                canDelete && {
                  name: "Delete",
                  handler: () => {
                    deleteHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
                canDelete && {
                  name: renderData?.isArchived ? `Unarchive` : "Archive",
                  handler: () => {
                    archiveHandler(renderData?._id, renderData?.isArchived, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default TripsTableColumns;
