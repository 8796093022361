import React, { useContext, useEffect } from "react";

import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";

import { NotificationContext } from "context";
import { EmptyMessageComponent, MyButton, MyIconButton } from "components";
import { IconTypeEnum } from "interfaces";
import { getIconHelper } from "helpers";
import { PageLinks } from "routes";

import "./style.css";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

function NotificationDetail() {
  return (
    <Popup
      key={`tp-sd`}
      trigger={
        <div>
          <MyIconButton
            title={"Notifications"}
            iconSize={24}
            IconType={IconTypeEnum.NOTIFICATION}
          />
        </div>
      }
      position={"bottom right"}
      on={["click"]}
      contentStyle={{ backgroundColor: "white", width: 350 }}
      arrow
      arrowStyle={{ color: "white" }}
    >
      <div className={"h-[30vh] overflow-y-scroll px-3 py-2"}>
        <NotificationsContainer />
      </div>
    </Popup>
  );
}

const NotificationsContainer = () => {
  const { getAllDataHandlers, allData, isLoading } =
    useContext(NotificationContext);
  useEffect(() => {
    if (allData?.length < 1) {
      getAllDataHandlers();
    }
  }, []);
  const navigate = useNavigate();
  return (
    <div>
      {!isLoading && allData?.length < 1 && (
        <EmptyMessageComponent message={"No notifications"} />
      )}
      <div className={"flex flex-col gap-2"}>
        {isLoading
          ? new Array(4).fill(4).map((e, key) => {
              return (
                <NotificationCard details={undefined} isLoading key={key} />
              );
            })
          : allData?.map((details, key) => {
              return (
                <NotificationCard
                  details={details}
                  isLoading={false}
                  key={key}
                />
              );
            })}
      </div>
      {allData?.length > 0 && (
        <div className={"flex mt-5 flex-1"}>
          <MyButton
            size={"md"}
            className={"rounded-md"}
            isOutline
            colorType={"white"}
            name={"View All"}
            onClick={() => navigate(PageLinks.notification.list)}
          />
        </div>
      )}
    </div>
  );
};

const NotificationCard = ({ details, isLoading }) => {
  const navigate = useNavigate();
  const MailIcon = getIconHelper(IconTypeEnum.MAIL);
  const CheckAllIcon = getIconHelper(IconTypeEnum.CHECK_ALL);
  return (
    <div
      onClick={
        isLoading
          ? undefined
          : () => navigate(PageLinks.notification.details(details?._id))
      }
      className={`flex items-start justify-start text-[12px] p-2 rounded-md cursor-pointer ${
        details?.isRead
          ? "bg-white hover:bg-gray-50"
          : "bg-gray-100 hover:bg-gray-50"
      }`}
    >
      <div className={"flex-1 flex flex-col gap-2"}>
        <div className={"flex flex-1 justify-between "}>
          {isLoading ? (
            <Skeleton className={"w-[100px]"} />
          ) : (
            <b className={"text-[14px]"}>{details?.title}</b>
          )}
        </div>
        {isLoading ? (
          <Skeleton className={"h-[100px]"} />
        ) : (
          <p className={"text-justify text-[14px] truncate w-[250px] "}>
            {details?.body}
          </p>
        )}
        <div className={"flex items-center gap-5"}>
          {!isLoading && (
            <div className={"flex items-center gap-2"}>
              {details?.isRead ? (
                <>
                  <MailIcon className={"text-[12px]"} />
                  <span>Unread</span>
                </>
              ) : (
                <>
                  <CheckAllIcon className={"text-[12px]"} />
                  <span>Mark as read</span>
                </>
              )}
            </div>
          )}

          {!isLoading && (
            <div className={"flex gap-5 items-center"}>
              <div className={"flex items-center gap-2 text-[12px]"}>
                {details?.createdAt && (
                  <div className={"flex items-center gap-2 text-[12px]"}>
                    {moment(details?.createdAt)?.fromNow()}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default NotificationDetail;
