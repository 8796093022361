import { useField } from "formik";
import "./styles.css";
import { MyTextFieldInterface } from "./interfaces";
import { getInputDateFormat } from "helpers";
import { RiContrastDropFill } from "react-icons/ri";

export default function MyTextField({
  label,
  isRequired,
  isFastField,
  validateHandler,
  fontSize,
  height,
  labelStyle,
  inputSize,
  isNumberOnly,
  isDigitOnly,
  onChangeCallbackHandler,
  maxChar,
  ...props
}: MyTextFieldInterface) {
  let [field, meta, helpers] = useField(props);
  let isError = meta.touched && meta.error;
  const inputMaxLength = process.env.REACT_APP_INPUT_NUMBER_MAX_LENGTH;
  const onChangeHandler = (e) => {
    e?.preventDefault();
    let value = e?.target?.value;
    if (isNumberOnly) {
      value = typeof value === "number" ? String(value) : value;
      if (!value) {
        helpers.setValue(value);
        return;
      }
      let isMatched = /^[0-9]+$/.test(value);
      if (!isMatched) {
        return;
      }
    }
    if (typeof props?.type === "number" || isNumberOnly) {
      value = typeof value === "number" ? String(value) : value;
      if (value?.length > inputMaxLength) {
        return;
      }
    }
    if (isDigitOnly) {
      let isMatched = /^[0-9]+$/.test(value);
      if (!isMatched) {
        return;
      }
    }
    if (maxChar) {
      value = typeof value === "number" ? String(value) : value;
      if (value?.length > maxChar) {
        return;
      }
    }
    if (typeof onChangeCallbackHandler === "function") {
      onChangeCallbackHandler(value);
    }
    helpers.setValue(value);
  };
  return (
    <div className={`input_container`}>
      {label && (
        <label
          className={`input_label ${
            inputSize === "sm"
              ? "text-[12px]"
              : inputSize === "md"
              ? "text-[12px]"
              : "text-[14px]"
          } ${labelStyle} `}
        >
          {label}
          {isRequired && <span className={"text-tRed "}>*</span>}
        </label>
      )}
      {props?.type === "color" ? (
        <div
          className={
            "flex items-center gap-4  border-2 p-2 rounded-full cursor-pointer "
          }
        >
          <RiContrastDropFill fontSize={20} />
          <input
            className={`flex-1 rounded-full p-1 text-[16px] overflow-hidden ${
              isError && "border-tRed"
            }  
            `}
            {...field}
            {...props}
          />
        </div>
      ) : (
        <input
          {...field}
          {...props}
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onChange={onChangeHandler}
          type={props?.type || "text"}
          className={`${
            props?.disabled && "bg-gray-100 cursor-not-allowed"
          } rounded-full outline-none  focus:border-[#2680EB] ${
            isError ? "border-tRed" : "border-[#C0C0C0]"
          } 
           ${
             inputSize === "sm"
               ? "text-[12px] pl-4  h-[35px]  min-h-[35px]"
               : inputSize === "md"
               ? "text-[14px] pl-2 min-h-[35px]"
               : "text-[16px] pl-6 min-h-[48px]"
           }
           ${props?.className} `}
          style={(height || fontSize) && { height: height, fontSize: fontSize }}
          value={
            props?.type === "date" || props?.type === "datetime-local"
              ? getInputDateFormat(field?.value, props?.type)
              : field.value
          }
        />
      )}
      {isError && meta?.error?.trim() && (
        <span className="input_textError">* {meta.error}</span>
      )}
    </div>
  );
}
