import React from "react";
import { Route } from "react-router-dom";

import { EventPage, NewEventPage } from "./pages";
import { QueryStringEnum, SpaceAccessListEnum } from "interfaces";
import "./styles.css";
import { AccessVerifier } from "templates";
function Index() {
  return (
    <Route path="events/">
      <Route
        path={""}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.EVENT_READ_EVENT]}
            isForPage
          >
            <EventPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"new/*"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.EVENT_ADD_EVENT]}
            isForPage
          >
            <NewEventPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`edit/:${QueryStringEnum.ID}/*`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.EVENT_EDIT_EVENT]}
            isForPage
          >
            <NewEventPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
