import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  TableColumnInterface,
  DocumentInterface,
  SpaceAccessListEnum,
} from "interfaces";
import { MyMoreOptionButton } from "components";

import { DocumentContext } from "context";
import { useAuthorizationHook, useDateHook } from "useHooks";
import { usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";
import { CollapseUserImage, PropertyUnitTableText, StatusText } from "assets";

function DocumentsTableColumns(
  query
): TableColumnInterface<DocumentInterface>[] {
  const navigate = useNavigate();
  const { archiveHandler, deleteHandler, getAllDataHandlers } =
    useContext(DocumentContext);
  const { calcSNHandler } = usePaginationQueryHooks();
  const { getDateHandler } = useDateHook();

  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.DOCUMENT_EDIT_DOCUMENT,
  ]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.DOCUMENT_DEL_DOCUMENT,
  ]);
  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <span>{calcSNHandler(key)}</span>;
      },
    },

    {
      title: "File",
      render(renderData) {
        return (
          <span className={"text-primary font-semibold"}>
            {renderData?.documentType?.label}
          </span>
        );
      },
    },
    {
      title: "Property",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.property?.propertyID}
            address={renderData?.property?.address}
            propertyID={renderData?.property?._id}
          />
        );
      },
    },
    {
      title: "Sharing",
      render(renderData) {
        const isSharing = renderData?.sharedTo?.length > 0 ? true : false;
        return (
          <div>
            <StatusText
              status={isSharing ? "active" : "rejected"}
              label={isSharing ? "Yes" : "No"}
            />
          </div>
        );
      },
    },
    {
      title: "Shared With",
      render(renderData) {
        return <CollapseUserImage users={renderData?.sharedTo} />;
      },
    },
    {
      title: "Date",
      render(renderData) {
        return (
          <div>
            <span>
              {getDateHandler({ date: renderData?.dateOfValidation })}
            </span>
          </div>
        );
      },
    },

    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigate(PageLinks.documents.details(renderData?._id));
                  },
                },
                canEdit &&
                  !renderData?.isArchived && {
                    name: "Edit",
                    handler: () => {
                      navigate({
                        pathname: PageLinks.documents.edit(renderData?._id),
                      });
                    },
                  },
                canDelete && {
                  name: "Delete",
                  handler: () => {
                    deleteHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
                canDelete && {
                  name: renderData?.isArchived ? `Unarchive` : "Archive",
                  handler: () => {
                    archiveHandler(renderData?._id, renderData?.isArchived, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default DocumentsTableColumns;
