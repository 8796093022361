import { PropertyInterface, UnitInterface } from "../rental";
import { UserRoleEnum } from "../generic.enum";

export interface SpaceGrantedInterface {
  OR?: boolean;
  AUTH: {
    ADD_ROLE: boolean;
    EDIT_ROLE: boolean;
    DEL_ROLE: boolean;
    READ_ROLE: boolean;
    ADD_USER: boolean;
    EDIT_USER: boolean;
    DEL_USER: boolean;
    READ_USER: boolean;
    ACCESS_SUPPLIER: boolean;
  };
  RENTAL: {
    ADD_PROPERTY: boolean;
    EDIT_PROPERTY: boolean;
    DEL_PROPERTY: boolean;
    READ_PROPERTY: boolean;
    ADD_UNIT: boolean;
    EDIT_UNIT: boolean;
    DEL_UNIT: boolean;
    READ_UNIT: boolean;
  };
  TENANCY: {
    ADD_TENANCY: boolean;
    EDIT_TENANCY: boolean;
    DEL_TENANCY: boolean;
    READ_TENANCY: boolean;
  };
  BOOKING: {
    ADD_BOOKING: boolean;
    EDIT_BOOKING: boolean;
    DEL_BOOKING: boolean;
    READ_BOOKING: boolean;
  };
  FINANCE: {
    ADD_INVOICE: boolean;
    EDIT_INVOICE: boolean;
    DEL_INVOICE: boolean;
    READ_INVOICE: boolean;
    ADD_BANK: boolean;
    EDIT_BANK: boolean;
    DEL_BANK: boolean;
    READ_BANK: boolean;
    LINK_BANK: boolean;
    RECONCILIATION: boolean;
    READ_RECONCILE_STATEMENT: boolean;
    ADD_LOAN_AND_MORTGAGE: boolean;
    EDIT_LOAN_AND_MORTGAGE: boolean;
    DEL_LOAN_AND_MORTGAGE: boolean;
    READ_LOAN_AND_MORTGAGE: boolean;
  };
  INVENTORY: {
    ADD_INVENTORY: boolean;
    EDIT_INVENTORY: boolean;
    DEL_INVENTORY: boolean;
    READ_INVENTORY: boolean;
  };
  EVENT: {
    ADD_EVENT: boolean;
    EDIT_EVENT: boolean;
    DEL_EVENT: boolean;
    READ_EVENT: boolean;
  };
  REPAIR_AND_MAINTENANCE: {
    ADD_REPAIR_AND_MAINTENANCE: boolean;
    EDIT_REPAIR_AND_MAINTENANCE: boolean;
    DEL_REPAIR_AND_MAINTENANCE: boolean;
    READ_REPAIR_AND_MAINTENANCE: boolean;
  };
  TASK: {
    ADD_TASK: boolean;
    EDIT_TASK: boolean;
    DEL_TASK: boolean;
    READ_TASK: boolean;
  };
  CANDIDATE: {
    ADD_CANDIDATE: boolean;
    EDIT_CANDIDATE: boolean;
    DEL_CANDIDATE: boolean;
    READ_CANDIDATE: boolean;
  };
  DOCUMENT: {
    ADD_DOCUMENT: boolean;
    EDIT_DOCUMENT: boolean;
    DEL_DOCUMENT: boolean;
    READ_DOCUMENT: boolean;
  };
  TOOLS: {
    ADD_METER_READING: boolean;
    EDIT_METER_READING: boolean;
    DEL_METER_READING: boolean;
    READ_METER_READING: boolean;
    ADD_TRIP: boolean;
    EDIT_TRIP: boolean;
    DEL_TRIP: boolean;
    READ_TRIP: boolean;
  };
  MISC: {
    ACCESS_DASHBOARD: boolean;
    ALLOW_MOBILE_LOGIN: boolean;
    ACCESS_REPORT_PAGE: boolean;
    ACCESS_TRASH: boolean;
    ACCESS_MESSAGE: boolean;
  };
  CONFIGURATION: {
    ADD_ADMINISTRATIVE_SETUP: boolean;
    EDIT_ADMINISTRATIVE_SETUP: boolean;
    DEL_ADMINISTRATIVE_SETUP: boolean;

    MANAGE_NOTIFICATION: boolean;
    MANAGE_SETTINGS: boolean;
    ACCESS_RETAIN_EARNING: boolean;
    MANAGE_TRUE_LAYER_SETTINGS: boolean;
    MANAGE_TWILIO_SETTINGS: boolean;
    MANAGE_BREVO_SETTINGS: boolean;
  };
  BILLING: { VIEW_SUBSCRIPTION: boolean; PURCHASE_SUBSCRIPTION: boolean };
  ACCOUNT: {
    ACCESS_SUB_LEDGER: boolean;
    ADD_LEDGER_NAME: boolean;
    EDIT_LEDGER_NAME: boolean;
    DEL_LEDGER_NAME: boolean;
    READ_LEDGER_NAME: boolean;
    ADD_JOURNAL: boolean;
    EDIT_JOURNAL: boolean;
    DEL_JOURNAL: boolean;
    READ_JOURNAL: boolean;
    ACCESS_TRIAL_BALANCE: boolean;
    ACCESS_LEDGER: boolean;
    ACCESS_LEDGER_TRANSACTION: boolean;
    ACCESS_INCOME_STATEMENT: boolean;
    ACCESS_BALANCE_SHEET: boolean;
  };
}

export interface PrivilegeInterface {
  _id?: string;
  name?: string; // name
  role?: UserRoleEnum; // custom for admin made
  isSeed?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  properties?: PropertyInterface[]; // accessed Properties List
  units?: UnitInterface[]; // accessed Units List
  granted?: SpaceGrantedInterface; // generated from generatePrivilegeInterfaceFromEnum function
  isDeleted?: boolean;
  isArchived?: boolean;
}

export enum SpaceAccessListEnum {
  // flag
  OR = "OR", // make access list OR conjunction
  AND = "AND", // make access list AND conjunction
  AUTH_ACCESS_SUPPLIER = "AUTH.ACCESS_SUPPLIER",

  // roles start here

  // PRIVILEGE
  AUTH_ADD_ROLE = "AUTH.ADD_ROLE",
  AUTH_EDIT_ROLE = "AUTH.EDIT_ROLE",
  AUTH_DEL_ROLE = "AUTH.DEL_ROLE",
  AUTH_READ_ROLE = "AUTH.READ_ROLE",

  // USER
  AUTH_ADD_USER = "AUTH.ADD_USER",
  AUTH_EDIT_USER = "AUTH.EDIT_USER",
  AUTH_DEL_USER = "AUTH.DEL_USER",
  AUTH_READ_USER = "AUTH.READ_USER",

  // PROPERTY
  RENTAL_ADD_PROPERTY = "RENTAL.ADD_PROPERTY",
  RENTAL_EDIT_PROPERTY = "RENTAL.EDIT_PROPERTY",
  RENTAL_DEL_PROPERTY = "RENTAL.DEL_PROPERTY",
  RENTAL_READ_PROPERTY = "RENTAL.READ_PROPERTY",

  // UNIT
  RENTAL_ADD_UNIT = "RENTAL.ADD_UNIT",
  RENTAL_EDIT_UNIT = "RENTAL.EDIT_UNIT",
  RENTAL_DEL_UNIT = "RENTAL.DEL_UNIT",
  RENTAL_READ_UNIT = "RENTAL.READ_UNIT",

  // TENANCY
  TENANCY_ADD_TENANCY = "TENANCY.ADD_TENANCY",
  TENANCY_EDIT_TENANCY = "TENANCY.EDIT_TENANCY",
  TENANCY_DEL_TENANCY = "TENANCY.DEL_TENANCY",
  TENANCY_READ_TENANCY = "TENANCY.READ_TENANCY",

  // Booking
  BOOKING_ADD_BOOKING = "BOOKING.ADD_BOOKING",
  BOOKING_EDIT_BOOKING = "BOOKING.EDIT_BOOKING",
  BOOKING_DEL_BOOKING = "BOOKING.DEL_BOOKING",
  BOOKING_READ_BOOKING = "BOOKING.READ_BOOKING",

  FINANCE_ADD_INVOICE = "FINANCE.ADD_INVOICE",
  FINANCE_EDIT_INVOICE = "FINANCE.EDIT_INVOICE",
  FINANCE_DEL_INVOICE = "FINANCE.DEL_INVOICE",
  FINANCE_READ_INVOICE = "FINANCE.READ_INVOICE",

  // bank
  FINANCE_ADD_BANK = "FINANCE.ADD_BANK",
  FINANCE_EDIT_BANK = "FINANCE.EDIT_BANK",
  FINANCE_DEL_BANK = "FINANCE.DEL_BANK",
  FINANCE_READ_BANK = "FINANCE.READ_BANK",
  FINANCE_LINK_BANK = "FINANCE.LINK_BANK",

  // reconcile
  FINANCE_RECONCILIATION = "FINANCE.RECONCILIATION",
  FINANCE_READ_RECONCILE_STATEMENT = "FINANCE.READ_RECONCILE_STATEMENT",

  // mortgage
  FINANCE_ADD_LOAN_AND_MORTGAGE = "FINANCE.ADD_LOAN_AND_MORTGAGE",
  FINANCE_EDIT_LOAN_AND_MORTGAGE = "FINANCE.EDIT_LOAN_AND_MORTGAGE",
  FINANCE_DEL_LOAN_AND_MORTGAGE = "FINANCE.DEL_LOAN_AND_MORTGAGE",
  FINANCE_READ_LOAN_AND_MORTGAGE = "FINANCE.READ_LOAN_AND_MORTGAGE",

  // Inventory
  INVENTORY_ADD_INVENTORY = "INVENTORY.ADD_INVENTORY",
  INVENTORY_EDIT_INVENTORY = "INVENTORY.EDIT_INVENTORY",
  INVENTORY_DEL_INVENTORY = "INVENTORY.DEL_INVENTORY",
  INVENTORY_READ_INVENTORY = "INVENTORY.READ_INVENTORY",

  // Event
  EVENT_ADD_EVENT = "EVENT.ADD_EVENT",
  EVENT_EDIT_EVENT = "EVENT.EDIT_EVENT",
  EVENT_DEL_EVENT = "EVENT.DEL_EVENT",
  EVENT_READ_EVENT = "EVENT.READ_EVENT",

  REPAIR_AND_MAINTENANCE_ADD_REPAIR_AND_MAINTENANCE = "REPAIR_AND_MAINTENANCE.ADD_REPAIR_AND_MAINTENANCE",
  REPAIR_AND_MAINTENANCE_EDIT_REPAIR_AND_MAINTENANCE = "REPAIR_AND_MAINTENANCE.EDIT_REPAIR_AND_MAINTENANCE",
  REPAIR_AND_MAINTENANCE_DEL_REPAIR_AND_MAINTENANCE = "REPAIR_AND_MAINTENANCE.DEL_REPAIR_AND_MAINTENANCE",
  REPAIR_AND_MAINTENANCE_READ_REPAIR_AND_MAINTENANCE = "REPAIR_AND_MAINTENANCE.READ_REPAIR_AND_MAINTENANCE",

  TASK_ADD_TASK = "TASK.ADD_TASK",
  TASK_EDIT_TASK = "TASK.EDIT_TASK",
  TASK_DEL_TASK = "TASK.DEL_TASK",
  TASK_READ_TASK = "TASK.READ_TASK",

  CANDIDATE_ADD_CANDIDATE = "CANDIDATE.ADD_CANDIDATE",
  CANDIDATE_EDIT_CANDIDATE = "CANDIDATE.EDIT_CANDIDATE",
  CANDIDATE_DEL_CANDIDATE = "CANDIDATE.DEL_CANDIDATE",
  CANDIDATE_READ_CANDIDATE = "CANDIDATE.READ_CANDIDATE",

  DOCUMENT_ADD_DOCUMENT = "DOCUMENT.ADD_DOCUMENT",
  DOCUMENT_EDIT_DOCUMENT = "DOCUMENT.EDIT_DOCUMENT",
  DOCUMENT_DEL_DOCUMENT = "DOCUMENT.DEL_DOCUMENT",
  DOCUMENT_READ_DOCUMENT = "DOCUMENT.READ_DOCUMENT",

  TOOLS_ADD_METER_READING = "TOOLS.ADD_METER_READING",
  TOOLS_EDIT_METER_READING = "TOOLS.EDIT_METER_READING",
  TOOLS_DEL_METER_READING = "TOOLS.DEL_METER_READING",
  TOOLS_READ_METER_READING = "TOOLS.READ_METER_READING",
  TOOLS_ADD_TRIP = "TOOLS.ADD_TRIP",
  TOOLS_EDIT_TRIP = "TOOLS.EDIT_TRIP",
  TOOLS_DEL_TRIP = "TOOLS.DEL_TRIP",
  TOOLS_READ_TRIP = "TOOLS.READ_TRIP",

  // MISC
  MISC_ALLOW_MOBILE_LOGIN = "MISC.ALLOW_MOBILE_LOGIN",
  MISC_ACCESS_DASHBOARD = "MISC.ACCESS_DASHBOARD",
  MISC_ACCESS_REPORT_PAGE = "MISC.ACCESS_REPORT_PAGE",
  MISC_ACCESS_TRASH = "MISC.ACCESS_TRASH",
  MISC_ACCESS_MESSAGE = "MISC.ACCESS_MESSAGE",

  // ADMINISTRATIVE SETUP
  CONFIGURATION_ADD_ADMINISTRATIVE_SETUP = "CONFIGURATION.ADD_ADMINISTRATIVE_SETUP",
  CONFIGURATION_EDIT_ADMINISTRATIVE_SETUP = "CONFIGURATION.EDIT_ADMINISTRATIVE_SETUP",
  CONFIGURATION_DEL_ADMINISTRATIVE_SETUP = "CONFIGURATION.DEL_ADMINISTRATIVE_SETUP",
  CONFIGURATION_MANAGE_NOTIFICATION = "CONFIGURATION.MANAGE_NOTIFICATION",
  CONFIGURATION_MANAGE_SETTINGS = "CONFIGURATION.MANAGE_SETTINGS",
  CONFIGURATION_ACCESS_RETAIN_EARNING = "CONFIGURATION.ACCESS_RETAIN_EARNING",
  CONFIGURATION_MANAGE_TRUE_LAYER_SETTINGS = "CONFIGURATION.MANAGE_TRUE_LAYER_SETTINGS",
  CONFIGURATION_MANAGE_TWILIO_SETTINGS = "CONFIGURATION.MANAGE_TWILIO_SETTINGS",
  CONFIGURATION_MANAGE_BREVO_SETTINGS = "CONFIGURATION.MANAGE_BREVO_SETTINGS",

  // Purchase
  BILLING_VIEW_SUBSCRIPTION = "BILLING.VIEW_SUBSCRIPTION",
  BILLING_PURCHASE_SUBSCRIPTION = "BILLING.PURCHASE_SUBSCRIPTION",

  ACCOUNT_ACCESS_SUB_LEDGER = "ACCOUNT.ACCESS_SUB_LEDGER",
  ACCOUNT_ADD_LEDGER_NAME = "ACCOUNT.ADD_LEDGER_NAME",
  ACCOUNT_EDIT_LEDGER_NAME = "ACCOUNT.EDIT_LEDGER_NAME",
  ACCOUNT_DEL_LEDGER_NAME = "ACCOUNT.DEL_LEDGER_NAME",
  ACCOUNT_READ_LEDGER_NAME = "ACCOUNT.READ_LEDGER_NAME",

  ACCOUNT_ADD_JOURNAL = "ACCOUNT.ADD_JOURNAL",
  ACCOUNT_EDIT_JOURNAL = "ACCOUNT.EDIT_JOURNAL",
  ACCOUNT_DEL_JOURNAL = "ACCOUNT.DEL_JOURNAL",
  ACCOUNT_READ_JOURNAL = "ACCOUNT.READ_JOURNAL",

  ACCOUNT_ACCESS_TRIAL_BALANCE = "ACCOUNT.ACCESS_TRIAL_BALANCE",
  ACCOUNT_ACCESS_LEDGER = "ACCOUNT.ACCESS_LEDGER",
  ACCOUNT_ACCESS_LEDGER_TRANSACTION = "ACCOUNT.ACCESS_LEDGER_TRANSACTION",
  ACCOUNT_ACCESS_INCOME_STATEMENT = "ACCOUNT.ACCESS_INCOME_STATEMENT",
  ACCOUNT_ACCESS_BALANCE_SHEET = "ACCOUNT.ACCESS_BALANCE_SHEET",
}
export enum ModuleListEnum {
  NONE = "NONE",
  AUTH = "AUTH",
  CONFIGURATION = "CONFIGURATION",
  RENTAL = "RENTAL",
  TENANCY = "TENANCY",
  INVOICE = "INVOICE",
  DOCUMENT = "DOCUMENT",
  COMMUNICATION = "COMMUNICATION",
  INVENTORY = "INVENTORY",
  FINANCE = "FINANCE",
  CANDIDATE = "CANDIDATE",
  BOOKING = "BOOKING",
  REPAIR_AND_MAINTENANCE = "REPAIR_AND_MAINTENANCE",
  LOAN_AND_MORTGAGE = "LOAN_AND_MORTGAGE",
  NOTES = "NOTES",
  TASK = "TASK",
  BANK = "BANK",
  TOOLS = "TOOLS",
}

export type ModuleListType =
  | ModuleListEnum.NONE
  | ModuleListEnum.AUTH
  | ModuleListEnum.CONFIGURATION
  | ModuleListEnum.RENTAL
  | ModuleListEnum.TENANCY
  | ModuleListEnum.INVOICE
  | ModuleListEnum.DOCUMENT
  | ModuleListEnum.COMMUNICATION
  | ModuleListEnum.INVENTORY
  | ModuleListEnum.FINANCE
  | ModuleListEnum.CANDIDATE
  | ModuleListEnum.BOOKING
  | ModuleListEnum.REPAIR_AND_MAINTENANCE
  | ModuleListEnum.NOTES
  | ModuleListEnum.TASK
  | ModuleListEnum.BANK
  | ModuleListEnum.TOOLS;
