import React from "react";
import { Route } from "react-router-dom";
import {
  BalanceSheetPage,
  IncomeStatementPage,
  JournalDetailsPage,
  JournalPage,
  LedgerPage,
  LedgerTransactionPage,
  NewJournalPage,
  TrialBalancePage,
} from "./pages";
import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import "./styles.css";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="accounting/">
      <Route
        path={"income-statement/"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.ACCOUNT_ACCESS_INCOME_STATEMENT]}
            isForPage={true}
          >
            <IncomeStatementPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"balance-sheet/"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.ACCOUNT_ACCESS_BALANCE_SHEET]}
            isForPage={true}
          >
            <BalanceSheetPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"trial-balance/"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.ACCOUNT_ACCESS_TRIAL_BALANCE]}
            isForPage={true}
          >
            <TrialBalancePage />
          </AccessVerifier>
        }
      />
      <Route
        path={"ledger/"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.ACCOUNT_ACCESS_LEDGER]}
            isForPage={true}
          >
            <LedgerPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"ledger-transaction/"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.ACCOUNT_ACCESS_LEDGER_TRANSACTION]}
            isForPage={true}
          >
            <LedgerTransactionPage />
          </AccessVerifier>
        }
      />
      {/*journal*/}
      <Route
        path={"journal/"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.ACCOUNT_READ_JOURNAL]}
            isForPage={true}
          >
            <JournalPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"journal/new/*"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.ACCOUNT_ADD_JOURNAL]}
            isForPage={true}
          >
            <NewJournalPage isForOpeningBalance={false} />
          </AccessVerifier>
        }
      />{" "}
      <Route
        path={`journal/edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.ACCOUNT_EDIT_JOURNAL]}
            isForPage={true}
          >
            <NewJournalPage isForOpeningBalance={false} />
          </AccessVerifier>
        }
      />
      <Route
        path={"journal/opening"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.ACCOUNT_ADD_JOURNAL]}
            isForPage={true}
          >
            <NewJournalPage isForOpeningBalance={true} />
          </AccessVerifier>
        }
      />
      <Route
        path={`journal/details/:${ParamsStringEnum.ID}/*`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.ACCOUNT_READ_JOURNAL]}
            isForPage={true}
          >
            <JournalDetailsPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
