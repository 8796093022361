const actionTypes = {
  APP_SET_AUTHENTICATED: "LOGIN_USER",
  LOGOUT: "LOGOUT_USER",
  APP_SET_TENANT_SPACE_LIST: "APP_SET_TENANT_SPACE_LIST",
  APP_SET_PROFILE: "APP_SET_PROFILE",
  APP_SET_SETTING: "APP_SET_SETTING",
  APP_SET_TRUE_LAYER_SETTING: "APP_SET_TRUE_LAYER_SETTING",
  APP_SET_TWILIO_SETTINGS: "APP_SET_TWILIO_SETTINGS",
  APP_SET_LOADING: "APP_SET_LOADING",
  APP_SET_ERROR: "APP_SET_ERROR",
  APP_SET_SUCCESS_ACTION: "APP_SET_SUCCESS_ACTION",
  APP_SET_AUTH_LOADING: "APP_SET_AUTH_LOADING",
  APP_SET_SWITCH_WORKSPACE: "APP_SET_SWITCH_WORKSPACE",
  APP_SET_CURRENT_WORKSPACE: "APP_SET_CURRENT_WORKSPACE",
  APP_SET_PAGE_ERROR: "APP_SET_PAGE_ERROR",
  APP_SET_APP_SETTINGS: "APP_SET_APP_SETTINGS",
};

export default actionTypes;
