import { EmptyDataInterface } from "./interfaces";

export default function EmptyData({
  message,
  styles,
  imageStyles,
  textStyles,
}: EmptyDataInterface) {
  return (
    <div style={styles} className="flex flex-col items-center">
      <img
        style={imageStyles}
        src="/img/app/noData.svg"
        alt="noData"
        className="w-[100px] h-[100px]"
      />
      <span className=" font-serif" style={textStyles}>
        {message}
      </span>
    </div>
  );
}
