import * as yup from "yup";
import { Task_RepairItemFormInterface, TaskInterface } from "interfaces";

export const TaskFormikValues = {
  ItemValues: (values?: Task_RepairItemFormInterface) => {
    return {
      chargeAmount: "",
      repair: "",
      targetDate: "",
      documents: [],
      description: "",
      taskStatus: "",
      taskPriority: "",
    };
  },
  initialValues: () => {
    return {
      property: "",
      unit: "",
      assignee: "",
      chargeAmount: "",
      name: "",
      targetDate: "",
      taskPriority: "",
      taskStatus: "",
      notifyParticipant: false,
      notifyTenant: false,
      notifyLandlord: false,
      description: "",
      documents: [],
      isRepairAndMaintenanceTask: false,
      items: [TaskFormikValues.ItemValues()],
    };
  },
  editInitialValue: (values: TaskInterface) => {
    return {
      _id: values?._id,
      property: values?.property?._id || "",
      unit: values?.unit?._id || "",
      assignee: values?.assignee?._id || "",
      chargeAmount: values?.chargeAmount || "",
      name: values?.name || "",
      targetDate: values?.targetDate || "",
      taskPriority: values?.taskPriority?._id || "",
      taskStatus: values?.taskStatus?._id || "",
      description: values?.description || "",
      documents: values?.documents || [],
      isRepairAndMaintenanceTask: values?.isRepairAndMaintenanceTask,
      repair: values?.repair?._id,
    };
  },
  validationSchema: yup.object().shape({
    property: yup.string().required("required"),
    unit: yup.string().required("required"),
    assignee: yup.string().required("required"),
    name: yup.string().required("required"),
    notifyParticipant: yup.boolean(),
    notifyTenant: yup.boolean(),
    notifyLandlord: yup.boolean(),
    chargeAmount: yup.number().when("isRepairAndMaintenanceTask", {
      is: false,
      then: yup.number().min(0, "invalid"),
      otherwise: yup.number(),
    }),
    targetDate: yup.string().when("isRepairAndMaintenanceTask", {
      is: false,
      then: yup.string().required("required"),
      otherwise: yup.string(),
    }),
    taskPriority: yup.string().when("isRepairAndMaintenanceTask", {
      is: false,
      then: yup.string().required("required"),
      otherwise: yup.string(),
    }),
    taskStatus: yup.string().when("isRepairAndMaintenanceTask", {
      is: false,
      then: yup.string().required("required"),
      otherwise: yup.string(),
    }),

    description: yup.string(),
    documents: yup.array(),
    isRepairAndMaintenanceTask: yup.boolean(),
    items: yup.array().when("isRepairAndMaintenanceTask", {
      is: true,
      then: yup
        .array()
        .of(
          yup.object().shape({
            repair: yup.string().required("required"),
            targetDate: yup.string().required("required"),
            taskPriority: yup.string().required("required"),
            taskStatus: yup.string().required("required"),
            chargeAmount: yup.number().min(0, "invalid"),
          })
        )
        .min(1, "At least on item required"),
    }),
  }),
  editValidationSchema: yup.object().shape({
    property: yup.string().required("required"),
    unit: yup.string().required("required"),
    assignee: yup.string().required("required"),
    chargeAmount: yup.string(),
    name: yup.string().required("required"),
    targetDate: yup.string().required("required"),
    taskPriority: yup.string().required("required"),
    taskStatus: yup.string().required("required"),
    notifyParticipant: yup.boolean(),
    notifyTenant: yup.boolean(),
    notifyLandlord: yup.boolean(),
    description: yup.string(),
    documents: yup.array(),
    isRepairAndMaintenanceTask: yup.boolean(),
    repair: yup.string().when("isRepairAndMaintenanceTask", {
      is: true,
      then: yup.string().required("required"),
      otherwise: yup.string(),
    }),
  }),
};
