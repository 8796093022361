import React from "react";
import { Route } from "react-router-dom";
import { TenancyPage, NewTenancyPage, TenancyDetailsPage } from "./pages";
import { GeneralDetailTab, InvoiceTab } from "./tabs";

import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="rental">
      {/*tenancy*/}
      <Route
        path={`tenancy/*`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.TENANCY_READ_TENANCY]}
          >
            <TenancyPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`tenancy/new`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.TENANCY_ADD_TENANCY]}
          >
            <NewTenancyPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`tenancy/edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.TENANCY_EDIT_TENANCY]}
          >
            <NewTenancyPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`tenancy/details/:${ParamsStringEnum?.ID}/*`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.TENANCY_READ_TENANCY]}
          >
            <TenancyDetailsPage />
          </AccessVerifier>
        }
      >
        <Route
          path={``}
          element={
            <AccessVerifier
              isForPage={true}
              accessKeys={[SpaceAccessListEnum.TENANCY_READ_TENANCY]}
            >
              <GeneralDetailTab />
            </AccessVerifier>
          }
        />
        <Route
          path={`invoice`}
          element={
            <AccessVerifier
              isForPage={true}
              accessKeys={[SpaceAccessListEnum.FINANCE_READ_INVOICE]}
            >
              <InvoiceTab />
            </AccessVerifier>
          }
        />
      </Route>
    </Route>
  );
}

export default Index;
