import { useContext } from "react";
import { useField } from "formik";

import { AppContext } from "context";
import { Api, getIconHelper } from "helpers";
import { ApiUrl } from "services";

import { MyUploadDocumentInterface } from "./interfaces";
import { IconTypeEnum } from "interfaces";
import { FileViewCard } from "../cards";
import { FileUploader } from "react-drag-drop-files";

export default function MyUploadDocument({
  fileType = "document",
  fileContainerStyle,
  onChangeCallBack,
  onDeleteHandler,
  ...props
}: MyUploadDocumentInterface) {
  const [field, meta, helpers] = useField(props);
  const { postFormApi } = Api();
  const ImageIcon = getIconHelper(IconTypeEnum.Images);

  const { handlers } = useContext(AppContext);
  let isError = meta.touched && meta.error;
  const isMultiple = Array.isArray(field.value);
  const maxFileSize: any = process.env.REACT_APP_DOC_SIZE_MB;
  const allowedExtensionsForImage = ["jpg", "jpeg", "png", "gif"];
  const allowedExtensionsForDocument = [
    "pdf",
    "doc",
    "jpg",
    "jpeg",
    "xlsx",
    "xls",
    "csv",
    "docx",
    "png",
  ];
  const changeHandler = async (file) => {
    try {
      // let file = e?.files[0];
      const fileName =
        file?.name?.split(".")?.length > 0
          ? file?.name?.split(".")[0]
          : field?.name;
      const fileExtension = file?.name?.split(".")?.pop()?.toLowerCase();
      if (
        (fileType === "image" &&
          allowedExtensionsForImage.indexOf(fileExtension) === -1) ||
        (fileType === "document" &&
          allowedExtensionsForDocument.indexOf(fileExtension) === -1)
      ) {
        // Invalid file type
        handlers?.setError(
          true,
          `Please select a valid file (${
            fileType === "image"
              ? allowedExtensionsForImage?.join(", ")
              : allowedExtensionsForDocument.join(", ")
          }).`
        );
        helpers?.setTouched(true);
        return;
      }
      if (maxFileSize && file?.size > maxFileSize * 1024 * 1024) {
        handlers?.setError(
          true,
          `File size exceeds the maximum allowed size (${maxFileSize} MB).`
        );
        return;
      }

      handlers?.setLoading(true);

      const bodyFormData = new FormData();
      bodyFormData.append("file", file);
      bodyFormData.append("imageTitle", fileName || field?.name);

      let res: any = await postFormApi(
        ApiUrl.file.post_uploadFile,
        bodyFormData
      );
      if (!res.success) {
        throw res;
      }
      if (isMultiple) {
        let value = [...field.value, res.name];
        helpers?.setValue(value);
        if (typeof onChangeCallBack === "function") {
          onChangeCallBack(value);
        }
      } else {
        helpers?.setValue(res.name);
        if (typeof onChangeCallBack === "function") {
          onChangeCallBack(res.name);
        }
      }
    } catch (error) {
      handlers.setError(true, error.message);
    } finally {
      handlers?.setLoading(false);
    }
  };

  const removeHandler = (index) => {
    if (typeof onDeleteHandler === "function") {
      onDeleteHandler(index);
    }
    if (typeof field?.value === "string") {
      helpers?.setValue("");
    } else {
      let value = [...field.value];
      value.splice(index, 1);
      helpers?.setValue(value);
    }
  };

  return (
    <div className="input_container">
      {props?.label && <label className={"input_label"}>{props?.label}</label>}

      <FileUploader
        onTypeError={(err) => handlers?.setError(true, err)}
        dropMessageStyle={{
          display: "none",
        }}
        types={
          fileType === "image"
            ? allowedExtensionsForImage
            : allowedExtensionsForDocument
        }
        name={field?.name}
        handleChange={changeHandler}
      >
        <div className={"cursor-pointer"}>
          {/* select file*/}
          {(isMultiple || !field.value) && (
            <>
              {fileType !== "image" ? (
                <div
                  className={
                    "flex flex-col items-center justify-center flex-1 h-[258px] gap-5 bg-[#f6f6f7] border-dashed border-[#C0C0C0] border-2 rounded-[28px]  "
                  }
                >
                  <div className={"p-5 bg-white rounded-full text-[24px]"}>
                    <ImageIcon className={"text-primary"} />
                  </div>

                  <span className={"text-[18px] font-bold"}>
                    Drop your file(s) here or Browse
                  </span>
                  <div
                    className={
                      "flex flex-col items-center text-center text-[#c0c0c0] text-[16px] font-displayLight"
                    }
                  >
                    <span>
                      Accepted formats :
                      {` ( ${
                        fileType !== "document"
                          ? allowedExtensionsForImage?.join(", ")
                          : allowedExtensionsForDocument.join(", ")
                      } )`}
                    </span>
                    {maxFileSize && <span>Maximum size: {maxFileSize}MB.</span>}
                  </div>
                </div>
              ) : (
                <div
                  className={
                    "flex items-center gap-3 h-[48px]  bg-[#f6f6f7] rounded-full p-2 text-[12px]"
                  }
                >
                  <div
                    className={
                      "bg-tBlue text-white rounded-full  p-1 px-4 uppercase"
                    }
                  >
                    Choose
                  </div>
                  <div className={"text-[#c0c0c0]"}>No files selected.</div>
                </div>
              )}
            </>
          )}
        </div>
      </FileUploader>
      {isError && <span className="input_textError">* {meta.error}</span>}
      {/*selected doc*/}
      <div
        className={
          fileContainerStyle ||
          (isMultiple
            ? `grid grid-cols-4 gap-5 md:grid-cols-2 sm:grid-cols-1 mt-2`
            : "grid grid-cols-1")
        }
      >
        {isMultiple
          ? field?.value?.length > 0 && (
              <>
                {field?.value?.map((e, key) => {
                  return (
                    <FileViewCard
                      key={key}
                      filePath={e}
                      deleteHandler={() => removeHandler(key)}
                    />
                  );
                })}
              </>
            )
          : field.value && (
              <FileViewCard
                filePath={meta.value}
                key={0}
                deleteHandler={() => removeHandler(0)}
              />
            )}
      </div>
    </div>
  );
}
