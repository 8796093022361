import React, { useContext, useEffect } from "react";
import Calendar from "react-calendar";
import { usePageQueryHooks } from "useHooks";
import { useDateHook } from "useHooks";
import { EventContext } from "context";

function CalendarCard() {
  const { getDateHandler } = useDateHook();
  const { monthEvents, getMonthEventHandler } = useContext(EventContext);
  const { date, setDateQueryHandler } = usePageQueryHooks();
  const selectedDate = date || getDateHandler({ date: new Date() });
  useEffect(() => {
    getMonthEventHandler({
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    });
  }, []);
  return (
    <div>
      <Calendar
        onActiveStartDateChange={({ activeStartDate }: any) => {
          getMonthEventHandler({
            year: new Date(activeStartDate).getFullYear(),
            month: new Date(activeStartDate).getMonth() + 1,
          });
        }}
        value={selectedDate}
        calendarType={"US"}
        onChange={(e: any) => setDateQueryHandler(getDateHandler({ date: e }))}
        tileContent={({ date }) => {
          let haveEvent = monthEvents?.find((e) => {
            return (
              getDateHandler({ date: e?.eventDate }) ===
              getDateHandler({ date: date })
            );
          });
          if (haveEvent?.eventCount > 0) {
            return (
              <div
                className={
                  "flex items-center rounded-full gap-1  bg-blue-200 p-1 text-[14px]"
                }
              >
                <b
                  className={
                    "rounded-full bg-white text-[12px] min-w-[25px] p-1"
                  }
                >
                  {haveEvent?.eventCount}
                </b>
                <span>Event</span>
              </div>
            );
          } else {
            return <div></div>;
          }
        }}
      />
    </div>
  );
}

export default CalendarCard;
