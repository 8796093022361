import * as yup from "yup";
import {
  MessageInterface,
  NoteInterface,
  MeterReadingInterface,
  TripInterface,
  CandidateInterface,
  DocumentInterface,
} from "interfaces";
import { addressInitialValues, addressValidationSchema } from "content";

export const NotesFormikValues = {
  initialValues: (values?: NoteInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      name: values?.name || "",
      property: values?.property?._id || "",
      unit: values?.unit?._id || "",
      note: values?.note || "",
      sharedTo: values?.sharedTo?.map((e) => e?._id) || [],
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().required("required"),
    property: yup.string(),
    unit: yup.string(),
    note: yup.string().required("required"),
  }),
};
export const MessageFormikValues = {
  initialValues: (values?: MessageInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      subject: values?.subject || "",
      recipients: values?.recipients?.map((e) => e?._id) || [],
      message: values?.message || "",
      documents: values?.documents || [],
    };
  },
  validationSchema: yup.object().shape({
    subject: yup.string().required("required"),
    message: yup.string().required("required"),
    recipients: yup.array().min(1, "required").required("required"),
    documents: yup.array(),
  }),
};
export const MeterReadingFormikValues = {
  initialValues: (values?: MeterReadingInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      property: values?.property?._id || "",
      unit: values?.unit?._id || "",
      name: values?.name || "",
      meterNumber: values?.meterNumber || "",
      meterType: values?.meterType?._id || "",
      readingDate: values?.readingDate || "",
      consumptionStartDate: values?.consumptionStartDate || "",
      consumptionEndDate: values?.consumptionEndDate || "",
      previousReading: values?.previousReading || "",
      newReading: values?.newReading || "",
      consumption: values?.consumption || "",
      unitCost: values?.unitCost || "",
      description: values?.description || "",
      totalAmount: values?.totalAmount || "",
      documents: values?.documents || [],
    };
  },
  validationSchema: yup.object().shape({
    property: yup.string().required("required"),
    unit: yup.string().required("required"),
    meterNumber: yup.string().required("required"),
    meterType: yup.string().required("required"),
    readingDate: yup.string().required("required"),
    consumptionStartDate: yup.string().required("required"),
    consumptionEndDate: yup.string().required("required"),
    previousReading: yup.string().min(0, "invalid").required("required"),
    newReading: yup
      .string()
      .min(0, "invalid")
      .test("custom-validation", "", function (value) {
        const preReading = this.parent.previousReading || 0;
        const isError = preReading > value;
        return (
          !isError ||
          this.createError({
            path: "newReading",
            message: "New Reading must be greater then previous reading.",
          })
        );
      }),
    unitCost: yup.string().required("required"),
    description: yup.string(),
    documents: yup.array(),
  }),
};

export const TripsFormikValues = {
  initialValues: (values?: TripInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      property: values?.property?._id || "",
      unit: values?.unit?._id || "",
      name: values?.name || "",
      tripDate: values?.tripDate || "",
      from: values?.from || "",
      to: values?.to || "",
      distanceCovered: values?.distanceCovered || "",
      costPerDistance: values?.costPerDistance || "",
      totalAmount: values?.totalAmount || "",
      description: values?.description || "",
      documents: values?.documents || [],
    };
  },
  validationSchema: yup.object().shape({
    property: yup.string().required("required"),
    unit: yup.string(),
    name: yup.string().required("required"),
    tripDate: yup.string().required("required"),
    from: yup.string().required("required"),
    to: yup.string().required("required"),
    distanceCovered: yup.string().required("required"),
    costPerDistance: yup.string().required("required"),
    description: yup.string(),
    documents: yup.array(),
  }),
};
export const CandidatesFormikValues = {
  initialValues: (values?: CandidateInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      name: {
        first: values?.name?.first || "",
        middle: values?.name?.middle || "",
        last: values?.name?.last || "",
      },
      email: values?.email,
      phone: values?.phone,
      mobile: values?.mobile,
      // address: addressInitialValues(values?.address),
      ...addressInitialValues(values?.address),
      profession: values?.profession?._id || "",
      revenue: values?.revenue || "",
      property: values?.property?._id || "",
      isTenantGuarantor: values?.isTenantGuarantor || false,
      tenantGuarantorSubject: values?.tenantGuarantorSubject || "",
      tenantGuarantorMessage: values?.tenantGuarantorMessage || "",
      shouldInvite: values?.shouldInvite || false,
      invitationText: values?.invitationText || "",
      comments: values?.comments || "",
      candidateStatus: values?.candidateStatus?._id || "",
    };
  },
  validationSchema: yup
    .object()
    .shape({
      name: yup.object().shape({
        first: yup.string().required("required"),
        last: yup.string().required("required"),
      }),
      profession: yup.string().required("required"),
      email: yup.string().required("required"),
      revenue: yup.number().positive("invalid").required("required"),
      mobile: yup.string().required("required"),
      property: yup.string().required("required"),
      candidateStatus: yup.string().required("required"),
      isTenantGuarantor: yup.boolean(),
      tenantGuarantorSubject: yup.string().when("isTenantGuarantor", {
        is: true,
        then: yup.string().required("required"),
      }),
      tenantGuarantorMessage: yup.string().when("isTenantGuarantor", {
        is: true,
        then: yup.string().required("required"),
      }),
      shouldInvite: yup.boolean(),
      invitationText: yup.string().when("shouldInvite", {
        is: true,
        then: yup.string().required("required"),
      }),
      comments: yup.string(),
    })
    .concat(addressValidationSchema),
};
export const DocumentFormikValues = {
  initialValues: (values?: DocumentInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      name: values?.name || "",
      documentType: values?.documentType?._id || "",
      property: values?.property?._id || "",
      unit: values?.unit?._id || "",
      description: values?.description || "",
      documents: values?.documents || [],
      dateOfValidation: values?.dateOfValidation || "",
      sharedTo: values?.sharedTo?.map((e) => e?._id) || [],
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().required("required"),
    documentType: yup.string().required("required"),
    property: yup.string().required("required"),
    unit: yup.string().required("required"),
    description: yup.string(),
    documents: yup.array().min(1, "required").required("required"),
    dateOfValidation: yup.string().required("required"),
    sharedTo: yup.array(),
  }),
};
