import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { MyButton, TableCardComponent } from "components";
import { AccessVerifier, PageTemplate } from "templates";
import {
  FieldTypeEnum,
  IconTypeEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { TableFilterCard } from "content";

import { CandidateTableColumns } from "../../components";
import { CandidateDataContext } from "context";
import { usePageQueryHooks, usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function CandidatesPage() {
  const {
    getAllDataHandlers,
    allData,
    isLoading,
    totalDocs,
    totalArchiveDocs,
  } = useContext(CandidateDataContext);
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const { isArchive, currentPage } = usePaginationQueryHooks();
  const { unitID, propertyID, type, status } = usePageQueryHooks();
  const pageQuery = {
    unit: unitID,
    property: propertyID,
    candidateStatus: status,
    profession: type,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, unitID, propertyID, currentPage, type, status]);

  const tableCardProps = {
    column: CandidateTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      leftChildren: (
        <TableFilterCard
          haveProperty
          haveDynamicOption={{
            queryName: QueryStringEnum.STATUS,
            placeholder: "Select Status",
            fieldName: FieldTypeEnum.CANDIDATE_STATUS,
          }}
          haveDynamicOption2={{
            queryName: QueryStringEnum.TYPE,
            placeholder: "Select Profession",
            fieldName: FieldTypeEnum.PROFESSION,
          }}
        />
      ),
      rightChildren: (
        <AccessVerifier
          isForPage={false}
          accessKeys={[SpaceAccessListEnum.CANDIDATE_ADD_CANDIDATE]}
        >
          <MyButton
            onClick={() => {
              navigate(PageLinks.candidates.new);
            }}
            iconType={IconTypeEnum.ADD}
            name={"Add Candidate"}
          />
        </AccessVerifier>
      ),
    },
  };
  return (
    <PageTemplate
      title={"Candidates"}
      breadCrumb={[
        {
          name: "Candidates",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default CandidatesPage;
