import React, { useContext, useEffect } from "react";

import { TableCardComponent } from "components";
import { PageTemplate } from "templates";
import { SubLedgerNameContext } from "context";
import { usePaginationQueryHooks } from "useHooks";

import { SubLedgerNameTableColumns } from "../../components";

function SubLedgerNamePage() {
  const {
    allData,
    getAllDataHandlers,
    isLoading,
    totalDocs,
    totalArchiveDocs,
  } = useContext(SubLedgerNameContext);
  const { isArchive, currentPage } = usePaginationQueryHooks();
  useEffect(() => {
    getAllDataHandlers();
  }, [isArchive, currentPage]);

  const tableCardProps = {
    column: SubLedgerNameTableColumns(),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
  };
  return (
    <PageTemplate title={"Sub-Ledger Name"}>
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default SubLedgerNamePage;
