import React, { useContext, useEffect, useState } from "react";
import { AppContext, TrueLayerContext } from "context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { QueryStringEnum } from "../../interfaces";
import { PageLinks } from "../../routes";
import { MyButton } from "../../components";

function Index() {
  const { handlers, state } = useContext(AppContext);
  const { setupBankFromCodeHandler } = useContext(TrueLayerContext);
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const code = query.get(QueryStringEnum.CODE);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState("");
  useEffect(() => {
    if (code) {
      setLoading(true);
      setupBankFromCodeHandler(
        { code },
        {
          onSuccess(payload?: any): any {
            setLoading(false);
            setError(null);
            navigate(PageLinks.banks.list);
          },
          onError(error?: any) {
            setError(error?.toString());
            setLoading(false);
          },
        }
      );
    } else {
      setLoading(false);
      setError("Invalid code");
    }
  }, [code]);

  return (
    <div
      className={
        "w-screen  h-screen z-[10001] items-center bg-tGray fixed top-0 left-0 flex justify-center"
      }
    >
      <div
        className={
          "flex flex-col items-center justify-center gap-5 w-[500px] min-h-[30vh] bg-white p-5 rounded-md shadow-sm"
        }
      >
        <img
          src={"/img/logo/logo.png"}
          className={"w-[80px] h-[80px] object-contain"}
        />
        <h1
          className={`font-bold text-[22px] ${
            isLoading ? "text-primary" : "text-tRed"
          }`}
        >
          {isLoading
            ? "Please wait"
            : error
            ? "Error Occurred"
            : "Something went wrong"}
        </h1>
        <samp>{isLoading ? "processing..." : error ? error : "Error"}</samp>
        {!isLoading && error && (
          <div>
            <MyButton
              name={"Cancel"}
              size={"md"}
              isOutline
              onClick={() => navigate(PageLinks.banks.list)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Index;
