import React, { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { PageLinks } from "routes";
import { PageTemplate } from "templates";
import { usePageQueryHooks } from "useHooks";
import { FormConcludeButtons } from "assets";
import { FieldTypeEnum, ParamsStringEnum } from "interfaces";
import { OptionsContext, TaskContext } from "context";

import { TaskFormikValues } from "../helpers";
import { EditTaskForm } from "../components";
import { Loader } from "components";

function EditTaskPage() {
  const navigate = useNavigate();
  const itemID = useParams<ParamsStringEnum>()?.id;
  const { returnURL } = usePageQueryHooks();
  const [enableReinitialize, setEnableReinitialize] = useState(true);
  const {
    getPropertyOptionsHandler,
    getOptionsHandler,
    getUserOptionsHandler,
  } = useContext(OptionsContext);

  const { details, getDetailsHandler, editDetailsHandler } =
    useContext(TaskContext);
  useEffect(() => {
    (async () => {
      if (itemID) {
        setEnableReinitialize(true);
        await Promise.all([
          getPropertyOptionsHandler(),
          getUserOptionsHandler(),
          getDetailsHandler(itemID),
          getOptionsHandler(FieldTypeEnum.TASK_PRIORITY),
          getOptionsHandler(FieldTypeEnum.TASK_STATUS),
        ]);
        setEnableReinitialize(false);
      }
    })();
  }, [itemID]);
  const submitHandler = async (values, formikHelpers) => {
    let payload = { ...values };
    await editDetailsHandler(payload, formikHelpers, {
      onSuccess: async (payload) => {
        returnURL ? navigate(returnURL) : navigate(PageLinks.task?.list);
      },
    });
  };
  return (
    <PageTemplate
      title={"Task"}
      scrollAll
      breadCrumb={[
        {
          name: "Task",
        },
      ]}
      backNavigation={{
        title: `Edit Task`,
        path: PageLinks.task.list,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={TaskFormikValues?.editInitialValue(details)}
        validationSchema={TaskFormikValues?.editValidationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={"flex flex-col gap-10"}>
              <EditTaskForm isLoading={enableReinitialize} />
              {enableReinitialize && <Loader />}
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default EditTaskPage;
