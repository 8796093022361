import { SystemDataInterface } from "../interfaces";

export interface DataContextInterface {
  loading: {
    isFieldListsLoading: boolean;
  };
  systemData: SystemDataInterface[];
  getSystemTypeDataHandler(type: string, parentId?: string);
  editSystemItemsHandler(values: any, props: any);
  deleteSystemItemHandler(itemId: string, parentID?: string);
}

export enum LoadingEnum {
  isFieldListsLoading = "isFieldListsLoading",
}
