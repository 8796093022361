import { useField } from "formik";
import "./styles.css";
import { MyCheckFieldInterface } from "./interfaces";
import { AiFillCheckCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { getIconHelper } from "helpers";
import { IconTypeEnum } from "interfaces";

export default function MyCheckInput({
  label,
  defaultValue,
  iconType,
  ...props
}: MyCheckFieldInterface) {
  const [field, meta, helpers] = useField(props);
  const isMultiple = Array.isArray(field?.value);
  defaultValue = String(defaultValue);
  const onClickHandler = () => {
    if (!isMultiple) {
      helpers.setValue(defaultValue);
      return;
    }
    let tempField = [...field?.value];
    let isAlready = tempField?.some((e) => e == defaultValue);
    if (isAlready) {
      helpers.setValue(
        tempField?.filter((e) => e !== defaultValue),
        false
      );
    } else {
      tempField?.push(defaultValue);
      helpers.setValue(tempField, false);
    }
  };
  const isSelected = () => {
    let status;
    if (isMultiple) {
      status = field?.value?.some((e) => e === defaultValue);
    } else {
      status = defaultValue == String(field?.value);
    }

    return status;
  };
  const RadioCheckIcon = getIconHelper(IconTypeEnum.SELECTED_RADIO);
  const RadioUnCheckIcon = getIconHelper(IconTypeEnum.UN_SELECTED_RADIO);
  let ActiveIcon = (
    <AiFillCheckCircle className={" text-tGreen  text-[20px]"} />
  );
  let InActiveIcon = (
    <AiOutlineCheckCircle className={"text-gray-300  text-[20px]"} />
  );
  if (iconType === "radio") {
    ActiveIcon = <RadioCheckIcon className={"text-primary text-[20px] "} />;
    InActiveIcon = (
      <RadioUnCheckIcon className={"text-gray-500 text-[20px] "} />
    );
  }
  return (
    <button
      type={"button"}
      className={
        "flex items-center gap-2 cursor-pointer select-none outline-tBlue"
      }
      onClick={onClickHandler}
    >
      <div className={""}>{isSelected() ? ActiveIcon : InActiveIcon}</div>
      <span className={"text-[14px]"}>{label}</span>
    </button>
  );
}
