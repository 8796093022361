import React, { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { FormConcludeButtons } from "assets";
import { PageTemplate } from "templates";
import { Loader } from "components";
import { PageLinks } from "routes";
import { usePageQueryHooks } from "useHooks";
import {
  FieldTypeEnum,
  InvoiceStatusEnum,
  LedgerFilterForEnum,
  ParamsStringEnum,
  QueryStringEnum,
} from "interfaces";
import { AppContext, InvoiceContext, OptionsContext } from "context";

import { getInvoicePageTitle, InvoiceFormikForm } from "../helpers";
import { InvoiceForm } from "../components";

function NewInvoicePage() {
  const [enableReinitialize, setEnableReinitialize] = useState(true);
  const [saveCreateNew, setSaveCreateNew] = useState(false);

  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { returnURL, navigateBackHandler } = usePageQueryHooks();
  const {
    handlers: { setError },
  } = useContext(AppContext);
  const params = useParams<ParamsStringEnum>();
  const isEdit = params.id;
  const userID = query.get(QueryStringEnum.USER_ID);

  const {
    fieldTypeOptions,
    getPropertyOptionsHandler,
    getOptionsHandler,
    getUserOptionsHandler,
    getLedgerOptionsHandler,
    getInvoiceHandler,
    getBankOptionsHandler,
  } = useContext(OptionsContext);

  const { details, editDetailsHandler, getDetailsHandler } =
    useContext(InvoiceContext);
  const invoiceType: any = params?.type;
  const pageTitle = getInvoicePageTitle(invoiceType);
  useEffect(() => {
    (async () => {
      try {
        if (!InvoiceStatusEnum[invoiceType]) {
          setError(true, "Invalid Page Status");
          navigate(PageLinks.invoice.list);
          return;
        }
        setEnableReinitialize(true);
        await Promise.all([
          isEdit && getDetailsHandler(isEdit),
          invoiceType === InvoiceStatusEnum.PURCHASE_RETURN &&
            getInvoiceHandler(InvoiceStatusEnum.ONLY_PURCHASE),
          invoiceType === InvoiceStatusEnum.PURCHASE &&
            getOptionsHandler(FieldTypeEnum.BILL_TYPE),
          invoiceType === InvoiceStatusEnum.SALES_RETURN &&
            getInvoiceHandler(InvoiceStatusEnum.ONLY_SALES),
          (invoiceType === InvoiceStatusEnum.PURCHASE ||
            invoiceType === InvoiceStatusEnum.PURCHASE_RETURN) &&
            getLedgerOptionsHandler({
              filterFor: LedgerFilterForEnum.PURCHASE_INVOICE,
            }),
          (invoiceType === InvoiceStatusEnum.SALES ||
            invoiceType === InvoiceStatusEnum.SALES_RETURN) &&
            getLedgerOptionsHandler({
              filterFor: LedgerFilterForEnum.SALES_INVOICE,
            }),
          getPropertyOptionsHandler(),
          getUserOptionsHandler(),
          getBankOptionsHandler(),
          getOptionsHandler(FieldTypeEnum.TAX),
          getOptionsHandler(FieldTypeEnum.PAYMENT_MODE),
        ]);
      } catch (err) {
        setError(true, err?.message || "Something went wrong");
      } finally {
        setEnableReinitialize(false);
      }
    })();
  }, [isEdit, invoiceType]);

  const submitHandler = async (values, formikHelpers) => {
    let payload = { ...values };
    payload.billItems = payload?.billItems?.map((e) => {
      let taxID = fieldTypeOptions[FieldTypeEnum.TAX]?.find(
        (f) => parseInt(f?.value) == e?.taxRate
      )?._id;
      return {
        ...e,
        taxRate: taxID,
      };
    });
    await editDetailsHandler(payload, formikHelpers, {
      onSuccess: async (payload) => {
        if (saveCreateNew) {
          setEnableReinitialize(true);
          formikHelpers.resetForm(
            InvoiceFormikForm.initialValues({
              invoiceType: invoiceType,
              client: {
                _id: userID || "",
              },
            })
          );
          setEnableReinitialize(false);
          return;
        }
        if (returnURL) {
          navigateBackHandler(returnURL);
        } else {
          navigate(PageLinks.invoice.list);
        }
      },
    });
  };
  return (
    <PageTemplate
      title={`${isEdit ? "Edit " : ""}Invoice`}
      scrollAll
      backNavigation={{
        title: pageTitle,
        path: PageLinks.invoice.list,
      }}
    >
      {enableReinitialize && <Loader />}
      <Formik
        initialValues={InvoiceFormikForm?.initialValues(
          isEdit
            ? details
            : {
                invoiceType: invoiceType,
                client: {
                  _id: userID || "",
                },
              }
        )}
        validationSchema={InvoiceFormikForm?.validationSchema}
        onSubmit={submitHandler}
      >
        {({ setFieldValue, handleSubmit }) => {
          return (
            <Form className={"flex flex-col gap-5"}>
              <InvoiceForm isFormLoading={enableReinitialize} />
              <FormConcludeButtons
                submitButton={{
                  title: `${isEdit ? "Update" : "Save"} `,
                  notSubmit: true,
                  handler() {
                    setFieldValue("isDraft", false);
                    setSaveCreateNew(false);
                    handleSubmit();
                  },
                }}
                saveAndCreateButton={
                  !isEdit && {
                    title: `Save & New`,
                    notSubmit: true,
                    handler() {
                      setFieldValue("isDraft", false);
                      setSaveCreateNew(true);
                      handleSubmit();
                    },
                  }
                }
                draftButton={{
                  title: `${isEdit ? "Update " : ""}Draft `,
                  handler() {
                    setSaveCreateNew(false);
                    setFieldValue("isDraft", true);
                    handleSubmit();
                  },
                }}
                cancelButton={{
                  title: "Cancel",
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default NewInvoicePage;
