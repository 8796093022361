import React from "react";
import { Route } from "react-router-dom";
import { EditTaskPage, NewTaskPage, TaskDetailsPage, TaskPage } from "./pages";
import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="services/tasks">
      <Route
        path={""}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.TASK_READ_TASK]}
          >
            <TaskPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"new/*"}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.TASK_ADD_TASK]}
          >
            <NewTaskPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.TASK_EDIT_TASK]}
          >
            <EditTaskPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`details/:${ParamsStringEnum.ID}/*`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.TASK_READ_TASK]}
          >
            <TaskDetailsPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
