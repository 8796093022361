import React from "react";
import { Route } from "react-router-dom";

import {
  ParamsStringEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { InventoryDetailsPage, InventoryPage, NewInventoryPage } from "./pages";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="services/">
      {/*    inventory*/}
      <Route
        path={"inventory"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.INVENTORY_READ_INVENTORY]}
            isForPage={true}
          >
            <InventoryPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"inventory/new/*"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.INVENTORY_ADD_INVENTORY]}
            isForPage={true}
          >
            <NewInventoryPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`inventory/edit/:${QueryStringEnum.ID}/*`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.INVENTORY_EDIT_INVENTORY]}
            isForPage={true}
          >
            <NewInventoryPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`inventory/details/:${ParamsStringEnum.ID}/*`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.INVENTORY_READ_INVENTORY]}
            isForPage={true}
          >
            <InventoryDetailsPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
