import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { DataContext } from "../context";
import { ResetPasswordFormik } from "../helpers";
import { MyButton, MyPasswordInput } from "components";
import { useNavigate } from "react-router-dom";

function ChangePassword() {
  const { resetPasswordHandler } = useContext(DataContext);
  const navigate = useNavigate();
  return (
    <Formik onSubmit={resetPasswordHandler} {...ResetPasswordFormik}>
      {({ isSubmitting, errors }) => {
        return (
          <Form className={"flex flex-col gap-5 text-[14px]"}>
            <div className={"flex flex-col gap-2"}>
              <span className={"text-[14px] text-tRed"}>
                {typeof errors?.error === "string" && `*${errors?.error}`}
              </span>

              <MyPasswordInput
                name={"password"}
                label={"New Password"}
                isRequired
                placeholder={"******"}
              />
            </div>
            <MyButton
              name={"Save Password"}
              type={"submit"}
              isLoading={isSubmitting}
            />
            <div>
              <span>Remembered Password?</span>
              <span
                onClick={() => navigate("/login")}
                className={"text-primary font-bold cursor-pointer"}
              >
                {" "}
                Login
              </span>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ChangePassword;
