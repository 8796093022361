import React from "react";
import { Route } from "react-router-dom";
import { InvoicePage, NewInvoicePage, InvoiceDetailsPage } from "./pages";
import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import { AccessVerifier } from "templates";
import "./index.css";
function Index() {
  return (
    <Route path="finance/">
      {/*Invoice*/}
      <Route
        path={"invoice/*"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_READ_INVOICE]}
            isForPage={true}
          >
            <InvoicePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`invoice/new/:${ParamsStringEnum.TYPE}/*`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_ADD_INVOICE]}
            isForPage={true}
          >
            <NewInvoicePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`invoice/edit/:${ParamsStringEnum.TYPE}/:${ParamsStringEnum.ID}/*`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_EDIT_INVOICE]}
            isForPage={true}
          >
            <NewInvoicePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`invoice/details/:${ParamsStringEnum.ID}/*`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_READ_INVOICE]}
            isForPage={true}
          >
            <InvoiceDetailsPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
