import React, { useContext, useEffect } from "react";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { FormConcludeButtons } from "assets";
import { PageTemplate } from "templates";
import { ParamsStringEnum } from "interfaces";
import { AppContext, ManageNotificationContext } from "context";

import { ManageNotificationFormikValues } from "../helpers";
import { usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";
import { ManageNotificationForm } from "../components";

function EditNotificationPage() {
  const navigate = useNavigate();
  const params = useParams<ParamsStringEnum>();
  const { returnURL } = usePageQueryHooks();

  const {
    handlers: { setLoading },
  } = useContext(AppContext);

  const { details, editDetailsHandler, getDetailsHandler } = useContext(
    ManageNotificationContext
  );
  const isEdit = params.id;
  useEffect(() => {
    (async () => {
      setLoading(true);
      if (isEdit) {
        await getDetailsHandler(isEdit);
      }
      setLoading(false);
    })();
  }, [isEdit]);

  const submitHandler = async (values, formikHelpers) => {
    await editDetailsHandler(values, formikHelpers, {
      onSuccess: async (payload) => {
        if (returnURL) {
          navigate(returnURL);
        } else {
          navigate(PageLinks.manageNotification.list);
        }
      },
      onError(error?: any) {},
    });
  };
  return (
    <PageTemplate
      title={"Settings"}
      scrollAll
      breadCrumb={[
        {
          name: `Manage Notification`,
        },
      ]}
      backNavigation={{
        title: `Manage Notification Details`,
        path: PageLinks.manageNotification.list,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={ManageNotificationFormikValues?.initialValues(details)}
        validationSchema={ManageNotificationFormikValues?.validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={"flex flex-col gap-5"}>
              <ManageNotificationForm />
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default EditNotificationPage;
