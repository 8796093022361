import { useSearchParams } from "react-router-dom";
import { DateRangeOptionEnum, QueryStringEnum } from "interfaces";
import { checkIsDate, thisMonthEnd, thisMonthStart } from "helpers";

export const useGetStartEndDateRangeHooks = () => {
  const [query] = useSearchParams();
  let rangeType = query?.get(QueryStringEnum.RANGE_TYPE);
  const fiscalYear = query.get(QueryStringEnum.FISCAL_YEAR);
  const isAllTime = rangeType === DateRangeOptionEnum.ALL_TIME;
  let startDate = query?.get(QueryStringEnum.START_DATE);
  let endDate = query?.get(QueryStringEnum.END_DATE);

  let compareRangeType = query?.get(QueryStringEnum.COMPARE_RANGE_TYPE);
  const compareFiscalYear = query.get(QueryStringEnum.COMPARE_FISCAL_YEAR);
  const isCompareAllTime = rangeType === DateRangeOptionEnum.ALL_TIME;
  let compareStartDate = query?.get(QueryStringEnum.COMPARE_START_DATE);
  let compareEndDate = query?.get(QueryStringEnum.COMPARE_END_DATE);

  if (!startDate) {
    startDate = thisMonthStart;
  }
  if (!endDate) {
    endDate = thisMonthEnd;
  }
  if (!rangeType) {
    rangeType = DateRangeOptionEnum.THIS_MONTH;
  }
  if (isAllTime) {
    startDate = "";
    endDate = "";
  }

  if (isCompareAllTime) {
    compareStartDate = "";
    compareEndDate = "";
  }

  startDate = checkIsDate(startDate) ? startDate : "";
  endDate = checkIsDate(endDate) ? endDate : "";
  compareStartDate = checkIsDate(compareStartDate) ? compareStartDate : "";
  compareEndDate = checkIsDate(compareEndDate) ? compareEndDate : "";
  return {
    startDate,
    endDate,
    rangeType,
    isAllTime,
    fiscalYear,
    compareRangeType,
    compareStartDate,
    compareEndDate,
    isCompareAllTime,
    compareFiscalYear,
  };
};
