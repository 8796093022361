import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { LeftRightHeader } from "assets";
import { PropertyUnitDocumentForm } from "content";
import { UnitDataContext } from "context";

function UnitEditDocuments() {
  const navigate = useNavigate();
  const { details, editDetailsHandler, getDetailsHandler } =
    useContext(UnitDataContext);
  const submitHandler = (values, actions) => {
    editDetailsHandler({ _id: details?._id, documents: values }, actions, {
      onSuccess(payload?: any): any {
        getDetailsHandler(details?._id);
        navigate(-1);
      },
    });
  };
  return (
    <div className={"flex flex-col gap-5"}>
      <LeftRightHeader title={"Edit Documents"} />
      <PropertyUnitDocumentForm
        cancelHandler={() => {
          navigate(-1);
        }}
        values={{ ...details?.documents }}
        submitHandler={submitHandler}
      />
    </div>
  );
}

export default UnitEditDocuments;
