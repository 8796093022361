import React, { useContext } from "react";
import {
  AddressText,
  FormWrapper,
  LeftRightHeader,
  NameValueText,
} from "assets";
import {
  DocumentView,
  EmptyMessageComponent,
  ImageViewCard,
  MyButton,
} from "components";
import { Outlet, useNavigate } from "react-router-dom";

import { useDateHook } from "useHooks";
import { getCurrency } from "helpers";
import { BookingDataContext } from "context";
import { IconTypeEnum, TaxTypeEnum } from "interfaces";
import { PageLinks } from "routes";

function BookingDetailsTab() {
  const { details: bookingDetails, isDetailsLoading } =
    useContext(BookingDataContext);
  const navigate = useNavigate();
  const { getDateHandler } = useDateHook();
  if (!isDetailsLoading && !bookingDetails?._id) {
    return (
      <div className={"mt-5"}>
        <EmptyMessageComponent message={"No data"} />
      </div>
    );
  }
  return (
    <>
      <div className={"flex flex-col gap-5"}>
        <div>
          <LeftRightHeader
            title={"General"}
            rightChildren={
              <>
                <MyButton
                  iconType={IconTypeEnum.ADD}
                  name={"Edit General"}
                  onClick={() => {
                    navigate(PageLinks.bookings.edit(bookingDetails?._id));
                  }}
                  isOutline
                />
              </>
            }
          />
        </div>
        <FormWrapper
          header={{
            title: {
              name: "",
            },
          }}
        >
          <div className={"flex flex-col gap-2"}>
            <div className={"grid grid-cols-3 gap-5"}>
              <NameValueText
                name={"Property"}
                isLoading={isDetailsLoading}
                value={
                  <div className={"flex gap-2 items-center"}>
                    <span className={"text-tBlue font-bold text-[14px]"}>
                      {bookingDetails?.property?.propertyID}
                    </span>
                    <AddressText
                      isOneLine
                      address={bookingDetails?.property?.address}
                    />
                  </div>
                }
              />
              <NameValueText
                name={"Unit"}
                isLoading={isDetailsLoading}
                value={
                  <div className={"flex gap-2 items-center"}>
                    <span className={"text-tBlue font-bold text-[14px]"}>
                      {bookingDetails?.unit?.unitID}
                    </span>
                    <span>{bookingDetails?.unit?.name}</span>
                  </div>
                }
              />
            </div>
          </div>
        </FormWrapper>

        <FormWrapper
          header={{
            title: {
              name: "Rent",
            },
          }}
        >
          <div className={"flex flex-col gap-2"}>
            <div className={"grid grid-cols-3 gap-5"}>
              <NameValueText
                name={`Rent Amount (${getCurrency()})`}
                isLoading={isDetailsLoading}
                value={bookingDetails?.rent?.rentCost}
              />
            </div>{" "}
            <div className={"grid grid-cols-3 gap-5"}>
              <NameValueText
                name={`Tax Type`}
                isLoading={isDetailsLoading}
                value={bookingDetails?.rent?.taxType}
              />
              {bookingDetails?.rent?.taxType !== TaxTypeEnum.EXEMPTED && (
                <NameValueText
                  name={"Tax Rate"}
                  isLoading={isDetailsLoading}
                  value={bookingDetails?.rent?.taxRate?.label}
                />
              )}
            </div>
          </div>
        </FormWrapper>

        <FormWrapper
          header={{
            title: {
              name: "Security Deposit",
            },
          }}
        >
          <div className={"flex flex-col gap-2"}>
            <div className={"grid grid-cols-3 gap-5"}>
              <NameValueText
                name={"Charge Type"}
                isLoading={isDetailsLoading}
                value={bookingDetails?.securityDeposit?.chargeType?.label}
              />
              <NameValueText
                name={`Amount (${getCurrency()})`}
                isLoading={isDetailsLoading}
                value={bookingDetails?.securityDeposit?.securityDepositedAmount}
              />
              <NameValueText
                name={`Deposit Date`}
                isLoading={isDetailsLoading}
                value={getDateHandler({
                  date: bookingDetails?.securityDeposit?.securityDepositedOn,
                })}
              />
            </div>
            <div>
              <NameValueText
                name={`Remarks`}
                isLoading={isDetailsLoading}
                value={bookingDetails?.securityDeposit?.securityDepositedNote}
              />
            </div>
          </div>
        </FormWrapper>
        {bookingDetails?.agreementDocuments?.length > 0 && (
          <FormWrapper
            header={{
              title: {
                name: "Agreement Documents",
              },
            }}
          >
            <div className={"flex  gap-5 items-start"}>
              {bookingDetails?.agreementDocuments?.map((e, key) => {
                return <DocumentView key={key} file={e} isLoading={false} />;
              })}
            </div>
          </FormWrapper>
        )}

        <FormWrapper
          header={{
            title: {
              name: "Signature",
            },
          }}
        >
          <div className={"flex  gap-5 items-start"}>
            <ImageViewCard
              fileName={bookingDetails?.tenantSignature?.signature}
            />
          </div>
        </FormWrapper>
      </div>
      <Outlet />
    </>
  );
}

export default BookingDetailsTab;
