import React, { useContext, useEffect } from "react";

import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { FormConcludeButtons } from "assets";
import { AppContext, PrivilegeContext } from "context";
import { PageTemplate } from "templates";
import { ParamsStringEnum } from "interfaces";
import { PageLinks } from "routes";

import { RolesForm } from "../../components";
import { RolesFormik } from "../../helpers";

function EditRolesPage() {
  const { details, editDetailsHandler, getDetailsHandler } =
    useContext(PrivilegeContext);
  const {
    handlers: { setError },
  } = useContext(AppContext);
  const params = useParams<ParamsStringEnum>();
  const isEdit = params.id;
  const navigate = useNavigate();
  useEffect(() => {
    if (isEdit) {
      (async () => {
        await getDetailsHandler(isEdit);
      })();
    }
  }, [isEdit]);
  const onSubmitHandler = async (values, helpers) => {
    let isAtLeastOneSelected = false;
    Object.keys(values?.granted).forEach((e) => {
      if (typeof values?.granted[e] === "object") {
        Object.keys(values?.granted[e]).forEach((f) => {
          if (values?.granted[e]?.[f]) {
            isAtLeastOneSelected = true;
            return;
          }
        });
      }
    });
    if (!isAtLeastOneSelected) {
      setError(true, "at least one permission must be selected.");
      return;
    }
    await editDetailsHandler(values, helpers, {
      onSuccess: async (payload) => {
        navigate(PageLinks.role.list);
      },
    });
  };
  return (
    <PageTemplate
      title={"Roles & Permissions"}
      backNavigation={{
        title: `${isEdit ? "Edit" : "Add"} Roles & Permissions`,
      }}
      scrollAll
    >
      <Formik
        enableReinitialize
        initialValues={RolesFormik?.initialValues(isEdit && details)}
        validationSchema={RolesFormik?.validationSchema}
        onSubmit={onSubmitHandler}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={"flex flex-col gap-5"}>
              <RolesForm />
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                  handler: () => {
                    navigate(PageLinks.role.list);
                  },
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default EditRolesPage;
