import React from "react";
import { ToggleContentWrapper } from "../components";
import {
  DashboardApiTypeEnum,
  TenancyDashboardResponseFormat,
} from "interfaces";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function TenancyStatsContent({ isToggle }) {
  return (
    <ToggleContentWrapper
      toggle={isToggle}
      title={"Tenancies"}
      getDataType={DashboardApiTypeEnum.tenancy}
      haveDateFilter={false}
      havePropertyFilter={true}
    >
      {(data: TenancyDashboardResponseFormat) => {
        const isEmpty =
          !data?.activeTenancy?.count ||
          !data?.futureTenancy?.count ||
          !data?.pastTenancy?.count;
        const pieData = {
          labels: ["Active Tenancies", "Future Tenancies", "Expired Tenancies"],
          datasets: [
            {
              label: "Tenancies",
              data: [
                data?.activeTenancy?.count,
                data?.futureTenancy?.count,
                data?.pastTenancy?.count,
              ],
              backgroundColor: ["#8990f6", "#fcdc85", "#c385e1"],
              borderColor: ["white", "white", "white"],
            },
          ],
        };
        if (isEmpty) {
          return (
            <div className={"text-center mt-5 text-gray-500"}>
              No Tenancies Found
            </div>
          );
        }
        return (
          <div className={"grid grid-cols-5 gap-2 pt-5"}>
            <div className={"col-span-3 flex items-center justify-center"}>
              <div className={"h-[200px] w-[200px]"}>
                <Doughnut
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    cutout: 60,
                  }}
                  data={pieData}
                />
              </div>
            </div>
            <div className={"col-span-2 flex flex-col gap-5"}>
              <div className={"flex flex-col gap-1"}>
                <span className={"text-gray-500 text-[14px]"}>
                  Active Tenancies ({data?.activeTenancy?.count || 0})
                </span>
                <span className={"font-semibold text-[18px]"}>
                  {data?.activeTenancy?.ratio}%
                </span>
                <div
                  className={"relative w-full h-[8px] rounded-full bg-gray-200"}
                >
                  <div
                    style={{
                      width: `${data?.activeTenancy?.ratio}%`,
                    }}
                    className={`absolute h-full w-[${data?.activeTenancy?.ratio}%] bg-[#3944F7] rounded-full`}
                  ></div>
                </div>
              </div>
              <div className={"flex flex-col gap-1"}>
                <span className={"text-gray-500 text-[14px]"}>
                  Future Tenancies ({data?.futureTenancy?.count || 0})
                </span>
                <span className={"font-semibold text-[18px]"}>
                  {data?.futureTenancy?.ratio}%
                </span>
                <div
                  className={"relative w-full h-[8px] rounded-full bg-gray-200"}
                >
                  <div
                    style={{
                      width: `${data?.futureTenancy?.ratio}%`,
                    }}
                    className={`absolute h-full bg-[#EDC126] rounded-full`}
                  ></div>
                </div>
              </div>
              <div className={"flex flex-col gap-1"}>
                <span className={"text-gray-500 text-[14px]"}>
                  Expired Tenancies ({data?.pastTenancy?.count || 0})
                </span>
                <span className={"font-semibold text-[18px]"}>
                  {data?.pastTenancy?.ratio}%
                </span>
                <div
                  className={"relative w-full h-[8px] rounded-full bg-gray-200"}
                >
                  <div
                    style={{
                      width: `${data?.pastTenancy?.ratio}%`,
                    }}
                    className={`absolute h-full z-10  bg-[#8D3DAF] rounded-full`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </ToggleContentWrapper>
  );
}

export default TenancyStatsContent;
