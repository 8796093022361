import React, { useContext, useEffect, useMemo, useState } from "react";
import { OptionsContext } from "context";
import { FieldArray, useFormikContext } from "formik";
import { TenancyFormInterface } from "../interfaces";
import {
  BankInterface,
  FieldTypeEnum,
  IconTypeEnum,
  TaxTypeEnum,
  UserRoleEnum,
} from "interfaces";
import {
  MyButton,
  MyCheckInput,
  MyRadioInput,
  MySelectField,
  MyTextAreaInput,
  MyTextField,
  MyUploadDocument,
} from "components";

import {
  decodeBankOptionsHandler,
  decodeDynamicFieldHandler,
  decodePropertyOptionsHandler,
  decodeUnitOptionsHandler,
  decodeUserOptionsHandler,
  getCurrency,
} from "helpers";
import { FormToggleWrapper, FormWrapper, NameValueText } from "assets";
import { usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";
import moment from "moment";

function TenancyForm() {
  const [activatedForm, setActivatedForm] = useState("");
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const setActivatedFormHandler = (name: string) => {
    setActivatedForm((e) => (e == name ? null : name));
  };

  const {
    propertyOptions,
    unitOptions,
    userOptions,
    fieldTypeOptions,
    bankOptions,
    getUnitOptionsHandler,
  } = useContext(OptionsContext);
  const { values, setFieldValue } =
    useFormikContext<TenancyFormInterface<string>>();
  const bankDetails: BankInterface = useMemo(() => {
    return bankOptions?.find((e) => e?._id === values?.bank);
  }, [values?.bank]);
  useEffect(() => {
    if (fieldTypeOptions[FieldTypeEnum.CHARGE_TYPE]?.length > 0) {
      setFieldValue(
        "chargeType",
        fieldTypeOptions[FieldTypeEnum.CHARGE_TYPE][0]?._id,
        false
      );
    }
  }, [fieldTypeOptions[FieldTypeEnum.CHARGE_TYPE]]);
  useEffect(() => {
    if (values?.recurringFrequency) {
      const selectedFrequencyName = fieldTypeOptions[
        FieldTypeEnum.YEARLY_FREQUENCY
      ]?.find((e) => e?._id === values?.recurringFrequency);

      const addRecurringDue =
        selectedFrequencyName?.value?.toLocaleString() === "custom quarterly";
      if (addRecurringDue) {
        setFieldValue("recurringDue", ["", "", ""], false);
      } else {
        setFieldValue("recurringDue", [], false);
      }
    }
  }, [values?.recurringFrequency]);
  return (
    <div className={"flex flex-col gap-10"}>
      <div
        className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start  gap-10`}
      >
        <MySelectField
          autoFocus
          name={"property"}
          label={"Property"}
          isRequired
          option={{
            selectOptions: decodePropertyOptionsHandler(propertyOptions),
            onChangeCallback(currentValue?: any) {
              setFieldValue("unit", "", false);
            },
          }}
        />
        <MySelectField
          disabled={!values?.property}
          isClearable
          name={"unit"}
          label={"Unit"}
          fetchOptionsHandler={() => getUnitOptionsHandler(values?.property)}
          isRequired
          option={{
            selectOptions: decodeUnitOptionsHandler(unitOptions),
          }}
        />
      </div>
      <div
        className={`grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1 items-start  gap-10`}
      >
        <MySelectField
          name={"tenant"}
          label={"Tenant"}
          isRequired
          option={{
            selectOptions: decodeUserOptionsHandler(userOptions),
          }}
        />
        <div className={"mt-7 sm:mt-0"}>
          <MyButton
            isOutline
            name={"Add New"}
            onClick={() =>
              navigateWithReturnUrlHandler(
                PageLinks.spaceUser.new,
                `type=${UserRoleEnum.TENANT}`
              )
            }
            iconType={IconTypeEnum.ADD}
          />
        </div>
      </div>
      <div
        className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start  gap-10 items-start`}
      >
        <MySelectField
          name={"tenancyType"}
          label={"Tenancy Type"}
          isRequired
          isCreatable={{
            type: FieldTypeEnum.TENANCY_TYPE,
          }}
          option={{
            selectOptions: decodeDynamicFieldHandler(
              fieldTypeOptions[FieldTypeEnum.TENANCY_TYPE]
            ),
          }}
        />
        <MyTextField
          label={"Agreement Date"}
          name={"agreementOn"}
          type={"date"}
          isRequired
        />
        <MyTextField
          isRequired
          label={"Rent Commencement Date"}
          name={"rentCommencementOn"}
          type={"date"}
        />
      </div>
      <FormWrapper
        header={{
          title: {
            name: "Tenancy Duration/Contractual Term",
            isRequired: true,
          },
        }}
      >
        <div
          className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start   gap-10`}
        >
          <MyTextField
            name={"durationQuantity"}
            placeholder={"Eg.10"}
            label={`Duration`}
            type={"number"}
            isRequired
          />
          <MySelectField
            label={"Frequency"}
            name={"durationFrequency"}
            isRequired
            isCreatable={{
              type: FieldTypeEnum.YEAR_FREQUENCY,
            }}
            option={{
              selectOptions: decodeDynamicFieldHandler(
                fieldTypeOptions[FieldTypeEnum.YEAR_FREQUENCY]
              ),
            }}
          />
        </div>
      </FormWrapper>
      <FormWrapper
        header={{
          title: {
            name: "Rent",
          },
        }}
      >
        <div className={"flex flex-col gap-10"}>
          <div
            className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start   gap-10`}
          >
            <MyTextField
              type={"number"}
              name={"rentCost"}
              placeholder={"Eg.5000"}
              label={`Rent Amount (${getCurrency()})`}
              isRequired
            />
            <MySelectField
              label={"Rent Frequency"}
              name={"rentFrequency"}
              isRequired
              isCreatable={{
                type: FieldTypeEnum.ANNUAL_FREQUENCY,
              }}
              option={{
                selectOptions: decodeDynamicFieldHandler(
                  fieldTypeOptions[FieldTypeEnum.ANNUAL_FREQUENCY]
                ),
              }}
            />
          </div>
          <div className={`flex items-center gap-10`}>
            <MyCheckInput
              defaultValue={TaxTypeEnum.EXEMPTED}
              name={"taxType"}
              label={`Exempted`}
              iconType={"radio"}
            />
            <MyCheckInput
              defaultValue={TaxTypeEnum.EXCLUSIVE}
              name={"taxType"}
              label={`Tax Exclusive`}
              iconType={"radio"}
            />
            <MyCheckInput
              defaultValue={TaxTypeEnum.INCLUSIVE}
              name={"taxType"}
              label={`Tax Inclusive`}
              iconType={"radio"}
            />
          </div>
          {values?.taxType !== TaxTypeEnum.EXEMPTED && (
            <div
              className={
                "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start gap-10"
              }
            >
              <MySelectField
                option={{
                  selectOptions: decodeDynamicFieldHandler(
                    fieldTypeOptions[FieldTypeEnum.TAX]
                  ),
                }}
                isRequired
                label={"Tax Rate"}
                name={"taxRate"}
              />
            </div>
          )}
        </div>
      </FormWrapper>
      {/*  Initial Rent Payment*/}
      <FormWrapper
        header={{
          title: {
            name: "Initial Rent Amount",
          },
        }}
      >
        <div className={"flex flex-col gap-10"}>
          <div
            className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start  gap-10`}
          >
            <MyTextField
              label={"Due Date"}
              name={"initialRentDueDate"}
              type={"date"}
            />
            <MyTextField
              name={"initialRentCost"}
              type={"number"}
              placeholder={"eg. 65000"}
              label={`Amount (${getCurrency()})`}
            />
          </div>
          <div
            className={
              "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start"
            }
          >
            <div className={"col-span-2"}>
              <MyTextAreaInput
                label={"Remarks/Note"}
                name={"initialRentNote"}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
      {/*  Recurring Payment*/}
      <FormWrapper
        header={{
          title: {
            name: "Recurring Payment",
          },
        }}
      >
        <div className={"flex flex-col gap-10"}>
          <div
            className={
              "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start gap-10"
            }
          >
            <MySelectField
              name={"recurringPaymentTerms"}
              label={"Payment Terms"}
              isRequired
              isCreatable={{
                type: FieldTypeEnum.TENANCY_PAYMENT_TERMS,
              }}
              option={{
                selectOptions: decodeDynamicFieldHandler(
                  fieldTypeOptions[FieldTypeEnum.TENANCY_PAYMENT_TERMS]
                ),
              }}
            />
            <MySelectField
              name={"recurringFrequency"}
              label={"Frequency"}
              isRequired
              isCreatable={{
                type: FieldTypeEnum.YEARLY_FREQUENCY,
              }}
              option={{
                selectOptions: decodeDynamicFieldHandler(
                  fieldTypeOptions[FieldTypeEnum.YEARLY_FREQUENCY]
                ),
              }}
            />
          </div>
          <FieldArray
            name={"recurringDue"}
            render={({ push, remove }) => {
              return (
                <div>
                  <div
                    className={
                      "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start gap-10"
                    }
                  >
                    {values?.recurringDue?.map((e, key) => {
                      return (
                        <div className={"flex flex-1 items-center  gap-10"}>
                          <div className={"flex-1"}>
                            <MyTextField
                              label={"Due Date"}
                              name={`recurringDue.${key}`}
                              type={"date"}
                            />
                          </div>
                        </div>
                      );
                    })}
                    {values?.initialRentDueDate && (
                      <div className={"input_container"}>
                        <div className={"input_label "}>Due Date</div>
                        <div
                          className={
                            "flex cursor-pointer items-center bg-gray-100 border-[1px] border-[#C0C0C0] text-[16px] pl-6 min-h-[48px] rounded-full"
                          }
                        >
                          {moment(values?.initialRentDueDate)
                            .add(1, "year")
                            .subtract(1, "day")
                            .format("MM/DD/YYYY")}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
          />
          <div
            className={
              "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start gap-10"
            }
          >
            <div className={"col-span-2"}>
              <MyTextAreaInput
                label={"Remarks"}
                placeholder={"Type here..."}
                name={"recurringRemarks"}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
      {/* Opening Balance*/}
      <FormToggleWrapper
        activatedForm={activatedForm}
        formName={"1"}
        title={{
          name: "Opening Balance",
        }}
        onClick={setActivatedFormHandler}
      >
        <div
          className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start  gap-10`}
        >
          <MyTextField
            label={"Due Date"}
            name={"openingBalanceDue"}
            type={"date"}
          />
          <MyTextField
            type={"number"}
            label={`Amount (${getCurrency()})`}
            name={"openingBalanceAmount"}
            placeholder={`eg. 65000`}
          />
          <MySelectField
            name={"balanceType"}
            label={"Balance Type"}
            isCreatable={{
              type: FieldTypeEnum.OPENING_BALANCE_TYPE,
            }}
            option={{
              selectOptions: decodeDynamicFieldHandler(
                fieldTypeOptions[FieldTypeEnum.OPENING_BALANCE_TYPE]
              ),
            }}
          />
        </div>
      </FormToggleWrapper>

      {/*    Utilities charges*/}
      <FormToggleWrapper
        activatedForm={activatedForm}
        formName={"2"}
        title={{
          name: "Utilities Charges",
        }}
        onClick={setActivatedFormHandler}
      >
        <div
          className={
            "grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 items-start justify-start gap-5"
          }
        >
          {fieldTypeOptions[FieldTypeEnum.TENANCY_UTILITIES_CHARGE]?.map(
            (e, key) => {
              return (
                <div className={"flex items-start"} key={key}>
                  <MyCheckInput
                    defaultValue={e?._id}
                    label={e?.label}
                    name={"utilitiesCharges"}
                  />
                </div>
              );
            }
          )}
        </div>
      </FormToggleWrapper>

      {/* Security Deposit*/}
      <FormToggleWrapper
        activatedForm={activatedForm}
        formName={"3"}
        title={{
          name: "Security Deposit",
        }}
        onClick={setActivatedFormHandler}
      >
        <div
          className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start  gap-10`}
        >
          <MySelectField
            name={"chargeType"}
            label={"Charges Type"}
            disabled={true}
            option={{
              selectOptions: decodeDynamicFieldHandler(
                fieldTypeOptions[FieldTypeEnum.CHARGE_TYPE]
              ),
            }}
          />
          <MyTextField
            label={`Amount (${getCurrency()})`}
            name={"securityDepositedAmount"}
            placeholder={"eg. 65000.00"}
            type={"number"}
          />
          <MyTextField
            label={"Deposit Date"}
            name={"securityDepositedOn"}
            type={"date"}
          />
          <div className={"col-span-2 sm:col-span-1"}>
            <MyTextAreaInput
              label={"Remarks/Note"}
              name={"securityDepositedNote"}
            />
          </div>
        </div>
      </FormToggleWrapper>
      {/* Other charges*/}
      <FormToggleWrapper
        activatedForm={activatedForm}
        formName={"4"}
        title={{
          name: "Other Charges",
        }}
        onClick={setActivatedFormHandler}
      >
        <div
          className={
            "grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 items-start justify-start gap-5"
          }
        >
          {fieldTypeOptions[FieldTypeEnum.TENANCY_OTHER_CHARGE]?.map(
            (e, key) => {
              return (
                <div className={"flex items-start"} key={key}>
                  <MyCheckInput
                    name={"otherCharges"}
                    defaultValue={e?._id}
                    label={e?.label}
                  />
                </div>
              );
            }
          )}
        </div>
      </FormToggleWrapper>
      {/* Bank Account Details*/}
      <FormToggleWrapper
        activatedForm={activatedForm}
        formName={"5"}
        title={{
          name: "Bank Account Details",
        }}
        onClick={setActivatedFormHandler}
      >
        <div className={"flex flex-col gap-10"}>
          <div
            className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start  gap-10`}
          >
            <MySelectField
              name={"bank"}
              label={"Select Bank"}
              isRequired
              option={{
                selectOptions: decodeBankOptionsHandler(bankOptions),
              }}
            />
          </div>
          {values?.bank && (
            <div
              className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start  gap-10`}
            >
              <NameValueText
                alignDirection={"row"}
                name={"Bank Name"}
                value={bankDetails?.label || "-"}
              />
              <NameValueText
                alignDirection={"row"}
                name={"Account Number"}
                value={bankDetails?.accountNumber || "-"}
              />
            </div>
          )}
        </div>
      </FormToggleWrapper>
      <FormToggleWrapper
        activatedForm={activatedForm}
        formName={"7"}
        title={{
          name: "Advance Options",
        }}
        onClick={setActivatedFormHandler}
      >
        <div
          className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start  gap-10`}
        >
          <MyRadioInput
            name={"notifyLateFeeChangeToTenant"}
            label={"Notify Late Fee Change To Tenant"}
          />
          <MyRadioInput
            name={"maximumMonthlyLateFees"}
            label={"Maximum Monthly Late Fees"}
          />
          <MyRadioInput
            name={"maximumDailyLateFees"}
            label={"Maximum Daily Late Fees"}
          />
          <MyRadioInput
            name={"minimumBalanceToApplyLateFee"}
            label={"Minimum Balance To Apply Late Fee"}
          />
          <MyRadioInput
            name={"invoiceMailBeforeDueDate"}
            label={"Invoice Mail Before Due Date"}
          />
        </div>
      </FormToggleWrapper>
      {/* Agreement Document*/}
      <FormToggleWrapper
        activatedForm={activatedForm}
        formName={"8"}
        title={{
          name: "Agreement Document",
        }}
        onClick={setActivatedFormHandler}
      >
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1"}>
          <div className={"col-span-2"}>
            <MyUploadDocument name={"agreementDocuments"} />
          </div>
        </div>
      </FormToggleWrapper>
    </div>
  );
}

export default TenancyForm;
