import React from "react";
import { AddressInterface } from "interfaces";
import { getPropertyNameHandler } from "helpers";
import { usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";

interface PropsInterface {
  ID: string;
  address?: AddressInterface;
  name?: string;
  type?: "property" | "unit";
  isOneLine?: boolean;
  propertyID: string;
  unitID?: string;
}
function PropertyUnitTableText({
  ID,
  address,
  name,
  type = "property",
  isOneLine,
  propertyID,
  unitID,
}: PropsInterface) {
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const onClickHandler = () => {
    if (propertyID && unitID && type === "unit") {
      navigateWithReturnUrlHandler(PageLinks.unit?.details(propertyID, unitID));
      return;
    }
    if (type === "property") {
      navigateWithReturnUrlHandler(PageLinks.property?.details(propertyID));
    }
  };
  return (
    <div
      onClick={onClickHandler}
      className={`flex ${
        isOneLine ? "flex-row items-center gap-2" : "flex-col"
      }  items-start text-[14px] text-gray-500`}
    >
      {ID && <span className={"text-[#2680eb]"}>{ID}</span>}
      {type == "unit" ? (
        name && <span className={"whitespace-nowrap"}>{name}</span>
      ) : (
        <span className={"whitespace-nowrap"}>
          {getPropertyNameHandler(address)}
        </span>
      )}
    </div>
  );
}

export default PropertyUnitTableText;
