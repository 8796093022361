import React, { useContext, useState } from "react";
import { BillingAddressContext } from "context";
import { getIconHelper } from "helpers";
import { IconTypeEnum, PackageCreateOrderPayloadInterface } from "interfaces";
import { BoxModal } from "components";
import { useFormikContext } from "formik";
import { FormConcludeButtons } from "assets";

import { EditBillingAddressModal } from "./index";
import { BillingAddressCard } from "../components";

function BillingAddressModal({ closeHandler }) {
  const { values, setFieldValue } =
    useFormikContext<PackageCreateOrderPayloadInterface>();
  const { allData } = useContext(BillingAddressContext);
  const AddIcon = getIconHelper(IconTypeEnum.ADD);
  const [editBillingAddress, setEditBillingAddress] = useState(false);
  const [editBillingID, setEditBillingID] = useState(null);

  return (
    <BoxModal title={"My Billing Address"} closeHandler={closeHandler}>
      <div className={"flex flex-col gap-5 "}>
        {allData?.length < 1 && (
          <div
            onClick={() => setEditBillingAddress(true)}
            className={
              "flex items-center justify-center mt-10 gap-2 cursor-pointer"
            }
          >
            <AddIcon /> <span>Add New Billing Address</span>
          </div>
        )}
        <div className={"grid grid-cols-2 gap-5"}>
          {allData?.map((e, key) => {
            let isSelected = values?.billingAddress === e?._id;
            return (
              <BillingAddressCard
                onEditHandler={(id) => {
                  setEditBillingID(id);
                  setEditBillingAddress(true);
                }}
                key={key}
                details={e}
                isSelected={isSelected}
                onClickHandler={() => {
                  setFieldValue("billingAddress", e?._id);
                }}
              />
            );
          })}
        </div>
        {editBillingAddress && (
          <EditBillingAddressModal
            id={editBillingID || null}
            closeHandler={() => {
              setEditBillingAddress(false);
              setEditBillingID(null);
            }}
          />
        )}
        {allData?.length > 0 && (
          <FormConcludeButtons
            leftChildren={
              <div
                onClick={() => setEditBillingAddress(true)}
                className={"flex items-center gap-2 cursor-pointer"}
              >
                <AddIcon /> <span>Add New Billing Address</span>
              </div>
            }
            submitButton={{
              title: "Save",
              notSubmit: true,
              handler() {
                closeHandler();
              },
            }}
          />
        )}
      </div>
    </BoxModal>
  );
}

export default BillingAddressModal;
