import React, { useContext } from "react";
import { AppContext } from "../context";
import moment from "moment";
import { NameFormatEnum } from "../interfaces";

function UseDateHooks() {
  const {
    state: { spaceSettings },
  } = useContext(AppContext);
  const getStaffFullName = (payload: { first: string; last: string }) => {
    let name = `${payload?.first || "Untitled"} ${payload?.last || ""}`;
    if (spaceSettings?.nameFormat === NameFormatEnum.LAST_NAME_FIRST) {
      name = `${payload?.last || "Untitled"} ${payload?.first || ""}`;
    }
    return name;
  };
  const getDateHandler = ({
    date,
    haveTime,
    defaultFormat,
  }: {
    date: string | Date;
    haveTime?: boolean;
    defaultFormat?: string;
  }) => {
    if (!date) {
      return "-";
    }
    // Desired time zone
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    date = new Date(
      date.toLocaleString("en-US", {
        timeZone: timeZone,
      })
    );

    if (defaultFormat) {
      return moment(date)?.format(defaultFormat);
    }
    return moment(date)?.format(
      `${spaceSettings?.dateFormat || "YYYY-MM-DD"} ${
        haveTime ? "hh:mm a" : ""
      } `
    );
  };
  return {
    getDateHandler,
    getStaffFullName,
  };
}

export default UseDateHooks;
