import * as yup from "yup";
import {
  AccountStatementRowInterface,
  JournalEntry,
  JournalInterface,
} from "interfaces";

export const JournalFormikForm = {
  entries: (entries?: Partial<JournalEntry>) => {
    return {
      name: entries?.name || "",
      account: entries?.account?._id || "",
      debit: entries?.debit || "",
      credit: entries?.credit || "",
    };
  },
  initialValues: (values?: Partial<JournalInterface>) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      name: values?.name || "",
      isDraft: values?.isDraft || false,
      transactionDate: values?.transactionDate || new Date().toISOString(),
      property: values?.property?._id || "",
      documents: values?.documents || [],
      unit: values?.unit?._id || "",
      entries:
        values?.entries?.length > 0
          ? values?.entries?.map((e) => {
              return JournalFormikForm?.entries(e);
            })
          : [JournalFormikForm?.entries({}), JournalFormikForm?.entries({})],
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().required("required"),
    transactionDate: yup.string().required("required"),
    entries: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string().required(" "),
          account: yup.string().required(" "),
          debit: yup.number().min(0, " "),
          credit: yup.number().min(0, " "),
        })
      )
      .min(1, "required"),
  }),
};

export const calculateJournalTotalAccount = (
  values: JournalInterface<string>
) => {
  let debitSubTotal = 0;
  let creditSubTotal = 0;

  values?.entries?.forEach((e) => {
    if (e?.debit) {
      let amount: any = e?.debit;
      debitSubTotal += parseFloat(amount);
    } else if (e?.credit) {
      let amount: any = e?.credit;
      creditSubTotal += parseFloat(amount);
    }
  });
  return {
    debitSubTotal,
    creditSubTotal,
  };
};

export class GetContentRowFormat {
  title: string;
  value: number;
  firstCompareTimeValue: number;
  secondCompareTimeValue: number;
  percentageCompareValue: number;
  deviationCompareValue: number;
  isLess: boolean;
  showBorder: boolean;
  account?: string;
  constructor(
    values: AccountStatementRowInterface,
    title?: string,
    showBorder?: boolean
  ) {
    this.title = values?.title || title;
    this.value = values?.amount || 0;
    this.account = values?.account;
    this.firstCompareTimeValue = values?.firstCompareTimeValue || 0;
    this.secondCompareTimeValue = values?.secondCompareTimeValue || 0;
    this.percentageCompareValue = values?.percentageCompareValue || 0;
    this.deviationCompareValue = values?.deviationCompareValue || 0;
    this.isLess = values?.isLess;
    this.showBorder = showBorder;
  }
}
