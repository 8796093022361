import React, { createContext, useContext } from "react";

import { AppContext } from "context";
import { Api } from "helpers";
import { ApiUrl } from "services";
import { BookingInterface, HandlerCallbackInterface } from "interfaces";
import {
  useConfirmHook,
  useDataContextHooks,
  usePaginationQueryHooks,
} from "useHooks";

import { DataContextInterface } from "interfaces";
import { FormikHelpers } from "formik/dist/types";

export const BookingDataContext = createContext<
  DataContextInterface<BookingInterface>
>({
  isLoading: false,
  allData: [],
  isDetailsLoading: false,
  totalArchiveDocs: 0,
  details: null,
  totalDocs: 0,
  getAllDataHandlers(query?: any) {},
  getDetailsHandler(itemId: string, isFromEdit?: boolean) {},

  archiveHandler(
    itemId: string,
    status: boolean,
    callback?: HandlerCallbackInterface
  ) {},
  deleteHandler(itemId: string, callback?: HandlerCallbackInterface) {},

  editDetailsHandler(
    values,
    actions: FormikHelpers<unknown>,
    callback?: HandlerCallbackInterface
  ) {},
});

function DataContextProvider({ children }) {
  const { confirm } = useConfirmHook();
  const { isArchive, paginationQuery } = usePaginationQueryHooks();

  const {
    isArchiveLoaded,
    totalArchiveDocs,
    setTotalArchiveDocs,
    isDetailsLoading,
    setDetailsLoading,
    details,
    setLoading,
    isLoading,
    setTotalDocs,
    totalDocs,
    setDetails,
    allData,
    setAllData,
  } = useDataContextHooks();

  const { handlers } = useContext(AppContext);
  const { getApi, postApi, putApi, deleteApi } = Api();

  //  handler
  const bookingHandlers = {
    getAllDataHandlers: async (query) => {
      try {
        setLoading(true);
        let res: any;
        res = await getApi(ApiUrl.booking.get_booking, {
          ...query,
          ...paginationQuery,
        });
        if (isArchive) {
          setTotalArchiveDocs(res?.data?.totalDocs);
        } else {
          setTotalDocs(res?.data?.totalDocs);
        }
        setAllData(res?.data?.docs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getDetailsHandler: async (itemID: string, isFromEdit) => {
      try {
        if (!isFromEdit) {
          setDetailsLoading(true);
          setDetails(null);
        }
        const res: any = await getApi(
          ApiUrl.booking.get_bookingDetails + itemID
        );
        setDetails(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
        handlers.setPageErrorHandler(true, err?.message);
      } finally {
        setDetailsLoading(false);
      }
    },
    editDetailsHandler: async (
      values,
      props,
      callback: HandlerCallbackInterface
    ) => {
      try {
        typeof props?.setSubmitting === "function"
          ? props?.setSubmitting(true)
          : handlers?.setLoading(true);
        const shouldEdit = values?._id;
        let res;
        if (shouldEdit) {
          res = await putApi(ApiUrl.booking.put_editBooking, values);
        } else {
          res = await postApi(ApiUrl.booking.post_addBooking, values);
        }
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        typeof props?.setSubmitting === "function"
          ? props?.setSubmitting(false)
          : handlers?.setLoading(false);
      }
    },
    deleteHandler: async (itemsId, callback: HandlerCallbackInterface) => {
      try {
        const isConfirm = await confirm(
          "Are you sure you want to delete this item?",
          "Delete",
          "Cancel"
        );
        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res: any = await deleteApi(
          ApiUrl.booking.delete_booking + itemsId,
          {}
        );
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
    archiveHandler: async (
      itemsId,
      status: boolean,
      callback: HandlerCallbackInterface
    ) => {
      try {
        const isConfirm = await confirm("Are you sure?", "Archive", "Cancel");

        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res: any = await putApi(
          ApiUrl.booking.put_archiveUnArchiveBooking +
            `${itemsId}/${status ? "off" : "on"}`,
          {}
        );
        if (isArchiveLoaded) {
          // isAlready archived
          if (status) {
            setTotalArchiveDocs(totalArchiveDocs - 1);
            setTotalDocs(totalDocs + 1);
          } else {
            setTotalArchiveDocs(totalArchiveDocs + 1);
          }
        }
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
  };

  const contextValue = {
    totalDocs,
    totalArchiveDocs,
    allData,
    details,
    isDetailsLoading,
    isLoading,
    ...bookingHandlers,
  };
  return (
    <BookingDataContext.Provider value={contextValue}>
      {children}
    </BookingDataContext.Provider>
  );
}

export default DataContextProvider;
