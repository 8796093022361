import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { MyButton, TabComponent, TableCardComponent } from "components";
import { PageTemplate } from "templates";
import {
  IconTypeEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
  TenancyStatusEnum,
  UserRoleEnum,
} from "interfaces";

import { TableFilterCard, TenancyTableColumn } from "content";
import { ReportContext, TenancyDataContext } from "context";
import { useAuthorizationHook, usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function TenancyPage() {
  const {
    allData,
    totalDocs,
    getAllDataHandlers,
    isLoading,
    totalArchiveDocs,
  } = useContext(TenancyDataContext);

  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { isArchive } = usePaginationQueryHooks();
  let activeTab = query.get(QueryStringEnum?.ACTIVE_TAB);
  const unitID = query.get(QueryStringEnum?.UNIT_ID);
  const propertyID = query.get(QueryStringEnum?.PROPERTY_ID);
  const { generateTenancyReportHandler } = useContext(ReportContext);

  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAdd = checkCanAccessHandler([
    SpaceAccessListEnum.TENANCY_ADD_TENANCY,
  ]);
  const canRead = checkCanAccessHandler(
    [SpaceAccessListEnum.TENANCY_READ_TENANCY],
    [UserRoleEnum.LANDLORD, UserRoleEnum.CUSTOM]
  );
  if (!activeTab) {
    activeTab = TenancyStatusEnum.ACTIVE;
  } else if (activeTab === "all") {
    activeTab = "";
  }

  let pageQuery = {
    ...(activeTab ? { status: activeTab } : {}),
    unit: unitID,
    property: propertyID,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, unitID, propertyID, activeTab]);
  const Tabs = [
    {
      title: `Active`,
      pathName: "",
    },
    {
      title: `Future Tenancies`,
      pathName: TenancyStatusEnum.FUTURE,
    },
    {
      title: `Expired Tenancies`,
      pathName: TenancyStatusEnum.EXPIRED,
    },
    canRead && {
      title: `Draft`,
      pathName: TenancyStatusEnum.DRAFT,
    },
    {
      title: `All`,
      pathName: "all",
    },
  ];

  const tableCardProps = {
    column: TenancyTableColumn(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      rightChildren: canAdd && (
        <MyButton
          iconType={IconTypeEnum.ADD}
          onClick={() => {
            navigate(PageLinks.tenancy.new);
          }}
          name={"Add Tenancies"}
        />
      ),
      leftChildren: <TableFilterCard haveProperty={true} haveUnit={true} />,
      aboveTableChildren: !isArchive && <TabComponent tabs={Tabs} />,
    },
  };
  return (
    <PageTemplate
      title={"Tenancies"}
      breadCrumb={[
        {
          name: "Tenancies",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        exportXlsx={{
          generateHandler() {
            generateTenancyReportHandler(pageQuery);
          },
        }}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default TenancyPage;
