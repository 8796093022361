import { apiConfig } from "configs";
import { ApiUrl } from "services";

import {
  AddressInterface,
  DateRangeOptionEnum,
  FieldTypeEnum,
  TaxTypeEnum,
  UserRoleEnum,
} from "../interfaces";
import moment from "moment";

export const downloadBlobFileHandler = (
  fileBlob,
  fileName,
  fileFormat?: "pdf"
) => {
  let url = window.URL.createObjectURL(new Blob([fileBlob]));

  if (fileFormat === "pdf") {
    url = window.URL.createObjectURL(
      new Blob([fileBlob], {
        type: "application/pdf",
      })
    );
  }
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();
  URL.revokeObjectURL(url);
  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const downloadLink = (url, fileName) => {
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.setAttribute("download", fileName);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
};
export const navigateLinkHandler = (url) => {
  const link = document.createElement("a");
  link.href = url;
  // Append to html link element page
  document.body.appendChild(link);
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
};
export const getStaffFullName = (payload: { first: string; last: string }) => {
  return `${payload?.first || "Untitled"} ${payload?.last || ""}`;
};
export function capitalizeFirstLetter(name: string) {
  if (!name) {
    return name;
  }
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export function capitalizeAfterSpace(str: string) {
  if (!str) return;
  return str
    ?.toLowerCase()
    .replaceAll("_", " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
export const decodeRoleNameHandler = (
  name: UserRoleEnum,
  isPlural?: boolean
) => {
  switch (name) {
    case UserRoleEnum.ADMIN:
      return isPlural ? "Admins" : "Admin";
    case UserRoleEnum.LANDLORD:
      return isPlural ? "Landlords" : "Landlord";
    case UserRoleEnum.LENDER:
      return isPlural ? "Lenders" : "Lender";
    case UserRoleEnum.OWNER:
      return isPlural ? "Owners" : "Owner";
    case UserRoleEnum.SUPPLIER:
      return isPlural ? "Suppliers" : "Supplier";
    case UserRoleEnum.TENANT:
      return isPlural ? "Tenants" : "Tenant";
    case UserRoleEnum.CUSTOM:
      return isPlural ? "Users" : "User";
    case UserRoleEnum.NONE:
      return isPlural ? "Users" : "User";
    default:
      return isPlural ? "Users" : "User";
  }
};
export const getInputDateFormat = (
  date: string | Date,
  type: "date" | "datetime-local" = "date"
) => {
  if (date) {
    if (type == "date") {
      return new Date(date)?.toISOString()?.split("T")[0];
    } else {
      if (date?.toString().includes(".")) {
        return new Date(date)?.toISOString()?.split(".")[0];
      } else {
        return date;
      }
    }
  }
};

export const getCurrency = (isLabel?: boolean) => {
  if (isLabel) {
    return process.env.REACT_APP_CURRENCY_LABEL || "GBP";
  }

  return process.env.REACT_APP_CURRENCY || "£";
};

export const getImageUrlHandler = (imageURl?: string) => {
  if (!imageURl) return null;
  return apiConfig.default + ApiUrl.utils.get_Image + imageURl;
};

export function separateWithComma(number: string | number) {
  if (!number) {
    return "0.00";
  }
  // Convert the number to a string
  let formattedNumber = number.toString();

  // Check if the number has a decimal point
  if (formattedNumber.includes(".")) {
    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = formattedNumber.split(".");

    // Format the integer part by adding commas
    formattedNumber = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Keep only two decimal places for the decimal part
    formattedNumber += "." + decimalPart.substring(0, 2);
  } else {
    // Format the number by adding commas and appending '.00'
    formattedNumber =
      formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00";
  }

  return formattedNumber;
}

export const checkIsDate = (value: any) => {
  // Create a new Date object from the provided string
  const date: any = new Date(value);

  // Check if the date is valid
  // The date is considered valid if it's not NaN and the string representation
  // of the date is not equal to "Invalid Date"
  return !isNaN(date) && date.toString() !== "Invalid Date";
};

export function isDR(balance: number): boolean {
  return balance > 0 ? true : false;
}

// Last Month
export const lastMonthStart = moment(new Date())
  .subtract(1, "month")
  .startOf("month")
  .format("YYYY-MM-DD");
export const lastMonthEnd = moment(new Date())
  .subtract(1, "month")
  .endOf("month")
  .format("YYYY-MM-DD");

// This Month
export const thisMonthStart = moment(new Date())
  .startOf("month")
  .format("YYYY-MM-DD");
export const thisMonthEnd = moment(new Date())
  .endOf("month")
  .format("YYYY-MM-DD");

// Last Year
export const lastYearStart = moment(new Date())
  .subtract(1, "year")
  .startOf("year")
  .format("YYYY-MM-DD");

export const lastYearEnd = moment(new Date())
  .subtract(1, "year")
  .endOf("year")
  .format("YYYY-MM-DD");

// This Year
export const thisYearStart = moment(new Date())
  .startOf("year")
  .format("YYYY-MM-DD");

export const thisYearEnd = moment(new Date())
  .endOf("year")
  .format("YYYY-MM-DD");

export const getTaxNameHandler = (type: TaxTypeEnum, labelOnly?: boolean) => {
  switch (type) {
    case TaxTypeEnum.EXCLUSIVE:
      return labelOnly ? "Exclusive" : "Tax Exclusive";
    case TaxTypeEnum.INCLUSIVE:
      return labelOnly ? "Inclusive" : "Tax Inclusive";
    case TaxTypeEnum.EXEMPTED:
      return labelOnly ? "Exempted" : "Tax Exempted";
    default:
      return "Invalid Tax Type";
  }
};

export const separateWithBracket = (amount) => {
  if (!amount) {
    return "0.00";
  }
  return amount < 0
    ? `(${separateWithComma(Math.abs(amount))})`
    : `${separateWithComma(amount)}`;
};

// for invoice
export const calcInvoiceBillHandler = (
  qty: number = 0,
  rate: number = 0,
  disRate: number = 0,
  taxRate: any = 0,
  taxType: TaxTypeEnum
) => {
  const isTaxExempted = taxType === TaxTypeEnum.EXEMPTED;
  const isTaxInclusive = taxType === TaxTypeEnum.INCLUSIVE;

  const subTotal = qty * rate;
  const disAmount = subTotal * (disRate / 100);
  const totalAfterDiscount = subTotal - disAmount;
  let taxableAmount = isTaxInclusive
    ? totalAfterDiscount / (1 + taxRate / 100)
    : totalAfterDiscount;

  let taxAmount = taxableAmount * (parseFloat(taxRate) / 100);
  if (isTaxExempted) {
    taxableAmount = totalAfterDiscount;
    taxAmount = 0;
  }
  const total = taxableAmount + taxAmount;
  return {
    total,
    subTotal: totalAfterDiscount,
    taxAmount,
    disAmount,
  };
};

export const getBalanceSheetTitleHandler = ({
  startDate,
  endDate,
  rangeType,
  isAllTime,
  isForBalanceSheet,
}: {
  startDate?: string | Date;
  endDate?: string | Date;
  rangeType?: string;
  isAllTime?: boolean;
  isForBalanceSheet?: boolean;
}) => {
  let accountDateTitle;
  endDate = checkIsDate(endDate) ? endDate : null;
  startDate = checkIsDate(startDate) ? startDate : null;
  if (
    rangeType === DateRangeOptionEnum.THIS_MONTH ||
    rangeType === DateRangeOptionEnum.LAST_MONTH
  ) {
    accountDateTitle = `For the Month Ended ${
      endDate ? moment(endDate).format("DD MMM, YYYY") : "-"
    }`;
  } else if (
    rangeType === DateRangeOptionEnum.THIS_YEAR ||
    rangeType === DateRangeOptionEnum.LAST_YEAR ||
    rangeType === DateRangeOptionEnum.FISCAL_YEAR
  ) {
    accountDateTitle = `For the Year Ended ${
      endDate ? moment(endDate).format("DD MMM, YYYY") : "-"
    }`;
  } else if (isAllTime) {
    accountDateTitle = `Till ${moment().format("DD MMM, YYYY")}`;
  } else {
    accountDateTitle = `For the Period ${
      startDate ? moment(startDate).format("DD MMM, YYYY") : "-"
    } to ${endDate ? moment(endDate).format("DD MMM, YYYY") : "-"}`;
  }
  if (isForBalanceSheet) {
    accountDateTitle = `As On ${
      endDate
        ? moment(endDate).format("DD MMM, YYYY")
        : moment().format("DD MMM, YYYY")
    }`;
  }
  return { accountDateTitle };
};

export const getPropertyNameHandler = (address: AddressInterface) => {
  if (!address) {
    return;
  }
  return [address?.address1].join(",");
};
export const getAddressNameHandler = (address: AddressInterface) => {
  if (!address) {
    return;
  }
  return [
    address?.address1,
    address?.address2,
    address?.county?.name,
    address?.postalCode?.toUpperCase(),
  ].join(",");
};

export function getImageFileDetails(filename: string) {
  const imageExtensions = [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".bmp",
    ".tiff",
    ".webp",
  ];
  if (!filename) {
    return { isImage: false, extension: "", name: "" };
  }
  const fileExtension = filename.split(".").pop().toLowerCase();
  let decode = filename?.split("/");
  let name = decode[decode?.length - 1];

  return {
    isImage: imageExtensions?.includes("." + fileExtension),
    extension: fileExtension,
    name,
  };
}

export function validateDateHandler(
  dateInput,
  dateValidation: {
    minDate?: Date;
    maxDate?: Date;
    pastOnly?: boolean;
    futureOnly?: boolean;
  }
) {
  const selectedDate = new Date(dateInput);
  const currentDate = new Date();
  let message,
    isError = false;
  if (dateValidation.minDate) {
    if (selectedDate < new Date(dateValidation.minDate)) {
      message =
        "Date must be greater than or equal to " + dateValidation.minDate;
      isError = true;
    }
  }

  if (dateValidation.maxDate) {
    if (selectedDate > new Date(dateValidation.maxDate)) {
      message = "Date must be less than or equal to " + dateValidation.maxDate;
      isError = true;
    }
  }

  if (dateValidation.pastOnly && selectedDate > currentDate) {
    message = "Please select a past date";
    isError = true;
  }

  if (dateValidation.futureOnly && selectedDate < currentDate) {
    message = "Please select a future date";
    isError = true;
  }
  return {
    message,
    isError,
  };
}

export const DynamicListConstantList = {
  // key: isDynamic
  [FieldTypeEnum.DOCUMENT_TYPE]: true,
  [FieldTypeEnum.UNTITLED]: false,
  [FieldTypeEnum.NAME_FORMAT]: false,
  [FieldTypeEnum.DATE_FORMAT]: false,
  [FieldTypeEnum.PROPERTY_TYPE]: true,
  [FieldTypeEnum.PROPERTY_AMENITIES]: true,
  [FieldTypeEnum.OWNER_TYPE]: false,
  [FieldTypeEnum.OWNERSHIP_TYPE]: false,
  [FieldTypeEnum.UNIT_AMENITIES]: true,
  [FieldTypeEnum.FURNISHED_TYPE]: true,
  [FieldTypeEnum.ROOM_TYPE]: true,
  [FieldTypeEnum.UNIT_SIZE]: false,
  [FieldTypeEnum.SELECT_FLOOR]: true,
  [FieldTypeEnum.ANNUAL_FREQUENCY]: false,
  [FieldTypeEnum.YEARLY_FREQUENCY]: false,
  [FieldTypeEnum.YEAR_FREQUENCY]: false,
  [FieldTypeEnum.TENANCY_TYPE]: false,
  [FieldTypeEnum.TENANCY_PAYMENT_TERMS]: true,
  [FieldTypeEnum.OPENING_BALANCE_TYPE]: true,
  [FieldTypeEnum.TENANCY_UTILITIES_CHARGE]: true,
  [FieldTypeEnum.CHARGE_TYPE]: false,
  [FieldTypeEnum.TENANCY_OTHER_CHARGE]: false,
  [FieldTypeEnum.TENANCY_ADVANCE_OPTION]: false,
  [FieldTypeEnum.TAX]: true,
  [FieldTypeEnum.BILL_TYPE]: true,
  [FieldTypeEnum.PAYMENT_MODE]: true,
  [FieldTypeEnum.INVENTORY_STATUS]: false,
  [FieldTypeEnum.INVENTORY_ITEM]: true,
  [FieldTypeEnum.INVENTORY_UNIT]: true,
  [FieldTypeEnum.INVENTORY_ITEM_CONDITION]: true,
  [FieldTypeEnum.REPAIR_PRIORITY]: false,
  [FieldTypeEnum.REPAIR_STATUS]: false,
  [FieldTypeEnum.TASK_PRIORITY]: false,
  [FieldTypeEnum.TASK_STATUS]: false,
  [FieldTypeEnum.MORTGAGE_TYPE]: true,
  [FieldTypeEnum.INTEREST_TYPE]: true,
  [FieldTypeEnum.FINANCE_TYPE]: true,
  [FieldTypeEnum.PROFESSION]: true,
  [FieldTypeEnum.CANDIDATE_STATUS]: false,
  [FieldTypeEnum.BOOKING_STATUS]: false,
  [FieldTypeEnum.METER_TYPE]: true,
  [FieldTypeEnum.REMINDER_BEFORE_AFTER]: false,
};
