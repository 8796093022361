import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const config = {
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
};
const stripePromise = loadStripe(config.stripePublishableKey);

export default function Stripe(props) {
  const options = {
    clientSecret: props.client_secret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {props.children}
    </Elements>
  );
}
