import { InventoryInterface, InventoryItemInterface } from "../../interfaces";
import * as yup from "yup";

export const InventoryFormikValues = {
  inventoryItemValues: (values?: InventoryItemInterface) => {
    return {
      isGeneral: values?.isGeneral || false,
      ...(values?.roomID ? { roomID: values?.roomID } : {}),
      ...(values?.roomSerialNumber
        ? { roomSerialNumber: values?.roomSerialNumber }
        : {}),
      ...(values?.roomItemName ? { roomItemName: values?.roomItemName } : {}),
      item: "",
      unit: "",
      unitQuantity: "",
      inventoryItemCondition: "",
      notes: "",
      images: [],
    };
  },
  initialValues: (values?: Partial<InventoryInterface>) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      property: values?.property?._id || "",
      unit: values?.unit?._id || "",
      tenant: values?.tenant?._id || "",
      inventoryStatus: values?.inventoryStatus?._id || "",
      inspectionDate: values?.inspectionDate || "",

      items:
        values?.items?.map((e) => {
          return {
            ...e,
            item: e?.item?._id,
            unit: e?.unit?._id,
            inventoryItemCondition: e?.inventoryItemCondition?._id,
          };
        }) || [],
      overallComments: values?.overallComments,
      documents: values?.documents || [],
      ownerSignature: {
        participant:
          typeof values?.ownerSignature?.participant === "string"
            ? values?.ownerSignature?.participant
            : values?.ownerSignature?.participant?._id || "",
        signedDate: values?.ownerSignature?.signedDate || "",
        signature: values?.ownerSignature?.signature || "",
      },
      tenantSignature: {
        // participant:
        //   typeof values?.tenantSignature?.participant === "string"
        //     ? values?.tenantSignature?.participant
        //     : values?.tenantSignature?.participant?._id || "",
        signedDate: values?.tenantSignature?.signedDate || "",
        signature: values?.tenantSignature?.signature || "",
      },
    };
  },
  validationSchema: yup.object().shape({
    property: yup.string().required("required"),
    unit: yup.string().required("required"),
    items: yup.array().of(
      yup.object().shape({
        item: yup.string().required("required"),
        unit: yup.string().required("required"),
        inventoryItemCondition: yup.string().required("required"),
        unitQuantity: yup.number().min(0, "invalid").required("required"),
      })
    ),
    inventoryStatus: yup.string().required("required"),
    inspectionDate: yup.string().required("required"),
    tenant: yup.string(),
    ownerSignature: yup.object().shape({
      participant: yup.string().required("required"),
    }),
    tenantSignature: yup.object().shape({
      participant: yup.string(),
    }),
  }),
};
