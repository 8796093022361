import React, { useContext, useEffect } from "react";

import { useDateHook, usePageQueryHooks } from "useHooks";
import { useParams } from "react-router-dom";
import {
  IconTypeEnum,
  InvoiceBillInterface,
  InvoicePaymentInterface,
  InvoiceSettlementSourceEnum,
  InvoiceStatusEnum,
  ParamsStringEnum,
  TableColumnInterface,
  TaxTypeEnum,
} from "interfaces";
import { PageTemplate } from "templates";
import { PageLinks } from "routes";
import { InvoiceContext, PDFContext } from "context";
import { FormWrapper, NameValueText, PropertyUnitTableText } from "assets";
import {
  DocumentView,
  FileViewCard,
  MyButton,
  MyCheckButton,
  TableComponent,
} from "components";
import { getCurrency, getTaxNameHandler, separateWithComma } from "helpers";

import { calcBillAccount, getInvoicePageTitle } from "../helpers";

function InvoiceDetailsPage() {
  const { getDateHandler, getStaffFullName } = useDateHook();
  const { downloadInvoicePDF } = useContext(PDFContext);
  const params = useParams<ParamsStringEnum>();
  const { details, getDetailsHandler, isDetailsLoading } =
    useContext(InvoiceContext);
  const { returnURL, navigateWithReturnUrlHandler } = usePageQueryHooks();
  const isSales = details?.invoiceType === InvoiceStatusEnum.SALES;
  const isSalesReturn = details?.invoiceType === InvoiceStatusEnum.SALES_RETURN;
  const isPurchase = details?.invoiceType === InvoiceStatusEnum.PURCHASE;
  const isPurchaseReturn =
    details?.invoiceType === InvoiceStatusEnum.PURCHASE_RETURN;
  const title = getInvoicePageTitle(details?.invoiceType);
  useEffect(() => {
    getDetailsHandler(params.id);
  }, [params.id]);

  return (
    <PageTemplate
      scrollAll
      title={"Invoice"}
      backNavigation={{
        title: `Invoice Details (${title})`,
        path: PageLinks.invoice.list,
        overRideReturnURL:
          returnURL && returnURL === PageLinks.ledger.listLedgerTransaction,
      }}
    >
      <div className={"grid gap-10"}>
        <div className={"flex flex-col gap-5"}>
          <div
            className={`grid ${
              isPurchase ? "grid-cols-7" : "grid-cols-6"
            } gap-5 md:grid-cols-2 sm:grid-cols-1`}
          >
            <div className={"col-span-2"}>
              <NameValueText
                isLoading={isDetailsLoading}
                name={`${
                  isSales || isSalesReturn ? "Client" : "Supplier"
                } Name`}
                value={getStaffFullName(details?.client?.name)}
              />
            </div>

            <NameValueText
              isLoading={isDetailsLoading}
              value={`${getDateHandler({ date: details?.invoiceDate })}`}
              name={"Date"}
            />
            {(isSales || isPurchase) && (
              <NameValueText
                isLoading={isDetailsLoading}
                value={`${getDateHandler({ date: details?.dueDate })}`}
                name={"Due Date"}
              />
            )}
            <NameValueText
              isLoading={isDetailsLoading}
              value={details?.invoiceID || "-"}
              name={`${
                isPurchase
                  ? "Bill Number"
                  : isSalesReturn || isPurchaseReturn
                  ? "Credit Note"
                  : "Invoice#"
              }`}
            />

            <NameValueText
              isLoading={isDetailsLoading}
              value={details?.reference || "-"}
              name={"Reference"}
            />

            {isPurchase && (
              <NameValueText
                isLoading={isDetailsLoading}
                name={"Bill Type"}
                value={details?.billType?.label || "-"}
              />
            )}

            {(isSalesReturn || isPurchaseReturn) && (
              <NameValueText
                onValueClickHandler={() => {
                  navigateWithReturnUrlHandler(
                    PageLinks.invoice.details(details?.invoiceReferenceID?._id)
                  );
                }}
                isLoading={isDetailsLoading}
                name={isSalesReturn ? `Sales Invoice` : "Purchase Order"}
                value={details?.invoiceReferenceID?.invoiceID || "-"}
              />
            )}
          </div>
        </div>
        <div className={"flex justify-between"}>
          <div>
            {details?._id && (
              <MyButton
                iconType={IconTypeEnum.PDF}
                colorType={"danger"}
                name={"Download PDF"}
                onClick={() => downloadInvoicePDF(details?._id)}
              />
            )}
          </div>
          <div>
            <MyCheckButton
              checkIcon={"radio"}
              isChecked={true}
              label={getTaxNameHandler(details?.taxType)}
            />
          </div>
        </div>
        <div className={"overflow-y-scroll"}>
          <TableComponent
            column={BillItemsFormValues(details?.taxType)}
            dataSource={details?.billItems}
            loading={{
              isLoading: isDetailsLoading,
              loadingRow: 5,
            }}
          />
        </div>
        {/*bills*/}
        <div
          className={
            "grid grid-cols-3 md:grid-cols-1 sm:grid-cols-1 sm:mt-1 mt-5"
          }
        >
          <div className={"col-span-2 "}>
            <div className={"flex flex-col gap-2"}>
              {details?.documents?.map((e, key) => {
                return <DocumentView file={e} key={key} isLoading={false} />;
              })}
            </div>
            <NameValueText
              name={"Remarks"}
              value={details?.description || "-"}
              alignDirection={"col"}
            />
          </div>
          {/*bills*/}
          <div className={"flex flex-col  gap-5 text-[14px]"}>
            <div className={"grid grid-cols-3"}>
              <span className={"col-span-2"}>SubTotal (After Discount): </span>
              <span className={"text-right"}>
                {getCurrency()}{""}
                {separateWithComma(details?.subTotalAmount || 0)}
              </span>
            </div>
            {details?.taxType !== TaxTypeEnum.EXCLUSIVE && (
              <>
                {details?.billItems?.map((e, key) => {
                  const { taxAmount } = calcBillAccount(
                    e?.quantity,
                    e?.rate,
                    e?.discountPercent,
                    parseInt(e?.taxRate?.value) || 0,
                    details?.taxType
                  );
                  return (
                    <div className={"grid grid-cols-3"} key={key}>
                      <span className={"col-span-2"}>
                        {details?.taxType === TaxTypeEnum.INCLUSIVE
                          ? "Inclusive "
                          : "Total "}
                        TAX {e?.taxRate?.value}%
                      </span>
                      <span className={"text-right"}>
                        {getCurrency()}{separateWithComma(taxAmount || 0)}
                      </span>
                    </div>
                  );
                })}
              </>
            )}

            <div
              className={
                "grid text-[18px] grid-cols-3 pt-5 border-t-2 border-t-gray-300"
              }
            >
              <span className={"col-span-2"}>Total Amount</span>
              <span className={"font-bold text-tBlue text-right"}>
                {getCurrency()}{separateWithComma(details?.grandTotal || 0)}
              </span>
            </div>
            {details?.settlement?.map((e, key) => {
              return (
                <div className={"grid text-[14px] grid-cols-3 "} key={key}>
                  <span
                    className={"col-span-2 cursor-pointer hover:underline"}
                    onClick={() =>
                      navigateWithReturnUrlHandler(
                        e?.source === InvoiceSettlementSourceEnum.RETURN
                          ? PageLinks.invoice.details(e?.invoiceID)
                          : PageLinks.reconciliation.details(
                              e?.reconciliationID
                            )
                      )
                    }
                  >
                    {e?.title}
                  </span>
                  <span className={"font-semibold text-tBlue text-right"}>
                    {getCurrency()}{separateWithComma(e?.amount) || 0}
                  </span>
                </div>
              );
            })}
            {details?.settlement?.length > 0 && (
              <div
                className={
                  "grid text-[18px] grid-cols-3 pt-5 border-t-2 border-t-gray-300"
                }
              >
                <span className={"col-span-2"}>Amount Due</span>
                <span className={"font-bold text-tBlue text-right"}>
                  {getCurrency()}{separateWithComma(details?.due) || 0}
                </span>
              </div>
            )}
          </div>
        </div>

        {/*  Mode of receipt*/}
        {details?.payments?.length > 0 && (
          <FormWrapper
            header={{
              title: {
                name: "Mode of Receipt",
              },
            }}
          >
            <div className={"flex flex-col gap-5"}>
              {details?.payments?.map((e, key) => {
                return <ReceiptModeForm payment={e} key={key} />;
              })}
            </div>
          </FormWrapper>
        )}

        <FormWrapper
          header={{
            title: {
              name: "More",
            },
          }}
        >
          <div className={"flex flex-col gap-10"}>
            <NameValueText
              name={"Issued By"}
              value={getStaffFullName(details?.createdBy?.name)}
            />
            {details?.documents?.length > 0 && (
              <div className={"input_container"}>
                <span className={"input_label font-bold"}>Documents</span>
                <div
                  className={
                    "grid grid-cols-5 gap-5 md:grid-cols-3 sm:grid-cols-1"
                  }
                >
                  {details?.documents?.map((e, key) => {
                    return <FileViewCard filePath={e} canDownload key={key} />;
                  })}
                </div>
              </div>
            )}
            {details?.description && (
              <NameValueText
                name={"Remarks/Notes"}
                value={details?.description}
              />
            )}
          </div>
        </FormWrapper>
      </div>
    </PageTemplate>
  );
}

function BillItemsFormValues(
  taxType
): TableColumnInterface<InvoiceBillInterface>[] {
  return [
    {
      title: "Property",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.property?.propertyID}
            address={renderData?.property?.address}
            propertyID={renderData?.property?._id}
          />
        );
      },
    },
    {
      title: "Unit",
      render(renderData) {
        return (
          <PropertyUnitTableText
            type={"unit"}
            unitID={renderData?.unit?._id}
            propertyID={renderData?.property?._id}
            ID={renderData?.unit?.unitID}
            name={renderData?.unit?.name}
          />
        );
      },
    },
    {
      title: "Account Head",
      render(renderData) {
        return (
          <span className={"whitespace-nowrap"}>
            {renderData?.account?.name || "n/a"}
          </span>
        );
      },
    },
    {
      title: "Description",
      render(renderData) {
        return <p className={"text-justify"}>{renderData?.description}</p>;
      },
    },
    {
      title: "Quantity",
      titleStyleClassName: "text-right",
      render(renderData) {
        return (
          <div className={"flex flex-1 items-end justify-end"}>
            <span>{renderData?.quantity}</span>
          </div>
        );
      },
    },
    {
      title: "Rate",
      titleStyleClassName: "text-right",

      render(renderData) {
        return (
          <div className={"flex flex-1 items-end justify-end"}>
            <span className={"text-right"}>{renderData?.rate}</span>
          </div>
        );
      },
    },
    {
      title: "Discount (in %)",
      titleStyleClassName: "text-right",

      render(renderData) {
        return (
          <div className={"flex flex-1 items-end justify-end"}>
            <span>{renderData?.discountPercent}</span>
          </div>
        );
      },
    },
    taxType !== TaxTypeEnum.EXEMPTED && {
      title: "TAX Rate",
      titleStyleClassName: "text-right",
      render(renderData) {
        return (
          <div className={"flex flex-1 items-end justify-end"}>
            <span className={"text-right"}>
              {renderData?.taxRate?.label || "-"}
            </span>
          </div>
        );
      },
    },
    taxType === TaxTypeEnum.EXEMPTED && {
      title: "TAX Amount",
      titleStyleClassName: "text-right",
      render(renderData) {
        return (
          <div className={"flex flex-1 items-end justify-end"}>
            <span className={"text-right"}>
              {getCurrency()}{separateWithComma(renderData?.taxAmount || 0)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Amount",
      titleStyleClassName: "text-right",
      render(renderData) {
        return (
          <div className={"flex flex-1 items-end justify-end"}>
            <span className={"whitespace-nowrap text-right"}>
              {getCurrency()}{separateWithComma(renderData?.total || 0)}
            </span>
          </div>
        );
      },
    },
  ];
}

function ReceiptModeForm({ payment }: { payment: InvoicePaymentInterface }) {
  const { getDateHandler } = useDateHook();

  return (
    <div className={"flex flex-col gap-5 border-b-2 pb-5"}>
      <div className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        {payment?.amount && (
          <NameValueText name={"Amount"} value={payment?.amount} />
        )}
        {payment?.paymentOn && (
          <NameValueText
            name={"Date"}
            value={getDateHandler({ date: payment?.paymentOn })}
          />
        )}
      </div>
      <div className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        {payment?.mode && (
          <NameValueText
            name={"Mode of Receipt"}
            value={payment?.mode?.label}
          />
        )}
      </div>
      <div className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        {payment?.bankName && (
          <NameValueText
            name={"Bank Name"}
            value={payment?.bankName?.label || "-"}
          />
        )}
        {payment?.agents && (
          <NameValueText name={"Bank Name"} value={payment?.agents || "-"} />
        )}
        {payment?.chequeNo && (
          <NameValueText
            name={"Cheque No/ Chalan No"}
            value={payment?.chequeNo || "-"}
          />
        )}
        {payment?.transactionNo && (
          <NameValueText
            name={"Transaction No"}
            value={payment?.transactionNo || "-"}
          />
        )}
      </div>
    </div>
  );
}
export default InvoiceDetailsPage;
