import React from "react";
import { Route } from "react-router-dom";
import { PropertyPage, NewPropertyPage, PropertyDetailsPage } from "./pages";
import {
  PropertyDetails_General,
  PropertyDetails_Owners,
  PropertyDetails_Tenants,
  PropertyDetails_Units,
  PropertyDetails_Amenities,
  PropertyDetails_Documents,
  PropertyDetails_Tenancies,
  PropertyDetails_EditDocuments,
} from "./tabs";
import {
  AddOwnerModal,
  EditAmenitiesModal,
  EditAssignOwnerModal,
  OwnershipDetailsModal,
} from "./modal";
import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="rental/property">
      {/*  property*/}
      <Route
        path={""}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.RENTAL_READ_PROPERTY]}
            isForPage={true}
          >
            <PropertyPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"new"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.RENTAL_ADD_PROPERTY]}
            isForPage={true}
          >
            <NewPropertyPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`edit/:${ParamsStringEnum?.PROPERTY_ID}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.RENTAL_EDIT_PROPERTY]}
            isForPage={true}
          >
            <NewPropertyPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`details/:${ParamsStringEnum?.PROPERTY_ID}/*`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.RENTAL_READ_PROPERTY]}
            isForPage={true}
          >
            <PropertyDetailsPage />
          </AccessVerifier>
        }
      >
        <Route
          path={``}
          element={
            <AccessVerifier
              accessKeys={[SpaceAccessListEnum.RENTAL_READ_PROPERTY]}
              isForPage={true}
            >
              <PropertyDetails_General />
            </AccessVerifier>
          }
        ></Route>
        <Route
          path={`amenities`}
          element={
            <AccessVerifier
              accessKeys={[SpaceAccessListEnum.RENTAL_READ_PROPERTY]}
              isForPage={true}
            >
              <PropertyDetails_Amenities />
            </AccessVerifier>
          }
        >
          <Route
            path={`modal/amenities/edit`}
            element={
              <AccessVerifier
                accessKeys={[SpaceAccessListEnum.RENTAL_READ_PROPERTY]}
                isForPage={true}
              >
                <EditAmenitiesModal />
              </AccessVerifier>
            }
          />
        </Route>
        <Route
          path={`owners`}
          element={
            <AccessVerifier
              accessKeys={[SpaceAccessListEnum.AUTH_READ_USER]}
              isForPage={true}
            >
              <PropertyDetails_Owners />
            </AccessVerifier>
          }
        >
          <Route
            path={`modal/assignOwner`}
            element={
              <AccessVerifier
                accessKeys={[SpaceAccessListEnum.AUTH_EDIT_USER]}
                isForPage={true}
              >
                <EditAssignOwnerModal />
              </AccessVerifier>
            }
          />
          <Route
            path={`modal/assignOwner/edit/:${ParamsStringEnum.ID}`}
            element={
              <AccessVerifier
                accessKeys={[SpaceAccessListEnum.AUTH_EDIT_USER]}
                isForPage={true}
              >
                <EditAssignOwnerModal />
              </AccessVerifier>
            }
          />{" "}
          <Route
            path={`modal/ownership/details/:${ParamsStringEnum.ID}`}
            element={<OwnershipDetailsModal />}
          />
          <Route
            path={`modal/add`}
            element={
              <AccessVerifier
                accessKeys={[SpaceAccessListEnum.AUTH_ADD_USER]}
                isForPage={true}
              >
                <AddOwnerModal />
              </AccessVerifier>
            }
          />
        </Route>
        <Route
          path={`units`}
          element={
            <AccessVerifier
              accessKeys={[SpaceAccessListEnum.RENTAL_READ_UNIT]}
              isForPage={true}
            >
              <PropertyDetails_Units />
            </AccessVerifier>
          }
        />
        <Route
          path={`tenants`}
          element={
            <AccessVerifier
              accessKeys={[SpaceAccessListEnum.AUTH_READ_USER]}
              isForPage={true}
            >
              <PropertyDetails_Tenants />
            </AccessVerifier>
          }
        />
        <Route path={`documents/*`}>
          <Route
            path={""}
            index={true}
            element={
              <AccessVerifier
                accessKeys={[SpaceAccessListEnum.RENTAL_READ_PROPERTY]}
                isForPage={true}
              >
                <PropertyDetails_Documents />
              </AccessVerifier>
            }
          />
          <Route
            path={`editDocuments`}
            element={
              <AccessVerifier
                accessKeys={[SpaceAccessListEnum.RENTAL_EDIT_PROPERTY]}
                isForPage={true}
              >
                <PropertyDetails_EditDocuments />
              </AccessVerifier>
            }
          />
        </Route>

        <Route
          path={`tenancies/*`}
          element={
            <AccessVerifier
              accessKeys={[SpaceAccessListEnum.TENANCY_READ_TENANCY]}
              isForPage={true}
            >
              <PropertyDetails_Tenancies />
            </AccessVerifier>
          }
        />
      </Route>
    </Route>
  );
}

export default Index;
