import {
  IconTypeEnum,
  InvoiceStatusEnum,
  NavLinkInterface,
  QueryStringEnum,
  ShortCutLinkInterface,
  SpaceAccessListEnum,
  UserRoleEnum,
} from "interfaces";
import { MdAlternateEmail, MdOutlineNotificationsActive } from "react-icons/md";
import {
  HiOutlineDocumentText,
  HiOutlineWrenchScrewdriver,
} from "react-icons/hi2";
import { SlCup } from "react-icons/sl";
import {
  BsBank,
  BsFileEarmarkSpreadsheet,
  BsFolder2,
  BsJournalBookmark,
  BsLayers,
  BsSpeedometer2,
} from "react-icons/bs";
import {
  FiKey,
  FiMessageCircle,
  FiPieChart,
  FiUserCheck,
  FiUsers,
} from "react-icons/fi";
import { TbReportAnalytics, TbTruckDelivery, TbWallet } from "react-icons/tb";
import { CgMenuBoxed, CgTwilio } from "react-icons/cg";
import { GoListUnordered } from "react-icons/go";
import { CiBoxes } from "react-icons/ci";
import {
  RiChatSettingsLine,
  RiDashboardFill,
  RiDeleteBinLine,
  RiHandCoinLine,
} from "react-icons/ri";
import {
  BiCalendar,
  BiLockOpenAlt,
  BiReceipt,
  BiTransfer,
  BiWallet,
} from "react-icons/bi";
import { AiOutlineSetting, AiOutlineUser } from "react-icons/ai";
import { IoBedOutline, IoGridOutline, IoJournalOutline } from "react-icons/io5";
import { IoMdJournal } from "react-icons/io";
import PageLinks from "./PageLinks";
import { SiFuturelearn } from "react-icons/si";
import getIconHelpers from "../helpers/utils/getIconHelpers";
import { useAuthorizationHook } from "../useHooks";

const RentalIcon = getIconHelpers(IconTypeEnum.RENTAL);
const PropertyIcon = getIconHelpers(IconTypeEnum.PROPERTY);
const UnitIcon = getIconHelpers(IconTypeEnum.UNIT);
const UsersIcon = getIconHelpers(IconTypeEnum.USERS);
const AddIcon = getIconHelpers(IconTypeEnum.ADD);
const FinanceIcon = getIconHelpers(IconTypeEnum.FINANCE);
const InActiveFinanceIcon = getIconHelpers(IconTypeEnum.IN_ACTIVE_FINANCE);
const InvoiceIcon = getIconHelpers(IconTypeEnum.INVOICE);
const InActiveInvoiceIcon = getIconHelpers(IconTypeEnum.IN_ACTIVE_INVOICE);
const InventoryIcon = getIconHelpers(IconTypeEnum.INVENTORY);
const InActiveInventoryIcon = getIconHelpers(IconTypeEnum.IN_ACTIVE_INVENTORY);

export const AccessibleNavLinks = (): NavLinkInterface[][] => {
  let links = navLinks;
  let accessibleNavLinks: NavLinkInterface[] = [].concat(...links);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const accLink = accessibleNavLinks?.reduce((acc, currentValue) => {
    if (checkCanAccessHandler(currentValue?.access)) {
      if (!currentValue?.isGroup) {
        return [...acc, currentValue];
      } else {
        const childFiltered = currentValue?.groups?.reduce(
          (childAcc, childCurrent) => {
            if (checkCanAccessHandler(childCurrent?.access)) {
              return [...childAcc, childCurrent];
            }
            return childAcc;
          },
          []
        );
        return [
          ...acc,
          {
            ...currentValue,
            groups: childFiltered,
          },
        ];
      }
    }
    return acc;
  }, []);
  // group into 2
  const result = accLink.reduce((acc, current, index) => {
    if (index % 2 === 0) {
      acc.push([current]);
    } else {
      acc[acc.length - 1].push(current);
    }
    return acc;
  }, []);
  return result;
};
export const AccessibleSettingLinks = (): NavLinkInterface[][] => {
  let links = settingLinks;
  const { checkCanAccessHandler } = useAuthorizationHook();
  let results = [];
  links.forEach((e) => {
    const accLink = e?.reduce((acc, currentValue) => {
      if (checkCanAccessHandler(currentValue?.access)) {
        if (!currentValue?.isGroup) {
          return [...acc, currentValue];
        } else {
          const childFiltered = currentValue?.groups?.reduce(
            (childAcc, childCurrent) => {
              if (checkCanAccessHandler(childCurrent?.access)) {
                return [...childAcc, childCurrent];
              }
              return childAcc;
            },
            []
          );
          return [
            ...acc,
            {
              ...currentValue,
              groups: childFiltered,
            },
          ];
        }
      }
      return acc;
    }, []);

    results.push(accLink);
  });

  return results;
};
export const AccessibleShortCutLinks = (): ShortCutLinkInterface[] => {
  let links = ShortCutLinks;
  const { checkCanAccessHandler } = useAuthorizationHook();
  const accLink = links?.reduce((acc, currentValue) => {
    if (checkCanAccessHandler(currentValue?.access)) {
      if (!currentValue?.groups?.length) {
        return [...acc, currentValue];
      } else {
        const childFiltered = currentValue?.groups?.reduce(
          (childAcc, childCurrent) => {
            if (checkCanAccessHandler(childCurrent?.access)) {
              return [...childAcc, childCurrent];
            }
            return childAcc;
          },
          []
        );
        return [
          ...acc,
          {
            ...currentValue,
            groups: childFiltered,
          },
        ];
      }
    }
    return acc;
  }, []);

  return accLink;
};

export const navLinks: NavLinkInterface[][] = [
  [
    {
      name: "Dashboard",
      Icon: IoGridOutline,
      path: PageLinks.dashboard.list,
      isGroup: false,
      access: [SpaceAccessListEnum.MISC_ACCESS_DASHBOARD],
    },
    {
      name: "Rental",
      Icon: RentalIcon,
      path: "/rental",
      isGroup: true,
      access: [
        SpaceAccessListEnum.RENTAL_READ_PROPERTY,
        SpaceAccessListEnum.RENTAL_READ_UNIT,
      ],
      groups: [
        {
          name: "Property",
          Icon: PropertyIcon,
          path: PageLinks.property.list,
          access: [SpaceAccessListEnum.RENTAL_READ_PROPERTY],
          isGroup: false,
        },
        {
          name: "Unit",
          Icon: UnitIcon,
          path: PageLinks.unit.list,
          access: [SpaceAccessListEnum.RENTAL_READ_UNIT],
          isGroup: false,
        },
      ],
    },
  ],
  [
    {
      name: "Tenancies",
      Icon: FiKey,
      path: PageLinks.tenancy.list,
      isGroup: false,
      access: [SpaceAccessListEnum.TENANCY_READ_TENANCY],
    },
    {
      name: "Bookings",
      Icon: BiCalendar,
      path: PageLinks.bookings.list,
      access: [SpaceAccessListEnum.BOOKING_READ_BOOKING],
      isGroup: false,
    },
  ],
  [
    {
      name: "Invoice",
      Icon: InvoiceIcon,
      InActiveIcon: InActiveInvoiceIcon,
      path: "/finance/invoice",
      access: [SpaceAccessListEnum.FINANCE_READ_INVOICE],

      isGroup: false,
    },
    {
      name: "Accounting",
      Icon: BiReceipt,
      path: "/accounting/",
      isGroup: true,
      access: [
        SpaceAccessListEnum.ACCOUNT_READ_JOURNAL,
        SpaceAccessListEnum.ACCOUNT_ACCESS_TRIAL_BALANCE,
        SpaceAccessListEnum.ACCOUNT_ACCESS_LEDGER,
        SpaceAccessListEnum.ACCOUNT_ACCESS_LEDGER_TRANSACTION,
        SpaceAccessListEnum.ACCOUNT_ACCESS_INCOME_STATEMENT,
        SpaceAccessListEnum.ACCOUNT_ACCESS_BALANCE_SHEET,
      ],
      groups: [
        {
          name: "Journal",
          Icon: IoMdJournal,
          path: PageLinks.journal.list,
          isGroup: false,
          access: [SpaceAccessListEnum.ACCOUNT_READ_JOURNAL],
        },
        {
          name: "Trial Balance",
          Icon: TbWallet,
          path: PageLinks.account.trialBalance,
          isGroup: false,
          access: [SpaceAccessListEnum.ACCOUNT_ACCESS_TRIAL_BALANCE],
        },
        {
          name: "Ledger",
          Icon: BsJournalBookmark,
          path: PageLinks.ledger.list,
          isGroup: false,
          access: [SpaceAccessListEnum.ACCOUNT_ACCESS_LEDGER],
        },
        {
          name: "Ledger Transaction",
          Icon: BiTransfer,
          path: PageLinks.ledger.listLedgerTransaction,
          isGroup: false,
          access: [SpaceAccessListEnum.ACCOUNT_ACCESS_LEDGER_TRANSACTION],
        },
        {
          name: "Income Statement",
          Icon: BiWallet,
          path: PageLinks.account.incomeStatement,
          isGroup: false,
          access: [SpaceAccessListEnum.ACCOUNT_ACCESS_INCOME_STATEMENT],
        },
        {
          name: "Balance Sheet",
          Icon: BsFileEarmarkSpreadsheet,
          path: PageLinks.account.balanceSheet,
          isGroup: false,
          access: [SpaceAccessListEnum.ACCOUNT_ACCESS_BALANCE_SHEET],
        },
      ],
    },
  ],
  [
    {
      name: "Finance",
      Icon: FinanceIcon,
      InActiveIcon: InActiveFinanceIcon,
      path: "/finance/",
      isGroup: true,
      access: [
        SpaceAccessListEnum.FINANCE_READ_LOAN_AND_MORTGAGE,
        SpaceAccessListEnum.FINANCE_RECONCILIATION,
      ],
      groups: [
        {
          name: "Loans/Mortgages",
          Icon: RiHandCoinLine,
          path: PageLinks.loansAndMortgages.list,
          isGroup: false,
          access: [SpaceAccessListEnum.FINANCE_READ_LOAN_AND_MORTGAGE],
        },
        {
          name: "Bank Reconciliation",
          Icon: BsBank,
          path: PageLinks.reconciliation.list,
          isGroup: false,
          access: [SpaceAccessListEnum.FINANCE_RECONCILIATION],
        },
        {
          name: "Reconciled Statement",
          Icon: HiOutlineDocumentText,
          path: PageLinks.reconciliation.reconciledLists,
          isGroup: false,
          access: [SpaceAccessListEnum.FINANCE_READ_RECONCILE_STATEMENT],
        },
      ],
    },
    {
      name: "Inventories",
      Icon: InventoryIcon,
      InActiveIcon: InActiveInventoryIcon,
      path: PageLinks.inventory.list,
      isGroup: false,
      access: [SpaceAccessListEnum.INVENTORY_READ_INVENTORY],
    },
  ],
  [
    {
      name: "Repair and Maintenance",
      Icon: HiOutlineWrenchScrewdriver,
      path: "/services/maintenance/",
      access: [
        SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_READ_REPAIR_AND_MAINTENANCE,
      ],
      isGroup: false,
    },
    {
      name: "Tasks",
      Icon: HiOutlineDocumentText,
      path: "/services/tasks",
      access: [SpaceAccessListEnum.TASK_READ_TASK],

      isGroup: false,
    },
  ],
  [
    {
      name: "Messages",
      Icon: FiMessageCircle,
      path: "/tools/messages/",
      access: [SpaceAccessListEnum.MISC_ACCESS_MESSAGE],

      isGroup: false,
    },
    {
      name: "Documents",
      Icon: BsFolder2,
      path: "/tools/documents/",
      isGroup: false,
      access: [SpaceAccessListEnum.DOCUMENT_READ_DOCUMENT],
    },
  ],
  [
    {
      name: "Notes",
      Icon: TbReportAnalytics,
      path: "/tools/notes/",
      access: [],

      isGroup: false,
    },
    {
      name: "Users",
      Icon: UsersIcon,
      path: `/spaceUser/users`,
      isGroup: true,
      access: [SpaceAccessListEnum.AUTH_READ_USER],
      groups: [
        {
          name: "All Users",
          Icon: UsersIcon,
          path: PageLinks.spaceUser.list(UserRoleEnum.NONE),
          access: [SpaceAccessListEnum.AUTH_READ_USER],
          isGroup: false,
        },
        {
          name: "Tenants",
          Icon: FiUsers,
          path: PageLinks.spaceUser.list(UserRoleEnum.TENANT),
          isGroup: false,
          access: [SpaceAccessListEnum.AUTH_READ_USER],
        },
        {
          name: "Owners",
          Icon: AiOutlineUser,
          path: PageLinks.spaceUser.list(UserRoleEnum.OWNER),
          access: [SpaceAccessListEnum.AUTH_READ_USER],
          isGroup: false,
        },

        {
          name: "Suppliers",
          Icon: AiOutlineUser,
          path: PageLinks.spaceUser.list(UserRoleEnum.SUPPLIER),
          access: [SpaceAccessListEnum.AUTH_ACCESS_SUPPLIER],
          isGroup: false,
        },
        {
          name: "Lenders",
          Icon: AiOutlineUser,
          path: PageLinks.spaceUser.list(UserRoleEnum.LENDER),
          access: [SpaceAccessListEnum.AUTH_READ_USER],
          isGroup: false,
        },
        {
          name: "Landlords",
          Icon: AiOutlineUser,
          path: PageLinks.spaceUser.list(UserRoleEnum.LANDLORD),
          access: [SpaceAccessListEnum.AUTH_READ_USER],
          isGroup: false,
        },
      ],
    },
  ],

  [
    {
      name: "Admin Setup",
      Icon: CgMenuBoxed,
      path: "/setup/module",
      isGroup: true,
      access: [],
      groups: [
        {
          name: "Module Metric",
          Icon: RiDashboardFill,
          path: PageLinks.dynamicLists.list(),
          access: [],
          isGroup: false,
        },
        {
          name: "System Metric",
          Icon: GoListUnordered,
          path: "/setup/system",
          access: [],
          isGroup: false,
        },
        {
          name: "Roles & Permissions",
          Icon: BiLockOpenAlt,
          path: PageLinks.role.list,
          isGroup: false,
          access: [SpaceAccessListEnum.AUTH_READ_ROLE],
        },
        {
          name: "Sub-Ledger Name",
          Icon: IoJournalOutline,
          path: PageLinks.subLedgerName.list,
          isGroup: false,
          access: [SpaceAccessListEnum.ACCOUNT_ACCESS_SUB_LEDGER],
        },
        {
          name: "Ledger Name",
          Icon: IoJournalOutline,
          path: PageLinks.ledgerName.list,
          isGroup: false,
          access: [SpaceAccessListEnum.ACCOUNT_READ_LEDGER_NAME],
        },
      ],
    },
    {
      name: "Tools",
      Icon: IoBedOutline,
      path: "/tools/meter-reading",
      isGroup: true,
      access: [
        SpaceAccessListEnum.TOOLS_READ_TRIP,
        SpaceAccessListEnum.TOOLS_READ_METER_READING,
      ],
      groups: [
        {
          name: "Meter Reading",
          Icon: BsSpeedometer2,
          path: "/tools/meter-reading",
          isGroup: false,
          access: [SpaceAccessListEnum.TOOLS_READ_METER_READING],
        },
        {
          name: "Trips",
          Icon: TbTruckDelivery,
          path: "/tools/trips",
          isGroup: false,
          access: [SpaceAccessListEnum.TOOLS_READ_TRIP],
        },
      ],
    },
  ],

  [
    {
      name: "Candidates",
      Icon: FiUserCheck,
      path: "/tools/candidates/",
      isGroup: false,
      access: [SpaceAccessListEnum.CANDIDATE_READ_CANDIDATE],
    },
    {
      name: "Reports",
      Icon: FiPieChart,
      path: PageLinks.report.reportPage,
      isGroup: false,
      access: [SpaceAccessListEnum.MISC_ACCESS_REPORT_PAGE],
    },
  ],
  [
    {
      name: "Trash",
      Icon: RiDeleteBinLine,
      path: "/tools/trash/",
      isGroup: false,
      access: [SpaceAccessListEnum.MISC_ACCESS_TRASH],
    },
  ],
];

export const settingLinks: NavLinkInterface[][] = [
  [
    {
      name: "General Settings",
      Icon: AiOutlineSetting,
      path: PageLinks.settings.spaceSettings,
      access: [SpaceAccessListEnum.CONFIGURATION_MANAGE_SETTINGS],
      isGroup: false,
    },
  ],
  [
    {
      name: "Initial Opening Balance",
      Icon: BiReceipt,
      path: PageLinks.journal.opening,
      isGroup: false,
      access: [SpaceAccessListEnum.ACCOUNT_READ_JOURNAL],
    },
    // {
    //   name: "Retained Earning",
    //   Icon: SiFuturelearn,
    //   path: PageLinks.settings.retainEarning,
    //   isGroup: false,
    //   access: [SpaceAccessListEnum.CONFIGURATION_ACCESS_RETAIN_EARNING],
    // },
  ],
  [
    {
      name: "Manage Notifications",
      Icon: MdOutlineNotificationsActive,
      path: PageLinks.manageNotification.list,
      isGroup: false,
      access: [SpaceAccessListEnum.CONFIGURATION_MANAGE_NOTIFICATION],
    },
  ],
  [
    {
      name: "True Layer Configuration",
      Icon: BsLayers,
      path: PageLinks.settings.trueLayerSettings,
      access: [SpaceAccessListEnum.CONFIGURATION_MANAGE_TRUE_LAYER_SETTINGS],
      isGroup: false,
    },

    {
      name: "Banks Link Configuration",
      Icon: BsBank,
      path: PageLinks.banks.list,
      isGroup: false,
      access: [SpaceAccessListEnum.FINANCE_READ_BANK],
    },
  ],
  [
    {
      name: "Twilio Configuration",
      Icon: CgTwilio,
      path: PageLinks.settings.twilioSettings,
      isGroup: false,
      access: [SpaceAccessListEnum.CONFIGURATION_MANAGE_TWILIO_SETTINGS],
    },
    {
      name: "Brevo Configuration",
      Icon: RiChatSettingsLine,
      path: PageLinks.settings.brevoSettings,
      isGroup: false,
      access: [SpaceAccessListEnum.CONFIGURATION_MANAGE_BREVO_SETTINGS],
    },
  ],
  [
    {
      name: "Change Password",
      Icon: MdAlternateEmail,
      path: PageLinks.profile.changePassword,
      isGroup: false,
      access: [],
    },
    {
      name: "Billing & Subscription",
      Icon: SlCup,
      path: PageLinks.subscription.activeSubscription,
      isGroup: false,
      access: [SpaceAccessListEnum.BILLING_VIEW_SUBSCRIPTION],
    },
  ],
];

export const ShortCutLinks: ShortCutLinkInterface[] = [
  {
    name: "Rental",
    Icon: RentalIcon,
    path: "",
    access: [
      SpaceAccessListEnum.RENTAL_ADD_PROPERTY,
      SpaceAccessListEnum.RENTAL_ADD_UNIT,
    ],
    groups: [
      {
        name: "Add Property",
        Icon: AddIcon,
        path: PageLinks.property?.new,
        access: [SpaceAccessListEnum.RENTAL_ADD_PROPERTY],
      },
      {
        name: "Add Unit",
        Icon: AddIcon,
        path: PageLinks.unit?.new,
        access: [SpaceAccessListEnum.RENTAL_ADD_UNIT],
      },
    ],
  },
  {
    name: "Tenancies",
    Icon: FiKey,
    path: "",
    access: [SpaceAccessListEnum.TENANCY_ADD_TENANCY],
    groups: [
      {
        name: "Add Tenancies",
        Icon: AddIcon,
        path: PageLinks.tenancy?.new,
        access: [SpaceAccessListEnum.TENANCY_ADD_TENANCY],
      },
    ],
  },
  {
    name: "Bookings",
    Icon: BiCalendar,
    path: "",
    access: [SpaceAccessListEnum.BOOKING_ADD_BOOKING],
    groups: [
      {
        name: "Add Booking",
        Icon: AddIcon,
        path: PageLinks.bookings?.new,
        access: [SpaceAccessListEnum.BOOKING_ADD_BOOKING],
      },
    ],
  },
  {
    name: "Invoice",
    Icon: InvoiceIcon,
    path: "",
    access: [SpaceAccessListEnum.FINANCE_ADD_INVOICE],
    groups: [
      {
        name: "Add Sales",
        Icon: AddIcon,
        path: PageLinks.invoice?.new(InvoiceStatusEnum.SALES),
        access: [SpaceAccessListEnum.FINANCE_ADD_INVOICE],
      },
      {
        name: "Add Purchase",
        Icon: AddIcon,
        path: PageLinks.invoice?.new(InvoiceStatusEnum.PURCHASE),
        access: [SpaceAccessListEnum.FINANCE_ADD_INVOICE],
      },
      {
        name: "Add Sales Return",
        Icon: AddIcon,
        path: PageLinks.invoice?.new(InvoiceStatusEnum.SALES_RETURN),
        access: [SpaceAccessListEnum.FINANCE_ADD_INVOICE],
      },
      {
        name: "Add Purchase Return",
        Icon: AddIcon,
        path: PageLinks.invoice?.new(InvoiceStatusEnum.PURCHASE_RETURN),
        access: [SpaceAccessListEnum.FINANCE_ADD_INVOICE],
      },
    ],
  },
  {
    name: "Accounting",
    Icon: BiReceipt,
    path: "",
    access: [SpaceAccessListEnum.ACCOUNT_ADD_JOURNAL],
    groups: [
      {
        name: "Add Journal",
        Icon: AddIcon,
        path: PageLinks.journal?.new,
        access: [SpaceAccessListEnum.ACCOUNT_ADD_JOURNAL],
      },
    ],
  },
  {
    name: "Finance",
    Icon: FinanceIcon,

    path: "",
    access: [SpaceAccessListEnum.FINANCE_ADD_LOAN_AND_MORTGAGE],
    groups: [
      {
        name: "Add Loan",
        Icon: AddIcon,
        path: PageLinks.loansAndMortgages?.new,
        access: [SpaceAccessListEnum.FINANCE_ADD_LOAN_AND_MORTGAGE],
      },
    ],
  },
  {
    name: "Inventories",
    Icon: CiBoxes,
    path: "",
    access: [SpaceAccessListEnum.INVENTORY_ADD_INVENTORY],
    groups: [
      {
        name: "Add Inventory",
        Icon: AddIcon,
        path: PageLinks.inventory?.new,
        access: [SpaceAccessListEnum.INVENTORY_ADD_INVENTORY],
      },
    ],
  },
  {
    name: "Repair and Maintenance",
    Icon: HiOutlineWrenchScrewdriver,
    path: "",
    access: [
      SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_ADD_REPAIR_AND_MAINTENANCE,
    ],
    groups: [
      {
        name: "Add Maintenance",
        Icon: AddIcon,
        path: PageLinks.maintenance?.new,
        access: [
          SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_ADD_REPAIR_AND_MAINTENANCE,
        ],
      },
    ],
  },
  {
    name: "Task",
    Icon: HiOutlineDocumentText,
    path: "",
    access: [SpaceAccessListEnum.TASK_ADD_TASK],
    groups: [
      {
        name: "Add Task",
        Icon: AddIcon,
        path: PageLinks.task?.new,
        access: [SpaceAccessListEnum.TASK_ADD_TASK],
      },
    ],
  },
  {
    name: "Messages",
    Icon: FiMessageCircle,
    path: "",
    access: [SpaceAccessListEnum.MISC_ACCESS_MESSAGE],
    groups: [
      {
        name: "Compose Message",
        Icon: AddIcon,
        path: PageLinks.message?.new,
        access: [SpaceAccessListEnum.MISC_ACCESS_MESSAGE],
      },
    ],
  },
  {
    name: "Documents",
    Icon: BsFolder2,
    path: "",
    access: [SpaceAccessListEnum.DOCUMENT_ADD_DOCUMENT],
    groups: [
      {
        name: "Add Document",
        Icon: AddIcon,
        path: PageLinks.documents?.new,
        access: [SpaceAccessListEnum.DOCUMENT_ADD_DOCUMENT],
      },
    ],
  },
  {
    name: "Notes",
    Icon: TbReportAnalytics,
    path: "",
    access: [],
    groups: [
      {
        name: "Add Notes",
        Icon: AddIcon,
        path: PageLinks.notes?.new,
        access: [],
      },
    ],
  },
  {
    name: "User",
    Icon: UsersIcon,
    path: "",
    access: [SpaceAccessListEnum.AUTH_ADD_USER],
    groups: [
      {
        name: "Add Tenant",
        Icon: AddIcon,
        path:
          PageLinks.spaceUser?.new +
          `?${QueryStringEnum.TYPE}=${UserRoleEnum.TENANT}`,
        access: [SpaceAccessListEnum.AUTH_ADD_USER],
      },
      {
        name: "Add Owners",
        Icon: AddIcon,
        path:
          PageLinks.spaceUser?.new +
          `?${QueryStringEnum.TYPE}=${UserRoleEnum.OWNER}`,
        access: [SpaceAccessListEnum.AUTH_ADD_USER],
      },
      {
        name: "Add Lenders",
        Icon: AddIcon,
        path:
          PageLinks.spaceUser?.new +
          `?${QueryStringEnum.TYPE}=${UserRoleEnum.LENDER}`,
        access: [SpaceAccessListEnum.AUTH_ADD_USER],
      },
      {
        name: "Add Landlords",
        Icon: AddIcon,
        path:
          PageLinks.spaceUser?.new +
          `?${QueryStringEnum.TYPE}=${UserRoleEnum.LANDLORD}`,
        access: [SpaceAccessListEnum.AUTH_ADD_USER],
      },
    ],
  },
  {
    name: "Admin Setup",
    Icon: CgMenuBoxed,
    path: "",
    access: [SpaceAccessListEnum.AUTH_ADD_ROLE],
    groups: [
      {
        name: "Add New Role",
        Icon: AddIcon,
        path: PageLinks.role?.create,
        access: [SpaceAccessListEnum.AUTH_ADD_ROLE],
      },
    ],
  },
  {
    name: "Tools",
    Icon: IoBedOutline,
    path: "",
    access: [SpaceAccessListEnum.TASK_ADD_TASK],
    groups: [
      {
        name: "Add Meter Reading",
        Icon: AddIcon,
        path: PageLinks.meterReading?.new,
        access: [SpaceAccessListEnum.TOOLS_ADD_METER_READING],
      },
      {
        name: "Add Trips",
        Icon: AddIcon,
        path: PageLinks.trips?.new,
        access: [SpaceAccessListEnum.TOOLS_ADD_TRIP],
      },
    ],
  },
  {
    name: "Candidates",
    Icon: FiUserCheck,
    path: "",
    access: [SpaceAccessListEnum.CANDIDATE_ADD_CANDIDATE],
    groups: [
      {
        name: "Add Candidates",
        Icon: AddIcon,
        path: PageLinks.candidates?.new,
        access: [SpaceAccessListEnum.CANDIDATE_ADD_CANDIDATE],
      },
    ],
  },
];
