import React from "react";
import { AddressTextInterface } from "./interfaces";
import getIconHelpers from "../../helpers/utils/getIconHelpers";
import { IconTypeEnum } from "../../interfaces";

function AddressText({
  address,
  textStyle,
  style,
  isOneLine,
}: AddressTextInterface) {
  let country =
    typeof address?.county == "string"
      ? address?.county
      : address?.county?.name;
  const AddressIcon = getIconHelpers(IconTypeEnum.ADDRESS);
  return (
    <div
      style={style}
      className={
        `flex ${
          isOneLine ? "flex-row gap-2 " : "flex-col items-start gap-1"
        } text-[14px] ` + textStyle
      }
    >
      <span>
        {address && [address?.address1, address?.address2].join(", ")}
      </span>
      <div className={"flex gap-1 items-center justify-start"}>
        {address && <AddressIcon className={"text-[16px] text-gray-800"} />}
        <span>
          {address && [country, address?.postalCode?.toUpperCase()].join(", ")}
        </span>
      </div>
    </div>
  );
}

export default AddressText;
