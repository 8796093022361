import Popup from "reactjs-popup";

import { MyMoreOptionsButtonInterface } from "./interfaces";
import { IoCaretDown } from "react-icons/io5";
import { AiOutlineMore } from "react-icons/ai";

import React from "react";
import { getIconHelper } from "helpers";
import { IconTypeEnum } from "../../interfaces";

export default function MoreOptionsButton({
  options,
  type,
  colorType,
  isOutline,
  name,
  iconType,
  width,
}: MyMoreOptionsButtonInterface) {
  const getButtonStyle = () => {
    let styles, iconStyle;
    switch (colorType) {
      case "primary":
        if (isOutline) {
          styles = "border-primary border-2 text-primary";
        } else {
          styles = "bg-primary  text-white ";
        }
        iconStyle = "text-[22px] text-primary";
        break;
      case "danger":
        if (isOutline) {
          styles = "border-tRed border-2 text-tRed";
        } else {
          styles = "bg-tRed  text-white ";
        }
        break;
      default:
        if (isOutline) {
          styles = "border-primary border-2 text-primary";
        } else {
          styles = "bg-primary  text-white ";
        }
        iconStyle = "text-[22px]";
    }
    return { styles, iconStyle };
  };
  const { styles, iconStyle } = getButtonStyle();
  const Icon = getIconHelper(iconType);
  const DownIcon = getIconHelper(IconTypeEnum.DOWN);
  const optionsLengthWithName = options?.filter((e) => e?.name)?.length;
  if (!optionsLengthWithName) {
    return;
  }
  return (
    <Popup
      position="bottom right"
      closeOnDocumentClick
      contentStyle={{ width: width || "200px" }}
      disabled={options?.length < 1}
      trigger={
        type === "button" ? (
          <button
            type={"button"}
            className={`btn flex-1 border-[1px] flex rounded-full hover:opacity-95 gap-5 items-center  cursor-pointer text-[16px]  px-4 min-h-[48px]
      ${styles} `}
          >
            <div className={"flex  items-center justify-center  gap-1"}>
              {iconType && (
                <Icon
                  height={iconStyle?.height || "20px"}
                  width={iconStyle?.width || "20px"}
                  className={` text-[16px] ${iconStyle} `}
                />
              )}
              <span className={"whitespace-nowrap font-medium"}>{name}</span>
            </div>
            <DownIcon />
          </button>
        ) : (
          <button className="button">
            <div className={"rounded-full p-2 border-2 text-gray-400 bg-white"}>
              <AiOutlineMore fontSize={18} />
            </div>
          </button>
        )
      }
    >
      <div className={"flex flex-col "}>
        {options?.map((e, key) => {
          const isLastItem = key === optionsLengthWithName - 1;
          if (!e?.name) return;
          return (
            <div
              className={`flex items-center gap-1 border-b-[0.5px] border-[#c0c0c0] px-2 py-4 text-primary text-[14px] cursor-pointer hover:bg-gray-100`}
              key={key}
              onClick={() => e?.handler()}
            >
              {iconType && (
                <Icon
                  height={iconStyle?.height || "20px"}
                  width={iconStyle?.width || "20px"}
                  className={` text-[16px] ${iconStyle} `}
                />
              )}
              <span>{e?.name}</span>
            </div>
          );
        })}
      </div>
    </Popup>
  );
}
