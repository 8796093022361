import React, { useContext, useEffect } from "react";

import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { MyButton, MyIconButton, TableCardComponent } from "components";
import { PageTemplate } from "templates";

import { ParamsEnum, QueryEnum, SystemDataInterface } from "../../interfaces";
import { DataContext } from "../../context";
import { FieldTypeEnum, IconTypeEnum, SpaceAccessListEnum } from "interfaces";
import { useAuthorizationHook } from "useHooks";
import { capitalizeAfterSpace } from "helpers";

function SystemDynamicPage() {
  const location = useLocation();
  const params = useParams<ParamsEnum>();
  const [query, setQuery] = useSearchParams();
  const parentID = query?.get(QueryEnum.PARENT_ID);
  const parentName = query?.get(QueryEnum.PARENT_NAME);
  const navigate = useNavigate();
  const {
    loading,
    systemData,
    getSystemTypeDataHandler,
    deleteSystemItemHandler,
  } = useContext(DataContext);
  useEffect(() => {
    getSystemTypeDataHandler(params?.SYSTEM, parentID);
  }, [params?.SYSTEM, parentID]);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.CONFIGURATION_EDIT_ADMINISTRATIVE_SETUP,
  ]);
  const canAdd = checkCanAccessHandler([
    SpaceAccessListEnum.CONFIGURATION_ADD_ADMINISTRATIVE_SETUP,
  ]);
  const canDel = checkCanAccessHandler([
    SpaceAccessListEnum.CONFIGURATION_DEL_ADMINISTRATIVE_SETUP,
  ]);
  const renderTitleName = () => {
    let name;
    if (parentID) {
      if (params.SYSTEM === FieldTypeEnum.COUNTRY) {
        name = "County";
      }
    } else {
      name = params?.SYSTEM;
    }
    return capitalizeAfterSpace(name);
  };
  const viewDetailsHandler = (parentId, name) => {
    if (query?.get(QueryEnum.PARENT_ID)) return;
    query.set(QueryEnum.PARENT_ID, parentId);
    query.set(QueryEnum.PARENT_NAME, name);
    setQuery(query);
  };
  const navigateAddCountyPageHandler = (
    parentID: string,
    parentName: string
  ) => {
    navigate({
      pathname: location.pathname + "/edit",
      search: `?${QueryEnum?.PARENT_ID}=${parentID}&${QueryEnum?.PARENT_NAME}=${parentName}`,
    });
  };
  return (
    <PageTemplate
      title={"Administrative Setup"}
      breadCrumb={[
        {
          name: "System",
          path: "/setup/system",
        },
        parentID && {
          name: `${parentName}`,
          path: `/setup/system/${params?.SYSTEM}`,
        },
      ]}
      backNavigation={
        parentID
          ? {
              title: `${parentName} County List`,
              path: `/setup/system/${params?.SYSTEM}`,
            }
          : {
              title: `Country List`,
              path: `/setup/system`,
            }
      }
    >
      <TableCardComponent
        loading={{
          isLoading: loading?.isFieldListsLoading,
        }}
        children={{
          rightChildren: canAdd && (
            <>
              <MyButton
                iconType={IconTypeEnum.ADD}
                onClick={() => {
                  navigate({
                    pathname: location.pathname + "/edit",
                    search:
                      parentID &&
                      `?${QueryEnum?.PARENT_ID}=${parentID}&${QueryEnum?.PARENT_NAME}=${parentName}`,
                  });
                }}
                name={`Add ${renderTitleName()}`}
              />
            </>
          ),
        }}
        dataSource={systemData}
        column={[
          {
            title: "SN.",
            render(renderData: SystemDataInterface, key: number) {
              return <div>{key + 1}.</div>;
            },
          },
          {
            title: "Label",
            render(renderData: SystemDataInterface) {
              return (
                <div
                  onClick={() => {
                    renderData?.child &&
                      viewDetailsHandler(renderData?._id, renderData?.name);
                  }}
                  className={` ${
                    !parentID && "text-tBlue"
                  }  cursor-pointer text-left`}
                >
                  {renderData?.name}
                </div>
              );
            },
          },
          {
            title: "Value",
            render(renderData: SystemDataInterface) {
              return <div>{renderData?.name}</div>;
            },
          },

          (canEdit || canDel) && {
            title: "Action",
            render(renderData: SystemDataInterface) {
              return (
                <div className={"flex items-center gap-4"}>
                  {canEdit && (
                    <MyIconButton
                      onClick={() => {
                        navigate({
                          pathname: location.pathname + "/edit",
                          search: `${QueryEnum?.ID}=${renderData?._id}`,
                        });
                      }}
                      containerStyle={`border-2 p-2 border-primary text-primary"
                    `}
                      iconColor={"text-primary"}
                      iconSize={14}
                      IconType={IconTypeEnum.EDIT}
                    />
                  )}
                  {canDel && (
                    <MyIconButton
                      IconType={IconTypeEnum.DELETE}
                      onClick={() => {
                        deleteSystemItemHandler(renderData?._id, parentID);
                      }}
                      containerStyle={`border-2 p-2 border-tRed text-tRed`}
                      iconSize={14}
                    />
                  )}
                </div>
              );
            },
          },
          !parentID &&
            canAdd && {
              title: "",
              render(renderData: SystemDataInterface) {
                return (
                  <div>
                    <MyButton
                      name={"Add County"}
                      isOutline
                      onClick={() => {
                        navigateAddCountyPageHandler(
                          renderData?._id,
                          renderData?.name
                        );
                      }}
                    />
                  </div>
                );
              },
            },
        ]}
      />
    </PageTemplate>
  );
}

export default SystemDynamicPage;
