import React, { useContext, useEffect } from "react";
import { PageTemplate } from "templates";
import { Outlet, useSearchParams } from "react-router-dom";
import { RetainEarningContext } from "context";
import { MyButton } from "components";
import { NameValueText } from "assets";
import { useDateHook } from "useHooks";
import { QueryStringEnum } from "interfaces";
import { TableFilterCard } from "content";

function RetainEarningSetupPage() {
  const [query] = useSearchParams();
  const fiscalYear = query.get(QueryStringEnum.FISCAL_YEAR);

  const {
    getFiscalYearEarningHandler,
    details,
    isDetailsLoading,
    transferFiscalYearEarningHandler,
  } = useContext(RetainEarningContext);
  const { getDateHandler } = useDateHook();
  useEffect(() => {
    if (fiscalYear) {
      getFiscalYearEarningHandler(fiscalYear);
    }
  }, [fiscalYear]);
  return (
    <PageTemplate
      title={"Settings"}
      breadCrumb={[
        {
          name: "Retained Earning Setup",
        },
      ]}
    >
      <div className={"flex flex-col gap-10 items-start"}>
        <TableFilterCard haveFiscalYear />
        <>
          <div className={"grid grid-cols-2 gap-10 w-full"}>
            <NameValueText
              isLoading={isDetailsLoading}
              value={
                getDateHandler({ date: details?.fiscalYear?.startFrom }) || "-"
              }
              name={"Start from"}
            />{" "}
            <NameValueText
              isLoading={isDetailsLoading}
              value={
                getDateHandler({ date: details?.fiscalYear?.endTo }) || "-"
              }
              name={"End to"}
            />
          </div>{" "}
          <div className={"grid grid-cols-2 gap-10 w-full"}>
            <NameValueText
              isLoading={isDetailsLoading}
              value={details?.earning || "-"}
              name={"Earning"}
            />{" "}
            <NameValueText
              isLoading={isDetailsLoading}
              value={details?.retainEarning || "-"}
              name={"Already transfer earning"}
            />
          </div>
          <div className={"grid grid-cols-2 gap-10 w-full"}>
            <NameValueText
              isLoading={isDetailsLoading}
              value={details?.toBeTransferred || "-"}
              name={"To be transferred"}
            />
          </div>
          {details && details?.toBeTransferred !== 0 && (
            <div>
              <MyButton
                name={"Transfer"}
                onClick={() => {
                  transferFiscalYearEarningHandler(fiscalYear, {
                    onSuccess(payload?: any): any {
                      getFiscalYearEarningHandler(fiscalYear);
                    },
                  });
                }}
              />
            </div>
          )}
        </>
      </div>
    </PageTemplate>
  );
}

export default RetainEarningSetupPage;
