import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { IconTypeEnum, ParamsStringEnum } from "interfaces";
import { NotificationContext } from "context";
import { ImageViewCard } from "components";
import { PageTemplate } from "templates";
import { getIconHelper } from "helpers";
import Skeleton from "react-loading-skeleton";
import { PageLinks } from "routes";
import moment from "moment";

function NotificationDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const { getDetailsHandler, details, isDetailsLoading } =
    useContext(NotificationContext);
  const ClockIcon = getIconHelper(IconTypeEnum.CLOCK);

  const id = params.id;
  useEffect(() => {
    if (id) {
      getDetailsHandler(id);
    }
  }, [id]);

  return (
    <PageTemplate
      title={"Notification Details"}
      breadCrumb={[
        {
          name: "Notification",
        },
      ]}
      backNavigation={{
        title: "Notification Details",
        path: PageLinks.notification.list,
      }}
    >
      <div className={"flex flex-col gap-10"}>
        {isDetailsLoading && <Skeleton className={"h-[200px]"} />}
        {details?.photo && (
          <div className={"h-[200px]"}>
            <ImageViewCard fileName={details?.photo} />
          </div>
        )}
        <div className={"flex justify-between items-center "}>
          {isDetailsLoading ? (
            <Skeleton className={"w-[400px]"} />
          ) : (
            <>
              <b className={"text-[18px]"}>{details?.title}</b>
              <span>
                {details?.createdAt && (
                  <div className={"flex items-center gap-2 text-[14px]"}>
                    <ClockIcon />
                    {moment(details?.createdAt)?.fromNow()}
                  </div>
                )}
              </span>
            </>
          )}
        </div>
        {details?.link && (
          <div
            className={`${
              details?.isRead ? "bg-gray-100" : "bg-white"
            }  rounded-full px-5 py-1 text-[14px]`}
          >
            {details?.link || "asdfjl;kjasd lkjas df"}
          </div>
        )}
        {isDetailsLoading ? (
          <Skeleton className={"h-[80px]"} />
        ) : (
          <p className={"text-justify text-[16px]"}>{details?.body}</p>
        )}
      </div>
    </PageTemplate>
  );
}

export default NotificationDetailsPage;
