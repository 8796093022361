import { MyIconButton } from "components";
import { IconTypeEnum, SpaceAccessListEnum } from "interfaces";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "routes";
import { NotificationDetails, SettingDetail } from "../assets";
import "./style.css";
import { useAuthorizationHook } from "useHooks";
import React, { useContext } from "react";
import { AppContext } from "context";
import { capitalizeFirstLetter, getIconHelper } from "helpers";

export default function TopNav() {
  const navigate = useNavigate();
  const { handlers, state } = useContext(AppContext);
  const ReloadIcon = getIconHelper(IconTypeEnum.RELOAD);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAccessEvents = checkCanAccessHandler([
    SpaceAccessListEnum.EVENT_READ_EVENT,
    SpaceAccessListEnum.EVENT_ADD_EVENT,
  ]);
  const canAccessDashboard = checkCanAccessHandler([
    SpaceAccessListEnum.MISC_ACCESS_DASHBOARD,
  ]);
  return (
    <div className="topNav_container ">
      <div className="flex gap-[120px] items-center justify-between ">
        <img
          onClick={() => canAccessDashboard && navigate("/")}
          className="cursor-pointer h-[65px] w-[130px]  select-none"
          src="/img/logo/logo.svg"
          alt="logo"
        />
        <div className={"flex items-center gap-5"}>
          {state?.profileDetails?.subscription && (
            <MyIconButton
              onClick={() => {
                navigate(PageLinks.shortCut.list);
              }}
              title={"Add shortcuts"}
              IconType={IconTypeEnum.ADD}
            />
          )}
          {canAccessEvents && (
            <MyIconButton
              title={"Events"}
              onClick={() => {
                navigate(PageLinks.events.list);
              }}
              IconType={IconTypeEnum.CALENDER}
            />
          )}
        </div>
      </div>
      <div></div>
      <div className={"flex flex-1 items-center justify-end gap-5"}>
        <div
          title={"Switch Workspace"}
          className={
            "bg-white rounded-md p-1 px-3 py-1 text-gray-500 cursor-pointer hover:bg-gray-200"
          }
          onClick={() => handlers.switchWorkspaceHandler()}
        >
          <div className={"flex items-center gap-5"}>
            <div className={"flex flex-col  items-start "}>
              <span className={"text-[12px] text-black"}>
                <b>Role: </b>
                {capitalizeFirstLetter(state?.profileDetails?.granted?.name)}
              </span>
              <span className={"text-black text-[12px]"}>
                <b>Workspace: </b>
                {capitalizeFirstLetter(state?.currentWorkspace?.tenantID?.name)}
              </span>
            </div>
            <div className={"text-[18px] text-tBlue"}>
              <ReloadIcon />
            </div>
          </div>
        </div>
        <NotificationDetails />
        <SettingDetail />
      </div>
    </div>
  );
}
