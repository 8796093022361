import React, { useContext, useEffect } from "react";
import { MyButton, TabComponent } from "components";
import { AddressText, FormWrapper, NameValueText } from "assets";

import { PageTemplate } from "templates";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CandidateDataContext } from "context";
import { IconTypeEnum, ParamsStringEnum, QueryStringEnum } from "interfaces";
import { getCurrency } from "helpers";
import { PageLinks } from "routes";

function CandidatesDetails() {
  const params = useParams<ParamsStringEnum>();
  const [query] = useSearchParams();
  const activeTab = query.get(QueryStringEnum.ACTIVE_TAB);
  const {
    details: candidateDetails,
    isDetailsLoading,
    getDetailsHandler,
  } = useContext(CandidateDataContext);
  const navigate = useNavigate();
  useEffect(() => {
    getDetailsHandler(params?.id);
  }, [params?.id]);

  return (
    <PageTemplate
      scrollAll
      title={"Candidates "}
      backNavigation={{
        title: "Candidate Details",
        path: PageLinks.candidates.list,
      }}
      titleRightChildren={
        <div>
          {candidateDetails?._id && (
            <MyButton
              isOutline
              iconType={IconTypeEnum.EDIT}
              name={"Edit Details"}
              onClick={() => {
                navigate(PageLinks.candidates.edit(candidateDetails?._id));
              }}
            />
          )}
        </div>
      }
    >
      <div className={"flex flex-col gap-10"}>
        <TabComponent
          tabs={[
            {
              title: "General Tab",
              pathName: "",
            },
            {
              title: "Additional Info",
              pathName: "additional",
            },
          ]}
        />
        {!activeTab ? (
          <>
            <FormWrapper
              header={{
                title: {
                  name: "Personal Information",
                },
              }}
            >
              <div className={"grid grid-cols-3 gap-5"}>
                <NameValueText
                  name={"First Name"}
                  isLoading={isDetailsLoading}
                  value={candidateDetails?.name?.first}
                />
                <NameValueText
                  name={"Middle Name"}
                  isLoading={isDetailsLoading}
                  value={candidateDetails?.name?.middle || "N/A"}
                />
                <NameValueText
                  name={"Last Name"}
                  isLoading={isDetailsLoading}
                  value={candidateDetails?.name?.last}
                />
              </div>
            </FormWrapper>{" "}
            <FormWrapper
              header={{
                title: {
                  name: "Contact Information",
                },
              }}
            >
              <div className={"grid grid-cols-3 gap-5"}>
                <NameValueText
                  name={"Email Address"}
                  isLoading={isDetailsLoading}
                  value={candidateDetails?.email}
                />
                <NameValueText
                  name={"Phone Number"}
                  isLoading={isDetailsLoading}
                  value={candidateDetails?.phone}
                />
                <NameValueText
                  name={"Mobile Number"}
                  isLoading={isDetailsLoading}
                  value={candidateDetails?.mobile}
                />
              </div>
            </FormWrapper>
            <FormWrapper
              header={{
                title: {
                  name: "Job Status",
                },
              }}
            >
              <div className={"grid grid-cols-3 gap-5"}>
                <NameValueText
                  name={"Profession"}
                  isLoading={isDetailsLoading}
                  value={candidateDetails?.profession?.label}
                />
                <NameValueText
                  name={`Revenues ${getCurrency()}`}
                  isLoading={isDetailsLoading}
                  value={`${getCurrency()}${candidateDetails?.revenue}`}
                />
              </div>
            </FormWrapper>
            <FormWrapper
              header={{
                title: {
                  name: "Property",
                },
              }}
            >
              <div className={"grid gap-5"}>
                <NameValueText
                  name={"Property"}
                  isLoading={isDetailsLoading}
                  value={
                    <div className={"flex gap-2 items-center"}>
                      ({candidateDetails?.property?.propertyID})
                      <AddressText
                        isOneLine
                        address={candidateDetails?.property?.address}
                      />
                    </div>
                  }
                />
              </div>
            </FormWrapper>
            {candidateDetails?.isTenantGuarantor && (
              <FormWrapper
                header={{
                  title: {
                    name: "Tenant guarantor",
                  },
                }}
              >
                <div className={"flex flex-col gap-5"}>
                  <NameValueText
                    name={"Subject"}
                    isLoading={isDetailsLoading}
                    value={candidateDetails?.tenantGuarantorSubject}
                  />{" "}
                  <NameValueText
                    name={"Message"}
                    isLoading={isDetailsLoading}
                    value={candidateDetails?.tenantGuarantorMessage}
                  />
                </div>
              </FormWrapper>
            )}
            <FormWrapper
              header={{
                title: {
                  name: "Invitation",
                },
              }}
            >
              <div className={"flex flex-col gap-5"}>
                <NameValueText
                  name={"Invitation Mail"}
                  isLoading={isDetailsLoading}
                  value={candidateDetails?.invitationText}
                />
              </div>
            </FormWrapper>
          </>
        ) : (
          <FormWrapper
            header={{
              title: {
                name: "Additional Info",
              },
            }}
          >
            <div className={"flex flex-col gap-5"}>
              <NameValueText
                name={"Comments"}
                isLoading={isDetailsLoading}
                value={candidateDetails?.comments}
              />
            </div>
          </FormWrapper>
        )}
      </div>
    </PageTemplate>
  );
}

export default CandidatesDetails;
