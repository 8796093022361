import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { BookingInterface } from "interfaces";
import React from "react";
import {
  getCurrency,
  getPropertyNameHandler,
  getStaffFullName,
} from "../HandlerHelpers";

interface PropsInterface {
  data?: BookingInterface[];
  dateFormatter?({ date }: { date: Date });
}
async function generateBookingReport({ data, dateFormatter }: PropsInterface) {
  try {
    //    create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Booking Report", {});
    worksheet.properties.defaultRowHeight = 20;
    worksheet.properties.defaultColWidth = 20;
    let tableHeaders = [
      {
        name: "SN.",
      },
      {
        name: "Property ID",
      },
      {
        name: "Property",
      },
      {
        name: "Unit ID",
      },
      {
        name: "Unit",
      },
      {
        name: "Tenant ID",
      },
      {
        name: "Tenant",
      },
      {
        name: "Amount",
      },
      {
        name: "Status",
      },
      {
        name: "Duration",
      },
    ];
    let tableRows = data?.map((e, index) => {
      const propertyName = getPropertyNameHandler(e?.property?.address);
      return [
        index + 1,
        e?.property?.propertyID,
        propertyName,
        e?.unit?.unitID,
        e?.unit?.name,
        e?.tenant?.userID,
        getStaffFullName(e?.tenant?.name),
        `${getCurrency()}${e?.rent?.rentCost}`,
        e?.status?.label,
        `${dateFormatter({ date: e?.arrivalDate })} - ${dateFormatter({
          date: e?.departureDate,
        })}`,
      ];
    });
    // add a table to a sheet
    worksheet.addTable({
      name: "Booking Report",
      ref: "A1",
      headerRow: true,
      totalsRow: false,
      style: {
        showRowStripes: false,
      },
      columns: tableHeaders,
      rows: tableRows,
    });
    //    save the workbook to a file
    const bufferFile = await workbook.xlsx.writeBuffer();
    await FileSaver.saveAs(
      new Blob([bufferFile], { type: "application/octet-stream" }),
      `bookings.xlsx`
    );
  } catch (err) {
    throw err;
  }
}

export default generateBookingReport;
