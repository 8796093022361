import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import {
  DashboardApiTypeEnum,
  DateRangeOptionEnum,
  IconTypeEnum,
  SpaceAccessListEnum,
} from "interfaces";
import {
  decodeFiscalYearOptionsHandler,
  decodePropertyOptionsHandler,
  getIconHelper,
  lastMonthEnd,
  lastMonthStart,
  lastYearEnd,
  lastYearStart,
  thisMonthEnd,
  thisMonthStart,
  thisYearEnd,
  thisYearStart,
  validateDateHandler,
} from "helpers";
import { DashboardStatsContext, OptionsContext } from "context";
import {
  BoxModal,
  MyButton,
  MySearchSelect,
  MySelectField,
  MyTextField,
} from "components";
import { Form, Formik } from "formik";
import { DateRangeOptions } from "content/cards/helpers";
import { FormConcludeButtons } from "assets";
import { useAuthorizationHook, usePageQueryHooks } from "useHooks";
import moment from "moment";

interface PropsInterface {
  getDataType: DashboardApiTypeEnum;
  havePropertyFilter?: boolean;
  haveDateFilter?: boolean;
  filterNewLine?: boolean;
  toggle?: boolean;
  title?: string;
  children(
    data: any,
    isLoading: boolean,
    error: string,
    dateFilter: DateFilterInterface
  );
  isViewAll?: {
    path: string;
  };
}
interface DateFilterInterface {
  startDate: Date;
  endDate: Date;
  type: DateRangeOptionEnum;
}
function ToggleContentWrapper({
  haveDateFilter,
  havePropertyFilter,
  title,
  getDataType,
  children,
  isViewAll,
  toggle,
  filterNewLine,
}: PropsInterface) {
  const { getStatsHandler } = useContext(DashboardStatsContext);
  const { propertyOptions } = useContext(OptionsContext);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState(null);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const [isToggle, setToggle] = useState(false);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canReadProperty = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_READ_PROPERTY,
  ]);
  useEffect(() => {
    setToggle(toggle);
  }, [toggle]);

  const [dateFilter, setDateFilter] = useState<DateFilterInterface>({
    startDate: null,
    endDate: null,
    type: null,
  });
  const [selectedProperty, setSelectedProperty] = useState("");
  const DownIcon = getIconHelper(IconTypeEnum.DOWN);
  const UpIcon = getIconHelper(IconTypeEnum.RIGHT_BOLD);
  const setDateFilterHandler = (payload) => {
    setDateFilter(payload);
  };
  useEffect(() => {
    (async () => {
      if (isToggle && getDataType) {
        setLoading(true);
        await getStatsHandler(
          getDataType,
          {
            startDate: dateFilter?.startDate,
            endDate: dateFilter?.endDate,
            property: selectedProperty,
          },
          {
            onError(error?: any) {
              setError(error);
              setLoading(false);
            },
            onSuccess(payload?: any): any {
              setData(payload);
              setLoading(false);
            },
          }
        );
      }
    })();
  }, [isToggle, selectedProperty, dateFilter]);
  return (
    <div className={"bg-white rounded-2xl p-5"}>
      <div
        className={`flex ${
          filterNewLine ? "flex-col gap-5" : "justify-between items-center"
        } `}
      >
        <div className={"flex items-center gap-5"}>
          <div
            className={
              "flex items-center justify-center bg-[#E8E8E8] rounded-full p-2 cursor-pointer"
            }
            onClick={() => setToggle((e) => !e)}
          >
            {!isToggle ? (
              <UpIcon className={"text-[16px] text-gray-600"} />
            ) : (
              <DownIcon className={"text-[16px] text-gray-600"} />
            )}
          </div>
          <span className={"text-[20px] font-bold text-[#00040E]"}>
            {title}
          </span>
        </div>
        <div className={"flex items-center gap-5"}>
          {havePropertyFilter && canReadProperty && (
            <MySearchSelect
              isClearable
              styles={{ height: "40px" }}
              onSelectHandler={(val) => setSelectedProperty(val)}
              option={{
                selectOptions: decodePropertyOptionsHandler(propertyOptions),
                defaultLabel: "All Property",
              }}
              selectedValue={selectedProperty}
            />
          )}
          {haveDateFilter && (
            <SelectDateFilterModal
              dateFilter={dateFilter}
              submitHandler={setDateFilterHandler}
            />
          )}
          {isViewAll && (
            <div>
              <MyButton
                className={"p-2 px-4 "}
                size={"md"}
                onClick={() => navigateWithReturnUrlHandler(isViewAll?.path)}
                isOutline={true}
                name={"View all"}
                colorType={"white"}
              />
            </div>
          )}
        </div>
      </div>
      {isToggle && (
        <div className={" border-t-[1px] mt-5"}>
          {!isLoading && children(data, isLoading, error, dateFilter)}
          {isLoading && (
            <div className={"text-center text-gray-500 mt-5 text-[16px] "}>
              Loading...
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export const SelectDateFilterModal = ({
  dateFilter,
  submitHandler,
}: {
  dateFilter: DateFilterInterface;
  submitHandler(payload);
}) => {
  const { fiscalYearOptions } = useContext(OptionsContext);
  const [isToggle, setToggle] = useState(false);
  const CalendarIcon = getIconHelper(IconTypeEnum.CALENDER);
  const DownIcon = getIconHelper(IconTypeEnum.DOWN);
  const getDateLabelHandler = () => {
    let label = "All Time";
    if (!dateFilter?.startDate || !dateFilter?.endDate) {
      return label;
    }
    switch (dateFilter?.type) {
      case DateRangeOptionEnum.CUSTOM:
        label = `${moment(dateFilter?.startDate)?.format(
          "DD MMM YYYY"
        )} - ${moment(dateFilter?.endDate)?.format("DD MMM YYYY")}`;
        break;
      case DateRangeOptionEnum.ALL_TIME:
        label = `${moment(dateFilter?.startDate)?.format(
          "DD MMM YYYY"
        )} - ${moment(dateFilter?.endDate)?.format("DD MMM YYYY")}`;
        break;
      case DateRangeOptionEnum.THIS_MONTH:
        label = `${moment(dateFilter?.startDate)?.format("MMM YYYY")}`;
        break;
      case DateRangeOptionEnum.LAST_MONTH:
        label = `${moment(dateFilter?.startDate)?.format("MMM YYYY")}`;
        break;
      case DateRangeOptionEnum.FISCAL_YEAR:
        label = `${moment(dateFilter?.startDate)?.format("YYYY")} - ${moment(
          dateFilter?.endDate
        )?.format("YYYY")}`;
        break;
      case DateRangeOptionEnum.LAST_YEAR:
        label = `${moment(dateFilter?.startDate)?.format("YYYY")} `;
        break;
      case DateRangeOptionEnum.THIS_YEAR:
        label = `${moment(dateFilter?.startDate)?.format("YYYY")} `;
        break;
      default:
        label = "invalid";
    }
    return label;
  };
  const validationSchema = yup.object().shape({
    startDate: yup.string(),
    endDate: yup
      .string()
      .test("custom-validation", "Invalid field value", function (value) {
        const { message, isError } = validateDateHandler(value, {
          minDate: this.parent.startDate,
        });
        return (
          !isError ||
          this.createError({
            path: "endDate",
            message: message,
          })
        );
      }),
  });
  return (
    <>
      <div
        onClick={() => setToggle(true)}
        className={
          "flex items-center gap-2 rounded-full px-4 cursor-pointer min-w-[50px] text-[16px] py-2 border-[1px] border-[#C0C0C0] text-gray-500"
        }
      >
        <CalendarIcon className={"text-[16px]"} />
        <span className={"text-[14px] whitespace-nowrap"}>
          {getDateLabelHandler()}
        </span>
        <DownIcon className={"text-[16px]"} />
      </div>
      {isToggle && (
        <BoxModal title={"Filter Date"} closeHandler={() => setToggle(false)}>
          <Formik
            initialValues={{
              type: dateFilter?.type,
              startDate: dateFilter?.startDate,
              endDate: dateFilter?.endDate,
              fiscalYear: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              submitHandler(values);
              setToggle(false);
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <div className={"flex flex-col  gap-5 "}>
                    <div className={"flex items-start gap-5 min-h-[100px]"}>
                      <MySelectField
                        isClearable
                        option={{
                          selectOptions: DateRangeOptions,
                          defaultLabel: "Select Date Range Type",
                          onChangeCallback(currentValue?: any) {
                            currentValue = currentValue?.value;
                            if (
                              currentValue === DateRangeOptionEnum.THIS_MONTH
                            ) {
                              setFieldValue("startDate", thisMonthStart);
                              setFieldValue("endDate", thisMonthEnd);
                            } else if (
                              currentValue === DateRangeOptionEnum.THIS_YEAR
                            ) {
                              setFieldValue("startDate", thisYearStart);
                              setFieldValue("endDate", thisYearEnd);
                            } else if (
                              currentValue === DateRangeOptionEnum.LAST_MONTH
                            ) {
                              setFieldValue("startDate", lastMonthStart);
                              setFieldValue("endDate", lastMonthEnd);
                            } else if (
                              currentValue === DateRangeOptionEnum.LAST_YEAR
                            ) {
                              setFieldValue("startDate", lastYearStart);
                              setFieldValue("endDate", lastYearEnd);
                            } else {
                              setFieldValue("startDate", "");
                              setFieldValue("endDate", "");
                            }
                          },
                        }}
                        label={"Period"}
                        name={"type"}
                      />
                      {values?.type === DateRangeOptionEnum.FISCAL_YEAR && (
                        <MySelectField
                          isClearable
                          option={{
                            selectOptions:
                              decodeFiscalYearOptionsHandler(fiscalYearOptions),
                            defaultLabel: "Fiscal Year",
                            onChangeCallback(currentValue?: any) {
                              if (!currentValue) {
                                return;
                              }
                              const fiscalYear = fiscalYearOptions.find(
                                (e) => e?.title == currentValue?.label
                              );
                              if (!fiscalYear) {
                                return;
                              }
                              setFieldValue("startDate", fiscalYear.startFrom);
                              setFieldValue("endDate", fiscalYear.endTo);
                            },
                          }}
                          label={"Fiscal Year"}
                          name={"fiscalYear"}
                        />
                      )}
                      {values?.type === DateRangeOptionEnum.CUSTOM && (
                        <>
                          <MyTextField
                            label={"Start Date"}
                            type={"date"}
                            name={"startDate"}
                          />
                          <MyTextField
                            label={"End Date"}
                            type={"date"}
                            name={"endDate"}
                          />
                        </>
                      )}
                    </div>
                    <FormConcludeButtons
                      submitButton={{
                        title: "Filter",
                      }}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </BoxModal>
      )}
    </>
  );
};

export default ToggleContentWrapper;
