import React, { useContext, useEffect } from "react";
import { FormWrapper } from "assets";
import {
  MyRadioInput,
  MySelectField,
  MyTextAreaInput,
  MyTextField,
  MyUploadDocument,
} from "components";
import { AddressForm } from "content";
import { useFormikContext } from "formik";
import {
  BankSourceEnum,
  DateTimeFormatEnum,
  NameFormatEnum,
  SpaceSettingsInterface,
  TimeFormatEnum,
} from "interfaces";
import { OptionsContext } from "context";
import { decodeBankOptionsHandler } from "helpers";
import { generateDaysOptionsForMonth, monthsOptions } from "../helpers";
import moment from "moment";

function SpaceSettingForm() {
  const { values, setFieldValue } =
    useFormikContext<SpaceSettingsInterface<string>>();
  const { getBankOptionsHandler, bankOptions } = useContext(OptionsContext);
  useEffect(() => {
    getBankOptionsHandler(BankSourceEnum.Manual);
  }, []);

  return (
    <div className={"flex flex-col gap-10 pb-20"}>
      <FormWrapper header={{ title: { name: "Company" } }}>
        <div className={"flex flex-col gap-5"}>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <MyTextField
              autoFocus
              name={"companyName"}
              placeholder={"Eg. UK Property PVT. LTD."}
              label={"Company Name"}
            />
            <MyTextField
              name={"companyRegistration"}
              placeholder={"eg. 756-86-AHM-45"}
              label={"Company Registration Number"}
            />
            <MyTextField
                name={"companyPan"}
                placeholder={"eg. 756-86-AHM-45"}
                label={"VAT Registration Number"}
            />
          </div>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <MyUploadDocument
              fileType={"image"}
              fileContainerStyle={"grid grid-cols-1"}
              label={"Logo"}
              name={"companyLogo"}
            />
          </div>

          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <MyRadioInput
              name={"isCorporateAccount"}
              label={"Corporate Account?"}
            />
          </div>
          {values?.isCorporateAccount && (
            <AddressForm
              country={{
                value: values?.companyAddress?.country,
              }}
            />
          )}
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "Footer" } }}>
        <div className={"grid grid-cols-3 md:grid-cols-1 sm:grid-cols-1"}>
          <div className={"flex flex-col gap-2 col-span-2"}>
            <MyTextAreaInput name={"footerText"} label={""} />
            <span className={"text-[12px] text-gray-500"}>
              Leave empty to use the default footer.
            </span>
          </div>
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "Bank Account" } }}>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1"}>
          <MySelectField
            option={{
              selectOptions: decodeBankOptionsHandler(bankOptions),
            }}
            name={"defaultBank"}
          />
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "QR Code" } }}>
        <div className={"grid grid-cols-2"}>
          <div>
            <MyRadioInput
              radioButtonType={"switch"}
              name={"showQrOnRecipient"}
              label={"Show the QR code on the rent receipts"}
            />
          </div>
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "Invoice" } }}>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1"}>
          <MyTextField
            name={"showFutureInvoiceInDays"}
            placeholder={"Eg. 10"}
            type={"number"}
            label={"Show future Invoice (days)"}
          />
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "Invoice Prefix" } }}>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <MyTextField
            name={"invoicePrefix.separator"}
            placeholder={"eg: -"}
            label={"Separator"}
          />
          <MyTextField
            name={"invoicePrefix.SALES"}
            placeholder={"eg: S"}
            label={"Sales"}
          />{" "}
          <MyTextField
            name={"invoicePrefix.SALES_RETURN"}
            placeholder={"eg: SR"}
            label={"Sales Return"}
          />
          <MyTextField
            name={"invoicePrefix.PURCHASE"}
            placeholder={"eg: P"}
            label={"Purchase"}
          />
          <MyTextField
            name={"invoicePrefix.PURCHASE_RETURN"}
            placeholder={"eg: PR"}
            label={"Purchase Return"}
          />
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "Name,Time & Date Format" } }}>
        <div
          className={
            "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
          }
        >
          <div className={"flex flex-col gap-2"}>
            <MySelectField
              name={"nameFormat"}
              label={"Name Format"}
              option={{
                selectOptions: [
                  {
                    label: "First Name First",
                    value: NameFormatEnum.FIRST_NAME_FIRST,
                  },
                  {
                    label: "Last Name First",
                    value: NameFormatEnum.LAST_NAME_FIRST,
                  },
                ],
              }}
            />
            {values?.nameFormat && (
              <span className={"text-[14px] text-gray-500 px-2"}>
                eg:{" "}
                {values?.nameFormat === NameFormatEnum.FIRST_NAME_FIRST
                  ? "John Doe"
                  : "Doe John"}
              </span>
            )}
          </div>
          <div className={"flex flex-col gap-2"}>
            <MySelectField
              name={"timeFormat"}
              label={"Time Format"}
              option={{
                selectOptions: [
                  {
                    label: "24 Hour",
                    value: TimeFormatEnum.Twenty_Four_HOUR,
                  },
                  {
                    label: "12 Hour",
                    value: TimeFormatEnum.TWELVE_HOUR,
                  },
                ],
              }}
            />
            {values?.timeFormat && (
              <span className={"text-[14px] text-gray-500 px-2"}>
                eg:{" "}
                {values?.timeFormat === TimeFormatEnum.TWELVE_HOUR
                  ? "05:00 PM"
                  : "17:00"}
              </span>
            )}
          </div>

          <div className={"flex flex-col gap-2"}>
            <MySelectField
              name={"dateFormat"}
              label={"Date Format"}
              option={{
                selectOptions: [
                  {
                    label: "YYYY-MMM-DD",
                    value: DateTimeFormatEnum["YYYY-MMM-DD"],
                  },
                  {
                    label: "DD-MM-YYYY",
                    value: DateTimeFormatEnum["DD-MM-YYYY"],
                  },
                  {
                    label: "DD/MMM/YYYY",
                    value: DateTimeFormatEnum["DD/MMM/YYYY"],
                  },
                  {
                    label: "MMM DD, YYYY",
                    value: DateTimeFormatEnum["MMM DD, YYYY"],
                  },
                ],
              }}
            />
            {values?.dateFormat && (
              <span className={"text-[14px] text-gray-500 px-2"}>
                eg: {moment().format(values?.dateFormat)}
              </span>
            )}
          </div>
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "Accounting" } }}>
        <div
          className={
            "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
          }
        >
          <MySelectField
            name={"fiscalYearStartMonth"}
            label={"Fiscal Month"}
            isClearable
            option={{
              selectOptions: monthsOptions,
              onChangeCallback(currentValue?: any) {
                setFieldValue("fiscalYearStartDay", "");
              },
            }}
          />
          <MySelectField
            name={"fiscalYearStartDay"}
            label={"Fiscal Month Date"}
            isClearable
            option={{
              selectOptions: generateDaysOptionsForMonth(
                values?.fiscalYearStartMonth
              ),
            }}
          />
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "Trash" } }}>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1"}>
          <MyTextField
            isNumberOnly
            name={"autoTrashDeleteDay"}
            placeholder={"Eg. 10"}
            label={"Auto-Delete (In Days)"}
          />
        </div>
      </FormWrapper>
    </div>
  );
}

export default SpaceSettingForm;
