import React, { useContext } from "react";
import { AddressText, FormWrapper, NameValueText } from "assets";
import {
  EmptyMessageComponent,
  FileViewCard,
  MyButton,
  MyCheckButton,
} from "components";
import { Outlet, useNavigate } from "react-router-dom";

import { useAuthorizationHook, useDateHook } from "useHooks";
import { getCurrency, getTaxNameHandler, separateWithComma } from "helpers";
import { TenancyDataContext } from "context";
import { PageLinks } from "routes";
import { IconTypeEnum, SpaceAccessListEnum } from "interfaces";

function TenancyDetailTab() {
  const { details, isDetailsLoading } = useContext(TenancyDataContext);
  const navigate = useNavigate();
  const { getDateHandler, getStaffFullName } = useDateHook();
  const { checkCanAccessHandler } = useAuthorizationHook();

  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.TENANCY_EDIT_TENANCY,
  ]);

  if (!isDetailsLoading && !details?._id) {
    return (
      <div className={"mt-5"}>
        <EmptyMessageComponent message={"No data"} />
      </div>
    );
  }
  return (
    <div className={"flex flex-col gap-10"}>
      <FormWrapper
        header={{
          title: {
            name: "General",
          },
          rightChildren: canEdit && details?.isDraft && (
            <div>
              <MyButton
                name={"Edit Tenancies"}
                iconType={IconTypeEnum.EDIT}
                onClick={() => {
                  navigate(PageLinks.tenancy.edit(details?._id));
                }}
              />
            </div>
          ),
        }}
      >
        <div className={"flex flex-col gap-8"}>
          <div className={"flex flex-col gap-5"}>
            <NameValueText
              alignDirection={"row"}
              name={"Property"}
              isLoading={isDetailsLoading}
              value={
                <div className={"flex gap-2 items-center"}>
                  <span className={"text-tBlue font-bold text-[14px]"}>
                    {details?.property?.propertyID}
                  </span>
                  <AddressText isOneLine address={details?.property?.address} />
                </div>
              }
            />
            <NameValueText
              alignDirection={"row"}
              name={"Unit"}
              isLoading={isDetailsLoading}
              value={
                <div className={"flex gap-2 items-center"}>
                  <span className={"text-tBlue font-bold text-[14px]"}>
                    {details?.unit?.unitID}
                  </span>
                  <span>{details?.unit?.name}</span>
                </div>
              }
            />
          </div>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              alignDirection={"row"}
              name={"Tenant"}
              isLoading={isDetailsLoading}
              value={getStaffFullName(details?.tenancy?.tenant?.name)}
            />
          </div>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              alignDirection={"row"}
              name={"Tenancy Type"}
              isLoading={isDetailsLoading}
              value={details?.tenancy?.tenancyType?.value}
            />
            <NameValueText
              alignDirection={"row"}
              name={"Agreement Date"}
              isLoading={isDetailsLoading}
              value={getDateHandler({ date: details?.tenancy?.agreementOn })}
            />
            <NameValueText
              alignDirection={"row"}
              name={"Rent Commencement Date"}
              isLoading={isDetailsLoading}
              value={getDateHandler({
                date: details?.tenancy?.rentCommencementOn,
              })}
            />
          </div>
        </div>
      </FormWrapper>
      <FormWrapper
        header={{
          title: {
            name: "Tenancy Duration/Contractual Term",
          },
        }}
      >
        <div className={"flex flex-col gap-8"}>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              alignDirection={"row"}
              name={"Duration"}
              isLoading={isDetailsLoading}
              value={details?.duration?.durationQuantity}
            />
            <NameValueText
              alignDirection={"row"}
              name={"Frequency"}
              isLoading={isDetailsLoading}
              value={details?.duration?.durationFrequency?.label}
            />
          </div>
        </div>
      </FormWrapper>
      <FormWrapper
        header={{
          title: {
            name: "Rent",
          },
        }}
      >
        <div className={"flex flex-col gap-8"}>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              alignDirection={"row"}
              name={`Rent Amount (${getCurrency()})`}
              isLoading={isDetailsLoading}
              value={details?.rent?.rentCost}
            />
            <NameValueText
              alignDirection={"row"}
              name={"Frequency"}
              isLoading={isDetailsLoading}
              value={details?.rent?.rentFrequency?.label}
            />
          </div>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              alignDirection={"row"}
              name={`Tax Type`}
              isLoading={isDetailsLoading}
              value={getTaxNameHandler(details?.rent?.taxType)}
            />
            <NameValueText
              alignDirection={"row"}
              name={`Tax Rate`}
              isLoading={isDetailsLoading}
              value={details?.rent?.taxRate?.label}
            />
          </div>
        </div>
      </FormWrapper>
      <FormWrapper
        header={{
          title: {
            name: "Initial Rent Amount",
          },
        }}
      >
        <div className={"flex flex-col gap-8"}>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              alignDirection={"row"}
              name={"Due Date"}
              isLoading={isDetailsLoading}
              value={getDateHandler({
                date: details?.initialRent?.initialRentDueDate,
              })}
            />
            <NameValueText
              alignDirection={"row"}
              name={`Amount (${getCurrency()})`}
              isLoading={isDetailsLoading}
              value={details?.initialRent?.initialRentCost}
            />
          </div>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <div className={"col-span-2"}>
              <NameValueText
                alignDirection={"row"}
                name={"Remarks"}
                isLoading={isDetailsLoading}
                value={details?.initialRent?.initialRentNote}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
      <FormWrapper
        header={{
          title: {
            name: "Recurring Payment",
          },
        }}
      >
        <div className={"flex flex-col gap-8"}>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              alignDirection={"row"}
              name={"Payment Term"}
              isLoading={isDetailsLoading}
              value={details?.recurringPayment?.recurringPaymentTerms?.value}
            />
            <NameValueText
              alignDirection={"row"}
              name={`Frequency`}
              isLoading={isDetailsLoading}
              value={details?.recurringPayment?.recurringFrequency?.label}
            />
          </div>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            {details?.recurringPayment?.recurringDue?.map((e, key) => {
              return (
                <div key={key} className={"col-span-3"}>
                  <NameValueText
                    alignDirection={"row"}
                    name={"Due Date"}
                    isLoading={isDetailsLoading}
                    value={getDateHandler({ date: e })}
                  />
                </div>
              );
            })}
          </div>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <div className={"col-span-2"}>
              <NameValueText
                alignDirection={"row"}
                name={"Remarks"}
                isLoading={isDetailsLoading}
                value={details?.recurringPayment?.recurringRemarks}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
      <FormWrapper
        header={{
          title: {
            name: "Opening Balance",
          },
        }}
      >
        <div className={"flex flex-col gap-8"}>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              alignDirection={"row"}
              name={"Due Date"}
              isLoading={isDetailsLoading}
              value={
                getDateHandler({
                  date: details?.openingBalance?.openingBalanceDue,
                }) || "-"
              }
            />
            <NameValueText
              alignDirection={"row"}
              name={`Amount (${getCurrency()})`}
              isLoading={isDetailsLoading}
              value={separateWithComma(
                details?.openingBalance?.openingBalanceAmount
              )}
            />
            <NameValueText
              alignDirection={"row"}
              name={`Balance Type`}
              isLoading={isDetailsLoading}
              value={details?.openingBalance?.balanceType?.label || "-"}
            />
          </div>
        </div>
      </FormWrapper>
      {details?.utilitiesCharges?.length > 0 && (
        <FormWrapper
          header={{
            title: {
              name: "Utilities Charges",
            },
          }}
        >
          <div className={"flex flex-col gap-8"}>
            <div
              className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
            >
              {details?.utilitiesCharges?.map((e, key) => {
                return (
                  <MyCheckButton
                    key={key}
                    label={e?.label}
                    isChecked={true}
                    value={e?.value}
                  />
                );
              })}
            </div>
          </div>
        </FormWrapper>
      )}

      <FormWrapper
        header={{
          title: {
            name: "Security Deposit",
          },
        }}
      >
        <div className={"flex flex-col gap-8"}>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              alignDirection={"row"}
              name={"Charge Type"}
              isLoading={isDetailsLoading}
              value={details?.securityDeposit?.chargeType?.label || "-"}
            />
            <NameValueText
              alignDirection={"row"}
              name={`Amount (${getCurrency()})`}
              isLoading={isDetailsLoading}
              value={separateWithComma(
                details?.securityDeposit?.securityDepositedAmount
              )}
            />
            <NameValueText
              alignDirection={"row"}
              name={`Deposit Date`}
              isLoading={isDetailsLoading}
              value={getDateHandler({
                date: details?.securityDeposit?.securityDepositedOn,
              })}
            />
          </div>
          <div>
            <NameValueText
              alignDirection={"row"}
              name={`Remarks`}
              isLoading={isDetailsLoading}
              value={details?.securityDeposit?.securityDepositedNote || "-"}
            />
          </div>
        </div>
      </FormWrapper>
      {details?.otherCharges?.length > 0 && (
        <FormWrapper
          header={{
            title: {
              name: "Other Charges",
            },
          }}
        >
          <div className={"flex flex-col gap-8"}>
            <div
              className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
            >
              {details?.otherCharges?.map((e, key) => {
                return (
                  <MyCheckButton
                    key={key}
                    label={e?.label}
                    isChecked={true}
                    value={e?.value}
                  />
                );
              })}
            </div>
          </div>
        </FormWrapper>
      )}

      <FormWrapper
        header={{
          title: {
            name: "Bank Account Details",
          },
        }}
      >
        <div className={"flex flex-col gap-8"}>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              alignDirection={"row"}
              name={"Bank Name"}
              isLoading={isDetailsLoading}
              value={details?.bank?.label || "-"}
            />
            <NameValueText
              alignDirection={"row"}
              name={`Account Number`}
              isLoading={isDetailsLoading}
              value={details?.bank?.accountNumber || "-"}
            />
          </div>
        </div>
      </FormWrapper>
      <FormWrapper
        header={{
          title: {
            name: "Advance Options",
          },
        }}
      >
        <div className={"flex flex-col gap-8"}>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <MyCheckButton
              label={"Invoice Mail Before Due Date"}
              isChecked={details?.advance?.invoiceMailBeforeDueDate}
              value={""}
            />
            <MyCheckButton
              label={"Maximum Daily Late Fees"}
              isChecked={details?.advance?.maximumDailyLateFees}
              value={""}
            />
            <MyCheckButton
              label={"Maximum Monthly Late Fees"}
              isChecked={details?.advance?.maximumMonthlyLateFees}
              value={""}
            />
            <MyCheckButton
              label={"Maximum Balance To Apply Late Fees"}
              isChecked={details?.advance?.minimumBalanceToApplyLateFee}
              value={""}
            />
            <MyCheckButton
              label={"Notify Late Fee Change To Tenant"}
              isChecked={details?.advance?.notifyLateFeeChangeToTenant}
              value={""}
            />
          </div>
        </div>
      </FormWrapper>
      {details?.agreementDocuments?.length > 0 && (
        <FormWrapper
          header={{
            title: {
              name: "Agreement Documents",
            },
          }}
        >
          <div className={"grid grid-cols-5 md:grid-cols-3"}>
            {details?.agreementDocuments?.map((e, key) => {
              return <FileViewCard canDownload key={key} filePath={e} />;
            })}
          </div>
        </FormWrapper>
      )}

      <Outlet />
    </div>
  );
}

export default TenancyDetailTab;
