import React, { useContext } from "react";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsDownload } from "react-icons/bs";
import { DocumentViewInterface } from "./interfaces";
import { Api, downloadBlobFileHandler } from "helpers";
import { ApiUrl } from "services";
import { AppContext } from "context";

function DocumentView({ file, isLoading }: DocumentViewInterface) {
  const { getBlobResApi } = Api();
  const {
    handlers: { setLoading, setError },
  } = useContext(AppContext);
  const downloadDocumentHandler = async () => {
    try {
      setLoading(true);
      const res = await getBlobResApi(`${ApiUrl.file.get_file}${file}`);
      downloadBlobFileHandler(res, file);
    } catch (err) {
      setError(true, err?.message || "No file exist");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div
      className={
        "flex flex-col gap-2 items-center w-[140px] overflow-hidden cursor-pointer "
      }
    >
      <div
        className={
          "relative flex items-center justify-center overflow-hidden w-[140px] h-[120px] border-2 border-primary rounded-md bg-gray-50"
        }
      >
        <IoDocumentTextOutline fontSize={50} color={"gray"} />
        <div
          className={
            "absolute top-0 flex items-center justify-center   w-full h-full   "
          }
        >
          {!isLoading && (
            <div
              onClick={downloadDocumentHandler}
              className={"bg-primary  text-white p-2 rounded-md"}
            >
              <BsDownload fontSize={16} />
            </div>
          )}
          <div className="absolute bottom-0 left-0 bg-tRed text-white p-1 rounded-sm text-[12px]">
            {file?.split(".")[1]}
          </div>
        </div>
      </div>
      <span className={"text-[12px] overflow-ellipsis truncate w-[120px]"}>
        {file}
      </span>
    </div>
  );
}

export default DocumentView;
