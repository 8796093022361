import { FieldTypeEnum } from "interfaces";
export interface ItemFormInterface {
  fieldType?: string;
  label?: string;
  description?: string;
  _id?: string;
}

export enum ParamsEnum {
  Module = "MODULE",
  SUBMODULE = "SUBMODULE",
  FIELD = "FIELD",
  SYSTEM = "SYSTEM",
  SYSTEM_TYPE = "SYSTEM_TYPE",
}
export enum QueryEnum {
  ID = "id",
  PARENT_ID = "parentID",
  PARENT_NAME = "parentName",
}

export interface SystemInterface {
  name: string;
  description: string;
  key: FieldTypeEnum;
}
export interface SystemDataInterface {
  _id: string;
  name: string;
  child?: SystemInterface[];
  isActive: boolean;
}
export enum ModuleEnum {
  RENTAL = "RENTAL",
  TENANTS = "TENANTS",
  TENANCIES = "TENANCIES",
}

export enum SubModuleEnum {
  PROPERTY = "PROPERTY",
  UNIT = "UNIT",
}

export type ModuleInterface = {
  [key in ModuleEnum]: {
    Icon: any;
    name: string;
  };
};

export type SubModuleInterface = {
  [key in ModuleEnum]: {
    Icon: any;
    name: string;
    key: SubModuleEnum;
  }[];
};

export type FieldSetInterface = {
  [key in SubModuleEnum]: {
    name: string;
    details: string;
    key: FieldTypeEnum;
  }[];
};
