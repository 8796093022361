import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MyButton, TableCardComponent } from "components";
import { AccessVerifier, PageTemplate } from "templates";
import {
  FieldTypeEnum,
  IconTypeEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { TableFilterCard } from "content";

import { BookingDataContext, OptionsContext, ReportContext } from "context";
import { usePageQueryHooks, usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";

import { BookingTableColumns } from "../components";

function BookingsPage() {
  const {
    getAllDataHandlers,
    allData,
    totalDocs,
    totalArchiveDocs,
    isLoading,
  } = useContext(BookingDataContext);
  const { generateBookingReportHandler } = useContext(ReportContext);
  const navigate = useNavigate();
  const { getOptionsHandler } = useContext(OptionsContext);
  const { isArchive, paginationQuery, currentPage } = usePaginationQueryHooks();
  const { unitID, propertyID, status } = usePageQueryHooks();

  const pageQuery = {
    unit: unitID,
    property: propertyID,
    bookingStatus: status,
    ...paginationQuery,
  };
  useEffect(() => {
    getAllDataHandlers({
      ...pageQuery,
    });
    getOptionsHandler(FieldTypeEnum.BOOKING_STATUS);
  }, [isArchive, currentPage, unitID, propertyID, status]);

  const tableCardProps = {
    column: BookingTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      leftChildren: (
        <TableFilterCard
          haveProperty
          haveUnit
          haveDynamicOption={{
            queryName: QueryStringEnum?.STATUS,
            fieldName: FieldTypeEnum.BOOKING_STATUS,
            placeholder: "Select Booking Status",
          }}
        />
      ),
      rightChildren: (
        <AccessVerifier
          accessKeys={[SpaceAccessListEnum.BOOKING_ADD_BOOKING]}
          isForPage={false}
        >
          <MyButton
            onClick={() => {
              navigate(PageLinks.bookings.new);
            }}
            iconType={IconTypeEnum.ADD}
            name={"Add Booking"}
          />
        </AccessVerifier>
      ),
    },
  };
  return (
    <PageTemplate
      title={"Bookings"}
      breadCrumb={[
        {
          name: "Bookings",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        exportXlsx={{
          generateHandler: () => generateBookingReportHandler(pageQuery),
        }}
        {...tableCardProps}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default BookingsPage;
