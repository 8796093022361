import React, { useContext } from "react";
import {
  QueryStringEnum,
  SpaceAccessListEnum,
  TableColumnInterface,
  TenancyInterface,
} from "interfaces";
import { MyMoreOptionButton } from "components";
import { PropertyUnitTableText, UserTableProfile } from "assets";
import { TenancyDataContext } from "context";
import { getCurrency, separateWithComma } from "helpers";
import { PageLinks } from "routes";
import {
  useAuthorizationHook,
  useDateHook,
  usePageQueryHooks,
  usePaginationQueryHooks,
} from "useHooks";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

function TenancyTableColumns(
  pageQuery?: any
): TableColumnInterface<TenancyInterface>[] {
  const [query] = useSearchParams();
  let activeTab = query.get(QueryStringEnum?.ACTIVE_TAB);
  const { getDateHandler, getStaffFullName } = useDateHook();
  const {
    deleteHandler,
    endTenancyHandler,
    archiveHandler,
    getAllDataHandlers,
  } = useContext(TenancyDataContext);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { calcSNHandler } = usePaginationQueryHooks();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.TENANCY_EDIT_TENANCY,
  ]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.TENANCY_DEL_TENANCY,
  ]);
  const canReadInvoice = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_READ_INVOICE,
  ]);
  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },
    {
      title: "Property",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.property?.propertyID}
            address={renderData?.property?.address}
            propertyID={renderData?.property?._id}
          />
        );
      },
    },
    {
      title: "Unit",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.unit?.unitID}
            type={"unit"}
            name={renderData?.unit?.name}
            propertyID={renderData?.property?._id}
            unitID={renderData?.unit?._id}
          />
        );
      },
    },

    {
      title: "Tenant",
      render(renderData) {
        return (
          <UserTableProfile
            firstLetter={renderData?.tenancy?.tenant?.name?.first?.charAt(0)}
            details={{
              profile: renderData?.tenancy?.tenant?.photo,
              subTitle: renderData?.tenancy?.tenant?.phone || "-",
              name: getStaffFullName(renderData?.tenancy?.tenant?.name),
            }}
          />
        );
      },
    },

    {
      title: "Term",
      render(renderData) {
        return (
          <div className={"flex items-start "}>
            <span>
              {renderData?.duration?.durationQuantity || 0}{" "}
              {renderData?.duration?.durationFrequency?.label}
            </span>
          </div>
        );
      },
    },
    {
      title: "Rent",
      render(renderData) {
        return (
          <div className={"flex items-start "}>
            <span>
              {getCurrency()}
              {separateWithComma(renderData?.rent?.rentCost)}
              {"  "}
              {renderData?.rent?.rentFrequency?.label}
            </span>
          </div>
        );
      },
    },
    {
      title: "Deposit",
      render(renderData) {
        return (
          <div className={"flex items-start "}>
            <span>
              {getCurrency()}
              {separateWithComma(
                renderData?.securityDeposit?.securityDepositedAmount
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: "Balance",
      render(renderData) {
        return (
          <div className={"flex items-start "}>
            <span>
              {getCurrency()}
              {separateWithComma(renderData?.initialRent?.initialRentCost)}
            </span>
          </div>
        );
      },
    },
    {
      title: "Expires",
      render(renderData) {
        const date =
          renderData?.tenancy?.rentTerminatedOn ||
          renderData?.tenancy?.rentEndDate;
        if (!date) {
          return <span>-</span>;
        }
        const formattedDate = getDateHandler({ date });
        return (
          <div className={"flex items-start "}>
            <span>{moment(new Date(formattedDate)).fromNow()}</span>
          </div>
        );
      },
    },

    {
      title: "Action",
      render(renderData) {
        const date =
          renderData?.tenancy?.rentTerminatedOn ||
          renderData?.tenancy?.rentEndDate;
        let isExpired = moment(new Date(date)).isBefore();
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigateWithReturnUrlHandler(
                      PageLinks.tenancy.details(renderData?._id)
                    );
                  },
                },
                canEdit &&
                  renderData?.isDraft &&
                  !renderData?.isArchived && {
                    name: "Edit Details",
                    handler: () => {
                      navigateWithReturnUrlHandler(
                        PageLinks.tenancy.edit(renderData?._id),
                        `${QueryStringEnum.ACTIVE_TAB}=${activeTab}`
                      );
                    },
                  },
                canReadInvoice &&
                  !(renderData?.isArchived || renderData?.isDraft) && {
                    name: "Invoice",
                    handler: () => {
                      navigateWithReturnUrlHandler(
                        PageLinks.tenancy.details(renderData?._id) + "/invoice"
                      );
                    },
                  },
                canEdit &&
                  !(renderData?.isDraft || isExpired) && {
                    name: "End Tenancy",
                    handler: () => {
                      endTenancyHandler(renderData?._id, {
                        async onSuccess(payload?: any) {
                          getAllDataHandlers(pageQuery);
                        },
                      });
                    },
                  },
                canDelete &&
                  renderData?.isDraft && {
                    name: renderData?.isArchived ? `Unarchive` : "Archive",
                    handler: () => {
                      archiveHandler(renderData?._id, renderData?.isArchived, {
                        async onSuccess(payload?: any) {
                          getAllDataHandlers(pageQuery);
                        },
                      });
                    },
                  },
                canDelete &&
                  renderData?.isDraft && {
                    name: "Delete Tenancy",
                    handler: () => {
                      deleteHandler(renderData?._id, {
                        async onSuccess(payload?: any) {
                          getAllDataHandlers(pageQuery);
                        },
                      });
                    },
                  },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default TenancyTableColumns;
