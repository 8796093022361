import React from "react";
import { Route } from "react-router-dom";
import { UserPage, UserDetailsPage, NewUserPage } from "./pages";

import {
  ParamsStringEnum,
  SpaceAccessListEnum,
  UserRoleEnum,
} from "interfaces";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="spaceUser/">
      <Route
        path={`users/${UserRoleEnum.SUPPLIER}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.AUTH_ACCESS_SUPPLIER]}
            isForPage={true}
          >
            <UserPage isFromSupplier />
          </AccessVerifier>
        }
      />
      <Route
        path={`users/:${ParamsStringEnum.USER_TYPE}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.AUTH_READ_USER]}
            isForPage={true}
          >
            <UserPage isFromSupplier={false} />
          </AccessVerifier>
        }
      />
      <Route
        path={`users/new`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.AUTH_ADD_USER]}
            isForPage={true}
          >
            <NewUserPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`users/edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.AUTH_EDIT_USER]}
            isForPage={true}
          >
            <NewUserPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`users/details/:${ParamsStringEnum?.USER_ID}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.AUTH_READ_USER]}
            isForPage={true}
          >
            <UserDetailsPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
