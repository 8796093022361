import React, { useContext, useEffect } from "react";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { FormConcludeButtons } from "assets";
import { PageTemplate } from "templates";
import { FieldTypeEnum, ParamsStringEnum } from "interfaces";
import { AppContext, MeterReadingContext, OptionsContext } from "context";

import { MeterReadingFormikValues } from "../../helpers";
import { MeterReadingForm } from "../../components";
import { PageLinks } from "routes";

function NewMeterReadingPage() {
  const navigate = useNavigate();

  const { getPropertyOptionsHandler, getOptionsHandler } =
    useContext(OptionsContext);
  const params = useParams<ParamsStringEnum>();
  const {
    handlers: { setLoading },
  } = useContext(AppContext);
  const {
    details: meterReadingsDetails,
    editDetailsHandler,
    getDetailsHandler,
  } = useContext(MeterReadingContext);
  const isEdit = params?.id;
  useEffect(() => {
    (async () => {
      setLoading(true);
      await getOptionsHandler(FieldTypeEnum.METER_TYPE);
      await getPropertyOptionsHandler();
      if (isEdit) {
        await getDetailsHandler(isEdit);
      }
      setLoading(false);
    })();
  }, [isEdit]);

  const submitHandler = async (values, formikHelpers) => {
    await editDetailsHandler(values, formikHelpers, {
      onSuccess: async (payload) => {
        navigate(PageLinks.meterReading.list);
      },
    });
  };
  return (
    <PageTemplate
      title={"Tools"}
      scrollAll
      breadCrumb={[
        {
          name: "Meter Reading",
        },
        {
          name: `${isEdit ? "Edit" : "New"} Reading`,
        },
      ]}
      backNavigation={{
        title: `${isEdit ? "Edit" : "New"} Reading`,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={MeterReadingFormikValues?.initialValues(
          isEdit && meterReadingsDetails
        )}
        validationSchema={MeterReadingFormikValues?.validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={"flex flex-col gap-5"}>
              <MeterReadingForm />
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default NewMeterReadingPage;
