import ExcelJS from "exceljs";
import FileSaver from "file-saver";

import React from "react";
import {
  BalanceSheetIncomeStatementRenderInterface,
  ContentTypeEnum,
} from "../interfaces";

interface GenerateIncomeStatementReportInterface {
  data?: BalanceSheetIncomeStatementRenderInterface;
  dateHeader?: string;
  organizationName: string;
  isCompared: boolean;
  compareHeader: string;
  dateFormatter?({ date }: { date: Date | string });
  isFromBalanceSheet?: boolean;
}
async function generateIncomeStatementReport({
  data,
  compareHeader,
  dateHeader,
  organizationName,
  dateFormatter,
  isCompared,
  isFromBalanceSheet,
}: GenerateIncomeStatementReportInterface) {
  try {
    //    create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    let worksheetTitle = "Balance Sheet";
    if (isFromBalanceSheet) {
      worksheetTitle = "Ledger Transaction";
    }
    const worksheet = workbook.addWorksheet(worksheetTitle, {});
    worksheet.properties.defaultRowHeight = 20;
    worksheet.properties.defaultColWidth = 20;
    // setting Account header
    worksheet.getCell("A2").value = organizationName;
    worksheet.getCell("A3").value = worksheetTitle;
    worksheet.getCell("A4").value = dateHeader;
    if (isCompared) {
      worksheet.mergeCells("A2:F2");
      worksheet.mergeCells("A3:F3");
      worksheet.mergeCells("A4:F4");
    } else {
      worksheet.mergeCells("A2:C2");
      worksheet.mergeCells("A3:C3");
      worksheet.mergeCells("A4:C4");
    }

    // style header style
    worksheet.getCell("A2").alignment = {
      horizontal: "center",
    };
    worksheet.getCell("A2").font = {
      size: 14,
    };
    worksheet.getCell("A3").alignment = {
      horizontal: "center",
    };
    worksheet.getCell("A3").font = {
      bold: true,
      size: 16,
    };
    worksheet.getCell("A4").alignment = {
      horizontal: "center",
    };
    worksheet.getCell("A4").font = {
      size: 14,
    };

    const tableHeaders = [
      [],
      ["Particulars", "", "Amount"].concat(
        isCompared
          ? [
              isCompared && compareHeader,
              isCompared && "Compared Amount",
              isCompared && "Compared %",
            ]
          : []
      ),
    ];
    worksheet?.addRows(tableHeaders)?.forEach((row) => {
      row.font = {
        bold: true,
        size: 14,
      };
    });
    Object?.keys(data)?.map((e, key) => {
      let values = data[e];
      let sectionHeadRow = [e, ""];
      worksheet.addRow(sectionHeadRow).font = {
        size: 14,
        color: {
          argb: "0000FF",
        },
      };
      if (
        values?.type === ContentTypeEnum.content &&
        Array.isArray(values?.data) &&
        values?.data?.length > 0
      ) {
        let tableRows = values?.data?.map((f) => {
          return [
            `${f?.isLess ? "Less: " : ""}${f?.title}`,
            "",
            f?.value,
          ].concat(
            isCompared
              ? [
                  isCompared && f?.firstCompareTimeValue,
                  isCompared && f?.deviationCompareValue,
                  isCompared && f?.percentageCompareValue,
                ]
              : []
          );
        });
        worksheet.addRows(tableRows)?.forEach((row) => {
          row.font = {
            size: 14,
          };
        });
      }
      if (
        (values?.type === ContentTypeEnum.subTotal ||
          values?.type === ContentTypeEnum.total) &&
        !Array.isArray(values?.data)
      ) {
        let rowData = [
          ["", values?.data?.title || e, values?.data?.value].concat(
            isCompared
              ? [
                  isCompared && values?.data?.firstCompareTimeValue,
                  isCompared && values?.data?.deviationCompareValue,
                  isCompared && values?.data?.percentageCompareValue,
                ]
              : []
          ),
        ];
        worksheet.addRows(rowData)?.forEach((row) => {
          row.font = {
            size: 14,
          };
          if (values?.type === ContentTypeEnum.subTotal) {
            row.font = {
              size: 14,
              bold: true,
              color: { argb: "FF808080" },
            };
          } else {
            row.font = {
              size: 14,
              bold: true,
            };
          }
        });
      }
    });
    worksheet.views = [
      {
        state: "frozen",
        xSplit: 0,
        ySplit: 6,
      },
    ];
    worksheet.getColumn(1).width = 50;
    worksheet.getColumn(2).width = 50;
    //    save the workbook to a file
    const bufferFile = await workbook.xlsx.writeBuffer();
    let fileName = `${Date.now()}-income-statement.xlsx`;
    if (isFromBalanceSheet) {
      fileName = `${Date.now()}-balance-sheet.xlsx`;
    }
    await FileSaver.saveAs(
      new Blob([bufferFile], { type: "application/octet-stream" }),
      fileName
    );
  } catch (err) {
    throw err;
  }
}

export default generateIncomeStatementReport;
