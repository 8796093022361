import { NavLinkInterface } from "interfaces";

import "../components/style.css";
import { NavLink } from "react-router-dom";
import Popup from "reactjs-popup";

interface groupNavInterface {
  link: NavLinkInterface;
  isExpand?: boolean;
}
export default function GroupNav({ link, isExpand }: groupNavInterface) {
  let Icon = link.Icon;
  return (
    <div
      className={`flex flex-col  px-4 py-1 text-gray-500 hover:border-r-4 hover:border-primary select-none hover:text-gray-800 mb-4 ${
        isExpand ? "min-w-[220px]" : "min-w-[60px]"
      }`}
    >
      <Popup
        key={`tp-sd`}
        trigger={
          <button type="button" className="button">
            <div className="flex items-center justify-start gap-4 ml-2">
              <Icon fontSize={18} />
              {isExpand && <span>{link.name}</span>}
            </div>
          </button>
        }
        position={"right bottom"}
        on={["click"]}
        contentStyle={{ backgroundColor: "#f1f1f1f1", width: 210 }}
        arrow
        arrowStyle={{ color: "#f1f1f1f1" }}
      >
        <div>
          {link?.groups?.map((e: any, key: any) => {
            return <GNavLink key={key} isExpand={isExpand} linkData={e} />;
          })}
        </div>
      </Popup>
    </div>
  );
}

const GNavLink = ({ linkData, isExpand }) => {
  let Icon = linkData.icon;

  return (
    <NavLink
      className={({ isActive }) => {
        return `flex flex-col p-1 text-gray-500 hover:border-r-4 hover:border-primary select-none hover:text-gray-800 mb-1 ${
          isActive && "border-r-4 border-primary text-gray-800 "
        } min-w-[150px]`;
      }}
      key={linkData.name}
      to={linkData.path}
    >
      <Popup
        key={`tp-`}
        trigger={
          <button type="button" className="button">
            <div className="flex items-center justify-start gap-2 ml-2">
              <Icon fontSize={20} />
              <span>{linkData.name}</span>
            </div>
          </button>
        }
        position={"right bottom"}
        on={["hover"]}
        contentStyle={{ backgroundColor: "#242B2E", minWidth: 120 }}
        arrow
        arrowStyle={{ color: "#242B2E" }}
      >
        <div className="text-center text-white">
          <span className="text-[14px]">{linkData.name}</span>
        </div>
      </Popup>
    </NavLink>
  );
};
