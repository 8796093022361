import React, { useContext, useEffect } from "react";
import { Api } from "helpers";
import { AppContext } from "context";
import { ApiUrl } from "services";
import { Form, Formik, useFormikContext } from "formik";
import { MyUploadDocument, FileViewCard } from "components";
import { SignatureFormInterface } from "./interfaces";
import { useAuthorizationHook } from "useHooks";
import { SpaceAccessListEnum } from "interfaces";

function SignatureForm({
  customerID,
  populateFieldName,
  populateFieldValue,
  unSelectedMessage,
  errorMessage,
  label,
  shouldSetParticipant,
}: SignatureFormInterface) {
  // Case 1: if signature exists
  //  -> get existing signature from customerID
  //  -> populate signature to parent form
  // Case 2: if not exists
  //  -> upload signature
  //  -> populate signature to parent form

  //  required-> customerID, populateFieldName
  const { getApi, postApi } = Api();
  const { setFieldValue, values } = useFormikContext<any>();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canUploadSignature = checkCanAccessHandler([
    SpaceAccessListEnum.AUTH_EDIT_USER,
    SpaceAccessListEnum.AUTH_ADD_USER,
  ]);
  const {
    handlers: { setLoading, setError },
  } = useContext(AppContext);
  const getCustomerSignature = async (customerId) => {
    try {
      setLoading(true);
      const res = await getApi(
        ApiUrl.customerSignature?.get_signature + customerId
      );
      setFieldValue(populateFieldName, res?.data?.signature);
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (customerID) {
        if (shouldSetParticipant) {
          setFieldValue(shouldSetParticipant, customerID);
        }
        await getCustomerSignature(customerID);
      }
    })();
  }, [customerID]);

  const submitHandler = async (values) => {
    try {
      setLoading(true);
      await postApi(ApiUrl.customerSignature?.post_saveSignature, values);
      if (typeof setFieldValue === "function") {
        setFieldValue(populateFieldName, values?.signature);
      }
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  if (!customerID) {
    return (
      <div
        className={
          "flex items-center justify-center text-[14px] text-gray-500 p-2 border-2 rounded-md"
        }
      >
        <span>{unSelectedMessage || "Select Customer to get signature"}</span>
      </div>
    );
  }
  return (
    <Formik
      enableReinitialize
      onSubmit={submitHandler}
      initialValues={{
        customerID: customerID,
        signature: values?.[populateFieldName] || populateFieldValue || "",
      }}
    >
      {({ values }) => {
        return (
          <Form className={"flex flex-col gap-2"}>
            {label && <span className={"input_label"}>{label}</span>}
            {canUploadSignature ? (
              <MyUploadDocument
                fileType={"image"}
                onChangeCallBack={async (currentValue) => {
                  await submitHandler({
                    customerID: customerID,
                    signature: currentValue,
                  });
                }}
                onDeleteHandler={async () => {
                  await submitHandler({
                    customerID: customerID,
                    signature: "",
                  });
                }}
                fileContainerStyle={"grid grid-col-1"}
                name={"signature"}
              />
            ) : (
              <>
                {values?.signature ? (
                  <FileViewCard filePath={values?.signature} />
                ) : (
                  <div className={"text-[14px] text-gray-500"}>
                    Signature not found
                  </div>
                )}
              </>
            )}
            {errorMessage && (
              <span className={"input_textError"}>*{errorMessage}</span>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default SignatureForm;
