import React from "react";
import { MyButtonInterface } from "./interfaces";
import { getIconHelper } from "helpers";

const MyButton: React.FC<MyButtonInterface> = ({
  name,
  isOutline,
  isLoading,
  iconType,
  colorType,
  className,
  iconClassName,
  size,
  ...props
}: MyButtonInterface) => {
  const getButtonStyle = () => {
    let styles, iconStyle;
    switch (colorType) {
      case "primary":
        if (isOutline) {
          styles =
            "border-primary border-[1px] text-primary hover:bg-gray-100 focus:outline-tBlue";
          iconStyle = "text-primary fill-primary";
        } else {
          styles = "bg-primary  text-white focus:outline-tRed  ";
          iconStyle = "text-white fill-white";
        }
        break;
      case "blue":
        if (isOutline) {
          styles = "border-tBlue border-[1px] text-tBlue focus:outline-tRed";
          iconStyle = "text-tBlue fill-tBlue";
        } else {
          styles = "bg-tBlue  text-white focus:outline-tRed ";
          iconStyle = "text-white fill-white";
        }
        break;
      case "secondary":
        if (isOutline) {
          styles =
            "bg-transparent text-secondary border-[1px] border-secondary focus:outline-tRed";
          iconClassName = "text-secondary fill-secondary";
        } else {
          styles = "bg-secondary text-black focus:outline-tRed";
          iconClassName = "text-black fill-black";
        }
        break;
      case "danger":
        if (isOutline) {
          styles = "border-tRed border-[1px] text-tRed focus:outline-tBlue";
          iconClassName = "text-tRed fill-tRed";
        } else {
          styles = "bg-tRed  text-white focus:outline-tBlue";
          iconClassName = "text-white fill-white";
        }
        break;
      case "white":
        styles =
          "border-tLightGray hover:bg-gray-200  bg-white border-[1px] text-black focus:outline-tRed";
        iconStyle = "text-black";

        break;
      default:
        if (isOutline) {
          styles =
            "border-primary border-[1px] text-primary hover:bg-gray-100 focus:outline-tBlue";
          iconStyle = "text-primary fill-primary";
        } else {
          styles = "bg-primary  text-white  focus:outline-tRed";
          iconStyle = "text-white fill-white";
        }
    }
    return { styles, iconStyle };
  };
  const { styles, iconStyle } = getButtonStyle();
  const Icon = getIconHelper(iconType);
  const isContainRounded = className?.includes("rounded");
  return (
    <button
      type={"button"}
      className={`btn flex-1 hover:opacity-95   cursor-pointer  box-border
     ${
       size === "sm"
         ? "text-[12px] min-h-[25px]  px-3"
         : size === "md"
         ? "text-[14px] min-h-[30px]  px-3"
         : "text-[16px]  px-5  min-h-[48px]"
     }   ${styles}  ${
        (props?.disabled || isLoading) && "cursor-not-allowed opacity-90"
      } ${!isContainRounded && "rounded-full"}     ${className}`}
      {...props}
      disabled={props?.disabled || isLoading}
      onClick={props?.onClick}
    >
      <div className={"flex  items-center justify-center  gap-2"}>
        {iconType && (
          <Icon
            height={iconStyle?.height || "18px"}
            width={iconStyle?.width || "18px"}
            className={` text-[22px]  ${iconStyle} `}
          />
        )}
        {name && (
          <span className={"whitespace-nowrap font-semibold tracking-widest "}>
            {isLoading ? "Please wait..." : name}
          </span>
        )}
      </div>
    </button>
  );
};

export default MyButton;
