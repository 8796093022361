import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  CandidateInterface,
  FieldTypeEnum,
  SpaceAccessListEnum,
  TableColumnInterface,
} from "interfaces";
import { MyMoreOptionButton, MyStatusSelectButton } from "components";

import { CandidateDataContext, OptionsContext } from "context";
import { decodeOptionColorHandler } from "helpers";
import {
  useAuthorizationHook,
  useDateHook,
  usePaginationQueryHooks,
} from "useHooks";
import { UserTableProfile } from "assets";
import { PageLinks } from "routes";

function CandidateTableColumns(
  query
): TableColumnInterface<CandidateInterface>[] {
  const navigate = useNavigate();
  const {
    archiveHandler,
    getAllDataHandlers,
    deleteHandler,
    editDetailsHandler,
  } = useContext(CandidateDataContext);
  const { fieldTypeOptions, getOptionsHandler } = useContext(OptionsContext);
  const { getDateHandler, getStaffFullName } = useDateHook();
  const candidateStatusOptions = fieldTypeOptions[
    FieldTypeEnum.CANDIDATE_STATUS
  ]?.map((e) => {
    return {
      label: e?.label,
      value: e?._id,
      textColor: decodeOptionColorHandler(e?.value).textColor,
      bgColor: decodeOptionColorHandler(e?.value).bgColor,
    };
  });
  const { calcSNHandler } = usePaginationQueryHooks();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.CANDIDATE_EDIT_CANDIDATE,
  ]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.CANDIDATE_DEL_CANDIDATE,
  ]);
  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <span>{calcSNHandler(key)}</span>;
      },
    },
    {
      title: "Candidate",
      render(renderData, key: number) {
        return (
          <UserTableProfile
            details={{
              name: getStaffFullName({
                first: renderData?.name.first,
                last: renderData?.name?.last,
              }),
              profile: "",
              subTitle: renderData?.profession?.label,
            }}
          />
        );
      },
    },

    {
      title: "Property",
      render(renderData, key: number) {
        return (
          <span className={"text-primary font-semibold"}>
            {renderData?.property?.propertyID}
          </span>
        );
      },
    },
    {
      title: "Phone",
      render(renderData, key: number) {
        return (
          <div>
            <span>{renderData?.phone}</span>
          </div>
        );
      },
    },
    {
      title: "Date",
      render(renderData, key: number) {
        return (
          <div>
            <span>{getDateHandler({ date: renderData?.createdAt })}</span>
          </div>
        );
      },
    },
    {
      title: "Status",
      render(renderData, key: number) {
        return (
          <MyStatusSelectButton
            initialFetchHandler={() =>
              getOptionsHandler(FieldTypeEnum.CANDIDATE_STATUS)
            }
            onSelectHandler={(value) => {
              canEdit &&
                editDetailsHandler(
                  {
                    _id: renderData?._id,
                    candidateStatus: value,
                  },
                  null,
                  {
                    async onSuccess(payload?: any) {
                      await getAllDataHandlers();
                    },
                  }
                );
            }}
            selectedOption={{
              name: renderData?.candidateStatus?.label,
              textColor: decodeOptionColorHandler(
                renderData?.candidateStatus?.value
              ).textColor,
              bgColor: decodeOptionColorHandler(
                renderData?.candidateStatus?.value
              ).bgColor,
            }}
            title={"Change To"}
            options={candidateStatusOptions}
          />
        );
      },
    },
    {
      title: "Action",
      render(renderData, key: number) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigate(PageLinks.candidates.details(renderData?._id));
                  },
                },
                canEdit &&
                  !renderData?.isArchived && {
                    name: "Edit",
                    handler: () => {
                      navigate({
                        pathname: PageLinks.candidates.edit(renderData?._id),
                      });
                    },
                  },
                canDelete && {
                  name: "Delete",
                  handler: () => {
                    deleteHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
                canDelete && {
                  name: renderData?.isArchived ? `Unarchive` : "Archive",
                  handler: () => {
                    archiveHandler(renderData?._id, renderData?.isArchived, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default CandidateTableColumns;
