import React, { useState } from "react";
import { IconTypeEnum, SubscriptionPackageInterface } from "interfaces";
import {
  getCurrency,
  getIconHelper,
  getTaxNameHandler,
  separateWithComma,
} from "helpers";
import { MyButton } from "components";

interface PropsInterface {
  packageDetails: SubscriptionPackageInterface;
  isLoading?: boolean;
  onClickHandler?(id: string);
  costType: "monthly" | "annual";
}
function PackageCard({
  packageDetails,
  isLoading,
  onClickHandler,
  costType,
}: PropsInterface) {
  const CheckIcon = getIconHelper(IconTypeEnum.CHECK);
  const CrossIcon = getIconHelper(IconTypeEnum.CLOSE);
  const isMonthly = costType === "monthly";
  const [isHover, setHover] = useState(false);
  let packageCost;
  if (isMonthly) {
    packageCost = packageDetails?.monthlyCost?.cost;
  } else {
    packageCost = packageDetails?.annualCost?.cost;
  }
  if (packageDetails?.isFreeForever) {
    packageCost = 0;
  }
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`flex flex-col justify-between border-2 rounded-xl p-8 min-h-[600px] cursor-pointer ${
        isHover ? "bg-primary" : "bg-white"
      }`}
    >
      <div
        className={`flex flex-col gap-8 items-center ${
          isHover ? "text-white" : "text-black"
        }`}
      >
        <div className={"flex flex-col gap-1 items-center"}>
          <b className={"text-[18px] font-bold"}>{packageDetails?.name}</b>
          <b className={"text-[32px] font-bold"}>
            {getCurrency()}
            {separateWithComma(packageCost)}
          </b>
          <span className={"text-[12px]"}>
            *
            {getTaxNameHandler(
              isMonthly
                ? packageDetails?.monthlyCost?.taxType
                : packageDetails?.annualCost?.taxType,
              true
            )}{" "}
            of{" "}
            {isMonthly
              ? packageDetails?.monthlyCost?.taxRate
              : packageDetails?.annualCost?.taxRate}
            % VAT.
          </span>
        </div>

        <div className={"flex justify-between  w-full"}>
          <div className={"flex flex-col items-center"}>
            <b className={"text-[16px]"}>
              {packageDetails?.propertyIncludedInBasePrice}
            </b>
            <span
              className={`text-[12px] ${
                isHover ? " text-gray-300" : " text-gray-500"
              }`}
            >
              No. of properties
            </span>
          </div>{" "}
          <div className={"flex flex-col items-center"}>
            <b className={"text-[16px]"}>
              {Math.floor(packageDetails?.storage?.base / 1024)} GB{" "}
              <sub
                className={`text-[10px] ${
                  isHover ? " text-gray-300" : " text-gray-500"
                }`}
              >
                /properties
              </sub>
            </b>
            <span
              className={`text-[12px] ${
                isHover ? " text-gray-300" : " text-gray-500"
              }`}
            >
              Storage Limit
            </span>
          </div>
        </div>
        <div className={"w-full"}>
          <MyButton
            className={`w-full rounded-md ${isHover && "text-primary"}`}
            onClick={() => onClickHandler(packageDetails?._id)}
            name={"Select Plan"}
            isOutline={isHover}
            colorType={isHover ? "white" : "primary"}
          />
        </div>
        <div className={"w-full"}>
          <b className={"text-[14px]"}>Key Features</b>
          <div className={"flex flex-col gap-2 mt-2"}>
            {packageDetails?.specifications?.map((e, key) => {
              return (
                <div
                  key={key}
                  className={"flex items-center gap-2 text-[14px]"}
                >
                  {e?.isIncluded ? (
                    <CheckIcon className={"text-tGreen"} />
                  ) : (
                    <CrossIcon
                      className={`${
                        isHover ? "text-gray-400" : "text-gray-500"
                      } text-[18px]`}
                    />
                  )}
                  <span
                    className={
                      e?.isIncluded
                        ? isHover
                          ? "text-white"
                          : "text-black"
                        : isHover
                        ? "text-gray-400"
                        : "text-gray-500"
                    }
                  >
                    {e?.feature}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {packageDetails?.trial?.enable && (
        <div
          className={`${
            isHover ? "text-[#FDD546]" : "text-primary"
          } text-[14px] text-center`}
        >
          Free trial {packageDetails?.trial?.days} Days
        </div>
      )}
    </div>
  );
}

export default PackageCard;
