import React from "react";
import {
  TaskMaintenanceCardDetails,
  ToggleContentWrapper,
} from "../components";
import {
  DashboardApiTypeEnum,
  RepairPriorityEnum,
  RepairStatusEnum,
  SpaceAccessListEnum,
  TaskDashboardStatsInterface,
} from "interfaces";
import { PageLinks } from "routes";
import { useAuthorizationHook } from "useHooks";

function MaintenanceStatsContent({ isToggle }) {
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAccessRead = checkCanAccessHandler([
    SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_READ_REPAIR_AND_MAINTENANCE,
  ]);
  return (
    <ToggleContentWrapper
      toggle={isToggle}
      filterNewLine={true}
      title={"Repair and Maintenance"}
      getDataType={DashboardApiTypeEnum.maintenance}
      haveDateFilter={true}
      havePropertyFilter={true}
      isViewAll={{
        path: PageLinks.maintenance.list,
      }}
    >
      {(data: TaskDashboardStatsInterface, isLoading, error) => {
        const totalLow =
          data?.[RepairPriorityEnum.LOW][RepairStatusEnum.CANCELLED] +
          data?.[RepairPriorityEnum.LOW][RepairStatusEnum.CLOSE] +
          data?.[RepairPriorityEnum.LOW][RepairStatusEnum.OPEN] +
          data?.[RepairPriorityEnum.LOW][RepairStatusEnum.OVERDUE];
        const totalMedium =
          data?.[RepairPriorityEnum.MEDIUM][RepairStatusEnum.CANCELLED] +
          data?.[RepairPriorityEnum.MEDIUM][RepairStatusEnum.CLOSE] +
          data?.[RepairPriorityEnum.MEDIUM][RepairStatusEnum.OPEN] +
          data?.[RepairPriorityEnum.MEDIUM][RepairStatusEnum.OVERDUE];
        const totalHigh =
          data?.[RepairPriorityEnum.HIGH][RepairStatusEnum.CANCELLED] +
          data?.[RepairPriorityEnum.HIGH][RepairStatusEnum.CLOSE] +
          data?.[RepairPriorityEnum.HIGH][RepairStatusEnum.OPEN] +
          data?.[RepairPriorityEnum.HIGH][RepairStatusEnum.OVERDUE];
        const totalUrgent =
          data?.[RepairPriorityEnum.EMERGENCY][RepairStatusEnum.CANCELLED] +
          data?.[RepairPriorityEnum.EMERGENCY][RepairStatusEnum.CLOSE] +
          data?.[RepairPriorityEnum.EMERGENCY][RepairStatusEnum.OPEN] +
          data?.[RepairPriorityEnum.EMERGENCY][RepairStatusEnum.OVERDUE];
        const getIDHandler = () => {
          let openID,
            closeID,
            overDueID,
            cancelledID,
            emergencyID,
            highID,
            mediumID,
            lowID;
          data?.priorities?.forEach((e) => {
            if (e?.label === "Emergency") {
              emergencyID = e?._id;
            }
            if (e?.label === "High") {
              highID = e?._id;
            }
            if (e?.label === "Medium") {
              mediumID = e?._id;
            }
            if (e?.label === "Low") {
              lowID = e?._id;
            }
            if (e?.label === "Open") {
              openID = e?._id;
            }
          });
          data?.statuses?.forEach((e) => {
            if (e?.label === "Open") {
              openID = e?._id;
            }
            if (e?.label === "Close") {
              closeID = e?._id;
            }
            if (e?.label === "Overdue") {
              overDueID = e?._id;
            }
            if (e?.label === "Cancelled") {
              cancelledID = e?._id;
            }
          });
          return {
            openID,
            closeID,
            overDueID,
            cancelledID,
            emergencyID,
            highID,
            mediumID,
            lowID,
          };
        };
        const {
          openID,
          closeID,
          overDueID,
          cancelledID,
          emergencyID,
          highID,
          mediumID,
          lowID,
        } = getIDHandler();
        return (
          <div className={"flex flex-col gap-2 mt-5"}>
            <TaskMaintenanceCardDetails
              canAccessRead={canAccessRead}
              title={{
                count: totalLow,
                color: "bg-green-200",
                name: "Low",
              }}
              cancelledCount={
                data?.[RepairPriorityEnum.LOW][RepairStatusEnum.CANCELLED]
              }
              closeCount={
                data?.[RepairPriorityEnum.LOW][RepairStatusEnum.CLOSE]
              }
              openCount={data?.[RepairPriorityEnum.LOW][RepairStatusEnum.OPEN]}
              overdueCount={
                data?.[RepairPriorityEnum.LOW][RepairStatusEnum.OVERDUE]
              }
              pageLink={PageLinks.maintenance.list}
              query={{
                closeID,
                cancelledID,
                overDueID,
                openID,
                statusID: lowID,
                title: "Repair And Maintenance",
              }}
            />
            <TaskMaintenanceCardDetails
              canAccessRead={canAccessRead}
              title={{
                count: totalMedium,
                color: "bg-green-700",
                name: "Normal",
              }}
              cancelledCount={
                data?.[RepairPriorityEnum.MEDIUM][RepairStatusEnum.CANCELLED]
              }
              closeCount={
                data?.[RepairPriorityEnum.MEDIUM][RepairStatusEnum.CLOSE]
              }
              openCount={
                data?.[RepairPriorityEnum.MEDIUM][RepairStatusEnum.OPEN]
              }
              overdueCount={
                data?.[RepairPriorityEnum.MEDIUM][RepairStatusEnum.OVERDUE]
              }
              pageLink={PageLinks.maintenance.list}
              query={{
                closeID,
                cancelledID,
                overDueID,
                openID,
                statusID: mediumID,
                title: "Repair And Maintenance",
              }}
            />
            <TaskMaintenanceCardDetails
              canAccessRead={canAccessRead}
              title={{
                count: totalHigh,
                color: "bg-purple-700",
                name: "High",
              }}
              cancelledCount={
                data?.[RepairPriorityEnum.HIGH][RepairStatusEnum.CANCELLED]
              }
              closeCount={
                data?.[RepairPriorityEnum.HIGH][RepairStatusEnum.CLOSE]
              }
              openCount={data?.[RepairPriorityEnum.HIGH][RepairStatusEnum.OPEN]}
              overdueCount={
                data?.[RepairPriorityEnum.HIGH][RepairStatusEnum.OVERDUE]
              }
              pageLink={PageLinks.maintenance.list}
              query={{
                closeID,
                cancelledID,
                overDueID,
                openID,
                statusID: highID,
                title: "Repair And Maintenance",
              }}
            />
            <TaskMaintenanceCardDetails
              canAccessRead={canAccessRead}
              title={{
                count: totalUrgent,
                color: "bg-tRed",
                name: "Emergency",
              }}
              cancelledCount={
                data?.[RepairPriorityEnum.EMERGENCY][RepairStatusEnum.CANCELLED]
              }
              closeCount={
                data?.[RepairPriorityEnum.EMERGENCY][RepairStatusEnum.CLOSE]
              }
              openCount={
                data?.[RepairPriorityEnum.EMERGENCY][RepairStatusEnum.OPEN]
              }
              overdueCount={
                data?.[RepairPriorityEnum.EMERGENCY][RepairStatusEnum.OVERDUE]
              }
              pageLink={PageLinks.maintenance.list}
              query={{
                closeID,
                cancelledID,
                overDueID,
                openID,
                statusID: emergencyID,
                title: "Repair And Maintenance",
              }}
            />
          </div>
        );
      }}
    </ToggleContentWrapper>
  );
}

export default MaintenanceStatsContent;
