import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { DateRangeOptionEnum, QueryStringEnum } from "interfaces";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { MyButton, MySelectField, MyTextField } from "components";
import {
  decodeFiscalYearOptionsHandler,
  lastMonthEnd,
  lastMonthStart,
  lastYearEnd,
  lastYearStart,
  thisMonthEnd,
  thisMonthStart,
  thisYearEnd,
  thisYearStart,
  validateDateHandler,
} from "helpers";
import { useGetStartEndDateRangeHooks } from "useHooks";
import { DateRangeOptions } from "content/cards/helpers";
import { OptionsContext } from "context";

function CompareFilterComponent() {
  const [query, setQuery] = useSearchParams();
  const { fiscalYearOptions, getFiscalYearOptionsHandler } =
    useContext(OptionsContext);
  const {
    compareFiscalYear,
    compareEndDate,
    compareStartDate,
    compareRangeType,
  } = useGetStartEndDateRangeHooks();
  useEffect(() => {
    getFiscalYearOptionsHandler();
  }, []);

  const submitHandler = (values) => {
    query.set(QueryStringEnum.COMPARE_RANGE_TYPE, values?.rangeType);
    query.set(QueryStringEnum.COMPARE_START_DATE, values?.startDate);
    query.set(QueryStringEnum.COMPARE_END_DATE, values?.endDate);
    query.set(QueryStringEnum.COMPARE_FISCAL_YEAR, values?.fiscalYear);
    setQuery(query);
  };

  return (
    <div className={"flex flex-col gap-5 bg-gray-100 p-5 rounded-md"}>
      <b className={"text-[18px] font-bold"}>Compare With</b>
      <Formik
        initialValues={{
          rangeType: compareRangeType,
          startDate: compareStartDate,
          endDate: compareEndDate,
          fiscalYear: compareFiscalYear,
        }}
        validationSchema={yup.object().shape({
          rangeType: yup.string(),
          startDate: yup.string(),
          endDate: yup
            .string()
            .test("custom-validation", "Invalid field value", function (value) {
              const { message, isError } = validateDateHandler(value, {
                minDate: this.parent.startDate,
              });
              return (
                !isError ||
                this.createError({
                  path: "endDate",
                  message: message,
                })
              );
            }),
        })}
        onSubmit={submitHandler}
      >
        {({ values, setFieldValue }) => (
          <Form className={"grid grid-cols-4 gap-5 items-start"}>
            <MySelectField
              name="rangeType"
              option={{
                selectOptions: DateRangeOptions,
                defaultLabel: "Select Date Range Type",
                onChangeCallback(currentValue) {
                  currentValue = currentValue?.value;
                  let startDate, endDate;
                  if (currentValue === DateRangeOptionEnum.THIS_MONTH) {
                    startDate = thisMonthStart;
                    endDate = thisMonthEnd;
                  } else if (currentValue === DateRangeOptionEnum.THIS_YEAR) {
                    startDate = thisYearStart;
                    endDate = thisYearEnd;
                  } else if (currentValue === DateRangeOptionEnum.LAST_MONTH) {
                    startDate = lastMonthStart;
                    endDate = lastMonthEnd;
                  } else if (currentValue === DateRangeOptionEnum.LAST_YEAR) {
                    startDate = lastYearStart;
                    endDate = lastYearEnd;
                  } else {
                    startDate = "";
                    endDate = "";
                  }
                  setFieldValue("startDate", startDate);
                  setFieldValue("endDate", endDate);
                },
              }}
              label={"Report Period"}
            />
            {values?.rangeType === DateRangeOptionEnum.FISCAL_YEAR && (
              <MySelectField
                isClearable
                label={"Fiscal Year"}
                option={{
                  selectOptions:
                    decodeFiscalYearOptionsHandler(fiscalYearOptions),
                  onChangeCallback(currentValue) {
                    currentValue = currentValue?.value;
                    if (!currentValue) {
                      setFieldValue("startDate", "");
                      setFieldValue("endDate", "");
                      setFieldValue("fiscalYear", "");
                      return;
                    }
                    const fiscalYear = fiscalYearOptions.find(
                      (e) => e?.title == currentValue
                    );
                    if (!fiscalYear) {
                      return;
                    }
                    setFieldValue("startDate", fiscalYear?.startFrom);
                    setFieldValue("endDate", fiscalYear?.endTo);
                    setFieldValue("fiscalYear", currentValue);
                  },
                }}
                name="fiscalYear"
              />
            )}
            {values?.rangeType === DateRangeOptionEnum.CUSTOM && (
              <>
                <MyTextField
                  name={"startDate"}
                  type={"date"}
                  label={"Comparative Period 1"}
                />
                <MyTextField
                  type={"date"}
                  name={"endDate"}
                  label={"Comparative Period 2"}
                />
              </>
            )}

            <div className={"mt-8 border-l-2 border-gray-300 pl-8 ml-2"}>
              <MyButton name={"Apply Filters"} type={"submit"} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CompareFilterComponent;
