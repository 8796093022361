import { DateRangeOptionEnum } from "interfaces";

export const DateRangeOptions = [
  {
    label: "Fiscal Year",
    value: DateRangeOptionEnum.FISCAL_YEAR,
  },
  {
    label: "This Year",
    value: DateRangeOptionEnum.THIS_YEAR,
  },
  {
    label: "This Month",
    value: DateRangeOptionEnum.THIS_MONTH,
  },
  {
    label: "Last Year",
    value: DateRangeOptionEnum.LAST_YEAR,
  },
  {
    label: "Last Month",
    value: DateRangeOptionEnum.LAST_MONTH,
  },
  {
    label: "All Time",
    value: DateRangeOptionEnum.ALL_TIME,
  },
  {
    label: "Custom",
    value: DateRangeOptionEnum.CUSTOM,
  },
];
