import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { TableColumnInterface, NoteInterface } from "interfaces";
import { MyMoreOptionButton } from "components";

import { NotesContext } from "context";
import { useDateHook } from "useHooks";
import { usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function NotesTableColumns(query): TableColumnInterface<NoteInterface>[] {
  const navigate = useNavigate();
  const { deleteHandler, archiveHandler, getAllDataHandlers } =
    useContext(NotesContext);
  const { calcSNHandler } = usePaginationQueryHooks();
  const { getDateHandler } = useDateHook();

  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <span>{calcSNHandler(key)}.</span>;
      },
    },
    {
      title: "Subject",
      render(renderData) {
        return <span>{renderData?.name}</span>;
      },
    },
    {
      title: "Property",
      render(renderData) {
        return (
          <span className={"text-blue-400 font-semibold"}>
            {renderData?.property?.propertyID}
          </span>
        );
      },
    },
    {
      title: "Unit",
      render(renderData) {
        return (
          <div className={"flex flex-col gap-1"}>
            <span className={"text-blue-400 font-semibold"}>
              {renderData?.unit?.unitID}
            </span>
            <span className={"font-semibold"}>{renderData?.unit?.name}</span>
          </div>
        );
      },
    },
    {
      title: "Date",
      render(renderData) {
        return (
          <div>
            <span>{getDateHandler({ date: renderData?.createdAt })}</span>
          </div>
        );
      },
    },
    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigate(PageLinks.notes.details(renderData?._id));
                  },
                },
                !renderData?.isArchived && {
                  name: "Edit",
                  handler: () => {
                    navigate(PageLinks.notes.edit(renderData?._id));
                  },
                },
                {
                  name: "Delete",
                  handler: () => {
                    deleteHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },

                {
                  name: renderData?.isArchived ? `Unarchive` : "Archive",
                  handler: () => {
                    archiveHandler(renderData?._id, renderData?.isArchived, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default NotesTableColumns;
