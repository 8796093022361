import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MyButton, TableCardComponent } from "components";
import { PageTemplate } from "templates";
import { IconTypeEnum, SpaceAccessListEnum } from "interfaces";

import { PropertyDataContext } from "context";
import {
  useAuthorizationHook,
  usePageQueryHooks,
  usePaginationQueryHooks,
} from "useHooks";
import { PageLinks } from "routes";

import { PropertyTableColumns } from "../components";
import { TableFilterCard } from "content";

function PropertyPage() {
  const {
    isLoading,
    allData,
    totalDocs,
    totalArchiveDocs,
    getAllDataHandlers,
  } = useContext(PropertyDataContext);
  const navigate = useNavigate();
  const { isArchive } = usePaginationQueryHooks();
  const { propertyID } = usePageQueryHooks();
  const pageQuery = {
    property: propertyID,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, propertyID]);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAdd = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_ADD_PROPERTY,
  ]);
  const tableCardProps = {
    column: PropertyTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 5,
    },
    dataSource: allData,
    children: {
      leftChildren: <TableFilterCard haveProperty />,
      rightChildren: canAdd && (
        <MyButton
          iconType={IconTypeEnum.ADD}
          onClick={() => {
            navigate(PageLinks.property.new);
          }}
          name={"Add Property"}
        />
      ),
    },
  };
  return (
    <PageTemplate
      title={"Rental"}
      scrollAll
      breadCrumb={[
        {
          name: "Property",
        },
        {
          name: "View Properties",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default PropertyPage;
