import React from "react";
import { Route } from "react-router-dom";
import { DashboardPage } from "./pages";
import "./styles.css";

function Index() {
  return (
    <Route path="/">
      <Route index path={""} element={<DashboardPage />} />
    </Route>
  );
}

export default Index;
