import React from "react";
import { IconComponent, ToggleContentWrapper } from "../components";
import {
  BookingDashboardStatsInterface,
  DashboardApiTypeEnum,
  IconTypeEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { PageLinks } from "routes";
import { usePageQueryHooks } from "useHooks";
import { AccessVerifier } from "templates";

function BookingStatsContent({ isToggle }) {
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  return (
    <ToggleContentWrapper
      toggle={isToggle}
      title={"Booking Status"}
      getDataType={DashboardApiTypeEnum.booking}
      haveDateFilter={false}
      havePropertyFilter={false}
      isViewAll={{
        path: PageLinks.bookings.list,
      }}
    >
      {(data: BookingDashboardStatsInterface) => {
        return (
          <div className={"grid grid-cols-3 gap-5 py-5"}>
            <div
              className={
                "flex items-center gap-5 border-[1px] p-3 rounded-md cursor-pointer"
              }
            >
              <IconComponent
                color={"green"}
                iconType={IconTypeEnum.BOOKING_CONFIRM}
              />
              <div className={"flex flex-col gap-2 text-[14px] text-gray-500"}>
                <span className={"font-bold text-[20px]"}>
                  {data?.confirmed || "-"}
                </span>
                <AccessVerifier
                  accessKeys={[SpaceAccessListEnum.BOOKING_READ_BOOKING]}
                  isForPage={false}
                >
                  <span
                    className={"underline text-[12px] cursor-pointer"}
                    onClick={() =>
                      navigateWithReturnUrlHandler(
                        PageLinks.bookings.list,
                        `${QueryStringEnum.STATUS}=${data?.confirmedID}`
                      )
                    }
                  >
                    View
                  </span>
                </AccessVerifier>
                <span>Confirmed</span>
              </div>
            </div>
            <div
              className={
                "flex items-center gap-5 border-[1px] p-3 rounded-md cursor-pointer"
              }
            >
              <IconComponent
                color={"blue"}
                iconType={IconTypeEnum.BOOKING_PENDING}
              />
              <div className={"flex flex-col gap-2 text-[14px] text-gray-500"}>
                <span className={"font-bold text-[20px]"}>
                  {data?.pending || "-"}
                </span>
                <AccessVerifier
                  accessKeys={[SpaceAccessListEnum.BOOKING_READ_BOOKING]}
                  isForPage={false}
                >
                  <span
                    className={"underline text-[12px] cursor-pointer"}
                    onClick={() =>
                      navigateWithReturnUrlHandler(
                        PageLinks.bookings.list,
                        `${QueryStringEnum.STATUS}=${data?.pendingID}`
                      )
                    }
                  >
                    View
                  </span>
                </AccessVerifier>

                <span>Pending</span>
              </div>
            </div>
            <div
              className={
                "flex items-center gap-5 border-[1px] p-3 rounded-md cursor-pointer"
              }
            >
              <IconComponent
                color={"red"}
                iconType={IconTypeEnum.BOOKING_CANCELLED}
              />
              <div className={"flex flex-col gap-2 text-[14px] text-gray-500"}>
                <span className={"font-bold text-[20px]"}>
                  {data?.cancelled || "-"}
                </span>
                <AccessVerifier
                  accessKeys={[SpaceAccessListEnum.BOOKING_READ_BOOKING]}
                  isForPage={false}
                >
                  <span
                    className={"underline text-[12px] cursor-pointer"}
                    onClick={() =>
                      navigateWithReturnUrlHandler(
                        PageLinks.bookings.list,
                        `${QueryStringEnum.STATUS}=${data?.cancelledID}`
                      )
                    }
                  >
                    View
                  </span>
                </AccessVerifier>

                <span>Cancelled</span>
              </div>
            </div>
          </div>
        );
      }}
    </ToggleContentWrapper>
  );
}

export default BookingStatsContent;
