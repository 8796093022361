import React, { useContext, useEffect } from "react";
import { PageTemplate } from "templates";
import { TableFilterCard } from "content";
import { ReportContext } from "context";
import { useSearchParams } from "react-router-dom";
import { QueryStringEnum } from "interfaces";
import { AddressText } from "assets";
import { getCurrency, separateWithComma } from "helpers";
import { PageLinks } from "routes";
import useDateHooks from "../../../useHooks/useDateHooks";

function PropertyReportPage() {
  const { propertyReport, getPropertyReportsHandler } =
    useContext(ReportContext);
  const [query] = useSearchParams();
  const { getStaffFullName } = useDateHooks();

  const propertyID = query.get(QueryStringEnum.PROPERTY_ID);
  const LandlordDetails = {
    "Landlord Name": getStaffFullName(propertyReport?.landlordDetail?.name),
    Address: (
      <AddressText
        textStyle={"text-[14px]"}
        address={propertyReport?.landlordDetail?.address}
      />
    ),
    Mobile: propertyReport?.landlordDetail?.phone,
    "E-mail": propertyReport?.landlordDetail?.email,
  };
  const BuildingDetails = {
    "Property ID": propertyReport?.property?.propertyID,
    Address: (
      <AddressText
        textStyle={"text-[14px]"}
        address={propertyReport?.property?.address}
      />
    ),
    "Property Cost": `${getCurrency()}${separateWithComma(
      propertyReport?.propertyCost
    )}`,
    "Property Value": `${getCurrency()}${separateWithComma(
      propertyReport?.propertyValue
    )}`,
  };
  useEffect(() => {
    propertyID && getPropertyReportsHandler(propertyID);
  }, [propertyID]);

  return (
    <PageTemplate
      scrollAll
      title={"Reports"}
      breadCrumb={[
        {
          name: "Report",
        },
        {
          name: "Property Details",
        },
      ]}
      backNavigation={{
        title: "Property Details",
        path: PageLinks.report.reportPage,
      }}
    >
      <div className={"flex flex-col gap-10"}>
        <div className={"flex flex-col gap-2"}>
          <TableFilterCard haveProperty haveLabel />
        </div>
        {propertyID && propertyReport && (
          <>
            <div className={"grid grid-cols-2 gap-10"}>
              <div
                className={
                  "flex flex-col gap-5 bg-gray-100 rounded-lg p-5 text-[14px]"
                }
              >
                <span className={"font-bold text-[18px]"}>
                  Landlord Details
                </span>
                <div className={"flex flex-col gap-2"}>
                  {Object.keys(LandlordDetails).map((e, key) => {
                    return (
                      <div className={"grid grid-cols-2 "} key={key}>
                        <span className={"text-gray-500"} key={e}>
                          {e}
                        </span>
                        <span className={"font-bold"} key={e}>
                          {LandlordDetails[e]}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div
                className={
                  "flex flex-col gap-5 bg-gray-100 rounded-lg p-5 text-[14px]"
                }
              >
                <span className={"font-bold text-[18px]"}>
                  Building Details
                </span>
                <div className={"flex flex-col gap-2"}>
                  {Object.keys(BuildingDetails).map((e, eky) => {
                    return (
                      <div className={"grid grid-cols-2 "} key={eky}>
                        <span className={"text-gray-500"} key={e}>
                          {e}
                        </span>
                        <span className={"font-bold text-[14px]"} key={e}>
                          {BuildingDetails[e]}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div>
              <table className={"table table_container"}>
                <thead className={"table_head"}>
                  <th>Name</th>
                  <th>Mortgage loan</th>
                  <th>Interest Cost</th>
                  <th>Rental Total</th>
                  <th>Loan to value</th>
                  <th>Yield</th>
                </thead>
                <tbody>
                  {propertyReport?.unitRow?.map((unit, key) => {
                    return (
                      <tr className={"table_row "} key={key}>
                        <td className={"py-2 px-2"}>
                          {unit?.unit?.unitID} {unit?.unit?.name}
                        </td>
                        <td className={"py-2 px-2"}>
                          {separateWithComma(unit?.mortgageLoan)}
                        </td>
                        <td className={"py-2 px-2"}>
                          {separateWithComma(unit?.interestCost)}
                        </td>
                        <td className={"py-2 px-2"}>
                          {separateWithComma(unit?.totalRent)}
                        </td>
                        <td className={"py-2 px-2"}>
                          {separateWithComma(unit?.loanToValue)}
                        </td>
                        <td className={"py-2 px-2"}>{unit?.yield}</td>
                      </tr>
                    );
                  })}
                  <tr className={"table_row bg-gray-50"}>
                    <td className={"py-2 px-2"}>Property</td>
                    <td className={"py-2 px-2"}>
                      {separateWithComma(
                        propertyReport?.propertyRow?.mortgageLoan
                      )}
                    </td>
                    <td className={"py-2 px-2"}>
                      {separateWithComma(
                        propertyReport?.propertyRow?.interestCost
                      )}
                    </td>
                    <td className={"py-2 px-2"}>
                      {separateWithComma(
                        propertyReport?.propertyRow?.totalRent
                      )}
                    </td>
                    <td className={"py-2 px-2"}>
                      {separateWithComma(
                        propertyReport?.propertyRow?.loanToValue
                      )}
                    </td>
                    <td className={"py-2 px-2"}>
                      {propertyReport?.propertyRow?.yield}
                    </td>
                  </tr>
                  <tr className={"table_row bg-gray-200"}>
                    <td className={"py-2 px-2"}>Total</td>
                    <td className={"py-2 px-2"}>
                      {separateWithComma(propertyReport?.total?.mortgageLoan)}
                    </td>
                    <td className={"py-2 px-2"}>
                      {separateWithComma(propertyReport?.total?.interestCost)}
                    </td>
                    <td className={"py-2 px-2"}>
                      {separateWithComma(propertyReport?.total?.totalRent)}
                    </td>
                    <td className={"py-2 px-2"}>
                      {separateWithComma(propertyReport?.total?.loanToValue)}
                    </td>
                    <td className={"py-2 px-2"}>
                      {propertyReport?.total?.yield}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </PageTemplate>
  );
}

export default PropertyReportPage;
