import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Api,
  calcInvoiceBillHandler,
  decodeLedgerNameOptionsHandler,
  decodeLoanOptionsHandler,
  decodePropertyOptionsHandler,
  decodeUnitOptionsHandler,
  getCurrency,
} from "helpers";
import { MyFastTextField, MyIconButton, MySelectField } from "components";
import { AppContext, OptionsContext } from "context";
import { ApiUrl } from "services";
import {
  FieldTypeEnum,
  IconTypeEnum,
  InputSizeEnum,
  StaticLedgerNameEnum,
  TaxTypeEnum,
} from "interfaces";
import { FastField } from "formik";
import { InvoiceFormikForm } from "../../invoiceModule/helpers";

function InvoiceFormListTable({
  taxType,
  removeHandler,
  insertHandler,
  value,
  index,
  setFieldValue,
}) {
  const {
    handlers: { setError },
  } = useContext(AppContext);
  const [isLoading, setLoading] = useState(false);
  const loanActiveAt = [
    StaticLedgerNameEnum.MortgageLoanMoreThanYear,
    StaticLedgerNameEnum.BankLoanAndMortgageInterest,
  ];
  const [unitOptions, setUnitOptions] = useState([]);
  const [loanOptions, setLoanOptions] = useState([]);
  const { getApi } = Api();
  const { propertyOptions, ledgerNameOptions, fieldTypeOptions } =
    useContext(OptionsContext);
  const { taxAmount, total } = calcInvoiceBillHandler(
    value?.quantity,
    value?.rate,
    value?.discountPercent,
    value?.taxRate || 0,
    taxType
  );

  useEffect(() => {
    if (value?.property) {
      (async () => {
        try {
          setLoading(true);
          let res = await getApi(ApiUrl.unit.get_unit, {
            property: value?.property,
          });
          setUnitOptions(res?.data?.docs);
        } catch (err) {
          setError(true, err?.message);
          setUnitOptions([]);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [value?.property]);
  useEffect(() => {
    (async () => {
      if (value?.property && value?.account) {
        let decodeItemName: any = ledgerNameOptions?.find(
          (e) => e?._id === value?.account
        );
        if (
          decodeItemName?._id &&
          loanActiveAt?.includes(decodeItemName?.value)
        ) {
          const res = await getApi(ApiUrl.loansAndMortgages.get_loans, {
            property: value?.property,
            unit: value?.unit,
          });
          setLoanOptions(res?.data?.docs);
        }
      }
    })();
  }, [value?.property, value?.unit, value?.account]);
  const canSelectLoanOptions = useMemo(() => {
    if (value?.property && value?.account) {
      let decodeItemName: any = ledgerNameOptions?.find(
        (e) => e?._id === value?.account
      );
      if (decodeItemName?._id && loanActiveAt?.includes(decodeItemName?.name)) {
        return true;
      }
    }
    return false;
  }, [value?.property, value?.unit, value?.account]);
  return (
    <tr className={"table_row bg-transparent"}>
      <td>
        <FastField
          name={`newMatch.invoiceData.billItems.${index}.property`}
          component={(props) => (
            <MySelectField
              {...props}
              name={`newMatch.invoiceData.billItems.${index}.property`}
              inputSize={InputSizeEnum.SM}
              style={{
                width: "150px",
                borderRadius: "5px",
              }}
              option={{
                selectOptions: decodePropertyOptionsHandler(propertyOptions),
                defaultLabel: "Property",
                onChangeCallback(currentValue?: any) {
                  setFieldValue(
                    `newMatch.invoiceData.billItems.${index}.unit`,
                    ""
                  );
                },
              }}
            />
          )}
        />
      </td>
      <td>
        <MySelectField
          style={{
            width: "150px",
            borderRadius: "5px",
          }}
          disabled={!value?.property}
          inputSize={InputSizeEnum.SM}
          isClearable
          option={{
            selectOptions: decodeUnitOptionsHandler(unitOptions),
            defaultLabel: "Unit",
          }}
          name={`newMatch.invoiceData.billItems.${index}.unit`}
        />
      </td>
      <td>
        <div className={"flex items-center gap-2"}>
          <MySelectField
            inputSize={InputSizeEnum.SM}
            name={`newMatch.invoiceData.billItems.${index}.account`}
            style={{
              width: "150px",
              borderRadius: "5px",
            }}
            option={{
              selectOptions: decodeLedgerNameOptionsHandler(ledgerNameOptions),
              defaultLabel: "Account",
            }}
          />
          {canSelectLoanOptions && (
            <MySelectField
              name={`newMatch.invoiceData.billItems.${index}.mortgageID`}
              inputSize={InputSizeEnum.SM}
              isClearable
              style={{
                width: "150px",
                borderRadius: "5px",
              }}
              option={{
                selectOptions: decodeLoanOptionsHandler(loanOptions),
                defaultLabel: "Loan",
              }}
            />
          )}
        </div>
      </td>
      <td>
        <MyFastTextField
          placeholder={"type here"}
          className={" rounded-md"}
          inputSize={InputSizeEnum.SM}
          name={`newMatch.invoiceData.billItems.${index}.description`}
        />
      </td>
      <td>
        <MyFastTextField
          type={"number"}
          placeholder={"Eg: 10"}
          className={" rounded-md w-[80px]"}
          inputSize={InputSizeEnum.SM}
          name={`newMatch.invoiceData.billItems.${index}.quantity`}
        />
      </td>
      <td>
        <MyFastTextField
          type={"number"}
          placeholder={"Eg: 10"}
          inputSize={InputSizeEnum.SM}
          className={"w-[100px]  rounded-md  pl-1"}
          name={`newMatch.invoiceData.billItems.${index}.rate`}
        />
      </td>
      <td>
        <MyFastTextField
          type={"number"}
          placeholder={"Eg: 10"}
          inputSize={InputSizeEnum.SM}
          className={"rounded-md  pl-1 w-[80px]"}
          name={`newMatch.invoiceData.billItems.${index}.discountPercent`}
        />
      </td>
      {taxType !== TaxTypeEnum.EXEMPTED && (
        <td>
          <FastField
            name={`newMatch.invoiceData.billItems.${index}.taxRate`}
            component={(props) => {
              return (
                <MySelectField
                  {...props}
                  inputSize={InputSizeEnum.SM}
                  style={{
                    width: 100,
                  }}
                  option={{
                    selectOptions: fieldTypeOptions[FieldTypeEnum.TAX]?.map(
                      (e) => {
                        return {
                          label: e?.label,
                          value: e?.value,
                        };
                      }
                    ),
                    defaultLabel: "Tax",
                  }}
                  name={`newMatch.invoiceData.billItems.${index}.taxRate`}
                />
              );
            }}
          />
        </td>
      )}
      {taxType === TaxTypeEnum.INCLUSIVE && (
        <td className={"pl-2"}>
          <span>
            {getCurrency()}{parseFloat(String(taxAmount || 0)).toFixed(2)}
          </span>
        </td>
      )}
      <td className={"pl-2"}>
        <span>
          {getCurrency()}{parseFloat(String(total || 0)).toFixed(2)}
        </span>
      </td>
      <td>
        <div className={"flex gap-2 items-center"}>
          <MyIconButton
            IconType={IconTypeEnum.ADD}
            iconColor={"text-primary"}
            containerStyle={"border-primary border-[1px] p-1"}
            onClick={() => insertHandler(index + 1, {})}
          />
          {index !== 0 && (
            <MyIconButton
              IconType={IconTypeEnum.MINUS}
              onClick={() => removeHandler(index)}
              iconColor={"text-tRed"}
              containerStyle={"border-tRed border-[1px] p-1"}
            />
          )}
        </div>
      </td>
    </tr>
  );
}

export default InvoiceFormListTable;
