import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDateHook, usePageQueryHooks } from "useHooks";

import { BankReconciliationContext, PDFContext } from "context";
import { PageTemplate } from "templates";
import {
  IconTypeEnum,
  ParamsStringEnum,
  ReconcileItemInterface,
  TableColumnInterface,
} from "interfaces";
import { PageLinks } from "routes";
import { NameValueText } from "assets";
import { capitalizeFirstLetter, getCurrency, separateWithComma } from "helpers";
import { MyButton, TableComponent } from "components";

function ReconcileDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const id = params.id;
  const { getDateHandler, getStaffFullName } = useDateHook();
  const { downloadReconcilePDF } = useContext(PDFContext);
  const {
    getReconcileItemDetailsHandler,
    reconcileItemDetails,
    isDetailsLoading,
  } = useContext(BankReconciliationContext);
  useEffect(() => {
    if (id) {
      getReconcileItemDetailsHandler(id);
    }
  }, [id]);

  return (
    <PageTemplate
      scrollAll
      title={"Reconcile"}
      backNavigation={{
        title: "Batch Deposit Slip",
        path: PageLinks.reconciliation.reconciledLists,
      }}
      breadCrumb={[
        {
          name: "Finance",
        },
        {
          name: "Reconciled Statement",
        },
      ]}
    >
      <div className={"flex flex-col gap-10 sm:gap-5 overflow-x-scroll "}>
        <div>
          <MyButton
            iconType={IconTypeEnum.PDF}
            colorType={"danger"}
            name={"Download PDF"}
            onClick={() => downloadReconcilePDF(reconcileItemDetails?._id)}
          />
        </div>
        <div className={"grid grid-cols-4 gap-5 md:grid-cols-3 sm:grid-cols-1"}>
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Reconciled Date"}
            value={getDateHandler({
              date: reconcileItemDetails?.reconciledDate,
            })}
          />
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Reconciled By"}
            value={getStaffFullName(reconcileItemDetails?.createdBy?.name)}
          />
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Statement"}
            value={
              reconcileItemDetails?.statement?.remarks ??
              reconcileItemDetails?.statement?.merchantName ??
              "-"
            }
          />
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Statement Code"}
            value={reconcileItemDetails?.statement?.statementCode || "-"}
          />
        </div>
        <div className={"grid grid-cols-4 gap-5 md:grid-cols-3 sm:grid-cols-1"}>
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Bank"}
            value={reconcileItemDetails?.bank?.name}
          />
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Bank Account"}
            value={reconcileItemDetails?.bank?.accountNumber}
          />
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Remarks"}
            value={reconcileItemDetails?.remarks || "-"}
          />
        </div>
        <div className={"overflow-x-scroll"}>
          <TableComponent
            column={ReconciledItems(reconcileItemDetails?.statement?.currency)}
            dataSource={reconcileItemDetails?.reconciled}
            loading={{
              isLoading: isDetailsLoading,
              loadingRow: 5,
            }}
          />
        </div>

        {/*bills*/}
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 mt-5"}>
          <div className={"col-span-2 md:col-span-1"}></div>
          <div
            className={
              "grid grid-cols-3 bg-primary text-white p-3 rounded-md font-bold"
            }
          >
            <span className={"col-span-2"}>
              TOTAL{" "}
              {reconcileItemDetails?.statement?.currency || getCurrency(true)}:
            </span>
            <span>
              {reconcileItemDetails?.statement?.currency || getCurrency(true)}{" "}
              {separateWithComma(reconcileItemDetails?.balance)}
            </span>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

function ReconciledItems(
  currency
): TableColumnInterface<ReconcileItemInterface>[] {
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { getDateHandler, getStaffFullName } = useDateHook();
  return [
    {
      title: "From",
      render(renderData, index) {
        return <span>{getStaffFullName(renderData?.client?.name)}</span>;
      },
    },
    {
      title: "Type",
      render(renderData, index) {
        return <span>{capitalizeFirstLetter(renderData?.type)}</span>;
      },
    },
    {
      title: "Invoice",
      render(renderData, index) {
        return (
          <div className={"flex flex-col gap-2"}>
            <span
              className={"text-blue-600 font-bold"}
              onClick={
                renderData?.invoiceID?._id
                  ? () =>
                      navigateWithReturnUrlHandler(
                        PageLinks.invoice.details(renderData?.invoiceID?._id)
                      )
                  : undefined
              }
            >
              {renderData?.invoiceID?.invoiceID}
            </span>
            <span>{renderData?.invoiceID?.invoiceType}</span>
          </div>
        );
      },
    },
    {
      title: "Journal",
      render(renderData, index) {
        return (
          <div className={"flex flex-col gap-2"}>
            <span
              className={"text-blue-600 font-bold"}
              onClick={
                renderData?.journalID?._id
                  ? () =>
                      navigateWithReturnUrlHandler(
                        PageLinks.journal.details(renderData?.journalID?._id)
                      )
                  : undefined
              }
            >
              {renderData?.journalID?.journalID || "-"}
            </span>
          </div>
        );
      },
    },

    {
      title: "Invoice Date",
      render(renderData, index) {
        return (
          <span>
            {getDateHandler({ date: renderData?.invoiceID?.invoiceDate }) ||
              "-"}
          </span>
        );
      },
    },
    {
      title: `Payment ${currency}`,
      render(renderData, index) {
        return (
          <span>
            {" "}
            {currency || getCurrency()}{separateWithComma(renderData?.balance)}
          </span>
        );
      },
    },
  ];
}

export default ReconcileDetailsPage;
