import * as yup from "yup";
import { RoomUnitInterface, UnitInterface } from "interfaces";

export const newUnitFormikValues = {
  roomValues: (values?: RoomUnitInterface) => {
    return {
      roomType: values?.roomType?._id,
      quantity: values?.quantity,
    };
  },
  initialValues: (values?: UnitInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      area: {
        unit: values?.area?.unit?._id || "",
        size: values?.area?.size || "",
      },
      marketRent: values?.marketRent || "",
      furnishedType: values?.furnishedType?._id || "",
      property: values?.property?._id || "",
      description: values?.description || "",
      name: values?.name || "",
      floor: values?.floor?._id || "",
      room: values?.room?.map((e) => newUnitFormikValues.roomValues(e)) || [
        newUnitFormikValues.roomValues(),
      ],
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().required("required"),
    marketRent: yup.string(),
    furnishedType: yup.string().required("required"),
    property: yup.string().required("required"),
    description: yup.string(),
    // floor: yup.string().required("required"),
    area: yup.object().shape({
      size: yup.string(),
      unit: yup.string(),
    }),
  }),
  roomValidationSchema: (maxRoom: number) =>
    yup.object().shape({
      room: yup.array().of(
        yup.object().shape({
          roomType: yup.string().required("required"),
          quantity: yup
            .number()
            .min(0, "invalid")
            .max(maxRoom, `must be less than ${maxRoom}`)
            .required("required"),
        })
      ),
    }),
};
