import React from "react";
import { useNavigate } from "react-router-dom";
import {
  IconTypeEnum,
  SubLedgerNameInterface,
  TableColumnInterface,
} from "interfaces";
import { MyIconButton } from "components";

import { PageLinks } from "routes";
import { usePaginationQueryHooks } from "useHooks";

function SubLedgerNameTableCols(): TableColumnInterface<SubLedgerNameInterface>[] {
  const navigate = useNavigate();
  const { calcSNHandler } = usePaginationQueryHooks();
  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },
    {
      title: "Name",
      render(renderData, key: number) {
        return <span className={""}>{renderData?.name}</span>;
      },
    },
    {
      title: "Group",
      render(renderData, key: number) {
        return <span className={""}>{renderData?.group}</span>;
      },
    },
    {
      title: "Action",
      render(renderData, key: number) {
        return (
          <div className={"flex flex-row  gap-4 items-center"}>
            <MyIconButton
              iconSize={14}
              onClick={() => {
                if (renderData?.canEdit) {
                  navigate(PageLinks.subLedgerName.edit(renderData?._id));
                }
              }}
              containerStyle={`${
                renderData?.canEdit
                  ? "text-primary border-primary"
                  : "text-blue-100 border-blue-100"
              }  border-2  p-2`}
              IconType={IconTypeEnum.EDIT}
            />
          </div>
        );
      },
    },
  ];
}

export default SubLedgerNameTableCols;
