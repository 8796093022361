import React, { useContext, useEffect } from "react";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { FormConcludeButtons } from "assets";
import { PageTemplate } from "templates";
import { FieldTypeEnum, ParamsStringEnum } from "interfaces";
import { AppContext, EventContext, OptionsContext } from "context";

import { EventFormikValues } from "../helpers";
import { EventsForm } from "../components";
import { usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function NewEventPage() {
  const navigate = useNavigate();
  const params = useParams<ParamsStringEnum>();
  const { returnURL } = usePageQueryHooks();
  const {
    getPropertyOptionsHandler,
    getUserOptionsHandler,
    getOptionsHandler,
  } = useContext(OptionsContext);
  const {
    handlers: { setLoading },
  } = useContext(AppContext);

  const { details, editDetailsHandler, getDetailsHandler } =
    useContext(EventContext);
  const isEdit = params.id;
  useEffect(() => {
    (async () => {
      setLoading(true);

      await Promise.all([
        isEdit && getDetailsHandler(isEdit),
        getPropertyOptionsHandler(),
        getUserOptionsHandler(),
        getOptionsHandler(FieldTypeEnum.YEARLY_FREQUENCY),
        getOptionsHandler(FieldTypeEnum.REMINDER_BEFORE_AFTER),
      ]);

      setLoading(false);
    })();
  }, [isEdit]);

  const submitHandler = async (values, formikHelpers) => {
    await editDetailsHandler(values, formikHelpers, {
      onSuccess: async (payload) => {
        if (returnURL) {
          navigate(returnURL);
        } else {
          navigate(PageLinks.events.list);
        }
      },
      onError(error?: any) {},
    });
  };
  return (
    <PageTemplate
      title={"Events"}
      scrollAll
      breadCrumb={[
        {
          name: `${isEdit ? "Edit" : "New"} Events`,
        },
      ]}
      backNavigation={{
        title: `${isEdit ? "Edit" : "New"} Events`,
        path: PageLinks.events.list,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={EventFormikValues?.initialValues(isEdit && details)}
        validationSchema={EventFormikValues?.validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={"flex flex-col gap-10"}>
              <EventsForm />
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                  handler() {
                    navigate(PageLinks.events.list);
                  },
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default NewEventPage;
