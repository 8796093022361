import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { GeneratedTrialBalanceApiResInterface } from "interfaces";
import { separateWithComma } from "helpers";

interface GenerateTBReportInterface {
  data?: GeneratedTrialBalanceApiResInterface;
  dateHeader?: string;
}
async function generateTBReport({
  data,
  dateHeader,
}: GenerateTBReportInterface) {
  try {
    //    create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet("Trial Balance", {});
    worksheet.properties.defaultRowHeight = 20;
    // setting Account header
    worksheet.getCell("C1").value = "Trial Balance";
    worksheet.getCell("C2").value = dateHeader;

    worksheet.mergeCells("C1:G1");
    worksheet.mergeCells("C2:G2");

    // setting Table head
    worksheet.getCell("A4").value = "SN.";
    worksheet.getCell("B4").value = "Group/Ledger";
    worksheet.getCell("C4").value = "Type";
    worksheet.getCell("D4").value = "Opening";
    worksheet.getCell("F4").value = "Transaction";
    worksheet.getCell("H4").value = "Closing";
    // merging a cell
    worksheet.mergeCells("D4:E4");
    worksheet.mergeCells("F4:G4");
    worksheet.mergeCells("H4:I4");

    // making sub  title
    worksheet.getCell("D5").value = "DR";
    worksheet.getCell("E5").value = "CR";
    worksheet.getCell("F5").value = "DR";
    worksheet.getCell("G5").value = "CR";
    worksheet.getCell("H5").value = "DR";
    worksheet.getCell("I5").value = "CR";
    const fillCols = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    fillCols.forEach((colVal) => {
      return (worksheet.getCell(5, colVal).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFF2F2F2" },
      });
    });

    // filling details in sub-title
    worksheet.getCell("A6").value = "Total";
    worksheet.getCell("D6").value = separateWithComma(
      data?.totalOpeningBalanceDebit
    );
    worksheet.getCell("E6").value = separateWithComma(
      data?.totalOpeningBalanceCredit
    );
    worksheet.getCell("F6").value = separateWithComma(data?.totalBalanceDebit);
    worksheet.getCell("G6").value = separateWithComma(data?.totalBalanceCredit);
    worksheet.getCell("H6").value = separateWithComma(
      data?.totalClosingBalanceDebit
    );
    worksheet.getCell("I6").value = separateWithComma(
      data?.totalClosingBalanceCredit
    );
    worksheet.mergeCells("A6:C6");

    // inserting data in table row
    data?.ledgers?.forEach((entry, key) => {
      worksheet.addRow([
        key + 1,
        entry?.account?.name,
        entry?.account?.group,
        entry?.openingBalance > 0
          ? separateWithComma(Math.abs(entry?.openingBalance))
          : 0.0,
        entry?.openingBalance < 0
          ? separateWithComma(Math.abs(entry?.openingBalance))
          : 0.0,
        entry?.balance > 0 ? separateWithComma(Math.abs(entry?.balance)) : 0.0,
        entry?.balance < 0 ? separateWithComma(Math.abs(entry?.balance)) : 0.0,
        entry?.closingBalance > 0
          ? separateWithComma(Math.abs(entry?.closingBalance))
          : 0.0,
        entry?.closingBalance < 0
          ? separateWithComma(Math.abs(entry?.closingBalance))
          : 0.0,
      ]);
    });

    // aligning center
    worksheet.columns.forEach((column, columnIndex) => {
      if (columnIndex >= 2) {
        // Start from the third column (index 2)
        column.eachCell((cell) => {
          cell.alignment = { horizontal: "center" };
        });
      }
    });
    worksheet.getColumn(2).width = 50;
    worksheet.getColumn(3).width = 40;
    new Array(6).fill("").forEach((e, key) => {
      worksheet.getColumn(4 + key).width = 20;
    });

    // increase the font size
    worksheet.columns.forEach((col) => {
      col.eachCell((cell) => {
        cell.font = {
          size: 14,
        };
      });
    });
    // style header style
    worksheet.getCell("C1").alignment = {
      horizontal: "center",
    };
    worksheet.getCell("C1").font = {
      bold: true,
      size: 20,
    };
    worksheet.getCell("C2").alignment = {
      horizontal: "center",
    };
    worksheet.getRow(4).font = {
      bold: true,
      size: 14,
    };
    worksheet.getRow(5).font = {
      bold: true,
      size: 14,
    };
    worksheet.getRow(6).font = {
      bold: true,
      size: 14,
    };

    worksheet.views = [
      {
        state: "frozen",
        xSplit: 0,
        ySplit: 6,
      },
    ];
    //    save the workbook to a file
    const bufferFile = await workbook.xlsx.writeBuffer();
    await FileSaver.saveAs(
      new Blob([bufferFile], { type: "application/octet-stream" }),
      `${Date.now()}tb.xlsx`
    );
  } catch (err) {
    throw err;
  }
}

export default generateTBReport;
