import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageTemplate } from "templates";

import { AppContext, BankContext, TrueLayerContext } from "context";
import { MyButton, TableCardComponent } from "components";
import { PageLinks } from "routes";
import { IconTypeEnum, SpaceAccessListEnum } from "interfaces";
import { useAuthorizationHook, usePaginationQueryHooks } from "useHooks";

import { BankTableColumns } from "../components";
import { navigateLinkHandler } from "helpers";

function BankPage() {
  const {
    allData,
    isLoading,
    getAllDataHandlers,
    totalDocs,
    totalArchiveDocs,
  } = useContext(BankContext);
  const {
    handlers: { getTrueLayerSettingsHandler },
    state: { trueLayerSettings },
  } = useContext(AppContext);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAddBank = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_ADD_BANK,
  ]);
  const canLinkBank = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_LINK_BANK,
  ]);
  const { getAuthLinkHandler } = useContext(TrueLayerContext);
  const { isArchive, currentPage } = usePaginationQueryHooks();
  const navigate = useNavigate();
  const pageQuery = {};
  useEffect(() => {
    if (!trueLayerSettings) {
      getTrueLayerSettingsHandler();
    }
  }, []);
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [currentPage, isArchive]);
  const tableCardProps = {
    column: BankTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      rightChildren: (
        <div className={"flex items-center gap-2"}>
          {canAddBank && (
            <MyButton
              onClick={() => {
                navigate(PageLinks.banks.new);
              }}
              iconType={IconTypeEnum.ADD}
              name={`New Bank`}
            />
          )}
          {canLinkBank && trueLayerSettings?.enableTrueLayer && (
            <MyButton
              isOutline
              onClick={() => {
                getAuthLinkHandler(
                  {},
                  {
                    onSuccess(payload?: any): any {
                      navigateLinkHandler(payload);
                    },
                  }
                );
              }}
              iconType={IconTypeEnum.ADD}
              name={`Link Bank`}
            />
          )}
        </div>
      ),
    },
  };
  return (
    <PageTemplate
      title={"Bank"}
      breadCrumb={[
        {
          name: "Banks",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default BankPage;
