import React, { createContext } from "react";

import { Api } from "helpers";
import { ApiUrl } from "services";
import { DashboardContextInterface } from "./interfaces";
import { HandlerCallbackInterface, DashboardApiTypeEnum } from "interfaces";

export const DashboardStatsContext = createContext<DashboardContextInterface>({
  getStatsHandler(
    type: DashboardApiTypeEnum,
    query: any,
    callback: HandlerCallbackInterface
  ) {},
});

function DataContextProvider({ children }) {
  const { getApi } = Api();
  //  handler
  const contextHandlers = {
    async getStatsHandler(
      type: DashboardApiTypeEnum,
      query: any,
      callback: HandlerCallbackInterface
    ) {
      try {
        let url;
        switch (type) {
          case DashboardApiTypeEnum.booking:
            url = ApiUrl.dashboard.getBookingStats;
            break;
          case DashboardApiTypeEnum.reconciliation:
            url = ApiUrl.bankReconciliation.get_bankWithStatement;
            query = {
              ...query,
              limit: 4,
            };
            break;
          case DashboardApiTypeEnum.invoice:
            url = ApiUrl.dashboard.getInvoiceStats;
            break;
          case DashboardApiTypeEnum.task:
            url = ApiUrl.dashboard.getTaskStats;
            break;
          case DashboardApiTypeEnum.mortgage:
            url = ApiUrl.dashboard.getMortgageStats;
            break;
          case DashboardApiTypeEnum.unit:
            url = ApiUrl.dashboard.getUnitStats;
            break;
          case DashboardApiTypeEnum.tenancy:
            url = ApiUrl.dashboard.getTenancyStats;
            break;
          case DashboardApiTypeEnum.maintenance:
            url = ApiUrl.dashboard.getMaintenanceStats;
            break;
          case DashboardApiTypeEnum.property:
            url = ApiUrl.dashboard.get_propertyStats;
            break;
          case DashboardApiTypeEnum.profitLoss:
            url = ApiUrl.dashboard.getProfitLoss;
            break;
          case DashboardApiTypeEnum.IncomeExpensesChart:
            url = ApiUrl.dashboard.getIncomeStatement;
            break;
          case DashboardApiTypeEnum.purchaseChart:
            url = ApiUrl.dashboard.getPurchase;
            break;
          case DashboardApiTypeEnum.salesChart:
            url = ApiUrl.dashboard.getSales;
            break;
          default:
            url = "";
        }
        const res = await getApi(url, query);
        if (typeof callback.onSuccess === "function") {
          callback.onSuccess(res?.data?.docs || res?.data);
        }
      } catch (err) {
        if (typeof callback.onError === "function") {
          callback?.onError(err);
        }
      }
    },
  };

  const contextValue = {
    ...contextHandlers,
  };
  return (
    <DashboardStatsContext.Provider value={contextValue}>
      {children}
    </DashboardStatsContext.Provider>
  );
}

export default DataContextProvider;
