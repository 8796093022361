import React, { useContext, useEffect } from "react";

import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { FormConcludeButtons } from "assets";
import { AppContext, LedgerNameContext, OptionsContext } from "context";
import { PageTemplate } from "templates";
import { ParamsStringEnum } from "interfaces";

import { LedgerNameForm } from "../../components";
import { ledgerNameFormikValues } from "../../helpers";
import { PageLinks } from "routes";

function EditLedgerNamePage() {
  const { details, getDetailsHandler, editDetailsHandler } =
    useContext(LedgerNameContext);
  const { getLedgerGroupListHandler } = useContext(OptionsContext);
  const {
    handlers: { setLoading },
  } = useContext(AppContext);
  const params = useParams<ParamsStringEnum>();
  const isEdit = params?.id;
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      if (isEdit) {
        setLoading(true);
        await getDetailsHandler(isEdit);
        setLoading(false);
      }
      await getLedgerGroupListHandler();
    })();
  }, [isEdit]);

  return (
    <PageTemplate
      scrollAll
      title={"Ledger Name"}
      backNavigation={{
        title: `${isEdit ? "Edit" : "Add"} ledger name`,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={ledgerNameFormikValues?.initialValues(isEdit && details)}
        validationSchema={ledgerNameFormikValues?.validationSchema}
        onSubmit={async (values, formikHelpers: any) => {
          await editDetailsHandler(values, formikHelpers, {
            onSuccess: async (payload) => {
              navigate(PageLinks?.ledgerName?.list);
            },
          });
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={"flex flex-col gap-5"}>
              <LedgerNameForm />
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                  handler: () => {
                    navigate(-1);
                  },
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default EditLedgerNamePage;
