import {
  CustomerInterface,
  DocumentInterface,
  DynamicListInterface,
  FieldTypeEnum,
  FiscalYearInterface,
  LedgerGroupListInterface,
  LedgerNameInterface,
  PrivilegeInterface,
  PropertyInterface,
  UnitInterface,
  UserRoleEnum,
  InvoiceInterface,
  InvoiceStatusEnum,
  BankInterface,
  LoanAndMortgageInterface,
  BankSourceEnum,
} from "interfaces";

export interface OptionsContextInterface {
  fieldTypeOptions: {
    [FieldTypeEnum.DOCUMENT_TYPE]?: DynamicListInterface[];
    [FieldTypeEnum.OWNER_TYPE]?: DynamicListInterface[];
    [FieldTypeEnum.PROPERTY_TYPE]?: DynamicListInterface[];
    [FieldTypeEnum.UNIT_SIZE]?: DynamicListInterface[];
    [FieldTypeEnum.OWNERSHIP_TYPE]?: DynamicListInterface[];
    [FieldTypeEnum.PROPERTY_AMENITIES]?: DynamicListInterface[];
  };
  bankOptions: BankInterface[];
  privilegeOptions: PrivilegeInterface[];
  fiscalYearOptions: FiscalYearInterface[];
  propertyOptions: PropertyInterface[];
  unitOptions: UnitInterface[];
  ownerOptions: CustomerInterface[];
  userOptions: CustomerInterface[];
  ledgerNameOptions: LedgerNameInterface[];
  documentOptions: DocumentInterface[];
  ledgerGroupList: LedgerGroupListInterface[];
  invoiceOptions: InvoiceInterface[];
  loanOptions: LoanAndMortgageInterface[];
  getInvoiceHandler(status: InvoiceStatusEnum);
  getLoanOptionsHandler(property: string, unit: string);
  getLedgerOptionsHandler(query?: any);
  getBankOptionsHandler(source?: BankSourceEnum);
  getFiscalYearOptionsHandler();
  getDocumentOptionsHandler();
  getOptionsHandler(optionType: FieldTypeEnum);
  getPropertyOptionsHandler();
  getUserOptionsHandler(userRole?: UserRoleEnum);
  getUnitOptionsHandler(property?: string);
  getOwnerOptionsHandler();
  getPrivilegeOptionsHandler();
  getLedgerGroupListHandler();
}

export enum OptionsDataEnum {
  propertyOptions = "propertyOptions",
  unitOptions = "unitOptions",
  ownerOptions = "ownerOptions",
  privilegeOptions = "privilegeOptions",
  userOptions = "userOptions",
  ledgerNameOptions = "ledgerNameOptions",
  documentOptions = "documentOptions",
  ledgerGroupList = "ledgerGroupList",
  fiscalYearOptions = "fiscalYearOptions",
  invoiceOptions = "invoiceOptions",
  bankOptions = "bankOptions",
  loanOptions = "loanOptions",
}
