import React, { useMemo, useState } from "react";
import { IconTypeEnum, InvoiceBillInterface } from "interfaces";
import { MyButton, MyIconButton } from "components";
import { getIconHelper } from "helpers";
import { InvoiceFormikForm } from "../helpers";

interface propsInterface {
  pushHandler(details);
  allBillItems: InvoiceBillInterface[];
  addedBillItems: InvoiceBillInterface<number>[];
}
function InvoiceAddRemainingItems({
  allBillItems,
  addedBillItems,
  pushHandler,
}: propsInterface) {
  const [isOpen, setOpen] = useState(false);
  const remainingBillItems = useMemo(() => {
    let items: any = [];
    items = allBillItems?.filter((e) => {
      return !addedBillItems?.some((f) => e?._id === f?._id);
    });
    return items;
  }, [allBillItems, addedBillItems]);

  if (remainingBillItems?.length < 1) {
    return;
  }
  return (
    <div className={"flex flex-col gap-5"}>
      <div>
        <MyButton
          isOutline
          iconType={IconTypeEnum.ADD}
          name={"Add Remaining Items"}
          onClick={() => setOpen(true)}
        />
      </div>
      {isOpen && (
        <div className={"flex flex-col gap-5 p-5 rounded-md bg-gray-50"}>
          <div className={"flex items-center justify-between"}>
            <b>Remaining Items</b>
            <MyIconButton
              IconType={IconTypeEnum.CLOSE}
              iconColor={"text-gray-500"}
              containerStyle={"border-gray-500 border-[1px] p-1"}
              onClick={() => setOpen(false)}
            />
          </div>
          <table className={"table_container table-auto "}>
            <thead className={"table_head  h-[50px] "}>
              <th>Property</th>
              <th>Unit</th>
              <th>Account</th>
              <th>Description</th>
              <th>QTY.</th>
              <th>Rate</th>
              <th>Disc%</th>
              <th>TAX Rate</th>
              <th>TAX Amount</th>
              <th>Amount</th>
              <th></th>
            </thead>
            <tbody>
              {remainingBillItems?.map((e, key) => {
                return (
                  <tr className={"table_row"} key={key}>
                    <td>{e?.property?.propertyID || "-"}</td>
                    <td>{e?.unit?.unitID || "-"}</td>
                    <td>{e?.item?.label || e?.account?.name}</td>
                    <td>{e?.description}</td>
                    <td>{e?.quantity}</td>
                    <td>{e?.rate}</td>
                    <td>{e?.discountPercent || 0}%</td>
                    <td>{e?.taxRate?.label || 0}%</td>
                    <td>{e?.taxAmount || 0}</td>
                    <td>{e?.total || 0}</td>
                    <td>
                      <MyIconButton
                        IconType={IconTypeEnum.ADD}
                        iconColor={"text-primary"}
                        containerStyle={"border-primary border-[1px] p-1"}
                        onClick={() =>
                          pushHandler(InvoiceFormikForm.billItemValue(e))
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default InvoiceAddRemainingItems;
