import React, { useContext, useEffect, useState } from "react";
import { DashboardStatsContext } from "context";
import { getCurrency, getIconHelper, separateWithComma } from "helpers";
import {
  DashboardApiTypeEnum,
  IconTypeEnum,
  PropertyDashboardResponseInterface,
} from "interfaces";

function PropertyStatsContent() {
  const { getStatsHandler } = useContext(DashboardStatsContext);
  const PropertyIcon = getIconHelper(IconTypeEnum.HOME);
  const ValueIcon = getIconHelper(IconTypeEnum.PROPERTY_VALUE);
  const KeysIcon = getIconHelper(IconTypeEnum.KEY);
  const GraphIcon = getIconHelper(IconTypeEnum.RENTAL_PROFIT);
  const OperationProfitIcon = getIconHelper(IconTypeEnum.OPERATION_PROFIT);
  const PropertyTotalIcon = getIconHelper(IconTypeEnum.PROPERTY_TOTAL);

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState<PropertyDashboardResponseInterface>(null);
  useEffect(() => {
    (async () => {
      setLoading(true);
      await getStatsHandler(
        DashboardApiTypeEnum.property,
        {},
        {
          onError(error?: any) {
            setError(error);
            setLoading(false);
          },
          onSuccess(payload?: any): any {
            setData(payload);
            setLoading(false);
          },
        }
      );
    })();
  }, []);
  const ContentData = [
    {
      name: "Property Value",
      value: `${getCurrency()}${
        separateWithComma(data?.propertyValue) || "-"
      }`,
      style: "bg-[#6B9DFE]",
      icon: PropertyIcon,
    },
    {
      name: "Property Cost",
      value: `${getCurrency()}${separateWithComma(data?.propertyCost) || "-"}`,
      style: "bg-[#BD6BFE]",
      icon: ValueIcon,
    },
    {
      name: "Gross Rental Income",
      value: `${getCurrency()}${
        separateWithComma(data?.netRentalIncome) || "-"
      }`,
      style: "bg-[#FE8E6B]",
      icon: KeysIcon,
    },
    {
      name: "Profit & Loss",
      value: `${getCurrency()}${
        separateWithComma(data?.rentalProfitAndLossToday) || "-"
      }`,
      style: "bg-[#58D164]",
      icon: GraphIcon,
    },
    {
      name: "Number of Properties",
      value: `${data?.propertyCount || "-"}`,
      style: "bg-[#F6A935]",
      icon: PropertyTotalIcon,
    },
    {
      name: "Operating Profit",
      value: `${getCurrency()}${
        separateWithComma(data?.operatingProfit) || "-"
      }`,
      style: "bg-[#33C0DF]",
      icon: OperationProfitIcon,
    },
  ];
  return (
    <div className={"grid grid-cols-2 sm:grid-cols-1 gap-5"}>
      {ContentData?.map((e, key) => {
        const Icon = e?.icon;
        return (
          <div
            key={key}
            className={`flex items-center justify-start gap-5 ${e?.style} rounded-md pl-5 text-white min-h-[140px]`}
          >
            <Icon className={"w-[50px] h-[50px]"} />
            <div className={"flex flex-col gap-2 text-[16px] "}>
              <span className={"text-[20px]"}>{e?.name}</span>
              <span className={"text-[22px] font-bold"}>{e?.value || "-"}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PropertyStatsContent;
