import React from "react";
import { ImageViewCard } from "components";
import { CustomerInterface } from "interfaces";
import { PageLinks } from "routes";
import { usePageQueryHooks } from "useHooks";
import useDateHooks from "../../useHooks/useDateHooks";

interface propsInterface {
  users: CustomerInterface[];
}
function CollapseUserImage({ users }: propsInterface) {
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { getStaffFullName } = useDateHooks();
  return (
    <div className={"flex items-center "}>
      {users?.map((e, key) => {
        return (
          <div
            onClick={() =>
              navigateWithReturnUrlHandler(PageLinks.spaceUser.details(e?._id))
            }
            key={key}
            title={getStaffFullName(e?.name)}
          >
            {e?.photo ? (
              <ImageViewCard
                containerStyle={
                  "w-[40px] h-[40px] bg-white shadow-md  rounded-full border-2 border-white mr-[-15px]"
                }
                fileName={e?.photo || "/img/user/man.jpg"}
              />
            ) : (
              <div
                className={
                  "flex hover:border-green-500 items-center uppercase justify-center bg-primary text-white font-bold w-[40px] h-[40px] bg-white shadow-md  rounded-full border-2 border-white mr-[-15px]"
                }
              >
                {e?.name?.first?.charAt(0)}
                {e?.name?.last?.charAt(0)}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default CollapseUserImage;
