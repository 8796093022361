import React, { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { FormConcludeButtons } from "assets";
import { PageTemplate } from "templates";
import { JournalInterface, ParamsStringEnum } from "interfaces";
import { AppContext, JournalContext, OptionsContext } from "context";

import { calculateJournalTotalAccount, JournalFormikForm } from "../../helpers";
import { JournalForm } from "../../components";
import { PageLinks } from "routes";

function NewJournalPage({ isForOpeningBalance }) {
  const navigate = useNavigate();
  const [enableReinitialize, setEnableReinitialize] = useState(true);
  const [saveCreateNew, setSaveCreateNew] = useState(false);
  const {
    ledgerNameOptions,
    getLedgerOptionsHandler,
    getPropertyOptionsHandler,
    getUnitOptionsHandler,
  } = useContext(OptionsContext);
  const {
    handlers: { setLoading },
  } = useContext(AppContext);
  const params = useParams<ParamsStringEnum>();
  const isEdit = params.id;
  const { details, getDetailsHandler, editDetailsHandler } =
    useContext(JournalContext);
  const initialValues = JournalFormikForm?.initialValues(
    isEdit
      ? details
      : isForOpeningBalance
      ? {
          name: "Opening Balance",
          entries: ledgerNameOptions?.map((e) => {
            return {
              account: e,
              name: "Opening balance",
            };
          }),
        }
      : {}
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      setEnableReinitialize(true);
      if (isEdit) {
        await getDetailsHandler(isEdit);
      }
      await getLedgerOptionsHandler();
      await getPropertyOptionsHandler();
      setLoading(false);
      setEnableReinitialize(false);
    })();
  }, [isEdit, isForOpeningBalance]);
  useEffect(() => {
    if (isEdit && details?.property) {
      getUnitOptionsHandler(details?.property?._id);
    }
  }, [details, isEdit]);

  const submitHandler = async (values, formikHelpers) => {
    if (values?.entries?.length < 1) {
      return;
    }
    const { creditSubTotal, debitSubTotal } =
      calculateJournalTotalAccount(values);
    const totalCredit = parseFloat(String(creditSubTotal))?.toFixed(2);
    const totalDebit = parseFloat(String(debitSubTotal))?.toFixed(2);
    const isBalanced = totalDebit === totalCredit;
    if (creditSubTotal <= 0 || debitSubTotal <= 0) {
      return;
    }
    if (!isBalanced) {
      return;
    }
    let payload: JournalInterface = { ...values };
    payload.entries = payload?.entries.filter(
      (e) => !(e?.debit === 0 && e?.credit === 0)
    );

    await editDetailsHandler(payload, formikHelpers, {
      onSuccess: async (payload) => {
        if (saveCreateNew) {
          formikHelpers.setValues(JournalFormikForm.initialValues({}), false);
          return;
        }
        if (isForOpeningBalance) {
          navigate("/");
        } else {
          navigate(PageLinks.journal.list);
        }
      },
    });
  };
  return (
    <PageTemplate
      title={"Accounting"}
      scrollAll
      backNavigation={{
        title: isForOpeningBalance
          ? "Opening Entries"
          : isEdit
          ? "Edit Journal"
          : "New Journal",
      }}
    >
      <Formik
        enableReinitialize={enableReinitialize}
        initialValues={initialValues}
        validationSchema={JournalFormikForm?.validationSchema}
        onSubmit={submitHandler}
      >
        {({ values, handleSubmit, setFieldValue }) => {
          const hideDraft = isEdit && !values?.isDraft;
          return (
            <Form className={"flex flex-col gap-5"}>
              <div>
                <JournalForm
                  isLoading={enableReinitialize}
                  isOpeningEntries={isForOpeningBalance}
                />{" "}
              </div>

              <FormConcludeButtons
                submitButton={{
                  title: "Save",
                  notSubmit: true,
                  handler() {
                    setSaveCreateNew(false);
                    setFieldValue("isDraft", false);
                    handleSubmit();
                  },
                }}
                saveAndCreateButton={
                  !isForOpeningBalance &&
                  !isEdit && {
                    title: "Save & New",
                    notSubmit: true,
                    handler() {
                      setSaveCreateNew(true);
                      setFieldValue("isDraft", false);
                      handleSubmit();
                    },
                  }
                }
                draftButton={
                  !hideDraft &&
                  !isForOpeningBalance && {
                    title: "Draft",
                    handler() {
                      setSaveCreateNew(false);
                      setFieldValue("isDraft", true);
                      handleSubmit();
                    },
                  }
                }
                cancelButton={{
                  title: isForOpeningBalance ? "Skip" : "Cancel",
                  handler: () => {
                    isForOpeningBalance ? navigate("/") : navigate(-1);
                  },
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default NewJournalPage;
