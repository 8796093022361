import { CustomerInterface } from "interfaces";

export enum ActivityActionEnum {
  "READ" = "READ",
  "ADD" = "ADD",
  "DELETE" = "DELETE",
  "ARCHIVED" = "ARCHIVED",
  "UNARCHIVED" = "UNARCHIVED",
  "UPDATE" = "UPDATE",
  "NONE" = "NONE",
  "ASSIGN" = "ASSIGN",
  "REMOVE" = "REMOVE",
  "LOGIN" = "LOGIN",
}

export interface ActivityConfig {
  ipAddress?: string;
  device?: string;
  os?: string;
}

export interface ActivityInterface extends ActivityConfig {
  _id: string;
  customer: CustomerInterface["_id"];
  // systemUser: SystemTenantUserInterface["_id"];
  modelName: string;
  action: ActivityActionEnum; // might be enum like edit/delete/
  description?: string;
  itemName: string; // John's profile
  itemID: string[];
}
