import React, { useContext, useEffect, useState } from "react";
import { FastField, FieldArray, useFormikContext } from "formik";
import { MaintenanceFormikValues } from "../../helpers";
import queryString from "query-string";

import {
  FileViewCard,
  MyButton,
  MyFastTextField,
  MyRadioInput,
  MySelectField,
  MyTextField,
  MyUploadDocument,
  TabComponent,
} from "components";
import {
  Api,
  decodeDynamicFieldHandler,
  decodePropertyOptionsHandler,
  decodeUnitOptionsHandler,
  decodeUserOptionsHandler,
} from "helpers";
import {
  FieldTypeEnum,
  IconTypeEnum,
  InventoryInterface,
  InventoryItemInterface,
  MaintenanceItemInterface,
  MaintenanceMultipleFormInterface,
  QueryStringEnum,
} from "interfaces";
import { AppContext, OptionsContext } from "context";
import { ApiUrl } from "services";
import { useSearchParams } from "react-router-dom";
import { FormWrapper, NameValueText } from "assets";
import { usePageQueryHooks } from "useHooks";

function MaintenanceForm({ isLoading }: { isLoading: boolean }) {
  const { values, setFieldValue } =
    useFormikContext<MaintenanceMultipleFormInterface<string>>();
  const { unitID, propertyID, itemID } = usePageQueryHooks();
  const [tabs, setTabs] = useState<{ title: string; pathName: string }[]>([]);
  const [unitInventories, setUnitInventories] =
    useState<InventoryInterface>(null);
  const { propertyOptions, unitOptions, userOptions, getUnitOptionsHandler } =
    useContext(OptionsContext);
  console.log(values);
  const {
    handlers: { setLoading, setError },
  } = useContext(AppContext);
  const { getApi } = Api();

  // query values
  const [query] = useSearchParams();

  let activeTab = query.get(QueryStringEnum.ACTIVE_TAB);
  const roomSerialNumber: any =
    queryString.parse(activeTab)?.roomSerialNumber ||
    query?.get("roomSerialNumber");
  const roomID: any =
    queryString.parse(activeTab)?.roomID || query?.get("roomID");
  if (activeTab) {
    activeTab =
      activeTab.split("&").length > 0 ? activeTab.split("&")[0] : activeTab;
  }

  const getUnitRoomsAndInventoryHandler = async (unit, property) => {
    // for unit rooms
    try {
      setLoading(true);

      const unitRes = await getApi(ApiUrl.unit.get_unitDetails + unit);
      const unitRooms = unitRes?.data?.room;
      let tempTabs = [];
      unitRooms.map((e) => {
        if (e?.quantity > 1) {
          new Array(e?.quantity).fill("").map((_, key) => {
            tempTabs.push({
              title: `${e?.roomType?.label} (${key + 1})`,
              pathName: `roomSerialNumber=${key + 1}&roomID=${e?._id}`,
            });
          });
        } else {
          tempTabs.push({
            title: e?.roomType?.label,
            pathName: `roomSerialNumber=${1}&roomID=${e?._id}`,
          });
        }
      });
      setTabs(tempTabs);
    } catch (err) {
      setTabs([]);
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
    try {
      setLoading(true);
      const inventoryRes = await getApi(ApiUrl.inventory.get_activeInventory, {
        unit,
        property,
      });
      setUnitInventories(inventoryRes?.data);
    } catch (err) {
      setUnitInventories(undefined);
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };

  const getSelectedRoomValues = () => {
    const itemsValues: MaintenanceItemInterface[] = [...values?.items];
    let selectedInputValues;
    if (!activeTab) {
      selectedInputValues = itemsValues?.reduce((acc, current, index) => {
        if (current?.isGeneral) {
          acc?.push({
            index,
            values: current,
          });
        }
        return acc;
      }, []);
    } else {
      selectedInputValues = itemsValues?.reduce((acc, current, index) => {
        if (
          current?.roomID === roomID &&
          parseInt(current?.roomSerialNumber) === parseInt(roomSerialNumber) - 1
        ) {
          acc?.push({
            index,
            values: current,
          });
        }
        return acc;
      }, []);
    }

    return selectedInputValues;
  };
  const getInventoryItemDetails = (itemId) => {
    return unitInventories?.items?.find((e) => e?._id === itemId);
  };
  const selectedRoomValues = getSelectedRoomValues();
  useEffect(() => {
    (async () => {
      if (!values?.unit) {
        return;
      }
      if (!itemID) {
        setFieldValue("items", []);
      }
      await getUnitRoomsAndInventoryHandler(values?.unit, values?.property);
    })();
  }, [values?.unit, values?.property, itemID]);
  useEffect(() => {
    getUnitOptionsHandler(values?.property);
  }, [values?.property]);
  const addItemPayload = MaintenanceFormikValues.ItemValues({
    isGeneral: activeTab ? false : true,
    inventoryID: unitInventories?._id,
    roomID: roomID,
    roomSerialNumber: activeTab && `${parseInt(roomSerialNumber) - 1}`,
  });
  useEffect(() => {
    if (unitID && propertyID && itemID) {
      setFieldValue("property", propertyID);
      setFieldValue("unit", unitID);
      setFieldValue("items", [
        {
          ...addItemPayload,
          inventoryItemID: itemID,
          roomID: roomID,
        },
      ]);
    }
  }, [unitID, propertyID, itemID]);
  return (
    <div className={"flex flex-col gap-10 overflow-x-scroll hideScroll"}>
      <div
        className={`grid grid-cols-4 md:grid-cols-3 sm:grid-cols-1   gap-5 items-start`}
      >
        <MySelectField
          name={"property"}
          isClearable
          label={"Property"}
          isRequired
          disabled={itemID ? true : false}
          option={{
            selectOptions: decodePropertyOptionsHandler(propertyOptions),
            onChangeCallback(currentValue?: any) {
              setFieldValue("unit", "", false);
            },
          }}
        />
        <MySelectField
          disabled={!values?.property || (itemID ? true : false)}
          name={"unit"}
          label={"Unit"}
          isClearable
          isRequired
          option={{
            selectOptions: decodeUnitOptionsHandler(unitOptions),
          }}
        />
        <MySelectField
          name={"supplier"}
          label={"Assign To"}
          isClearable
          option={{
            selectOptions: decodeUserOptionsHandler(userOptions),
          }}
        />
      </div>
      {!isLoading && values?.unit && (
        <>
          <TabComponent
            tabs={[
              {
                title: "General Information",
                pathName: "",
              },
              ...tabs,
            ]}
          />
          <FieldArray
            name={"items"}
            render={({ push, remove, insert }) => {
              return (
                <div className={"flex flex-col gap-16"}>
                  {selectedRoomValues?.map((e, key) => {
                    return (
                      <ItemForm
                        itemDetails={getInventoryItemDetails(
                          e?.values?.inventoryItemID
                        )}
                        addItemHandler={(index) => {
                          insert(index + 1, addItemPayload);
                        }}
                        key={key}
                        removeHandler={(index) => remove(index)}
                        index={e?.index}
                        parentName={"items"}
                        hideInventoryItemOptions={
                          itemID === values?.items?.[e?.index]?.inventoryItemID
                        }
                        activeTab={activeTab}
                        roomID={roomID}
                        roomSerialNumber={roomSerialNumber}
                        unitInventories={unitInventories}
                      />
                    );
                  })}
                  {values?.unit && !selectedRoomValues?.length && (
                    <div>
                      <MyButton
                        isOutline
                        name={"Add Item"}
                        onClick={() => {
                          push(addItemPayload);
                        }}
                        iconType={IconTypeEnum.ADD}
                      />
                    </div>
                  )}
                </div>
              );
            }}
          />

          {/*  active tab*/}
          {!activeTab && (
            <FormWrapper
              header={{
                title: {
                  name: "Notifications",
                },
              }}
            >
              <div
                className={
                  "grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5"
                }
              >
                <MyRadioInput
                  name={"notifyTenant"}
                  label={"Notify the tenant"}
                />
                <MyRadioInput
                  name={"notifySupplier"}
                  label={"Notify the contractor"}
                />
              </div>
            </FormWrapper>
          )}
        </>
      )}
    </div>
  );
}

const ItemForm = ({
  parentName,
  index,
  removeHandler,
  itemDetails,
  hideInventoryItemOptions,
  addItemHandler,
  unitInventories,
  activeTab,
  roomID,
  roomSerialNumber,
}) => {
  const { fieldTypeOptions } = useContext(OptionsContext);
  const { values } = useFormikContext<any>();
  const details: InventoryItemInterface = itemDetails;
  const getInventoryItemsOptions = () => {
    // data that are not selected
    const inventoryItems: InventoryItemInterface[] =
      unitInventories?.items?.reduce((acc, current) => {
        const valuesItems: any = values?.items;
        const isUsed = valuesItems?.some(
          (e) =>
            e?.inventoryItemID === current?._id &&
            details?._id !== e?.inventoryItemID
        );

        if (!isUsed) {
          acc?.push(current);
        }
        return acc;
      }, []);

    let options;
    if (!activeTab) {
      options = inventoryItems?.reduce((acc, current) => {
        if (current?.isGeneral) {
          acc?.push({
            label: current?.item?.label,
            value: current?._id,
          });
        }
        return acc;
      }, []);
    } else {
      options = inventoryItems?.reduce((acc, current) => {
        if (
          current?.roomID === roomID &&
          current?.roomSerialNumber === parseInt(roomSerialNumber) - 1
        ) {
          acc?.push({
            label: current?.item?.label,
            value: current?._id,
          });
        }
        return acc;
      }, []);
    }

    return options;
  };
  const inventoryItemOptions = getInventoryItemsOptions();
  return (
    <div className={"flex flex-col gap-5"}>
      <div className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1 gap-5"}>
        {!hideInventoryItemOptions && (
          <MySelectField
            isRequired
            isClearable
            label={"Items"}
            option={{
              selectOptions: inventoryItemOptions,
              defaultLabel: "Select Item",
            }}
            name={`${parentName}.${index}.inventoryItemID`}
          />
        )}

        {itemDetails && (
          <>
            {hideInventoryItemOptions && (
              <NameValueText
                name={"Inventory Item"}
                valueStyle={"mt-3"}
                value={details?.item?.label}
              />
            )}
            <NameValueText
              name={"Quantity"}
              valueStyle={"mt-3"}
              value={details?.unitQuantity}
            />
            <NameValueText
              name={"Unit"}
              valueStyle={"mt-3"}
              value={details?.unit?.label}
            />

            <NameValueText
              name={"Condition"}
              valueStyle={"mt-3"}
              value={details?.inventoryItemCondition?.label}
            />
            <div className={"col-span-2"}>
              <NameValueText name={"Notes"} value={details?.notes} />
            </div>
          </>
        )}
      </div>
      {itemDetails && (
        <>
          {details?.images?.length > 0 && (
            <div className={"input_container"}>
              <NameValueText name={"Inventory Images"} value={""} />
              <div className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-2"}>
                {details?.images?.map((e, key) => {
                  return <FileViewCard key={key} filePath={e} canDownload />;
                })}
              </div>
            </div>
          )}

          <div
            className={
              "grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2 items-start gap-5"
            }
          >
            <FastField
              name={`${parentName}.${index}.repairPriority`}
              component={(props) => {
                return (
                  <MySelectField
                    {...props}
                    label={"Priority"}
                    isClearable
                    option={{
                      selectOptions: decodeDynamicFieldHandler(
                        fieldTypeOptions[FieldTypeEnum.REPAIR_PRIORITY]
                      ),
                      defaultLabel: "Select Priority",
                    }}
                    isRequired
                    name={`${parentName}.${index}.repairPriority`}
                  />
                );
              }}
            />
            <FastField
              name={`${parentName}.${index}.repairStatus`}
              component={(props) => {
                return (
                  <MySelectField
                    {...props}
                    isClearable
                    label={"Status"}
                    option={{
                      selectOptions: decodeDynamicFieldHandler(
                        fieldTypeOptions[FieldTypeEnum.REPAIR_STATUS]
                      ),
                      defaultLabel: "Select Status",
                    }}
                    isRequired
                    name={`${parentName}.${index}.repairStatus`}
                  />
                );
              }}
            />
            <div className={"col-span-2"}>
              <MyFastTextField
                isRequired
                label={"Description"}
                name={`${parentName}.${index}.description`}
                placeholder={"Enter description"}
              />
            </div>
          </div>
          <div
            className={
              "grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2 items-start gap-4"
            }
          >
            <MyUploadDocument
              label={"Images"}
              fileType={"image"}
              fileContainerStyle={"grid grid-cols-1"}
              name={`${parentName}.${index}.documents`}
            />
          </div>
        </>
      )}

      <div
        className={
          "grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2 items-start gap-5"
        }
      >
        {itemDetails && (
          <>
            <MyFastTextField
              isRequired
              type={"number"}
              max={details?.unitQuantity}
              label={"Unit to Repair"}
              name={`${parentName}.${index}.unitToRepair`}
              placeholder={"Eg. 5"}
            />
            <MyTextField
              label={"Target Date"}
              isRequired
              name={`${parentName}.${index}.targetDate`}
              type={"date"}
            />
          </>
        )}

        <div
          className={
            "md:col-span-2 flex items-center gap-5 mt-8 md:mt-0 sm:mt-0"
          }
        >
          <MyButton
            isOutline
            onClick={() => addItemHandler(index)}
            colorType={"primary"}
            name={"Add Items"}
            iconType={IconTypeEnum.ADD}
          />
          <MyButton
            isOutline
            onClick={() => removeHandler(index)}
            colorType={"danger"}
            name={"Delete Item"}
            iconType={IconTypeEnum.DELETE}
          />
        </div>
      </div>
    </div>
  );
};

export default MaintenanceForm;
