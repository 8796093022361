import React from "react";

import { MyIconButton } from "components";

import Skeleton from "react-loading-skeleton";
import { IconTypeEnum } from "interfaces";
import { PropertyUnitTableText } from "assets";

import { UnitTitleDetailsInterface } from "./interfaces";
import { getPropertyNameHandler } from "../../../helpers";

function UnitTitleDetails({ details, isLoading }: UnitTitleDetailsInterface) {
  return (
    <div
      className={"flex items-center gap-4 bg-tGray rounded-md p-4 text-[18px]"}
    >
      <MyIconButton
        iconSize={20}
        iconColor={"fill-primary"}
        IconType={IconTypeEnum.UNIT}
      />
      <div className={"flex items-center gap-10 text-[16px]"}>
        {isLoading ? (
          <>
            <Skeleton width={100} />
            <Skeleton width={100} />
          </>
        ) : (
          <>
            <div className={"flex items-center gap-2"}>
              <span className={"font-bold"}>Property :</span>
              <span className={"font-normal text-[14px]"}>
                {`(${details?.property?.propertyID}) ${getPropertyNameHandler(
                  details?.property?.address
                )}`}
              </span>
            </div>
            <div className={"flex items-center gap-2 "}>
              <span className={"font-bold"}> Unit :</span>

              <span className={"font-normal text-[14px]"}>
                ({details?.unitID}) {details?.name}
              </span>
            </div>
            <div className={" ml-10 "}>
              <span className={"font-bold"}>Size : </span>
              <span className={"font-normal text-[14px]"}>
                {details?.area?.size} Sq. {details?.area?.unit?.label}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default UnitTitleDetails;
