import React, { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { PageLinks } from "routes";
import { PageTemplate } from "templates";
import { FieldTypeEnum } from "interfaces";
import { usePageQueryHooks } from "useHooks";
import { FormConcludeButtons } from "assets";
import { OptionsContext, TaskContext } from "context";

import { TaskFormikValues } from "../helpers";
import { TaskForm } from "../components";
import { Loader } from "components";

function NewTaskPage() {
  const navigate = useNavigate();
  const [enableReinitialize, setEnableReinitialize] = useState(true);
  const {
    getPropertyOptionsHandler,
    getOptionsHandler,
    getUserOptionsHandler,
  } = useContext(OptionsContext);

  const { returnURL } = usePageQueryHooks();

  const { editDetailsHandler } = useContext(TaskContext);
  useEffect(() => {
    (async () => {
      setEnableReinitialize(true);
      await Promise.all([
        getPropertyOptionsHandler(),
        getUserOptionsHandler(),
        getOptionsHandler(FieldTypeEnum.TASK_PRIORITY),
        getOptionsHandler(FieldTypeEnum.TASK_STATUS),
      ]);

      setEnableReinitialize(false);
    })();
  }, []);
  const submitHandler = async (values, formikHelpers) => {
    let payload = { ...values };
    if (payload?.isRepairAndMaintenanceTask) {
      delete payload["chargeAmount"];
      delete payload["targetDate"];
      delete payload["taskPriority"];
      delete payload["taskStatus"];
    } else {
      delete payload["items"];
    }
    await editDetailsHandler(payload, formikHelpers, {
      onSuccess: async (payload) => {
        returnURL ? navigate(returnURL) : navigate(PageLinks.task.list);
      },
    });
  };
  return (
    <PageTemplate
      title={"Task"}
      scrollAll
      breadCrumb={[
        {
          name: "Task",
        },
        {
          name: `New Task`,
        },
      ]}
      backNavigation={{
        title: `New Task`,
        path: PageLinks.task.list,
      }}
    >
      <Formik
        enableReinitialize={enableReinitialize}
        initialValues={TaskFormikValues?.initialValues()}
        validationSchema={TaskFormikValues?.validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={"flex flex-col gap-10"}>
              <TaskForm isLoading={enableReinitialize} />
              {enableReinitialize && <Loader />}
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                  handler: () => {
                    navigate(-1);
                  },
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default NewTaskPage;
