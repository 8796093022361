import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MyButton, TableCardComponent } from "components";
import { PageTemplate } from "templates";
import { IconTypeEnum, SpaceAccessListEnum } from "interfaces";

import { UnitDataContext } from "context";
import { TableFilterCard, UnitTableColumn } from "content";
import {
  useAuthorizationHook,
  usePageQueryHooks,
  usePaginationQueryHooks,
} from "useHooks";
import { PageLinks } from "routes";

function UnitPage() {
  const {
    allData,
    getAllDataHandlers,
    isLoading,
    totalArchiveDocs,
    totalDocs,
  } = useContext(UnitDataContext);
  const navigate = useNavigate();
  const { status, unitID, propertyID } = usePageQueryHooks();
  const { isArchive } = usePaginationQueryHooks();
  const query = {
    occupancy: status,
    unit: unitID,
    property: propertyID,
  };
  useEffect(() => {
    getAllDataHandlers(query);
  }, [isArchive, status, unitID, propertyID]);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAdd = checkCanAccessHandler([SpaceAccessListEnum.RENTAL_ADD_UNIT]);
  const tableCardProps = {
    column: UnitTableColumn(true, query),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      leftChildren: <TableFilterCard haveUnit haveProperty />,
      rightChildren: canAdd && (
        <MyButton
          iconType={IconTypeEnum.ADD}
          onClick={() => {
            navigate(PageLinks.unit.new);
          }}
          name={"Add Unit"}
        />
      ),
    },
  };
  return (
    <PageTemplate
      scrollAll
      title={"Rental"}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
      breadCrumb={[
        {
          name: "Units",
          path: PageLinks.unit.list,
        },
      ]}
    >
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default UnitPage;
