import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PackagePurchaseType,
  PackageTimeType,
  QueryStringEnum,
  SubscriptionPackageInterface,
} from "interfaces";
import { Api } from "helpers";
import { AppContext } from "context";
import { ApiUrl } from "services";
import { PageTemplate } from "templates";
import { PageLinks } from "routes";
import { PackageCard } from "./components";
import { MdToggleOff, MdToggleOn } from "react-icons/md";

function Index() {
  const [subscriptionPackages, setSubscriptionPackages] = useState<
    SubscriptionPackageInterface[]
  >([]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const { getApi } = Api();
  const { handlers } = useContext(AppContext);
  const [isAnnual, setIsAnnual] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await getApi(
          ApiUrl.subscription.get_purchasableSubscriptionPackage
        );
        setSubscriptionPackages(res?.data?.docs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <PageTemplate scrollAll>
      <div className={"flex flex-col gap-10"}>
        <div className={"flex flex-col items-center gap-5"}>
          <b className={"text-[28px] font-bold"}>Simple, affordable pricing!</b>
          <span className={"text-gray-500 text-[14px]"}>
            Start building for free and pay as you grow.
          </span>
          <div className={"flex items-center gap-5 text-[14px]"}>
            <b>Bill Monthly</b>
            <div
              className={"cursor-pointer"}
              onClick={() => setIsAnnual((e) => !e)}
            >
              {isAnnual ? (
                <MdToggleOn className={"text-[50px] text-primary"} />
              ) : (
                <MdToggleOff className={"text-[50px] text-primary"} />
              )}
            </div>

            <b>Bill Annually</b>
          </div>
        </div>
        <div>
          {isLoading && (
            <div className={"text-center text-[20px] text-gray-500"}>
              Please wait...
            </div>
          )}
          <div className={"grid grid-cols-3 gap-10 px-5"}>
            {subscriptionPackages?.map((e, key) => {
              return (
                <PackageCard
                  packageDetails={e}
                  key={key}
                  costType={isAnnual ? "annual" : "monthly"}
                  onClickHandler={(id) => {
                    navigate({
                      pathname: PageLinks.subscription.checkout(
                        PackagePurchaseType.SUBSCRIPTION
                      ),
                      search: `${QueryStringEnum.ID}=${id}&${
                        QueryStringEnum.TYPE
                      }=${
                        isAnnual
                          ? PackageTimeType.ANNUALLY
                          : PackageTimeType.MONTHLY
                      }`,
                    });
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

export default Index;
