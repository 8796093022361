import React, { useContext, useEffect } from "react";
import { ListingTableColumns } from "../components/tableColumns";
import { MyButton, TableCardComponent } from "components";
import { UnitDataContext } from "context";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconTypeEnum,
  ParamsStringEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { useAuthorizationHook, usePaginationQueryHooks } from "useHooks";
import { LeftRightHeader } from "../../../assets";

function UnitListingTab() {
  const params = useParams<ParamsStringEnum>();
  const unitId = params?.unitId;
  const { getListingHandler, isLoading, listings, totalListsDocs } =
    useContext(UnitDataContext);
  const { currentPage } = usePaginationQueryHooks();

  useEffect(() => {
    getListingHandler(unitId);
  }, [unitId, currentPage]);
  const location = useLocation();
  const navigate = useNavigate();
  const { checkCanAccessHandler } = useAuthorizationHook();

  const canAdd = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_ADD_UNIT,
    SpaceAccessListEnum.RENTAL_EDIT_UNIT,
  ]);
  const tableCardProps = {
    column: ListingTableColumns(),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: listings,
  };
  return (
    <div className={"flex flex-col gap-5"}>
      <LeftRightHeader
        title={"Listing/List"}
        rightChildren={
          canAdd && (
            <MyButton
              iconType={IconTypeEnum.ADD}
              onClick={() => {
                navigate({
                  pathname: location?.pathname + "/modal/listing/edit",
                });
              }}
              name={"Add Listing"}
            />
          )
        }
      />
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: totalListsDocs,
        }}
      />
      <Outlet />
    </div>
  );
}

export default UnitListingTab;
