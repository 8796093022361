import React, { useContext, useEffect } from "react";
import { FileViewCard, MyButton } from "components";
import { AddressText, FormWrapper, NameValueText } from "assets";

import { PageTemplate } from "templates";
import { useNavigate, useParams } from "react-router-dom";
import { DocumentContext } from "context";
import { IconTypeEnum, ParamsStringEnum } from "interfaces";
import { useDateHook } from "useHooks";
import { PageLinks } from "routes";

function MeterReadingDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const { details, isDetailsLoading, getDetailsHandler } =
    useContext(DocumentContext);
  const { getDateHandler, getStaffFullName } = useDateHook();

  const navigate = useNavigate();
  useEffect(() => {
    getDetailsHandler(params?.id);
  }, [params?.id]);

  return (
    <PageTemplate
      scrollAll
      title={"Documents "}
      backNavigation={{
        title: "My Documents",
      }}
      titleRightChildren={
        <div>
          {details?._id && (
            <MyButton
              isOutline
              iconType={IconTypeEnum.EDIT}
              name={"Edit Details"}
              onClick={() => {
                navigate({
                  pathname: PageLinks.documents.edit(details?._id),
                });
              }}
            />
          )}
        </div>
      }
    >
      <div className={"flex flex-col gap-10"}>
        <div
          className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1  gap-5"}
        >
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"Name"}
            value={details?.name}
          />
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"Type"}
            value={details?.documentType?.label}
          />
        </div>
        <div
          className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1  gap-5"}
        >
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"Property"}
            value={
              <div className={"flex gap-2 items-center"}>
                ({details?.property?.propertyID})
                <AddressText isOneLine address={details?.property?.address} />
              </div>
            }
          />
          <NameValueText
            alignDirection={"col"}
            name={"Unit"}
            isLoading={isDetailsLoading}
            value={
              <span>
                ({details?.unit?.unitID}) {details?.unit?.name}
              </span>
            }
          />
        </div>
        <div
          className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1  gap-5"}
        >
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Description"}
            value={details?.description || "-"}
          />
        </div>{" "}
        <div
          className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1  gap-5"}
        >
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Date of Validation"}
            value={getDateHandler({
              date: details?.dateOfValidation,
            })}
          />
        </div>
        <div className={"grid "}>
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Share With"}
            value={details?.sharedTo
              ?.map((e) => {
                return `(${e?.userID || "-"})-${getStaffFullName(e?.name)}`;
              })
              ?.join(", ")}
          />
        </div>
        {details?.documents?.length > 0 && (
          <FormWrapper
            header={{
              title: {
                name: "Documents",
              },
            }}
          >
            <div
              className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5"}
            >
              {details?.documents?.map((e, key) => {
                return <FileViewCard canDownload filePath={e} key={key} />;
              })}
            </div>
          </FormWrapper>
        )}
      </div>
    </PageTemplate>
  );
}

export default MeterReadingDetailsPage;
