import React, { useContext, useEffect } from "react";
import { FormWrapper, NameValueText } from "assets";

import { PageTemplate } from "templates";
import { useParams } from "react-router-dom";
import { MessageContext } from "context";
import { ParamsStringEnum } from "interfaces";
import { PageLinks } from "routes";
import { FileViewCard } from "components";

function MessageDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const {
    details: messageDetails,
    isDetailsLoading,
    getDetailsHandler,
  } = useContext(MessageContext);
  useEffect(() => {
    getDetailsHandler(params?.id);
  }, [params?.id]);

  return (
    <PageTemplate
      title={"Messages"}
      scrollAll
      backNavigation={{
        title: "Message",
        path: PageLinks.message.list,
      }}
    >
      <div className={"flex flex-col gap-10"}>
        <NameValueText
          isLoading={isDetailsLoading}
          alignDirection={"col"}
          name={"Recipient"}
          value={messageDetails?.recipients
            ?.map((e) => {
              return `${e?.email}`;
            })
            .join(", ")}
        />
        <NameValueText
          isLoading={isDetailsLoading}
          alignDirection={"col"}
          name={"Subject"}
          value={messageDetails?.subject}
        />
        <NameValueText
          isLoading={isDetailsLoading}
          alignDirection={"col"}
          name={"Message"}
          value={messageDetails?.message}
        />
        <FormWrapper header={{ title: { name: "Documents" } }}>
          <div
            className={"grid grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-5"}
          >
            {messageDetails?.documents?.map((e, key) => {
              return <FileViewCard canDownload filePath={e} key={key} />;
            })}
          </div>
        </FormWrapper>
      </div>
    </PageTemplate>
  );
}

export default MessageDetailsPage;
