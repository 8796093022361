import React, { useContext } from "react";

import {
  InventoryInterface,
  SpaceAccessListEnum,
  TableColumnInterface,
} from "interfaces";
import { MyMoreOptionButton } from "components";

import { InventoryDataContext } from "context";
import {
  useAuthorizationHook,
  useDateHook,
  usePageQueryHooks,
  usePaginationQueryHooks,
} from "useHooks";
import { PropertyUnitTableText, UserTableProfile } from "assets";
import { PageLinks } from "routes";

function InventoryTableColumns(
  query?: any
): TableColumnInterface<InventoryInterface>[] {
  const { getDateHandler, getStaffFullName } = useDateHook();
  const { archiveHandler, getAllDataHandlers, deleteHandler } =
    useContext(InventoryDataContext);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { calcSNHandler } = usePaginationQueryHooks();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.INVENTORY_EDIT_INVENTORY,
  ]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.INVENTORY_DEL_INVENTORY,
  ]);
  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <span>{calcSNHandler(key)}</span>;
      },
    },
    {
      title: "Property",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.property?.propertyID}
            address={renderData?.property?.address}
            propertyID={renderData?.property?._id}
          />
        );
      },
    },
    {
      title: "Unit",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.unit?.unitID}
            type={"unit"}
            name={renderData?.unit?.name}
            propertyID={renderData?.property?._id}
            unitID={renderData?.unit?._id}
          />
        );
      },
    },
    {
      title: "Tenant",
      render(renderData) {
        return (
          <UserTableProfile
            details={{
              subTitle: getStaffFullName(renderData?.tenant?.name),
              profile: renderData?.tenant?.photo,
              name: renderData?.tenant?.userID,
            }}
          />
        );
      },
    },
    {
      title: `Inventory Status`,
      render(renderData) {
        return <span>{renderData?.inventoryStatus?.label}</span>;
      },
    },

    {
      title: `Inspection Date`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>{getDateHandler({ date: renderData?.inspectionDate })}</span>
          </div>
        );
      },
    },
    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigateWithReturnUrlHandler(
                      PageLinks.inventory.details(renderData?._id)
                    );
                  },
                },
                canEdit &&
                  !renderData?.isArchived && {
                    name: "Edit",
                    handler: () => {
                      navigateWithReturnUrlHandler(
                        PageLinks.inventory.edit(renderData?._id)
                      );
                    },
                  },
                canDelete && {
                  name: renderData?.isArchived ? `Unarchive` : "Archive",
                  handler: () => {
                    archiveHandler(renderData?._id, renderData?.isArchived, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
                canDelete && {
                  name: "Delete",
                  handler: () => {
                    deleteHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default InventoryTableColumns;
