import React from "react";
import { Route } from "react-router-dom";
import { NotificationPage, NotificationDetailsPage } from "./pages";
import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="/notification">
      <Route
        path={""}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.CONFIGURATION_MANAGE_NOTIFICATION]}
            isForPage={true}
          >
            <NotificationPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`details/:${ParamsStringEnum.ID}/*`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.CONFIGURATION_MANAGE_NOTIFICATION]}
            isForPage={true}
          >
            <NotificationDetailsPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
