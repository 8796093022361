import React, { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  TableColumnInterface,
  QueryStringEnum,
  MessageInterface,
} from "interfaces";
import { MyMoreOptionButton } from "components";

import { MessageContext } from "context";
import { useDateHook } from "useHooks";
import { usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";
import { CollapseUserImage } from "../../../../assets";

function MessageTableColumns(
  pageQuery
): TableColumnInterface<MessageInterface>[] {
  const { deleteHandler, archiveHandler, getAllDataHandlers } =
    useContext(MessageContext);
  const [query] = useSearchParams();
  const messageStatus = query.get(QueryStringEnum?.MESSAGE_STATUS);
  const { calcSNHandler } = usePaginationQueryHooks();
  const navigate = useNavigate();
  const { getDateHandler, getStaffFullName } = useDateHook();

  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <span>{calcSNHandler(key)}</span>;
      },
    },
    {
      title: "Subject",
      render(renderData, key: number) {
        return <span>{renderData?.subject}</span>;
      },
    },
    {
      title: `${messageStatus === "SENT" ? "To" : "From"}`,
      render: function (renderData) {
        const isSent = messageStatus === "SENT";
        if (isSent) {
          return <CollapseUserImage users={renderData?.recipients} />;
        }
        return (
          <div className={"flex flex-col gap-1"}>
            <span className={" font-semibold"}>
              {getStaffFullName(renderData?.sender?.name)}
            </span>
            <span className={"text-gray-500"}>{renderData?.sender?.email}</span>
          </div>
        );
      },
    },
    {
      title: "Date",
      render(renderData, key: number) {
        return (
          <div>
            <span>{getDateHandler({ date: renderData?.createdAt })}</span>
          </div>
        );
      },
    },
    {
      title: "Action",
      render(renderData, key: number) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigate(PageLinks.message.details(renderData?._id));
                  },
                },
                {
                  name: "Delete ",
                  handler: () => {
                    deleteHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(pageQuery);
                      },
                    });
                  },
                },

                {
                  name: renderData?.isArchived ? `Unarchive` : "Archive",
                  handler: () => {
                    archiveHandler(renderData?._id, renderData?.isArchived, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(pageQuery);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default MessageTableColumns;
