import { AiFillCaretDown } from "react-icons/ai";
import Popup from "reactjs-popup";

import { MyStatusSelectButtonInterface } from "./interfaces";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { getIconHelper } from "../../helpers";
import { IconTypeEnum } from "../../interfaces";

export default function MyStatusSelectButton({
  options,
  selectedOption,
  onSelectHandler,
  title,
  initialFetchHandler,
}: MyStatusSelectButtonInterface) {
  const [isLoading, setLoading] = useState(false);
  const DownIcon = getIconHelper(IconTypeEnum.DOWN);
  useEffect(() => {
    (async () => {
      setLoading(true);
      await initialFetchHandler();
      setLoading(false);
    })();
  }, []);
  return (
    <Popup
      position="bottom right"
      closeOnDocumentClick
      contentStyle={{ width: "200px" }}
      trigger={
        <button className="button">
          <div
            className={`flex items-center gap-2 px-3 py-1 rounded-full  ${selectedOption?.textColor} ${selectedOption?.bgColor}`}
          >
            <span className={"text-[14px]"}>{selectedOption?.name}</span>
            <DownIcon fontSize={18} />
          </div>
        </button>
      }
    >
      <div className={"flex flex-col gap-2 p-2"}>
        <span className={"text-[14px] font-semibold text-center"}>{title}</span>
        <div className={"flex flex-col gap-2"}>
          {isLoading
            ? new Array(3).fill("").map((e, key) => {
                return (
                  <Skeleton
                    key={key}
                    height={30}
                    className={"rounded-full p-2"}
                  />
                );
              })
            : options?.map((e, key) => {
                return (
                  <div
                    onClick={() => onSelectHandler(e?.value)}
                    className={`cursor-pointer items-center  text-center gap-2 px-2 py-1 rounded-full   ${e?.textColor} ${e?.bgColor}`}
                    key={key}
                  >
                    <span className={"text-[14px]"}>{e?.label}</span>
                  </div>
                );
              })}
        </div>
      </div>
    </Popup>
  );
}
