import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MyButton, TableCardComponent } from "components";
import { PageTemplate } from "templates";
import { IconTypeEnum } from "interfaces";
import { TableFilterCard } from "content";
import { JournalContext, ReportContext } from "context";
import { PageLinks } from "routes";

import { JournalTableColumns } from "../../components";
import { usePageQueryHooks, usePaginationQueryHooks } from "useHooks";

function JournalPage() {
  const {
    allData,
    getAllDataHandlers,
    totalDocs,
    totalArchiveDocs,
    isLoading,
  } = useContext(JournalContext);
  const { generateJournalReportHandler } = useContext(ReportContext);
  const navigate = useNavigate();
  const { isArchive, currentPage } = usePaginationQueryHooks();
  const { startFrom, endTo } = usePageQueryHooks();
  const pageQuery = {
    startDate: startFrom,
    endDate: endTo,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, currentPage, startFrom, endTo]);

  const tableCardProps = {
    column: JournalTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      leftChildren: <TableFilterCard haveDateRange />,
      rightChildren: (
        <div className={"flex items-center gap-2"}>
          <MyButton
            iconType={IconTypeEnum.ADD}
            name={"Add Journal"}
            onClick={() => {
              navigate(PageLinks.journal.new);
            }}
          />
        </div>
      ),
    },
  };
  return (
    <PageTemplate
      title={"Accounting"}
      breadCrumb={[
        {
          name: "Journal",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        exportXlsx={{
          generateHandler() {
            generateJournalReportHandler();
          },
        }}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default JournalPage;
