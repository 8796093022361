import { UserTimestampSchemaInterface } from "../generic.interface";
import { CustomerInterface, ModuleListEnum } from "../user";

export enum NotificationTitleEnum {
  NONE = "NONE",
  LATE_FEES = "LATE_FEES",
  ADVANCE_OPTION = "ADVANCE_OPTION",
  SALES_INVOICE = "SALES_INVOICE",
  PURCHASE_INVOICE = "PURCHASE_INVOICE",
  SALES_RETURN_INVOICE = "SALES_RETURN_INVOICE",
  PURCHASE_RETURN_INVOICE = "PURCHASE_RETURN_INVOICE",
  BOOKINGS = "BOOKINGS",
  TASK = "TASK",
  MAINTENANCE = "MAINTENANCE",
  LOANS_MORTGAGE = "LOANS_MORTGAGE",
  TENANCY = "TENANCY",
  NOTES = "NOTES",
  MESSAGE = "MESSAGE",
}

export interface CustomDaysNotificationInterface {
  isBefore?: boolean;
  day?: number;
}
export enum NotificationCategory {
  SYSTEM = "SYSTEM",
  GENERAL = "GENERAL",
  REMINDER = "REMINDER",
}
export enum NotificationToEnum {
  "LANDLORD" = "LANDLORD",
  "LENDER" = "LENDER",
  "OWNER" = "OWNER",
  "SUPPLIER" = "SUPPLIER",
  "TENANT" = "TENANT",
  "PARTICIPANT" = "PARTICIPANT",
}

export interface NotificationSettingsInterface
  extends UserTimestampSchemaInterface {
  _id?: string;
  module?: string;
  title?: NotificationTitleEnum;
  isCustom?: boolean;
  customDays?: CustomDaysNotificationInterface[];
  notificationTo?: NotificationToEnum[];
  allowedNotificationTo?: NotificationToEnum[];
  description?: string;
  enableEmailNotification?: boolean;
  enablePushNotification?: boolean;
  enableSmsNotification?: boolean;
}

export interface NotificationInterface extends UserTimestampSchemaInterface {
  _id: string;
  campaignName: string;
  forAll: boolean;
  isRead: boolean;
  customer: CustomerInterface[];
  module: ModuleListEnum;
  title: string;
  body: string;
  link: string;
  photo: string;
  category: NotificationCategory; // system, general, reminder
  // system message are direct, reminder are scheduled
  deletedBy: CustomerInterface["_id"][];
  archivedBy: CustomerInterface["_id"][];
  sendEmail: boolean;
  saveInApp: boolean;
  isArchived: boolean;
  sendPushNotification: boolean;
  emailListATM: string[];
  email?: { subject?: string; body?: string };
}
