import React from "react";
import { ToggleContentWrapper } from "../components";
import { DashboardApiTypeEnum, ChartResponseInterface } from "interfaces";
import { getCurrency, separateWithComma } from "helpers";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getGraphData, ChartOptions } from "../helpers";
import { getBalanceSheetTitleHandler } from "helpers";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
function PurchaseStatsContent({ isToggle }) {
  return (
    <ToggleContentWrapper
      toggle={isToggle}
      title={"Purchase Amount"}
      getDataType={DashboardApiTypeEnum.purchaseChart}
      haveDateFilter={true}
      havePropertyFilter={false}
    >
      {(data: ChartResponseInterface, isLoading, error, dateFilter) => {
        const { accountDateTitle } = getBalanceSheetTitleHandler({
          endDate: dateFilter?.endDate,
          startDate: dateFilter?.startDate,
          rangeType: dateFilter?.type,
        });
        return (
          <div className={"pt-5"}>
            <div className={"flex flex-col gap-1"}>
              <span className={"text-[22px] font-bold"}>
                {getCurrency()}{separateWithComma(data?.total) || "-"}
              </span>
              <span className={"text-[14px]"}>
                Purchase amount {accountDateTitle}
              </span>
            </div>
            <div className={"mt-5"}>
              <Line
                options={{
                  ...ChartOptions,
                  scales: {
                    y: {
                      title: {
                        display: true,
                        text: `Amount (${getCurrency()})`,
                        font: {
                          weight: "bold",
                        },
                      },
                    },
                    x: {
                      title: {
                        display: true,
                        text: "Date",
                        font: {
                          weight: "bold",
                        },
                      },
                    },
                  },
                }}
                data={getGraphData(data)}
              />
            </div>
          </div>
        );
      }}
    </ToggleContentWrapper>
  );
}

export default PurchaseStatsContent;
