import {
  NotificationSettingsInterface,
  SpaceSettingsInterface,
} from "interfaces";
import * as yup from "yup";
import { addressInitialValues } from "content";

export const ManageNotificationFormikValues = {
  initialValues: (values?: NotificationSettingsInterface) => {
    return {
      module: values?.module,
      title: values?.title,
      isCustom: values?.isCustom,
      customDays: values?.customDays || [],
      notificationTo: values?.notificationTo || [],
      description: values?.description,
      allowedNotificationTo: values?.allowedNotificationTo || [],
      enableEmailNotification: values?.enableEmailNotification || false,
      enableSmsNotification: values?.enableSmsNotification || false,
      enablePushNotification: values?.enablePushNotification || false,
    };
  },
  validationSchema: yup.object().shape({}),
};
export const SpaceSettingFormikValues = {
  initialValues: (values?: SpaceSettingsInterface) => {
    return {
      footerText: values?.footerText,
      showQrOnRecipient: values?.showQrOnRecipient,
      nameFormat: values?.nameFormat,
      timeFormat: values?.timeFormat,
      dateFormat: values?.dateFormat,
      fiscalYearStartMonth: values?.fiscalYearStartMonth,
      fiscalYearStartDay: values?.fiscalYearStartDay,
      showFutureInvoiceInDays: values?.showFutureInvoiceInDays,
      isCorporateAccount: values?.isCorporateAccount,
      companyName: values?.companyName,
      companyLogo: values?.companyLogo,
      companyPan: values?.companyPan,
      companyRegistration: values?.companyRegistration,
      contactDetail: values?.contactDetail,
      website: values?.website,
      companyEmail: values?.companyEmail,
      defaultBank: values?.defaultBank?._id,
      autoTrashDeleteDay: values?.autoTrashDeleteDay,
      brevo: {
        brevoKey: values?.brevo?.brevoKey,
        senderEmail: values?.brevo?.senderEmail,
        senderName: values?.brevo?.senderName,
      },
      invoicePrefix: {
        separator: values?.invoicePrefix?.separator,
        SALES: values?.invoicePrefix?.SALES,
        SALES_RETURN: values?.invoicePrefix?.SALES_RETURN,
        PURCHASE: values?.invoicePrefix?.PURCHASE,
        PURCHASE_RETURN: values?.invoicePrefix?.PURCHASE_RETURN,
      },
      ...addressInitialValues(values?.companyAddress),
    };
  },
  validationSchema: yup.object().shape({
    companyEmail: yup.string().email("Invalid"),
    brevo: yup.object().shape({
      senderEmail: yup.string().email("Invalid email"),
    }),
    autoTrashDeleteDay: yup
      .number()
      .min(0, "Invalid")
      .max(30, "Should be less than 30 days"),
  }),
};

export const monthsOptions = [
  { label: "January", value: 1, days: 31 },
  { label: "February", value: 2, days: 28 },
  { label: "March", value: 3, days: 31 },
  { label: "April", value: 4, days: 30 },
  { label: "May", value: 5, days: 31 },
  { label: "June", value: 6, days: 30 },
  { label: "July", value: 7, days: 31 },
  { label: "August", value: 8, days: 31 },
  { label: "September", value: 9, days: 30 },
  { label: "October", value: 10, days: 31 },
  { label: "November", value: 11, days: 30 },
  { label: "December", value: 12, days: 31 },
];

export const generateDaysOptionsForMonth = (monthIndex) => {
  if (monthIndex > monthsOptions.length) {
    return [];
  }
  let noDays = monthsOptions[monthIndex]?.days;
  return new Array(noDays).fill("-")?.map((e, index) => {
    return {
      label: `${index + 1}`,
      value: index + 1,
    };
  });
};
