export enum ContentTypeEnum {
  content = "content",
  subTotal = "subTotal",
  total = "total",
  title = "title",
}

export interface BalanceSheetIncomeStatementDataRowInterface {
  title: string;
  value: number;
  isLess: boolean;
  showBorder: boolean;
  firstCompareTimeValue: number;
  secondCompareTimeValue: number;
  percentageCompareValue: number;
  deviationCompareValue: number;
  account?: string;
}

export interface BalanceSheetIncomeStatementDataInterface {
  type: ContentTypeEnum;
  data:
    | BalanceSheetIncomeStatementDataRowInterface[]
    | BalanceSheetIncomeStatementDataRowInterface;
}
export interface BalanceSheetIncomeStatementRenderInterface {
  [Title: string]: BalanceSheetIncomeStatementDataInterface;
}
