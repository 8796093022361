import React, { useContext } from "react";
import { useFormikContext } from "formik";
import { EventInterface, FieldTypeEnum } from "interfaces";
import { OptionsContext } from "context";
import { FormWrapper } from "assets";
import {
  MyRadioInput,
  MySelectField,
  MyTextAreaInput,
  MyTextField,
} from "components";
import {
  decodeDynamicFieldHandler,
  decodePropertyOptionsHandler,
  decodeUserOptionsHandler,
} from "helpers";

function EventsForm() {
  const { values } = useFormikContext<EventInterface>();

  const { propertyOptions, userOptions, fieldTypeOptions } =
    useContext(OptionsContext);

  return (
    <div className={"flex flex-col gap-5"}>
      <FormWrapper header={{ title: { name: "Property" } }}>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <MySelectField
            label={"Property"}
            option={{
              selectOptions: decodePropertyOptionsHandler(propertyOptions),
              defaultLabel: "Select Property",
            }}
            name={"property"}
          />
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "Details" } }}>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <MyTextField
            label={"Title"}
            isRequired
            placeholder={"eg. New Event"}
            name={"name"}
          />
        </div>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <MyTextField
            label={"Start Date"}
            type={"datetime-local"}
            isRequired
            name={"startDate"}
          />
          <MyTextField
            label={"End Date"}
            type={"datetime-local"}
            isRequired
            name={"endDate"}
          />
        </div>
        <div className={"flex flex-col gap-2"}>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 "}
          >
            <b>Recurrence</b>
            <MyRadioInput
              radioButtonType={"switch"}
              label={"Create a recurring event"}
              name={"recurring.createRecurringEvent"}
            />
          </div>
          {values?.recurring?.createRecurringEvent && (
            <div className={"flex flex-col gap-5"}>
              <div
                className={
                  "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 "
                }
              >
                <MySelectField
                  isClearable
                  label={"Type"}
                  option={{
                    selectOptions: decodeDynamicFieldHandler(
                      fieldTypeOptions[FieldTypeEnum.YEARLY_FREQUENCY]
                    ),
                  }}
                  name={"recurring.recurringType"}
                />
              </div>
              <div
                className={
                  "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 "
                }
              >
                <div>
                  <MyRadioInput
                    name={"recurring.forever"}
                    label={"Never ends"}
                    defaultValue={false}
                  />
                </div>
                <div className={"flex flex-col gap-5"}>
                  <MyRadioInput
                    name={"recurring.forever"}
                    label={"End Date"}
                    defaultValue={true}
                  />
                  {values?.recurring?.forever && (
                    <MyTextField
                      label={"End Date"}
                      type={"date"}
                      name={"recurring.endDate"}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={"flex flex-col gap-2"}>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 "}
          >
            <b>Reminder</b>
            <MyRadioInput
              radioButtonType={"switch"}
              label={"Send a reminder of this event to the user."}
              name={"reminder.pushReminder"}
            />
          </div>
          {values?.reminder?.pushReminder && (
            <div className={"flex flex-col gap-5"}>
              <div
                className={
                  "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 "
                }
              >
                <MySelectField
                  isCreatable={{
                    type: FieldTypeEnum.REMINDER_BEFORE_AFTER,
                  }}
                  option={{
                    selectOptions: decodeDynamicFieldHandler(
                      fieldTypeOptions[FieldTypeEnum.REMINDER_BEFORE_AFTER]
                    ),
                  }}
                  label={"Type"}
                  name={"reminder.reminderBeforeAfterType"}
                />
                <MyTextField
                  label={"Before"}
                  isNumberOnly
                  isDigitOnly
                  placeholder={"eg. 1"}
                  name={"reminder.beforeAfterQuantity"}
                />
              </div>
            </div>
          )}
        </div>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <MyTextField
            placeholder={"eg. Gabahal, Lalitpur"}
            label={"Location"}
            name={"location"}
          />
        </div>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <div className={"col-span-2"}>
            <MyTextAreaInput label={"Description"} name={"description"} />
          </div>
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "Participants" } }}>
        <div
          className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1  gap-5"}
        >
          <div className={"col-span-2"}>
            <MySelectField
              label={"Participants"}
              option={{
                selectOptions: decodeUserOptionsHandler(userOptions),
              }}
              name={"participants"}
            />
          </div>
        </div>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <MyRadioInput
            label={"Notify participants"}
            name={"shouldNotifyParticipant"}
          />
        </div>{" "}
      </FormWrapper>
    </div>
  );
}

export default EventsForm;
