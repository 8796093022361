import React, { createContext, useContext, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { Api } from "helpers";
import { ApiUrl } from "services";
import { AppContext } from "context";
import { useConfirmHook } from "useHooks";

import { DataContextInterface, LoadingEnum } from "./interfaces";
import { ParamsEnum } from "../interfaces";
import { FieldTypeEnum } from "interfaces";

export const DataContext = createContext<DataContextInterface>({
  systemData: [],
  loading: {
    isFieldListsLoading: false,
  },
  getSystemTypeDataHandler(type, parentId) {},
  editSystemItemsHandler(values: any, props: any) {},
  deleteSystemItemHandler(itemId: string, parentID) {},
});

function DataContextProvider() {
  const navigate = useNavigate();
  const { confirm } = useConfirmHook();
  const params = useParams<ParamsEnum>();
  const { handlers } = useContext(AppContext);
  const { getApi, postApi, deleteApi } = Api();
  const [systemData, setSystemData] = useState([]);

  const [loading, setLoading] = useState({
    isFieldListsLoading: false,
  });
  const setLoadingHandler = (field: LoadingEnum, value: boolean) => {
    setLoading((e) => {
      return {
        ...e,
        [field]: value,
      };
    });
  };

  const getSystemTypeDataHandler = async (
    field: FieldTypeEnum | string,
    parentId?: string
  ) => {
    try {
      setLoadingHandler(LoadingEnum.isFieldListsLoading, true);
      setSystemData([]);
      let res = { data: [] };
      if (field === FieldTypeEnum.COUNTRY) {
        res = await getApi(ApiUrl.address.get_Locations, {
          parentID: parentId,
        });
      }

      setSystemData(res?.data);
    } catch (err) {
      handlers?.setError(true, err?.message);
    } finally {
      setLoadingHandler(LoadingEnum.isFieldListsLoading, false);
    }
  };
  const editSystemItemsHandler = async (values, props) => {
    try {
      handlers?.setLoading(true);

      setSystemData([]);
      if (params?.SYSTEM === FieldTypeEnum.COUNTRY) {
        await postApi(ApiUrl.address.post_editLocation, values);
      }
      handlers.setSuccess(true, "Data added successfully!");

      navigate(-1);
    } catch (err) {
      handlers?.setError(true, err?.message);
    } finally {
      handlers?.setLoading(false);
    }
  };

  const deleteSystemItemHandler = async (itemId: string, parentID?: string) => {
    try {
      const isConfirm = await confirm("Are you sure?");
      if (!isConfirm) {
        return;
      }
      handlers?.setLoading(true);
      if (params?.SYSTEM === FieldTypeEnum.COUNTRY) {
        await deleteApi(ApiUrl.address.delete_Location + itemId, {});
      }
      handlers.setSuccess(true, "Items deleted");
      await getSystemTypeDataHandler(params?.SYSTEM, parentID);
    } catch (err) {
      handlers?.setError(true, err?.message);
    } finally {
      handlers?.setLoading(false);
    }
  };

  let contextValue = {
    systemData,
    loading,
    getSystemTypeDataHandler,
    deleteSystemItemHandler,
    editSystemItemsHandler,
  };
  return (
    <DataContext.Provider value={contextValue}>
      <Outlet />
    </DataContext.Provider>
  );
}

export default DataContextProvider;
