import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { AccessibleNavLinks, PageLinks } from "routes";
import { IconTypeEnum, NavLinkInterface } from "interfaces";
import "./style.css";
import { ImageViewCard, MyIconButton } from "components";
import { AppContext } from "context";

export default function SideNavComponent() {
  const [activeGroupLink, setActiveGroupLink] = useState<string>();
  const [isImageLoadError, setImageLoadError] = useState(false);
  const navigate = useNavigate();
  const accessibleNavLinks = AccessibleNavLinks();
  const {
    state: { profileDetails },
  } = useContext(AppContext);
  const setGroupLinkHandler = (linkName?: string) => {
    setActiveGroupLink(() => {
      return linkName;
    });
  };

  return (
    <div className="sideNavComponent hideScroll">
      {/* profile*/}
      <div
        className={"flex items-center justify-center min-h-[150px]  relative"}
      >
        <div className={"absolute left-0 top-0"}>
          <MyIconButton
            onClick={() => navigate(PageLinks.profile.edit)}
            IconType={IconTypeEnum.EDIT}
          />
        </div>
        <div
          onClick={() => navigate(PageLinks.profile.details)}
          className={
            "flex items-center text-primary justify-center uppercase text-[22px] font-semibold cursor-pointer w-[96px] h-[96px] overflow-hidden rounded-full p-1 border-2 border-primary"
          }
        >
          {!isImageLoadError && profileDetails?.photo ? (
            <ImageViewCard
              onErrorHandler={() => setImageLoadError(true)}
              containerStyle={"w-[96px] h-[96px] rounded-full object-fill"}
              fileName={profileDetails?.photo}
            />
          ) : (
            <div>
              {profileDetails?.name?.first?.charAt(0)}{" "}
              {profileDetails?.name?.last?.charAt(0)}
            </div>
          )}
        </div>
      </div>

      <div className={"flex flex-col gap-2 "}>
        {accessibleNavLinks?.map((e, key) => {
          return (
            <NavComponent
              activeGroupLink={activeGroupLink}
              setGroupLinkHandler={setGroupLinkHandler}
              navLink={e}
              key={key}
            />
          );
        })}
      </div>
    </div>
  );
}

const NavComponent = ({
  navLink,
  activeGroupLink,
  setGroupLinkHandler,
}: {
  navLink: NavLinkInterface[];
  activeGroupLink: string;
  setGroupLinkHandler(linkName?: string);
}) => {
  let activeLinks = navLink?.find((e) => e?.name == activeGroupLink)?.groups;
  return (
    <>
      <div className={"flex gap-2"}>
        {navLink?.map((e, key) => {
          return (
            <NavLinks
              navLink={e}
              key={key}
              setGroupLinkHandler={setGroupLinkHandler}
              activeGroupLink={activeGroupLink}
            />
          );
        })}
      </div>
      {/*child link*/}
      {activeLinks?.length > 0 && (
        <div className={"flex flex-col rounded-md overflow-hidden"}>
          {activeLinks?.map((e, key) => {
            let Icon = e.Icon;
            return (
              <NavLink
                onClick={() => {
                  setGroupLinkHandler(activeGroupLink);
                }}
                className={() => {
                  return `sideNav_childLinkContainer bg-white text-primary  fill-primary `;
                }}
                key={key}
                to={e?.path}
              >
                {({ isActive }) => {
                  return (
                    <>
                      {isActive && (
                        <div className={"sideNav_activeBadge"}></div>
                      )}
                      <Icon
                        className={` text-[18px] h-[20px] w-[20px] text-primary`}
                      />
                      <span className={"text-[14px] text-center"}>
                        {e?.name}
                      </span>
                    </>
                  );
                }}
              </NavLink>
            );
          })}
        </div>
      )}
    </>
  );
};

function NavLinks({
  navLink,
  activeGroupLink,
  setGroupLinkHandler,
}: {
  navLink: NavLinkInterface;
  activeGroupLink: string;
  setGroupLinkHandler(name: string);
}) {
  const [isHover, setHover] = useState(false);
  let isActive = navLink?.name === activeGroupLink;
  let Icon = navLink?.Icon;
  const InActiveIcon = navLink?.InActiveIcon;
  const { pathname } = useLocation();
  useEffect(() => {
    if (navLink?.isGroup) {
      let shouldInclude = navLink?.groups?.some((e) => e?.path == pathname);
      if (shouldInclude) {
        setGroupLinkHandler(navLink?.name);
      }
    }
  }, []);
  return navLink?.isGroup ? (
    <div
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      className={`sidenav_LinkContainer hover:bg-white hover:text-primary hover:fill-primary ${
        isActive
          ? "bg-white text-primary fill-primary"
          : "border-2 text-[#000000d4] fill-[#000000d4]"
      }`}
      onClick={() => setGroupLinkHandler(navLink?.name)}
    >
      <>
        {isActive && <div className={"sideNav_activeBadge"}></div>}
        <div className={"mt-5 flex flex-col items-center gap-2"}>
          <div className={"h-[30px] flex items-center justify-center"}>
            {InActiveIcon && !isActive && !isHover ? (
              <InActiveIcon className={"w-[20px] h-[20px] text-[18px]"} />
            ) : (
              <Icon className={"w-[20px] h-[20px] text-[18px]"} />
            )}
          </div>
          <span className={"text-[14px] text-center"}>{navLink?.name}</span>
        </div>
      </>
    </div>
  ) : (
    <NavLink
      onClick={() => {
        setGroupLinkHandler("");
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      className={({ isActive }) => {
        return `sidenav_LinkContainer hover:bg-white hover:text-primary  ${
          isActive && !activeGroupLink
            ? "bg-white text-primary "
            : "border-2 text-[#000000d4] "
        }`;
      }}
      to={navLink?.path}
    >
      {({ isActive }) => {
        return (
          <>
            {isActive && !activeGroupLink && (
              <div className={"sideNav_activeBadge"}></div>
            )}
            <div className={"mt-5 flex flex-col items-center gap-2"}>
              <div className={"h-[30px] flex items-center justify-center"}>
                {InActiveIcon && !isActive && !isHover ? (
                  <InActiveIcon className={"w-[20px] h-[20px] text-[18px]"} />
                ) : (
                  <Icon className={"w-[20px] h-[20px] text-[18px]"} />
                )}
              </div>
              <span className={"text-[14px] text-center"}>{navLink?.name}</span>
            </div>
          </>
        );
      }}
    </NavLink>
  );
}
