import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";

import { FormConcludeButtons } from "assets";
import { AppContext, OptionsContext } from "context";
import { decodeOwnerOptionsHandler } from "helpers";
import { BoxModal, MySelectField } from "components";
import { usePageQueryHooks } from "useHooks";
import { CustomerInterface, ParamsStringEnum } from "interfaces";
import { PropertyDataContext } from "context";

import { OwnershipForms } from "../components";
import { ownershipFormikValues } from "../helpers";

function EditAssignOwnershipModal() {
  const navigate = useNavigate();
  const params = useParams<ParamsStringEnum>();
  const {
    editOwnershipHandler,
    getOwnershipHandler,
    ownershipDetails,
    getOwnershipDetailHandler,
  } = useContext(PropertyDataContext);
  const { returnURL } = usePageQueryHooks();
  const { ownerOptions, getOwnerOptionsHandler } = useContext(OptionsContext);
  const {
    handlers: { setLoading },
  } = useContext(AppContext);

  const ownerId = params.id;
  const propertyId = params.propertyId;

  const submitHandler = async (values, actions) => {
    await editOwnershipHandler(values, actions, {
      async onSuccess(payload?: any) {
        await getOwnershipHandler(propertyId);
      },
    });
  };
  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([
        ownerId && getOwnershipDetailHandler(ownerId, propertyId),
        getOwnershipHandler(propertyId),
        getOwnerOptionsHandler(),
      ]);

      setLoading(false);
    })();
  }, [ownerId]);
  const initialValues = {
    owner: ownerId || "",
    ...(ownershipDetails?._id
      ? {
          _id: ownershipDetails?._id,
        }
      : {}),
    ...ownershipFormikValues.initialValues(
      params?.propertyId,
      ownershipDetails
    ),
  };
  const validationSchema = yup
    .object()
    .shape({
      owner: yup.string().required("required"),
    })
    ?.concat(ownershipFormikValues?.validationSchema);
  return (
    <BoxModal
      isCloseAuto
      title={`${ownerId ? "Edit" : "Assign"} Ownership`}
      closeHandler={() => {
        returnURL ? navigate(returnURL) : navigate(-1);
      }}
    >
      <Formik
        enableReinitialize
        onSubmit={submitHandler}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values }) => {
          const ownerDetails: CustomerInterface = ownerOptions?.find(
            (e) => e?._id === values?.owner
          );
          return (
            <Form className={"flex flex-col justify-between gap-5"}>
              <div className={"grid grid-cols-2 gap-5"}>
                <MySelectField
                  disabled={ownerId ? true : false}
                  name={"owner"}
                  label={"Select Owner"}
                  option={{
                    selectOptions: decodeOwnerOptionsHandler(ownerOptions),
                  }}
                  isRequired
                />
                <div className={"input_container"}>
                  <span className={"input_label"}>
                    Email <span className={"text-tRed"}>*</span>
                  </span>
                  <div className={" p-1 text-[16px]"}>
                    <span>{ownerDetails?.email || "-"}</span>
                  </div>
                </div>
                <div className={"input_container"}>
                  <span className={"input_label"}>
                    Contact Number <span className={"text-tRed"}>*</span>
                  </span>
                  <div className={" p-1 text-[16px]"}>
                    <span>{ownerDetails?.phone || "-"}</span>
                  </div>
                </div>
              </div>
              {values?.owner && <OwnershipForms />}
              <FormConcludeButtons
                cancelButton={{
                  title: "Cancel",
                }}
                submitButton={{
                  title: "Submit",
                  isLoading: isSubmitting,
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </BoxModal>
  );
}

export default EditAssignOwnershipModal;
