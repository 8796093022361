import React, { useContext, useEffect } from "react";
import { FileViewCard, MyButton } from "components";
import { AddressText, FormWrapper, NameValueText } from "assets";

import { PageTemplate } from "templates";
import { useNavigate, useParams } from "react-router-dom";
import { MeterReadingContext } from "context";
import { IconTypeEnum, ParamsStringEnum } from "interfaces";
import { getCurrency, separateWithComma } from "helpers";
import { useDateHook } from "useHooks";
import { PageLinks } from "routes";

function MeterReadingDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const {
    details: meterReadingsDetails,
    isDetailsLoading,
    getDetailsHandler,
  } = useContext(MeterReadingContext);
  const { getDateHandler } = useDateHook();
  const navigate = useNavigate();
  useEffect(() => {
    getDetailsHandler(params?.id);
  }, [params?.id]);

  return (
    <PageTemplate
      scrollAll
      title={"Tools"}
      backNavigation={{
        title: "Reading Details",
      }}
      titleRightChildren={
        <div>
          {meterReadingsDetails?._id && (
            <MyButton
              isOutline
              iconType={IconTypeEnum.EDIT}
              name={"Edit Details"}
              onClick={() => {
                navigate({
                  pathname: PageLinks.meterReading.edit(
                    meterReadingsDetails?._id
                  ),
                });
              }}
            />
          )}
        </div>
      }
    >
      <div className={"flex flex-col gap-10"}>
        <div
          className={"grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-5"}
        >
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"Property"}
            value={
              <div className={"flex gap-2 items-center"}>
                ({meterReadingsDetails?.property?.propertyID})
                <AddressText
                  isOneLine
                  address={meterReadingsDetails?.property?.address}
                />
              </div>
            }
          />
          <NameValueText
            alignDirection={"col"}
            name={"Unit"}
            isLoading={isDetailsLoading}
            value={
              <span>
                ({meterReadingsDetails?.unit?.unitID})
                {meterReadingsDetails?.unit?.name}
              </span>
            }
          />
        </div>
        <div
          className={"grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-5"}
        >
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"Meter Number"}
            value={meterReadingsDetails?.name}
          />
        </div>
        <div
          className={"grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-5"}
        >
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"Type"}
            value={meterReadingsDetails?.meterType?.label}
          />
        </div>
        <div
          className={"grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-5"}
        >
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"From"}
            value={getDateHandler({
              date: meterReadingsDetails?.consumptionStartDate,
            })}
          />{" "}
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"To"}
            value={getDateHandler({
              date: meterReadingsDetails?.consumptionEndDate,
            })}
          />
        </div>
        <FormWrapper
          header={{
            title: {
              name: "Description",
            },
          }}
        >
          <div
            className={"grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              isLoading={isDetailsLoading}
              alignDirection={"col"}
              name={"Previous Reading"}
              value={meterReadingsDetails?.previousReading}
            />
            <NameValueText
              isLoading={isDetailsLoading}
              alignDirection={"col"}
              name={"New Reading"}
              value={meterReadingsDetails?.newReading}
            />
            <NameValueText
              isLoading={isDetailsLoading}
              alignDirection={"col"}
              name={"Consumption"}
              value={meterReadingsDetails?.consumption}
            />
            <NameValueText
              isLoading={isDetailsLoading}
              alignDirection={"col"}
              name={`Unit Price (${getCurrency()})`}
              value={separateWithComma(meterReadingsDetails?.unitCost)}
            />
            <NameValueText
              isLoading={isDetailsLoading}
              alignDirection={"col"}
              name={`Total Amount (${getCurrency()})`}
              value={separateWithComma(meterReadingsDetails?.totalAmount)}
            />
          </div>
        </FormWrapper>

        {meterReadingsDetails?.documents?.length > 0 && (
          <FormWrapper
            header={{
              title: {
                name: "Documents",
              },
            }}
          >
            <div className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1"}>
              {meterReadingsDetails?.documents?.map((e, key) => {
                return <FileViewCard canDownload filePath={e} key={key} />;
              })}
            </div>
          </FormWrapper>
        )}
      </div>
    </PageTemplate>
  );
}

export default MeterReadingDetailsPage;
