import React, { useContext } from "react";
import {
  IconTypeEnum,
  PackageCreateOrderPayloadInterface,
  PackagePurchaseType,
  SubscriptionPackageInterface,
} from "interfaces";
import { getCurrency, getIconHelper, separateWithComma } from "helpers";
import { useFormikContext } from "formik";
import { SubscriptionContext } from "../context";

interface PropsInterface {
  isSelected?: boolean;
  details: SubscriptionPackageInterface;
  isMonthly: boolean;
  canSelectPackage: boolean;
  duration: number;
  canHaveUnlimitedProperty: boolean;
  minExtraProperty: number;
  maxExtraProperty: number;
  onSelectPackageHandler(packageID: string);
}
function SelectSubscriptionCard({
  isMonthly,
  isSelected,
  details,
  canSelectPackage,
  duration,
  canHaveUnlimitedProperty,
  minExtraProperty,
  maxExtraProperty,
  onSelectPackageHandler,
}: PropsInterface) {
  const { isDetailsLoading } = useContext(SubscriptionContext);
  const { values, setFieldValue } =
    useFormikContext<PackageCreateOrderPayloadInterface>();
  const isPurchaseTypeProperty =
    values?.purchaseType === PackagePurchaseType.PROPERTY;
  const RadioSelectedIcon = getIconHelper(IconTypeEnum.SELECTED_RADIO);
  const RadioUnSelectedIcon = getIconHelper(IconTypeEnum.UN_SELECTED_RADIO);
  const CheckIcon = getIconHelper(IconTypeEnum.CHECK_FILL);
  const CloseIcon = getIconHelper(IconTypeEnum.CLOSE);
  const PropertyIcon = getIconHelper(IconTypeEnum.PROPERTY);
  const AddIcon = getIconHelper(IconTypeEnum.ADD);
  const MinusIcon = getIconHelper(IconTypeEnum.MINUS);
  let packageCost, flatPropertyCost;
  if (isMonthly) {
    packageCost = details?.monthlyCost?.cost * duration;
    flatPropertyCost = details?.monthlyCost?.flatPropertyCost;
  } else {
    packageCost = details?.annualCost?.cost * duration;
    flatPropertyCost = details?.annualCost?.flatPropertyCost;
  }
  if (details?.isFreeForever) {
    packageCost = 0;
  }
  const changePropertyQuantityHandler = (isAdd: boolean) => {
    if (isDetailsLoading) {
      return;
    }
    const currentValue = values?.propertyQuantity || 0;
    if (isAdd) {
      if (canHaveUnlimitedProperty) {
        setFieldValue("propertyQuantity", currentValue + 1);
      } else if (maxExtraProperty > currentValue) {
        setFieldValue("propertyQuantity", currentValue + 1);
      } else {
        return;
      }
    } else {
      if (minExtraProperty < currentValue) {
        setFieldValue("propertyQuantity", currentValue - 1);
      } else {
        return;
      }
    }
  };
  return (
    <div
      onClick={() =>
        canSelectPackage ? onSelectPackageHandler(details?._id) : undefined
      }
      className={`flex flex-col gap-5 bg-white cursor-pointer p-5 rounded-md select-none ${
        isSelected && "border-2 border-tBlue"
      } ${!canSelectPackage && "cursor-not-allowed opacity-50"}`}
    >
      <div className={"flex items-center justify-between"}>
        <div className={"flex items-center gap-2"}>
          {isSelected ? (
            <RadioSelectedIcon className={"text-tBlue text-[30px]"} />
          ) : (
            <RadioUnSelectedIcon className={"text-gray-200 text-[30px]"} />
          )}
          <b className={"text-[20px]"}>{details?.name}</b>
        </div>{" "}
        <div>
          {!isPurchaseTypeProperty && (
            <b className={"text-[20px]"}>
              {getCurrency()}
              {separateWithComma(packageCost)}
            </b>
          )}
        </div>
      </div>

      {isSelected && canSelectPackage && (
        <div className={"flex flex-col gap-5 border-t-2 pt-5"}>
          <div className={"grid grid-cols-2 sm:grid-cols-1 gap-5"}>
            {details?.propertyIncludedInBasePrice > 0 && (
              <div className={"flex items-center gap-2"}>
                <CheckIcon className={"text-blue-800 text-[22px]"} />
                <span className={"text-[14px]"}>
                  {details?.propertyIncludedInBasePrice}{" "}
                  {details?.propertyIncludedInBasePrice > 1
                    ? "Properties"
                    : "Property"}{" "}
                  Included
                </span>
              </div>
            )}

            {details?.specifications?.map((e, key) => {
              return (
                <div key={key} className={"flex items-center gap-2"}>
                  {e?.isIncluded ? (
                    <CheckIcon className={"text-blue-800 text-[22px]"} />
                  ) : (
                    <CloseIcon className={"text-gray-400 text-[22px]"} />
                  )}
                  <span className={"text-[14px]"}>{e?.feature}</span>
                </div>
              );
            })}
          </div>
          <div
            className={
              "flex justify-between flex-row md:flex-col sm:flex-col md:items-start sm:items-start bg-gray-50 p-5 gap-5 rounded-xl"
            }
          >
            <div className={"flex items-center gap-2"}>
              <div className={"p-2 text-[16px] bg-gray-200 rounded-full"}>
                <PropertyIcon className={"w-[16px] h-[16px] fill-gray-800"} />
              </div>
              <div className={"flex flex-col text-[14px]"}>
                <b>Add Extra Properties</b>
                <span>
                  Per Properties {getCurrency()}
                  {separateWithComma(flatPropertyCost)}
                </span>
              </div>
            </div>
            <div
              className={
                " flex items-center gap-8 rounded-full p-2 border-2 cursor-pointer bg-white"
              }
            >
              <div
                onClick={() => changePropertyQuantityHandler(false)}
                className={"bg-gray-100 border-2  rounded-full p-1 text-[14px]"}
              >
                <MinusIcon />
              </div>
              <span>{values?.propertyQuantity}</span>
              <div
                onClick={() => changePropertyQuantityHandler(true)}
                className={"bg-gray-100 border-2 rounded-full p-1 text-[14px]"}
              >
                <AddIcon />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectSubscriptionCard;
