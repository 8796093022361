import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { FieldArray, useFormikContext } from "formik";

import {
  MyButton,
  MyCheckButton,
  MyCheckInput,
  MySelectField,
  MyTextAreaInput,
  MyTextField,
  MyUploadDocument,
} from "components";
import {
  Api,
  decodeBankOptionsHandler,
  decodeDynamicFieldHandler,
  decodeInvoiceHandler,
  decodeUserOptionsHandler,
  getCurrency,
  getTaxNameHandler,
} from "helpers";
import {
  FieldTypeEnum,
  IconTypeEnum,
  InvoiceBillInterface,
  InvoiceInterface,
  InvoiceStatusEnum,
  ParamsStringEnum,
  QueryStringEnum,
  TaxTypeEnum,
} from "interfaces";
import { AppContext, InvoiceContext, OptionsContext } from "context";
import { FormConcludeButtons, FormWrapper } from "assets";
import { ApiUrl } from "services";

import {
  calcBillAccount,
  checkInvoiceStatus,
  InvoiceFormikForm,
} from "../helpers";
import { InvoiceAddRemainingItems, InvoiceFormListTable } from "../assets";
import { useConfirmHook } from "useHooks";
import ModalComponent from "../../../components/modal/BoxModal";
import { TableVirtuoso } from "react-virtuoso";

function InvoiceForm({ isFormLoading }) {
  const { values, setFieldValue, setFieldError, setFieldTouched, setValues } =
    useFormikContext<InvoiceInterface<number>>();
  const { confirm } = useConfirmHook();
  const params = useParams<ParamsStringEnum>();
  const {
    handlers: { setError, setLoading },
  } = useContext(AppContext);
  const [query] = useSearchParams();
  const { generateInvoiceIdHandler, details } = useContext(InvoiceContext);
  const { putApi, getApi } = Api();
  const [invoiceIDAndQty, setInvoiceIDAndQty] = useState({});
  // save invoice details for (P,S) return edit
  const [invoiceDetails, setInvoiceDetails] = useState<
    Partial<InvoiceInterface>
  >({});
  const { fieldTypeOptions, userOptions, invoiceOptions } =
    useContext(OptionsContext);
  const { isSales, isSalesReturn, isPurchaseReturn, isPurchase } =
    checkInvoiceStatus(values?.invoiceType);
  const invoiceID = query.get(QueryStringEnum.INVOICE_ID);
  const isEdit = params.id;
  const calculateTotalAccount = useMemo(() => {
    let subTotalAmount = 0,
      totalAmount = 0;
    values?.billItems?.forEach((e) => {
      const { total, subTotal } = calcBillAccount(
        e?.quantity,
        e?.rate,
        e?.discountPercent,
        e?.taxRate,
        values?.taxType
      );
      subTotalAmount += subTotal;
      totalAmount += total;
    });
    return {
      totalAmount,
      subTotalAmount,
    };
  }, [values?.billItems, values?.taxType]);

  //case 1: if return & edit -> don't fill the bill items
  const formResetHandler = async () => {
    try {
      setLoading(true);
      let tempInvoiceIDAndQty = {};
      const itemID = values?.invoiceReferenceID || invoiceID;
      if (!itemID) {
        setLoading(false);
        return;
      }
      const res: any = await getApi(ApiUrl.invoice.get_invoiceDetails + itemID);
      const data: InvoiceInterface = res?.data;
      setInvoiceDetails(data);
      if ((isPurchaseReturn || isSalesReturn) && isEdit) {
        data?.billItems?.forEach((e) => {
          tempInvoiceIDAndQty[e?._id] = e?.dueQuantity;
          return;
        });
      } else {
        setFieldValue("taxType", data?.taxType);
        setFieldValue("client", data?.client?._id);
        setFieldValue(
          "billItems",
          res?.data?.billItems?.map((e: InvoiceBillInterface) => {
            tempInvoiceIDAndQty[e?._id] = e?.dueQuantity;
            return {
              _id: e?._id,
              property: e?.property?._id,
              unit: e?.unit?._id,
              account: e?.account?._id,
              quantity: e?.quantity,
              rate: e?.rate,
              taxRate: e?.taxRate?.value,
              discountPercent: e?.discountPercent,
              description: e?.description,
            };
          })
        );
      }

      setInvoiceIDAndQty(tempInvoiceIDAndQty);
    } catch (err) {
      setFieldValue("billItems", []);
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  const validateInvoiceIdHandler = async (id) => {
    try {
      if (!id) {
        return;
      }
      const res = await putApi(ApiUrl.invoice.put_invoiceCheckUnique, {
        invoiceType: InvoiceStatusEnum.PURCHASE,
        invoiceDate: values?.invoiceDate,
        id: id,
        invoiceID: values?._id,
      });
      if (!res?.data) {
        setFieldError("invoiceID", "invalid");
        setFieldTouched("invoiceID", true, false);
      }
    } catch (err) {
      setError(true, new Error(err)?.message);
    }
  };

  const isItemsInReturn =
    (isSalesReturn || isPurchaseReturn) && values?.invoiceReferenceID
      ? true
      : false;

  useEffect(() => {
    (async () => {
      if (values?.invoiceReferenceID || invoiceID) {
        await formResetHandler();
        if (invoiceID && !values?.invoiceReferenceID) {
          setFieldValue("invoiceReferenceID", invoiceID);
        }
      }
    })();
  }, [invoiceID, values?.invoiceReferenceID]);

  const setInvoiceIDFieldHandler = async () => {
    setLoading(true);
    await generateInvoiceIdHandler(
      {
        invoiceType: values?.invoiceType,
        invoiceDate: values?.invoiceDate,
      },
      {
        onSuccess(payload?: any): any {
          setFieldValue("invoiceID", payload, false);
        },
      }
    );
    setLoading(false);
  };
  useEffect(() => {
    (async () => {
      if (!isEdit && values?.invoiceType && !values?.invoiceID) {
        await setInvoiceIDFieldHandler();
        console.log("enter after invoice");
      }
      console.log("called");
    })();
  }, [values?.invoiceType, isEdit, isFormLoading]);
  useEffect(() => {
    (() => {
      if (isEdit && details) {
        let values: any = InvoiceFormikForm.initialValues(details);
        setValues(values);
      }
    })();
  }, [isEdit, details]);
  return (
    <div className={"grid gap-10"}>
      <div
        className={`grid ${
          isPurchase ? "grid-cols-6" : "grid-cols-5"
        } gap-5 items-start  md:grid-cols-3 sm:grid-cols-1`}
      >
        <MySelectField
          autoFocus
          option={{
            defaultLabel: `Select ${
              isSales || isSalesReturn ? "Client" : "Supplier"
            }`,
            selectOptions: decodeUserOptionsHandler(userOptions),
          }}
          label={`${isSales || isSalesReturn ? "Client" : "Supplier"} Name`}
          isRequired
          name={"client"}
        />
        <MyTextField
          name={"invoiceDate"}
          type={"date"}
          isRequired
          label={"Invoice Date"}
        />
        {(isSales || isPurchase) && (
          <MyTextField name={"dueDate"} type={"date"} label={"Due Date"} />
        )}
        {/*<div className={"flex items-start"}>*/}
        <MyTextField
          label={`${
            isPurchase
              ? "Bill Number"
              : isSalesReturn || isPurchaseReturn
              ? "Credit Note"
              : "Invoice Number"
          } `}
          isRequired
          name={"invoiceID"}
          disabled={isEdit ? true : false}
          onBlur={(e) => validateInvoiceIdHandler(e?.target?.value)}
          placeholder={isPurchase ? "eg: Bill-123" : "eg: Inv-123"}
        />
        {/*<div className={"mt-2"}>*/}
        {/*  <MyButton*/}
        {/*    onClick={setInvoiceIDFieldHandler}*/}
        {/*    colorType={"white"}*/}
        {/*    className={"border-gray-200 py-2"}*/}
        {/*    isOutline*/}
        {/*    size={"md"}*/}
        {/*    iconType={IconTypeEnum.RELOAD}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*</div>*/}

        <MyTextField
          name={"reference"}
          placeholder={"eg: REF-123"}
          label={"Reference"}
        />

        {isPurchase && (
          <MySelectField
            label={"Bill Type"}
            isCreatable={{
              type: FieldTypeEnum.BILL_TYPE,
            }}
            option={{
              selectOptions: decodeDynamicFieldHandler(
                fieldTypeOptions[FieldTypeEnum.BILL_TYPE]
              ),
            }}
            name={`billType`}
          />
        )}

        {(isSalesReturn || isPurchaseReturn) && (
          <MySelectField
            isClearable
            label={isSalesReturn ? `Sales Invoice` : "Purchase Order"}
            option={{
              selectOptions: decodeInvoiceHandler(invoiceOptions),
            }}
            isRequired
            name={`invoiceReferenceID`}
          />
        )}
      </div>

      {(((isSalesReturn || isPurchaseReturn) && isItemsInReturn) ||
        isSales ||
        isPurchase) && (
        <>
          <div
            className={"flex justify-between md:justify-start sm:justify-start"}
          >
            <div></div>
            <div className={"flex  gap-5"}>
              {values?.invoiceType === InvoiceStatusEnum.SALES_RETURN ||
              values?.invoiceType === InvoiceStatusEnum.PURCHASE_RETURN ? (
                <>
                  <MyCheckButton
                    changeHandler={() => {}}
                    label={getTaxNameHandler(values?.taxType)}
                    isChecked
                    value={"true"}
                  />
                  <MyButton
                    onClick={async () => {
                      const isConfirm = await confirm("Are you sure?");
                      if (!isConfirm) return;
                      await formResetHandler();
                    }}
                    name={"Reset Field"}
                    colorType={"white"}
                  />
                </>
              ) : (
                <>
                  <MyCheckInput
                    name={"taxType"}
                    iconType={"radio"}
                    label={"Tax Exclusive"}
                    defaultValue={TaxTypeEnum.EXCLUSIVE}
                  />
                  <MyCheckInput
                    iconType={"radio"}
                    name={"taxType"}
                    label={"Tax Inclusive"}
                    defaultValue={TaxTypeEnum.INCLUSIVE}
                  />
                  <MyCheckInput
                    iconType={"radio"}
                    name={"taxType"}
                    label={"Tax Exempted"}
                    defaultValue={TaxTypeEnum.EXEMPTED}
                  />
                </>
              )}
            </div>
          </div>
          {!isFormLoading && (
            <FieldArray
              name={"billItems"}
              render={({ remove, insert, push }) => {
                return (
                  <>
                    <div
                      className={"grid virtuoso-table-header overflow-y-scroll"}
                    >
                      <TableVirtuoso
                        style={{
                          height: !values?.billItems?.length
                            ? "100px"
                            : values?.billItems?.length >= 2
                            ? "500px"
                            : "250px",
                          width: "full",
                        }}
                        data={values?.billItems}
                        className={"table_container  w-full"}
                        fixedHeaderContent={() => (
                          <>
                            <th
                              className={
                                "table_head_data min-w-[150px] border-b-2  border-b-2 bg-white px-1  text-left"
                              }
                            >
                              Property
                            </th>
                            <th
                              className={
                                "table_head_data min-w-[150px] border-b-2 bg-white px-1 text-left "
                              }
                            >
                              Unit
                            </th>
                            <th
                              className={
                                "table_head_data min-w-[150px] border-b-2  bg-white px-1 text-left"
                              }
                            >
                              Account
                              <span className={"input_textError"}>*</span>
                            </th>
                            <th
                              className={
                                "table_head_data  border-b-2  bg-white px-1 text-left"
                              }
                            >
                              Description
                            </th>
                            <th
                              className={
                                "table_head_data  border-b-2  bg-white px-1 text-left"
                              }
                            >
                              Quantity
                              <span className={"input_textError"}>*</span>
                            </th>
                            <th
                              className={
                                "table_head_data border-b-2  bg-white px-1 text-left"
                              }
                            >
                              Rate<span className={"input_textError"}>*</span>
                            </th>
                            <th
                              className={
                                "table_head_data   border-b-2  bg-white px-1 text-left"
                              }
                            >
                              Discount (in %)
                            </th>
                            {values?.taxType !== TaxTypeEnum.EXEMPTED && (
                              <th
                                className={
                                  "table_head_data  border-b-2  bg-white px-1 text-left"
                                }
                              >
                                Tax Rate
                              </th>
                            )}
                            {values?.taxType === TaxTypeEnum.INCLUSIVE && (
                              <th
                                className={
                                  "table_head_data   border-b-2  bg-white px-1 text-center"
                                }
                              >
                                Tax Amount
                              </th>
                            )}
                            <th
                              className={
                                "table_head_data   border-b-2  bg-white px-1 text-center"
                              }
                            >
                              Amount
                            </th>
                            <th
                              className={
                                "table_head_data  border-b-2  bg-white px-1 text-center"
                              }
                            ></th>
                          </>
                        )}
                        itemContent={(index, value) => (
                          <InvoiceFormListTable
                            index={index}
                            key={index}
                            removeHandler={remove}
                            insertHandler={insert}
                            setFieldValue={setFieldValue}
                            value={value}
                            invoiceIDAndQty={invoiceIDAndQty}
                            taxType={values?.taxType}
                          />
                        )}
                      />
                      {!values?.billItems?.length && (
                        <div className={"text-center"}>No bill items</div>
                      )}
                    </div>
                    <div className={"grid overflow-y-scroll"}>
                      {(isPurchaseReturn || isSalesReturn) && (
                        <InvoiceAddRemainingItems
                          pushHandler={push}
                          allBillItems={invoiceDetails?.billItems}
                          addedBillItems={values?.billItems}
                        />
                      )}
                    </div>
                  </>
                );
              }}
            />
          )}
          <div
            className={"grid grid-cols-3 gap-5 md:grid-cols-1 sm:grid-cols-1"}
          >
            <div
              className={"col-span-1 flex flex-col gap-10 md:gap-5 sm:gap-5"}
            >
              <MyUploadDocument
                fileType={"image"}
                label={"Documents"}
                name={"documents"}
                fileContainerStyle={"grid grid-cols-2 gap-5"}
              />
              <NoteModal />
            </div>
            <div></div>
            {/*bills*/}
            <div className={"flex flex-col col-span-1  gap-5 text-[14px]"}>
              <div className={"grid grid-cols-3"}>
                <span className={"col-span-2"}>
                  Sub-total (After discount):
                </span>
                <span>
                  {getCurrency()}{""}
                  {parseFloat(
                    String(calculateTotalAccount.subTotalAmount || 0)
                  )?.toFixed(2)}
                </span>
              </div>
              {values?.taxType !== TaxTypeEnum.EXEMPTED && (
                <>
                  {values?.billItems?.map((e, key) => {
                    if (!e?.taxRate) {
                      return;
                    }
                    const { taxAmount } = calcBillAccount(
                      e?.quantity,
                      e?.rate,
                      e?.discountPercent,
                      e?.taxRate,
                      values?.taxType
                    );
                    return (
                      <div className={"grid grid-cols-3"} key={key}>
                        <span className={"col-span-2"}>
                          {values?.taxType === TaxTypeEnum.INCLUSIVE
                            ? "Inclusive"
                            : "Total "}{" "}
                          TAX {e?.taxRate}%
                        </span>
                        <span>
                          {getCurrency()}{""}
                          {parseFloat(String(taxAmount || 0))?.toFixed(2)}
                        </span>
                      </div>
                    );
                  })}
                </>
              )}

              <div
                className={
                  "grid text-[18px] grid-cols-3 pt-5 border-t-[1px] border-t-[#012169]"
                }
              >
                <span className={"col-span-2 font-semibold"}>Total:</span>
                <span className={"font-bold text-tBlue"}>
                  {getCurrency()}
                  {parseFloat(
                    String(calculateTotalAccount.totalAmount || 0)
                  ).toFixed(2)}
                </span>
              </div>
            </div>
          </div>

          <FormWrapper
            header={{
              title: {
                name: "Mode of Receipt",
              },
            }}
          >
            <FieldArray
              name={"payments"}
              render={({ remove, push }) => {
                const modeOptions =
                  fieldTypeOptions[FieldTypeEnum.PAYMENT_MODE];
                const bankID = modeOptions?.find(
                  (e) => e?.value == "bank"
                )?._id;
                const cardID = modeOptions?.find(
                  (e) => e?.value == "card"
                )?._id;
                const onlineID = modeOptions?.find(
                  (e) => e?.value == "online-payment"
                )?._id;
                return (
                  <div className={"flex flex-col gap-5"}>
                    {values?.payments?.map((e, index) => {
                      return (
                        <ReceiptModeForm
                          bankID={bankID}
                          cardID={cardID}
                          onlineID={onlineID}
                          values={values}
                          index={index}
                          key={index}
                          removeHandler={() => remove(index)}
                        />
                      );
                    })}
                    <div>
                      <MyButton
                        isOutline
                        name={"Add More"}
                        iconType={IconTypeEnum.ADD}
                        onClick={() => push({})}
                      />
                    </div>
                  </div>
                );
              }}
            />
          </FormWrapper>
        </>
      )}
    </div>
  );
}
function ReceiptModeForm({
  index,
  removeHandler,
  values,
  bankID,
  cardID,
  onlineID,
}) {
  const { userOptions, fieldTypeOptions, bankOptions } =
    useContext(OptionsContext);
  const formValues = values?.payments[index];
  return (
    <div className={"flex flex-col gap-5"}>
      <div className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <MyTextField
          label={"Amount"}
          placeholder={`${getCurrency()}0.00`}
          name={`payments.${index}.amount`}
        />
        <MyTextField
          label={"Date"}
          type={"date"}
          name={`payments.${index}.paymentOn`}
        />
      </div>
      <div className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <MySelectField
          option={{
            selectOptions: decodeDynamicFieldHandler(
              fieldTypeOptions[FieldTypeEnum.PAYMENT_MODE]
            ),
          }}
          isCreatable={{
            type: FieldTypeEnum.PAYMENT_MODE,
          }}
          label={"Mode of Receipt"}
          isRequired
          placeholder={`${getCurrency()}`}
          name={`payments.${index}.mode`}
        />
        <div className={"flex items-end"}>
          <div>
            <MyButton
              onClick={removeHandler}
              name={"Remove"}
              iconType={IconTypeEnum.DELETE}
              colorType={"danger"}
              isOutline
            />
          </div>
        </div>
      </div>
      {formValues?.mode && formValues?.mode === bankID && (
        <div className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          {(formValues.mode == bankID ||
            formValues?.mode == cardID ||
            formValues?.mode == onlineID) && (
            <MySelectField
              name={`payments.${index}.bankName`}
              option={{
                selectOptions: decodeBankOptionsHandler(bankOptions),
              }}
              label={"Name of Bank"}
            />
          )}

          {formValues?.mode == bankID && (
            <>
              <MyTextField
                placeholder={"Eg. CHQ12"}
                label={"Cheque No"}
                name={`payments.${index}.chequeNo`}
              />
            </>
          )}
          {formValues?.mode === onlineID && (
            <>
              <MySelectField
                label={"Agents"}
                name={`payments.${index}.agents`}
                option={{
                  selectOptions: decodeUserOptionsHandler(userOptions),
                }}
              />
            </>
          )}
          {(formValues?.mode === onlineID || formValues?.mode === cardID) && (
            <>
              <MyTextField
                placeholder={"Eg. TRNS 122"}
                label={"Transaction No"}
                name={`payments.${index}.transactionNo`}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}

function NoteModal() {
  const [isOpen, setOpen] = useState(false);
  const { values } = useFormikContext<InvoiceInterface<number>>();
  if (isOpen) {
    return (
      <ModalComponent
        title={values?.description ? "Edit Remarks" : "Add Remarks"}
        closeHandler={() => setOpen(false)}
      >
        <div className={"flex flex-col gap-10"}>
          <MyTextAreaInput label={"Remark/Note"} name={"description"} />
          <FormConcludeButtons
            isInModal
            submitButton={{
              title: "Save",
              notSubmit: true,
              handler() {
                setOpen(false);
              },
            }}
            cancelButton={{
              title: "Cancel",
              handler() {
                setOpen(false);
              },
            }}
          />
        </div>
      </ModalComponent>
    );
  }
  return (
    <div>
      <MyButton
        onClick={() => setOpen(true)}
        isOutline
        name={values?.description ? "Edit Note" : "Add Note"}
        type={"button"}
        iconType={IconTypeEnum.FILE}
      />
    </div>
  );
}
export default InvoiceForm;
