import React, { useContext, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import {
  IconTypeEnum,
  ParamsStringEnum,
  RepairAndMaintenanceInterface,
  SpaceAccessListEnum,
} from "interfaces";
import { PageLinks } from "routes";
import { TaskContext } from "context";
import { PageTemplate } from "templates";
import { AddressText, NameValueText, StatusText } from "assets";
import { getCurrency, separateWithComma } from "helpers";
import { FileViewCard, MyButton } from "components";
import { useAuthorizationHook, useDateHook } from "useHooks";
import useDateHooks from "../../../useHooks/useDateHooks";

function TaskDetailsPage() {
  const params = useParams<ParamsStringEnum>();

  const navigate = useNavigate();
  const { getStaffFullName } = useDateHooks();

  const {
    isDetailsLoading,
    details: taskDetails,
    getDetailsHandler,
  } = useContext(TaskContext);

  useEffect(() => {
    (async () => {
      await getDetailsHandler(params?.id);
    })();
  }, [params?.id]);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([SpaceAccessListEnum.TASK_EDIT_TASK]);
  const { getDateHandler } = useDateHook();
  return (
    <>
      <PageTemplate
        scrollAll
        title={"Task"}
        backNavigation={{
          title: "Task Details",
          path: PageLinks.task.list,
        }}
        breadCrumb={[
          {
            name: "Task",
          },
        ]}
        titleRightChildren={
          <div>
            {canEdit && taskDetails?._id && (
              <MyButton
                name={"Edit Details"}
                iconType={IconTypeEnum.EDIT}
                isOutline
                onClick={() => navigate(PageLinks.task.edit(taskDetails?._id))}
              />
            )}
          </div>
        }
      >
        <div className={"flex flex-col gap-10 md:gap-5 sm:gap-5"}>
          <div
            className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              isLoading={isDetailsLoading}
              name={"Property"}
              value={
                <div className={"flex gap-2 items-center"}>
                  <span className={"text-tBlue font-bold text-[14px]"}>
                    {taskDetails?.property?.propertyID}
                  </span>
                  <AddressText
                    isOneLine
                    address={taskDetails?.property?.address}
                  />
                </div>
              }
            />
            {/*unit*/}
            <NameValueText
              name={"Unit"}
              isLoading={isDetailsLoading}
              value={
                <div className={"flex items-center gap-2"}>
                  <div className={"flex gap-2 items-center"}>
                    <span className={"text-tBlue font-bold text-[14px]"}>
                      {taskDetails?.unit?.unitID}
                    </span>
                    <span>{taskDetails?.unit?.name}</span>
                  </div>
                </div>
              }
            />
            <NameValueText
              isLoading={isDetailsLoading}
              name={"Assign To"}
              value={getStaffFullName(taskDetails?.assignee?.name) || "-"}
            />{" "}
            <NameValueText
              isLoading={isDetailsLoading}
              name={"Charge Amount"}
              value={`${getCurrency()}${separateWithComma(
                taskDetails?.chargeAmount
              )}`}
            />
          </div>
          <div
            className={"grid grid-cols-4  md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              isLoading={isDetailsLoading}
              name={"Subject/Title"}
              value={taskDetails?.name}
            />
            <NameValueText
              name={"Target Date"}
              value={getDateHandler({ date: taskDetails?.targetDate })}
            />
          </div>
          <div
            className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              isLoading={isDetailsLoading}
              name={"Priority"}
              value={
                <StatusText
                  status={taskDetails?.taskPriority?.label}
                  label={taskDetails?.taskPriority?.label}
                />
              }
            />{" "}
            <NameValueText
              isLoading={isDetailsLoading}
              name={"Status"}
              value={
                <StatusText
                  status={taskDetails?.taskStatus?.label}
                  label={taskDetails?.taskStatus?.label}
                />
              }
            />
          </div>{" "}
          {taskDetails?.isRepairAndMaintenanceTask && (
            <>
              <div
                className={
                  "grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5"
                }
              >
                <NameValueText
                  isLoading={isDetailsLoading}
                  name={"Repair/Maintenance"}
                  value={`${taskDetails?.repair?.repairID}-${
                    taskDetails?.repair?.inventoryItemID?.item?.label || ""
                  }`}
                />
              </div>
              <ItemCard details={taskDetails?.repair} />
            </>
          )}
          <div className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1"}>
            <div className={"col-span-2"}>
              <NameValueText
                isLoading={isDetailsLoading}
                name={"Overall Comment"}
                value={taskDetails?.description || "-"}
              />{" "}
            </div>
          </div>
          <div>
            {taskDetails?.documents?.length > 0 && (
              <div
                className={
                  "grid grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-5"
                }
              >
                {taskDetails?.documents?.map((e, key) => {
                  return <FileViewCard key={key} filePath={e} canDownload />;
                })}
              </div>
            )}
          </div>
        </div>
      </PageTemplate>
    </>
  );
}

const ItemCard = ({ details }: { details: RepairAndMaintenanceInterface }) => {
  const { getDateHandler } = useDateHook();
  return (
    <div className={"flex flex-col gap-5  pb-4"}>
      <div
        className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-5 gap-5"}
      >
        <NameValueText
          name={"Item"}
          value={details?.inventoryItemID?.item?.label}
        />{" "}
        <NameValueText
          name={"Unit"}
          value={details?.inventoryItemID?.unit?.label}
        />{" "}
        <NameValueText name={"Unit to repair"} value={details?.unitToRepair} />
        <NameValueText
          name={"Condition"}
          value={details?.inventoryItemID?.inventoryItemCondition?.label}
        />
        <NameValueText
          name={"Notes"}
          value={details?.inventoryItemID?.notes || "-"}
        />
      </div>

      {details?.inventoryItemID?.images?.length > 0 && (
        <div className={"input_container"}>
          <NameValueText name={"Inventory Images"} value={""} />
          <div
            className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-5"}
          >
            {details?.inventoryItemID?.images?.map((e, key) => {
              return <FileViewCard key={key} filePath={e} canDownload />;
            })}
          </div>
        </div>
      )}
      <div
        className={"grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-5 gap-5"}
      >
        <NameValueText
          name={"Priority"}
          value={details?.repairPriority?.label}
        />{" "}
        <NameValueText name={"Status"} value={details?.repairStatus?.label} />{" "}
        <NameValueText name={"Description"} value={details?.description} />{" "}
        <NameValueText
          name={"Target Date"}
          value={getDateHandler({ date: details?.targetDate })}
        />{" "}
      </div>
    </div>
  );
};

export default TaskDetailsPage;
