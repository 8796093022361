import React from "react";

import { FormWrapper } from "assets";
import { MyTextField } from "components";

function SubLedgerNameForm() {
  return (
    <div className={"flex flex-col gap-5"}>
      <FormWrapper header={{ title: { name: "" } }}>
        <div className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5`}>
          <MyTextField
            isRequired
            label={"Name"}
            name="label"
            placeholder={"Eg. Income balance"}
          />
        </div>
      </FormWrapper>
    </div>
  );
}

export default SubLedgerNameForm;
