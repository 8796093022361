import React from "react";

import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { JournalInterface } from "interfaces";

import { getCurrency } from "../HandlerHelpers";

interface PropsInterface {
  data?: JournalInterface[];
  dateFormatter?({ date }: { date: Date });
}
async function generateJournalReport({ data, dateFormatter }: PropsInterface) {
  try {
    //    create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Journal Report", {});
    worksheet.properties.defaultRowHeight = 20;
    worksheet.properties.defaultColWidth = 20;
    let tableHeaders = [
      {
        name: "SN.",
      },
      {
        name: "JID",
      },
      {
        name: "Narration",
      },
      {
        name: "Date",
      },
      {
        name: "Amount (Dr)",
      },
      {
        name: "Amount (Cr)",
      },

      {
        name: "Status",
      },
    ];
    let tableRows = data?.map((e, index) => {
      return [
        index + 1,
        e?.journalID,
        e?.name,
        dateFormatter({ date: e?.transactionDate }),
        `${getCurrency()}${e?.totalDr}`,
        `${getCurrency()}${e?.totalCr}`,
        e?.isDraft ? "pending" : "confirmed",
      ];
    });
    // add a table to a sheet
    worksheet.addTable({
      name: "Journal Report",
      ref: "A1",
      headerRow: true,
      totalsRow: false,
      style: {
        showRowStripes: false,
      },
      columns: tableHeaders,
      rows: tableRows,
    });
    worksheet.getColumn(3).width = 40;
    //    save the workbook to a file
    const bufferFile = await workbook.xlsx.writeBuffer();
    await FileSaver.saveAs(
      new Blob([bufferFile], { type: "application/octet-stream" }),
      `${Date.now()}-journal.xlsx`
    );
  } catch (err) {
    throw err;
  }
}

export default generateJournalReport;
