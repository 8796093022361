import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { MyButton, TableCardComponent } from "components";
import { AccessVerifier, PageTemplate } from "templates";
import {
  FieldTypeEnum,
  IconTypeEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { TableFilterCard } from "content";
import { PageLinks } from "routes";

import { MeterReadingTableColumns } from "../../components";
import { MeterReadingContext } from "context";
import { usePageQueryHooks, usePaginationQueryHooks } from "useHooks";

function MeterReadingPage() {
  const {
    getAllDataHandlers,
    allData,
    isLoading,
    totalDocs,
    totalArchiveDocs,
  } = useContext(MeterReadingContext);
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const { isArchive, currentPage } = usePaginationQueryHooks();
  const { unitID, type, propertyID } = usePageQueryHooks();
  const pageQuery = {
    unit: unitID,
    type,
    property: propertyID,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, unitID, propertyID, type, currentPage]);

  const tableCardProps = {
    column: MeterReadingTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      leftChildren: (
        <TableFilterCard
          haveProperty
          haveUnit
          haveDynamicOption={{
            queryName: QueryStringEnum.TYPE,
            placeholder: "All Type",
            fieldName: FieldTypeEnum.METER_TYPE,
          }}
        />
      ),
      rightChildren: (
        <AccessVerifier
          accessKeys={[SpaceAccessListEnum.TOOLS_ADD_METER_READING]}
          isForPage={false}
        >
          <MyButton
            onClick={() => {
              navigate(PageLinks.meterReading.new);
            }}
            iconType={IconTypeEnum.ADD}
            name={"Add Reading"}
          />
        </AccessVerifier>
      ),
    },
  };
  return (
    <PageTemplate
      title={"Tools"}
      breadCrumb={[
        {
          name: "Meter Readings",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default MeterReadingPage;
