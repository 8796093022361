import CandidateContextProvider, {
  CandidateDataContext,
} from "./CandidateContext";
import TrashContextProvider, { TrashContext } from "./TrashContext";
import TripContextProvider, { TripContext } from "./TripContext";
import MessageContextProvider, { MessageContext } from "./MessageContext";
import MeterReadingContextProvider, {
  MeterReadingContext,
} from "./MeterReadingContext";
import NotesContextProvider, { NotesContext } from "./NotesContext";
import DocumentContextProvider, { DocumentContext } from "./DocumentContext";
import BookingContextProvider, {
  BookingDataContext,
} from "./BookingDataContext";
import InventoryContextProvider, {
  InventoryDataContext,
} from "./InventoryContext";
import BillingAddressContextProvider, {
  BillingAddressContext,
} from "./BillingAddressContext";
import MaintenanceContextProvider, {
  MaintenanceContext,
} from "./MaintenanceContext";
import TaskContextProvider, { TaskContext } from "./TaskContext";
import UserContextProvider, { UserContext } from "./UserContext";
import LoanContextProvider, { LoanContext } from "./LoansAndMortgagesContext";
import PrivilegeContextProvider, { PrivilegeContext } from "./PrivilegeContext";
import InvoiceContextProvider, { InvoiceContext } from "./InvoiceContext";
import JournalContextProvider, { JournalContext } from "./JournalContext";
import PDFContextProvider, { PDFContext } from "./PDFContext";
import LedgerNameContextProvider, {
  LedgerNameContext,
} from "./LedgerNameContext";
import PropertyDataContextProvider, {
  PropertyDataContext,
} from "./PropertyContext";
import UnitDataContextProvider, { UnitDataContext } from "./UnitContext";
import EventDataContextProvider, { EventContext } from "./EventContext";
import TenancyDataContextProvider, {
  TenancyDataContext,
} from "./TenancyContext";
import LedgerSubNameContextProvider, {
  SubLedgerNameContext,
} from "./LedgerSubNameContext";
import ManageNotificationContextProvider, {
  ManageNotificationContext,
} from "./ManageNotificationContext";
import NotificationContextProvider, {
  NotificationContext,
} from "./NotificationContext";
import AccountingContextProvider, {
  AccountingContext,
} from "./AccountingContext";
import DynamicListContextProvider, {
  DynamicListContext,
} from "./DynamicListContext";
import BankContextProvider, { BankContext } from "./BankContext";
import BankReconciliationContextProvider, {
  BankReconciliationContext,
} from "./BankReconciliationContext";
import DashboardContextProvider, {
  DashboardStatsContext,
} from "./DashboardStatsContext";
import TrueLayerContextProvider, { TrueLayerContext } from "./TrueLayerContext";
import ReportContextProvider, { ReportContext } from "./ReportContext";
import RetainEarningContextProvider, {
  RetainEarningContext,
} from "./RetainEarningContext";
import ProfileContextProvider, { ProfileContext } from "./ProfileContext";

import React from "react";
function ModuleContext({ children }) {
  return (
    <PDFContextProvider>
      <ReportContextProvider>
        <ProfileContextProvider>
          <BillingAddressContextProvider>
            <LedgerNameContextProvider>
              <JournalContextProvider>
                <TenancyDataContextProvider>
                  <TripContextProvider>
                    <NotesContextProvider>
                      <TrashContextProvider>
                        <CandidateContextProvider>
                          <MeterReadingContextProvider>
                            <MessageContextProvider>
                              <DocumentContextProvider>
                                <BookingContextProvider>
                                  <InventoryContextProvider>
                                    <MaintenanceContextProvider>
                                      <TaskContextProvider>
                                        <UserContextProvider>
                                          <LoanContextProvider>
                                            <PrivilegeContextProvider>
                                              <InvoiceContextProvider>
                                                <PropertyDataContextProvider>
                                                  <UnitDataContextProvider>
                                                    <EventDataContextProvider>
                                                      <LedgerSubNameContextProvider>
                                                        <ManageNotificationContextProvider>
                                                          <AccountingContextProvider>
                                                            <BankContextProvider>
                                                              <DynamicListContextProvider>
                                                                <BankReconciliationContextProvider>
                                                                  <DashboardContextProvider>
                                                                    <TrueLayerContextProvider>
                                                                      <NotificationContextProvider>
                                                                        <RetainEarningContextProvider>
                                                                          {
                                                                            children
                                                                          }
                                                                        </RetainEarningContextProvider>
                                                                      </NotificationContextProvider>
                                                                    </TrueLayerContextProvider>
                                                                  </DashboardContextProvider>
                                                                </BankReconciliationContextProvider>
                                                              </DynamicListContextProvider>
                                                            </BankContextProvider>
                                                          </AccountingContextProvider>
                                                        </ManageNotificationContextProvider>
                                                      </LedgerSubNameContextProvider>
                                                    </EventDataContextProvider>
                                                  </UnitDataContextProvider>
                                                </PropertyDataContextProvider>
                                              </InvoiceContextProvider>
                                            </PrivilegeContextProvider>
                                          </LoanContextProvider>
                                        </UserContextProvider>
                                      </TaskContextProvider>
                                    </MaintenanceContextProvider>
                                  </InventoryContextProvider>
                                </BookingContextProvider>
                              </DocumentContextProvider>
                            </MessageContextProvider>
                          </MeterReadingContextProvider>
                        </CandidateContextProvider>
                      </TrashContextProvider>
                    </NotesContextProvider>
                  </TripContextProvider>
                </TenancyDataContextProvider>
              </JournalContextProvider>
            </LedgerNameContextProvider>
          </BillingAddressContextProvider>
        </ProfileContextProvider>
      </ReportContextProvider>
    </PDFContextProvider>
  );
}

export default ModuleContext;
export {
  RetainEarningContext,
  DynamicListContext,
  PDFContext,
  ReportContext,
  BankReconciliationContext,
  AccountingContext,
  ManageNotificationContext,
  NotificationContext,
  SubLedgerNameContext,
  PropertyDataContext,
  LedgerNameContext,
  UnitDataContext,
  JournalContext,
  InvoiceContext,
  BillingAddressContext,
  PrivilegeContext,
  LoanContext,
  UserContext,
  TaskContext,
  EventContext,
  TenancyDataContext,
  CandidateDataContext,
  TrashContext,
  TripContext,
  MessageContext,
  MeterReadingContext,
  NotesContext,
  DocumentContext,
  BookingDataContext,
  InventoryDataContext,
  MaintenanceContext,
  BankContext,
  DashboardStatsContext,
  TrueLayerContext,
  ProfileContext,
};
