import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { DataContext } from "../context";
import { SignUpLandlordFormik } from "../helpers";
import {
  MyButton,
  MyTextField,
  MyPasswordInput,
  MyToggleButton,
} from "components";
import { useNavigate } from "react-router-dom";
import { useToggleHooks } from "useHooks";
import { AppContext } from "../../../context";

function SignupPage() {
  const { signUpAsLandlordHandler } = useContext(DataContext);
  const {
    state: { appSettings },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { isToggle, setToggle } = useToggleHooks();
  return (
    <Formik onSubmit={signUpAsLandlordHandler} {...SignUpLandlordFormik}>
      {({ isSubmitting, errors }) => {
        return (
          <Form className={"flex flex-col gap-10 "}>
            <div className={"flex flex-col gap-8"}>
              <span className={"text-[18px] text-tRed"}>
                {typeof errors?.error === "string" && `*${errors?.error}`}
              </span>
              <MyTextField
                name={"firstName"}
                label={"First Name"}
                isRequired
                placeholder={"Enter your first name"}
              />
              <MyTextField
                name={"lastName"}
                label={"Last Name"}
                isRequired
                placeholder={"Enter your last name"}
              />
              <MyTextField
                name={"email"}
                label={"Email Address"}
                isRequired
                placeholder={"Enter your email address"}
              />
              <MyTextField
                name={"phone"}
                label={"Phone Number"}
                isRequired
                maxChar={16}
                placeholder={"Enter your phone number"}
              />{" "}
              <MyTextField
                name={"companyName"}
                label={"Company Name"}
                placeholder={"Enter your company name"}
              />
              <MyTextField
                name={"companyRegistration"}
                label={"Company Registration Number"}
                placeholder={"Enter your company registration number"}
              />
              <MyTextField
                name={"vatRegistration"}
                label={"VAT Registration Number"}
                placeholder={"Enter your VAT registration number"}
              />
              <MyPasswordInput
                name={"password"}
                label={"Password"}
                isRequired
                placeholder={"Enter a new password"}
              />
              <div className={"flex items-start gap-5"}>
                <MyToggleButton
                  value={isToggle}
                  type={"button"}
                  changeHandler={setToggle}
                />
                <span
                  onClick={() => {}}
                  className={
                    "cursor-pointer text-black font-normal text-[16px]"
                  }
                >
                  By ticking, you are confirming that you have read, understood
                  and agree to our{" "}
                  {appSettings?.termsAndConditionURL ? (
                    <a
                      href={appSettings?.termsAndConditionURL}
                      target={"_blank"}
                      className={"text-primary font-bold underline"}
                    >
                      Terms & Conditions
                    </a>
                  ) : (
                    <b className={"text-primary font-bold"}>
                      Terms & Conditions
                    </b>
                  )}
                </span>
              </div>
            </div>
            <MyButton
              disabled={!isToggle}
              title={!isToggle && "Accept the Terms & Conditions"}
              name={"Sign Up"}
              type={"submit"}
              isLoading={isSubmitting}
            />
            <div>
              <span className={"text-[#00040e]"}>Already a User?</span>
              <span
                onClick={() => navigate("/login")}
                className={"text-primary font-bold cursor-pointer"}
              >
                {" "}
                Login
              </span>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default SignupPage;
