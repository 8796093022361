export enum FieldTypeEnum {
  // should be indexing by fieldType
  REMINDER_BEFORE_AFTER = "REMINDER_BEFORE_AFTER",
  NAME_FORMAT = "NAME_FORMAT",
  DATE_FORMAT = "DATE_FORMAT",
  UNTITLED = "UNTITLED", // STATIC
  ROOM_TYPE = "ROOM_TYPE", // DYNAMIC
  PROPERTY_TYPE = "PROPERTY_TYPE", // STATIC
  UNIT_SIZE = "UNIT_SIZE", // STATIC
  PROPERTY_AMENITIES = "PROPERTY_AMENITIES", // DYNAMIC
  UNIT_AMENITIES = "UNIT_AMENITIES", // DYNAMIC
  OWNER_TYPE = "OWNER_TYPE", // STATIC
  OWNERSHIP_TYPE = "OWNERSHIP_TYPE", // STATIC
  TENANCY_TYPE = "TENANCY_TYPE", // STATIC
  ANNUAL_FREQUENCY = "ANNUAL_FREQUENCY", // STATIC
  YEARLY_FREQUENCY = "YEARLY_FREQUENCY", // STATIC
  YEAR_FREQUENCY = "YEAR_FREQUENCY", // STATIC
  TENANCY_PAYMENT_TERMS = "PAYMENT_TERMS", // DYNAMIC
  OPENING_BALANCE_TYPE = "OPENING_BALANCE_TYPE", // DYNAMIC
  TENANCY_UTILITIES_CHARGE = "UTILITIES_CHARGE", // DYNAMIC
  CHARGE_TYPE = "CHARGE_TYPE", // STATIC
  TENANCY_OTHER_CHARGE = "TENANCY_OTHER_CHARGE", // STATIC
  BANK_LIST = "BANK_LIST", // STATIC
  TENANCY_ADVANCE_OPTION = "TENANCY_ADVANCE_OPTION", // STATIC
  FURNISHED_TYPE = "FURNISHED_TYPE", // DYNAMIC
  SELECT_FLOOR = "SELECT_FLOOR", // DYNAMIC
  DOCUMENT_TYPE = "DOCUMENT_TYPE", // DYNAMIC
  MODULE = "MODULE", // STATIC
  INVENTORY_ITEM = "INVENTORY_ITEM", // D
  INVENTORY_STATUS = "INVENTORY_STATUS", // STATIC
  INVENTORY_ITEM_CONDITION = "INVENTORY_ITEM_CONDITION", // D
  INVENTORY_UNIT = "INVENTORY_UNIT", // D
  PROFESSION = "PROFESSION", // D
  CANDIDATE_STATUS = "CANDIDATE_STATUS", // STATIC
  METER_TYPE = "METER_TYPE", // D
  TAX = "TAX", // D

  REPAIR_STATUS = "REPAIR_STATUS", // STATIC
  REPAIR_PRIORITY = "REPAIR_PRIORITY", // STATIC

  TASK_STATUS = "TASK_STATUS", // STATIC
  TASK_PRIORITY = "TASK_PRIORITY", // STATIC
  BOOKING_STATUS = "BOOKING_STATUS", // STATIC
  COUNTRY = "COUNTRY", // STATIC

  // loan & mortgage
  FINANCE_TYPE = "FINANCE_TYPE", // D
  MORTGAGE_TYPE = "MORTGAGE_TYPE", // D
  INTEREST_TYPE = "INTEREST_TYPE", // STATIC

  // invoice
  BILL_TYPE = "BILL_TYPE", // STATIC
  INVOICE_ITEM = "INVOICE_ITEM", // STATIC
  PAYMENT_MODE = "PAYMENT_MODE", // STATIC
}

export interface DynamicListInterface {
  _id: string;
  label: string;
  value: string;
  canRemove: boolean;
  isValueEditable: boolean; // can edit value?
  isSeed: boolean;
  isActive: boolean;
  isDeleted: boolean;
  description?: string;
  fieldType: FieldTypeEnum; // should be indexed by this field so searching would be faster
}

export interface DynamicConstantInterface {
  title: string;
  icon: string;
  children?: DynamicConstantInterface[];
  fieldType?: string;
  isDynamic?: boolean;
}
