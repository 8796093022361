import React, { useContext, useEffect } from "react";

import { PageTemplate } from "templates";
import { useParams } from "react-router-dom";
import { IconTypeEnum, ParamsStringEnum } from "interfaces";

import { FormWrapper, NameValueText } from "assets";
import { DocumentView, ImageViewCard, MyButton } from "components";
import { BankContext } from "context";
import { PageLinks } from "routes";
import { useDateHook } from "useHooks";
import { usePageQueryHooks } from "useHooks";

function UserDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { details, isDetailsLoading, getDetailsHandler } =
    useContext(BankContext);
  useEffect(() => {
    getDetailsHandler(params?.id);
  }, [params?.id]);
  const { getDateHandler } = useDateHook();
  return (
    <PageTemplate
      scrollAll
      title={"Bank"}
      titleRightChildren={
        <div>
          {details?._id && (
            <MyButton
              iconType={IconTypeEnum.EDIT}
              isOutline
              name={"Edit Details"}
              onClick={() =>
                navigateWithReturnUrlHandler(PageLinks.banks.edit(details?._id))
              }
            />
          )}
        </div>
      }
      breadCrumb={[
        {
          name: "Bank Details",
        },
      ]}
      backNavigation={{
        title: "Bank Details",
        path: PageLinks.banks.list,
      }}
    >
      <div className={"flex flex-col gap-10"}>
        <div className={`grid grid-cols-3  gap-5`}>
          <NameValueText
            value={details?.name || "-"}
            isLoading={isDetailsLoading}
            name={"Account Holder Name"}
          />
          <NameValueText
            value={details?.label || "-"}
            isLoading={isDetailsLoading}
            name={"Bank Name"}
          />
          <NameValueText
            value={details?.accountNumber || "-"}
            isLoading={isDetailsLoading}
            name={"Account Number"}
          />
          <NameValueText
            value={details?.branch || "-"}
            isLoading={isDetailsLoading}
            name={"Branch"}
          />
          <NameValueText
            value={details?.bankCode || "-"}
            isLoading={isDetailsLoading}
            name={"Bank Code"}
          />
          <NameValueText
            value={details?.ifscCode || "-"}
            isLoading={isDetailsLoading}
            name={"IFSC Code"}
          />{" "}
          <NameValueText
            value={details?.swiftCode || "-"}
            isLoading={isDetailsLoading}
            name={"Swift Code"}
          />
          <NameValueText
            value={details?.source || "-"}
            isLoading={isDetailsLoading}
            name={"Source"}
          />
          <NameValueText
            value={details?.currency || "-"}
            isLoading={isDetailsLoading}
            name={"Currency"}
          />
          <NameValueText
            value={details?.iban || "-"}
            isLoading={isDetailsLoading}
            name={"iBAN"}
          />
          <NameValueText
            value={details?.sortCode || "-"}
            isLoading={isDetailsLoading}
            name={"Sort Code"}
          />{" "}
          <NameValueText
            value={details?.sortCode || "-"}
            isLoading={isDetailsLoading}
            name={"Sort Code"}
          />{" "}
          <NameValueText
            value={details?.accountType || "-"}
            isLoading={isDetailsLoading}
            name={"Account Type"}
          />
        </div>
        <FormWrapper
          header={{
            title: {
              name: "Balance",
            },
          }}
        >
          <div className={"grid grid-cols-3 gap-5"}>
            <NameValueText
              name={"Closing Balance"}
              value={details?.closingBalance || "-"}
              isLoading={isDetailsLoading}
            />
            <NameValueText
              name={"Reconciled Item Count"}
              value={details?.reconciledItemCount || "-"}
              isLoading={isDetailsLoading}
            />
            <NameValueText
              name={"Reconciled Amount"}
              value={details?.reconciledAmount}
              isLoading={isDetailsLoading}
            />
          </div>
        </FormWrapper>
        <FormWrapper
          header={{
            title: {
              name: "True Layer",
            },
          }}
        >
          <div className={"grid grid-cols-3 gap-5"}>
            <NameValueText
              name={"Prover ID"}
              value={details?.trueLayer?.providerID || "-"}
              isLoading={isDetailsLoading}
            />
            <NameValueText
              name={"Balance"}
              value={details?.trueLayer?.balance || "-"}
              isLoading={isDetailsLoading}
            />
            <NameValueText
              name={"Last Synced"}
              value={
                details?.trueLayer?.lastSynced
                  ? getDateHandler({
                      date: details?.trueLayer?.lastSynced,
                      haveTime: true,
                    })
                  : "-"
              }
              isLoading={isDetailsLoading}
            />
          </div>
        </FormWrapper>
        <div className={`grid grid-cols-3  gap-5`}>
          {details?.logo && (
            <div>
              <span className={"input_label"}>Bank Logo</span>
              <div className={"h-[80px] w-[80px]"}>
                <ImageViewCard fileName={details?.logo} />
              </div>
            </div>
          )}
          {details?.qrImage && (
            <div>
              <span className={"input_label"}>QR Image</span>
              <div className={"h-[80px] w-[80px]"}>
                <ImageViewCard fileName={details?.qrImage} />
              </div>
            </div>
          )}
        </div>
        {details?.documents?.length > 0 && (
          <FormWrapper
            header={{
              title: {
                name: "Documents",
              },
            }}
          >
            <div className={"flex  gap-5 items-start"}>
              {details?.documents?.map((e, key) => {
                return <DocumentView key={key} file={e} isLoading={false} />;
              })}
            </div>
          </FormWrapper>
        )}
        <div className={`grid grid-cols-3  gap-5`}>
          <NameValueText
            value={details?.description || "-"}
            isLoading={isDetailsLoading}
            name={"Description"}
          />
        </div>
      </div>
    </PageTemplate>
  );
}

export default UserDetailsPage;
