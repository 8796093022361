import React from "react";

import { GeneratedLedgerInterface, TableColumnInterface } from "interfaces";

import { getCurrency, isDR } from "helpers";
import { useDateHook } from "useHooks";
import { PageLinks } from "routes";
import { usePageQueryHooks, usePaginationQueryHooks } from "useHooks";

function LedgerTableColumns(
  getQueryProps
): TableColumnInterface<GeneratedLedgerInterface>[] {
  const { calcSNHandler } = usePaginationQueryHooks();
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { getDateHandler } = useDateHook();

  return [
    {
      title: "SN",
      render(renderData, key) {
        return <span>{calcSNHandler(key)}.</span>;
      },
    },
    {
      title: "Date",
      render(renderData) {
        return <span>{getDateHandler({ date: renderData?.ledgerDate })}</span>;
      },
    },
    {
      title: "Ledger Account",
      render(renderData) {
        return <span>{renderData?.account?.name}</span>;
      },
    },
    {
      title: "Sub-Group",
      render(renderData) {
        return <span>{renderData?.account?.subgroup?.name}</span>;
      },
    },
    {
      title: `Description`,
      render(renderData) {
        return <span>{renderData?.account?.description}</span>;
      },
    },

    {
      title: `Dr`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span
              onClick={() =>
                navigateWithReturnUrlHandler(
                  PageLinks.ledger.listLedgerTransaction,
                  getQueryProps(renderData?.account?._id)
                )
              }
              className={`${
                isDR(renderData?.balance) && "font-bold hover:underline"
              }`}
            >
              {getCurrency()}{""}
              {isDR(renderData?.balance)
                ? Math.abs(renderData?.balance)
                : "0.00"}
            </span>
          </div>
        );
      },
    },
    {
      title: `Cr`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span
              onClick={() =>
                navigateWithReturnUrlHandler(
                  PageLinks.ledger.listLedgerTransaction,
                  getQueryProps(renderData?.account?._id)
                )
              }
              className={`${
                !isDR(renderData?.balance) && "font-bold hover:underline"
              }`}
            >
              {getCurrency()}{""}
              {!isDR(renderData?.balance)
                ? Math.abs(renderData?.balance)
                : "0.00"}
            </span>
          </div>
        );
      },
    },
  ];
}

export default LedgerTableColumns;
