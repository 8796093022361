import React, { useContext } from "react";
import { MySelectField, MyTextAreaInput, MyTextField } from "components";
import { FormWrapper } from "assets";
import { FieldTypeEnum } from "interfaces";
import { OptionsContext } from "context";
import { AddressForm } from "content";
import { useFormikContext } from "formik";
import { decodeDynamicFieldHandler } from "helpers";
function PropertyForm() {
  const { values } = useFormikContext<any>();
  const { fieldTypeOptions } = useContext(OptionsContext);

  return (
    <div className={"flex flex-col gap-10"}>
      <FormWrapper header={{ title: { name: "Property Details" } }}>
        <div className={`grid gap-10`}>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-10"}
          >
            <MySelectField
              autoFocus={true}
              isRequired={true}
              label={"Property Type"}
              name="propertyType"
              option={{
                selectOptions: decodeDynamicFieldHandler(
                  fieldTypeOptions[FieldTypeEnum?.PROPERTY_TYPE]
                ),
              }}
            />
          </div>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-10"}
          >
            <div className={"col-span-2 "}>
              <MyTextAreaInput label={"Description"} name="description" />
            </div>
          </div>
        </div>
      </FormWrapper>
      <AddressForm
        country={{
          value: values?.country,
        }}
      />
      <FormWrapper header={{ title: { name: "Area" } }}>
        <div
          className={`grid grid-cols-3 items-start md:grid-cols-2 sm:grid-cols-1 gap-10`}
        >
          <MyTextField
            isNumberOnly
            name="unitNumber"
            placeholder={"Eg. 12850"}
          />
          <MySelectField
            name="unitSize"
            option={{
              defaultLabel: "select unit",
              selectOptions: decodeDynamicFieldHandler(
                fieldTypeOptions[FieldTypeEnum?.UNIT_SIZE],
                "Sq. "
              ),
            }}
          />
          {/*</>*/}
          {/*</GroupInputComponent>*/}
          {/*</div>*/}
        </div>
      </FormWrapper>

      <FormWrapper header={{ title: { name: "More Details" } }}>
        <div className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1`}>
          <MyTextField
            dateValidation={{
              pastOnly: true,
            }}
            name={"constructionDate"}
            type={"date"}
            label={"Date of Completion/Date of Exchange"}
          />
        </div>
      </FormWrapper>
    </div>
  );
}

export default PropertyForm;
