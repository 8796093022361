import React, { useContext, useEffect } from "react";

import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { FormConcludeButtons } from "assets";
import { AppContext, SubLedgerNameContext } from "context";
import { PageTemplate } from "templates";
import { ParamsStringEnum } from "interfaces";

import { SubLedgerNameForm } from "../../components";
import { subLedgerNameFormikValues } from "../../helpers";
import { PageLinks } from "routes";

function EditSubLedgerNamePage() {
  const { details, getDetailsHandler, editDetailsHandler } =
    useContext(SubLedgerNameContext);
  const {
    handlers: { setLoading },
  } = useContext(AppContext);
  const params = useParams<ParamsStringEnum>();
  const isEdit = params.id;
  const navigate = useNavigate();
  useEffect(() => {
    if (isEdit) {
      (async () => {
        setLoading(true);
        await getDetailsHandler(isEdit);
        setLoading(false);
      })();
    }
  }, [isEdit]);

  return (
    <PageTemplate
      scrollAll
      title={"Sub-Ledger Name"}
      backNavigation={{
        title: `${isEdit ? "Edit" : "Add"} Sub-ledger name`,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={subLedgerNameFormikValues?.initialValues(
          isEdit && details
        )}
        validationSchema={subLedgerNameFormikValues?.validationSchema}
        onSubmit={async (values, formikHelpers: any) => {
          await editDetailsHandler(values, formikHelpers, {
            onSuccess: async (payload) => {
              navigate(PageLinks.subLedgerName.list);
            },
          });
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={"flex flex-col gap-5"}>
              <SubLedgerNameForm />
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                  handler: () => {
                    navigate(-1);
                  },
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default EditSubLedgerNamePage;
