import React, { useContext, useEffect } from "react";

import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

import { PageLinks } from "routes";
import { MyButton } from "components";
import { getIconHelper } from "helpers";
import {
  IconTypeEnum,
  PackagePurchaseType,
  SpaceAccessListEnum,
} from "interfaces";

import { SubscriptionContext } from "../context";
import { useAuthorizationHook } from "../../../useHooks";

function ActiveSubscriptionTab() {
  const navigate = useNavigate();
  const InfoIcon = getIconHelper(IconTypeEnum.INFO);
  const AlertIcon = getIconHelper(IconTypeEnum.ALERT);
  const {
    activeSubscription,
    isDetailsLoading,
    contextHandlers: { getActiveSubscriptionHandler },
  } = useContext(SubscriptionContext);
  useEffect(() => {
    getActiveSubscriptionHandler();
  }, []);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canPurchasePlan = checkCanAccessHandler([
    SpaceAccessListEnum.BILLING_PURCHASE_SUBSCRIPTION,
  ]);
  if (
    !activeSubscription?.active &&
    activeSubscription?.lastSubscribed &&
    !isDetailsLoading
  ) {
    return (
      <div
        className={
          " flex flex-col gap-2  text-center items-center justify-center"
        }
      >
        <div className={"w-[400px] flex flex-col items-center gap-5"}>
          <AlertIcon className={"text-[#FF1A1A] text-[60px]"} />
          <div className={"flex flex-col text-[22px] font-bold"}>
            <b>Oh-no! Your</b>
            <b>Subscription Has Expired</b>
          </div>
          <p className={"text-[14px]"}>
            Upgrade to a paid plan by simply visiting the dashboard on your
            desktop
          </p>
          <div>
            {canPurchasePlan && (
              <MyButton
                name={"Continue"}
                onClick={() =>
                  navigate(
                    PageLinks.subscription.checkout(
                      PackagePurchaseType.SUBSCRIPTION
                    )
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
    );
  }
  if (
    !activeSubscription?.active &&
    !activeSubscription?.lastSubscribed &&
    !isDetailsLoading
  ) {
    return (
      <div
        className={
          "flex items-center justify-between gap-5 border-2 border-orange-500 bg-orange-50 text-orange-500 rounded-md p-5"
        }
      >
        <div className={"flex items-center gap-2"}>
          <InfoIcon className={"text-[22px]"} />
          <b>Subscribe to our packages</b>
        </div>
        <div>
          {canPurchasePlan && (
            <MyButton
              className={"rounded-md"}
              onClick={() =>
                navigate(
                  PageLinks.subscription.checkout(
                    PackagePurchaseType.SUBSCRIPTION
                  )
                )
              }
              name={"Subscribe Now"}
            />
          )}
        </div>
      </div>
    );
  }
  return (
    <div className={"flex flex-col gap-8 border-b-2 pb-5 mt-5"}>
      {!isDetailsLoading && activeSubscription?.numberOfDaysRemains < 15 && (
        <div
          className={
            "flex items-center gap-5 border-2 border-orange-500 bg-orange-50 text-orange-500 rounded-md p-5"
          }
        >
          <InfoIcon className={"text-[22px]"} />
          <b>
            Your account expires in {activeSubscription?.numberOfDaysRemains}{" "}
            days
          </b>
        </div>
      )}
      <div className={"flex items-center justify-between"}>
        <div className={"flex items-start gap-10"}>
          <div className={"flex flex-col"}>
            <b>Subscription Plan:</b>
            <span className={"text-[12px] text-gray-500"}>Current Plan</span>
          </div>
          <>
            {isDetailsLoading ? (
              <Skeleton className={"w-20"} />
            ) : (
              <b className={"text-tBlue"}>
                {activeSubscription?.active?.subscribedPackage?.name}
              </b>
            )}
          </>
        </div>
        <div>
          {canPurchasePlan && (
            <MyButton
              colorType={"white"}
              className={"rounded-md bg-gray-50"}
              onClick={() =>
                navigate(
                  PageLinks.subscription.checkout(
                    PackagePurchaseType.SUBSCRIPTION
                  )
                )
              }
              name={"Upgrade Subscription"}
            />
          )}
        </div>
      </div>
      <div className={"flex items-center justify-between"}>
        <div className={"flex items-start gap-10"}>
          <div className={"flex flex-col"}>
            <b>Number of Properties:</b>
            <span className={"text-[12px] text-gray-500"}>
              Total number of properties
            </span>
          </div>
          <>
            {isDetailsLoading ? (
              <Skeleton className={"w-20"} />
            ) : (
              <b className={"text-tBlue"}>
                {activeSubscription?.active?.purchasedProperty || 0} Properties
              </b>
            )}
          </>
        </div>
        <div>
          {!activeSubscription?.active?.subscribedPackage?.isFreeForever &&
            canPurchasePlan && (
              <MyButton
                colorType={"white"}
                // size={"md"}
                className={"rounded-md bg-gray-50"}
                name={"Upgrade Properties"}
                onClick={() =>
                  navigate(
                    PageLinks.subscription.checkout(
                      PackagePurchaseType.PROPERTY
                    )
                  )
                }
              />
            )}
        </div>
      </div>
    </div>
  );
}

export default ActiveSubscriptionTab;
