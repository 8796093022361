import {
  AccessRoleDataFormatInterface,
  SpaceAccessListEnum,
} from "../interfaces";
const authorizationConfig: AccessRoleDataFormatInterface = {
  label: "Authorization",
  name: "AUTH",
  access: [
    { label: "Add Role", name: SpaceAccessListEnum.AUTH_ADD_ROLE },
    { label: "Edit Role", name: SpaceAccessListEnum.AUTH_EDIT_ROLE },
    { label: "Delete Role", name: SpaceAccessListEnum.AUTH_DEL_ROLE },
    { label: "Read Role", name: SpaceAccessListEnum.AUTH_READ_ROLE },
    { label: "Add User", name: SpaceAccessListEnum.AUTH_ADD_USER },
    { label: "Edit User", name: SpaceAccessListEnum.AUTH_EDIT_USER },
    { label: "Delete User", name: SpaceAccessListEnum.AUTH_DEL_USER },
    { label: "Read User", name: SpaceAccessListEnum.AUTH_READ_USER },
    {
      label: "Access Supplier",
      name: SpaceAccessListEnum.AUTH_ACCESS_SUPPLIER,
    },
  ],
};
const rentalConfig = {
  label: "Rental",
  name: "RENTAL",
  access: [
    { label: "Add Property", name: SpaceAccessListEnum.RENTAL_ADD_PROPERTY },
    { label: "Edit Property", name: SpaceAccessListEnum.RENTAL_EDIT_PROPERTY },
    { label: "Delete Property", name: SpaceAccessListEnum.RENTAL_DEL_PROPERTY },
    { label: "Read Property", name: SpaceAccessListEnum.RENTAL_READ_PROPERTY },
    { label: "Add Unit", name: SpaceAccessListEnum.RENTAL_ADD_UNIT },
    { label: "Edit Unit", name: SpaceAccessListEnum.RENTAL_EDIT_UNIT },
    { label: "Delete Unit", name: SpaceAccessListEnum.RENTAL_DEL_UNIT },
    { label: "Read Unit", name: SpaceAccessListEnum.RENTAL_READ_UNIT },
  ],
};
const tenancyConfig = {
  label: "Tenancy",
  name: "TENANCY",
  access: [
    { label: "Add Tenancy", name: SpaceAccessListEnum.TENANCY_ADD_TENANCY },
    { label: "Edit Tenancy", name: SpaceAccessListEnum.TENANCY_EDIT_TENANCY },
    { label: "Delete Tenancy", name: SpaceAccessListEnum.TENANCY_DEL_TENANCY },
    { label: "Read Tenancy", name: SpaceAccessListEnum.TENANCY_READ_TENANCY },
  ],
};
const bookingConfig = {
  label: "Booking",
  name: "BOOKING",
  access: [
    { label: "Add Booking", name: SpaceAccessListEnum.BOOKING_ADD_BOOKING },
    { label: "Edit Booking", name: SpaceAccessListEnum.BOOKING_EDIT_BOOKING },
    { label: "Delete Booking", name: SpaceAccessListEnum.BOOKING_DEL_BOOKING },
    { label: "Read Booking", name: SpaceAccessListEnum.BOOKING_READ_BOOKING },
  ],
};
const financeConfig = {
  label: "Finance",
  name: "FINANCE",
  access: [
    { label: "Add Invoice", name: SpaceAccessListEnum.FINANCE_ADD_INVOICE },
    { label: "Edit Invoice", name: SpaceAccessListEnum.FINANCE_EDIT_INVOICE },
    { label: "Delete Invoice", name: SpaceAccessListEnum.FINANCE_DEL_INVOICE },
    { label: "Read Invoice", name: SpaceAccessListEnum.FINANCE_READ_INVOICE },
    { label: "Add Bank", name: SpaceAccessListEnum.FINANCE_ADD_BANK },
    { label: "Edit Bank", name: SpaceAccessListEnum.FINANCE_EDIT_BANK },
    { label: "Delete Bank", name: SpaceAccessListEnum.FINANCE_DEL_BANK },
    { label: "Read Bank", name: SpaceAccessListEnum.FINANCE_READ_BANK },
    { label: "Link Bank", name: SpaceAccessListEnum.FINANCE_LINK_BANK },
    {
      label: "Reconciliation",
      name: SpaceAccessListEnum.FINANCE_RECONCILIATION,
    },
    {
      label: "Read Reconcile Statement",
      name: SpaceAccessListEnum.FINANCE_READ_RECONCILE_STATEMENT,
    },
    {
      label: "Add Loan and Mortgage",
      name: SpaceAccessListEnum.FINANCE_ADD_LOAN_AND_MORTGAGE,
    },
    {
      label: "Edit Loan and Mortgage",
      name: SpaceAccessListEnum.FINANCE_EDIT_LOAN_AND_MORTGAGE,
    },
    {
      label: "Delete Loan and Mortgage",
      name: SpaceAccessListEnum.FINANCE_DEL_LOAN_AND_MORTGAGE,
    },
    {
      label: "Read Loan and Mortgage",
      name: SpaceAccessListEnum.FINANCE_READ_LOAN_AND_MORTGAGE,
    },
  ],
};
const inventoryConfig = {
  label: "Inventory",
  name: "INVENTORY",
  access: [
    {
      label: "Add Inventory",
      name: SpaceAccessListEnum.INVENTORY_ADD_INVENTORY,
    },
    {
      label: "Edit Inventory",
      name: SpaceAccessListEnum.INVENTORY_EDIT_INVENTORY,
    },
    {
      label: "Delete Inventory",
      name: SpaceAccessListEnum.INVENTORY_DEL_INVENTORY,
    },
    {
      label: "Read Inventory",
      name: SpaceAccessListEnum.INVENTORY_READ_INVENTORY,
    },
  ],
};
const eventConfig = {
  label: "Event",
  name: "EVENT",
  access: [
    { label: "Add Event", name: SpaceAccessListEnum.EVENT_ADD_EVENT },
    { label: "Edit Event", name: SpaceAccessListEnum.EVENT_EDIT_EVENT },
    { label: "Delete Event", name: SpaceAccessListEnum.EVENT_DEL_EVENT },
    { label: "Read Event", name: SpaceAccessListEnum.EVENT_READ_EVENT },
  ],
};
const repairAndMaintenanceConfig = {
  label: "Repair and Maintenance",
  name: "REPAIR_AND_MAINTENANCE",
  access: [
    {
      label: "Add Repair and Maintenance",
      name: SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_ADD_REPAIR_AND_MAINTENANCE,
    },
    {
      label: "Edit Repair and Maintenance",
      name: SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_EDIT_REPAIR_AND_MAINTENANCE,
    },
    {
      label: "Delete Repair and Maintenance",
      name: SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_DEL_REPAIR_AND_MAINTENANCE,
    },
    {
      label: "Read Repair and Maintenance",
      name: SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_READ_REPAIR_AND_MAINTENANCE,
    },
  ],
};
const taskConfig = {
  label: "Task",
  name: "TASK",
  access: [
    { label: "Add Task", name: SpaceAccessListEnum.TASK_ADD_TASK },
    { label: "Edit Task", name: SpaceAccessListEnum.TASK_EDIT_TASK },
    { label: "Delete Task", name: SpaceAccessListEnum.TASK_DEL_TASK },
    { label: "Read Task", name: SpaceAccessListEnum.TASK_READ_TASK },
  ],
};
const candidateConfig = {
  label: "Candidate",
  name: "CANDIDATE",
  access: [
    {
      label: "Add Candidate",
      name: SpaceAccessListEnum.CANDIDATE_ADD_CANDIDATE,
    },
    {
      label: "Edit Candidate",
      name: SpaceAccessListEnum.CANDIDATE_EDIT_CANDIDATE,
    },
    {
      label: "Delete Candidate",
      name: SpaceAccessListEnum.CANDIDATE_DEL_CANDIDATE,
    },
    {
      label: "Read Candidate",
      name: SpaceAccessListEnum.CANDIDATE_READ_CANDIDATE,
    },
  ],
};
const documentConfig = {
  label: "Document",
  name: "DOCUMENT",
  access: [
    { label: "Add Document", name: SpaceAccessListEnum.DOCUMENT_ADD_DOCUMENT },
    {
      label: "Edit Document",
      name: SpaceAccessListEnum.DOCUMENT_EDIT_DOCUMENT,
    },
    {
      label: "Delete Document",
      name: SpaceAccessListEnum.DOCUMENT_DEL_DOCUMENT,
    },
    {
      label: "Read Document",
      name: SpaceAccessListEnum.DOCUMENT_READ_DOCUMENT,
    },
  ],
};
const toolsConfig = {
  label: "Tools",
  name: "TOOLS",
  access: [
    {
      label: "Add Meter Reading",
      name: SpaceAccessListEnum.TOOLS_ADD_METER_READING,
    },
    {
      label: "Edit Meter Reading",
      name: SpaceAccessListEnum.TOOLS_EDIT_METER_READING,
    },
    {
      label: "Delete Meter Reading",
      name: SpaceAccessListEnum.TOOLS_DEL_METER_READING,
    },
    {
      label: "Read Meter Reading",
      name: SpaceAccessListEnum.TOOLS_READ_METER_READING,
    },
    { label: "Add Trip", name: SpaceAccessListEnum.TOOLS_ADD_TRIP },
    { label: "Edit Trip", name: SpaceAccessListEnum.TOOLS_EDIT_TRIP },
    { label: "Delete Trip", name: SpaceAccessListEnum.TOOLS_DEL_TRIP },
    { label: "Read Trip", name: SpaceAccessListEnum.TOOLS_READ_TRIP },
  ],
};
const miscConfig = {
  label: "Misc",
  name: "MISC",
  access: [
    {
      label: "Access Dashboard",
      name: SpaceAccessListEnum.MISC_ACCESS_DASHBOARD,
    },
    // {
    //   label: "Allow Mobile Login",
    //   name: SpaceAccessListEnum.MISC_ALLOW_MOBILE_LOGIN,
    // },
    {
      label: "Access Report Page",
      name: SpaceAccessListEnum.MISC_ACCESS_REPORT_PAGE,
    },
    { label: "Access Trash", name: SpaceAccessListEnum.MISC_ACCESS_TRASH },
    { label: "Access Message", name: SpaceAccessListEnum.MISC_ACCESS_MESSAGE },
  ],
};
const configurationConfig = {
  label: "Configuration",
  name: "CONFIGURATION",
  access: [
    {
      label: "Add Administrative Setup",
      name: SpaceAccessListEnum.CONFIGURATION_ADD_ADMINISTRATIVE_SETUP,
    },
    {
      label: "Edit Administrative Setup",
      name: SpaceAccessListEnum.CONFIGURATION_EDIT_ADMINISTRATIVE_SETUP,
    },
    {
      label: "Delete Administrative Setup",
      name: SpaceAccessListEnum.CONFIGURATION_DEL_ADMINISTRATIVE_SETUP,
    },
    {
      label: "Manage Notification",
      name: SpaceAccessListEnum.CONFIGURATION_MANAGE_NOTIFICATION,
    },
    {
      label: "Manage Settings",
      name: SpaceAccessListEnum.CONFIGURATION_MANAGE_SETTINGS,
    },

    {
      label: "Access Retain Earning",
      name: SpaceAccessListEnum.CONFIGURATION_ACCESS_RETAIN_EARNING,
    },
    {
      label: "Manage True Layer Settings",
      name: SpaceAccessListEnum.CONFIGURATION_MANAGE_TRUE_LAYER_SETTINGS,
    },
    {
      label: "Manage Twilio Settings",
      name: SpaceAccessListEnum.CONFIGURATION_MANAGE_TWILIO_SETTINGS,
    },
    {
      label: "Manage Brevo Settings",
      name: SpaceAccessListEnum.CONFIGURATION_MANAGE_BREVO_SETTINGS,
    },
  ],
};
const billingConfig = {
  label: "Billing",
  name: "BILLING",
  access: [
    {
      label: "View Subscription",
      name: SpaceAccessListEnum.BILLING_VIEW_SUBSCRIPTION,
    },
    {
      label: "Purchase Subscription",
      name: SpaceAccessListEnum.BILLING_PURCHASE_SUBSCRIPTION,
    },
  ],
};
const accountConfig = {
  label: "Account",
  name: "ACCOUNT",
  access: [
    {
      label: "Access Sub Ledger",
      name: SpaceAccessListEnum.ACCOUNT_ACCESS_SUB_LEDGER,
    },
    {
      label: "Add Ledger Name",
      name: SpaceAccessListEnum.ACCOUNT_ADD_LEDGER_NAME,
    },
    {
      label: "Edit Ledger Name",
      name: SpaceAccessListEnum.ACCOUNT_EDIT_LEDGER_NAME,
    },
    {
      label: "Delete Ledger Name",
      name: SpaceAccessListEnum.ACCOUNT_DEL_LEDGER_NAME,
    },
    {
      label: "Read Ledger Name",
      name: SpaceAccessListEnum.ACCOUNT_READ_LEDGER_NAME,
    },
    { label: "Add Journal", name: SpaceAccessListEnum.ACCOUNT_ADD_JOURNAL },
    { label: "Edit Journal", name: SpaceAccessListEnum.ACCOUNT_EDIT_JOURNAL },
    { label: "Delete Journal", name: SpaceAccessListEnum.ACCOUNT_DEL_JOURNAL },
    { label: "Read Journal", name: SpaceAccessListEnum.ACCOUNT_READ_JOURNAL },
    {
      label: "Access Trial Balance",
      name: SpaceAccessListEnum.ACCOUNT_ACCESS_TRIAL_BALANCE,
    },
    { label: "Access Ledger", name: SpaceAccessListEnum.ACCOUNT_ACCESS_LEDGER },
    {
      label: "Access Ledger Transaction",
      name: SpaceAccessListEnum.ACCOUNT_ACCESS_LEDGER_TRANSACTION,
    },
    {
      label: "Access Income Statement",
      name: SpaceAccessListEnum.ACCOUNT_ACCESS_INCOME_STATEMENT,
    },
    {
      label: "Access Balance Sheet",
      name: SpaceAccessListEnum.ACCOUNT_ACCESS_BALANCE_SHEET,
    },
  ],
};

export const UserPermissionData: AccessRoleDataFormatInterface[] = [
  authorizationConfig,
  rentalConfig,
  tenancyConfig,
  bookingConfig,
  financeConfig,
  inventoryConfig,
  eventConfig,
  repairAndMaintenanceConfig,
  taskConfig,
  candidateConfig,
  documentConfig,
  toolsConfig,
  miscConfig,
  configurationConfig,
  billingConfig,
  accountConfig,
];
