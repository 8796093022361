import React from "react";
import { Route } from "react-router-dom";
import { UnitPage, UnitDetailsPage, NewUnitPage } from "./pages";
import {
  UnitListingTab,
  UnitDocumentTab,
  UnitAmenitiesTab,
  UnitDetailTab,
  UnitEditDocuments,
  InventoryTab,
  LoanTab,
} from "./tabs";
import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import { EditAmenitiesModal, EditListingModal } from "./modal";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="rental/">
      {/*unit*/}
      <Route
        path={`unit`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.RENTAL_READ_UNIT]}
          >
            <UnitPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`unit/new`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.RENTAL_ADD_UNIT]}
          >
            <NewUnitPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`unit/edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.RENTAL_EDIT_UNIT]}
          >
            <NewUnitPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`unit/details/:${ParamsStringEnum?.PROPERTY_ID}/:${ParamsStringEnum?.UNIT_ID}`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.RENTAL_READ_UNIT]}
          >
            <UnitDetailsPage />
          </AccessVerifier>
        }
      >
        <Route
          path={``}
          element={
            <AccessVerifier
              isForPage={true}
              accessKeys={[SpaceAccessListEnum.RENTAL_READ_UNIT]}
            >
              <UnitDetailTab />
            </AccessVerifier>
          }
        ></Route>
        <Route
          path={`amenities`}
          element={
            <AccessVerifier
              isForPage={true}
              accessKeys={[SpaceAccessListEnum.RENTAL_READ_UNIT]}
            >
              <UnitAmenitiesTab />
            </AccessVerifier>
          }
        >
          <Route
            path={`modal/amenities/edit`}
            element={
              <AccessVerifier
                isForPage={true}
                accessKeys={[SpaceAccessListEnum.RENTAL_READ_UNIT]}
              >
                <EditAmenitiesModal />
              </AccessVerifier>
            }
          />
        </Route>
        <Route
          path={`listing`}
          element={
            <AccessVerifier
              isForPage={true}
              accessKeys={[SpaceAccessListEnum.RENTAL_READ_UNIT]}
            >
              <UnitListingTab />
            </AccessVerifier>
          }
        >
          <Route
            path={`modal/listing/edit`}
            element={
              <AccessVerifier
                isForPage={true}
                accessKeys={[SpaceAccessListEnum.RENTAL_READ_UNIT]}
              >
                <EditListingModal />
              </AccessVerifier>
            }
          />
        </Route>
        <Route path={`documents/*`}>
          <Route
            path={``}
            element={
              <AccessVerifier
                isForPage={true}
                accessKeys={[SpaceAccessListEnum.RENTAL_ADD_UNIT]}
              >
                <UnitDocumentTab />
              </AccessVerifier>
            }
          />
          <Route
            path={`editDocuments`}
            element={
              <AccessVerifier
                isForPage={true}
                accessKeys={[SpaceAccessListEnum.RENTAL_EDIT_UNIT]}
              >
                <UnitEditDocuments />
              </AccessVerifier>
            }
          />
        </Route>
        <Route
          path={"inventory"}
          element={
            <AccessVerifier
              isForPage={true}
              accessKeys={[SpaceAccessListEnum.INVENTORY_READ_INVENTORY]}
            >
              <InventoryTab />
            </AccessVerifier>
          }
        />
        <Route
          path={"loan"}
          element={
            <AccessVerifier
              isForPage={true}
              accessKeys={[SpaceAccessListEnum.FINANCE_READ_LOAN_AND_MORTGAGE]}
            >
              <LoanTab />
            </AccessVerifier>
          }
        />
      </Route>
    </Route>
  );
}

export default Index;
