import React, { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { FormConcludeButtons } from "assets";
import { PageTemplate } from "templates";

import { newTenancyFormikValues } from "../helpers";
import { TenancyForm } from "../components";
import { AppContext, OptionsContext, TenancyDataContext } from "context";
import {
  FieldTypeEnum,
  ParamsStringEnum,
  QueryStringEnum,
  UserRoleEnum,
} from "interfaces";
import { usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function NewTenancyPage() {
  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const returnActiveTab = query?.get(QueryStringEnum.ACTIVE_TAB);
  const params = useParams<ParamsStringEnum>();
  const editID = params?.id;
  const { propertyID, returnURL, navigateBackHandler } = usePageQueryHooks();
  const { details, editDetailsHandler, getDetailsHandler } =
    useContext(TenancyDataContext);
  const { handlers } = useContext(AppContext);
  const {
    getUserOptionsHandler,
    getPropertyOptionsHandler,
    getOptionsHandler,
    getBankOptionsHandler,
    getUnitOptionsHandler,
  } = useContext(OptionsContext);
  useEffect(() => {
    (async () => {
      handlers?.setLoading(true);
      setEnableReinitialize(true);
      await Promise.all([
        editID && getDetailsHandler(editID),
        getPropertyOptionsHandler(),
        getBankOptionsHandler(),
        getUserOptionsHandler(UserRoleEnum.TENANT),
        getOptionsHandler(FieldTypeEnum.TENANCY_TYPE),
        getOptionsHandler(FieldTypeEnum.YEARLY_FREQUENCY),
        getOptionsHandler(FieldTypeEnum.YEAR_FREQUENCY),
        getOptionsHandler(FieldTypeEnum.ANNUAL_FREQUENCY),
        getOptionsHandler(FieldTypeEnum.TENANCY_PAYMENT_TERMS),
        getOptionsHandler(FieldTypeEnum.OPENING_BALANCE_TYPE),
        getOptionsHandler(FieldTypeEnum.TENANCY_UTILITIES_CHARGE),
        getOptionsHandler(FieldTypeEnum.TAX),
        getOptionsHandler(FieldTypeEnum.CHARGE_TYPE),
        getOptionsHandler(FieldTypeEnum.TENANCY_OTHER_CHARGE),
      ]);
      setEnableReinitialize(false);
      handlers?.setLoading(false);
    })();
  }, [editID]);
  useEffect(() => {
    if (editID && details && details?.property?._id) {
      getUnitOptionsHandler(details?.property?._id);
      if (!details?.isDraft) {
        handlers?.setError(true, "Only Draft tenancy can be modified");
        if (returnURL) {
          navigateBackHandler(returnURL);
        } else {
          navigate(PageLinks.tenancy.list);
        }
      }
    }
  }, [editID, details]);

  const {
    handlers: { setLoading },
  } = useContext(AppContext);

  const submitHandler = async (values, formikHelpers) => {
    setLoading(true);
    await editDetailsHandler(values, formikHelpers, {
      onSuccess: async (payload) => {
        setLoading(false);
        if (returnURL) {
          navigate({
            pathname: returnURL,
            search: `${QueryStringEnum.ACTIVE_TAB}=${returnActiveTab}`,
          });
        } else {
          navigate(PageLinks.tenancy.list);
        }
      },
      onError(error?: any) {
        setLoading(false);
      },
    });
  };
  return (
    <PageTemplate
      scrollAll
      title={"Tenancies"}
      breadCrumb={[
        {
          name: "Tenancies",
        },
        {
          name: editID ? "Edit Tenancy" : "Add Tenancy",
        },
      ]}
      backNavigation={{
        title: editID ? "Edit Tenancy" : "Add Tenancy",
      }}
    >
      <Formik
        enableReinitialize={enableReinitialize}
        initialValues={newTenancyFormikValues?.initialValues(
          editID
            ? details
            : propertyID
            ? {
                property: {
                  _id: propertyID,
                },
              }
            : {}
        )}
        validationSchema={newTenancyFormikValues?.validationSchema}
        onSubmit={submitHandler}
      >
        {({ handleSubmit, setFieldValue }) => {
          return (
            <Form className={"flex flex-col gap-5"}>
              <TenancyForm />
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  notSubmit: true,
                  handler() {
                    setFieldValue("isDraft", false);
                    handleSubmit();
                  },
                }}
                draftButton={{
                  title: "Draft",
                  handler() {
                    setFieldValue("isDraft", true);
                    handleSubmit();
                  },
                }}
                cancelButton={{
                  title: "Cancel",
                  handler: () => {
                    navigate(-1);
                  },
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default NewTenancyPage;
