import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthorizationHook, useDateHook, usePageQueryHooks } from "useHooks";
import { EmptyMessageComponent, MyButton } from "components";
import { PageTemplate } from "templates";
import { IconTypeEnum, SpaceAccessListEnum } from "interfaces";
import { TableFilterCard } from "content";
import { EventContext } from "context";
import { PageLinks } from "routes";
import { LeftRightHeader } from "assets";
import { CalendarCard, EventCard } from "../components";

function EventPage() {
  const { allData, isLoading, getAllDataHandlers } = useContext(EventContext);
  const { getDateHandler } = useDateHook();

  const navigate = useNavigate();
  let { propertyID, date } = usePageQueryHooks();
  date = getDateHandler({ date: date || new Date() });
  const pageQuery = {
    property: propertyID,
    startDate: getDateHandler({ date: date }),
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [propertyID, date]);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAdd = checkCanAccessHandler([SpaceAccessListEnum.EVENT_ADD_EVENT]);
  return (
    <PageTemplate
      title={"Events"}
      breadCrumb={[
        {
          name: "Events",
        },
      ]}
    >
      <div className={"flex flex-col gap-5 overflow-scroll hideScroll"}>
        <LeftRightHeader
          leftChildren={<TableFilterCard haveProperty />}
          rightChildren={
            canAdd && (
              <MyButton
                iconType={IconTypeEnum.ADD}
                name={"Add Event"}
                onClick={() => navigate(PageLinks.events.new)}
              />
            )
          }
        />
        <hr />
        <div className={"grid grid-cols-2 gap-5 overflow-scroll hideScroll"}>
          <CalendarCard />
          <div
            className={
              "flex flex-col gap-2 border rounded-md p-5 overflow-scroll hideScroll"
            }
          >
            <div>
              <b>{date}</b>
            </div>
            <hr />
            <div className={"flex flex-col gap-2 overflow-y-scroll"}>
              {isLoading ? (
                new Array(4).fill("")?.map((e, key) => {
                  return <EventCard isLoading key={key} />;
                })
              ) : allData?.length < 1 ? (
                <div className={"mt-5"}>
                  <EmptyMessageComponent message={"No event scheduled"} />
                </div>
              ) : (
                <>
                  {allData?.map((e) => {
                    return (
                      <EventCard key={e?._id} event={e} pageQuery={pageQuery} />
                    );
                  })}{" "}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

export default EventPage;
