import React, { useContext, useEffect, useState } from "react";
import { OptionsContext } from "context";
import { IconTypeEnum, SpaceAccessListEnum } from "interfaces";
import { MySelectField, MyUploadDocument } from "components";
import { decodeDocumentOptionsHandler, getIconHelper } from "helpers";
import { useAuthorizationHook } from "useHooks";
import { useFormikContext } from "formik";

function SelectExistingDocumentForm() {
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canReadDocument = checkCanAccessHandler([
    SpaceAccessListEnum.DOCUMENT_READ_DOCUMENT,
  ]);
  const { getDocumentOptionsHandler, documentOptions } =
    useContext(OptionsContext);
  const { setFieldValue, values } = useFormikContext<any>();
  const isExistingDocument = values?.isExistingDocument;
  const setExistingDocument = (value: boolean) => {
    setFieldValue("isExistingDocument", value);
  };
  useEffect(() => {
    (async () => {
      await Promise.all([canReadDocument && getDocumentOptionsHandler()]);
    })();
  }, []);
  return (
    <div className={"flex flex-col gap-5"}>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <ToggleComponent
          isExistingDocument={isExistingDocument}
          setExistingDocument={setExistingDocument}
        />
      </div>
      <div className={"grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1"}>
        {!isExistingDocument ? (
          <div className={"col-span-2"}>
            <MyUploadDocument
              fileContainerStyle={"grid grid-cols-2"}
              name={"documents"}
            />
          </div>
        ) : (
          <div className={"col-span-2"}>
            <MySelectField
              option={{
                selectOptions: decodeDocumentOptionsHandler(documentOptions),
                onChangeCallback(currentValue?: any) {
                  let documents = documentOptions?.find(
                    (e) => e?._id === currentValue?.value
                  );
                  console.log(documents, currentValue);
                  setFieldValue("existingDocuments", documents?.documents);
                },
              }}
              label={"Select Files"}
              name={"documentReferenceID"}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const ToggleComponent = ({ isExistingDocument, setExistingDocument }) => {
  const { checkCanAccessHandler } = useAuthorizationHook();
  const AttachmentIcon = getIconHelper(IconTypeEnum.ATTACHMENT);
  const canEditDocument = checkCanAccessHandler([
    SpaceAccessListEnum.DOCUMENT_EDIT_DOCUMENT,
  ]);
  const dataStatus = [
    {
      title: "New Document",
      status: !isExistingDocument,
      handler() {
        setExistingDocument(false);
      },
    },
    canEditDocument && {
      title: "Existing Document",
      status: isExistingDocument,
      handler() {
        setExistingDocument(true);
      },
    },
  ];
  return (
    <div className={"grid grid-cols-2 gap-1 bg-tGray rounded-full p-1"}>
      {dataStatus?.map((e, key) => {
        return (
          <div
            key={key}
            onClick={() => {
              e?.handler();
            }}
            className={`flex items-center justify-center gap-2 cursor-pointer min-w-[150px] min-h-[35px]  px-5 rounded-full text-[16px] ${
              e?.status ? "bg-white text-black" : "text-gray-500 "
            }`}
          >
            <AttachmentIcon
              className={`text-[14px] ${
                e?.status ? "fill-black" : "text-gray-200"
              }`}
            />
            <span className={"text-[14px]"}>{e?.title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default SelectExistingDocumentForm;
