import { AuthTokenENUM } from "interfaces";

export const getAuthToken = (type: AuthTokenENUM) => {
  let token = localStorage.getItem(type);

  return token;
};

export const clearAuthToken = (type: AuthTokenENUM) => {
  localStorage.removeItem(type);
};

export const setAuthToken = (token, type: AuthTokenENUM) => {
  localStorage.setItem(type, `Bearer ${token}`);
};

export const setAccessTokenAttempted = (flag: boolean) => {
  localStorage.setItem("accessTokenAttempted", `${flag}`);
};
