import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  SpaceAccessListEnum,
  PrivilegeInterface,
  TableColumnInterface,
} from "interfaces";
import { MyMoreOptionButton } from "components";

import { PrivilegeContext } from "context";
import { PageLinks } from "routes";
import { useAuthorizationHook, usePaginationQueryHooks } from "useHooks";
import { decodeRoleNameHandler } from "../../../../helpers";

function RolesPermissionTableColumns(): TableColumnInterface<PrivilegeInterface>[] {
  const navigate = useNavigate();
  const { deleteHandler, getAllDataHandlers, archiveHandler } =
    useContext(PrivilegeContext);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([SpaceAccessListEnum.AUTH_EDIT_ROLE]);
  const canDelete = checkCanAccessHandler([SpaceAccessListEnum.AUTH_DEL_ROLE]);
  const { calcSNHandler } = usePaginationQueryHooks();
  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },
    {
      title: "Name",
      render(renderData) {
        return <span className={""}>{renderData?.name}</span>;
      },
    },
    {
      title: "Roles",
      render(renderData) {
        return (
          <span className={""}>{decodeRoleNameHandler(renderData?.role)}</span>
        );
      },
    },
    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                renderData?._id && {
                  name: "View ",
                  handler: () => {
                    navigate(PageLinks.role.details(renderData?._id));
                  },
                },
                canEdit &&
                  renderData?.canEdit &&
                  !renderData?.isArchived && {
                    name: "Edit",
                    handler: () => {
                      navigate(PageLinks.role.edit(renderData?._id));
                    },
                  },
                canDelete &&
                  renderData?.canDelete && {
                    name: "Archive",
                    handler: () => {
                      archiveHandler(renderData?._id, renderData?.isArchived, {
                        async onSuccess(payload?: any) {
                          getAllDataHandlers();
                        },
                      });
                    },
                  },
                canDelete &&
                  renderData?.canDelete && {
                    name: "Delete",
                    handler: () => {
                      deleteHandler(renderData?._id, {
                        async onSuccess(payload?: any) {
                          getAllDataHandlers();
                        },
                      });
                    },
                  },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default RolesPermissionTableColumns;
