import React from "react";
import { FieldArray, useFormikContext } from "formik";
import {
  IconTypeEnum,
  NotificationSettingsInterface,
  NotificationToEnum,
} from "interfaces";
import {
  MyButton,
  MyCheckInput,
  MyIconButton,
  MyRadioInput,
  MyTextAreaInput,
  MyTextField,
} from "components";
import { FormWrapper, NameValueText } from "assets";
import { capitalizeFirstLetter } from "../../../helpers";

function ManageNotificationForm() {
  const { values } = useFormikContext<NotificationSettingsInterface>();
  return (
    <div className={"flex flex-col gap-10"}>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <NameValueText
          name={"Notification"}
          value={capitalizeFirstLetter(values?.title?.replaceAll("_", " "))}
        />
        <NameValueText
          name={"Module"}
          value={capitalizeFirstLetter(values?.module)}
        />
      </div>
      <FormWrapper
        header={{
          title: { name: "Notification Type" },
        }}
      >
        <>
          <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1"}>
            <div className={"flex items-center gap-10"}>
              <MyRadioInput
                radioButtonType={"radio"}
                label={"Custom Days"}
                defaultValue={true}
                name={`isCustom`}
              />
              <MyRadioInput
                radioButtonType={"radio"}
                label={"Direct Notification"}
                defaultValue={false}
                name={`isCustom`}
              />
            </div>
          </div>
          {values?.isCustom && (
            <FieldArray
              name={"customDays"}
              render={({ push, remove }) => {
                return (
                  <div className={"p-2 border-2 rounded-md"}>
                    <div className={"flex flex-col gap-5"}>
                      {values?.customDays?.map((e, key) => {
                        return (
                          <div key={key} className={"flex flex-col gap-2"}>
                            <div className={"flex items-center gap-5"}>
                              <MyRadioInput
                                label={"After"}
                                defaultValue={false}
                                name={`customDays.${key}.isBefore`}
                              />
                              <MyRadioInput
                                label={"Before"}
                                defaultValue={true}
                                name={`customDays.${key}.isBefore`}
                              />
                            </div>
                            <div className={"flex flex-1 items-center  gap-5"}>
                              <div className={"flex items-center gap-2"}>
                                <MyTextField
                                  className={"w-[150px]"}
                                  placeholder={"eg. 1"}
                                  type={"number"}
                                  name={`customDays.${key}.day`}
                                />
                                <span>{`Days ${
                                  e?.isBefore ? "before" : "after"
                                } due date`}</span>
                              </div>
                              {key !== 0 && (
                                <MyIconButton
                                  iconColor={"red"}
                                  IconType={IconTypeEnum.DELETE}
                                  onClick={() => remove(key)}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className={" mt-5"}>
                      <MyButton
                        size={"md"}
                        colorType={"white"}
                        isOutline
                        iconType={IconTypeEnum.ADD}
                        onClick={() => {
                          push({
                            isBefore: false,
                            day: 0,
                          });
                        }}
                        name={"Add"}
                      />
                    </div>
                  </div>
                );
              }}
            />
          )}
        </>
      </FormWrapper>
      <FormWrapper
        header={{
          title: { name: "Notification To" },
        }}
      >
        <div className={"grid grid-cols-6 md:grid-cols-3 sm:grid-cols-2 gap-5"}>
          {values?.allowedNotificationTo.map((e, key) => {
            return (
              <MyCheckInput
                key={key}
                defaultValue={e}
                label={capitalizeFirstLetter(e)}
                name={`notificationTo`}
              />
            );
          })}
        </div>
      </FormWrapper>
      <FormWrapper
        header={{
          title: { name: "Enable For" },
        }}
      >
        <div
          className={"grid grid-cols-6 md:grid-cols-3 sm:grid-cols-2 gap-5 "}
        >
          <MyRadioInput label={"Mail"} name={"enableEmailNotification"} />
          <MyRadioInput label={"Web"} name={"enablePushNotification"} />
          <MyRadioInput label={"App"} name={"enableInAppNotification"} />
        </div>
      </FormWrapper>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1"}>
        <div className={"col-span-2"}>
          <MyTextAreaInput label={"Description"} name={"description"} />
        </div>
      </div>
    </div>
  );
}

export default ManageNotificationForm;
