import React from "react";
import { Route } from "react-router-dom";

import {
  BankReconciliationPage,
  BankStatementPage,
  ReconcilePage,
  ReconcileDetailsPage,
} from "./pages";
import { UploadBankStatementModal } from "./modal";
import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import { AccessVerifier } from "templates";
function Index() {
  return (
    <Route path="bank-reconciliation/">
      <Route
        path={""}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_RECONCILIATION]}
            isForPage={true}
          >
            <BankReconciliationPage />
          </AccessVerifier>
        }
      >
        <Route
          path={`uploadStatement`}
          element={
            <AccessVerifier
              accessKeys={[
                SpaceAccessListEnum.FINANCE_READ_RECONCILE_STATEMENT,
              ]}
              isForPage={true}
            >
              <UploadBankStatementModal />
            </AccessVerifier>
          }
        />
      </Route>
      <Route
        path={`statements/details`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_READ_RECONCILE_STATEMENT]}
            isForPage={true}
          >
            <BankStatementPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`reconciled`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_READ_RECONCILE_STATEMENT]}
            isForPage={true}
          >
            <ReconcilePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`details/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_READ_RECONCILE_STATEMENT]}
            isForPage={true}
          >
            <ReconcileDetailsPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
