import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MyButton, TableCardComponent } from "components";
import { AccessVerifier, PageTemplate } from "templates";
import {
  FieldTypeEnum,
  IconTypeEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { TableFilterCard } from "content";
import { MaintenanceContext } from "context";

import { MaintenanceTableColumns } from "../components";
import { usePageQueryHooks, usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function MaintenancePage() {
  const {
    getAllDataHandlers,
    isLoading,
    allData,
    totalDocs,
    totalArchiveDocs,
  } = useContext(MaintenanceContext);
  const navigate = useNavigate();
  const { isArchive } = usePaginationQueryHooks();
  const { propertyID, unitID, status, type } = usePageQueryHooks();
  const pageQuery = {
    unit: unitID,
    property: propertyID,
    repairStatus: status,
    repairPriority: type,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, unitID, propertyID, status, type]);

  const tableCardProps = {
    column: MaintenanceTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      leftChildren: (
        <TableFilterCard
          haveDynamicOption={{
            fieldName: FieldTypeEnum.REPAIR_PRIORITY,
            placeholder: "Select Priority",
            queryName: QueryStringEnum.TYPE,
          }}
          haveDynamicOption2={{
            fieldName: FieldTypeEnum.REPAIR_STATUS,
            placeholder: "Select Status",
            queryName: QueryStringEnum.STATUS,
          }}
          haveProperty
          haveUnit
        />
      ),
      rightChildren: (
        <AccessVerifier
          accessKeys={[
            SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_ADD_REPAIR_AND_MAINTENANCE,
          ]}
          isForPage={false}
        >
          <MyButton
            onClick={() => {
              navigate(PageLinks.maintenance.new);
            }}
            iconType={IconTypeEnum.ADD}
            name={"Add Maintenance"}
          />
        </AccessVerifier>
      ),
    },
  };
  return (
    <PageTemplate
      title={"Repair and Maintenance"}
      breadCrumb={[
        {
          name: "Maintenance",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default MaintenancePage;
