import {
  BankInterface,
  CustomerInterface,
  DocumentInterface,
  DynamicListInterface,
  FiscalYearInterface,
  InvoiceInterface,
  LedgerGroupListInterface,
  LedgerNameInterface,
  LoanAndMortgageInterface,
  PrivilegeInterface,
  PropertyInterface,
  UnitInterface,
  UserRoleEnum,
} from "interfaces";
import { getStaffFullName } from "../HandlerHelpers";

export const decodeDynamicFieldHandler = (
  options: DynamicListInterface[],
  preFixLabelText?: string
) => {
  return options?.map((e) => {
    return {
      label: `${preFixLabelText || ""}${e?.label}`,
      value: e?._id,
    };
  });
};
export const decodeInvoiceHandler = (options: InvoiceInterface[]) => {
  return options?.map((e) => {
    return {
      label: e?.invoiceID,
      value: e?._id,
    };
  });
};
export const decodeLedgerGroupOptionsHandler = (
  options: LedgerGroupListInterface[]
) => {
  return options?.map((e) => {
    return {
      label: e?.groupName,
      options: e?.subgroups?.map((f) => {
        return {
          label: f?.name,
          value: f?._id,
        };
      }),
    };
  });
};
export const decodeDocumentOptionsHandler = (options: DocumentInterface[]) => {
  return options?.map((e) => {
    return {
      label: e?.name,
      value: e?._id,
    };
  });
};
export const decodeBankOptionsHandler = (options: BankInterface[]) => {
  return options?.map((e) => {
    return {
      label: `(${e?.bankCode}) ${e?.label}`,
      value: e?._id,
    };
  });
};
export const decodeFiscalYearOptionsHandler = (
  options: FiscalYearInterface[]
) => {
  return options?.map((e) => {
    return {
      label: e?.title,
      value: e?.title,
    };
  });
};

export const decodePropertyOptionsHandler = (options: PropertyInterface[]) => {
  return options?.map((e) => {
    let country =
      typeof e?.address?.country !== "string" && e?.address?.country?.name;
    let county =
      typeof e?.address?.county !== "string" && e?.address?.county?.name;
    return {
      value: e?._id,
      label: `${e?.propertyID} ${e?.address?.address1}, ${county && county} ${
        country && country
      }`,
    };
  });
};

export const decodeOwnerOptionsHandler = (options: CustomerInterface[]) => {
  return options?.map((e) => {
    return {
      label: `${getStaffFullName(e?.name)} (${e?.email})`,
      value: e?._id,
    };
  });
};
export const decodeLedgerNameOptionsHandler = (
  options: LedgerNameInterface[],
  filterField?: string[]
) => {
  if (filterField || filterField?.length > 0) {
    return options
      ?.filter((e) => !filterField?.includes(e?.name))
      ?.map((e) => {
        return {
          label: e?.name,
          value: e?._id,
        };
      });
  }
  return options?.map((e) => {
    return {
      label: e?.name,
      value: e?._id,
    };
  });
};
export const decodeUnitOptionsHandler = (options: UnitInterface[]) => {
  return options?.map((e) => {
    return {
      label: `${e?.unitID} ${e?.name}`,
      value: e?._id,
    };
  });
};

export const decodeLoanOptionsHandler = (
  options: LoanAndMortgageInterface[]
) => {
  return options?.map((e) => {
    return {
      label: `${e?.loanID}`,
      value: e?._id,
    };
  });
};
export const decodePrivilegeOptionsHandler = (
  options: PrivilegeInterface[],
  filters?: UserRoleEnum[]
) => {
  if (filters?.length > 0) {
    return options
      ?.filter((e) => {
        return !filters?.some((_e) => _e === e?.role);
      })
      ?.map((e) => ({
        label: e?.name,
        value: e?._id,
      }));
  }
  return options?.map((e) => {
    return {
      label: `${e?.name}`,
      value: e?._id,
    };
  });
};
export const decodeUserOptionsHandler = (
  options: any,
  filter?: UserRoleEnum
) => {
  if (filter) {
    return options
      ?.filter((e) => {
        return e?.role === filter;
      })
      ?.map((e) => ({
        label: `(${e?.userID}) ${getStaffFullName(e?.name)}`,
        value: e?._id,
      }));
  }
  return options?.map((e) => {
    return {
      label: `(${e?.userID}) ${getStaffFullName(e?.name)}`,
      value: e?._id,
    };
  });
};
