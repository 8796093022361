import { Suspense } from "react";

import {
  AppContextProvider,
  ConfirmContextProvider,
  OptionsContextProvider,
  ModuleContextProvider,
} from "./context";

import { Layout, Loader } from "./components";
import { ErrorBoundaryTemplate } from "./templates";
import { AuthRoutes } from "./routes";

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <ErrorBoundaryTemplate>
        <ConfirmContextProvider>
          <AppContextProvider>
            <OptionsContextProvider>
              <ModuleContextProvider>
                <Layout>
                  <AuthRoutes />
                </Layout>
              </ModuleContextProvider>
            </OptionsContextProvider>
          </AppContextProvider>
        </ConfirmContextProvider>
      </ErrorBoundaryTemplate>
    </Suspense>
  );
}

export default App;
