import React from "react";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

function UseParamHook() {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateFn = (params) => {
    navigate(
      {
        pathname: location.pathname,
        search: queryString.stringify(params),
      },
      {
        state: {
          //@ts-ignore
          backgroundLocation: location?.state?.backgroundLocation,
        },
      }
    );
  };
  const setParamsHandler = (key: string, value: any) => {
    const params = queryString.parse(location?.search);
    params[key] = value;
    navigateFn(params);
  };
  const deleteParamsHandler = (key?: string) => {
    const params = queryString.parse(location?.search);
    if (params[key]) {
      delete params[key];
    }

    navigateFn(params);
  };
  return { setParamsHandler, deleteParamsHandler };
}

export default UseParamHook;
