import React from "react";
import { Route } from "react-router-dom";
import { BookingDetailsPage, BookingsPage, NewBookingsPage } from "./pages";
import { BookingsGeneralDetailTab } from "./tabs";
import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="services/">
      {/*  booking*/}
      <Route
        path={"bookings"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.BOOKING_READ_BOOKING]}
            isForPage
          >
            <BookingsPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"bookings/new"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.BOOKING_ADD_BOOKING]}
            isForPage
          >
            <NewBookingsPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`bookings/edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.BOOKING_EDIT_BOOKING]}
            isForPage
          >
            <NewBookingsPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`bookings/details/:${ParamsStringEnum.ID}/*`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.BOOKING_READ_BOOKING]}
            isForPage
          >
            <BookingDetailsPage />
          </AccessVerifier>
        }
      >
        <Route path={""} element={<BookingsGeneralDetailTab />} />
      </Route>
    </Route>
  );
}

export default Index;
