import React, { useContext, useEffect } from "react";

import { PageTemplate } from "templates";
import { useNavigate, useParams } from "react-router-dom";
import { BookingDataContext } from "context";
import {
  IconTypeEnum,
  ParamsStringEnum,
  SpaceAccessListEnum,
  TaxTypeEnum,
} from "interfaces";
import { PageLinks } from "routes";
import { getCurrency, getTaxNameHandler } from "helpers";
import { FileViewCard, MyButton } from "components";
import { useAuthorizationHook, useDateHook } from "useHooks";
import { AddressText, FormWrapper, NameValueText } from "assets";

function BookingDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const navigate = useNavigate();
  const { details, getDetailsHandler, isDetailsLoading } =
    useContext(BookingDataContext);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.BOOKING_EDIT_BOOKING,
  ]);
  const { getDateHandler, getStaffFullName } = useDateHook();

  useEffect(() => {
    getDetailsHandler(params?.id);
  }, [params?.id]);
  return (
    <PageTemplate
      title={"Bookings"}
      titleRightChildren={
        canEdit &&
        details?._id && (
          <div>
            <MyButton
              iconType={IconTypeEnum.EDIT}
              name={"Edit General"}
              onClick={() => {
                navigate(PageLinks.bookings.edit(details?._id));
              }}
              isOutline
            />
          </div>
        )
      }
      backNavigation={{
        title: "Booking Details",
        path: PageLinks.bookings.list,
      }}
      scrollAll
    >
      <div className={"flex flex-col gap-5"}>
        <div className={"grid grid-cols-3 gap-5 sm:grid-cols-1 md:grid-cols-2"}>
          <NameValueText
            name={"Property"}
            isLoading={isDetailsLoading}
            value={
              <div className={"flex gap-2 items-center"}>
                <span className={"text-tBlue font-bold text-[14px]"}>
                  {details?.property?.propertyID}
                </span>
                <AddressText isOneLine address={details?.property?.address} />
              </div>
            }
          />
          <NameValueText
            name={"Unit"}
            isLoading={isDetailsLoading}
            value={
              <div className={"flex gap-2 items-center"}>
                <span className={"text-tBlue font-bold text-[14px]"}>
                  {details?.unit?.unitID}
                </span>
                <span>{details?.unit?.name}</span>
              </div>
            }
          />{" "}
          <NameValueText
            name={"Tenant"}
            isLoading={isDetailsLoading}
            value={`${details?.tenant?.userID || ""} ${getStaffFullName(
              details?.tenant?.name
            )}`}
          />
        </div>
        <div className={"grid grid-cols-3 gap-5 sm:grid-cols-1 md:grid-cols-2"}>
          <NameValueText
            name={"Arrival Date"}
            value={getDateHandler({
              date: details?.arrivalDate,
              haveTime: true,
            })}
          />
          <NameValueText
            name={"Departure Date"}
            value={getDateHandler({
              date: details?.departureDate,
              haveTime: true,
            })}
          />
        </div>
        <div className={"grid grid-cols-3 gap-5 sm:grid-cols-1 md:grid-cols-2"}>
          <NameValueText name={"Description"} value={details?.description} />
        </div>
        <FormWrapper
          header={{
            title: {
              name: "Rent",
            },
          }}
        >
          <div className={"flex flex-col gap-5"}>
            <div
              className={"grid grid-cols-3 gap-5 sm:grid-cols-1 md:grid-cols-2"}
            >
              <NameValueText
                name={`Rent Amount (${getCurrency()}) (Per Day)`}
                isLoading={isDetailsLoading}
                value={details?.rent?.rentCost}
              />
            </div>{" "}
            <div
              className={"grid grid-cols-3 gap-5 sm:grid-cols-1 md:grid-cols-2"}
            >
              <NameValueText
                name={`Tax Type`}
                isLoading={isDetailsLoading}
                value={getTaxNameHandler(details?.rent?.taxType)}
              />
              {details?.rent?.taxType !== TaxTypeEnum.EXEMPTED && (
                <NameValueText
                  name={"Tax Rate"}
                  isLoading={isDetailsLoading}
                  value={details?.rent?.taxRate?.label}
                />
              )}
            </div>
            <div
              className={
                "grid items-start grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-5 "
              }
            >
              <div className={"col-span-2"}>
                <NameValueText
                  name={"Remarks"}
                  value={details?.rent?.remarks || "-"}
                />
              </div>
            </div>
          </div>
        </FormWrapper>

        <FormWrapper
          header={{
            title: {
              name: "Security Deposit",
            },
          }}
        >
          <div
            className={"grid grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-5"}
          >
            <NameValueText
              name={"Charge Type"}
              isLoading={isDetailsLoading}
              value={details?.securityDeposit?.chargeType?.label}
            />
            <NameValueText
              name={`Amount (${getCurrency()})`}
              isLoading={isDetailsLoading}
              value={details?.securityDeposit?.securityDepositedAmount}
            />
            <NameValueText
              name={`Deposit Date`}
              isLoading={isDetailsLoading}
              value={getDateHandler({
                date: details?.securityDeposit?.securityDepositedOn,
              })}
            />
            <div className={"col-span-2"}>
              <NameValueText
                name={`Remarks`}
                isLoading={isDetailsLoading}
                value={details?.securityDeposit?.securityDepositedNote}
              />
            </div>
          </div>
        </FormWrapper>
        {details?.agreementDocuments?.length > 0 && (
          <FormWrapper
            header={{
              title: {
                name: "Additional Documents",
              },
            }}
          >
            <div className={"grid grid-cols-5 sm:grid-cols-3 sm:grid-cols-1"}>
              {details?.agreementDocuments?.map((e, key) => {
                return <FileViewCard key={key} filePath={e} />;
              })}
            </div>
          </FormWrapper>
        )}

        <FormWrapper
          header={{
            title: {
              name: "Signature",
            },
          }}
        >
          <div className={"flex  gap-5 items-start"}>
            <FileViewCard filePath={details?.tenantSignature?.signature} />
          </div>
        </FormWrapper>
      </div>
    </PageTemplate>
  );
}

export default BookingDetailsPage;
