import React, { useContext } from "react";
import { MyTextField, MyUploadDocument } from "components";
import { Form, Formik } from "formik";
import { editProfileFormik } from "../helpers";
import { AppContext, ProfileContext } from "context";
import { useNavigate } from "react-router-dom";
import { FormConcludeButtons } from "assets";
import { PageTemplate } from "templates";
import { PageLinks } from "routes";

function EditProfileModal() {
  const navigate = useNavigate();
  const {
    state: { profileDetails },
  } = useContext(AppContext);

  const {
    contextHandlers: { updateProfileHandler },
  } = useContext(ProfileContext);

  const submitHandler = async (values, props) => {
    try {
      updateProfileHandler(values, props, {
        onSuccess(payload) {
          navigate(PageLinks.profile.details);
        },
      });
    } catch (err) {}
  };
  return (
    <PageTemplate
      backNavigation={{
        title: "Edit User Profile",
      }}
      title={"Profile"}
    >
      <Formik
        initialValues={editProfileFormik.initialValues(profileDetails)}
        onSubmit={submitHandler}
        enableReinitialize
      >
        <Form className={"flex flex-col gap-5"}>
          <>
            <div className={`grid grid-cols-3  gap-5`}>
              <MyTextField
                name={"firstName"}
                isRequired
                label={"First Name"}
                placeholder={"eg.John"}
              />
              <MyTextField
                isRequired
                name={"lastName"}
                label={"Last Name"}
                placeholder={"eg.Doe"}
              />
              <MyTextField
                name={"email"}
                disabled
                label={"Email Address"}
                isRequired
                placeholder={"eg. doe@john.com"}
              />
              <MyTextField
                name={"phone"}
                label={"Phone Number"}
                isRequired
                placeholder={"eg.9866000000"}
              />
            </div>
            <div className={`grid grid-cols-3  gap-5`}>
              <MyUploadDocument
                fileType={"image"}
                name={"photo"}
                label={"User Display Picture"}
                fileContainerStyle={"grid grid-cols-1"}
              />
            </div>
            <div className={`grid grid-cols-3  gap-5`}>
              <MyTextField
                name={"companyName"}
                label={"Company Name"}
                placeholder={"eg.UK Accountant"}
              />
              <MyTextField
                name={"companyRegistration"}
                label={"Company Registration Number"}
                placeholder={"eg.1245DFGHG"}
              />
              <MyTextField
                name={"vatRegistration"}
                label={"VAT Registration Number"}
                placeholder={"eg.1245DFGHG"}
              />
            </div>
          </>
          <FormConcludeButtons
            cancelButton={{
              title: "Cancel",
              handler() {
                navigate(PageLinks.profile.details);
              },
            }}
            submitButton={{
              title: "Submit",
            }}
          />
        </Form>
      </Formik>
    </PageTemplate>
  );
}

export default EditProfileModal;
