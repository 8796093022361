import React from "react";

import { NavLink, useLocation } from "react-router-dom";

import queryString from "query-string";
import { QueryStringEnum } from "interfaces";

import { TabComponentInterface } from "./interfaces";

function TabComponent({
  tabs,
  stateControl,
  control = "url",
}: TabComponentInterface) {
  const location = useLocation();
  const currentQuery = queryString.parse(location?.search);
  return (
    <div className={"overflow-x-scroll "}>
      <div
        className={
          "font-medium flex  gap-16 border-b-2 border-gray-200 min-w-full w-max cursor-pointer select-none"
        }
      >
        {tabs?.map((e, key) => {
          if (!e) return;
          if (control === "url") {
            currentQuery[QueryStringEnum.ACTIVE_TAB] = e?.pathName;
            return (
              <div className={"flex items-start "}>
                <NavLink
                  end
                  key={key}
                  id={e?.pathName}
                  to={{
                    pathname: `${e?.pathName}`,
                    search: queryString.stringify(currentQuery),
                  }}
                  className={({ isActive }) => {
                    return `h-[40px] min-w-[80px]  w-full text-center  whitespace-nowrap  ${
                      isActive
                        ? "text-primary border-b-2 border-b-primary "
                        : "text-black"
                    }`;
                  }}
                >
                  {e?.title}
                </NavLink>
              </div>
            );
          } else {
            let isActive = stateControl?.activeTab === e?.pathName;
            return (
              <div
                key={key}
                id={e?.pathName}
                onClick={() => stateControl.setActiveTabHandler(e?.pathName)}
                className={`h-[40px]  min-w-[80px] text-center whitespace-nowrap  ${
                  isActive
                    ? "text-primary border-b-2 border-b-primary "
                    : " text-black"
                }`}
              >
                {e?.title}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default TabComponent;
