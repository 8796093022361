import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";

import { PageLinks } from "routes";
import { AddressText } from "assets";
import { PropertyDataContext } from "context";
import { useAuthorizationHook, usePaginationQueryHooks } from "useHooks";
import { MyMoreOptionButton, MyToggleButton } from "components";
import {
  PropertyInterface,
  SpaceAccessListEnum,
  TableColumnInterface,
} from "interfaces";

function PropertyTableColumns(
  pageQuery?: any
): TableColumnInterface<PropertyInterface>[] {
  const navigate = useNavigate();
  const {
    deleteHandler,
    archiveHandler,
    getAllDataHandlers,
    editDetailsHandler,
  } = useContext(PropertyDataContext);
  const { calcSNHandler } = usePaginationQueryHooks();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canRead = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_READ_PROPERTY,
  ]);
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_EDIT_PROPERTY,
  ]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_DEL_PROPERTY,
  ]);
  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },
    {
      title: "Property Code",
      render(renderData) {
        return (
          <div
            onClick={() =>
              canRead && navigate(PageLinks.property.details(renderData?._id))
            }
            className={"text-[16px] font-bold text-[#2680EB]"}
          >
            {renderData?.propertyID}
          </div>
        );
      },
    },
    {
      title: "Address",
      render(renderData) {
        return (
          <div className={"flex items-start "}>
            <AddressText address={renderData?.address} />
          </div>
        );
      },
    },
    {
      title: "Property Type",
      render(renderData) {
        return <div>{renderData?.propertyType?.label}</div>;
      },
    },
    // {
    //   title: "Area",
    //   render(renderData) {
    //     return (
    //       <div>
    //         {renderData?.unitNumber} sq. {renderData?.unitSize?.label}
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Current Status",
      render(renderData) {
        return (
          <div className={"flex items-start justify-start"}>
            <MyToggleButton
              type={"text"}
              changeHandler={async (newValue) => {
                let payload = {
                  _id: renderData?._id,
                  isVacant: newValue,
                };
                canEdit &&
                  (await editDetailsHandler(payload, null, {
                    async onSuccess() {
                      getAllDataHandlers();
                    },
                  }));
              }}
              value={renderData?.isVacant}
              options={{
                active: {
                  name: "Vacant",
                  className: "bg-tRed ",
                },
                inActive: {
                  name: "Occupied",
                  className: "bg-tGreen",
                },
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigate(PageLinks.property.details(renderData?._id));
                  },
                },
                canEdit &&
                  !renderData?.isArchived && {
                    name: "Edit Details",
                    handler: () => {
                      navigate(PageLinks.property.edit(renderData?._id));
                    },
                  },
                canDelete && {
                  name: "Delete Property",
                  handler: () => {
                    deleteHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(pageQuery);
                      },
                    });
                  },
                },
                canDelete && {
                  name: renderData?.isArchived ? `Unarchive` : "Archive",
                  handler: () => {
                    archiveHandler(renderData?._id, renderData?.isArchived, {
                      async onSuccess() {
                        await getAllDataHandlers(pageQuery);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default PropertyTableColumns;
