import actionTypes from "./config";

export const setLoadingAction = (flag: boolean) => {
  return {
    type: actionTypes.APP_SET_LOADING,
    payload: flag,
  };
};
export const setSpaceSettingAction = (values) => {
  return {
    type: actionTypes.APP_SET_SETTING,
    payload: values,
  };
};
export const setAppSettingActions = (values) => {
  return {
    type: actionTypes.APP_SET_APP_SETTINGS,
    payload: values,
  };
};

export const setErrorAction = (flag: boolean, message?: string) => {
  return {
    type: actionTypes.APP_SET_ERROR,
    payload: { flag, message },
  };
};
export const setPageErrorAction = (status: boolean, message?: string) => {
  return {
    type: actionTypes.APP_SET_PAGE_ERROR,
    payload: { status, message },
  };
};

export const setSuccessAction = (flag: boolean, message?: string) => {
  return {
    type: actionTypes.APP_SET_SUCCESS_ACTION,
    payload: { flag, message },
  };
};

export const setAuthLoadingAction = (flag: boolean) => {
  return {
    type: actionTypes.APP_SET_AUTH_LOADING,
    payload: flag,
  };
};

export const setAuthenticateAction = (payload: any) => {
  return {
    type: actionTypes.APP_SET_AUTHENTICATED,
    payload,
  };
};

export const logoutAction = (payload: any) => {
  return {
    type: actionTypes.LOGOUT,
    payload,
  };
};

export const setProfileAction = (payload: any) => {
  return {
    type: actionTypes.APP_SET_PROFILE,
    payload,
  };
};

export const setTenantSpaceListAction = (payload: any) => {
  return {
    type: actionTypes.APP_SET_TENANT_SPACE_LIST,
    payload,
  };
};
export const setTrueLayerSettingAction = (payload: any) => {
  return {
    type: actionTypes.APP_SET_TRUE_LAYER_SETTING,
    payload,
  };
};
export const setTwilioSettingAction = (payload: any) => {
  return {
    type: actionTypes.APP_SET_TWILIO_SETTINGS,
    payload,
  };
};
export const setCurrentWorkspaceAction = (payload: any) => {
  return {
    type: actionTypes.APP_SET_CURRENT_WORKSPACE,
    payload,
  };
};
export const switchWorkspaceAction = () => {
  return {
    type: actionTypes.APP_SET_SWITCH_WORKSPACE,
  };
};
