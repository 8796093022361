import * as yup from "yup";
import { TaxTypeEnum, TenancyInterface } from "interfaces";
import { TenancyFormInterface } from "./interfaces";

export const newTenancyFormikValues = {
  initialValues: (
    values?: Partial<TenancyInterface>
  ): TenancyFormInterface<string> => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      isDraft: values?.isDraft || false,
      property: values?.property?._id || "",
      unit: values?.unit?._id || "",
      tenant: values?.tenancy?.tenant?._id || "",
      tenancyType: values?.tenancy?.tenancyType?._id || "",
      agreementOn: values?.tenancy?.agreementOn,
      rentCommencementOn: values?.tenancy?.rentCommencementOn,
      //  duration
      durationQuantity: values?.duration?.durationQuantity,
      durationFrequency: values?.duration?.durationFrequency?._id,
      // rent
      rentCost: values?.rent?.rentCost,
      rentFrequency: values?.rent?.rentFrequency?._id || "",
      taxRate: values?.rent?.taxRate?._id || "",
      taxType: values?.rent?.taxType || TaxTypeEnum.EXEMPTED,
      // initial rent payment
      initialRentDueDate: values?.initialRent?.initialRentDueDate,
      initialRentCost: values?.initialRent?.initialRentCost,
      initialRentNote: values?.initialRent?.initialRentNote || "",
      // recurring payment
      recurringPaymentTerms:
        values?.recurringPayment?.recurringPaymentTerms?._id || "",
      recurringFrequency:
        values?.recurringPayment?.recurringFrequency?._id || "",
      recurringRemarks: values?.recurringPayment?.recurringRemarks || "",
      recurringDue: values?.recurringPayment?.recurringDue,
      // opening balance
      openingBalanceDue: values?.openingBalance?.openingBalanceDue,
      openingBalanceAmount: values?.openingBalance?.openingBalanceAmount,
      balanceType: values?.openingBalance?.balanceType?._id || "",

      //utilitiesCharges
      utilitiesCharges: values?.utilitiesCharges?.map((e) => e?._id) || [],

      //securityDeposit
      chargeType: values?.securityDeposit?.chargeType?._id || "",
      securityDepositedAmount: values?.securityDeposit?.securityDepositedAmount,
      securityDepositedOn: values?.securityDeposit?.securityDepositedOn,
      securityDepositedNote:
        values?.securityDeposit?.securityDepositedNote || "",

      // other charges
      otherCharges: values?.otherCharges?.map((e) => e?._id) || [],

      // bank details
      bank: values?.bank?._id || "",

      // advance
      notifyLateFeeChangeToTenant:
        values?.advance?.notifyLateFeeChangeToTenant || false,
      maximumMonthlyLateFees: values?.advance?.maximumMonthlyLateFees || false,
      maximumDailyLateFees: values?.advance?.maximumDailyLateFees || false,
      minimumBalanceToApplyLateFee:
        values?.advance?.minimumBalanceToApplyLateFee || false,
      invoiceMailBeforeDueDate:
        values?.advance?.invoiceMailBeforeDueDate || false,

      // agreementDocuments
      agreementDocuments: values?.agreementDocuments || [],
    };
  },
  validationSchema: yup.object().shape({
    property: yup.string().required("required"),
    unit: yup.string().required("required"),
    tenant: yup.string().required("required"),
    tenancyType: yup.string().required("required"),
    agreementOn: yup.string().required("required"),
    rentCommencementOn: yup.string().required("required"),
    durationQuantity: yup.number().min(0, "invalid").required("required"),
    durationFrequency: yup.string().required("required"),
    rentCost: yup.number().min(0, "invalid").required("required"),
    rentFrequency: yup.string().required("required"),
    recurringPaymentTerms: yup.string().required("required"),
    recurringFrequency: yup.string().required("required"),
  }),
};
