import React, { useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import {
  ProfilePage,
  EditProfilePage,
  EditPasswordPage,
  ChangePasswordFirstTimePage,
  EmailVerificationPage,
} from "./pages";
import { AppContext } from "../../context";
function Index() {
  const { state } = useContext(AppContext);
  const [canSetPassword, setSetPassword] = useState(false);
  useEffect(() => {
    if (
      state?.isAuthenticated &&
      state?.profileDetails &&
      state?.profileDetails?.systemID?.forceChangePassword
    ) {
      setSetPassword(true);
    }
  }, [state?.profileDetails]);
  return (
    <Route path="profile/">
      <Route path={``} element={<ProfilePage />} />
      <Route path={`edit`} element={<EditProfilePage />} />
      <Route path={`changePassword`} element={<EditPasswordPage />} />
      {canSetPassword && (
        <Route
          path={`set-password`}
          element={<ChangePasswordFirstTimePage />}
        />
      )}
    </Route>
  );
}

export { EmailVerificationPage };

export default Index;
