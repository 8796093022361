import React from "react";
import { AccessibleShortCutLinks } from "routes";
import { PageTemplate } from "templates";
import { useNavigate } from "react-router-dom";

function Index() {
  const links = AccessibleShortCutLinks();
  const navigate = useNavigate();
  return (
    <PageTemplate>
      <div className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-10"}>
        {links?.map((e, key) => {
          let Icon = e?.Icon;
          return (
            <div key={key} className={"flex flex-col gap-5 cursor-pointer"}>
              <div className={"flex items-center gap-3"}>
                <Icon className={"text-[18px] h-[18px] w-[18px]"} />
                <b className={"text-[18px] font-semibold"}>{e?.name}</b>
              </div>
              <div className={"flex flex-col gap-3"}>
                {e?.groups?.map((f, fKey) => {
                  const ListIcon = f?.Icon;
                  return (
                    <div
                      key={fKey}
                      onClick={() => navigate(f?.path)}
                      className={
                        "flex items-center gap-2 text-tBlue hover:underline text-[16px] cursor-pointer"
                      }
                    >
                      <ListIcon className={"text-[16px] h-[16px] w-[16px]"} />
                      <span className={"text-[16px]"}>{f?.name}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </PageTemplate>
  );
}

export default Index;
