import React, { useContext, useEffect } from "react";

import { PageTemplate } from "templates";
import { useSearchParams } from "react-router-dom";
import { FieldTypeEnum, IconTypeEnum, QueryStringEnum } from "interfaces";

import { BankReconciliationContext, OptionsContext } from "context";
import { PageLinks } from "routes";
import { ReconciliationCardContainer } from "../container";
import {
  EmptyMessageComponent,
  MySearchSelect,
  PaginationComponent,
} from "components";
import { decodeBankOptionsHandler, getIconHelper } from "helpers";
import { usePaginationQueryHooks } from "useHooks";
import Skeleton from "react-loading-skeleton";

function BankStatementPage() {
  const [query] = useSearchParams();
  const id = query?.get(QueryStringEnum.ID);
  const ReloadIcon = getIconHelper(IconTypeEnum.RELOAD);
  const {
    getReconciliationStatementHandler,
    reconciliationStatements,
    totalReconciliationStatements,
    isDetailsLoading,
  } = useContext(BankReconciliationContext);
  const { currentPage } = usePaginationQueryHooks();
  const {
    bankOptions,
    getPropertyOptionsHandler,
    getBankOptionsHandler,
    getLedgerOptionsHandler,
    getUserOptionsHandler,
    getOptionsHandler,
  } = useContext(OptionsContext);

  useEffect(() => {
    getPropertyOptionsHandler();
    getBankOptionsHandler();
    getLedgerOptionsHandler();
    getUserOptionsHandler();
    getOptionsHandler(FieldTypeEnum.TAX);
  }, []);

  useEffect(() => {
    getReconciliationStatementHandler(id);
  }, [currentPage, id]);

  return (
    <PageTemplate
      scrollAll
      title={"Bank Reconciliation"}
      backNavigation={{
        title: "Bank Statements",
        path: PageLinks.reconciliation.list,
      }}
      titleRightChildren={
        <div className={"flex items-center gap-5"}>
          <MySearchSelect
            option={{
              selectOptions: decodeBankOptionsHandler(bankOptions),
              defaultLabel: "Select Bank",
            }}
            paramsName={QueryStringEnum.ID}
          />
          {id && (
            <div>
              <ReloadIcon
                onClick={() => getReconciliationStatementHandler(id)}
                className={"text-[30px] cursor-pointer"}
              />
            </div>
          )}
        </div>
      }
    >
      <div className={"flex flex-col gap-10"}>
        {!isDetailsLoading && reconciliationStatements?.length < 1 && (
          <div className={"mt-10"}>
            <EmptyMessageComponent message={"No statement found"} />
          </div>
        )}
        <div className={"flex flex-col gap-10"}>
          {isDetailsLoading
            ? new Array(3).fill("")?.map((e, key) => {
                return (
                  <div
                    className={"grid grid-cols-2 gap-5 h-[100px] z-0"}
                    key={key + 1}
                  >
                    <Skeleton className={"w-full h-full"} />
                    <Skeleton className={"w-full h-full"} />
                  </div>
                );
              })
            : reconciliationStatements?.map((e, key) => {
                return (
                  <ReconciliationCardContainer
                    key={key + e?._id}
                    statementDetails={e}
                  />
                );
              })}
        </div>
        {reconciliationStatements?.length > 0 && (
          <PaginationComponent totalDocs={totalReconciliationStatements} />
        )}
      </div>
    </PageTemplate>
  );
}

export default BankStatementPage;
