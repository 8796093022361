import React, { useContext } from "react";

import { TableColumnInterface, TrashInterface } from "interfaces";
import { MyMoreOptionButton } from "components";

import { TrashContext } from "context";
import { useDateHook } from "useHooks";
import { usePaginationQueryHooks } from "useHooks";

function TrashTableColumns(query): TableColumnInterface<TrashInterface>[] {
  const { getAllDataHandlers, deleteHandler, restoreTrashHandler } =
    useContext(TrashContext);

  const { calcSNHandler } = usePaginationQueryHooks();
  const { getDateHandler } = useDateHook();

  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <span>{calcSNHandler(key)}.</span>;
      },
    },
    {
      title: "Title",
      render(renderData, key: number) {
        return <span>{renderData?.itemName || "-"}</span>;
      },
    },
    {
      title: "Property",
      render(renderData, key: number) {
        return (
          <span className={"text-primary font-semibold"}>
            {renderData?.property?.propertyID || "N/A"}
          </span>
        );
      },
    },
    {
      title: "Unit",
      render(renderData, key: number) {
        return (
          <div className={"flex flex-col gap-1"}>
            <span className={"text-primary font-semibold"}>
              {renderData?.unit?.unitID}
            </span>
            <span className={"font-semibold"}>
              {renderData?.unit?.name || "N/A"}
            </span>
          </div>
        );
      },
    },

    {
      title: "Type",
      render(renderData, key: number) {
        return (
          <div>
            <span>{renderData?.description || "N/A"}</span>
          </div>
        );
      },
    },
    {
      title: "Date",
      render(renderData, key: number) {
        return (
          <div>
            <span>{getDateHandler({ date: renderData?.createdAt })}</span>
          </div>
        );
      },
    },
    {
      title: "Action",
      render(renderData, key: number) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                // {
                //   name: "Restore",
                //   handler: () => {
                //     restoreTrashHandler(renderData?._id, {
                //       async onSuccess(payload?: any) {
                //         await getAllDataHandlers(query);
                //       },
                //     });
                //   },
                // },
                {
                  name: "Delete",
                  handler: () => {
                    deleteHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default TrashTableColumns;
