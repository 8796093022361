import React, { useContext } from "react";
import { OptionsContext } from "context";
import { MySelectField, MyTextAreaInput, MyTextField } from "components";
import { decodeUserOptionsHandler } from "helpers";
import { FormWrapper } from "assets";
import { SelectExistingDocumentForm } from "../../assets";
function MessageForm() {
  const { userOptions } = useContext(OptionsContext);
  return (
    <div className={"flex flex-col gap-10"}>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <MySelectField
          label={"Recipient(s)"}
          isRequired
          name={"recipients"}
          multiple
          option={{
            selectOptions: decodeUserOptionsHandler(userOptions),
            defaultLabel: "Select Recipient(s)",
          }}
        />
      </div>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <MyTextField
          placeholder={"Enter the subject"}
          label={"Subject"}
          isRequired
          name={"subject"}
        />
      </div>{" "}
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <div className={"col-span-2"}>
          <MyTextAreaInput label={"Message"} isRequired name={"message"} />
        </div>
      </div>
      <FormWrapper
        header={{
          title: {
            name: "Documents",
          },
        }}
      >
        <SelectExistingDocumentForm />
      </FormWrapper>
    </div>
  );
}

export default MessageForm;
