import React, { useContext } from "react";

import Popup from "reactjs-popup";
import { NavLink, useNavigate } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";

import { AppContext } from "context";
import { MyIconButton } from "components";
import { AccessibleSettingLinks, PageLinks } from "routes";
import { IconTypeEnum, SpaceAccessListEnum, UserRoleEnum } from "interfaces";
import { getIconHelper } from "helpers";

import "./style.css";
import { useAuthorizationHook } from "../../../useHooks";

function convertBytes(byteValue: number): string {
  const kb: number = byteValue / 1024;
  const mb: number = kb / 1024;
  const gb: number = mb / 1024;

  if (gb >= 1) {
    return `${gb.toFixed(2)}GB`;
  } else if (mb >= 1) {
    return `${mb.toFixed(2)}MB`;
  } else {
    return `${parseInt(kb.toString())}KB`;
  }
}

function SettingDetail() {
  const { handlers, state } = useContext(AppContext);
  const navigate = useNavigate();
  const DeleteIcon = getIconHelper(IconTypeEnum.DELETE);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const settingLinks = AccessibleSettingLinks();
  const canFlushData = checkCanAccessHandler([], [UserRoleEnum.LANDLORD]);
  const canViewStorageDetails = checkCanAccessHandler([
    SpaceAccessListEnum.BILLING_PURCHASE_SUBSCRIPTION,
    SpaceAccessListEnum.BILLING_VIEW_SUBSCRIPTION,
    SpaceAccessListEnum.CONFIGURATION_MANAGE_SETTINGS,
  ])&&state?.profileDetails?.subscription;
  const usedStorage = state?.spaceSettings?.usedStorage || 0;
  const totalStorage = state?.spaceSettings?.storageLimit || 0;
  const usedPercentage = (usedStorage / totalStorage) * 100;
  return (
    <Popup
      key={`tp-sd`}
      trigger={
        <div>
          <MyIconButton title={"Settings"} IconType={IconTypeEnum.SETTING} />
        </div>
      }
      position={"bottom right"}
      on={["click"]}
      contentStyle={{ backgroundColor: "white", width: 250 }}
      arrow
      arrowStyle={{ color: "white" }}
    >
      <div className={""}>
        {settingLinks[0]?.length > 0 &&
          settingLinks[0].map((e, key) => {
            let Icon = e?.Icon;
            let isLastItem = settingLinks[0]?.length - 1 == key;
            return (
              <NavLink
                key={key}
                to={e?.path}
                className={`setting_navLink ${isLastItem && "border-0"}`}
              >
                <Icon className="text-[16px]" /> <span>{e?.name}</span>
              </NavLink>
            );
          })}
        {settingLinks[1]?.length > 0 && (
          <>
            <span className={"text-gray-500 text-[12px] px-2 "}>
              Accounting Setup
            </span>
            {settingLinks[1].map((e, key) => {
              let Icon = e?.Icon;
              return (
                <NavLink key={key} to={e?.path} className={`setting_navLink`}>
                  <Icon className="text-[16px]" /> <span>{e?.name}</span>
                </NavLink>
              );
            })}
          </>
        )}
        {canFlushData && (
          <div
            className={"setting_navLink"}
            onClick={() => handlers.accountResetHandler()}
          >
            <DeleteIcon className={"text-[16px]"} />{" "}
            <span>Flush Finance Data</span>
          </div>
        )}
        {settingLinks[2]?.length > 0 &&
          settingLinks[2].map((e, key) => {
            let Icon = e?.Icon;
            let isLastItem = settingLinks[2]?.length - 1 == key;

            return (
              <NavLink
                key={key}
                to={e?.path}
                className={`setting_navLink ${isLastItem && "border-none"}`}
              >
                <Icon className="text-[16px]" /> <span>{e?.name}</span>
              </NavLink>
            );
          })}
        {settingLinks[3]?.length > 0 && (
          <>
            <span className={"text-gray-500 text-[12px] px-2 "}>
              Bank Link Setup
            </span>
            {settingLinks[3].map((e, key) => {
              let Icon = e?.Icon;
              let isLastItem = settingLinks[3]?.length - 1 == key;
              return (
                <NavLink
                  key={key}
                  to={e?.path}
                  className={`setting_navLink ${isLastItem && "border-none"}`}
                >
                  <Icon className="text-[16px]" /> <span>{e?.name}</span>
                </NavLink>
              );
            })}
          </>
        )}
        {settingLinks[4]?.length > 0 && (
          <>
            <span className={"text-gray-500 text-[12px] px-2 "}>
              Email and SMS Setup
            </span>
            {settingLinks[4].map((e, key) => {
              let Icon = e?.Icon;
              let isLastItem = settingLinks[0]?.length - 1 == key;
              return (
                <NavLink
                  key={key}
                  to={e?.path}
                  className={`setting_navLink ${isLastItem && "border-none"}`}
                >
                  <Icon className="text-[16px]" /> <span>{e?.name}</span>
                </NavLink>
              );
            })}
          </>
        )}
        <span className={"text-gray-500 text-[12px] px-2 "}>My Account</span>
        <div className={"flex flex-col "}>
          {settingLinks[5].map((e, key) => {
            let Icon = e?.Icon;
            return (
              <NavLink to={e?.path} key={key} className="setting_navLink">
                <Icon className="text-[16px]" /> <span>{e?.name}</span>
              </NavLink>
            );
          })}
          {canViewStorageDetails && (
            <div
              onClick={() =>
                navigate(PageLinks.subscription?.activeSubscription)
              }
              className={
                "flex flex-col gap-2 p-2 bg-gray-50 hover:bg-gray-100 cursor-pointer"
              }
            >
              <span className={"text-primary text-[14px]"}>Cloud Storage</span>
              <div
                className={"relative w-full h-[8px] rounded-full bg-gray-200"}
              >
                <div
                  style={{
                    width: `${usedPercentage}%`,
                  }}
                  className={`absolute h-full ${
                    usedPercentage > 80 ? " bg-red-500" : "bg-blue-500"
                  } rounded-full`}
                ></div>
              </div>
              <div className={"text-[12px]"}>
                {convertBytes(usedStorage)} of {convertBytes(totalStorage)} used
              </div>
            </div>
          )}
          <div
            onClick={() => handlers.logout(true)}
            className="setting_navLink border-none"
          >
            <IoLogOutOutline className="text-[18px]" /> Log Out
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default SettingDetail;
