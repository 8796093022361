import React from "react";
import { Route } from "react-router-dom";
import {
  CandidatesDetailsPage,
  CandidatesPage,
  DocumentsDetailsPage,
  DocumentsPage,
  MessageDetailsPage,
  MessagePage,
  MeterReadingDetailsPage,
  MeterReadingPage,
  NewCandidatesPage,
  NewDocumentPage,
  NewMessagePage,
  NewMeterReadingPage,
  NewNotePage,
  NewTripsPage,
  NotesDetailsPage,
  NotesPage,
  TrashPage,
  TripsDetailsPage,
  TripsPage,
} from "./pages";
import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="tools/">
      <Route path={"notes"} element={<NotesPage />} />
      <Route
        path={`notes/edit/:${ParamsStringEnum.ID}`}
        element={<NewNotePage />}
      />
      <Route path={"notes/new"} element={<NewNotePage />} />
      <Route
        path={`notes/details/:${ParamsStringEnum.ID}`}
        element={<NotesDetailsPage />}
      />
      {/*messages*/}
      <Route
        path={"messages"}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.MISC_ACCESS_MESSAGE]}
          >
            <MessagePage />
          </AccessVerifier>
        }
      />
      <Route
        path={"messages/new"}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.MISC_ACCESS_MESSAGE]}
          >
            <NewMessagePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`messages/edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.MISC_ACCESS_MESSAGE]}
          >
            <NewMessagePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`messages/details/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.MISC_ACCESS_MESSAGE]}
          >
            <MessageDetailsPage />
          </AccessVerifier>
        }
      />{" "}
      {/*meter-reading*/}
      <Route
        path={"meter-reading"}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.TOOLS_READ_METER_READING]}
          >
            <MeterReadingPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"meter-reading/new"}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.TOOLS_ADD_METER_READING]}
          >
            <NewMeterReadingPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`meter-reading/edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.TOOLS_EDIT_METER_READING]}
          >
            <NewMeterReadingPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`meter-reading/details/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.TOOLS_READ_METER_READING]}
          >
            <MeterReadingDetailsPage />
          </AccessVerifier>
        }
      />{" "}
      {/*trips*/}
      <Route
        path={"trips"}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.TOOLS_READ_TRIP]}
          >
            <TripsPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"trips/new"}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.TOOLS_ADD_TRIP]}
          >
            <NewTripsPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`trips/edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.TOOLS_EDIT_TRIP]}
          >
            <NewTripsPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`trips/details/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.CANDIDATE_READ_CANDIDATE]}
          >
            <TripsDetailsPage />
          </AccessVerifier>
        }
      />
      {/*candidates*/}
      <Route
        path={"candidates"}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.CANDIDATE_READ_CANDIDATE]}
          >
            <CandidatesPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"candidates/new/*"}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.CANDIDATE_ADD_CANDIDATE]}
          >
            <NewCandidatesPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`candidates/edit/:${ParamsStringEnum.ID}/*`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.CANDIDATE_EDIT_CANDIDATE]}
          >
            <NewCandidatesPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`candidates/details/:${ParamsStringEnum.ID}/*`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.CANDIDATE_READ_CANDIDATE]}
          >
            <CandidatesDetailsPage />
          </AccessVerifier>
        }
      />
      {/*documents*/}
      <Route
        path={"documents"}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.DOCUMENT_READ_DOCUMENT]}
          >
            <DocumentsPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"documents/new/*"}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.DOCUMENT_ADD_DOCUMENT]}
          >
            <NewDocumentPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`documents/edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.DOCUMENT_EDIT_DOCUMENT]}
          >
            <NewDocumentPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`documents/details/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.DOCUMENT_READ_DOCUMENT]}
          >
            <DocumentsDetailsPage />
          </AccessVerifier>
        }
      />
      {/*trash*/}
      <Route
        path={"trash"}
        element={
          <AccessVerifier
            isForPage={true}
            accessKeys={[SpaceAccessListEnum.MISC_ACCESS_TRASH]}
          >
            <TrashPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
