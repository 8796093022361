import React, { useContext, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BoxModal, MySelectField, MyTextField } from "components";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { UnitDataContext } from "context";
import { FormConcludeButtons } from "assets";
import {
  getCurrency,
  getInputDateFormat,
  decodeOwnerOptionsHandler,
} from "helpers";
import { OptionsContext } from "context";
import { ParamsStringEnum, QueryStringEnum } from "interfaces";
import { usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function EditListingModal() {
  const navigate = useNavigate();
  const params = useParams<ParamsStringEnum>();
  const [query] = useSearchParams();
  const {
    editListingHandler,
    getListingHandler,
    getListingDetailsHandler,
    listingDetails,
  } = useContext(UnitDataContext);
  const { getOwnerOptionsHandler, ownerOptions } = useContext(OptionsContext);
  let itemId = query?.get(QueryStringEnum?.ID);
  const { returnURL } = usePageQueryHooks();
  useEffect(() => {
    if (itemId) {
      getListingDetailsHandler(itemId);
    }
    getOwnerOptionsHandler();
  }, [itemId]);
  const initialValues = {
    ...(itemId && { _id: itemId }),
    property: params?.propertyId,
    owner: itemId ? listingDetails?.owner?._id : "",
    unit: params?.unitId,
    availableDate: itemId
      ? getInputDateFormat(listingDetails?.availableDate)
      : "",
    askingRent: itemId ? listingDetails?.askingRent : "",
    deposit: itemId ? listingDetails?.deposit : "",
    tenanciesTerm: itemId ? listingDetails?.tenanciesTerm : "",
  };
  const validationSchema = yup.object().shape({
    property: yup.string().required("required"),
    owner: yup.string().required("required"),
    unit: yup.string().required("required"),
    availableDate: yup.string().required("required"),
    askingRent: yup.string().required("required"),
    deposit: yup.string().required("required"),
    tenanciesTerm: yup.string().required("required"),
  });
  const submitHandler = async (values, actions) => {
    editListingHandler(values, actions, {
      async onSuccess(payload?: any) {
        await getListingHandler(params?.unitId);
      },
    });
  };
  return (
    <>
      <BoxModal
        isCloseAuto
        title={itemId ? "Edit Listing" : "Add Listing"}
        closeHandler={() => {
          if (returnURL) {
            navigate(returnURL);
          } else {
            navigate(-1);
          }
        }}
      >
        <Formik
          enableReinitialize
          onSubmit={submitHandler}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => {
            return (
              <Form className={"flex flex-col gap-5"}>
                <div
                  className={
                    "grid grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-5"
                  }
                >
                  <MyTextField
                    name={"availableDate"}
                    label={"Available Date"}
                    isRequired
                    type={"date"}
                  />
                </div>
                <div
                  className={
                    "grid grid-cols-2 md:grid-cols-1 sm:grid-cols-1  gap-5"
                  }
                >
                  <MySelectField
                    option={{
                      selectOptions: decodeOwnerOptionsHandler(ownerOptions),
                    }}
                    style={{
                      width: "350px",
                    }}
                    name={"owner"}
                    label={"Contact"}
                    isRequired
                  />
                </div>
                <div
                  className={
                    "grid grid-cols-2 md:grid-cols-1 sm:grid-cols-1  gap-5"
                  }
                >
                  <MyTextField
                    name={"askingRent"}
                    label={`Asking Rent (${getCurrency()})`}
                    placeholder={"eg. 250"}
                    isRequired
                    type={"number"}
                  />
                  <MyTextField
                    name={"deposit"}
                    placeholder={"eg. 250"}
                    label={`Deposit (${getCurrency()})`}
                    type={"number"}
                    isRequired
                  />
                </div>
                <div
                  className={"grid grid-cols-2 md:grid-cols-1 sm:grid-cols-1 "}
                >
                  <MyTextField
                    name={"tenanciesTerm"}
                    placeholder={"eg. Advance"}
                    label={"Tenancies Terms"}
                    isRequired
                  />
                </div>
                <FormConcludeButtons
                  isInModal
                  cancelButton={{
                    title: "Cancel",
                    handler() {
                      navigate(-1);
                    },
                  }}
                  submitButton={{
                    title: "Save",
                    isLoading: isSubmitting,
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </BoxModal>
    </>
  );
}

export default EditListingModal;
