import React, { useContext, useEffect } from "react";
import { BillingAddressContext } from "context";
import { IconTypeEnum, PackageCreateOrderPayloadInterface } from "interfaces";
import { BoxModal, MyTextField } from "components";
import { Form, Formik, useFormikContext } from "formik";
import { FormConcludeButtons, FormWrapper } from "assets";
import { BillingAddressFormik } from "../helpers";
import { getIconHelper } from "../../../helpers";

function EditBillingAddressModal({ closeHandler, id }) {
  const DeleteIcon = getIconHelper(IconTypeEnum.DELETE);
  const {
    getAllDataHandlers,
    editDetailsHandler,
    getDetailsHandler,
    details,
    deleteHandler,
  } = useContext(BillingAddressContext);
  const { setFieldValue, values } =
    useFormikContext<PackageCreateOrderPayloadInterface>();

  useEffect(() => {
    (async () => {
      if (id) {
        getDetailsHandler(id);
      }
    })();
  }, [id]);
  const onDeleteHandler = async () => {
    try {
      await deleteHandler(id, {
        onSuccess(payload?: any): any {
          if (values?.billingAddress === id) {
            setFieldValue("billingAddress", "");
          }
          getAllDataHandlers();
          closeHandler();
        },
      });
    } catch (err) {}
  };
  const submitHandler = (values, actions) => {
    editDetailsHandler(values, actions, {
      onSuccess(payload?: any): any {
        setFieldValue("billingAddress", payload);
        getAllDataHandlers();
        closeHandler();
      },
    });
  };
  return (
    <BoxModal
      title={id ? "Edit Delivery Address" : "Add New Delivery Address"}
      closeHandler={closeHandler}
    >
      <Formik
        enableReinitialize
        onSubmit={submitHandler}
        initialValues={BillingAddressFormik.initialValues(id && details)}
        validationSchema={BillingAddressFormik.validationSchema}
      >
        <Form className={"flex flex-col gap-10"}>
          <div className={"grid grid-cols-2 gap-5"}>
            <div className={"col-span-2"}>
              <MyTextField
                name={"fullName"}
                label={"Full Name"}
                placeholder={"eg: John Doe"}
                isRequired
              />{" "}
            </div>

            <MyTextField
              name={"mobile"}
              label={"Mobile"}
              placeholder={"eg: 9800000000"}
            />
            <MyTextField
              name={"email"}
              label={"Email"}
              placeholder={"eg: john@gmail.com"}
              isRequired
            />
          </div>
          <FormWrapper header={{ title: { name: "Address" } }}>
            <div className={`grid items-start grid-cols-3 gap-5 `}>
              <MyTextField
                placeholder={"Eg. 109 Olney Street"}
                isRequired={true}
                label={"Address-1"}
                name={"address1"}
              />
              <MyTextField
                placeholder={"Eg. 109 Olney Street 2"}
                label={"Address-2"}
                name={"address2"}
              />
              <MyTextField
                isRequired={true}
                label={"Town/City"}
                placeholder={"Eg. Manchester"}
                name={"city"}
              />
              <MyTextField
                isRequired={true}
                label={"Postal Code"}
                placeholder={"Enter postal code"}
                name={"postalCode"}
              />
              <MyTextField
                isRequired={true}
                label={"County"}
                placeholder={"Enter county name"}
                name={"county"}
              />
              <MyTextField
                isRequired={true}
                label={"Country"}
                placeholder={"Enter country name"}
                name={"country"}
              />
            </div>
          </FormWrapper>
          <FormConcludeButtons
            leftChildren={
              id && (
                <div
                  className={"flex items-center gap-2 cursor-pointer text-tRed"}
                  onClick={onDeleteHandler}
                >
                  <DeleteIcon />
                  <span>Delete Address</span>
                </div>
              )
            }
            cancelButton={{
              title: "Cancel",
              handler() {
                closeHandler();
              },
            }}
            submitButton={{
              title: "Save",
            }}
          />
        </Form>
      </Formik>
    </BoxModal>
  );
}

export default EditBillingAddressModal;
