import React from "react";
import { Route } from "react-router-dom";
import { ReportPage, PropertyReportPage } from "./pages";
import { AccessVerifier } from "templates";
import { SpaceAccessListEnum } from "../../interfaces";
function Index() {
  return (
    <Route path="report/">
      <Route
        path={``}
        index
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.MISC_ACCESS_REPORT_PAGE]}
            isForPage={true}
          >
            <ReportPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`property`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.MISC_ACCESS_REPORT_PAGE]}
            isForPage={true}
          >
            <PropertyReportPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
