import React, { createContext, useContext, useState } from "react";

import { Api } from "helpers";
import { ApiUrl } from "services";
import { HandlerCallbackInterface } from "interfaces";
import { AppContext } from "context";

import { FiscalYearEarningContextInterface } from "./interfaces";
import { useConfirmHook } from "useHooks";

export const RetainEarningContext =
  createContext<FiscalYearEarningContextInterface>({
    isDetailsLoading: false,
    details: undefined,
    getFiscalYearEarningHandler(query) {},
    transferFiscalYearEarningHandler(query, callback) {},
  });

function DataContextProvider({ children }) {
  const { getApi, putApi } = Api();
  const { confirm } = useConfirmHook();
  const {
    handlers: { setLoading, setError, setSuccess },
  } = useContext(AppContext);
  const [details, setDetails] = useState(undefined);
  const [isDetailsLoading, setDetailsLoading] = useState(false);
  //  handler
  const contextHandlers = {
    async transferFiscalYearEarningHandler(
      values,
      callback: HandlerCallbackInterface
    ) {
      try {
        const isConfirm = await confirm("Are you sure?");
        if (!isConfirm) {
          return;
        }

        setLoading(true);
        const res = await putApi(
          ApiUrl.currentYearEarning.put_transferCurrentFiscalYearEarning(values)
        );
        if (typeof callback.onSuccess === "function") {
          callback.onSuccess(res?.data);
        }
        setSuccess(true, "Transfer Successfully");
      } catch (err) {
        if (typeof callback.onError === "function") {
          callback?.onError(err);
        }
      } finally {
        setLoading(false);
      }
    },
    async getFiscalYearEarningHandler(fiscalYear: string) {
      try {
        setDetailsLoading(true);
        setDetails(null);
        const res = await getApi(
          ApiUrl.currentYearEarning.get_currentFiscalYearEarning(fiscalYear)
        );
        setDetails(res?.data);
      } catch (err) {
        setError(true, err?.message);
      } finally {
        setDetailsLoading(false);
      }
    },
  };

  const contextValue = {
    details,
    isDetailsLoading,
    ...contextHandlers,
  };
  return (
    <RetainEarningContext.Provider value={contextValue}>
      {children}
    </RetainEarningContext.Provider>
  );
}

export default DataContextProvider;
