import * as yup from "yup";
import {
  BillingAddressInterface,
  PackageCreateOrderPayloadInterface,
  PackageTimeType,
} from "interfaces";

export const CheckoutFormikValues = {
  initialValues: (
    values?: Partial<PackageCreateOrderPayloadInterface>
  ): PackageCreateOrderPayloadInterface => {
    return {
      duration: values?.duration || 1,
      propertyQuantity: values?.propertyQuantity || 0,
      purchaseType: values?.purchaseType || undefined,
      subscription: values?.subscription || "",
      timeUnit: values?.timeUnit || PackageTimeType.MONTHLY,
      paymentMethod: values?.paymentMethod,
      isNewCard: values?.isNewCard || false,
      billingAddress: values?.billingAddress,
      newCardDetails: {
        name: values?.newCardDetails?.name,
        address: {
          country: values?.newCardDetails?.address?.country,
          state: values?.newCardDetails?.address?.state,
          city: values?.newCardDetails?.address?.city,
          line1: values?.newCardDetails?.address?.line1,
        },
      },
    };
  },
  validationSchema: yup.object().shape({
    duration: yup.number().required("Duration is required"),
    propertyQuantity: yup.number().min(0, "invalid"),
    purchaseType: yup.string().required("Purchase type is required"),
    subscription: yup.string().required("Subscription is required"),
    timeUnit: yup.string().required("Time unit is required"),
    newCardDetails: yup.object().when("isNewCard", {
      is: true,
      then: yup.object().shape({
        name: yup.string().required("required"),
      }),
    }),
  }),
};

export const BillingAddressFormik = {
  initialValues: (values?: BillingAddressInterface) => {
    return {
      ...(values?._id ? { _id: values?._id } : {}),
      fullName: values?.fullName,
      mobile: values?.mobile,
      email: values?.email,
      address1: values?.address1,
      address2: values?.address1,
      postalCode: values?.postalCode,
      city: values?.city,
      county: values?.county,
      country: values?.country,
    };
  },
  validationSchema: yup.object().shape({
    fullName: yup.string().required("required"),
    mobile: yup.string(),
    email: yup.string().email("invalid").required("required"),
    address1: yup.string().required("required"),
    address2: yup.string(),
    postalCode: yup.string().required("required"),
    city: yup.string().required("required"),
    county: yup.string().required("required"),
    country: yup.string().required("required"),
  }),
};

export const getDurationHandler = (
  type: PackageTimeType
): { label: string; value: number }[] => {
  if (type === PackageTimeType.ANNUALLY) {
    return new Array(5).fill("").map((e, key) => {
      return {
        label: `No. of Year : ${key + 1}`,
        value: key + 1,
      };
    });
  } else {
    return new Array(12).fill("").map((e, key) => {
      return {
        label: `No. of month : ${key + 1}`,
        value: key + 1,
      };
    });
  }
};
