import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MyButton, TableCardComponent } from "components";
import { AccessVerifier, PageTemplate } from "templates";
import { IconTypeEnum, SpaceAccessListEnum } from "interfaces";
import { TableFilterCard } from "content";

import { DocumentsTableColumns } from "../../components";
import { DocumentContext } from "context";
import { usePageQueryHooks, usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function DocumentsPage() {
  const {
    allData,
    isLoading,
    totalArchiveDocs,
    totalDocs,
    getAllDataHandlers,
  } = useContext(DocumentContext);
  const navigate = useNavigate();
  const { propertyID, unitID } = usePageQueryHooks();
  const { isArchive, currentPage } = usePaginationQueryHooks();
  const pageQuery = {
    unit: unitID,
    property: propertyID,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, unitID, propertyID, currentPage]);

  const tableCardProps = {
    column: DocumentsTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      leftChildren: <TableFilterCard haveProperty haveUnit />,
      rightChildren: (
        <AccessVerifier
          accessKeys={[SpaceAccessListEnum.DOCUMENT_ADD_DOCUMENT]}
          isForPage={false}
        >
          <MyButton
            onClick={() => {
              navigate(PageLinks.documents?.new);
            }}
            iconType={IconTypeEnum.ADD}
            name={"Add Document"}
          />
        </AccessVerifier>
      ),
    },
  };
  return (
    <PageTemplate
      title={"Document"}
      breadCrumb={[
        {
          name: "My Document",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default DocumentsPage;
