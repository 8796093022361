import React from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { FormToggleWrapperInterface } from "./interfaces";
function FormToggleWrapper({
  title,
  onClick,
  formName,
  activatedForm,
  children,
}: FormToggleWrapperInterface) {
  const isActive = formName === activatedForm;
  return (
    <div
      onClick={() => onClick(formName)}
      className={"py-5 border-b-2  p-1 cursor-pointer"}
    >
      {/*  header*/}
      <div className={`flex items-center justify-between  select-none `}>
        <span className={`font-bold text-[18px] ${title?.className}`}>
          {title?.name}
        </span>
        {!isActive ? (
          <AiOutlinePlus className={"text-[24px] text-primary"} />
        ) : (
          <AiOutlineMinus className={"text-[24px] text-primary"} />
        )}
      </div>
      {/* children*/}
      {isActive && (
        <div
          onClick={(e) => {
            e?.stopPropagation();
          }}
          className={"mt-10"}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default FormToggleWrapper;
