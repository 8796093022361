import React, { useContext, useEffect } from "react";

import { PageTemplate } from "templates";

import { ManageNotificationContext } from "context";

import { usePaginationQueryHooks } from "useHooks";

import { ManageNotificationTableColumns } from "../components";
import { TableCardComponent } from "components";

function ManageNotificationPage() {
  const { allData, isLoading, getAllDataHandlers } = useContext(
    ManageNotificationContext
  );
  const { currentPage, paginationQuery } = usePaginationQueryHooks();

  useEffect(() => {
    getAllDataHandlers(paginationQuery);
  }, [currentPage]);
  const tableCardProps = {
    column: ManageNotificationTableColumns(),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
  };

  return (
    <PageTemplate
      title={"Settings"}
      breadCrumb={[
        {
          name: "Manage Notifications",
        },
      ]}
    >
      <TableCardComponent {...tableCardProps} />
    </PageTemplate>
  );
}

export default ManageNotificationPage;
