import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { NameValueText } from "assets";
import { AppContext } from "context";
import { getCurrency } from "helpers";
import { BoxModal, DocumentView, FileViewCard } from "components";
import { useDateHook, usePageQueryHooks } from "useHooks";
import { ParamsStringEnum } from "interfaces";
import { PropertyDataContext } from "context";
import useDateHooks from "../../../useHooks/useDateHooks";

function OwnershipDetailsModal() {
  const navigate = useNavigate();
  const params = useParams<ParamsStringEnum>();
  const { ownershipDetails, getOwnershipDetailHandler } =
    useContext(PropertyDataContext);
  const { returnURL } = usePageQueryHooks();
  const { getStaffFullName } = useDateHooks();

  const {
    handlers: { setLoading },
  } = useContext(AppContext);

  const ownerId = params.id;
  const propertyId = params.propertyId;

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([
        ownerId && getOwnershipDetailHandler(ownerId, propertyId),
      ]);
      setLoading(false);
    })();
  }, [ownerId]);
  const { getDateHandler } = useDateHook();
  let isSoleTrader = ownershipDetails?.ownershipType?.value === "sole-traders";
  let isLeaseHold = ownershipDetails?.ownershipType?.value === "lease-hold";
  let isRentToRent = ownershipDetails?.ownershipType?.value === "rent-to-rent";
  let isFreeHold = ownershipDetails?.ownershipType?.value === "free-hold";

  return (
    <BoxModal
      isCloseAuto
      title={`Ownership Details`}
      closeHandler={() => {
        returnURL ? navigate(returnURL) : navigate(-1);
      }}
    >
      <div className={"flex flex-col gap-7"}>
        <div className={"grid grid-cols-2 gap-7"}>
          <NameValueText
            name={"Name"}
            value={getStaffFullName(ownershipDetails?.owner?.name)}
          />
          <NameValueText
            name={"Email"}
            value={ownershipDetails?.owner?.email || "-"}
          />
          <NameValueText
            name={"Contact Number"}
            value={ownershipDetails?.owner?.phone || "-"}
          />
        </div>
        {ownershipDetails?.owner?.photo && (
          <div className={"grid grid-cols-2 gap-7"}>
            <FileViewCard filePath={ownershipDetails?.owner?.photo} />
          </div>
        )}

        <div className={"grid grid-cols-2 gap-5 items-start"}>
          <NameValueText
            name={"Owner Type"}
            value={ownershipDetails?.ownerType?.label || "-"}
          />
          <NameValueText
            name={"Ownership(%)"}
            value={ownershipDetails?.ownership || "-"}
          />
        </div>
        {ownershipDetails?.ownerType && !isSoleTrader && (
          <div className={"grid grid-cols-2 gap-5 items-start"}>
            <NameValueText
              name={"Company Name"}
              value={ownershipDetails?.limitedCompany?.companyName || "-"}
            />
            <NameValueText
              name={"Company Registration Number"}
              value={
                ownershipDetails?.limitedCompany?.registrationNumber || "-"
              }
            />
          </div>
        )}
        <div className={"grid grid-cols-2 gap-5 items-start"}>
          <NameValueText
            name={"Agreement Expiry Date"}
            value={getDateHandler({
              date: ownershipDetails?.agreementExpiryAt,
            })}
          />{" "}
          <NameValueText
            name={"Ownership Type"}
            value={ownershipDetails?.ownershipType?.label || "-"}
          />
        </div>
        {ownershipDetails?.ownershipType && (isFreeHold || isLeaseHold) && (
          <>
            <div className={"grid grid-cols-2 gap-5 items-start"}>
              <NameValueText
                name={"Purchase Price"}
                value={`${getCurrency()}${
                  ownershipDetails?.hold?.purchasePrice || "-"
                }`}
              />
              <NameValueText
                name={"Purchase Date"}
                value={getDateHandler({
                  date: ownershipDetails?.hold?.purchaseDate,
                })}
              />
            </div>
            <div className={"grid grid-cols-2 gap-5 items-start"}>
              <NameValueText
                name={"Current Value"}
                value={`${getCurrency()}${
                  ownershipDetails?.hold?.currentValue
                }`}
              />
              <NameValueText
                name={"Valuation Date"}
                value={getDateHandler({
                  date: ownershipDetails?.hold?.valuationDate,
                })}
              />
            </div>
          </>
        )}
        {ownershipDetails?.ownershipType && isLeaseHold && (
          <>
            <div className={"grid grid-cols-2 gap-5 items-start"}>
              <NameValueText
                name={"Ground Rate"}
                value={`${getCurrency()}${
                  ownershipDetails?.hold?.groundRent || "-"
                }`}
              />
              <NameValueText
                name={"Service Charge"}
                value={`${getCurrency()}${
                  ownershipDetails?.hold?.serviceCharge || "-"
                }`}
              />
            </div>
            <div className={"grid grid-cols-2 gap-5 items-start"}>
              <NameValueText
                name={"Lease End Date"}
                value={getDateHandler({
                  date: ownershipDetails?.hold?.leaseExpiry,
                })}
              />
            </div>
          </>
        )}
        {ownershipDetails?.ownershipType && isRentToRent && (
          <>
            <div className={"grid grid-cols-2 gap-5 items-start"}>
              <NameValueText
                name={"Rent Amount"}
                value={`${getCurrency()}${
                  ownershipDetails?.rentToRent?.rentPay || "-"
                }`}
              />
              <NameValueText
                name={"Lease Term (in Year)"}
                value={`${getCurrency()}${
                  ownershipDetails?.rentToRent?.leaseTerm || "-"
                }`}
              />
            </div>
            <div className={"grid grid-cols-2 gap-5 items-start"}>
              <NameValueText
                name={"Payment Terms (in Year)"}
                value={`${getCurrency()}${
                  ownershipDetails?.rentToRent?.paymentTerm || "-"
                }`}
              />
              <NameValueText
                name={`Deposit (${getCurrency()})`}
                value={`${getCurrency()}${
                  ownershipDetails?.rentToRent?.deposit || "-"
                }`}
              />
            </div>
          </>
        )}
      </div>
    </BoxModal>
  );
}

export default OwnershipDetailsModal;
