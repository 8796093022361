import React, { useContext, useEffect } from "react";
import { useFormikContext } from "formik";

import { FieldTypeEnum, PropertyOwnerInterface } from "interfaces";
import { OptionsContext } from "context";
import { MySelectField, MyTextField } from "components";
import { decodeDynamicFieldHandler, getCurrency } from "helpers";

function OwnershipForms() {
  const { values } = useFormikContext<PropertyOwnerInterface<string>>();
  const { fieldTypeOptions, getOptionsHandler } = useContext(OptionsContext);
  let selectedOwnerType = fieldTypeOptions[FieldTypeEnum.OWNER_TYPE]?.find(
    (e) => e?._id === values?.ownerType
  );
  let selectedOwnershipType = fieldTypeOptions[
    FieldTypeEnum.OWNERSHIP_TYPE
  ]?.find((e) => e?._id === values?.ownershipType);
  let isSoleTrader = selectedOwnerType?.value === "sole-traders";
  let isLeaseHold = selectedOwnershipType?.value === "lease-hold";
  let isRentToRent = selectedOwnershipType?.value === "rent-to-rent";
  let isFreeHold = selectedOwnershipType?.value === "free-hold";
  useEffect(() => {
    getOptionsHandler(FieldTypeEnum.OWNER_TYPE);
    getOptionsHandler(FieldTypeEnum.OWNERSHIP_TYPE);
  }, []);
  return (
    <>
      <div className={"grid grid-cols-2 gap-5 items-start"}>
        <MySelectField
          option={{
            selectOptions: decodeDynamicFieldHandler(
              fieldTypeOptions[FieldTypeEnum.OWNER_TYPE]
            ),
          }}
          isCreatable={{
            type: FieldTypeEnum.OWNER_TYPE,
          }}
          isClearable
          name={"ownerType"}
          label={"Owner Type"}
          isRequired
        />
        <MyTextField
          placeholder={"eg.25"}
          name={"ownership"}
          isNumberOnly
          label={"Ownership(%)"}
          isRequired
        />
      </div>
      {values?.ownerType && !isSoleTrader && (
        <div className={"grid grid-cols-2 gap-5 items-start"}>
          <MyTextField
            placeholder={"eg. company"}
            name={"companyName"}
            label={"Company Name"}
            isRequired
          />
          <MyTextField
            placeholder={"eg.2540"}
            name={"registrationNumber"}
            label={"Company Registration Number"}
            isRequired
          />
        </div>
      )}
      <div className={"grid grid-cols-2 gap-5 items-start"}>
        <MyTextField
          placeholder={"eg.25"}
          name={"agreementExpiryAt"}
          label={"Agreement Expiry Date"}
          isRequired
          type={"date"}
        />
        <MySelectField
          option={{
            selectOptions: decodeDynamicFieldHandler(
              fieldTypeOptions[FieldTypeEnum.OWNERSHIP_TYPE]
            ),
          }}
          isClearable
          isCreatable={{
            type: FieldTypeEnum.OWNERSHIP_TYPE,
          }}
          name={"ownershipType"}
          label={"Ownership Type"}
          isRequired
        />
      </div>
      {values?.ownershipType && (isFreeHold || isLeaseHold) && (
        <>
          <div className={"grid grid-cols-2 gap-5 items-start"}>
            <MyTextField
              isNumberOnly
              name={"purchasePrice"}
              label={"Purchase Price"}
              placeholder={"eg. 180000"}
              isRequired
            />
            <MyTextField
              name={"purchaseDate"}
              type={"date"}
              label={"Purchase Date"}
              isRequired
            />
          </div>
          <div className={"grid grid-cols-2 gap-5 items-start"}>
            <MyTextField
              isNumberOnly
              name={"currentValue"}
              label={"Current Value"}
              placeholder={"eg. 180000"}
              isRequired
            />
            <MyTextField
              name={"valuationDate"}
              type={"date"}
              label={"Valuation Date"}
              isRequired
            />
          </div>
        </>
      )}
      {values?.ownershipType && isLeaseHold && (
        <>
          <div className={"grid grid-cols-2 gap-5 items-start"}>
            <MyTextField
              isNumberOnly
              name={"groundRent"}
              label={`Ground Rent (${getCurrency()})`}
              placeholder={"eg. 180000"}
              isRequired
            />
            <MyTextField
              name={"serviceCharge"}
              label={`Service Charge (${getCurrency()})`}
              placeholder={"eg. 180"}
              isNumberOnly
              isRequired
            />
          </div>
          <div className={"grid grid-cols-2 gap-5 items-start"}>
            <MyTextField
              name={"leaseExpiry"}
              label={"Lease End Date"}
              type={"date"}
              isRequired
            />
          </div>
        </>
      )}
      {values?.ownershipType && isRentToRent && (
        <>
          <div className={"grid grid-cols-2 gap-5 items-start"}>
            <MyTextField
              name={"rentPay"}
              label={`Rent Amount (${getCurrency()})`}
              placeholder={"eg. 180000"}
              isRequired
              isNumberOnly
            />
            <MyTextField
              name={"leaseTerm"}
              label={"Lease Term (in Year)"}
              placeholder={"Type here"}
              isNumberOnly
              isRequired
            />
          </div>
          <div className={"grid grid-cols-2 gap-5 items-start"}>
            <MyTextField
              name={"paymentTerm"}
              label={"Payment Terms (in Year)"}
              placeholder={"Type here"}
              isNumberOnly
              isRequired
            />
            <MyTextField
              name={"deposit"}
              isNumberOnly
              label={`Deposit (${getCurrency()})`}
              placeholder={"Type here"}
              isRequired
            />
          </div>
        </>
      )}
    </>
  );
}

export default OwnershipForms;
