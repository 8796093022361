import React from "react";
import { Route } from "react-router-dom";
import {
  MaintenancePage,
  NewMaintenancePage,
  MaintenanceDetailsPage,
  EditMaintenancePage,
} from "./pages";
import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="services/maintenance">
      {/*maintenance*/}
      <Route
        path={""}
        element={
          <AccessVerifier
            accessKeys={[
              SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_READ_REPAIR_AND_MAINTENANCE,
            ]}
            isForPage={true}
          >
            <MaintenancePage />
          </AccessVerifier>
        }
      />
      <Route
        path={"new/*"}
        element={
          <AccessVerifier
            accessKeys={[
              SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_ADD_REPAIR_AND_MAINTENANCE,
            ]}
            isForPage={true}
          >
            <NewMaintenancePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[
              SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_EDIT_REPAIR_AND_MAINTENANCE,
            ]}
            isForPage={true}
          >
            <EditMaintenancePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`details/:${ParamsStringEnum.ID}/*`}
        element={
          <AccessVerifier
            accessKeys={[
              SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_READ_REPAIR_AND_MAINTENANCE,
            ]}
            isForPage={true}
          >
            <MaintenanceDetailsPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
