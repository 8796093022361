import visa from "../../images/paymentCards/visa.png";
import americanexpress from "../../images/paymentCards/americanexpress.png";
import dinersclub from "../../images/paymentCards/dinersclub.jpg";
import discover from "../../images/paymentCards/discover.jpg";
import elo from "../../images/paymentCards/elo.png";
import hiper from "../../images/paymentCards/hiper.png";
import jcb from "../../images/paymentCards/jcb.png";
import mastercard from "../../images/paymentCards/mastercard.png";
import mir from "../../images/paymentCards/mir.png";
import unionpay from "../../images/paymentCards/unionpay.png";

function getCardImage(type) {
  switch (type) {
    case "visa":
      return visa;
    case "mastercard":
      return mastercard;
    case "amex":
      return americanexpress;
    case "diners club":
      return dinersclub;
    case "discover":
      return discover;
    case "jcb":
      return jcb;
    case "unionpay":
      return unionpay;
    case "maestro":
      return mastercard;
    case "mir":
      return mir;
    case "elo":
      return elo;
    case "hiper":
      return hiper;
    case "hipercard":
      return hiper;
    default:
      return visa;
  }
}

export default getCardImage;
