import React, { useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import { DataContext } from "../context";
import { LoginFormik } from "../helpers";
import { MyButton, MyTextField, MyPasswordInput } from "components";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context";
function LoginPage() {
  const {
    handlers: { dispatchLogoutActionHandler },
  } = useContext(AppContext);
  const { loginHandler } = useContext(DataContext);
  useEffect(() => {
    dispatchLogoutActionHandler();
  }, []);
  const navigate = useNavigate();
  return (
    <Formik onSubmit={loginHandler} {...LoginFormik}>
      {({ isSubmitting, errors }) => {
        return (
          <Form className={"flex flex-col gap-10 text-[14px]"}>
            <div className={"flex flex-col gap-5"}>
              <span className={"text-[14px] text-tRed"}>
                {typeof errors?.error === "string" && `*${errors?.error}`}
              </span>
              <MyTextField
                name={"email"}
                label={"Email Address"}
                required
                placeholder={"Enter your email address"}
              />
              <MyPasswordInput
                name={"password"}
                label={"Password"}
                required
                placeholder={"Enter your password"}
              />
              <div className={"text-right"}>
                <span
                  onClick={() => navigate("/forgotPassword")}
                  className={"underline  text-primary  cursor-pointer"}
                >
                  Forgot Password?
                </span>
              </div>
            </div>
            <MyButton name={"Login"} type={"submit"} isLoading={isSubmitting} />
            <div>
              <span>New Landlord?</span>
              <span
                onClick={() => navigate("/register")}
                className={"text-primary font-bold cursor-pointer"}
              >
                {" "}
                Sign Up
              </span>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default LoginPage;
