import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import { OptionsContext } from "context";
import { BoxModal, MyCheckButton } from "components";
import { DynamicListInterface, FieldTypeEnum } from "interfaces";
import { FormConcludeButtons } from "assets";
import { ParamsStringEnum } from "interfaces";

import { PropertyDataContext } from "context";

function EditAmenitiesModal() {
  const [isLoading, setLoading] = useState(true);
  const [tempAmenities, setTempAmenities] = useState<DynamicListInterface[]>(
    []
  );
  const navigate = useNavigate();
  const params = useParams<ParamsStringEnum>();
  const { getOptionsHandler, fieldTypeOptions } = useContext(OptionsContext);
  const { getDetailsHandler, editDetailsHandler, details } =
    useContext(PropertyDataContext);
  let amenitiesOptions;
  amenitiesOptions = fieldTypeOptions[FieldTypeEnum.PROPERTY_AMENITIES];

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getOptionsHandler(FieldTypeEnum.PROPERTY_AMENITIES);
      setLoading(false);
      await getDetailsHandler(params?.propertyId);
      setTempAmenities(details?.amenities);
    })();
  }, []);
  const checkHandler = (item: DynamicListInterface) => {
    let tempData = [...tempAmenities];
    let index = tempData?.findIndex((e) => e?._id === item?._id);
    if (index > -1) {
      tempData.splice(index, 1);
    } else {
      tempData.push(item);
    }
    setTempAmenities(tempData);
  };
  const onSubmitHandler = async () => {
    let payload = {
      _id: params?.propertyId,
      amenities: tempAmenities?.map((e) => {
        return e?._id;
      }),
    };
    await editDetailsHandler(payload, null, {
      async onSuccess(payload?: any) {
        await getDetailsHandler(params?.propertyId);
      },
    });
  };
  return (
    <>
      <BoxModal
        title={"Add/Edit Amenities"}
        isCloseAuto
        closeHandler={() => navigate(-1)}
      >
        <div className={"flex flex-col gap-5"}>
          <div className={"grid grid-cols-2 items-start justify-start gap-5"}>
            {isLoading &&
              new Array(8).fill("").map((e, key) => {
                return <Skeleton key={key} className={"w-[150px]"} />;
              })}
            {!isLoading &&
              amenitiesOptions?.map((e, key) => {
                let isChecked = tempAmenities?.some((_e) => _e?._id === e?._id);
                return (
                  <div className={"flex items-start"} key={key}>
                    <MyCheckButton
                      value={e?.value}
                      label={e?.label}
                      isChecked={isChecked}
                      changeHandler={() => checkHandler(e)}
                    />
                  </div>
                );
              })}
          </div>
          <FormConcludeButtons
            isInModal
            cancelButton={{
              title: "Cancel",
            }}
            submitButton={{
              title: "Save",
              handler: async () => {
                await onSubmitHandler();
              },
            }}
          />
        </div>
      </BoxModal>
    </>
  );
}

export default EditAmenitiesModal;
