import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MyButton, TableCardComponent } from "components";
import { PageTemplate } from "templates";
import { IconTypeEnum } from "interfaces";
import { TableFilterCard, L_MTableColumns, L_MStatsContainer } from "content";
import { PageLinks } from "routes";

import { LoanContext } from "context";
import { usePageQueryHooks, usePaginationQueryHooks } from "useHooks";

function L_MPage() {
  const {
    getAllDataHandlers,
    allData,
    isLoading,
    totalArchiveDocs,
    totalDocs,
  } = useContext(LoanContext);

  const navigate = useNavigate();
  const { isArchive, currentPage } = usePaginationQueryHooks();
  const { unitID, propertyID } = usePageQueryHooks();
  const pageQuery = {
    unit: unitID,
    property: propertyID,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, currentPage, unitID, propertyID]);

  const tableCardProps = {
    column: L_MTableColumns(pageQuery),

    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      leftChildren: <TableFilterCard haveProperty haveUnit />,
      rightChildren: (
        <MyButton
          onClick={() => {
            navigate(PageLinks.loansAndMortgages.new);
          }}
          iconType={IconTypeEnum.ADD}
          name={"Add Loans/Mortgages"}
        />
      ),
      aboveTableChildren: (
        <L_MStatsContainer unit={unitID} property={propertyID} />
      ),
    },
  };
  return (
    <PageTemplate
      title={"Loans/Mortgages"}
      breadCrumb={[
        {
          name: "Loans/Mortgages",
        },
      ]}
      scrollAll
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default L_MPage;
