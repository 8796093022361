import React, { useContext, useEffect } from "react";
import { PageTemplate } from "templates";
import { Form, Formik } from "formik";
import { AppContext } from "context";
import * as yup from "yup";
import { FormConcludeButtons } from "assets";
import { PageLinks } from "routes";
import { MyTextField } from "components";
import { SpaceSettingFormikValues } from "../helpers";

function EditBravoSettingPage() {
  const {
    state: { spaceSettings },
    handlers: { editSpaceSettingsHandler, getSpaceSettingsHandler },
  } = useContext(AppContext);
  const submitHandler = (payload) => {
    editSpaceSettingsHandler(payload);
  };
  useEffect(() => {
    getSpaceSettingsHandler();
  }, []);
  return (
    <PageTemplate
      backNavigation={{
        title: "Brevo Configuration",
        path: PageLinks.dashboard.list,
      }}
      title={"Settings"}
      scrollAll
    >
      <Formik
        enableReinitialize
        validationSchema={SpaceSettingFormikValues.validationSchema}
        initialValues={SpaceSettingFormikValues.initialValues(spaceSettings)}
        onSubmit={submitHandler}
      >
        <Form className={"flex flex-col gap-10"}>
          <div
            className={
              "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start gap-5"
            }
          >
            <MyTextField
              autoFocus
              name={"brevo.senderName"}
              label={"Sender name"}
              placeholder={"eg. UKPA Company"}
            />
            <MyTextField
              name={"brevo.senderEmail"}
              label={"Sender email"}
              placeholder={"eg. my.company@ukpa.com"}
            />
            <div className={"col-span-2 sm:col-span-1"}>
              <MyTextField
                label={"Brevo key"}
                name={"brevo.brevoKey"}
                placeholder={"eg. a7858 a7w85 a7w6"}
              />
            </div>
          </div>
          <FormConcludeButtons
            submitButton={{
              title: "Save ",
            }}
            cancelButton={{
              title: "Cancel",
            }}
          />
        </Form>
      </Formik>
    </PageTemplate>
  );
}

export default EditBravoSettingPage;
