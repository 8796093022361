import { MyCheckButtonInterface, MyToggleButtonInterface } from "./interfaces";
import { AiFillCheckCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { getIconHelper } from "../../helpers";
import { IconTypeEnum } from "../../interfaces";

export default function MyCheckButton({
  isChecked,
  label,
  changeHandler,
  value,
  checkIcon,
}: MyCheckButtonInterface) {
  const RadioCheckIcon = getIconHelper(IconTypeEnum.SELECTED_RADIO);
  const RadioUnCheckIcon = getIconHelper(IconTypeEnum.UN_SELECTED_RADIO);
  const onClickHandler = () => {
    if (typeof value === "boolean") {
      changeHandler(!value);
    } else {
      changeHandler(value);
    }
  };
  let SelectedIcon = (
    <AiFillCheckCircle className={" text-tGreen  text-[24px]"} />
  );
  let UnSelectedIcon = (
    <AiOutlineCheckCircle className={"text-gray-300  text-[24px]"} />
  );

  if (checkIcon === "radio") {
    SelectedIcon = <RadioCheckIcon className={"text-primary  text-[24px]"} />;
    UnSelectedIcon = (
      <RadioUnCheckIcon className={"text-tLightGray  text-[24px]"} />
    );
  }

  return (
    <div
      className={"flex items-center gap-3 cursor-pointer select-none"}
      onClick={onClickHandler}
    >
      <div className={""}>{isChecked ? SelectedIcon : UnSelectedIcon}</div>

      <span className={"text-[14px]"}>{label}</span>
    </div>
  );
}
