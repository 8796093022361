import React, { useContext } from "react";
import { IconTypeEnum, NotificationInterface } from "interfaces";
import { getIconHelper } from "helpers";
import { MyMoreOptionButton } from "components";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "routes";
import Skeleton from "react-loading-skeleton";
import { NotificationContext } from "context";
import moment from "moment";

function NotificationCard({
  details,
  isLoading,
}: {
  details?: NotificationInterface;
  isLoading?: boolean;
}) {
  const {
    markNotificationHandler,
    getAllDataHandlers,
    archiveHandler,
    deleteHandler,
  } = useContext(NotificationContext);
  const DotIcon = getIconHelper(IconTypeEnum.DOT);
  const MailIcon = getIconHelper(IconTypeEnum.MAIL);
  const CheckAllIcon = getIconHelper(IconTypeEnum.CHECK_ALL);
  const ClockIcon = getIconHelper(IconTypeEnum.CLOCK);
  const navigate = useNavigate();
  const getDateLabel = () => {
    const notificationDate = moment(details?.createdAt);
    const currentDate = moment();
    const daysDifference = currentDate.diff(notificationDate, "days");

    if (daysDifference > 3) {
      return notificationDate.format("YYYY-MM-DD HH:mm"); // Adjust the format as needed
    } else {
      return notificationDate.fromNow();
    }
  };

  return (
    <div
      onClick={() => navigate(PageLinks.notification.details(details?._id))}
      className={`flex items-start justify-start p-2 py-5 rounded-md cursor-pointer ${
        details?.isRead
          ? "bg-white hover:bg-gray-50"
          : "bg-gray-100 hover:bg-gray-50"
      }`}
    >
      <DotIcon
        className={`text-[45px] ${
          isLoading
            ? "text-gray-200"
            : !details?.isRead
            ? "text-primary"
            : "text-gray-200"
        }`}
      />
      <div className={"flex-1 flex flex-col gap-2"}>
        <div className={"flex flex-1 justify-between items-center"}>
          {isLoading ? (
            <Skeleton className={"w-[250px]"} />
          ) : (
            <b className={"text-[18px]"}>{details?.title}</b>
          )}
          {!isLoading && (
            <div className={"flex gap-5 items-center"}>
              <div
                className={"flex items-center gap-2 text-[14px]"}
                onClick={(e) => {
                  e?.stopPropagation();
                  markNotificationHandler(
                    details?._id,
                    details?.isRead ? true : false,
                    {
                      onSuccess(payload?: any): any {
                        getAllDataHandlers();
                      },
                    }
                  );
                }}
              >
                {details?.isRead ? (
                  <>
                    <MailIcon className={"text-[16px]"} />
                    <span>Unread</span>
                  </>
                ) : (
                  <>
                    <CheckAllIcon className={"text-[16px]"} />
                    <span>Mark as read</span>
                  </>
                )}
              </div>
              <MyMoreOptionButton
                options={[
                  {
                    name: "Details",
                    handler() {
                      navigate(PageLinks.notification.details(details?._id));
                    },
                  },
                  {
                    name: "Delete",
                    handler() {
                      deleteHandler(details?._id, {
                        onSuccess(payload?: any): any {
                          getAllDataHandlers();
                        },
                      });
                    },
                  },
                  {
                    name: details?.isArchived ? "UnArchive" : "Archive",
                    handler() {
                      archiveHandler(
                        details?._id,
                        details?.isArchived ? true : false,
                        {
                          onSuccess(payload?: any): any {
                            getAllDataHandlers();
                          },
                        }
                      );
                    },
                  },
                ]}
              />
            </div>
          )}
        </div>
        {isLoading ? (
          <Skeleton className={"h-[100px]"} />
        ) : (
          <p className={"text-justify text-[16px]"}>{details?.body}</p>
        )}
        {details?.link && (
          <div
            className={`${
              details?.isRead ? "bg-gray-100" : "bg-white"
            }  rounded-full px-5 py-1 text-[14px]`}
          >
            {details?.link || "asdfjl;kjasd lkjas df"}
          </div>
        )}
        {details?.createdAt && (
          <div className={"flex items-center gap-2 text-[14px]"}>
            <ClockIcon />
            {getDateLabel()}
          </div>
        )}
      </div>
    </div>
  );
}

export default NotificationCard;
