import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import "./styles.css";
import { searchInterface } from "./interfaces";
import { usePageQueryHooks, usePaginationQueryHooks } from "../../useHooks";

export default function MySearchInput({
  placeholder,
  paramsName,
  type,
  label,
}: searchInterface) {
  const [params, setParams] = useSearchParams();
  const { searchKeyword } = usePageQueryHooks();
  const [value, setValue] = useState(searchKeyword);
  const { setCurrentPage } = usePaginationQueryHooks();
  const onChangeText = (e) => {
    setValue(e.target.value);
    params.set(paramsName, e?.target?.value);
    setParams(params);
    setCurrentPage("1");
  };

  const submitHandler = () => {
    if (!value) {
      params.delete(paramsName);
    } else if (params.get(paramsName)) {
      params.set(paramsName, encodeURIComponent(value));
    } else {
      params.append(paramsName, encodeURIComponent(value));
    }
    setCurrentPage("1");
    setParams(params);
  };
  return (
    <div className={"input_container"}>
      {label && <label className={"input_label"}>{label}</label>}
      <input
        type={type || "text"}
        onChange={onChangeText}
        placeholder={placeholder || "search..."}
        value={value}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            submitHandler();
          }
        }}
        className={` rounded-full outline-none  focus:border-[#2680EB] border-[1px] border-[#C0C0C0] text-[16px] pl-6 h-[48px]`}
      />
    </div>
  );
}
