import React, { useContext } from "react";
import { EventInterface, IconTypeEnum, SpaceAccessListEnum } from "interfaces";
import { useAuthorizationHook, useDateHook } from "useHooks";
import { getIconHelper } from "helpers";
import Skeleton from "react-loading-skeleton";
import { EventContext } from "context";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "routes";
import { MyMoreOptionButton } from "components";
import { CollapseUserImage } from "../../../assets";

interface propsInterface {
  isLoading?: boolean;
  event?: EventInterface;
  pageQuery?: any;
}
function EventCard({ isLoading, event, pageQuery }: propsInterface) {
  const { deleteHandler, getAllDataHandlers, getMonthEventHandler } =
    useContext(EventContext);
  const navigate = useNavigate();
  const { getDateHandler } = useDateHook();
  const PropertyIcon = getIconHelper(IconTypeEnum.PROPERTY);
  const AddressIcon = getIconHelper(IconTypeEnum.ADDRESS);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([SpaceAccessListEnum.EVENT_EDIT_EVENT]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.EVENT_DEL_EVENT,
  ]);
  return (
    <div
      className={
        "flex flex-col gap-2 bg-tGray text-[16px] rounded-md p-2 cursor-pointer"
      }
    >
      <div className={"flex items-center justify-between"}>
        <div className={"flex items-center gap-2"}>
          {isLoading ? (
            <Skeleton className={"w-[180px]"} />
          ) : (
            <>
              <b className={"text-tBlue"}>{event?.name}</b>
              <span className={"text-gray-400 text-[12px]"}>
                {getDateHandler({ date: event?.startDate })}
              </span>
            </>
          )}
        </div>
        <MyMoreOptionButton
          isOutline
          options={[
            canEdit && {
              name: "Edit",
              handler() {
                navigate(PageLinks.events.edit(event?._id));
              },
            },
            canDelete && {
              name: "Delete",
              handler() {
                deleteHandler(event?._id, {
                  async onSuccess(payload?: any) {
                    await getAllDataHandlers(pageQuery);
                    await getMonthEventHandler({
                      year: new Date(pageQuery?.startDate).getFullYear(),
                      month: new Date(pageQuery?.startDate).getMonth() + 1,
                    });
                  },
                });
              },
            },
          ]}
        />
      </div>
      <div className={"flex items-center gap-2"}>
        <PropertyIcon
          className={"text-primary text-[18px] w-[18px] h-[18px] fill-primary"}
        />
        {isLoading ? (
          <Skeleton className={"w-[100px]"} />
        ) : (
          <span>{event?.property?.propertyID}</span>
        )}
      </div>
      <div className={"flex items-center gap-2"}>
        <AddressIcon className={"text-primary text-[18px]"} />
        {isLoading ? (
          <Skeleton className={"w-[100px]"} />
        ) : (
          <span>{event?.location}</span>
        )}
      </div>
      <div className={"flex items-center "}>
        <CollapseUserImage users={event?.participants} />
      </div>
    </div>
  );
}

export default EventCard;
