import React, { createContext, useContext, useState } from "react";

import { Api } from "helpers";
import { ApiUrl } from "services";
import { AppContext } from "context";
import { useConfirmHook, usePaginationQueryHooks } from "useHooks";
import {
  CreateOrderCalculationInterface,
  HandlerCallbackInterface,
  PackageCreateOrderPayloadInterface,
} from "interfaces";

import { SubscriptionContextInterface } from "./interface";
import { Outlet, useNavigate } from "react-router-dom";
import { FormikHelpers } from "formik/dist/types";
import {
  CardCvcElement,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PageLinks } from "routes";

export const SubscriptionContext = createContext<SubscriptionContextInterface>({
  validationError: "",
  isStripePaymentMethodLoading: false,
  stripePaymentMethod: [],

  isSubscriptionPackageLoading: false,
  subscriptionPackages: [],

  isDetailsLoading: false,
  activeSubscription: {
    active: undefined,
    lastSubscribed: undefined,
    lastUsedBilling: undefined,
    numberOfDaysRemains: 0,
    numberOfDaysRemainsText: "",
  },

  isPurchaseHistoryLoading: false,
  totalPurchaseDocs: 0,
  purchaseHistory: [],
  isSubscriptionStatsLoading: false,
  mySubscriptionStats: undefined,
  validateOrderDetails: undefined,
  contextHandlers: {
    getActiveSubscriptionHandler: function () {},
    getPurchaseHistoryHandler: function () {},
    getStripePaymentMethodHandler: function () {},
    getSubscriptionPackagesHandler: function () {},
    validateAndCalculateOrderHandler: function (payload) {},
    getMySubscriptionStatsHandler() {},
    checkoutOrderHandler: function (values, actions) {},
    attachCardHandler(payload, actions) {},
    removeCardHandler(itemID, actions) {},
    resetValidateOrderDetails() {},
  },
});

function DataContextProvider() {
  const { getApi, postApi, deleteApi } = Api();
  const { confirm } = useConfirmHook();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { paginationQuery } = usePaginationQueryHooks();
  const { handlers } = useContext(AppContext);
  const [isDetailsLoading, setIsDetailsLoading] = useState(true);
  const [validationError, setValidationError] = useState(null);
  const [isPurchaseHistoryLoading, setPurchaseHistoryLoading] = useState(false);
  const [isStripePaymentMethodLoading, setStripePaymentMethodLoading] =
    useState(false);
  const [isSubscriptionStatsLoading, setSubscriptionStatsLoading] =
    useState(false);
  const [isSubscriptionPackageLoading, setSubscriptionPackageLoading] =
    useState(true);
  const [totalPurchaseDocs, setTotalPurchaseDocs] = useState(0);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [activeSubscription, setActiveSubscription] = useState({
    active: undefined,
    lastSubscribed: undefined,
    lastUsedBilling: undefined,
    numberOfDaysRemains: 0,
    numberOfDaysRemainsText: "",
  });
  const [mySubscriptionStats, setMySubscriptionStats] = useState(undefined);
  const [validateOrderDetails, setValidateOrderDetails] = useState(undefined);
  const [stripePaymentMethod, setStripePaymentMethod] = useState([]);
  const [subscriptionPackages, setSubscriptionPackages] = useState([]);

  const contextHandlers = {
    getActiveSubscriptionHandler: async () => {
      try {
        setIsDetailsLoading(true);
        let res = await getApi(ApiUrl.subscription.get_activeSubscription);
        setActiveSubscription(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setIsDetailsLoading(false);
      }
    },
    getMySubscriptionStatsHandler: async () => {
      try {
        setSubscriptionStatsLoading(true);
        let res = await getApi(ApiUrl.subscription.get_mySubscriptionStats);
        setMySubscriptionStats(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setSubscriptionStatsLoading(false);
      }
    },
    getStripePaymentMethodHandler: async () => {
      try {
        setStripePaymentMethodLoading(true);
        let res = await getApi(ApiUrl.stripe.get_paymentMethod);
        setStripePaymentMethod(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setStripePaymentMethodLoading(false);
      }
    },
    getSubscriptionPackagesHandler: async () => {
      try {
        setSubscriptionPackageLoading(true);
        let res = await getApi(
          ApiUrl.subscription.get_purchasableSubscriptionPackage
        );
        setSubscriptionPackages(res?.data?.docs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setSubscriptionPackageLoading(false);
      }
    },
    getPurchaseHistoryHandler: async () => {
      try {
        setPurchaseHistoryLoading(true);
        let res = await getApi(ApiUrl.subscription.get_purchaseHistory, {
          ...paginationQuery,
        });
        setPurchaseHistory(res?.data?.docs);
        setTotalPurchaseDocs(res?.data?.totalDocs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setPurchaseHistoryLoading(false);
      }
    },
    validateAndCalculateOrderHandler: async function (
      payload: PackageCreateOrderPayloadInterface
    ) {
      try {
        setIsDetailsLoading(true);
        setValidationError(null);
        let res = await postApi(ApiUrl.stripe.post_validateAndCalculateOrder, {
          ...payload,
        });
        setValidateOrderDetails(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
        setValidateOrderDetails(undefined);
        setValidationError(err?.message);
      } finally {
        setIsDetailsLoading(false);
      }
    },
    removeCardHandler: async function (
      itemID: string,
      callback: HandlerCallbackInterface
    ) {
      try {
        const isConfirm = await confirm("Are you sure?");
        if (!isConfirm) {
          return;
        }
        handlers?.setLoading(true);

        await deleteApi(ApiUrl.stripe.delete_card, {
          paymentMethod: itemID,
        });

        if (typeof callback?.onSuccess === "function") {
          await callback.onSuccess();
        }

        handlers?.setSuccess(true, "Card deleted!");
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
    checkoutOrderHandler: async function (
      payload: PackageCreateOrderPayloadInterface,
      actions: FormikHelpers<PackageCreateOrderPayloadInterface>
    ) {
      try {
        if (
          payload?.subscription &&
          validateOrderDetails?.payableAmount > 0 &&
          !payload?.paymentMethod &&
          !payload?.isNewCard
        ) {
          actions?.setFieldError(
            "paymentMethod",
            "Select payment method to proceed"
          );
          return;
        }
        if (
          !payload?.billingAddress &&
          validateOrderDetails?.payableAmount > 0
        ) {
          actions?.setFieldError(
            "paymentMethod",
            "Select billing address to proceed"
          );
          return;
        }
        const isConfirm = await confirm(
          "Are you sure you want to confirm this purchase?"
        );
        if (!isConfirm) {
          return;
        }
        handlers?.setLoading(true);
        if (validateOrderDetails?.payableAmount > 0 && payload?.isNewCard) {
          const paymentMethodID = await contextHandlers.attachCardHandler(
            payload,
            actions
          );
          payload.paymentMethod = paymentMethodID;
        }
        const createOrderRes = await postApi(
          ApiUrl.stripe.post_createOrder,
          payload
        );
        const createOrder: CreateOrderCalculationInterface =
          createOrderRes?.data;
        if (createOrder?.payableAmount > 0) {
          // not new card then only
          if (!payload?.isNewCard) {
            const stripeResult = await stripe.createToken(
              "cvc_update",
              elements.getElement(CardCvcElement)
            );
            if (stripeResult?.error) {
              throw stripeResult?.error?.message;
              // actions?.setFieldError(
              //   "paymentMethod",
              //   stripeResult?.error?.message
              // );
              // handlers?.setLoading(false);
              // return;
            }
          }

          await postApi(ApiUrl.stripe.post_confirmOrder, {
            paymentIntent: createOrder?.orderID,
          });
        } else {
          await postApi(ApiUrl.stripe.post_confirmOrder, {
            orderID: createOrder?.orderID,
          });
        }
        setValidateOrderDetails(undefined);
        await handlers.getCurrentProfile();
        navigate(PageLinks.subscription.activeSubscription);
        handlers?.setSuccess(true, "Payment success");
      } catch (err) {
        // if (typeof err?.message === "string") {
        //   actions?.setFieldError("paymentMethod", err?.message);
        // }
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
    async attachCardHandler(
      payload: PackageCreateOrderPayloadInterface,
      actions: FormikHelpers<PackageCreateOrderPayloadInterface>
    ): Promise<string> {
      try {
        const stripeResp = await stripe.createPaymentMethod({
          type: "card",
          billing_details: payload?.newCardDetails,
          card: elements.getElement(CardElement),
        });
        if (stripeResp?.error) {
          throw stripeResp?.error?.message;
        }
        const res = await postApi(ApiUrl.stripe.post_attachCard, {
          paymentMethod: stripeResp?.paymentMethod,
        });
        return res?.data?.id;
      } catch (err) {
        throw err;
      }
    },
    resetValidateOrderDetails() {
      setValidateOrderDetails(undefined);
      setValidationError(null);
    },
  };

  const contextValue = {
    validationError,
    isStripePaymentMethodLoading,
    stripePaymentMethod,
    isSubscriptionPackageLoading,
    subscriptionPackages,
    isDetailsLoading,
    isPurchaseHistoryLoading,
    totalPurchaseDocs,
    activeSubscription,
    purchaseHistory,
    mySubscriptionStats,
    isSubscriptionStatsLoading,
    validateOrderDetails,
    contextHandlers,
  };
  return (
    <SubscriptionContext.Provider value={contextValue}>
      <Outlet />
    </SubscriptionContext.Provider>
  );
}

export default DataContextProvider;
