import React, { useContext, useEffect } from "react";
import { OptionsContext } from "context";
import {
  MySelectField,
  MyTextAreaInput,
  MyTextField,
  MyUploadDocument,
} from "components";
import {
  decodeDynamicFieldHandler,
  decodePropertyOptionsHandler,
  decodeUserOptionsHandler,
  decodeUnitOptionsHandler,
} from "helpers";
import { FormWrapper } from "assets";
import { DocumentInterface, FieldTypeEnum } from "interfaces";
import { useFormikContext } from "formik";

function DocumentForm() {
  const { values } = useFormikContext<DocumentInterface<string>>();
  const {
    propertyOptions,
    userOptions,
    getUnitOptionsHandler,
    unitOptions,
    fieldTypeOptions,
  } = useContext(OptionsContext);
  useEffect(() => {
    if (values?.property) {
      getUnitOptionsHandler(values?.property);
    }
  }, [values?.property]);

  return (
    <div className={"flex flex-col gap-10"}>
      <div
        className={
          "grid grid-cols-3 items-start md:grid-cols-2 sm:grid-cols-1 gap-5"
        }
      >
        <MyTextField
          label={"Name"}
          isRequired
          placeholder={"Enter name"}
          name={"name"}
        />
        <MySelectField
          label={"Type"}
          isRequired
          name={"documentType"}
          option={{
            selectOptions: decodeDynamicFieldHandler(
              fieldTypeOptions[FieldTypeEnum.DOCUMENT_TYPE]
            ),
            defaultLabel: "Select document type",
          }}
        />
      </div>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <MySelectField
          label={"Property"}
          isRequired
          name={"property"}
          option={{
            selectOptions: decodePropertyOptionsHandler(propertyOptions),
            defaultLabel: "Select Property",
          }}
        />{" "}
        <MySelectField
          disabled={!values?.property}
          label={"Unit"}
          isRequired
          name={"unit"}
          option={{
            selectOptions: decodeUnitOptionsHandler(unitOptions),
            defaultLabel: "Select Unit",
          }}
        />
      </div>

      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <div className={"col-span-2"}>
          <MyTextAreaInput label={"Description"} name={"description"} />
        </div>
      </div>
      <FormWrapper
        header={{
          title: {
            name: "Documents",
            isRequired: true,
          },
        }}
      >
        <div
          className={"grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-5"}
        >
          <div className={"col-span-2"}>
            <MyUploadDocument name={"documents"} />
          </div>
        </div>
      </FormWrapper>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <MyTextField
          type={"date"}
          label={"Date of Validation"}
          isRequired
          name={"dateOfValidation"}
        />
      </div>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <div className={"col-span-2"}>
          <MySelectField
            option={{
              selectOptions: decodeUserOptionsHandler(userOptions),
            }}
            multiple
            label={"Share With"}
            isRequired
            name={"sharedTo"}
          />
        </div>
      </div>
    </div>
  );
}

export default DocumentForm;
