import * as yup from "yup";
import { addressInitialValues, addressValidationSchema } from "content";
import { PropertyInterface, PropertyOwnerInterface } from "interfaces";
import { getInputDateFormat, validateDateHandler } from "helpers";

export const newPropertyFormikValues = {
  initialValues: (values?: PropertyInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      propertyType: values?.propertyType?._id,
      unitSize: values?.unitSize?._id,
      unitNumber: values?.unitNumber,
      description: values?.description || "",
      constructionDate: values?.constructionDate,
      ...addressInitialValues(values?.address),
    };
  },
  validationSchema: yup
    .object()
    .shape({
      propertyType: yup.string().required("required"),
      constructionDate: yup
        .string()
        .test("custom-validation", "Invalid field value", function (value) {
          const { message, isError } = validateDateHandler(value, {
            pastOnly: true,
          });
          return (
            !isError ||
            this.createError({
              path: "constructionDate", // Replace with the actual field name
              message: message,
            })
          );
        }),
    })
    .concat(addressValidationSchema),
};

export const ownershipFormikValues = {
  initialValues: (propertyId, payload?: PropertyOwnerInterface) => {
    const data: any = {
      ownerType: payload?.ownerType?._id || "",
      property: propertyId,
      ownershipType: payload?.ownershipType?._id || "",
      ownership: payload?.ownership || "",
      agreementExpiryAt: payload?.agreementExpiryAt
        ? getInputDateFormat(payload?.agreementExpiryAt)
        : "",

      // "owner-type" != "sale-traders"
      companyName: payload?.limitedCompany?.companyName || "",
      registrationNumber: payload?.limitedCompany?.registrationNumber || "",
      //  ownership == 'rent-to-rent'

      //  ownership == 'freeHold'

      //  ownership == 'leasehold'
      //along with other freehold value
    };
    if (payload?.rentToRent?.rentPay)
      data.rentPay = payload?.rentToRent?.rentPay;

    if (payload?.rentToRent?.leaseTerm)
      data.leaseTerm = payload?.rentToRent?.leaseTerm;

    if (payload?.rentToRent?.deposit)
      data.deposit = payload?.rentToRent?.deposit;
    if (payload?.rentToRent?.paymentTerm)
      data.paymentTerm = payload?.rentToRent?.paymentTerm;

    if (payload?.hold?.groundRent) data.groundRent = payload?.hold?.groundRent;
    if (payload?.hold?.serviceCharge)
      data.serviceCharge = payload?.hold?.serviceCharge;

    if (payload?.hold?.purchasePrice)
      data.purchasePrice = payload?.hold?.purchasePrice;
    if (payload?.hold?.currentValue)
      data.currentValue = payload?.hold?.currentValue;

    if (payload?.hold?.leaseExpiry)
      data.leaseExpiry = payload?.hold?.leaseExpiry;

    if (payload?.hold?.purchaseDate) {
      data.purchaseDate = payload?.hold?.purchaseDate;
    }
    if (payload?.hold?.valuationDate) {
      data.valuationDate = payload?.hold?.valuationDate;
    }

    return data;
  },
  validationSchema: yup.object().shape({
    ownerType: yup.string().required("required"),
    property: yup.string().required("required"),
    ownershipType: yup.string().required("required"),
    ownership: yup
      .number()
      .min(0, "invalid")
      .max(100, "invalid")
      .required("required"),
    agreementExpiryAt: yup.string().required("required"),
    companyName: yup.string(),
    registrationNumber: yup.string(),
    purchasePrice: yup.number().min(0, "invalid"),
    rentPay: yup.number().min(0, "invalid"),
    deposit: yup.number().min(0, "invalid"),
    purchaseDate: yup.string(),
    currentValue: yup.number(),
    valuationDate: yup.string(),
  }),
};

export const addOwnerFormikValues = {
  initialValues: (pId: string) => {
    return {
      ownerName: {
        first: "",
        last: "",
      },
      ownerPhone: "",
      ownerEmail: "",
      ownerPhoto: "",
      ...ownershipFormikValues.initialValues(pId),
    };
  },
  validationSchema: yup
    .object()
    .shape({
      ownerName: yup.object().shape({
        first: yup.string().required("required"),
        last: yup.string().required("required"),
      }),
      ownerPhone: yup.string().required("required"),
      ownerEmail: yup.string().required("required"),
      ownerPhoto: yup.string(),
    })
    ?.concat(ownershipFormikValues?.validationSchema),
};
