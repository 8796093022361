import React from "react";

function MessageComponent({ message }) {
  return (
    <div
      className={
        "flex items-center justify-center text-center border-2 bg-blue-50 min-h-[10vh] rounded-md text-tBlue"
      }
    >
      <span>{message}</span>
    </div>
  );
}

export default MessageComponent;
