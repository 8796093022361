import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { TenancyInterface } from "interfaces";
import React from "react";
import {
  getCurrency,
  getPropertyNameHandler,
  getStaffFullName,
  separateWithComma,
} from "../HandlerHelpers";
import moment from "moment/moment";

interface PropsInterface {
  title: string;
  data?: TenancyInterface[];
  dateFormatter?({ date }: { date: Date });
}
async function generateTenancyReport({
  data,
  title,
  dateFormatter,
}: PropsInterface) {
  try {
    //    create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const pageTitle = `${title}-tenancy`;

    const worksheet = workbook.addWorksheet(pageTitle, {});
    worksheet.properties.defaultRowHeight = 20;
    worksheet.properties.defaultColWidth = 20;
    worksheet.getCell("A1").value = pageTitle;
    worksheet.getCell("A1").style = {
      font: {
        bold: true,
      },
    };
    worksheet.getCell("A2").value = `Exported on : ${dateFormatter({
      date: new Date(),
    })}`;
    worksheet.getCell("A2").style = {
      font: {
        bold: true,
      },
    };
    let tableHeaders = [
      {
        name: "SN.",
      },
      {
        name: "Property ID",
      },
      {
        name: "Property",
      },
      {
        name: "Unit ID",
      },
      {
        name: "Unit",
      },
      {
        name: "Tenant ID",
      },
      {
        name: "Tenant",
      },
      {
        name: "Term",
      },
      {
        name: "Rent",
      },
      {
        name: "Deposit",
      },
      {
        name: "Balance",
      },
      {
        name: "Expires",
      },
    ];
    let tableRows = data?.map((e, index) => {
      const date = e?.tenancy?.rentTerminatedOn || e?.tenancy?.rentEndDate;
      const expiresDate = dateFormatter({ date });
      const propertyName = getPropertyNameHandler(e?.property?.address);
      return [
        index + 1,
        e?.property?.propertyID,
        propertyName,
        e?.unit?.unitID,
        e?.unit?.name,
        e?.tenancy?.tenant?.userID,
        getStaffFullName(e?.tenancy?.tenant?.name) || "-",
        `${e?.duration?.durationQuantity || 0} ${
          e?.duration?.durationFrequency?.label
        }`,
        `${getCurrency()}${separateWithComma(e?.rent?.rentCost)} ${
          e?.rent?.rentFrequency?.label
        }`,
        `${getCurrency()}${separateWithComma(
          e?.securityDeposit?.securityDepositedAmount
        )}`,
        `${getCurrency()}${separateWithComma(
          e?.initialRent?.initialRentCost
        )}`,
        moment(new Date(expiresDate)).fromNow(),
      ];
    });
    // add a table to a sheet
    worksheet.addTable({
      name: pageTitle,
      ref: "A4",
      headerRow: true,
      totalsRow: false,
      style: {
        showRowStripes: false,
      },
      columns: tableHeaders,
      rows: tableRows,
    });
    //    save the workbook to a file
    const bufferFile = await workbook.xlsx.writeBuffer();
    await FileSaver.saveAs(
      new Blob([bufferFile], { type: "application/octet-stream" }),
      `${pageTitle}.xlsx`
    );
  } catch (err) {
    throw err;
  }
}

export default generateTenancyReport;
