import React, { createContext, useContext, useState } from "react";

import { AppContext } from "context";
import { Api } from "helpers";
import { ApiUrl } from "services";
import {
  HandlerCallbackInterface,
  UnitInterface,
  ListingUnitInterface,
} from "interfaces";

import { DataContextInterface } from "interfaces";
import {
  useConfirmHook,
  useDataContextHooks,
  usePaginationQueryHooks,
} from "useHooks";
interface PropsInterface extends DataContextInterface<UnitInterface> {
  listings: ListingUnitInterface[];
  listingDetails: ListingUnitInterface;
  totalListsDocs: number;
  getListingHandler(unitID: string);
  getListingDetailsHandler(listingID: string);
  editListingHandler(values, props, callback?: HandlerCallbackInterface);
  deleteListingHandler(listingID: string, callback?: HandlerCallbackInterface);
}
export const UnitDataContext = createContext<PropsInterface>({
  isDetailsLoading: false,
  isLoading: false,
  allData: [],
  details: undefined,
  totalDocs: 0,
  totalArchiveDocs: 0,
  totalListsDocs: 0,
  listings: [],
  listingDetails: undefined,
  getAllDataHandlers(query) {},
  getDetailsHandler(itemId: string) {},
  editDetailsHandler(values, actions, callback) {},
  archiveHandler(itemId, status, callback) {},
  deleteHandler(itemId, callback) {},

  getListingHandler(itemId) {},
  getListingDetailsHandler: function (listingID: string) {},
  editListingHandler(values, actions: any, callback) {},
  deleteListingHandler: function (listingID: string, callbackHandler) {},
});

function DataContextProvider({ children }) {
  const { confirm } = useConfirmHook();
  const { isArchive, paginationQuery } = usePaginationQueryHooks();

  const {
    isArchiveLoaded,
    allData,
    setAllData,
    totalArchiveDocs,
    setTotalArchiveDocs,
    totalDocs,
    setTotalDocs,
    setDetails,
    isDetailsLoading,
    setDetailsLoading,
    isLoading,
    setLoading,
    details,
  } = useDataContextHooks();

  const [totalListsDocs, setTotalListsDocs] = useState(0);
  const [listings, setListings] = useState([]);
  const [listingDetails, setListingDetails] = useState(null);

  const { handlers } = useContext(AppContext);
  const { getApi, postApi, putApi, deleteApi } = Api();
  const contextHandlers = {
    getAllDataHandlers: async (query) => {
      try {
        setLoading(true);
        let res: any;
        res = await getApi(ApiUrl.unit.get_unit, {
          ...paginationQuery,
          ...query,
        });
        if (isArchive) {
          setTotalArchiveDocs(res?.data?.totalDocs);
        } else {
          setTotalDocs(res?.data?.totalDocs);
        }
        setAllData(res?.data?.docs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getDetailsHandler: async (itemId: string, isFromEdit?: boolean) => {
      try {
        if (!isFromEdit) {
          setDetailsLoading(true);
          setDetails(null);
        }
        const res: any = await getApi(ApiUrl.unit.get_unitDetails + itemId);
        setDetails(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
        handlers.setPageErrorHandler(true, err?.message);
      } finally {
        setDetailsLoading(false);
      }
    },

    editDetailsHandler: async (
      values,
      props,
      callback: HandlerCallbackInterface
    ) => {
      try {
        typeof props?.setSubmitting === "function"
          ? props?.setSubmitting(true)
          : handlers?.setLoading(true);
        let res;
        const shouldEdit = values?._id;
        if (shouldEdit) {
          res = await putApi(ApiUrl.unit.put_editUnit, values);
        } else {
          res = await postApi(ApiUrl.unit.post_addUnit, values);
        }
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        typeof props?.setSubmitting === "function"
          ? props?.setSubmitting(false)
          : handlers?.setLoading(false);
      }
    },
    deleteHandler: async (itemsId, callback: HandlerCallbackInterface) => {
      try {
        const isConfirm = await confirm(
          "Are you sure you want to delete this item?",
          "Delete",
          "Cancel"
        );
        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res: any = await deleteApi(ApiUrl.unit.delete_unit + itemsId, {});
        if (typeof callback?.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
    archiveHandler: async (
      itemsId,
      status: boolean,
      callback: HandlerCallbackInterface
    ) => {
      try {
        const isConfirm = await confirm(
          `Are you sure you want to ${
            !status ? "archive" : "unarchive"
          } the selected items?`,
          !status ? "Archive" : "Unarchive",
          "Cancel"
        );
        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res: any = await putApi(
          ApiUrl.unit.put_archiveUnArchiveUnit +
            `${itemsId}/${status ? "off" : "on"}`,
          {}
        );
        if (isArchiveLoaded) {
          // isAlready archived
          if (status) {
            setTotalArchiveDocs(totalArchiveDocs - 1);
            setTotalDocs(totalDocs + 1);
          } else {
            setTotalArchiveDocs(totalArchiveDocs + 1);
          }
        }
        if (typeof callback?.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },

    getListingHandler: async (unitID) => {
      try {
        setLoading(true);

        const res = await getApi(ApiUrl.listing.get_listingFromUnitId, {
          unit: unitID,
        });
        setListings(res?.data?.docs);
        setTotalListsDocs(res?.data?.totalDocs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getListingDetailsHandler: async (listingID) => {
      try {
        handlers?.setLoading(true);
        setListingDetails({});
        const res = await getApi(ApiUrl.listing.get_ListingDetails + listingID);
        setListingDetails(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
    editListingHandler: async (
      values,
      props,
      callback: HandlerCallbackInterface
    ) => {
      try {
        handlers?.setLoading(true);
        let res;
        const shouldEdit = values?._id;
        if (shouldEdit) {
          res = await putApi(ApiUrl.listing.post_addListingToUnit, values);
        } else {
          res = await postApi(ApiUrl.listing.post_addListingToUnit, values);
        }
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
    deleteListingHandler: async (
      itemsId,
      callback: HandlerCallbackInterface
    ) => {
      try {
        const isConfirm = await confirm("Are you sure?");
        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res: any = await deleteApi(
          ApiUrl.listing.delete_listing + itemsId,
          {}
        );
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
  };
  const contextValue = {
    allData,
    details,
    totalDocs,
    totalArchiveDocs,
    totalListsDocs,
    listings,
    listingDetails,
    isLoading,
    isDetailsLoading,
    ...contextHandlers,
  };
  return (
    <UnitDataContext.Provider value={contextValue}>
      {children}
    </UnitDataContext.Provider>
  );
}

export default DataContextProvider;
