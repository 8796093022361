import React, { useContext, useEffect } from "react";

import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { PageTemplate } from "templates";
import { FormConcludeButtons } from "assets";
import { FieldTypeEnum, ParamsStringEnum } from "interfaces";
import { AppContext, OptionsContext } from "context";
import { PropertyDataContext } from "context";
import { PageLinks } from "routes";
import { usePageQueryHooks } from "useHooks";

import { newPropertyFormikValues } from "../helpers";
import { PropertyForm } from "../components";

function NewPropertyPage() {
  const params = useParams<ParamsStringEnum>();
  const isEdit = params?.propertyId;
  const { returnURL } = usePageQueryHooks();
  const { getOptionsHandler } = useContext(OptionsContext);
  const { handlers } = useContext(AppContext);
  const { getDetailsHandler, details, editDetailsHandler } =
    useContext(PropertyDataContext);

  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      handlers?.setLoading(true);
      await Promise.all([
        isEdit && getDetailsHandler(isEdit),
        getOptionsHandler(FieldTypeEnum.PROPERTY_TYPE),
        getOptionsHandler(FieldTypeEnum.UNIT_SIZE),
      ]);
      handlers?.setLoading(false);
    })();
  }, [isEdit]);
  return (
    <PageTemplate
      scrollAll
      title={"Rental"}
      backNavigation={{
        title: isEdit ? "Edit Property" : "New Property",
        path: PageLinks.property.list,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={newPropertyFormikValues?.initialValues(
          isEdit && details
        )}
        validationSchema={newPropertyFormikValues?.validationSchema}
        onSubmit={async (values, formikHelpers: any) => {
          await editDetailsHandler(values, formikHelpers, {
            onSuccess: async (payload) => {
              if (returnURL) {
                navigate(returnURL);
              } else {
                navigate(PageLinks.property.list);
              }
            },
          });
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={"flex flex-col gap-10"}>
              <PropertyForm />
              <FormConcludeButtons
                submitButton={{
                  title: "Save",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default NewPropertyPage;
