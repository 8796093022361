import React from "react";
import { decodeOptionColorHandler } from "helpers";

function StatusText({ status, label }) {
  const { textColor, bgColor } = decodeOptionColorHandler(status);
  return (
    <div
      className={`text-center px-3 py-2 rounded-full  ${textColor} ${bgColor}`}
    >
      <span className={"text-[14px] font-displayLight"}>{label}</span>
    </div>
  );
}

export default StatusText;
