import React from "react";

import { PageTemplate } from "templates";

import { AccessibleReportLinks } from "../helpers";
import { useNavigate } from "react-router-dom";
import getIconHelpers from "../../../helpers/utils/getIconHelpers";
import {IconTypeEnum} from "../../../interfaces";
const DotIcon = getIconHelpers(IconTypeEnum.DOT);

function ReportPage() {
  const navigate = useNavigate();
  const accessibleReportLinks = AccessibleReportLinks();
  return (
    <PageTemplate
      title={"Report"}
      scrollAll
      breadCrumb={[
        {
          name: "All Reports",
        },
      ]}
    >
      <div>
        <div
          className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-10"}
        >
          {accessibleReportLinks?.map((e, key) => {
              let Icon = e?.Icon;
              return (
              <div key={key} className={"flex flex-col gap-5"}>
                  <div className={"flex items-center gap-3"}>
                      <Icon className={"text-[18px] h-[18px] w-[18px]"} />
                      <b className={"text-[18px] font-semibold"}>{e?.name}</b>
                  </div>
                <div className={"flex flex-col gap-3"}>
                  {e?.reports?.map((f, fKey) => {
                      return (
                          <div
                              key={fKey}
                              onClick={() => navigate(f.pageLink)}
                              className={
                                  "flex items-center gap-2 text-tBlue hover:underline text-[16px] cursor-pointer"
                              }
                          >
                              <DotIcon className={"text-[16px] h-[16px] w-[16px]"} />
                              <span className={"text-[16px]"}>{f?.name}</span>
                          </div>
                      );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </PageTemplate>
  );
}

export default ReportPage;
