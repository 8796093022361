import React, { createContext, useContext } from "react";

import { AppContext } from "context";
import { Api } from "helpers";
import { ApiUrl } from "services";
import {
  useConfirmHook,
  useDataContextHooks,
  usePaginationQueryHooks,
} from "useHooks";

import {
  DataContextInterface,
  TrashInterface,
  HandlerCallbackInterface,
} from "interfaces";
import { FormikHelpers } from "formik/dist/types";

interface TrashContextInterface extends DataContextInterface<TrashInterface> {
  emptyTrashHandler(callback: HandlerCallbackInterface);
  restoreTrashHandler(itemID, callback: HandlerCallbackInterface);
}
export const TrashContext = createContext<TrashContextInterface>({
  isLoading: false,
  allData: [],
  isDetailsLoading: false,
  details: null,
  totalDocs: 0,
  getAllDataHandlers(query?: any) {},
  getDetailsHandler(itemId: string, isFromEdit?: boolean) {},
  deleteHandler(itemId: string, callback) {},

  editDetailsHandler(
    values,
    actions: FormikHelpers<unknown>,
    callback?: HandlerCallbackInterface
  ) {},
  emptyTrashHandler(callback) {},
  restoreTrashHandler(itemID, callback) {},
});

function DataContextProvider({ children }) {
  const { confirm } = useConfirmHook();
  const { isArchive, paginationQuery } = usePaginationQueryHooks();

  const {
    isArchiveLoaded,
    totalArchiveDocs,
    setTotalArchiveDocs,
    isDetailsLoading,
    setDetailsLoading,
    details,
    setLoading,
    isLoading,
    setTotalDocs,
    totalDocs,
    setDetails,
    allData,
    setAllData,
  } = useDataContextHooks();

  const { handlers } = useContext(AppContext);
  const { getApi, postApi, putApi, deleteApi } = Api();

  //  handler
  const trashHandlers = {
    getAllDataHandlers: async (query) => {
      try {
        setLoading(true);
        let res: any;
        res = await getApi(ApiUrl.trash.get_trash, {
          ...query,
          ...paginationQuery,
        });
        setTotalDocs(res?.data?.totalDocs);
        setAllData(res?.data?.docs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getDetailsHandler: async (itemID: string, isFromEdit) => {
      try {
        if (!isFromEdit) {
          setDetailsLoading(true);
          setDetails(null);
        }
        const res: any = await getApi(ApiUrl.trash.get_trashDetails + itemID);
        setDetails(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
        handlers.setPageErrorHandler(true, err?.message);
      } finally {
        setDetailsLoading(false);
      }
    },
    restoreTrashHandler: async (
      itemID: string,
      callback: HandlerCallbackInterface
    ) => {
      try {
        const isConfirm = await confirm("Are you sure?");
        if (!isConfirm) return;
        setLoading(true);
        const res: any = await putApi(ApiUrl.trash.put_restore + itemID);
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },

    deleteHandler: async (itemsId, callback: HandlerCallbackInterface) => {
      try {
        const isConfirm = await confirm(
          "Are you sure you want to delete this item?",
          "Delete",
          "Cancel"
        );
        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res: any = await deleteApi(
          ApiUrl.trash.delete_trash + itemsId,
          {}
        );
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
    emptyTrashHandler: async (callback: HandlerCallbackInterface) => {
      try {
        const isConfirm = await confirm("Are you sure?");
        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res: any = await deleteApi(ApiUrl.trash.delete_emptyBin, {});
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
  };

  const contextValue = {
    totalDocs,
    totalArchiveDocs,
    allData,
    details,
    isDetailsLoading,
    isLoading,
    ...trashHandlers,
  };
  return (
    <TrashContext.Provider value={contextValue}>
      {children}
    </TrashContext.Provider>
  );
}

export default DataContextProvider;
