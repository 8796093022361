import React, { useContext, useEffect, useState } from "react";

import { Form, Formik } from "formik";
import { PropertyUnitDocumentFormik } from "./helpers";
import { PropertyUnitDocumentFormInterface } from "./interfaces";
import { FormConcludeButtons } from "assets";
import { OptionsContext } from "context";
import { FieldTypeEnum, IconTypeEnum, SpaceAccessListEnum } from "interfaces";
import {
  MyRadioInput,
  MySelectField,
  MyTextAreaInput,
  MyTextField,
  MyUploadDocument,
} from "components";
import {
  decodeDocumentOptionsHandler,
  decodeDynamicFieldHandler,
} from "helpers";
import { useAuthorizationHook } from "../../useHooks";
import getIconHelpers from "../../helpers/utils/getIconHelpers";

function PropertyUnitDocumentForm({
  submitHandler,
  values,
  cancelHandler,
}: PropertyUnitDocumentFormInterface) {
  const [isNewDocument, setIsNewDocument] = useState(true);
  const {
    getOptionsHandler,
    fieldTypeOptions,
    getDocumentOptionsHandler,
    documentOptions,
  } = useContext(OptionsContext);
  useEffect(() => {
    (async () => {
      await Promise.all([
        getOptionsHandler(FieldTypeEnum.DOCUMENT_TYPE),
        getDocumentOptionsHandler(),
      ]);
    })();
  }, []);
  return (
    <Formik
      enableReinitialize
      onSubmit={submitHandler}
      initialValues={PropertyUnitDocumentFormik?.initialValues(values)}
    >
      {({ isSubmitting, setFieldValue }) => {
        return (
          <Form className={"flex flex-col gap-5"}>
            <div
              className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
            >
              <ToggleComponent
                isNewDocument={isNewDocument}
                setIsNewDocument={setIsNewDocument}
              />
            </div>
            <div
              className={
                "grid grid-cols-3 gap-5  md:grid-cols-2 sm:grid-cols-1"
              }
            >
              {isNewDocument ? (
                <MySelectField
                  option={{
                    selectOptions: decodeDynamicFieldHandler(
                      fieldTypeOptions[FieldTypeEnum.DOCUMENT_TYPE]
                    ),
                  }}
                  name={"documentType"}
                  label={"Type"}
                  isRequired
                />
              ) : (
                <MySelectField
                  option={{
                    selectOptions:
                      decodeDocumentOptionsHandler(documentOptions),
                    onChangeCallback(currentValue?: any) {
                      let selectedDocumentDetails = documentOptions?.find(
                        (e) => e?._id === currentValue?.value
                      );
                      if (selectedDocumentDetails) {
                        setFieldValue(
                          "dateOfIssue",
                          selectedDocumentDetails?.createdAt
                        );
                        setFieldValue(
                          "dateOfExpiry",
                          selectedDocumentDetails?.dateOfValidation
                        );
                        setFieldValue(
                          "documents",
                          selectedDocumentDetails?.documents
                        );
                        setFieldValue(
                          "description",
                          selectedDocumentDetails?.description
                        );
                        setFieldValue(
                          "documentType",
                          selectedDocumentDetails?.documentType?._id
                        );
                      }
                    },
                  }}
                  label={"Select Files"}
                  name={"documentReferenceID"}
                />
              )}
            </div>
            <div
              className={
                "grid grid-cols-3 gap-5  md:grid-cols-2 sm:grid-cols-1"
              }
            >
              <MyTextField
                name={"dateOfIssue"}
                label={"Date of Issue"}
                type={"date"}
              />
              <MyTextField
                name={"dateOfExpiry"}
                label={"Date of Expiry"}
                type={"date"}
              />
            </div>
            {isNewDocument && (
              <div
                className={"grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1"}
              >
                <div className={"col-span-2"}>
                  <MyUploadDocument name={"documents"} />
                </div>
              </div>
            )}

            <div
              className={
                "grid grid-cols-3 gap-5  md:grid-cols-2 sm:grid-cols-1"
              }
            >
              <div className={"col-span-2"}>
                <MyTextAreaInput name={"description"} label={"Description"} />
              </div>
            </div>
            <MyRadioInput
              label={"Share the document with your tenant."}
              name={"sharedToTenant"}
            />
            <FormConcludeButtons
              submitButton={{
                title: "Save",
                isLoading: isSubmitting,
              }}
              cancelButton={{
                title: "Cancel",
                handler() {
                  cancelHandler();
                },
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

const ToggleComponent = ({ isNewDocument, setIsNewDocument }) => {
  const { checkCanAccessHandler } = useAuthorizationHook();
  const AttachmentIcon = getIconHelpers(IconTypeEnum.ATTACHMENT);
  const canEditDocument = checkCanAccessHandler([
    SpaceAccessListEnum.DOCUMENT_EDIT_DOCUMENT,
  ]);
  const dataStatus = [
    {
      title: "New Document",
      status: isNewDocument,
      handler() {
        setIsNewDocument(true);
      },
    },
    canEditDocument && {
      title: "Existing Document",
      status: !isNewDocument,
      handler() {
        setIsNewDocument(false);
      },
    },
  ];
  return (
    <div className={"grid grid-cols-2 gap-1 bg-tGray rounded-full p-1"}>
      {dataStatus?.map((e, key) => {
        return (
          <div
            key={key}
            onClick={() => {
              e?.handler();
            }}
            className={`flex items-center justify-center gap-2 cursor-pointer min-w-[150px] min-h-[35px]  px-5 rounded-full text-[16px] ${
              e?.status ? "bg-white text-black" : "text-gray-500 "
            }`}
          >
            <AttachmentIcon
              className={`text-[14px] ${
                e?.status ? "fill-black" : "text-gray-200"
              }`}
            />
            <span className={"text-[14px]"}>{e?.title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default PropertyUnitDocumentForm;
