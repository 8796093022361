import React, { useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { PageTemplate } from "templates";
import { MyTextField, MyIconButton } from "components";
import { FormConcludeButtons } from "assets";
import { DynamicListContext } from "context";
import {
  ParamsStringEnum,
  QueryStringEnum,
  IconTypeEnum,
  FieldTypeEnum,
} from "interfaces";

import { newItemFormikValues } from "../../helpers";
import { PageLinks } from "routes";
import { capitalizeAfterSpace } from "helpers";

function EditItemsPage() {
  const params = useParams<ParamsStringEnum>();
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { details, getDetailsHandler, editDetailsHandler } =
    useContext(DynamicListContext);

  const isEdit = params.id;
  const type = params?.type || details?.fieldType || "-";
  const title = capitalizeAfterSpace(type);
  const haveValue = type === FieldTypeEnum.TAX;

  useEffect(() => {
    if (isEdit) {
      getDetailsHandler(isEdit);
    }
  }, [isEdit]);
  const submitHandler = (values, props) => {
    editDetailsHandler(values, props, {
      onSuccess(payload?: any): any {
        const returnURL = query?.get(QueryStringEnum.RETURN_URL);
        if (returnURL) {
          navigate(returnURL);
        } else {
          navigate(-1);
        }
      },
    });
  };
  return (
    <PageTemplate
      title={"Administrative Setup"}
      backNavigation={{
        title: `${isEdit ? "Edit" : "Add"} ${title}`,
        path: PageLinks.dynamicLists.list(),
      }}
    >
      <div className={"flex flex-col gap-10"}>
        <ItemDetailsCard title={title} />
        <Formik
          enableReinitialize
          onSubmit={submitHandler}
          initialValues={newItemFormikValues?.initialValues(
              isEdit ? details : {
              fieldType: type,
            }
          )}
          validationSchema={newItemFormikValues.validationSchema(haveValue)}
        >
          {({ isSubmitting }) => {
            return (
              <Form className={"flex flex-col gap-10"}>
                <div
                  className={
                    "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"
                  }
                >
                  <MyTextField
                    name={"label"}
                    label={`${title}`}
                    isRequired
                    placeholder={"eg. Fully Furnished"}
                  />
                  {haveValue && (
                    <MyTextField
                      name={"value"}
                      label={`Value`}
                      isRequired
                      isNumberOnly
                      placeholder={"eg. 15"}
                    />
                  )}
                  <MyTextField
                    name={"description"}
                    label={"Description"}
                    placeholder={"eg. Fully Furnished"}
                  />
                </div>
                <FormConcludeButtons
                  submitButton={{
                    title: "Submit",
                    isLoading: isSubmitting,
                  }}
                  cancelButton={{
                    title: "Cancel",
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </PageTemplate>
  );
}

function ItemDetailsCard({ title }) {
  return (
    <div className={"flex items-center gap-4 bg-tGray rounded-md p-4"}>
      <MyIconButton
        containerStyle={"p-3"}
        iconSize={18}
        iconColor={"text-primary"}
        IconType={IconTypeEnum.UN_ORDER_LIST}
      />
      <div className={"flex flex-col gap-5"}>
        <div className={"text-[14px] "}>
          Add listing for{" "}
          <span className={"text-primary font-bold"}>"{title}"</span>
        </div>
      </div>
    </div>
  );
}
export default EditItemsPage;
