import React, { useContext } from "react";
import {
  FormWrapper,
  LeftRightHeader,
  NameValueText,
  PropertyUnitTableText,
} from "assets";
import { getCurrency, getPropertyNameHandler } from "helpers";
import { MyButton } from "components";
import { Outlet } from "react-router-dom";

import { UnitDataContext } from "context";
import { IconTypeEnum, SpaceAccessListEnum } from "interfaces";
import { useAuthorizationHook, usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function UnitDetailTab() {
  const { details: unitDetails, isDetailsLoading } =
    useContext(UnitDataContext);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  let details = {
    "Property Name": `(${
      unitDetails?.property?.propertyID
    }) ${getPropertyNameHandler(unitDetails?.property?.address)}`,
    "Unit Name": `(${unitDetails?.unitID}) ${unitDetails?.name}`,
    "Unit Size": `${unitDetails?.area?.size} sq. ${unitDetails?.area?.unit?.label}`,
    "Market Rent": `${getCurrency()}${unitDetails?.marketRent}`,
    Furnished: unitDetails?.furnishedType?.label,
    "Selected Floor": unitDetails?.floor?.label,
  };
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([SpaceAccessListEnum.RENTAL_EDIT_UNIT]);

  return (
    <div className={"flex flex-col gap-10"}>
      <FormWrapper
        header={{
          title: { name: "Unit Details" },
          rightChildren: canEdit && (
            <div>
              {unitDetails?._id && (
                <MyButton
                  name={"Edit General"}
                  onClick={() => {
                    navigateWithReturnUrlHandler(
                      PageLinks.unit.edit(unitDetails?._id)
                    );
                  }}
                  iconType={IconTypeEnum.EDIT}
                  isOutline
                />
              )}
            </div>
          ),
        }}
      >
        <div
          className={
            "grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-5 mt-5"
          }
        >
          {Object.keys(details).map((e, key) => {
            return (
              <NameValueText
                key={key}
                alignDirection={"row"}
                isLoading={isDetailsLoading}
                name={e}
                value={details[e]}
              />
            );
          })}
        </div>
      </FormWrapper>
      {unitDetails?.room?.length > 0 && (
        <FormWrapper
          header={{
            title: {
              name: "Room Inventory",
            },
          }}
        >
          <div className={"flex flex-col gap-5"}>
            {unitDetails?.room?.map((e, key) => {
              return (
                <div
                  className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1"}
                  key={key}
                >
                  <NameValueText
                    isLoading={isDetailsLoading}
                    alignDirection={"row"}
                    name={"Room Type"}
                    value={e?.roomType?.label}
                  />
                  <NameValueText
                    isLoading={isDetailsLoading}
                    alignDirection={"row"}
                    name={"Quantity"}
                    value={e?.quantity}
                  />
                </div>
              );
            })}
          </div>
        </FormWrapper>
      )}
      <Outlet />
    </div>
  );
}

export default UnitDetailTab;
