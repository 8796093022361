import * as yup from "yup";
import {
  MaintenanceItemInterface,
  MaintenanceMultipleFormInterface,
  RepairAndMaintenanceInterface,
} from "interfaces";

export const MaintenanceFormikValues = {
  ItemValues: (values?: MaintenanceItemInterface) => {
    return {
      isGeneral: values?.isGeneral || false,
      ...(values?.roomID ? { roomID: values?.roomID } : {}),
      ...(values?.roomSerialNumber
        ? { roomSerialNumber: values?.roomSerialNumber }
        : {}),
      inventoryID: values?.inventoryID || "",
      unitToRepair: "",
      description: "",
      targetDate: "",
      documents: [],
      inventoryItemID: values?.inventoryItemID?._id || "",
      repairStatus: values?.repairStatus?._id || "",
      repairPriority: values?.repairPriority?._id || "",
    };
  },
  initialValues: (values?: Partial<MaintenanceMultipleFormInterface>) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      property: values?.property?._id || "",
      unit: values?.unit?._id || "",
      supplier: values?.supplier?._id || "",
      items:
        values?.items?.map((e) => MaintenanceFormikValues.ItemValues(e)) || [],
    };
  },
  validationSchema: yup.object().shape({
    property: yup.string().required("required"),
    unit: yup.string().required("required"),
    supplier: yup.string(),
    notifyTenant: yup.boolean(),
    notifySupplier: yup.boolean(),
    items: yup.array().of(
      yup.object().shape({
        inventoryItemID: yup.string().required("required"),
        repairStatus: yup.string().required("required"),
        repairPriority: yup.string().required("required"),
        unitToRepair: yup.number().required("required"),
        description: yup.string(),
        targetDate: yup.string().required("required"),
      })
    ),
  }),
  editValues: (values: RepairAndMaintenanceInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      property: values?.property?._id || "",
      unit: values?.unit?._id || "",
      supplier: values?.supplier?._id || "",
      notifyTenant: values?.notifyTenant || false,
      notifySupplier: values?.notifySupplier || false,
      isGeneral: values?.isGeneral || false,
      ...(values?.roomID ? { roomID: values?.roomID } : {}),
      ...(values?.roomSerialNumber
        ? { roomSerialNumber: values?.roomSerialNumber }
        : {}),
      description: values?.description || "",
      inventoryID: values?.inventoryID || "",
      inventoryItemID: values?.inventoryItemID?._id || "",
      repairStatus: values?.repairStatus?._id || "",
      repairPriority: values?.repairPriority?._id || "",
      unitToRepair: values?.unitToRepair || "",
      targetDate: values?.targetDate || "",
      documents: values?.documents || [],
    };
  },
  editValidationSchema: yup.object().shape({
    property: yup.string().required("required"),
    unit: yup.string().required("required"),
    supplier: yup.string(),
    notifyTenant: yup.boolean(),
    notifySupplier: yup.boolean(),
    description: yup.string(),
    inventoryID: yup.string().required("required"),
    inventoryItemID: yup.string().required("required"),
    repairStatus: yup.string().required("required"),
    repairPriority: yup.string().required("required"),
    unitToRepair: yup.string().required("required"),
    targetDate: yup.string().required("required"),
    documents: yup.array(),
  }),
};
