import React, { useContext, useEffect } from "react";
import { Form, Formik } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { AccessVerifier, PageTemplate } from "templates";
import { FormConcludeButtons } from "assets";
import { decodeRoleNameHandler } from "helpers";
import {
  ParamsStringEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
  UserRoleEnum,
} from "interfaces";
import { AppContext, OptionsContext, UserContext } from "context";

import { UserForm } from "../components";
import { UserFormikForm } from "../helpers";
import { useAuthorizationHook } from "../../../useHooks";

function NewUserPage() {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { checkCanAccessHandler } = useAuthorizationHook();
  let customRole: any = query?.get(QueryStringEnum.TYPE);
  const {
    details: userDetails,
    editDetailsHandler,
    getDetailsHandler,
  } = useContext(UserContext);
  const params = useParams<ParamsStringEnum>();
  const decodedRole = decodeRoleNameHandler(customRole || userDetails?.role);
  const canAccessSuppliers = checkCanAccessHandler([
    SpaceAccessListEnum.AUTH_ACCESS_SUPPLIER,
  ]);
  if (customRole === UserRoleEnum.NONE) {
    customRole = UserRoleEnum.CUSTOM;
  }

  const isEdit = params.id;

  const { getPrivilegeOptionsHandler } = useContext(OptionsContext);
  const {
    handlers: { setLoading },
  } = useContext(AppContext);

  useEffect(() => {
    (async () => {
      setLoading(true);

      await Promise.all([
        isEdit && getDetailsHandler(isEdit),
        getPrivilegeOptionsHandler(),
      ]);
      setLoading(false);
    })();
  }, [isEdit]);

  const submitHandler = async (values, formikHelpers) => {
    const payload = {
      ...values,
    };
    if (!payload?.role) {
      payload.role = customRole;
    }
    await editDetailsHandler({ ...payload }, formikHelpers, {
      onSuccess: async () => {
        navigate(-1);
      },
    });
  };
  if (!canAccessSuppliers && customRole === UserRoleEnum.SUPPLIER) {
    return (
      <AccessVerifier
        accessKeys={[SpaceAccessListEnum.AUTH_ACCESS_SUPPLIER]}
        isForPage={true}
      >
        <></>
      </AccessVerifier>
    );
  }
  return (
    <PageTemplate
      title={decodedRole}
      scrollAll
      backNavigation={{
        title: `${isEdit ? "Edit" : "Add"} ${decodedRole}`,
      }}
    >
      <>
        <Formik
          enableReinitialize
          initialValues={UserFormikForm?.initialValues(isEdit && userDetails)}
          validationSchema={UserFormikForm?.validationSchema}
          onSubmit={submitHandler}
        >
          {({ isSubmitting }) => {
            return (
              <Form className={"flex flex-col gap-10 mb-20"}>
                <UserForm />
                <FormConcludeButtons
                  submitButton={{
                    title: "Save",
                    isLoading: isSubmitting,
                  }}
                  cancelButton={{
                    title: "Cancel",
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </PageTemplate>
  );
}

export default NewUserPage;
