import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  MyMoreOptionButton,
  TabComponent,
  TableCardComponent,
} from "components";
import { PageTemplate } from "templates";
import { InvoiceTableColumns, TableFilterCard } from "content";
import { InvoiceContext, ReportContext } from "context";
import { PageLinks } from "routes";
import {
  useAuthorizationHook,
  usePageQueryHooks,
  usePaginationQueryHooks,
} from "useHooks";
import {
  IconTypeEnum,
  InvoiceStatusEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
  UserRoleEnum,
} from "interfaces";

function InvoicePage() {
  const {
    getAllDataHandlers,
    allData,
    isLoading,
    totalDocs,
    totalArchiveDocs,
  } = useContext(InvoiceContext);
  const { generateInvoiceReportHandler } = useContext(ReportContext);
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { currentPage, isArchive, paginationQuery } = usePaginationQueryHooks();
  const { searchKeyword, startFrom, endTo } = usePageQueryHooks();
  const tab = query.get(QueryStringEnum?.ACTIVE_TAB);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAddInvoice = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_ADD_INVOICE,
  ]);
  const canReadInvoice = checkCanAccessHandler(
    [SpaceAccessListEnum.FINANCE_READ_INVOICE],
    [UserRoleEnum.LANDLORD, UserRoleEnum.CUSTOM]
  );
  let invoiceStatus, invoiceActive;
  if (tab !== InvoiceStatusEnum.DRAFT) {
    invoiceStatus = tab;
    // invoiceActive = InvoiceActiveEnum.ACTIVE;
  }
  if (tab == InvoiceStatusEnum.DRAFT) {
    invoiceActive = InvoiceStatusEnum.DRAFT;
  }

  const tabOptions = [
    {
      title: `All`,
      pathName: "",
    },
    canReadInvoice && {
      title: `Draft`,
      pathName: InvoiceStatusEnum.DRAFT,
    },
    {
      title: `Sales`,
      pathName: InvoiceStatusEnum.SALES,
    },
    {
      title: `Purchase`,
      pathName: InvoiceStatusEnum.PURCHASE,
    },
    {
      title: `Settled`,
      pathName: InvoiceStatusEnum.SETTLED,
    },
  ];

  const pageQuery = {
    ...paginationQuery,
    invoiceStatus,
    invoiceActive,
    searchKeyword,
    startFrom,
    endTo,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, tab, currentPage, searchKeyword, startFrom, endTo]);

  const tableCardProps = {
    column: InvoiceTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 5,
    },
    dataSource: allData,
    children: {
      leftChildren: <TableFilterCard haveDateRange haveLabel={false} />,
      rightChildren: canAddInvoice && (
        <div className={"flex items-center gap-5"}>
          <MyMoreOptionButton
            type={"button"}
            iconType={IconTypeEnum.ADD}
            options={[
              {
                name: "Sales Invoice",
                handler() {
                  navigate(PageLinks.invoice.new(InvoiceStatusEnum.SALES));
                },
              },
              {
                name: "Sales Return",
                handler() {
                  navigate(
                    PageLinks.invoice.new(InvoiceStatusEnum.SALES_RETURN)
                  );
                },
              },
            ]}
            name={"Add Sales"}
          />
          <MyMoreOptionButton
            iconType={IconTypeEnum.ADD}
            isOutline
            type={"button"}
            options={[
              {
                name: "Purchase Order",
                handler() {
                  navigate(PageLinks.invoice.new(InvoiceStatusEnum.PURCHASE));
                },
              },
              {
                name: "Purchase Return",
                handler() {
                  navigate(
                    PageLinks.invoice.new(InvoiceStatusEnum.PURCHASE_RETURN)
                  );
                },
              },
            ]}
            name={"Add Purchase"}
          />
        </div>
      ),
      aboveTableChildren: <TabComponent tabs={tabOptions} />,
    },
  };
  return (
    <PageTemplate
      scrollAll
      title={"Invoice"}
      breadCrumb={[
        {
          name: "Invoice",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
        searchInput={{}}
        exportXlsx={{
          generateHandler: () => generateInvoiceReportHandler(pageQuery),
        }}
        {...tableCardProps}
      />
    </PageTemplate>
  );
}

export default InvoicePage;
