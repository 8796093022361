import React, { useContext, useEffect, useState } from "react";

import { PageTemplate } from "templates";
import { AccountingContext, AppContext } from "context";

import {
  useGetStartEndDateRangeHooks,
  usePageQueryHooks,
  usePaginationQueryHooks,
} from "useHooks";
import {
  GeneratedLedgerInterface,
  IconTypeEnum,
  QueryStringEnum,
} from "interfaces";
import {
  capitalizeFirstLetter,
  getBalanceSheetTitleHandler,
  getCurrency,
  separateWithComma,
} from "helpers";
import { TableFilterCard } from "content";
import { MyButton, MyToggleButton } from "components";
import { GenerateTrialBalanceReport } from "../reports";
import { PageLinks } from "routes";

function TrialBalancePage() {
  const [hideZBA, setHideZBA] = useState(true);
  const { trialBalance, generateTrialBalanceHandler } =
    useContext(AccountingContext);
  const {
    handlers: { setLoading, setError },
  } = useContext(AppContext);
  const { startDate, endDate, rangeType, isAllTime, fiscalYear } =
    useGetStartEndDateRangeHooks();
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const [data, setData] = useState(trialBalance?.ledgers);
  const { accountDateTitle } = getBalanceSheetTitleHandler({
    startDate,
    endDate,
    rangeType,
    isAllTime,
  });

  useEffect(() => {
    if (hideZBA) {
      setData(
        trialBalance?.ledgers?.filter((e) => {
          return (
            e?.balance !== 0 ||
            e?.openingBalance !== 0 ||
            e?.closingBalance !== 0
          );
        })
      );
    } else {
      setData(trialBalance?.ledgers);
    }
  }, [hideZBA, trialBalance]);
  const generateReportHandler = async () => {
    try {
      setLoading(true);
      await GenerateTrialBalanceReport({
        data: { ...trialBalance, ledgers: data },
        dateHeader: accountDateTitle,
      });
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  const {
    state: { isLoading },
  } = useContext(AppContext);
  const { calcSNHandler } = usePaginationQueryHooks();
  useEffect(() => {
    generateTrialBalanceHandler({
      ...(startDate ? { startDate } : {}),
      ...(endDate ? { endDate } : {}),
    });
  }, [startDate, endDate]);

  const getQueryProps = (id) => {
    return `${QueryStringEnum.ACCOUNT_TYPE}=${id}&${
      QueryStringEnum.START_DATE
    }=${startDate}&${QueryStringEnum.END_DATE}=${endDate}&${
      QueryStringEnum.RANGE_TYPE
    }=${rangeType}&${QueryStringEnum.FISCAL_YEAR}=${fiscalYear || ""}`;
  };

  return (
    <PageTemplate
      title={"Accounting"}
      breadCrumb={[
        {
          name: "Trial Balance",
        },
      ]}
    >
      <div className={"flex flex-col gap-5 overflow-y-scroll"}>
        <div className={"flex items-center justify-between"}>
          <TableFilterCard haveDateRange />
          <div className={"flex items-center gap-5"}>
            <MyToggleButton
              label={"Hide Zero Balance"}
              changeHandler={(e) => {
                setHideZBA(e);
              }}
              buttonType={"toggle"}
              type={"button"}
              value={hideZBA}
            />
            <MyButton
              iconType={IconTypeEnum.DOWNLOAD}
              isOutline
              onClick={generateReportHandler}
              name={"Export .xlsx"}
            />
          </div>
        </div>
        <hr />
        <div className={"overflow-y-scroll"}>
          <table className="table_container overflow-y-auto  ">
            <thead className="table_head whitespace-nowrap sticky top-0  shadow-sm">
              <tr className={"table_row bg-white"}>
                <th scope="col" className="pl-2">
                  SN.
                </th>
                <th scope="col" className="text-left">
                  Group/Ledger
                </th>
                <th scope="col" className="text-left">
                  Type
                </th>
                <th scope="col" colSpan={2} className="px-4 py-3 text-center">
                  Opening
                </th>
                <th scope="col" colSpan={2} className="px-4 py-3 text-center">
                  Transaction
                </th>
                <th scope="col" colSpan={2} className="px-4 py-3 text-center">
                  Closing
                </th>
              </tr>
              <tr className={"table_row bg-gray-100 "}>
                <th scope="col" className="px-4 py-1"></th>
                <th scope="col" className="px-4 py-1"></th>
                <th scope="col" className="px-4 py-1"></th>
                <th scope="col" className="px-4 py-1 text-right">
                  Dr
                </th>
                <th scope="col" className="px-4 py-1 text-right">
                  Cr
                </th>
                <th scope="col" className="px-4 py-1 text-right">
                  Dr
                </th>
                <th scope="col" className="px-4 py-1 text-right">
                  Cr
                </th>
                <th scope="col" className="px-4 py-1 text-right">
                  Dr
                </th>
                <th scope="col" className="px-4 py-1 text-right">
                  Cr
                </th>
              </tr>
              <tr className={"table_row font-semibold text-[16px] bg-white"}>
                <th scope="col" className="px-4 py-1"></th>
                <td scope="col" className="pl-2 py-3  ">
                  Total
                </td>{" "}
                <th scope="col" className="px-4 py-1"></th>
                <td
                  scope="col"
                  className="pl-2 py-3 text-center"
                  style={{ textAlign: "right" }}
                >
                  {getCurrency()}{""}
                  {separateWithComma(trialBalance?.totalOpeningBalanceDebit) ||
                    0}
                </td>{" "}
                <td
                  scope="col"
                  className="pl-2 py-3 text-center"
                  style={{ textAlign: "right" }}
                >
                  {getCurrency()}{""}
                  {separateWithComma(trialBalance?.totalOpeningBalanceCredit) ||
                    0}
                </td>
                <td
                  scope="col"
                  className="pl-2 py-3 text-center"
                  style={{ textAlign: "right" }}
                >
                  {getCurrency()}{""}
                  {separateWithComma(trialBalance?.totalBalanceDebit) || 0}
                </td>
                <td
                  scope="col"
                  className="pl-2 py-3 text-center"
                  style={{ textAlign: "right" }}
                >
                  {getCurrency()}{""}
                  {separateWithComma(trialBalance?.totalBalanceCredit) || 0}
                </td>
                <td
                  scope="col"
                  className="pl-2 py-3 text-center"
                  style={{ textAlign: "right" }}
                >
                  {getCurrency()}{""}
                  {separateWithComma(trialBalance?.totalClosingBalanceDebit) ||
                    0}
                </td>
                <td
                  scope="col"
                  className="pl-2 py-3 text-center"
                  style={{ textAlign: "right" }}
                >
                  {getCurrency()}{""}
                  {separateWithComma(trialBalance?.totalClosingBalanceCredit) ||
                    0}
                </td>
              </tr>
            </thead>
            <tbody className={" overflow-auto w-full"}>
              {!isLoading &&
                data?.map((item: GeneratedLedgerInterface, key) => {
                  // if (item?.balance === 0) {
                  return (
                    <tr className={`table_row`} key={key}>
                      <td scope="col" className="pl-2 py-3 ">
                        {calcSNHandler(key)}.
                      </td>
                      <td
                        scope="col"
                        style={{
                          whiteSpace: "normal",
                        }}
                        className="pl-2 py-3 max-w-[200px] "
                      >
                        {item?.account?.name}
                      </td>
                      <td scope="col" className="pl-2 py-3">
                        {capitalizeFirstLetter(item?.account?.group)}
                      </td>{" "}
                      <td
                        scope="col"
                        className="pl-2 py-3 "
                        style={{ textAlign: "right" }}
                      >
                        {getCurrency()}{""}
                        {item?.openingBalance > 0
                          ? separateWithComma(Math.abs(item?.openingBalance))
                          : "0.00"}
                      </td>{" "}
                      <td
                        scope="col"
                        className="pl-2 py-3 "
                        style={{ textAlign: "right" }}
                      >
                        {getCurrency()}{""}
                        {item?.openingBalance < 0
                          ? separateWithComma(Math.abs(item?.openingBalance))
                          : "0.00"}
                      </td>
                      <td
                        scope="col"
                        onClick={() => {
                          item?.balance > 0 &&
                            navigateWithReturnUrlHandler(
                              PageLinks.ledger.listLedgerTransaction,
                              getQueryProps(item?.account?._id)
                            );
                        }}
                        className={`pl-2 py-3  ${
                          item?.balance > 0 &&
                          "font-semibold text-primary cursor-pointer hover:underline"
                        }`}
                        style={{ textAlign: "right" }}
                      >
                        {getCurrency()}{""}
                        {item?.balance > 0
                          ? separateWithComma(Math.abs(item?.balance))
                          : "0.00"}
                      </td>
                      <td
                        scope="col"
                        className={`pl-2 py-3  ${
                          item?.balance < 0 &&
                          "font-semibold text-primary cursor-pointer hover:underline"
                        }`}
                        onClick={() => {
                          item?.balance < 0 &&
                            navigateWithReturnUrlHandler(
                              PageLinks.ledger.listLedgerTransaction,
                              getQueryProps(item?.account?._id)
                            );
                        }}
                        style={{ textAlign: "right" }}
                      >
                        {getCurrency()}{""}
                        {item?.balance < 0
                          ? separateWithComma(Math.abs(item?.balance))
                          : "0.00"}
                      </td>
                      <td
                        scope="col"
                        className="pl-2 py-3 text-center"
                        style={{ textAlign: "right" }}
                      >
                        {getCurrency()}{""}
                        {item?.closingBalance > 0
                          ? separateWithComma(Math.abs(item?.closingBalance))
                          : "0.00"}
                      </td>
                      <td
                        scope="col"
                        className="pl-2 py-3 text-center"
                        style={{ textAlign: "right" }}
                      >
                        {getCurrency()}{""}
                        {item?.closingBalance < 0
                          ? separateWithComma(Math.abs(item?.closingBalance))
                          : "0.00"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {!isLoading && (!trialBalance || trialBalance?.ledgers?.length < 1) && (
          <div className="text-center w-full bg-blue-50 p-1 ">No data</div>
        )}
      </div>
    </PageTemplate>
  );
}

export default TrialBalancePage;
