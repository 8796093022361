import { EventInterface } from "interfaces";
import * as yup from "yup";
import { validateDateHandler } from "../../helpers";

export const EventFormikValues = {
  initialValues: (values?: EventInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      property: values?.property?._id || "",
      name: values?.name,
      startDate: values?.startDate,
      endDate: values?.endDate,
      recurring: {
        createRecurringEvent:
          values?.recurring?.recurringType?._id ||
          values?.recurring?.endDate ||
          values?.recurring?.forever ||
          false,
        recurringType: values?.recurring?.recurringType?._id,
        forever: values?.recurring?.forever || false,
        endDate: values?.recurring?.endDate,
      },
      reminder: {
        pushReminder: values?.reminder?.pushReminder || false,
        reminderBeforeAfterType: values?.reminder?.reminderBeforeAfterType?._id,
        beforeAfterQuantity: values?.reminder?.beforeAfterQuantity,
      },
      location: values?.location,
      description: values?.description,
      participants: values?.participants?.map((e) => e?._id) || [],
      shouldNotifyParticipant: values?.shouldNotifyParticipant || false,
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().required("required"),
    startDate: yup.string().required("required"),
    endDate: yup
      .string()
      .test("custom-validation", "Invalid field value", function (value) {
        const { message, isError } = validateDateHandler(value, {
          minDate: this.parent.startDate,
        });
        return (
          !isError ||
          this.createError({
            path: "endDate",
            message: message,
          })
        );
      })
      .required("required"),
  }),
};
