import React, { createContext, useContext, useState } from "react";

import { AppContext } from "context";
import { Api } from "helpers";
import { ApiUrl } from "services";
import { AccountingContextInterface } from "./interfaces";

export const AccountingContext = createContext<AccountingContextInterface>({
  isDetailsLoading: false,
  trialBalance: null,
  ledgers: null,
  ledgerTransactions: null,
  incomeStatement: null,
  balanceSheet: null,
  generateBalanceSheetHandler(query) {},
  generateIncomeStatementHandler(query) {},
  generateLedgerTransactionHandler(query) {},
  generateLedgersHandler(query) {},
  generateTrialBalanceHandler(query) {},
});

function DataContextProvider({ children }) {
  const { handlers } = useContext(AppContext);
  const { putApi, getApi } = Api();
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [ledgers, setLedgers] = useState(null);
  const [trialBalance, setTrialBalance] = useState(null);
  const [ledgerTransactions, setLedgerTransactions] = useState(null);
  const [incomeStatement, setIncomeStatement] = useState(null);
  const [balanceSheet, setBalanceSheet] = useState(null);
  //  handler
  const contextHandlers = {
    generateLedgersHandler: async (query) => {
      try {
        setIsDetailsLoading(true);
        setLedgers({});
        let res = await putApi(ApiUrl.account.put_generateLedger, {
          ...query,
        });
        setLedgers(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setIsDetailsLoading(false);
      }
    },
    generateBalanceSheetHandler: async (query) => {
      try {
        setIsDetailsLoading(true);
        setBalanceSheet({});
        let res = await putApi(ApiUrl.account.put_generateBalanceSheet, {
          ...query,
        });
        setBalanceSheet(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setIsDetailsLoading(false);
      }
    },
    generateIncomeStatementHandler: async (query) => {
      try {
        setIsDetailsLoading(true);
        setIncomeStatement({});
        let res = await putApi(ApiUrl.account.put_generateIncomeStatement, {
          ...query,
        });
        setIncomeStatement(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setIsDetailsLoading(false);
      }
    },
    generateLedgerTransactionHandler: async (query) => {
      try {
        setIsDetailsLoading(true);
        setLedgerTransactions({});

        let res = await putApi(ApiUrl.account.put_generateLedgerTransaction, {
          ...query,
        });
        setLedgerTransactions(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setIsDetailsLoading(false);
      }
    },
    generateTrialBalanceHandler: async (query) => {
      try {
        handlers?.setLoading(true);
        setTrialBalance({});
        let res = await getApi(ApiUrl.trialBalance.put_generateTrialBalance, {
          ...query,
        });
        setTrialBalance(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
  };

  const contextValue = {
    isDetailsLoading,
    trialBalance,
    ledgers,
    ledgerTransactions,
    incomeStatement,
    balanceSheet,
    ...contextHandlers,
  };
  return (
    <AccountingContext.Provider value={contextValue}>
      {children}
    </AccountingContext.Provider>
  );
}

export default DataContextProvider;
