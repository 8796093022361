import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { PageTemplate } from "templates";

import { BankReconciliationContext, ReportContext } from "context";
import { TableCardComponent } from "components";
import {
  useGetStartEndDateRangeHooks,
  usePaginationQueryHooks,
} from "useHooks";

import { ReconcileItemsTableColumns } from "../components";
import { TableFilterCard } from "content";
import { QueryStringEnum } from "interfaces";

function ReconcilePage() {
  const [query] = useSearchParams();
  const { endDate, startDate } = useGetStartEndDateRangeHooks();
  const {
    getReconcileItemsHandler,
    reconcileItems,
    totalReconcileItems,
    isLoading,
  } = useContext(BankReconciliationContext);
  const { generateReconcileReportHandler } = useContext(ReportContext);
  const { currentPage } = usePaginationQueryHooks();
  const bank = query.get(QueryStringEnum.BANK);
  const transactionType = query.get(QueryStringEnum.TRANSACTION_TYPE);
  const pageQuery = {
    bank,
    startDate,
    endDate,
    transactionType,
  };
  useEffect(() => {
    getReconcileItemsHandler(pageQuery);
  }, [currentPage, startDate, endDate, bank, transactionType]);

  const tableCardProps = {
    column: ReconcileItemsTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: reconcileItems,
  };
  return (
    <PageTemplate
      scrollAll
      title={"Reconciled Statements"}
      breadCrumb={[
        {
          name: "Finance",
        },
        {
          name: "Reconciled Statements",
        },
      ]}
    >
      <div>
        <TableCardComponent
          exportXlsx={{
            generateHandler: () => generateReconcileReportHandler(pageQuery),
            havePaddingTop: true,
          }}
          children={{
            leftChildren: (
              <TableFilterCard
                haveTransactionType
                haveLabel
                haveBank
                haveDateRange
              />
            ),
          }}
          {...tableCardProps}
          havePagination={{
            totalDocs: totalReconcileItems,
          }}
        />
      </div>
    </PageTemplate>
  );
}

export default ReconcilePage;
