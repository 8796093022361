import React from "react";
import { ToggleContentWrapper } from "../components";
import { DashboardApiTypeEnum, ChartResponseInterface } from "interfaces";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getGraphData, ChartOptions } from "../helpers";
import { getCurrency } from "../../../helpers";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
function IncomeExpensesStats({ isToggle }) {
  return (
    <ToggleContentWrapper
      toggle={isToggle}
      title={"Income vs Expenses"}
      getDataType={DashboardApiTypeEnum.IncomeExpensesChart}
      haveDateFilter={true}
      havePropertyFilter={false}
    >
      {(data: ChartResponseInterface) => {
        return (
          <div className={"pt-5"}>
            <div className={"mt-5"}>
              <Line
                options={{
                  ...ChartOptions,
                  scales: {
                    y: {
                      title: {
                        display: true,
                        text: `Amount (${getCurrency()})`,
                        font: {
                          weight: "bold",
                        },
                      },
                    },
                    x: {
                      title: {
                        display: true,
                        text: "Date",
                        font: {
                          weight: "bold",
                        },
                      },
                    },
                  },
                }}
                data={getGraphData(data)}
              />
            </div>
          </div>
        );
      }}
    </ToggleContentWrapper>
  );
}

export default IncomeExpensesStats;
