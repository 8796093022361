import React, { useContext, useEffect, useState } from "react";

import { PageTemplate } from "templates";
import { getBalanceSheetTitleHandler } from "helpers";
import { AccountingContext, AppContext } from "context";
import { useDateHook, useGetStartEndDateRangeHooks } from "useHooks";

import { ContentTypeEnum } from "../interfaces";
import { GetContentRowFormat } from "../helpers";
import { BalanceSheetTemplate } from "../components";
import { BalanceSheet_IncomeStatementReport } from "../reports";
import { ToggleComponent, ContentRowComponent } from "../assets";

function BalanceSheetPage() {
  const [hideZero, setHideZero] = useState(false);
  const { balanceSheet, isDetailsLoading, generateBalanceSheetHandler } =
    useContext(AccountingContext);
  const {
    handlers: { setLoading, setError },
    state: { profileDetails },
  } = useContext(AppContext);
  const {
    startDate,
    endDate,
    rangeType,
    isAllTime,
    compareEndDate,
    compareStartDate,
    compareRangeType,
    isCompareAllTime,
  } = useGetStartEndDateRangeHooks();
  const { getDateHandler } = useDateHook();
  const { accountDateTitle } = getBalanceSheetTitleHandler({
    startDate,
    endDate,
    rangeType,
    isAllTime,
  });
  const { accountDateTitle: compareTitle } = getBalanceSheetTitleHandler({
    startDate: compareStartDate,
    endDate: compareEndDate,
    rangeType: compareRangeType,
    isAllTime: isCompareAllTime,
    isForBalanceSheet: true,
  });
  const isCompared =
    compareRangeType && compareStartDate && compareEndDate ? true : false;

  useEffect(() => {
    const payload = {
      startDate,
      endDate,
      compare: {
        startDate: compareStartDate,
        endDate: compareEndDate,
      },
    };
    generateBalanceSheetHandler(payload);
  }, [startDate, endDate, compareEndDate, compareStartDate]);

  const TangibleFixedAssets = [
    ...(balanceSheet?.tangibleFixedAssets?.length > 0
      ? balanceSheet?.tangibleFixedAssets?.map(
          (e) => new GetContentRowFormat(e)
        )
      : []),
  ];
  const IntangibleFixedAssets = [
    ...(balanceSheet?.intangibleFixedAssets?.length > 0
      ? balanceSheet?.intangibleFixedAssets?.map(
          (e) => new GetContentRowFormat(e)
        )
      : []),
  ];
  const NonCurrentAssets = [
    ...(balanceSheet?.nonCurrentAssets?.length > 0
      ? balanceSheet?.nonCurrentAssets?.map((e) => new GetContentRowFormat(e))
      : []),
  ];
  const InvestmentProperties = [
    ...(balanceSheet?.investmentProperties?.length > 0
      ? balanceSheet?.investmentProperties?.map(
          (e) => new GetContentRowFormat(e)
        )
      : []),
  ];
  const InvestmentCurrent = [
    ...(balanceSheet?.investmentCurrent?.length > 0
      ? balanceSheet?.investmentCurrent?.map((e) => new GetContentRowFormat(e))
      : []),
  ];
  const InvestmentFixed = [
    ...(balanceSheet?.investmentFixed?.length > 0
      ? balanceSheet?.investmentFixed?.map((e) => new GetContentRowFormat(e))
      : []),
  ];
  const CurrentAssets = [
    ...(balanceSheet?.currentAssets?.length > 0
      ? balanceSheet?.currentAssets?.map((e) => new GetContentRowFormat(e))
      : []),
  ];
  const CurrentLiabilityWithinYear = [
    ...(balanceSheet?.currentLiabilityWithinYear?.length > 0
      ? balanceSheet?.currentLiabilityWithinYear?.map(
          (e) => new GetContentRowFormat(e)
        )
      : []),
  ];
  const CurrentLiabilityAfterMoreThanYear = [
    ...(balanceSheet?.currentLiabilityAfterMoreThanYear?.length > 0
      ? balanceSheet?.currentLiabilityAfterMoreThanYear?.map(
          (e) => new GetContentRowFormat(e)
        )
      : []),
  ];
  const CurrentAndReserve = [
    ...(balanceSheet?.capitalReserve?.length > 0
      ? balanceSheet?.capitalReserve?.map((e) => new GetContentRowFormat(e))
      : []),
  ];

  const DataObj = {
    "Tangible Fixed Assets": {
      type: ContentTypeEnum.content,
      data: TangibleFixedAssets,
    },
    "Intangible Fixed Assets": {
      type: ContentTypeEnum.content,
      data: IntangibleFixedAssets,
    },
    "Non-Current Assets": {
      type: ContentTypeEnum.content,
      data: NonCurrentAssets,
    },
    "Investment Properties": {
      type: ContentTypeEnum.content,
      data: InvestmentProperties,
    },
    "Investment - Current": {
      type: ContentTypeEnum.content,
      data: InvestmentCurrent,
    },
    "Investment - Fixed": {
      type: ContentTypeEnum.content,
      data: InvestmentFixed,
    },
    "Total Fixed Assets & Investment": {
      type: ContentTypeEnum.subTotal,
      data: new GetContentRowFormat({
        amount: balanceSheet?.total?.totalFixedAssetsAndInvestment,
        firstCompareTimeValue:
          balanceSheet?.firstCompareTimeValue?.totalFixedAssetsAndInvestment,
        secondCompareTimeValue:
          balanceSheet?.secondCompareTimeValue?.totalFixedAssetsAndInvestment,
        percentageCompareValue:
          balanceSheet?.percentageCompareValue?.totalFixedAssetsAndInvestment,
        deviationCompareValue:
          balanceSheet?.deviationCompareValue?.totalFixedAssetsAndInvestment,
        isLess: false,
      }),
    },
    "Current Assets": {
      type: ContentTypeEnum.content,
      data: CurrentAssets,
    },
    "Current Liabilities Falling Due within One Year": {
      type: ContentTypeEnum.content,
      data: CurrentLiabilityWithinYear,
    },
    "Total Current Liabilities Falling Due Within One Year": {
      type: ContentTypeEnum.subTotal,
      data: new GetContentRowFormat({
        amount: balanceSheet?.total?.currentLiabilityWithinYear,
        firstCompareTimeValue:
          balanceSheet?.firstCompareTimeValue?.currentLiabilityWithinYear,
        secondCompareTimeValue:
          balanceSheet?.secondCompareTimeValue?.currentLiabilityWithinYear,
        percentageCompareValue:
          balanceSheet?.percentageCompareValue?.currentLiabilityWithinYear,
        deviationCompareValue:
          balanceSheet?.deviationCompareValue?.currentLiabilityWithinYear,
        isLess: false,
      }),
    },
    "Net Current Assets (Liabilities)": {
      type: ContentTypeEnum.total,
      data: new GetContentRowFormat({
        amount: balanceSheet?.total?.netCurrentAssetsAndLiabilities,
        firstCompareTimeValue:
          balanceSheet?.firstCompareTimeValue?.netCurrentAssetsAndLiabilities,
        secondCompareTimeValue:
          balanceSheet?.secondCompareTimeValue?.netCurrentAssetsAndLiabilities,
        percentageCompareValue:
          balanceSheet?.percentageCompareValue?.netCurrentAssetsAndLiabilities,
        deviationCompareValue:
          balanceSheet?.deviationCompareValue?.netCurrentAssetsAndLiabilities,
        isLess: false,
      }),
    },
    "Total Assets Less Current Liabilities": {
      type: ContentTypeEnum.total,
      data: new GetContentRowFormat({
        amount: balanceSheet?.total?.totalAssetsLessCurrent,
        firstCompareTimeValue:
          balanceSheet?.firstCompareTimeValue?.totalAssetsLessCurrent,
        secondCompareTimeValue:
          balanceSheet?.secondCompareTimeValue?.totalAssetsLessCurrent,
        percentageCompareValue:
          balanceSheet?.percentageCompareValue?.totalAssetsLessCurrent,
        deviationCompareValue:
          balanceSheet?.deviationCompareValue?.totalAssetsLessCurrent,
        isLess: false,
      }),
    },
    "Current Liabilities Falling Due After more than One Year": {
      type: ContentTypeEnum.content,
      data: CurrentLiabilityAfterMoreThanYear,
    },
    "Total Current Liabilities Falling Due After more than One Year": {
      type: ContentTypeEnum.subTotal,
      data: new GetContentRowFormat({
        amount: balanceSheet?.total?.currentLiabilityAfterMoreThanYear,
        firstCompareTimeValue:
          balanceSheet?.firstCompareTimeValue
            ?.currentLiabilityAfterMoreThanYear,
        secondCompareTimeValue:
          balanceSheet?.secondCompareTimeValue
            ?.currentLiabilityAfterMoreThanYear,
        percentageCompareValue:
          balanceSheet?.percentageCompareValue
            ?.currentLiabilityAfterMoreThanYear,
        deviationCompareValue:
          balanceSheet?.deviationCompareValue
            ?.currentLiabilityAfterMoreThanYear,
      }),
    },
    "Net Assets": {
      type: ContentTypeEnum.total,
      data: new GetContentRowFormat({
        amount: balanceSheet?.total?.netAssets,
        firstCompareTimeValue: balanceSheet?.firstCompareTimeValue?.netAssets,
        secondCompareTimeValue: balanceSheet?.secondCompareTimeValue?.netAssets,
        percentageCompareValue: balanceSheet?.percentageCompareValue?.netAssets,
        deviationCompareValue: balanceSheet?.deviationCompareValue?.netAssets,
      }),
    },
    "Current & Reserves": {
      type: ContentTypeEnum.content,
      data: CurrentAndReserve,
    },
    "Total Capital & Reserve Assets": {
      type: ContentTypeEnum.subTotal,

      data: new GetContentRowFormat({
        amount: balanceSheet?.total?.capitalReserve,
        firstCompareTimeValue:
          balanceSheet?.firstCompareTimeValue?.capitalReserve,
        secondCompareTimeValue:
          balanceSheet?.secondCompareTimeValue?.capitalReserve,
        percentageCompareValue:
          balanceSheet?.percentageCompareValue?.capitalReserve,
        deviationCompareValue:
          balanceSheet?.deviationCompareValue?.capitalReserve,
      }),
    },
  };
  const generateReportHandler = async () => {
    try {
      setLoading(true);
      await BalanceSheet_IncomeStatementReport({
        data: DataObj,
        isFromBalanceSheet: true,
        dateFormatter: getDateHandler,
        isCompared: isCompared,
        compareHeader: compareTitle,
        dateHeader: accountDateTitle,
        organizationName: profileDetails?.companyName,
      });
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <PageTemplate
      scrollAll
      title={"Accounting"}
      breadCrumb={[
        {
          name: "Balance Sheet",
        },
      ]}
    >
      <BalanceSheetTemplate
        hideZero={hideZero}
        setHideZero={setHideZero}
        isForBalanceSheet
        title={"Balance Sheet"}
        isLoading={isDetailsLoading}
        haveData={DataObj !== null}
        totalAmount={0}
        haveTitle={false}
        generateReportHandler={generateReportHandler}
      >
        {Object?.keys(DataObj)?.map((e, key) => {
          let values = DataObj[e];
          if (
            values?.type === ContentTypeEnum.content &&
            Array.isArray(values?.data) &&
            values?.data?.length > 0
          ) {
            let isEmpty = DataObj[e]?.data?.filter(
              (f) =>
                !(
                  (f?.value === 0 || !f?.value) &&
                  (f?.firstCompareTimeValue === 0 || !f?.firstCompareTimeValue)
                )
            )?.length;
            if (!isEmpty && hideZero) return;
            return (
              <ToggleComponent title={e} key={key}>
                {DataObj[e]?.data?.map((f, fKey) => {
                  if (!e) return;
                  if (
                    f?.value === 0 &&
                    f?.firstCompareTimeValue === 0 &&
                    hideZero
                  ) {
                    return;
                  }
                  return (
                    <>
                      <ContentRowComponent
                        key={fKey}
                        account={f?.account}
                        title={f?.title}
                        value={f?.value}
                        haveBackground={fKey % 2 === 0}
                        firstComparedAmount={f?.firstCompareTimeValue}
                        comparedAmount={f?.deviationCompareValue}
                        deviation={f?.percentageCompareValue}
                        isLess={f?.isLess}
                        isCompared={isCompared}
                        type={ContentTypeEnum.content}
                      />
                    </>
                  );
                })}
              </ToggleComponent>
            );
          }
          if (
            values?.type === ContentTypeEnum.subTotal ||
            values?.type === ContentTypeEnum.total
          ) {
            return (
              <>
                <hr className={"mb-2"} />
                <ContentRowComponent
                  key={key}
                  title={e}
                  value={values?.data?.value}
                  firstComparedAmount={values?.data?.firstCompareTimeValue}
                  comparedAmount={values?.data?.deviationCompareValue}
                  deviation={values?.data?.percentageCompareValue}
                  isCompared={isCompared}
                  type={values?.type}
                />
                <hr className={"mt-2"} />
              </>
            );
          }
        })}
      </BalanceSheetTemplate>
    </PageTemplate>
  );
}

export default BalanceSheetPage;
