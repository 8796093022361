import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { MyButton, TableCardComponent } from "components";
import { AccessVerifier, PageTemplate } from "templates";
import { IconTypeEnum, QueryStringEnum, SpaceAccessListEnum } from "interfaces";
import { TableFilterCard } from "content";

import { TripsTableColumns } from "../../components";
import { TripContext } from "context";
import { usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function TripsPage() {
  const {
    isLoading,
    getAllDataHandlers,
    allData,
    totalDocs,
    totalArchiveDocs,
  } = useContext(TripContext);

  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { isArchive, currentPage } = usePaginationQueryHooks();
  const unitID = query.get(QueryStringEnum?.UNIT_ID);
  const propertyID = query.get(QueryStringEnum?.PROPERTY_ID);
  const pageQuery = {
    unit: unitID,
    property: propertyID,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, unitID, propertyID, currentPage]);

  const tableCardProps = {
    column: TripsTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      leftChildren: <TableFilterCard haveProperty />,
      rightChildren: (
        <AccessVerifier
          accessKeys={[SpaceAccessListEnum.TOOLS_ADD_TRIP]}
          isForPage={false}
        >
          <MyButton
            onClick={() => {
              navigate(PageLinks.trips.new);
            }}
            iconType={IconTypeEnum.ADD}
            name={"Add Trip"}
          />
        </AccessVerifier>
      ),
    },
  };
  return (
    <PageTemplate
      title={"Tools"}
      breadCrumb={[
        {
          name: "Trips",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default TripsPage;
