import React, { useContext, useEffect, useState } from "react";
import { DashboardStatsContext } from "context";
import { getCurrency, separateWithComma } from "helpers";
import {
  DashboardApiTypeEnum,
  IconTypeEnum,
  MortgageDashboardStatsInterface,
  SpaceAccessListEnum,
} from "interfaces";
import { IconComponent } from "../components";
import { MyButton } from "components";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "routes";
import { AccessVerifier } from "../../../templates";

function MortgageStatsContent() {
  const { getStatsHandler } = useContext(DashboardStatsContext);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState<MortgageDashboardStatsInterface>(null);
  useEffect(() => {
    (async () => {
      setLoading(true);
      await getStatsHandler(
        DashboardApiTypeEnum.mortgage,
        {},
        {
          onError(error?: any) {
            setError(error);
            setLoading(false);
          },
          onSuccess(payload?: any): any {
            setData(payload);
            setLoading(false);
          },
        }
      );
    })();
  }, []);
  return (
    <div className={"flex flex-col gap-5 bg-white rounded-md p-5 h-full"}>
      <h1 className={"text-[22px] font-bold"}>Loans/Mortgages</h1>
      <div className={"flex flex-col gap-5"}>
        <div className={"flex items-center gap-5"}>
          <IconComponent
              isRounded={false}
              color={"purple"}
              iconType={IconTypeEnum.SETTLED}
          />
          <div className={"flex flex-col gap-1 text-[14px]"}>
            <span>Total Loan Amount</span>
            <span className={"font-bold text-[18px]"}>
              {getCurrency()}{separateWithComma(data?.totalLoanAmount) || "-"}
            </span>
          </div>
        </div>
        <div className={"flex items-center gap-5"}>
          <IconComponent
            isRounded={false}
            color={"blue"}
            iconType={IconTypeEnum.SETTLED}
          />
          <div className={"flex flex-col gap-1 text-[14px]"}>
            <span>Total Interest Paid</span>
            <span className={"font-bold text-[18px]"}>
              {getCurrency()}{""}
              {separateWithComma(data?.totalInterestPaid) || "-"}
            </span>
          </div>
        </div>
        {/*<div className={"flex items-center gap-5"}>*/}
        {/*  <IconComponent*/}
        {/*    isRounded={false}*/}
        {/*    color={"green"}*/}
        {/*    iconType={IconTypeEnum.SETTLED}*/}
        {/*  />*/}
        {/*  <div className={"flex flex-col gap-1 text-[14px]"}>*/}
        {/*    <span>Total Loan Paid</span>*/}
        {/*    <span className={"font-bold text-[18px]"}>*/}
        {/*      {getCurrency()}{separateWithComma(data?.totalLoanPaid) || "-"}*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <AccessVerifier
          accessKeys={[SpaceAccessListEnum.FINANCE_READ_LOAN_AND_MORTGAGE]}
          isForPage={false}
        >
          <MyButton
            onClick={() => navigate(PageLinks.loansAndMortgages.list)}
            className={"rounded-md"}
            colorType={"white"}
            name={"View All"}
            isOutline
          />
        </AccessVerifier>
      </div>
    </div>
  );
}

export default MortgageStatsContent;
