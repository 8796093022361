import React, { useContext, useEffect } from "react";
import { PageTemplate } from "templates";
import { Outlet, useNavigate } from "react-router-dom";
import { AppContext } from "context";
import { MyButton, MyCheckButton } from "components";
import { NameValueText } from "assets";
import { PageLinks } from "routes";
import { IconTypeEnum } from "interfaces";

function TrueLayerSettingsPage() {
  const navigate = useNavigate();
  const {
    state: { twilioSettings },
    handlers: { getTwilioSettingsHandler, editTwilioSettingsHandler },
  } = useContext(AppContext);
  useEffect(() => {
    getTwilioSettingsHandler();
  }, []);

  return (
    <PageTemplate
      title={"Settings"}
      titleRightChildren={
        <div>
          {twilioSettings?.enable && (
            <MyButton
              isOutline
              iconType={IconTypeEnum.SETTING}
              name={"Configure"}
              onClick={() =>
                navigate(PageLinks.settings.editTwilioLayerSettings)
              }
            />
          )}
        </div>
      }
      breadCrumb={[
        {
          name: "Twilio Configuration",
        },
      ]}
    >
      <div className={"flex flex-col gap-10 items-start"}>
        <MyCheckButton
          value={twilioSettings?.enable}
          changeHandler={(newValue) => {
            editTwilioSettingsHandler({
              ...twilioSettings,
              enable: newValue,
            });
          }}
          label={"Enable Twilio"}
          isChecked={twilioSettings?.enable}
        />
        <div className={"grid grid-cols-1 gap-10 "}>
          <NameValueText
            value={twilioSettings?.accountID || "-"}
            name={"Account ID"}
          />{" "}
          <NameValueText
            value={twilioSettings?.authToken || "-"}
            name={"Auth Token"}
          />
          <NameValueText
            value={twilioSettings?.senderPhone || "-"}
            name={"Sender Phone"}
          />
        </div>

        <Outlet />
      </div>
    </PageTemplate>
  );
}

export default TrueLayerSettingsPage;
