import { HiOutlineHomeModern } from "react-icons/hi2";
import { SlPeople } from "react-icons/sl";
import { FiKey } from "react-icons/fi";
import { MdOutlineBed } from "react-icons/md";

import {
  FieldSetInterface,
  ModuleEnum,
  ModuleInterface,
  SubModuleEnum,
  SubModuleInterface,
} from "../interfaces";
import { FieldTypeEnum } from "interfaces";

export const ModuleLists: ModuleInterface = {
  [ModuleEnum.RENTAL]: {
    Icon: HiOutlineHomeModern,
    name: "Rental",
  },
  [ModuleEnum.TENANTS]: {
    Icon: SlPeople,
    name: "Tenants",
  },
  [ModuleEnum.TENANCIES]: {
    Icon: FiKey,
    name: "Tenancies",
  },
};
export const SubModuleLists: SubModuleInterface = {
  [ModuleEnum.RENTAL]: [
    {
      name: "Property",
      Icon: HiOutlineHomeModern,
      key: SubModuleEnum.PROPERTY,
    },
    {
      name: "Unit",
      Icon: MdOutlineBed,
      key: SubModuleEnum.UNIT,
    },
  ],
  [ModuleEnum.TENANTS]: [],
  [ModuleEnum.TENANCIES]: [],
};
export const FieldSetLists: FieldSetInterface = {
  [SubModuleEnum.PROPERTY]: [
    {
      name: "Property Type",
      key: FieldTypeEnum.PROPERTY_TYPE,
      details: "Loream is this tas awe awr",
    },
    {
      name: "Owner Type",
      key: FieldTypeEnum.OWNER_TYPE,
      details: "Loream is this tas awe awr",
    },
    {
      name: "Property Amenities",
      key: FieldTypeEnum.PROPERTY_AMENITIES,
      details: "Loream is this tas awe awr",
    },
  ],
  [SubModuleEnum.UNIT]: [
    {
      name: "Unit Amenities",
      key: FieldTypeEnum.UNIT_AMENITIES,
      details: "Loream is this tas awe awr",
    },
    {
      name: "Unit Size",
      key: FieldTypeEnum.UNIT_SIZE,
      details: "Loream is this tas awe awr",
    },
  ],
};
