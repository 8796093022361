import React, { useContext } from "react";

import {
  useAuthorizationHook,
  useDateHook,
  usePageQueryHooks,
  usePaginationQueryHooks,
} from "useHooks";
import { PageLinks } from "routes";
import { LoanContext } from "context";
import { MyMoreOptionButton } from "components";

import { PropertyUnitTableText, StatusText, TableIdValueText } from "assets";
import { getCurrency, separateWithComma } from "helpers";
import {
  LoanAndMortgageInterface,
  SpaceAccessListEnum,
  TableColumnInterface,
} from "interfaces";

function L_MTableColumns(
  query?: any
): TableColumnInterface<LoanAndMortgageInterface>[] {
  const { archiveHandler, getAllDataHandlers, deleteHandler } =
    useContext(LoanContext);
  const { getDateHandler, getStaffFullName } = useDateHook();
  const { calcSNHandler } = usePaginationQueryHooks();
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_EDIT_LOAN_AND_MORTGAGE,
  ]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_DEL_LOAN_AND_MORTGAGE,
  ]);

  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <span>{calcSNHandler(key)}</span>;
      },
    },
    {
      title: "Lender",
      render(renderData) {
        return (
          <TableIdValueText
            id={renderData?.loanID}
            value={getStaffFullName(renderData?.lender?.name)}
          />
        );
      },
    },
    {
      title: "Property",
      render(renderData) {
        return (
          <PropertyUnitTableText
            address={renderData?.property?.address}
            type={"property"}
            propertyID={renderData?.property?._id}
            ID={renderData?.property?.propertyID}
          />
        );
      },
    },
    {
      title: "Unit",
      render(renderData) {
        return (
          <PropertyUnitTableText
            type={"unit"}
            ID={renderData?.unit?.unitID}
            name={renderData?.unit?.name}
            propertyID={renderData?.unit?._id}
          />
        );
      },
    },

    {
      title: `Start/End Date`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>{getDateHandler({ date: renderData?.startDate })}</span>
            <span>{getDateHandler({ date: renderData?.endDate })}</span>
          </div>
        );
      },
    },
    {
      title: `Loan Amount`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>
              {getCurrency()}{separateWithComma(renderData?.loanAmount)}
            </span>
          </div>
        );
      },
    },
    {
      title: `Mortgage Type`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span> {renderData?.mortgageType?.label}</span>
          </div>
        );
      },
    },
    {
      title: `Loan Amount Paid`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>
              {getCurrency()}{separateWithComma(renderData?.loanAmountPaid)}
            </span>
          </div>
        );
      },
    },
    {
      title: `Outstanding Loan Amount`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>
              {getCurrency()}{""}
              {separateWithComma(
                (renderData?.loanAmount ?? 0) -
                  (renderData?.loanAmountPaid ?? 0)
              )}
            </span>
          </div>
        );
      },
    },
    {
      title: `Status`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <StatusText
              status={renderData?.isCompleted ? "Close" : "Open"}
              label={renderData?.isCompleted ? "Close" : "Open"}
            />
          </div>
        );
      },
    },
    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigateWithReturnUrlHandler(
                      PageLinks.loansAndMortgages.details(renderData?._id)
                    );
                  },
                },
                canEdit &&
                  !renderData?.isArchived && {
                    name: "Edit",
                    handler: () => {
                      navigateWithReturnUrlHandler(
                        PageLinks.loansAndMortgages.edit(renderData?._id)
                      );
                    },
                  },
                canDelete && {
                  name: renderData?.isArchived ? `Unarchive` : "Archive",
                  handler: () => {
                    archiveHandler(renderData?._id, renderData?.isArchived, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
                canDelete && {
                  name: "Delete",
                  handler: () => {
                    deleteHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default L_MTableColumns;
