import React, { useContext, useEffect } from "react";

import { useSearchParams } from "react-router-dom";
import {
  useDateHook,
  useGetStartEndDateRangeHooks,
  usePageQueryHooks,
} from "useHooks";

import { PageLinks } from "routes";
import { PageTemplate } from "templates";
import { TableFilterCard } from "content";
import { IconTypeEnum, QueryStringEnum } from "interfaces";
import { AccountingContext, AppContext } from "context";
import {
  getBalanceSheetTitleHandler,
  isDR,
  separateWithComma,
  checkIsDate,
} from "helpers";
import { MessageComponent } from "../../assets";
import { LedgerTransactionReport } from "../../reports";
import { MyButton } from "components";

function LedgerTransactionPage() {
  const [query] = useSearchParams();
  const selectedAccount = query.get(QueryStringEnum.ACCOUNT_TYPE);
  const { startDate, endDate, rangeType, isAllTime } =
    useGetStartEndDateRangeHooks();
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { getDateHandler } = useDateHook();
  const {
    state: { spaceSettings },
    handlers: { setLoading, setError },
  } = useContext(AppContext);
  const {
    ledgerTransactions,
    isDetailsLoading,
    generateLedgerTransactionHandler,
  } = useContext(AccountingContext);
  const { accountDateTitle } = getBalanceSheetTitleHandler({
    startDate,
    endDate,
    rangeType,
    isAllTime,
  });
  useEffect(() => {
    if (selectedAccount) {
      generateLedgerTransactionHandler({
        account: selectedAccount,
        ...(checkIsDate(startDate) ? { startDate } : {}),
        ...(checkIsDate(endDate) ? { endDate } : {}),
      });
    }
  }, [selectedAccount, startDate, endDate]);

  let totalDr = 0,
    totalCr = 0,
    preBalance = ledgerTransactions?.openingBalance || 0;
  const accountName = ledgerTransactions?.ledger?.name;
  const bracesWrapper = (number: number) => {
    return `(${separateWithComma(Math.abs(number))})`;
  };
  const navigateInvoiceHandler = (id) => {
    navigateWithReturnUrlHandler(PageLinks.invoice.details(id));
  };
  const navigateJournalHandler = (id) => {
    navigateWithReturnUrlHandler(PageLinks.journal.details(id));
  };
  const generateReportHandler = async () => {
    try {
      setLoading(true);
      await LedgerTransactionReport({
        data: ledgerTransactions,
        dateFormatter: getDateHandler,
        accountType: accountName,
        dateHeader: accountDateTitle,
        organizationName: spaceSettings?.companyName,
      });
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  const haveDetails =
    selectedAccount &&
    !isDetailsLoading &&
    ledgerTransactions?.transactions?.length > 0;
  return (
    <PageTemplate
      title={"Accounting"}
      scrollAll
      breadCrumb={[
        {
          name: "Ledger Transaction",
        },
      ]}
      backNavigation={{
        title: "Ledger Transaction",
        path: PageLinks.ledger.list,
      }}
    >
      <div className={"flex flex-col gap-5  overflow-x-scroll"}>
        <div className={"flex justify-between items-center gap-5"}>
          <TableFilterCard haveLabel haveLedgerAccountFilter haveDateRange />
          <div className={"mt-8"}>
            {haveDetails && (
              <MyButton
                iconType={IconTypeEnum.DOWNLOAD}
                isOutline
                onClick={generateReportHandler}
                name={"Export .xlsx"}
              />
            )}
          </div>
        </div>
        {!selectedAccount && (
          <MessageComponent
            message={"Select Account to view Ledger Transaction"}
          />
        )}
        {selectedAccount && isDetailsLoading && (
          <MessageComponent message={"Please wait..."} />
        )}{" "}
        {selectedAccount &&
          !isDetailsLoading &&
          ledgerTransactions?.transactions?.length < 1 && (
            <MessageComponent message={"No Transactions found"} />
          )}
        {haveDetails && (
          <div
            className={
              "flex flex-col overflow-x-scroll  gap-5 border-2 p-5 rounded-md"
            }
          >
            <div className={"flex flex-col gap-2 text-[22px] text-center"}>
              <span className={"text-[18px]"}>
                {spaceSettings?.companyName || ""}
              </span>

              <b>{accountName} Transaction</b>
              <span className={"text-[18px]"}>{accountDateTitle}</span>
            </div>
            <div className={"overflow-x-scroll"}>
              <table className={"table_container  financeTable"}>
                <thead className="table_head whitespace-nowrap">
                  <tr className={"table_row"}>
                    <th>Date</th>
                    <th>Source</th>
                    <th>Description</th>
                    <th>Reference</th>
                    <th style={{ textAlign: "right" }}>Debit</th>
                    <th style={{ textAlign: "right" }}>Credit</th>
                    <th style={{ textAlign: "right" }}>Running Balance</th>
                    <th style={{ textAlign: "right" }}>Gross</th>
                  </tr>
                  <tr className="table_row">
                    <th>Opening Balance</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th style={{ textAlign: "right" }}>
                      {isDR(ledgerTransactions?.openingBalance)
                        ? separateWithComma(ledgerTransactions?.openingBalance)
                        : "-"}
                    </th>
                    <th style={{ textAlign: "right" }}>
                      {!isDR(ledgerTransactions?.openingBalance) &&
                      ledgerTransactions?.openingBalance != 0
                        ? separateWithComma(
                            Math.abs(ledgerTransactions?.openingBalance)
                          )
                        : "-"}
                    </th>
                    <th style={{ textAlign: "right" }}>-</th>
                    <th style={{ textAlign: "right" }}>
                      {ledgerTransactions?.openingBalance < 0
                        ? bracesWrapper(ledgerTransactions?.openingBalance)
                        : separateWithComma(
                            ledgerTransactions?.openingBalance
                          ) || "-"}
                    </th>
                  </tr>
                </thead>
                {ledgerTransactions?.transactions?.map((e, key) => {
                  totalCr += e?.entry?.credit;
                  totalDr += e?.entry?.debit;
                  preBalance +=
                    e?.entry?.debit > 0 ? e?.entry?.debit : -e?.entry?.credit;
                  return (
                    <tr className={"table_row"} key={key}>
                      <td>{getDateHandler({ date: e?.transactionDate })}</td>
                      <td>{e?.invoice ? "From Invoice" : "From Journal"}</td>
                      <td>{e?.description}</td>
                      <td>
                        {e?.invoiceID && (
                          <span
                            className={"underline font-semibold text-primary"}
                            onClick={() => navigateInvoiceHandler(e?.invoice)}
                          >
                            {e?.invoiceID}
                          </span>
                        )}
                        {e?.journalID && e?.invoiceID && <span> , </span>}
                        {e?.journalID && (
                          <span
                            className={"underline font-semibold text-primary"}
                            onClick={() => navigateJournalHandler(e?.journal)}
                          >
                            {e?.journalID}
                          </span>
                        )}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {separateWithComma(e?.entry?.debit) || "-"}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {separateWithComma(e?.entry?.credit) || "-"}
                      </td>
                      {/*Running balance*/}
                      <td style={{ textAlign: "right" }}>
                        {preBalance < 0
                          ? bracesWrapper(preBalance)
                          : separateWithComma(preBalance)}
                      </td>
                      {/*Gross*/}
                      <td style={{ textAlign: "right" }}>
                        {e?.entry?.debit > 0
                          ? separateWithComma(e?.entry?.debit)
                          : `(${separateWithComma(e?.entry?.credit)})`}
                      </td>
                    </tr>
                  );
                })}
                <tr className={"table_head"}>
                  <th>Total {accountName}</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th style={{ textAlign: "right" }}>
                    {separateWithComma(totalDr)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {totalCr < 0
                      ? bracesWrapper(totalCr)
                      : separateWithComma(totalCr.toFixed(2))}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {preBalance < 0
                      ? bracesWrapper(preBalance)
                      : separateWithComma(preBalance.toFixed(2))}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {preBalance < 0
                      ? bracesWrapper(preBalance)
                      : separateWithComma(preBalance.toFixed(2))}
                  </th>
                </tr>
                <tr className={"table_head"}>
                  <th>Closing Balance</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th style={{ textAlign: "right" }}>
                    {ledgerTransactions?.closingBalance > 0
                      ? separateWithComma(ledgerTransactions?.closingBalance)
                      : "-"}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {ledgerTransactions?.closingBalance < 0
                      ? `${bracesWrapper(ledgerTransactions?.closingBalance)}`
                      : "-"}
                  </th>
                  <th></th>
                  <th></th>
                </tr>
                <tr className={"table_head bg-gray-100"}>
                  <th>Total</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th style={{ textAlign: "right" }}>
                    {separateWithComma(totalDr)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {totalCr < 0
                      ? bracesWrapper(totalCr)
                      : separateWithComma(totalCr)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {preBalance < 0
                      ? bracesWrapper(preBalance)
                      : separateWithComma(preBalance)}
                  </th>
                  <th style={{ textAlign: "right" }}>
                    {preBalance < 0
                      ? bracesWrapper(preBalance)
                      : separateWithComma(preBalance)}
                  </th>
                </tr>
              </table>
            </div>
          </div>
        )}
      </div>
    </PageTemplate>
  );
}

export default LedgerTransactionPage;
