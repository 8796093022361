import React, { useContext, useEffect, useMemo } from "react";
import { FieldArray, useFormikContext } from "formik";
import { FormConcludeButtons } from "assets";
import {
  MyRadioInput,
  MySelectField,
  MyTextAreaInput,
  MyTextField,
  MyUploadDocument,
} from "components";
import {
  Api,
  calcInvoiceBillHandler,
  decodeDynamicFieldHandler,
  decodeUserOptionsHandler,
  getCurrency,
  getIconHelper,
} from "helpers";
import {
  BankStatementInterface,
  FieldTypeEnum,
  IconTypeEnum,
  InputSizeEnum,
  InvoiceStatusEnum,
  ReconciliationInvoiceEnum,
  TaxTypeEnum,
  TransactionTypeEnum,
} from "interfaces";
import { AppContext, InvoiceContext, OptionsContext } from "context";
import { ApiUrl } from "services";
import { InvoiceFormListTable } from "../assets";
import { ReconcileMatchFormikInterface } from "../interfaces";

interface PropsInterface {
  statementDetails: BankStatementInterface;
}
function AddInvoiceComponent({ statementDetails }: PropsInterface) {
  const { values, setFieldValue, setFieldError, setFieldTouched } =
    useFormikContext<ReconcileMatchFormikInterface>();
  const {
    handlers: { setError },
  } = useContext(AppContext);
  const invoiceType =
    statementDetails?.transactionType === TransactionTypeEnum.DR
      ? InvoiceStatusEnum.SALES
      : InvoiceStatusEnum.PURCHASE;
  const CloseIcon = getIconHelper(IconTypeEnum.CLOSE);
  const isPurchase = invoiceType === InvoiceStatusEnum.PURCHASE ? true : false;
  const { generateInvoiceIdHandler } = useContext(InvoiceContext);
  const { putApi } = Api();
  const { fieldTypeOptions, userOptions } = useContext(OptionsContext);
  const invoiceData = values?.newMatch?.invoiceData;
  const isInvoiceRecTypeAdd =
    values?.newMatch?.invoiceType === ReconciliationInvoiceEnum.INVOICE;
  const calcTotalBillItemsAmount = useMemo(() => {
    let subTotalAmount = 0,
      totalAmount = 0;
    invoiceData?.billItems?.forEach((e) => {
      const { total, subTotal } = calcInvoiceBillHandler(
        e?.quantity,
        e?.rate,
        e?.discountPercent,
        e?.taxRate,
        invoiceData.taxType
      );
      subTotalAmount += subTotal;
      totalAmount += total;
    });
    return {
      totalAmount,
      subTotalAmount,
    };
  }, [invoiceData?.taxType, invoiceData?.billItems]);
  const totalMatchInvoiceAmount = useMemo(() => {
    let totalAmount = 0;
    values?.matchInvoices?.forEach((e) => {
      totalAmount += e?.amount;
    });
    return totalAmount;
  }, [values?.matchInvoices]);
  const canReconcile =
    totalMatchInvoiceAmount + calcTotalBillItemsAmount.totalAmount ===
    statementDetails?.balance;
  const decodeInvoiceLabel =
    values?.newMatch?.invoiceType === ReconciliationInvoiceEnum.DIRECT
      ? "Direct Payment"
      : values?.newMatch?.invoiceType === ReconciliationInvoiceEnum.ADVANCE
      ? "Advance Payment"
      : "Add Invoice";
  const cancelInvoiceHandler = () => {
    setFieldValue("newMatch.invoiceType", ReconciliationInvoiceEnum.NONE);
  };
  const validateInvoiceIdHandler = async (id) => {
    try {
      if (!id) {
        return;
      }
      const res = await putApi(ApiUrl.invoice.put_invoiceCheckUnique, {
        invoiceType: invoiceType,
        invoiceDate: values?.newMatch?.invoiceData?.invoiceDate,
        id: id,
      });
      if (!res?.data) {
        setFieldError("newMatch.invoiceData.invoiceID", "invalid");
        setFieldTouched("newMatch.invoiceData.invoiceID", true, false);
      }
    } catch (err) {
      setError(true, new Error(err)?.message);
    }
  };

  useEffect(() => {
    if (
      !invoiceData?.invoiceID &&
      values?.newMatch?.invoiceType === ReconciliationInvoiceEnum.INVOICE
    ) {
      generateInvoiceIdHandler(
        {
          invoiceType: invoiceType,
          invoiceDate: values?.newMatch?.invoiceData?.invoiceDate,
        },
        {
          onSuccess(payload?: any): any {
            setFieldValue("newMatch.invoiceData.invoiceID", payload);
          },
        }
      );
    }
  }, [invoiceData?.invoiceID, values?.newMatch?.invoiceType]);
  useEffect(() => {
    if (values?.newMatch?.invoiceType !== ReconciliationInvoiceEnum.INVOICE) {
      setFieldValue("newMatch.invoiceData.taxType", TaxTypeEnum.EXEMPTED);
    }
  }, [values?.newMatch?.invoiceType]);

  return (
    <div className={"flex flex-col gap-5 bg-gray-50 p-5 mt-2 rounded-md"}>
      <div className={"flex items-center justify-between border-b-2 px-2 py-2"}>
        <span className={"text-[18px] font-semibold"}>
          {decodeInvoiceLabel}
        </span>
        <CloseIcon
          className={"cursor-pointer text-[25px]"}
          onClick={() => cancelInvoiceHandler()}
        />
      </div>
      <div className={`grid  grid-cols-5 gap-5 items-start`}>
        <MySelectField
          option={{
            defaultLabel: `Select ${!isPurchase ? "Client" : "Supplier"}`,
            selectOptions: decodeUserOptionsHandler(userOptions),
          }}
          label={`${!isPurchase ? "Client" : "Supplier"} Name`}
          name={"newMatch.invoiceData.client"}
          isRequired
        />
        <MyTextField
          name={"invoiceDate"}
          type={"date"}
          label={"Invoice Date"}
          isRequired
        />
        {isInvoiceRecTypeAdd && (
          <MyTextField
            name={"newMatch.invoiceData.dueDate"}
            type={"date"}
            label={"Due Date"}
          />
        )}
        {isInvoiceRecTypeAdd && (
          <MyTextField
            name={"newMatch.invoiceData.invoiceID"}
            isRequired
            onBlur={(e) => validateInvoiceIdHandler(e?.target?.value)}
            placeholder={isPurchase ? "eg: Bill-123" : "eg: Inv-123"}
            label={`${isPurchase ? "Bill Number" : "Invoice Number"} `}
          />
        )}
        <MyTextField
          name={"newMatch.invoiceData.reference"}
          placeholder={"eg: REF-123"}
          label={"Reference"}
        />

        {isPurchase && (
          <MySelectField
            label={"Bill Type"}
            isCreatable={{
              type: FieldTypeEnum.BILL_TYPE,
            }}
            option={{
              selectOptions: decodeDynamicFieldHandler(
                fieldTypeOptions[FieldTypeEnum.BILL_TYPE]
              ),
            }}
            name={`billType`}
          />
        )}
      </div>
      <div className={"flex justify-between"}>
        <div></div>
        <div className={"flex  gap-5"}>
          <>
            <MyRadioInput
              radioButtonType={"radio"}
              disabled={!isInvoiceRecTypeAdd}
              name={"newMatch.invoiceData.taxType"}
              label={"Tax Exclusive"}
              defaultValue={TaxTypeEnum.EXCLUSIVE}
            />
            <MyRadioInput
              radioButtonType={"radio"}
              disabled={!isInvoiceRecTypeAdd}
              name={"newMatch.invoiceData.taxType"}
              label={"Tax Inclusive"}
              defaultValue={TaxTypeEnum.INCLUSIVE}
            />
            <MyRadioInput
              radioButtonType={"radio"}
              disabled={!isInvoiceRecTypeAdd}
              name={"newMatch.invoiceData.taxType"}
              label={"Tax Exempted"}
              defaultValue={TaxTypeEnum.EXEMPTED}
            />
          </>
        </div>
      </div>

      <FieldArray
        name={"newMatch.invoiceData.billItems"}
        render={({ remove, insert }) => {
          return (
            <>
              <table className={"table_container table-auto "}>
                <thead className={"table_head  h-[50px] "}>
                  <th>Property</th>
                  <th>Unit</th>
                  <th>Account</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Discount (in %)</th>
                  {invoiceData?.taxType !== TaxTypeEnum.EXEMPTED && (
                    <th>Tax Rate</th>
                  )}
                  {invoiceData?.taxType === TaxTypeEnum.INCLUSIVE && (
                    <th>Tax Amount</th>
                  )}
                  <th>Amount</th>
                  <th></th>
                </thead>
                <tbody>
                  {invoiceData?.billItems?.map((e, key) => {
                    return (
                      <InvoiceFormListTable
                        key={key}
                        index={key}
                        removeHandler={remove}
                        insertHandler={insert}
                        setFieldValue={setFieldValue}
                        value={e}
                        taxType={invoiceData?.taxType}
                      />
                    );
                  })}
                </tbody>
              </table>
            </>
          );
        }}
      />

      <div className={"grid grid-cols-3"}>
        <div className={"col-span-2 grid grid-cols-2"}>
          <div className={"flex flex-col gap-5"}>
            <MyUploadDocument
              fileContainerStyle={"grid grid-cols-1 gap-5"}
              fileType={"document"}
              label={"Documents"}
              name={"documents"}
            />
            <MyTextAreaInput label={"Note"} name={"description"} />
          </div>
        </div>
        {/*bills*/}
        <div className={"flex flex-col  gap-5 text-[12px]"}>
          <div className={"grid grid-cols-3"}>
            <span className={"col-span-2"}>Sub-total</span>
            <span>
              {getCurrency()}{""}
              {parseFloat(
                String(calcTotalBillItemsAmount.subTotalAmount || 0)
              )?.toFixed(2)}
            </span>
          </div>
          {invoiceData?.taxType === TaxTypeEnum.EXCLUSIVE && (
            <>
              {invoiceData?.billItems?.map((e, key) => {
                if (!e?.taxRate) {
                  return;
                }
                const { taxAmount } = calcInvoiceBillHandler(
                  e?.quantity,
                  e?.rate,
                  e?.discountPercent,
                  e?.taxRate,
                  invoiceData?.taxType
                );
                return (
                  <div className={"grid grid-cols-3"} key={key}>
                    <span className={"col-span-2"}>
                      Total TAX {e?.taxRate}%
                    </span>
                    <span>
                      {getCurrency()}{""}
                      {parseFloat(String(taxAmount || 0))?.toFixed(2)}
                    </span>
                  </div>
                );
              })}
            </>
          )}

          <div
            className={
              "grid text-[14px] grid-cols-3 pt-5 border-t-2 border-t-gray-700"
            }
          >
            <span className={"col-span-2"}>Total Amount</span>
            <span className={"font-semibold text-tBlue"}>
              {getCurrency()}
              {parseFloat(
                String(calcTotalBillItemsAmount.totalAmount || 0)
              ).toFixed(2)}
            </span>
          </div>
        </div>
      </div>
      <div>
        <FormConcludeButtons
          size={"md"}
          submitButton={
            canReconcile && {
              title: "Reconcile",
            }
          }
          cancelButton={{
            title: "Cancel",
            handler() {
              cancelInvoiceHandler();
            },
          }}
        />
      </div>
    </div>
  );
}

export default AddInvoiceComponent;
