import { MyToggleButtonInterface } from "./interfaces";

import getIconHelpers from "../../helpers/utils/getIconHelpers";
import { IconTypeEnum } from "../../interfaces";

export default function MyToggleButton({
  changeHandler,
  value,
  options,
  type = "text",
  buttonType,
  label,
}: MyToggleButtonInterface) {
  const ToggleOnIcon = getIconHelpers(IconTypeEnum.TOGGLE_ON);
  const ToggleOffIcon = getIconHelpers(IconTypeEnum.TOGGLE_OFF);
  const CheckIcon = getIconHelpers(IconTypeEnum.CHECK_FILL);
  const Un_CheckIcon = getIconHelpers(IconTypeEnum.UN_CHECK);
  let isTypeText = type === "text";
  let currentText;
  if (isTypeText) {
    currentText = value ? options?.active : options?.inActive;
  }

  const onClickHandler = () => {
    changeHandler(!value);
  };
  const activeButton =
    buttonType == "toggle" ? (
      <ToggleOnIcon className={"text-tGreen text-[60px]"} />
    ) : (
      <CheckIcon className={" text-tGreen  text-[24px]"} />
    );
  const inActiveButton =
    buttonType == "toggle" ? (
      <ToggleOffIcon className={" text-[#c0c0c0]  text-[60px]"} />
    ) : (
      <Un_CheckIcon className={"text-gray-500  text-[24px]"} />
    );

  return (
    <div className={"flex items-center justify-center gap-2 cursor-pointer"}>
      {label && <span className={"whitespace-nowrap"}>{label}</span>}
      {isTypeText ? (
        <div
          className={`rounded-full py-1 px-4 py-2 text-[12px]  cursor-pointer text-white ${currentText?.className} `}
          onClick={onClickHandler}
        >
          {currentText?.name}
        </div>
      ) : (
        <div onClick={onClickHandler}>
          {value ? activeButton : inActiveButton}
        </div>
      )}
    </div>
  );
}
