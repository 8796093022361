import * as yup from "yup";
import {
  FinanceCostInterface,
  L_MInterestInterface,
  LoanAndMortgageInterface,
} from "interfaces";

export const LoanFormikForm = {
  financeCostValue: (values?: Partial<FinanceCostInterface>) => {
    return {
      financeName: values?.financeName,
      cost: values?.cost,
      isIncludedInMortgageLoan: values?.isIncludedInMortgageLoan || false,
    };
  },
  interestValue: (values?: L_MInterestInterface) => ({
    rate: values?.rate,
    interestType: values?.interestType?._id,
    interestApplyDate: values?.interestApplyDate,
    interestEndDate: values?.interestEndDate,
  }),
  initialValues: (values?: Partial<LoanAndMortgageInterface>) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      property: values?.property?._id || "",
      unit: values?.unit?._id || "",
      // finance cost
      financeCost: values?.financeCost?.map((e) =>
        LoanFormikForm?.financeCostValue(e)
      ) || [LoanFormikForm?.financeCostValue()],
      //loan details
      financeType: values?.financeType?._id || "",
      mortgageType: values?.mortgageType?._id || "",
      loanAmount: values?.loanAmount || "",
      startDate: values?.startDate || "",
      description: values?.description || "",
      totalLoanTermQuantity: values?.totalLoanTermQuantity || "",
      totalLoanTermFrequency: values?.totalLoanTermFrequency?._id || "",
      //  interest
      interest: values?.interest?.map((e) => {
        return LoanFormikForm?.interestValue(e);
      }) || [LoanFormikForm.interestValue()],
      // lender
      lender: values?.lender?._id || "",
      //notify
      notifyLandlord: values?.notifyLandlord || false,
      reminderOfPayment: values?.reminderOfPayment || false,
      notifyLender: values?.notifyLender || false,

      // documents
      documents: values?.documents || [],
    };
  },
  validationSchema: yup.object().shape({
    property: yup.string().required("required"),
    unit: yup.string(),
    // finance cost
    financeCost: yup.array().of(
      yup.object().shape({
        financeName: yup.string().required("required"),
        cost: yup.string().required("required"),
        isIncludedInMortgageLoan: yup.boolean(),
      })
    ),
    //loan details
    financeType: yup.string().required("required"),
    mortgageType: yup.string().required("required"),
    loanAmount: yup.string().required("required"),
    startDate: yup.string().required("required"),
    totalLoanTermQuantity: yup.number().min(1, "invalid").required("required"),
    totalLoanTermFrequency: yup.string().required("required"),
    //  interest
    interest: yup.array().of(
      yup.object().shape({
        rate: yup.number().min(0, "invalid").max(100, "invalid"),
        interestType: yup.string(),
        interestApplyDate: yup.string(),
        interestEndDate: yup.string(),
      })
    ),
    // lender
    lender: yup.string().required("required"),
    //notify
    notifyLandlord: yup.boolean().required("required"),
    reminderOfPayment: yup.boolean().required("required"),
    notifyLender: yup.boolean().required("required"),

    // documents
    documents: yup.array(),
  }),
};
