const config = {
  development: {
    // default:
    // "https://55da-2400-1a00-b030-17d9-21cd-2e5b-cd06-5203.ngrok-free.app/api/system/",
    default: "http://206.189.29.252:5000/api/system/",
  },

  production: {
    default: process.env.REACT_APP_API_HOST_URL,
  },
};

export default process.env.NODE_ENV === "production"
  ? config["production"]
  : config["development"];
