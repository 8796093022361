import React from "react";
import { Route } from "react-router-dom";

import { BillingAndSubscriptionPage, CheckoutPage } from "./pages";
import { ActiveSubscriptionTab, PurchaseHistoryTab } from "./tabs";
import { QueryStringEnum, SpaceAccessListEnum } from "interfaces";
import { StripeWrapper } from "./components";
import { SubscriptionContextProvider } from "./context";
import { AccessVerifier } from "../../templates";

function Index() {
  return (
    <Route
      path="subscription/"
      element={
        <StripeWrapper>
          <SubscriptionContextProvider />
        </StripeWrapper>
      }
    >
      <Route
        path={""}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.BILLING_VIEW_SUBSCRIPTION]}
            isForPage={true}
          >
            <BillingAndSubscriptionPage />
          </AccessVerifier>
        }
      >
        <Route path={""} element={<ActiveSubscriptionTab />} />
        <Route path={"purchase"} element={<PurchaseHistoryTab />} />
      </Route>
      <Route
        path={`checkout/:${QueryStringEnum.TYPE}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.BILLING_PURCHASE_SUBSCRIPTION]}
            isForPage={true}
          >
            <CheckoutPage />
          </AccessVerifier>
        }
      ></Route>
    </Route>
  );
}

export default Index;
