import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import {
  ParamsStringEnum,
  PropertyOwnerInterface,
  SpaceAccessListEnum,
  TableColumnInterface,
} from "interfaces";
import {
  useAuthorizationHook,
  useDateHook,
  usePageQueryHooks,
  usePaginationQueryHooks,
} from "useHooks";
import { PageLinks } from "routes";
import { UserTableProfile } from "assets";
import { PropertyDataContext } from "context";
import { MyMoreOptionButton, MyToggleButton } from "components";

function OwnershipTableColumns(): TableColumnInterface<PropertyOwnerInterface>[] {
  const params = useParams<ParamsStringEnum>();
  const pId = params.propertyId;
  const { calcSNHandler } = usePaginationQueryHooks();
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { getDateHandler, getStaffFullName } = useDateHook();
  const { revokeOwnershipHandler, getOwnershipHandler, editOwnershipHandler } =
    useContext(PropertyDataContext);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_EDIT_PROPERTY,
  ]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_DEL_PROPERTY,
  ]);
  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },
    {
      title: "Owner",
      render(renderData) {
        return (
          <UserTableProfile
            firstLetter={renderData?.owner?.name?.first?.charAt(0)}
            details={{
              profile: renderData?.owner?.photo,
              name: renderData?.owner?.userID,
              subTitle: getStaffFullName(renderData?.owner?.name),
            }}
          />
        );
      },
    },
    {
      title: "Ownership Type",
      render(renderData) {
        return (
          <div className={"flex items-start "}>
            <span>{renderData?.ownershipType?.label}</span>
          </div>
        );
      },
    },
    {
      title: "Email",
      render(renderData) {
        return <div>{renderData?.owner?.email}</div>;
      },
    },
    {
      title: "Contact Number",
      render(renderData) {
        return <div>{renderData?.owner?.phone}</div>;
      },
    },
    {
      title: "Ownership",
      render(renderData) {
        return <div>{renderData?.ownership}%</div>;
      },
    },
    {
      title: "Agreement Expiry",
      render(renderData) {
        return (
          <div>
            {getDateHandler({
              date: renderData?.agreementExpiryAt,
            })}
          </div>
        );
      },
    },
    {
      title: "Status",
      render(renderData) {
        return (
          <div className={"flex items-start justify-start"}>
            <MyToggleButton
              type={"text"}
              changeHandler={(newValue) => {
                canEdit &&
                  editOwnershipHandler(
                    {
                      isActive: newValue,
                      _id: renderData?._id,
                    },
                    {},
                    {
                      async onSuccess(payload?: any) {
                        await getOwnershipHandler(pId);
                      },
                    }
                  );
              }}
              value={renderData?.isActive}
              options={{
                active: {
                  name: "Active",
                  className: "bg-tGreen ",
                },
                inActive: {
                  name: "In-active",
                  className: "bg-tRed",
                },
              }}
            />
          </div>
        );
      },
    },

    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Ownership",
                  handler: () => {
                    navigateWithReturnUrlHandler(
                      PageLinks.property.viewOwnership(
                        pId,
                        renderData?.owner?._id
                      )
                    );
                  },
                },
                canEdit && {
                  name: "Edit Ownership",
                  handler: () => {
                    navigateWithReturnUrlHandler(
                      PageLinks.property.editOwnership(
                        pId,
                        renderData?.owner?._id
                      )
                    );
                  },
                },
                canDelete && {
                  name: "Revoke Ownership",
                  handler() {
                    revokeOwnershipHandler(renderData?.owner?._id, pId, {
                      onSuccess(payload?: any): any {
                        getOwnershipHandler(pId);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default OwnershipTableColumns;
