import React, { useContext } from "react";

import {
  TableColumnInterface,
  CustomerInterface,
  SpaceAccessListEnum,
  UserRoleEnum,
} from "interfaces";
import { MyMoreOptionButton } from "components";
import { AddressText, UserTableProfile } from "assets";

import { AppContext, UserContext } from "context";
import { PageLinks } from "routes";
import {
  useAuthorizationHook,
  usePageQueryHooks,
  usePaginationQueryHooks,
} from "useHooks";
import useDateHooks from "../../useHooks/useDateHooks";

function UserTableColumn(
  query?: any
): TableColumnInterface<CustomerInterface>[] {
  const {
    state: { profileDetails },
  } = useContext(AppContext);
  const { deleteHandler, archiveHandler, getAllDataHandlers } =
    useContext(UserContext);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { calcSNHandler } = usePaginationQueryHooks();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const { getStaffFullName } = useDateHooks();
  const canEdit = checkCanAccessHandler([SpaceAccessListEnum.AUTH_EDIT_USER]);
  const canDelete = checkCanAccessHandler([SpaceAccessListEnum.AUTH_DEL_USER]);
  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },
    {
      title: "Name",
      render(renderData) {
        return (
          <UserTableProfile
            firstLetter={renderData?.name?.first?.charAt(0)}
            details={{
              profile: renderData?.photo,
              name: renderData?.userID || "-",
              subTitle: getStaffFullName(renderData?.name),
            }}
          />
        );
      },
    },
    {
      title: "Role",
      render(renderData) {
        return (
          <div>
            <span>{renderData?.granted?.name}</span>
          </div>
        );
      },
    },
    {
      title: "Property",
      render(renderData) {
        return (
          <div className={"flex items-start text-tBlue"}>
            {renderData?.properties?.length < 1 && (
              <span className={"text-black"}>N/A</span>
            )}
            {renderData?.properties?.map((e) => e?.propertyID)?.join(", ")}
          </div>
        );
      },
    },
    {
      title: "Unit",
      render(renderData) {
        return (
          <div className={"flex items-start text-tBlue"}>
            {renderData?.units?.length < 1 && (
              <span className={"text-black"}>N/A</span>
            )}
            {renderData?.units?.map((e) => e?.unitID)?.join(", ")}
          </div>
        );
      },
    },

    {
      title: "Contact Number",
      render(renderData) {
        return (
          <div className={"flex items-start "}>
            <span>{renderData?.phone || "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Email",
      render(renderData) {
        return (
          <div className={"flex items-start "}>
            <span>{renderData?.email || "-"}</span>
          </div>
        );
      },
    },
    {
      title: "Address",
      render(renderData) {
        return <AddressText address={renderData?.address} />;
      },
    },

    {
      title: "Action",
      render(renderData) {
        const blockEdit =
          profileDetails?._id !== renderData?._id &&
          renderData?.role !== UserRoleEnum.LANDLORD;
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigateWithReturnUrlHandler(
                      PageLinks.spaceUser.details(renderData?._id)
                    );
                  },
                },
                canEdit &&
                  blockEdit &&
                  !renderData?.isArchived && {
                    name: "Edit Details",
                    handler: () => {
                      navigateWithReturnUrlHandler(
                        PageLinks.spaceUser.edit(renderData?._id)
                      );
                    },
                  },
                canDelete &&
                  blockEdit && {
                    name: renderData?.isArchived ? `Unarchive` : "Archive",
                    handler: () => {
                      archiveHandler(renderData?._id, renderData?.isArchived, {
                        async onSuccess(payload?: any) {
                          await getAllDataHandlers(query);
                        },
                      });
                    },
                  },
                canDelete &&
                  blockEdit && {
                    name: "Delete Users",
                    handler: () => {
                      deleteHandler(renderData?._id, {
                        async onSuccess(payload?: any) {
                          await getAllDataHandlers(query);
                        },
                      });
                    },
                  },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default UserTableColumn;
