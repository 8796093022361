import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";

import { FormConcludeButtons } from "assets";
import { ParamsStringEnum } from "interfaces";
import { PropertyDataContext } from "context";
import { usePageQueryHooks } from "useHooks";

import { BoxModal, MyTextField, MyUploadDocument } from "components";

import { OwnershipForms } from "../components";
import { addOwnerFormikValues } from "../helpers";

function AddOwnerModal() {
  const navigate = useNavigate();
  const params = useParams<ParamsStringEnum>();
  const pId = params.propertyId;
  const { addOwnerAndAssignOwnershipHandler, getOwnershipHandler } =
    useContext(PropertyDataContext);
  const { returnURL } = usePageQueryHooks();
  const submitHandler = (values, actions) => {
    addOwnerAndAssignOwnershipHandler(values, actions, {
      onSuccess: async () => {
        await getOwnershipHandler(pId);
      },
    });
  };
  return (
    <>
      <BoxModal
        isCloseAuto
        title={"Add Owner"}
        closeHandler={() => {
          returnURL ? navigate(returnURL) : navigate(-1);
        }}
      >
        <Formik
          enableReinitialize
          onSubmit={submitHandler}
          initialValues={addOwnerFormikValues.initialValues(pId)}
          validationSchema={addOwnerFormikValues.validationSchema}
        >
          {({ isSubmitting }) => {
            return (
              <Form className={"flex flex-col  gap-5"}>
                <div className={"grid grid-cols-2 gap-5"}>
                  <MyTextField
                    name={"ownerName.first"}
                    label={"First name"}
                    placeholder={"eg. Petros"}
                    isRequired
                  />
                  <MyTextField
                    name={"ownerName.last"}
                    label={"Last name"}
                    placeholder={"eg. Khan"}
                    isRequired
                  />
                </div>{" "}
                <div className={"grid grid-cols-2 gap-5"}>
                  <MyTextField
                    name={"ownerEmail"}
                    label={"Email"}
                    placeholder={"eg. pertros@gm.com"}
                    isRequired
                  />
                  <MyTextField
                    name={"ownerPhone"}
                    label={"Contact Number"}
                    placeholder={"eg.9800000000"}
                    isRequired
                  />
                </div>
                <div className={"grid grid-cols-2 gap-5"}>
                  <MyUploadDocument
                    fileType={"image"}
                    name={"ownerPhoto"}
                    label={"Owner Photo"}
                  />
                </div>
                <OwnershipForms />
                <FormConcludeButtons
                  cancelButton={{
                    title: "Cancel",
                  }}
                  submitButton={{
                    title: "Submit",
                    isLoading: isSubmitting,
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </BoxModal>
    </>
  );
}

export default AddOwnerModal;
