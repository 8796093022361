import React, { useContext } from "react";

import {
  UnitInterface,
  TableColumnInterface,
  SpaceAccessListEnum,
} from "interfaces";
import { MyMoreOptionButton, MyToggleButton } from "components";
import { getCurrency, separateWithComma } from "helpers";
import { useAuthorizationHook, usePaginationQueryHooks } from "useHooks";

import { UnitDataContext } from "context";
import { PageLinks } from "routes";
import { usePageQueryHooks } from "useHooks";
import { PropertyUnitTableText } from "assets";

function UnitTableColumns(
  isFromUnit: boolean,
  query?: any
): TableColumnInterface<UnitInterface>[] {
  const { deleteHandler, archiveHandler, getAllDataHandlers } =
    useContext(UnitDataContext);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { calcSNHandler } = usePaginationQueryHooks();

  const navigateUnitDetails = (propertyId, unitId) => {
    navigateWithReturnUrlHandler(PageLinks.unit.details(propertyId, unitId));
  };
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([SpaceAccessListEnum.RENTAL_EDIT_UNIT]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_DEL_UNIT,
  ]);
  return [
    {
      title: "SN",
      render(renderData, key) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },

    isFromUnit && {
      title: "Property",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.property?.propertyID}
            address={renderData?.property?.address}
            propertyID={renderData?.property?._id}
          />
        );
      },
    },
    {
      title: "Code",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.unitID}
            type={"unit"}
            propertyID={renderData?.property?._id}
            unitID={renderData?._id}
          />
        );
      },
    },
    {
      title: "Unit",
      render(renderData) {
        return (
          <div>
            <span className={"font-semibold"}>{renderData?.name}</span>
            <div>
              {renderData?.room
                ?.map((e) => `${e?.roomType?.label} (${e?.quantity})`)
                .join(",")}
            </div>
          </div>
        );
      },
    },
    {
      title: "Area",
      render(renderData) {
        return (
          <div className={"flex items-start "}>
            {renderData?.area?.size} sq. {renderData?.area?.unit?.label}
          </div>
        );
      },
    },
    {
      title: "Furnishing",
      render(renderData) {
        return <div>{renderData?.furnishedType?.label}</div>;
      },
    },
    {
      title: "Current Status",
      render(renderData) {
        return (
          <div className={"flex items-start justify-start"}>
            <MyToggleButton
              type={"text"}
              changeHandler={() => {}}
              value={!renderData?.isOccupied}
              options={{
                active: {
                  name: "Vacant",
                  className: "bg-tRed",
                },
                inActive: {
                  name: "Occupied",
                  className: "bg-tGreen",
                },
              }}
            />
          </div>
        );
      },
    },

    {
      title: "Market Rent",
      render(renderData) {
        return (
          <div>
            {getCurrency()}
            {separateWithComma(renderData?.marketRent)}
          </div>
        );
      },
    },
    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigateUnitDetails(
                      renderData?.property?._id,
                      renderData?._id
                    );
                  },
                },
                canEdit &&
                  !renderData?.isArchived && {
                    name: "Edit Details",
                    handler: () => {
                      navigateWithReturnUrlHandler(
                        PageLinks.unit.edit(renderData?._id)
                      );
                    },
                  },
                canDelete && {
                  name: renderData?.isArchived ? `Unarchive` : "Archive",
                  handler: () => {
                    archiveHandler(renderData?._id, renderData?.isArchived, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
                canDelete && {
                  name: "Delete Unit",
                  handler: () => {
                    deleteHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default UnitTableColumns;
