import React, { useContext, useEffect } from "react";

import { EmptyMessageComponent, PaginationComponent } from "components";
import { PageTemplate } from "templates";
import { NotificationContext } from "context";
import { usePaginationQueryHooks } from "useHooks";
import { NotificationCard } from "../components";

function NotificationPage() {
  const {
    getAllDataHandlers,
    allData,
    isLoading,
    totalDocs,
    totalArchiveDocs,
  } = useContext(NotificationContext);
  const { currentPage, isArchive, paginationQuery } = usePaginationQueryHooks();

  const pageQuery = {
    ...paginationQuery,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, currentPage]);

  return (
    <PageTemplate
      title={"Notification"}
      breadCrumb={[
        {
          name: "Notification",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <div>
        {!isLoading && allData?.length < 1 && (
          <div>
            <EmptyMessageComponent message={"No notifications"} />
          </div>
        )}
        <div className={"flex flex-col gap-1"}>
          {isLoading
            ? new Array(5).fill("").map((e, key) => {
                return <NotificationCard isLoading={true} key={key} />;
              })
            : allData?.map((e, key) => {
                return <NotificationCard details={e} key={key} />;
              })}
        </div>
        <PaginationComponent
          totalDocs={isArchive ? totalArchiveDocs : totalDocs}
        />
      </div>
    </PageTemplate>
  );
}

export default NotificationPage;
