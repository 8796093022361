import React, { useContext } from "react";
import {
  BankInterface,
  BankSourceEnum,
  IconTypeEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
  TableColumnInterface,
} from "interfaces";
import { useNavigate } from "react-router-dom";

import { MyMoreOptionButton } from "components";

import { BankContext, TrueLayerContext } from "context";
import { PageLinks } from "routes";
import {
  useAuthorizationHook,
  usePageQueryHooks,
  usePaginationQueryHooks,
} from "useHooks";
import { UserTableProfile } from "assets";
import { capitalizeAfterSpace, navigateLinkHandler } from "helpers";

function BankTableColumns(pageQuery): TableColumnInterface<BankInterface>[] {
  const navigate = useNavigate();
  const { getAllDataHandlers, archiveHandler, deleteHandler } =
    useContext(BankContext);
  const { syncTrueLayerHandler, getAuthLinkHandler } =
    useContext(TrueLayerContext);
  const { calcSNHandler } = usePaginationQueryHooks();
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_EDIT_BANK,
  ]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_DEL_BANK,
  ]);
  const canLinkBank = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_LINK_BANK,
  ]);

  return [
    {
      title: "SN",
      render(renderData, key) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },
    {
      title: "Name",
      render(renderData) {
        return (
          <UserTableProfile
            badgeIconType={
              renderData?.source === BankSourceEnum.TrueLayer &&
              IconTypeEnum.LAYER
            }
            onClick={() =>
              renderData?.source !== BankSourceEnum.SEED &&
              navigate(PageLinks.banks.details(renderData?._id))
            }
            details={{
              isStaticPath: renderData?.logo_uri ? true : false,
              name: renderData?.label,
              subTitle: `${renderData?.name}`,
              imageAltText: "Bank image",
              profile: renderData?.logo_uri || renderData?.logo,
            }}
          />
        );
      },
    },
    {
      title: "Account Name",
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>{renderData?.name}</span>
            <span className={"text-gray-500 text-[12px]"}>
              Account: {renderData?.accountNumber || "-"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Branch",
      render(renderData) {
        return <span>{renderData?.branch}</span>;
      },
    },
    {
      title: "Account",
      render(renderData) {
        return (
          <span
            className={"text-primary underline"}
            onClick={() =>
              navigateWithReturnUrlHandler(
                PageLinks.ledger.listLedgerTransaction,
                `${QueryStringEnum.ACCOUNT_TYPE}=${renderData?.ledgerAccount?._id}`
              )
            }
          >
            {renderData?.ledgerAccount?.name}
          </span>
        );
      },
    },
    {
      title: "Source",
      render(renderData) {
        return <span>{capitalizeAfterSpace(renderData?.source)}</span>;
      },
    },

    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                renderData?.source !== BankSourceEnum.SEED && {
                  name: "View ",
                  handler: () => {
                    navigate(PageLinks.banks.details(renderData?._id));
                  },
                },
                canEdit &&
                  renderData?.source !== BankSourceEnum.SEED &&
                  !renderData?.isArchived && {
                    name: "Edit",
                    handler: () => {
                      navigate(PageLinks.banks.edit(renderData?._id));
                    },
                  },
                canEdit &&
                  renderData?.source !== BankSourceEnum.SEED && {
                    name: renderData?.isArchived ? `Unarchive` : "Archive",
                    handler: () => {
                      archiveHandler(renderData?._id, renderData?.isArchived, {
                        async onSuccess(payload?: any) {
                          await getAllDataHandlers(pageQuery);
                        },
                      });
                    },
                  },
                canLinkBank &&
                  renderData?.isBankLinked && {
                    name: "Sync",
                    handler: async () => {
                      syncTrueLayerHandler(
                        { bankID: renderData?._id },
                        {
                          onSuccess(payload?: any): any {
                            getAllDataHandlers(pageQuery);
                          },
                        }
                      );
                    },
                  },
                canLinkBank &&
                  renderData?.isBankLinked && {
                    name: "Reconnect",
                    handler: async () => {
                      getAuthLinkHandler(
                        { bankID: renderData?._id },
                        {
                          onSuccess(payload?: any): any {
                            navigateLinkHandler(payload);
                          },
                        }
                      );
                    },
                  },
                canDelete &&
                  renderData?.source !== BankSourceEnum.SEED && {
                    name: "Delete ",
                    handler: () => {
                      deleteHandler(renderData?._id, {
                        async onSuccess(payload?: any) {
                          await getAllDataHandlers(pageQuery);
                        },
                      });
                    },
                  },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default BankTableColumns;
