import * as yup from "yup";
import { CustomerInterface } from "interfaces";

export const editProfileFormik = {
  initialValues: (values?: CustomerInterface) => {
    return {
      firstName: values?.name?.first,
      lastName: values?.name?.last,
      email: values?.email || "",
      phone: values?.phone || "",
      photo: values?.photo || "",
      companyName: values?.companyName || "",
      vatRegistration: values?.vatRegistration || "",
      companyRegistration: values?.companyRegistration || "",
    };
  },
  validationSchema: yup.object().shape({}),
};

export const editChangePasswordFormik = {
  initialValues: () => {
    return {
      password: "",
      oldPassword: "",
    };
  },
  validationSchema: yup.object().shape({
    password: yup
      .string()
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      )
      .required("required"),
  }),
};
export const changePasswordFormik = {
  initialValues: () => {
    return {
      password: "",
    };
  },
  validationSchema: yup.object().shape({
    password: yup
      .string()
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      )
      .required("required"),
  }),
};
