import React, { useContext } from "react";
import { BoxModal, MyTextField } from "components";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "routes";
import { Form, Formik } from "formik";
import { AppContext, TrueLayerContext } from "context";
import * as yup from "yup";
import { FormConcludeButtons } from "assets";

function EditTrueLayerConfigModal() {
  const navigate = useNavigate();
  const { editTrueLayerSettings } = useContext(TrueLayerContext);
  const {
    handlers: { getTrueLayerSettingsHandler },
    state: { trueLayerSettings },
  } = useContext(AppContext);
  const onSubmitHandler = (values, actions) => {
    editTrueLayerSettings(values, {
      onSuccess(payload?: any): any {
        getTrueLayerSettingsHandler();
      },
    });
  };
  return (
    <BoxModal
      isCloseAuto
      title={"Configure True layer"}
      closeHandler={() => navigate(PageLinks.settings.trueLayerSettings)}
    >
      <Formik
        validationSchema={yup.object().shape({
          clientID: yup.string(),
          clientSecretKey: yup.string(),
        })}
        initialValues={{
          clientID: trueLayerSettings?.clientID,
          clientSecretKey: "",
        }}
        onSubmit={onSubmitHandler}
      >
        <Form className={"flex flex-col  gap-10"}>
          <div className={"grid grid-cols-1 gap-5"}>
            <MyTextField
              name={"clientID"}
              label={"Client ID"}
              placeholder={"********-****-****-****-************"}
            />
            <MyTextField
              name={"clientSecretKey"}
              label={"Client Secret Key"}
              placeholder={"********-****-****-****-************"}
            />
          </div>
          <FormConcludeButtons
            submitButton={{
              title: "Submit",
            }}
          />
        </Form>
      </Formik>
    </BoxModal>
  );
}

export default EditTrueLayerConfigModal;
