import React, { useContext } from "react";
import { OptionsContext } from "context";
import {
  MyRadioInput,
  MySelectField,
  MyTextAreaInput,
  MyTextField,
  TabComponent,
} from "components";
import {
  decodeDynamicFieldHandler,
  decodePropertyOptionsHandler,
  getCurrency,
} from "helpers";
import { FormWrapper } from "assets";
import { AddressForm } from "content";
import { CandidateInterface, FieldTypeEnum, QueryStringEnum } from "interfaces";
import { useFormikContext } from "formik";
import { useSearchParams } from "react-router-dom";

function CandidatesForm() {
  const { values } = useFormikContext<CandidateInterface<string>>();
  const { propertyOptions, fieldTypeOptions } = useContext(OptionsContext);
  const [query] = useSearchParams();

  const isAdditionalInfoTab =
    query.get(QueryStringEnum?.ACTIVE_TAB) === "additional";
  const AdditionalInfoForm = (
    <>
      <FormWrapper
        header={{
          title: {
            name: "Additional Info",
          },
        }}
      >
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1"}>
          <div className={"col-span-2"}>
            <MyTextAreaInput name={"comments"} label={"Comments"} />
          </div>
        </div>
      </FormWrapper>
    </>
  );
  return (
    <div className={"flex flex-col gap-5"}>
      <TabComponent
        tabs={[
          {
            title: "General Info",
            pathName: "",
          },
          {
            title: "Additional Info",
            pathName: "additional",
          },
        ]}
      />
      {isAdditionalInfoTab ? (
        AdditionalInfoForm
      ) : (
        <div className={"flex flex-col gap-10"}>
          <FormWrapper
            header={{
              title: {
                name: "Personal Information",
              },
            }}
          >
            <div
              className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
            >
              <MyTextField
                label={"First Name"}
                isRequired
                placeholder={"Eg. Uttam"}
                name={"name.first"}
              />
              <MyTextField
                label={"Middle Name"}
                placeholder={"Eg. Kumar"}
                name={"name.middle"}
              />
              <MyTextField
                label={"Last Name"}
                isRequired
                placeholder={"Eg. Tamang"}
                name={"name.last"}
              />
            </div>
          </FormWrapper>
          <FormWrapper
            header={{
              title: {
                name: "Contact Information",
              },
            }}
          >
            <div
              className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
            >
              <MyTextField
                label={"Email Address"}
                isRequired
                placeholder={"Eg. Uttam_202@gmail.com"}
                name={"email"}
              />
              <MyTextField
                label={"Phone Number"}
                placeholder={"Eg. +44 "}
                name={"phone"}
              />
              <MyTextField
                label={"Mobile Number"}
                placeholder={"Eg. +44 "}
                name={"mobile"}
                isRequired
              />
            </div>
          </FormWrapper>

          <AddressForm
            county={{
              value: values?.address?.county,
            }}
            country={{
              value: values?.address?.country,
            }}
          />
          <FormWrapper
            header={{
              title: {
                name: "Candidate Status",
              },
            }}
          >
            <div
              className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
            >
              <MySelectField
                label={"Status"}
                isRequired
                isCreatable={{
                  type: FieldTypeEnum.CANDIDATE_STATUS,
                }}
                name={"candidateStatus"}
                option={{
                  selectOptions: decodeDynamicFieldHandler(
                    fieldTypeOptions[FieldTypeEnum.CANDIDATE_STATUS]
                  ),
                  defaultLabel: "Select Status",
                }}
              />
            </div>
          </FormWrapper>
          <FormWrapper
            header={{
              title: {
                name: "Job Status",
              },
            }}
          >
            <div
              className={
                "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
              }
            >
              <MySelectField
                label={"Profession"}
                isRequired
                name={"profession"}
                isCreatable={{
                  type: FieldTypeEnum.PROFESSION,
                }}
                option={{
                  selectOptions: decodeDynamicFieldHandler(
                    fieldTypeOptions[FieldTypeEnum.PROFESSION]
                  ),
                  defaultLabel: "Select Profession",
                }}
              />
              <MyTextField
                isRequired
                isNumberOnly
                label={`Revenue (${getCurrency()})`}
                placeholder={`Eg. ${getCurrency()}2500`}
                name={"revenue"}
              />
            </div>
          </FormWrapper>
          <FormWrapper
            header={{
              title: {
                name: "Property",
              },
            }}
          >
            <div
              className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
            >
              <MySelectField
                label={"Property"}
                isRequired
                name={"property"}
                option={{
                  selectOptions: decodePropertyOptionsHandler(propertyOptions),
                  defaultLabel: "Select Property",
                }}
              />
            </div>
          </FormWrapper>

          <FormWrapper
            header={{
              title: {
                name: "Tenant Guarantor",
              },
              leftChildren: (
                <>
                  <MyRadioInput
                    radioButtonType={"switch"}
                    name={"isTenantGuarantor"}
                  />
                </>
              ),
            }}
          >
            {values?.isTenantGuarantor && (
              <div className={"flex flex-col gap-5"}>
                <div
                  className={
                    "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"
                  }
                >
                  <MyTextField
                    isRequired
                    label={"Subject"}
                    placeholder={"Enter subject"}
                    name={"tenantGuarantorSubject"}
                  />
                </div>
                <div
                  className={
                    "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"
                  }
                >
                  <div className={"col-span-2"}>
                    <MyTextAreaInput
                      isRequired
                      label={"Message"}
                      name={"tenantGuarantorMessage"}
                    />
                  </div>
                </div>
              </div>
            )}
          </FormWrapper>
          <FormWrapper
            header={{
              title: {
                name: "Invitation.",
              },
              leftChildren: (
                <>
                  <MyRadioInput
                    radioButtonType={"switch"}
                    name={"shouldInvite"}
                  />
                </>
              ),
            }}
          >
            {values?.shouldInvite && (
              <>
                <div
                  className={
                    "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"
                  }
                >
                  <div className={"col-span-2"}>
                    <MyTextAreaInput
                      isRequired
                      label={"Invitation Mail"}
                      name={"invitationText"}
                    />
                  </div>
                </div>
              </>
            )}
          </FormWrapper>
        </div>
      )}
    </div>
  );
}

export default CandidatesForm;
