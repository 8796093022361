import React, { useContext, useEffect } from "react";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { FormConcludeButtons } from "assets";
import { PageTemplate } from "templates";
import { FieldTypeEnum, ParamsStringEnum, UserRoleEnum } from "interfaces";
import { AppContext, DocumentContext, OptionsContext } from "context";

import { DocumentFormikValues } from "../../helpers";
import { DocumentForm } from "../../components";
import { PageLinks } from "routes";

function NewDocumentPage() {
  const navigate = useNavigate();
  const {
    handlers: { setLoading },
  } = useContext(AppContext);
  const {
    getPropertyOptionsHandler,
    getOptionsHandler,
    getUserOptionsHandler,
  } = useContext(OptionsContext);
  const params = useParams<ParamsStringEnum>();

  const { details, getDetailsHandler, editDetailsHandler } =
    useContext(DocumentContext);
  const isEdit = params.id;
  useEffect(() => {
    (async () => {
      setLoading(true);

      await Promise.all([
        isEdit && getDetailsHandler(isEdit),
        await getUserOptionsHandler(UserRoleEnum.TENANT),
        await getOptionsHandler(FieldTypeEnum.DOCUMENT_TYPE),
        await getPropertyOptionsHandler(),
      ]);

      setLoading(false);
    })();
  }, [isEdit]);

  const submitHandler = async (values, formikHelpers) => {
    await editDetailsHandler(values, formikHelpers, {
      onSuccess: async (payload) => {
        navigate(PageLinks.documents.list);
      },
    });
  };
  return (
    <PageTemplate
      title={"Documents"}
      scrollAll
      breadCrumb={[
        {
          name: "My Documents",
        },
        {
          name: `${isEdit ? "Edit" : "New"} Documents`,
        },
      ]}
      backNavigation={{
        title: `${isEdit ? "Edit" : "New"} Document`,
        path: PageLinks.documents.list,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={DocumentFormikValues?.initialValues(isEdit && details)}
        validationSchema={DocumentFormikValues?.validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={"flex flex-col gap-5"}>
              <DocumentForm />
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default NewDocumentPage;
