import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineDeleteOutline } from "react-icons/md";

import { MyButton, TableCardComponent } from "components";
import { PageTemplate } from "templates";
import { LedgerNameContext } from "context";
import { IconTypeEnum } from "interfaces";

import { LedgerNameTableColumns } from "../../components";
import { usePageQueryHooks, usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function LedgerNamePage() {
  const {
    allData,
    getAllDataHandlers,
    isLoading,
    totalDocs,
    totalArchiveDocs,
  } = useContext(LedgerNameContext);
  const navigate = useNavigate();
  const { isArchive, currentPage } = usePaginationQueryHooks();
  const { searchKeyword } = usePageQueryHooks();
  const pageQuery = {
    searchKeyword,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, currentPage, searchKeyword]);

  const tableCardProps = {
    column: LedgerNameTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      rightChildren: (
        <MyButton
          iconType={IconTypeEnum.ADD}
          onClick={() => {
            navigate(PageLinks.ledgerName.new);
          }}
          name={"Add Ledger name"}
        />
      ),
    },
  };
  return (
    <PageTemplate title={"Ledger Name"}>
      <TableCardComponent
        {...tableCardProps}
        searchInput={{}}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default LedgerNamePage;
