import * as yup from "yup";
import { ItemFormInterface } from "./interfaces";
import {
  LedgerNameInterface,
  PrivilegeInterface,
  SubLedgerNameInterface,
} from "interfaces";

export const newItemFormikValues = {
  initialValues: (values?: ItemFormInterface) => {
    return {
      ...(values?._id && { _id: values?._id }),
      fieldType: values?.fieldType || "",
      label: values?.label || "",
      description: values?.description || "",
    };
  },
  validationSchema: (haveValue: boolean) =>
    yup.object().shape({
      label: yup.string().required("required"),
      value: haveValue ? yup.string().required("required") : yup.string(),
      description: yup.string(),
    }),
};

export const systemItemFormikValues = {
  initialValues: (values) => {
    return {
      ...(values?._id && { _id: values?._id }),
      ...(values?.parent && { parent: values?.parent }),
      name: values?.name || "",
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().required("required"),
  }),
};

export const ledgerNameFormikValues = {
  initialValues: (values: LedgerNameInterface) => {
    return {
      ...(values?._id && { _id: values?._id }),
      name: values?.name,
      subgroup: values?.subgroup?._id,
      isSeed: values?.isSeed || false,
      subgroupValue: values?.subgroup?.value,
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().required("required"),
    subgroup: yup.string().required("required"),
  }),
};
export const subLedgerNameFormikValues = {
  initialValues: (values: SubLedgerNameInterface) => {
    return {
      subGroupID: values?._id,
      label: values?.name,
    };
  },
  validationSchema: yup.object().shape({
    label: yup.string().required("required"),
  }),
};

export const RolesFormik = {
  initialValues: (values?: PrivilegeInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      name: values?.name,
      granted: values?.granted,
    };
  },
  validationSchema: yup.object().shape({
    name: yup.string().required("required"),
  }),
};
