import React, { useContext, useEffect, useState } from "react";
import { FastField, FieldArray, Form, Formik, useFormikContext } from "formik";
import { InventoryFormikValues } from "../helpers";
import queryString from "query-string";

import {
  MyButton,
  MySelectField,
  MyTextAreaInput,
  MyTextField,
  MyUploadDocument,
  TabComponent,
} from "components";
import {
  Api,
  decodeDynamicFieldHandler,
  decodePropertyOptionsHandler,
  decodeUnitOptionsHandler,
  decodeUserOptionsHandler,
} from "helpers";
import {
  FieldTypeEnum,
  IconTypeEnum,
  InventoryInterface,
  InventoryItemInterface,
  QueryStringEnum,
} from "interfaces";
import { AppContext, OptionsContext } from "context";
import { ApiUrl } from "services";
import { useSearchParams } from "react-router-dom";
import { FormWrapper } from "assets";
import { SignatureForm } from "content";
import { usePageQueryHooks } from "useHooks";

function InventoryForm({ isLoading }: { isLoading: boolean }) {
  const { values, setFieldValue } =
    useFormikContext<
      InventoryInterface<InventoryItemInterface, string, string>
    >();
  const { propertyID, unitID } = usePageQueryHooks();

  const [isAddRoom, setAddRoom] = useState(false);
  const [tabs, setTabs] = useState<{ title: string; pathName: string }[]>([]);
  const {
    propertyOptions,
    unitOptions,
    userOptions,
    fieldTypeOptions,
    getUnitOptionsHandler,
  } = useContext(OptionsContext);
  const {
    handlers: { setLoading, setError },
  } = useContext(AppContext);
  const { getApi, putApi } = Api();
  const [query] = useSearchParams();

  let activeTab = query.get(QueryStringEnum.ACTIVE_TAB);
  const roomSerialNumber: any = queryString.parse(activeTab)?.roomSerialNumber;
  const roomID: any = queryString.parse(activeTab)?.roomID;
  if (activeTab) {
    activeTab =
      activeTab.split("&").length > 0 ? activeTab.split("&")[0] : activeTab;
  }

  const getUnitRoomsHandler = async (unitID) => {
    try {
      setLoading(true);
      const res = await getApi(ApiUrl.unit.get_unitDetails + unitID);
      const unitRooms = res?.data?.room;
      let tempTabs = [];

      unitRooms.map((e) => {
        if (e?.quantity > 1) {
          new Array(e?.quantity).fill("").map((_, key) => {
            tempTabs.push({
              title: `${e?.roomType?.label} (${key + 1})`,
              pathName: `${e?.roomType?.value}&roomSerialNumber=${
                key + 1
              }&roomID=${e?._id}`,
            });
          });
        } else {
          tempTabs.push({
            title: e?.roomType?.label,
            pathName: `${e?.roomType?.value}&roomSerialNumber=${1}&roomID=${
              e?._id
            }`,
          });
        }
      });
      setTabs(tempTabs);
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  const addRoomHandler = async (payload) => {
    try {
      setLoading(true);
      await putApi(ApiUrl.unit.put_editRoom, payload);
      await getUnitRoomsHandler(values?.unit);
      setAddRoom(false);
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  const getSelectedRoomValues = () => {
    const itemsValues: any = [...values?.items];
    let selectedInputValues;
    if (!activeTab) {
      selectedInputValues = itemsValues?.reduce((acc, current, index) => {
        if (current?.isGeneral) {
          acc?.push({
            index,
            values: current,
          });
        }
        return acc;
      }, []);
    } else {
      selectedInputValues = itemsValues?.reduce((acc, current, index) => {
        if (
          current?.roomID === roomID &&
          parseInt(current?.roomSerialNumber) === parseInt(roomSerialNumber) - 1
        ) {
          acc?.push({
            index,
            values: current,
          });
        }
        return acc;
      }, []);
    }

    return selectedInputValues;
  };

  const selectedRoomValues = getSelectedRoomValues();
  useEffect(() => {
    if (values?.property) {
      getUnitOptionsHandler(values?.property);
    }
  }, [values?.property]);

  useEffect(() => {
    (async () => {
      if (!values?.unit) {
        return;
      }
      await getUnitRoomsHandler(values?.unit);
    })();
  }, [values?.unit]);
  let ActiveTabComponent;
  if (!activeTab) {
    ActiveTabComponent = <GeneralTabForm />;
  } else if (activeTab === "signature") {
    ActiveTabComponent = <SignatureTabForm />;
  }

  return (
    <div className={"flex flex-col gap-10 overflow-x-scroll hideScroll"}>
      <div
        className={`grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1  gap-5 items-start`}
      >
        <MySelectField
          name={"property"}
          label={"Property"}
          isClearable
          isRequired
          disabled={values?.property && propertyID ? true : false}
          option={{
            selectOptions: decodePropertyOptionsHandler(propertyOptions),
            onChangeCallback() {
              setFieldValue("unit", "");
            },
          }}
        />
        <MySelectField
          isClearable
          disabled={
            !values?.property || (values?.unit && unitID) ? true : false
          }
          name={"unit"}
          label={"Unit"}
          isRequired
          option={{
            selectOptions: decodeUnitOptionsHandler(unitOptions),
          }}
        />
        <MySelectField
          name={"tenant"}
          label={"Tenant"}
          isRequired
          option={{
            selectOptions: decodeUserOptionsHandler(userOptions),
          }}
        />{" "}
        <MySelectField
          name={"inventoryStatus"}
          label={"Inventory Status"}
          isRequired
          option={{
            selectOptions: decodeDynamicFieldHandler(
              fieldTypeOptions[FieldTypeEnum.INVENTORY_STATUS]
            ),
          }}
        />
        <MyTextField
          name={"inspectionDate"}
          type={"date"}
          label={"Inspection Date"}
          isRequired
        />
      </div>
      {values?.unit && !isAddRoom && (
        <div className={"flex items-end justify-end"}>
          <div>
            <MyButton
              name={"New Room"}
              iconType={IconTypeEnum.ADD}
              onClick={() => setAddRoom(true)}
            />
          </div>
        </div>
      )}

      {isAddRoom && values?.unit && (
        <Formik
          enableReinitialize
          initialValues={{
            roomType: "",
            quantity: 1,
            unit: values?.unit,
          }}
          onSubmit={addRoomHandler}
        >
          {({ submitForm }) => {
            return (
              <Form className={"flex flex-col gap-2"}>
                <div
                  className={
                    "grid grid-cols-5 grid-cols-5 md:grid-cols-3 sm:grid-cols-1  gap-5"
                  }
                >
                  <MySelectField
                    label={"New Room"}
                    option={{
                      selectOptions: decodeDynamicFieldHandler(
                        fieldTypeOptions[FieldTypeEnum.ROOM_TYPE]
                      ),
                      defaultLabel: "Select Room Type",
                    }}
                    name={`roomType`}
                  />
                </div>
                <div
                  className={
                    "grid grid-cols-5 grid-cols-5 md:grid-cols-3 sm:grid-cols-1  gap-5"
                  }
                >
                  <div className={"flex items-center gap-2"}>
                    <MyButton name={"Add Room"} onClick={submitForm} />
                    <MyButton
                      name={"Cancel"}
                      isOutline
                      onClick={() => setAddRoom(false)}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
      {values?.unit && !isLoading && (
        <>
          <TabComponent
            tabs={[
              {
                title: "General Information",
                pathName: "",
              },
              ...tabs,
              {
                title: "Signature",
                pathName: "signature",
              },
            ]}
          />
          {activeTab !== "signature" && (
            <FieldArray
              name={"items"}
              render={({ push, remove, insert }) => {
                return (
                  <div className={"flex flex-col gap-5"}>
                    {selectedRoomValues?.map((e, key) => {
                      return (
                        <ItemForm
                          addItemHandler={(id) => {
                            const payload =
                              InventoryFormikValues.inventoryItemValues({
                                isGeneral: activeTab ? false : true,
                                roomID: roomID,
                                //  @ts-ignore
                                roomSerialNumber:
                                  activeTab &&
                                  `${parseInt(roomSerialNumber) - 1}`,
                              });
                            insert(id + 1, payload);
                          }}
                          key={key}
                          removeHandler={(index) => remove(index)}
                          index={e?.index}
                          parentName={"items"}
                        />
                      );
                    })}
                    {!selectedRoomValues?.length && (
                      <div>
                        <MyButton
                          name={"Add Items"}
                          isOutline
                          onClick={() => {
                            const payload =
                              InventoryFormikValues.inventoryItemValues({
                                isGeneral: activeTab ? false : true,
                                roomID: roomID,
                                //  @ts-ignore
                                roomSerialNumber:
                                  activeTab &&
                                  `${parseInt(roomSerialNumber) - 1}`,
                              });
                            push(payload);
                          }}
                          iconType={IconTypeEnum.ADD}
                        />
                      </div>
                    )}
                  </div>
                );
              }}
            />
          )}
          {/*  active tab*/}
          {ActiveTabComponent}
        </>
      )}
    </div>
  );
}

const ItemForm = ({ parentName, index, removeHandler, addItemHandler }) => {
  const { fieldTypeOptions } = useContext(OptionsContext);
  return (
    <div className={"flex flex-col gap-5 border-b-[1px] pb-4"}>
      <div
        className={
          "grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1  gap-5 items-start"
        }
      >
        <FastField
          name={`${parentName}.${index}.item`}
          component={(props) => {
            return (
              <MySelectField
                {...props}
                name={`${parentName}.${index}.item`}
                label={"Items"}
                option={{
                  selectOptions: decodeDynamicFieldHandler(
                    fieldTypeOptions[FieldTypeEnum.INVENTORY_ITEM]
                  ),
                  defaultLabel: "Select Item",
                }}
              />
            );
          }}
        />
        <MyTextField
          label={"Quantity"}
          name={`${parentName}.${index}.unitQuantity`}
          placeholder={"00"}
          isNumberOnly
        />
        <FastField
          name={`${parentName}.${index}.unit`}
          component={(props) => {
            return (
              <MySelectField
                {...props}
                option={{
                  selectOptions: decodeDynamicFieldHandler(
                    fieldTypeOptions[FieldTypeEnum.INVENTORY_UNIT]
                  ),
                  defaultLabel: "Select Unit",
                }}
                label={"Unit"}
                name={`${parentName}.${index}.unit`}
              />
            );
          }}
        />
        <FastField
          name={`${parentName}.${index}.inventoryItemCondition`}
          component={(props) => {
            return (
              <MySelectField
                {...props}
                option={{
                  selectOptions: decodeDynamicFieldHandler(
                    fieldTypeOptions[FieldTypeEnum.INVENTORY_ITEM_CONDITION]
                  ),
                  defaultLabel: "Select Condition",
                }}
                label={"Condition"}
                name={`${parentName}.${index}.inventoryItemCondition`}
              />
            );
          }}
        />
        <MyTextField
          label={"Notes"}
          name={`${parentName}.${index}.notes`}
          placeholder={"enter note"}
        />
      </div>
      <div className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1 gap-5"}>
        <MyUploadDocument
          label={"Images"}
          fileType={"image"}
          name={`${parentName}.${index}.images`}
          fileContainerStyle={"grid grid-cols-1 gap-2"}
        />
        <div className={"mt-8 flex items-start gap-5"}>
          <MyButton
            isOutline
            onClick={() => addItemHandler(index)}
            colorType={"primary"}
            name={"Add Items"}
            iconType={IconTypeEnum.ADD}
          />{" "}
          <MyButton
            isOutline
            onClick={() => removeHandler(index)}
            colorType={"danger"}
            name={"Delete Item"}
            iconType={IconTypeEnum.DELETE}
          />
        </div>
      </div>
    </div>
  );
};

const GeneralTabForm = () => {
  return (
    <div className={"flex flex-col gap-5"}>
      <div className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1  gap-5"}>
        <div className={"col-span-3"}>
          <MyTextAreaInput label={"Overall Comment"} name={"overallComments"} />
        </div>
      </div>
      <div className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1  gap-5"}>
        <div className={"col-span-1"}>
          <MyUploadDocument
            name={"documents"}
            fileType={"image"}
            label={"Images"}
            fileContainerStyle={"grid grid-cols-1 gap-2"}
          />
        </div>
      </div>
    </div>
  );
};

const SignatureTabForm = () => {
  const { values } =
    useFormikContext<InventoryInterface<string, string, string, string>>();
  const { ownerOptions, userOptions } = useContext(OptionsContext);
  return (
    <div className={"flex flex-col gap-10"}>
      <FormWrapper
        header={{
          title: {
            name: "Owner",
          },
        }}
      >
        <div className={"flex flex-col gap-10"}>
          <div
            className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <MySelectField
              option={{
                selectOptions: decodeUserOptionsHandler(ownerOptions),
                defaultLabel: "Select Condition",
              }}
              label={"Print Name"}
              name={`ownerSignature.participant`}
            />
            <MyTextField
              label={"Signed Date"}
              name={"ownerSignature.signedDate"}
              type={"date"}
            />
          </div>
          <div
            className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <SignatureForm
              label={"Upload Signature"}
              customerID={
                typeof values.ownerSignature.participant === "string" &&
                values.ownerSignature.participant
              }
              populateFieldName={"ownerSignature.signature"}
              populateFieldValue={values?.ownerSignature?.signature}
            />
          </div>
        </div>
      </FormWrapper>
      <FormWrapper
        header={{
          title: {
            name: "Tenant",
          },
        }}
      >
        <div className={"flex flex-col gap-10"}>
          <div
            className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            {/*<MySelectField*/}
            {/*  option={{*/}
            {/*    selectOptions: decodeUserOptionsHandler(userOptions),*/}
            {/*    defaultLabel: "Select Tenant",*/}
            {/*  }}*/}
            {/*  label={"Print Name"}*/}
            {/*  name={`tenantSignature.participant`}*/}
            {/*/>*/}
            <MyTextField
              label={"Signed Date"}
              name={"tenantSignature.signedDate"}
              type={"date"}
            />
          </div>
          <div
            className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <SignatureForm
              label={"Upload Signature"}
              customerID={values.tenant}
              populateFieldName={"tenantSignature.signature"}
              populateFieldValue={values?.tenantSignature?.signature}
            />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
};

export default InventoryForm;
