import * as yup from "yup";
import { AddressInterface, PropertyUnitDocumentInterface } from "interfaces";
export const addressInitialValues = (values?: AddressInterface) => {
  return {
    address1: values?.address1 || "",
    address2: values?.address2 || "",
    city: values?.city || "",
    postalCode: values?.postalCode || "",
    county:
      values?.county && typeof values?.county !== "string"
        ? values?.county?._id
        : values?.county || "",
    country:
      values?.country && typeof values?.country !== "string"
        ? values?.country?._id
        : values?.country || "",
  };
};
export const addressValidationSchema = yup.object().shape({
  address1: yup.string().required("required"),
  address2: yup.string(),
  city: yup.string().required("required"),
  postalCode: yup.string().required("required"),
  county: yup.string().required("required"),
  country: yup.string().required("required"),
});

export const PropertyUnitDocumentFormik = {
  initialValues: (values: PropertyUnitDocumentInterface) => {
    return {
      documentType: values?.documentType?._id,
      documents: values?.documents || [],
      documentReferenceID: values?.documentReferenceID,
      description: values?.description,
      dateOfIssue: values?.dateOfIssue,
      dateOfExpiry: values?.dateOfExpiry,
      sharedToTenant: values?.sharedToTenant || false,
    };
  },
  validationSchema: yup.object().shape({}),
};
