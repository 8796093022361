import React from "react";
import { IconComponent, ToggleContentWrapper } from "../components";
import {
  DashboardApiTypeEnum,
  IconTypeEnum,
  InvoiceDashboardStatsInterface,
  InvoiceStatusEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { MyButton } from "components";
import { usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";
import { AccessVerifier } from "templates";

function InvoiceStatsContent({ isToggle }) {
  return (
    <ToggleContentWrapper
      toggle={isToggle}
      title={"Invoices"}
      getDataType={DashboardApiTypeEnum.invoice}
      haveDateFilter={true}
      havePropertyFilter={false}
    >
      {(data: InvoiceDashboardStatsInterface, isLoading, error) => {
        return (
          <div className={"grid grid-cols-2 gap-5 pt-5"}>
            <DetailsContainer
              title={"Sales"}
              draft={data?.sales?.draft}
              settled={data?.sales?.settled}
              unSettled={data?.sales?.unsettled}
              isSales={true}
              total={
                data?.sales?.draft +
                data?.sales?.settled +
                data?.sales?.unsettled
              }
            />
            <DetailsContainer
              title={"Purchase"}
              draft={data?.purchase?.draft}
              settled={data?.purchase?.settled}
              unSettled={data?.purchase?.unsettled}
              isSales={false}
              total={
                data?.purchase?.draft +
                data?.purchase?.settled +
                data?.purchase?.unsettled
              }
            />
          </div>
        );
      }}
    </ToggleContentWrapper>
  );
}

const DetailsContainer = ({
  title,
  draft,
  settled,
  unSettled,
  total,
  isSales,
}) => {
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const navigateHandler = () => {
    navigateWithReturnUrlHandler(
      PageLinks.invoice.list +
        `/${isSales ? InvoiceStatusEnum.SALES : InvoiceStatusEnum.PURCHASE}`,
      `${QueryStringEnum.ACTIVE_TAB}=${
        isSales ? InvoiceStatusEnum.SALES : InvoiceStatusEnum.PURCHASE
      }`
    );
  };
  return (
    <div className={"flex flex-col gap-5 border-2 rounded-2xl p-5"}>
      <div className={"flex justify-between items-center border-b-2 pb-2"}>
        <span className={"font-bold text-[16px]"}>{title}</span>
        <div className={"text-[14px]"}>
          <span className={"text-gray-500"}>Total records: </span>
          <span className={"text-black font-bold"}>{total}</span>
        </div>
      </div>
      <div className={"grid grid-cols-5 items-center"}>
        <IconComponent
          color={"red"}
          iconType={IconTypeEnum.SALES_STATUS_DRAFT}
        />
        <div className={"flex flex-col col-span-3 text-[12px]"}>
          <span className={"text-tRed"}>Draft</span>
          <span className={"text-[14px] font-semibold"}>{draft} Records</span>
        </div>
        <div>
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_READ_INVOICE]}
            isForPage={false}
          >
            <MyButton
              onClick={navigateHandler}
              size={"sm"}
              className={"p-2 px-4"}
              isOutline
              name={"View All"}
              colorType={"white"}
            />
          </AccessVerifier>
        </div>
      </div>
      <div className={"grid grid-cols-5 items-center"}>
        <IconComponent color={"green"} iconType={IconTypeEnum.SALES_SETTLED} />
        <div className={"flex flex-col col-span-3 text-[12px]"}>
          <span className={"text-green-500"}>Settled</span>
          <span className={"text-[14px] font-semibold"}>{settled} Records</span>
        </div>
        <div>
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_READ_INVOICE]}
            isForPage={false}
          >
            <MyButton
              size={"sm"}
              isOutline
              className={"p-2 px-4"}
              onClick={navigateHandler}
              name={"View All"}
              colorType={"white"}
            />
          </AccessVerifier>
        </div>
      </div>
      <div className={"grid grid-cols-5 items-center"}>
        <IconComponent color={"purple"} iconType={IconTypeEnum.UNSETTLED} />
        <div className={"flex flex-col col-span-3 text-[12px]"}>
          <span className={"text-purple-500"}>Unsettled</span>
          <span className={"text-[14px] font-semibold"}>
            {unSettled} Records
          </span>
        </div>
        <div>
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_READ_INVOICE]}
            isForPage={false}
          >
            <MyButton
              size={"sm"}
              isOutline
              className={"p-2 px-4"}
              onClick={navigateHandler}
              name={"View All"}
              colorType={"white"}
            />
          </AccessVerifier>
        </div>
      </div>
    </div>
  );
};

export default InvoiceStatsContent;
