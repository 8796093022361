import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { GeneratedLedgerApiResInterface } from "interfaces";
import { isDR } from "helpers";

interface GenerateLedgerReportInterface {
  data?: GeneratedLedgerApiResInterface;
  dateHeader?: string;
  dateFormatter?({ date }: { date: Date });
}
async function generateTBReport({
  data,
  dateHeader,
  dateFormatter,
}: GenerateLedgerReportInterface) {
  try {
    //    create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet("Ledger Balance", {});
    worksheet.properties.defaultRowHeight = 20;
    worksheet.properties.defaultColWidth = 12;
    // setting Account header
    worksheet.getCell("A2").value = "Ledger Account";
    worksheet.getCell("A3").value = dateHeader;

    worksheet.mergeCells("A2:G2");
    worksheet.mergeCells("A3:G3");

    const tableHeaders = [
      [],
      ["SN.", "Date", "Ledger", "Sub-Group", "Description", "Dr", "Cr"],
      ["", "Total", "", "", "", data?.totalDebit, data?.totalCredit],
      [
        "",
        "Closing Balance",
        "",
        "",
        "",
        data?.closingBalance < 0 ? Math.abs(data?.closingBalance) : 0,
        data?.closingBalance > 0 ? Math.abs(data?.closingBalance) : 0,
      ],
    ];

    worksheet?.addRows(tableHeaders);

    // inserting data in table row
    data?.ledgers?.forEach((entry, key) => {
      worksheet.addRow([
        key + 1,
        dateFormatter({ date: entry?.ledgerDate }) || "",
        entry?.account?.name || "",
        entry?.account?.subgroup?.name || "",
        entry?.account?.description || "",
        isDR(entry?.balance) ? Math.abs(entry?.balance) : 0,
        !isDR(entry?.balance) ? Math.abs(entry?.balance) : 0,
      ]);
    });

    // increase the font size
    worksheet.columns.forEach((col) => {
      col.eachCell((cell) => {
        cell.font = {
          size: 14,
        };
      });
    });
    // style header style
    worksheet.getCell("A2").alignment = {
      horizontal: "center",
    };
    worksheet.getCell("A2").font = {
      bold: true,
      size: 20,
    };
    worksheet.getCell("A3").alignment = {
      horizontal: "center",
    };
    // setting header bold
    new Array(tableHeaders?.length).fill("").forEach((e, key) => {
      worksheet.getRow(key + 4).font = {
        bold: true,
        size: 14,
      };
    });
    worksheet.getColumn(2).width = 30;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 35;
    worksheet.getColumn(5).width = 35;
    worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30;
    worksheet.views = [
      {
        state: "frozen",
        xSplit: 0,
        ySplit: 7,
      },
    ];
    //    save the workbook to a file
    const bufferFile = await workbook.xlsx.writeBuffer();
    await FileSaver.saveAs(
      new Blob([bufferFile], { type: "application/octet-stream" }),
      `${Date.now()}-ledger.xlsx`
    );
  } catch (err) {
    throw err;
  }
}

export default generateTBReport;
