import React, { useContext } from "react";

import { useFormikContext } from "formik";
import { useParams, useSearchParams } from "react-router-dom";

import { AddressForm } from "content";
import { OptionsContext, UserContext } from "context";
import { decodePrivilegeOptionsHandler } from "helpers";
import { MySelectField, MyTextField, MyUploadDocument } from "components";
import { ParamsStringEnum, QueryStringEnum, UserRoleEnum } from "interfaces";

function UserForm() {
  const { values, setFieldValue } = useFormikContext<any>();
  const { privilegeOptions } = useContext(OptionsContext);
  let userRoles = { ...UserRoleEnum };
  delete userRoles["NONE"];
  delete userRoles["CUSTOM"];
  const [query] = useSearchParams();
  const params = useParams<ParamsStringEnum>();
  const isEdit = params.id;
  const customRole: any = query?.get(QueryStringEnum.TYPE);
  const { details: userDetails } = useContext(UserContext);

  const isCreatingSeedUser = Object.values(userRoles).includes(
    customRole || (isEdit && userDetails?.role)
  );
  return (
    <>
      {!isCreatingSeedUser && (
        <div
          className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-10`}
        >
          <MySelectField
            autoFocus={!isCreatingSeedUser}
            label={"Role"}
            option={{
              selectOptions: decodePrivilegeOptionsHandler(privilegeOptions, [
                UserRoleEnum.LANDLORD,
              ]),
              onChangeCallback(currentValue) {
                const selectedRoleDetails = privilegeOptions?.find(
                  (e) => e?._id === currentValue?.value
                );
                if (selectedRoleDetails?.isSeed) {
                  setFieldValue("role", selectedRoleDetails?.role);
                } else {
                  setFieldValue("role", "custom");
                }
              },
            }}
            name={"customRole"}
          />
        </div>
      )}

      <div className={`grid grid-cols-3  gap-10 md:grid-cols-2 sm:grid-cols-1`}>
        <MyTextField
          autoFocus={isCreatingSeedUser}
          name={"firstName"}
          isRequired
          label={"First Name"}
          placeholder={"eg.John"}
        />
        <MyTextField
          name={"lastName"}
          label={"Last Name"}
          isRequired
          placeholder={"eg.Doe"}
        />

        <MyTextField
          disabled={isEdit ? true : false}
          name={"email"}
          label={"Email ID"}
          isRequired
          placeholder={"eg.doe@john.com"}
        />
        <MyTextField
          name={"phone"}
          label={"Mobile Number"}
          isRequired
          placeholder={"eg.9866000000"}
        />
      </div>
      <div className={`grid grid-cols-3  gap-10 md:grid-cols-2 sm:grid-cols-1`}>
        <MyUploadDocument
          fileContainerStyle={"flex"}
          fileType={"image"}
          name={"photo"}
          label={"Picture"}
        />
      </div>
      <div className={`grid grid-cols-3  gap-10 md:grid-cols-2 sm:grid-cols-1`}>
        <MyTextField
          name={"companyName"}
          label={"Company Name"}
          placeholder={"eg.UK Accountant"}
        />
        <MyTextField
          name={"companyRegistration"}
          label={"Company Registration Number"}
          placeholder={"eg.1245DFGHG"}
        />
        <MyTextField
          name={"vatRegistration"}
          label={"VAT Registration Number"}
          placeholder={"eg.1245DFGHG"}
        />
      </div>
      <AddressForm
        title={"Billing Details"}
        country={{
          value: values?.country,
        }}
      />
    </>
  );
}

export default UserForm;
