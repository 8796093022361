import React, { useContext, useEffect } from "react";
import { useFormikContext } from "formik";
import Skeleton from "react-loading-skeleton";

import {
  IconTypeEnum,
  PackageCreateOrderPayloadInterface,
  PackagePurchaseType,
  PackageTimeType,
} from "interfaces";
import { AppContext } from "context";
import { getIconHelper } from "helpers";
import { MySelectField } from "components";

import { getDurationHandler } from "../helpers";
import { SelectSubscriptionCard } from "../components";
import { SubscriptionContext } from "../context";
import { SubscriptionChildPagePropsInterface } from "../interfaces";

function SelectSubscriptionContainer({
  getPackageStatusHandler,
}: SubscriptionChildPagePropsInterface) {
  const {
    subscriptionPackages,
    mySubscriptionStats,
    isSubscriptionPackageLoading,
    isSubscriptionStatsLoading,
  } = useContext(SubscriptionContext);

  const {
    handlers: { setError },
  } = useContext(AppContext);
  const { values, setFieldValue } =
    useFormikContext<PackageCreateOrderPayloadInterface>();
  const isMonthly = values?.timeUnit === PackageTimeType.MONTHLY;
  const setTimeUnitHandler = (timeUnit: PackageTimeType) => {
    setFieldValue("timeUnit", timeUnit);
  };
  const isPurchaseTypeProperty =
    values?.purchaseType === PackagePurchaseType.PROPERTY;

  const selectPackageHandler = (packageID: string) => {
    setFieldValue("subscription", packageID);
  };
  useEffect(() => {
    if (values?.subscription) {
      const { minExtraProperty, canSelect } = getPackageStatusHandler(
        values?.subscription,
        values
      );
      if (!canSelect && !isPurchaseTypeProperty) {
        selectPackageHandler("");
      }
      if (canSelect) {
        setFieldValue("propertyQuantity", minExtraProperty);
      }
    }
    if (isPurchaseTypeProperty) {
      setFieldValue("propertyQuantity", 1);
    }
  }, [values?.subscription, isPurchaseTypeProperty, values?.timeUnit]);

  const onPurchaseTypePropertyHandler = () => {
    //  1. find if package exists or not
    const selectedSubscription = subscriptionPackages?.find(
      (e) =>
        e?._id === mySubscriptionStats?.activePackage?.subscribedPackage?._id
    );
    if (!selectedSubscription) {
      setError(true, "Subscribed package not found");
      return;
    }
    //  2. if exists then select that package
    selectPackageHandler(selectedSubscription?._id);
  };
  useEffect(() => {
    if (
      isPurchaseTypeProperty &&
      !isSubscriptionPackageLoading &&
      !isSubscriptionStatsLoading
    ) {
      onPurchaseTypePropertyHandler();
    }
  }, [
    isPurchaseTypeProperty,
    isSubscriptionPackageLoading,
    isSubscriptionStatsLoading,
  ]);
  let selectablePackages = subscriptionPackages;
  if (isPurchaseTypeProperty) {
    selectablePackages = selectablePackages?.filter(
      (e) => e?._id === values?.subscription
    );
  } else if (
    !isPurchaseTypeProperty &&
    mySubscriptionStats?.activePackage?.subscribedPackage?._id
  ) {
    selectablePackages = selectablePackages?.filter(
      (e) =>
        e?._id !== mySubscriptionStats?.activePackage?.subscribedPackage?._id
    );
  }
  return (
    <div className={"flex flex-col gap-5 bg-gray-100 p-5 rounded-xl"}>
      <div className={"flex items-center justify-between"}>
        <b className={"text-[18px]"}>
          {isPurchaseTypeProperty ? "Upgrade Property" : "Select Plan"}
        </b>
        {!isPurchaseTypeProperty && (
          <ToggleTimeUnitComponent
            isMonthly={isMonthly}
            setTimeUnitHandler={setTimeUnitHandler}
          />
        )}
      </div>
      {!isPurchaseTypeProperty && (
        <div className={"flex justify-end"}>
          <MySelectField
            option={{
              selectOptions: getDurationHandler(values?.timeUnit),
            }}
            name={"duration"}
          />
        </div>
      )}

      <div className={"flex flex-col gap-5"}>
        {!isSubscriptionPackageLoading && subscriptionPackages?.length < 1 && (
          <div className={"text-center mt-10"}>No package found</div>
        )}
        {isSubscriptionPackageLoading
          ? new Array(2)?.fill("")?.map((e, key) => {
              return (
                <Skeleton
                  baseColor={"white"}
                  className={"w-full h-[120px] rounded-md bg-white"}
                  key={key}
                />
              );
            })
          : selectablePackages?.map((e, key) => {
              let isSelected = e?._id === values?.subscription;
              const {
                canSelect,
                canHaveUnlimitedProperty,
                minExtraProperty,
                maxExtraProperty,
              } = getPackageStatusHandler(e?._id, values);
              return (
                <SelectSubscriptionCard
                  duration={values?.duration}
                  canSelectPackage={canSelect}
                  key={key}
                  details={e}
                  isMonthly={isMonthly}
                  isSelected={isSelected}
                  maxExtraProperty={maxExtraProperty}
                  minExtraProperty={minExtraProperty}
                  canHaveUnlimitedProperty={canHaveUnlimitedProperty}
                  onSelectPackageHandler={selectPackageHandler}
                />
              );
            })}
      </div>
    </div>
  );
}

const ToggleTimeUnitComponent = ({ isMonthly, setTimeUnitHandler }) => {
  const CalendarIcon = getIconHelper(IconTypeEnum.CALENDER);

  return (
    <div
      className={
        "flex items-center gap-2 cursor-pointer bg-gray-200 rounded-md p-1"
      }
    >
      <div
        className={`flex items-center gap-1 p-2 rounded-md ${
          isMonthly ? "bg-white" : "bg-transparent"
        }`}
        onClick={() => setTimeUnitHandler(PackageTimeType.MONTHLY)}
      >
        <div className={"bg-gray-200 rounded-md px-2 py-1"}>
          <CalendarIcon className={"text-gray-800"} />
        </div>
        <span>Monthly</span>
      </div>
      <div
        className={`flex items-center gap-1 p-2 rounded-md ${
          !isMonthly ? "bg-white" : "bg-transparent"
        }`}
        onClick={() => setTimeUnitHandler(PackageTimeType.ANNUALLY)}
      >
        <div className={"bg-gray-200 rounded-md px-2 py-1"}>
          <CalendarIcon className={"text-gray-800"} />
        </div>
        <span>Annually</span>
      </div>
    </div>
  );
};

export default SelectSubscriptionContainer;
