import React, { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { FormConcludeButtons } from "assets";
import { PageTemplate } from "templates";
import { FieldTypeEnum, ParamsStringEnum, UserRoleEnum } from "interfaces";
import { InventoryDataContext, OptionsContext } from "context";

import { InventoryFormikValues } from "../helpers";
import { InventoryForm } from "../components";
import { usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";
import { Loader } from "components";

function NewInventoryPage() {
  const navigate = useNavigate();
  const params = useParams<ParamsStringEnum>();
  const { returnURL, propertyID, unitID } = usePageQueryHooks();
  const {
    getPropertyOptionsHandler,
    getOptionsHandler,
    getUserOptionsHandler,
    getOwnerOptionsHandler,
  } = useContext(OptionsContext);
  const { details, editDetailsHandler, getDetailsHandler } =
    useContext(InventoryDataContext);
  const isEdit = params.id;
  const [enableReinitialize, setEnableReinitialize] = useState(true);
  useEffect(() => {
    (async () => {
      setEnableReinitialize(true);
      await Promise.all([
        isEdit && getDetailsHandler(isEdit),
        getPropertyOptionsHandler(),
        getUserOptionsHandler(UserRoleEnum.TENANT),
        getOwnerOptionsHandler(),
        getOptionsHandler(FieldTypeEnum.INVENTORY_STATUS),
        getOptionsHandler(FieldTypeEnum.ROOM_TYPE),
        getOptionsHandler(FieldTypeEnum.INVENTORY_ITEM),
        getOptionsHandler(FieldTypeEnum.INVENTORY_UNIT),
        getOptionsHandler(FieldTypeEnum.INVENTORY_ITEM_CONDITION),
      ]);
      setEnableReinitialize(false);
    })();
  }, [isEdit]);
  const submitHandler = async (values, formikHelpers) => {
    await editDetailsHandler(values, formikHelpers, {
      onSuccess: async (payload) => {
        if (returnURL) {
          navigate(returnURL);
        } else {
          navigate(PageLinks.inventory.list);
        }
      },
      onError(error?: any) {
        console.log(error);
      },
    });
  };
  return (
    <PageTemplate
      title={"Inventories"}
      scrollAll
      backNavigation={{
        title: `${isEdit ? "Edit" : "New"} Inventory`,
        path: PageLinks.inventory.list,
      }}
    >
      <Formik
        enableReinitialize={enableReinitialize}
        initialValues={InventoryFormikValues?.initialValues(
          isEdit
            ? details
            : {
                property: {
                  _id: propertyID,
                },
                unit: {
                  _id: unitID,
                },
              }
        )}
        validationSchema={InventoryFormikValues?.validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting }) => {
          return (
            <Form
              className={"flex flex-col gap-10 overflow-x-scroll hideScroll"}
            >
              <InventoryForm isLoading={enableReinitialize} />
              {enableReinitialize && <Loader />}
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default NewInventoryPage;
