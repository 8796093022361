import React, { useContext } from "react";

import { useAuthorizationHook, useDateHook } from "useHooks";

import { FormWrapper, NameValueText } from "assets";
import { MyButton, MyToggleButton } from "components";
import { Outlet, useNavigate } from "react-router-dom";
import { IconTypeEnum, SpaceAccessListEnum } from "interfaces";
import { PropertyDataContext } from "context";
import { PageLinks } from "routes";
import { separateWithComma } from "helpers";

function PropertyDetailTab() {
  const { getDateHandler } = useDateHook();

  const { details, isDetailsLoading, editDetailsHandler, getDetailsHandler } =
    useContext(PropertyDataContext);
  const navigate = useNavigate();
  const GeneralDetails = {
    "Property Code": details?.propertyID,
    "Property Type": details?.propertyType?.label,
    'Address 1': details?.address?.address1,
    'Address 2': details?.address?.address2,
    "Town/city": details?.address?.city,
    "Zip/Post Code": details?.address?.postalCode,
    County:
      typeof details?.address?.county !== "string" &&
      details?.address?.county?.name,
    Country:
      typeof details?.address?.country !== "string" &&
      details?.address?.country?.name,
  };

  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_EDIT_PROPERTY,
  ]);
  return (
    <>
      <div className={"flex flex-col gap-10"}>
        <FormWrapper
          header={{
            title: { name: "General Information" },
            rightChildren: canEdit && details?._id && (
              <div>
                <MyButton
                  isOutline
                  colorType={"primary"}
                  name={"Edit General Information"}
                  onClick={() => {
                    navigate(PageLinks.property?.edit(details?._id));
                  }}
                  iconType={IconTypeEnum.EDIT}
                />
              </div>
            ),
          }}
        >
          <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1"}>
            <div
              className={
                "col-span-2 grid grid-cols-2 sm:grid-cols-1 gap-5 mt-5"
              }
            >
              {Object.keys(GeneralDetails).map((e, key) => {
                return (
                  <NameValueText
                    key={key}
                    alignDirection={"row"}
                    name={e}
                    value={GeneralDetails[e]}
                    isLoading={isDetailsLoading}
                  />
                );
              })}
            </div>
          </div>
        </FormWrapper>
        <FormWrapper header={{ title: { name: "Description" } }}>
          <div
            className={
              "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 mt-5"
            }
          >
            <NameValueText
              alignDirection={"row"}
              isLoading={isDetailsLoading}
              name={"Area"}
              value={`${separateWithComma(details?.unitNumber)} sq. ${
                details?.unitSize?.label
              }`}
            />{" "}
            <NameValueText
              alignDirection={"row"}
              isLoading={isDetailsLoading}
              name={"Date of Completion/Date of Exchange"}
              value={getDateHandler({ date: details?.constructionDate })}
            />
            <div className={"col-span-2 sm:col-span-1"}>
              <NameValueText
                alignDirection={"row"}
                isLoading={isDetailsLoading}
                name={"Description"}
                value={details?.description || "-"}
              />
            </div>
          </div>
        </FormWrapper>
        <FormWrapper header={{ title: { name: "More" } }}>
          <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1"}>
            <div className={"flex items-center gap-10"}>
              <NameValueText
                alignDirection={"row"}
                isLoading={isDetailsLoading}
                name={"Current Status"}
                value={details?.isVacant ? "Vacant" : "Occupied"}
              />
              {!isDetailsLoading && canEdit && (
                <MyToggleButton
                  value={!details?.isVacant}
                  type={"button"}
                  buttonType={"toggle"}
                  changeHandler={async (newValue) => {
                    let payload = {
                      _id: details?._id,
                      isVacant: details?.isVacant ? false : true,
                    };
                    await editDetailsHandler(payload, null, {
                      async onSuccess(payload?: any) {
                        await getDetailsHandler(details?._id);
                      },
                    });
                  }}
                />
              )}
            </div>
          </div>
        </FormWrapper>
      </div>
      <Outlet />
    </>
  );
}

export default PropertyDetailTab;
