import React from "react";
import { ToggleContentWrapper } from "../components";
import {
  BankInterface,
  DashboardApiTypeEnum,
  IconTypeEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { MyButton } from "components";
import { NameValueText, UserTableProfile } from "assets";
import { PageLinks } from "routes";
import { getCurrency } from "helpers";
import { useNavigate } from "react-router-dom";
import { AccessVerifier } from "templates";

function ReconciliationContent({ isToggle }) {
  return (
    <ToggleContentWrapper
      toggle={isToggle}
      title={"Bank Reconciliation"}
      getDataType={DashboardApiTypeEnum.reconciliation}
    >
      {(data: BankInterface[]) => {
        return (
          <div className={"flex flex-col gap-2 mt-5"}>
            {data?.map((e, key) => {
              return <DetailsContainer details={e} key={key} />;
            })}
          </div>
        );
      }}
    </ToggleContentWrapper>
  );
}

const DetailsContainer = ({ details }: { details: BankInterface }) => {
  const navigate = useNavigate();
  return (
    <div
      className={
        "grid grid-cols-11 rounded-md border-[1px] p-4 cursor-pointer items-center"
      }
    >
      <div className={"col-span-3"}>
        <UserTableProfile
          badgeIconType={details?.isBankLinked && IconTypeEnum.LAYER}
          details={{
            isStaticPath: details?.logo_uri ? true : false,
            name: details?.label,
            subTitle: `${details?.name}`,
            profile: details?.logo_uri || details?.logo,
          }}
        />
      </div>
      <div className={"col-span-2"}>
        <NameValueText
          nameStyle={"text-[14px]"}
          valueStyle={"text-[14px]"}
          name={"Source"}
          value={details?.source}
        />
      </div>{" "}
      <div className={"col-span-2"}>
        <NameValueText
          nameStyle={"text-[14px]"}
          valueStyle={"text-[14px]"}
          name={"Closing Balance"}
          value={`${details?.currency || getCurrency()}${
            details?.closingBalance
          }`}
        />
      </div>
      <div className={"col-span-2"}>
        <NameValueText
          nameStyle={"text-[14px]"}
          valueStyle={"text-[14px]"}
          name={"Reconcile Item Count"}
          value={`${details?.reconciledItemCount}`}
        />
      </div>
      <div className={"col-span-2"}>
        <AccessVerifier
          accessKeys={[SpaceAccessListEnum.FINANCE_READ_RECONCILE_STATEMENT]}
          isForPage={false}
        >
          <MyButton
            className={"rounded-md shadow-md bg-[#6B9DFE] font-normal"}
            name={"Reconcile Items"}
            colorType={"blue"}
            onClick={() =>
              navigate(PageLinks.reconciliation.statements(details?._id))
            }
          />
        </AccessVerifier>
      </div>
    </div>
  );
};

export default ReconciliationContent;
