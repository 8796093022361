import React, { useContext, useEffect } from "react";
import { FileViewCard, MyButton } from "components";
import { AddressText, FormWrapper, NameValueText } from "assets";

import { PageTemplate } from "templates";
import { useNavigate, useParams } from "react-router-dom";
import { TripContext } from "context";
import { IconTypeEnum, ParamsStringEnum } from "interfaces";
import { getCurrency, separateWithComma } from "helpers";
import { useDateHook } from "useHooks";
import { PageLinks } from "routes";

function TripsDetails() {
  const { getDateHandler } = useDateHook();

  const params = useParams<ParamsStringEnum>();
  const { details, isDetailsLoading, getDetailsHandler } =
    useContext(TripContext);
  const navigate = useNavigate();
  useEffect(() => {
    getDetailsHandler(params?.id);
  }, [params?.id]);

  return (
    <PageTemplate
      scrollAll
      title={"Tools "}
      backNavigation={{
        title: "Trip Details",
        path: PageLinks.trips.list,
      }}
      titleRightChildren={
        <div>
          {details?._id && (
            <MyButton
              isOutline
              iconType={IconTypeEnum.EDIT}
              name={"Edit Details"}
              onClick={() => {
                navigate(PageLinks.trips.edit(details?._id));
              }}
            />
          )}
        </div>
      }
    >
      <div className={"flex flex-col gap-10"}>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"Property"}
            value={
              <div className={"flex gap-2 items-center"}>
                ({details?.property?.propertyID})
                <AddressText isOneLine address={details?.property?.address} />
              </div>
            }
          />
          <NameValueText
            alignDirection={"col"}
            name={"Unit"}
            isLoading={isDetailsLoading}
            value={
              <span>
                ({details?.unit?.unitID}){details?.unit?.name}
              </span>
            }
          />
        </div>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"Date"}
            value={getDateHandler({ date: details?.tripDate })}
          />
        </div>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"From"}
            value={details?.from}
          />{" "}
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"To"}
            value={details?.to}
          />
        </div>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"Distance (KM)"}
            value={details?.distanceCovered}
          />
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={`Unit Price (${getCurrency()})`}
            value={details?.costPerDistance}
          />{" "}
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={`Total Amount (${getCurrency()})`}
            value={separateWithComma(details?.totalAmount)}
          />
        </div>
        <div className={"grid grid-cols-2 gap-5"}>
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={`Description`}
            value={details?.description}
          />
        </div>

        {details?.documents?.length > 0 && (
          <FormWrapper
            header={{
              title: {
                name: "Documents",
              },
            }}
          >
            <div
              className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-5"}
            >
              {details?.documents?.map((e, key) => {
                return <FileViewCard filePath={e} key={key} />;
              })}
            </div>
          </FormWrapper>
        )}
      </div>
    </PageTemplate>
  );
}

export default TripsDetails;
