import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  TableColumnInterface,
  MeterReadingInterface,
  SpaceAccessListEnum,
} from "interfaces";
import { MyMoreOptionButton } from "components";

import { MeterReadingContext } from "context";
import { useAuthorizationHook, useDateHook } from "useHooks";
import { getCurrency, separateWithComma } from "helpers";
import { usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";
import { PropertyUnitTableText } from "../../../../assets";

function MeterReadingTableColumns(
  query
): TableColumnInterface<MeterReadingInterface>[] {
  const navigate = useNavigate();
  const { deleteHandler, archiveHandler, getAllDataHandlers } =
    useContext(MeterReadingContext);
  const { calcSNHandler } = usePaginationQueryHooks();
  const { getDateHandler } = useDateHook();

  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.TOOLS_EDIT_METER_READING,
  ]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.TOOLS_DEL_METER_READING,
  ]);

  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <span>{calcSNHandler(key)}.</span>;
      },
    },
    {
      title: "Property",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.property?.propertyID}
            address={renderData?.property?.address}
            propertyID={renderData?.property?._id}
          />
        );
      },
    },
    {
      title: "Unit",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.unit?.unitID}
            type={"unit"}
            name={renderData?.unit?.name}
            propertyID={renderData?.property?._id}
            unitID={renderData?.unit?._id}
          />
        );
      },
    },

    {
      title: "Meter",
      render(renderData, key: number) {
        return <span className={" "}>{renderData?.meterNumber}</span>;
      },
    },
    {
      title: "Type",
      render(renderData, key: number) {
        return <span>{renderData?.meterType?.label}</span>;
      },
    },
    {
      title: "Date",
      render(renderData, key: number) {
        return (
          <div>
            <span>{getDateHandler({ date: renderData?.readingDate })}</span>
          </div>
        );
      },
    },
    {
      title: "Consumption",
      render(renderData, key: number) {
        return (
          <div>
            <span>{renderData?.consumption}</span>
          </div>
        );
      },
    },
    {
      title: `Price (${getCurrency()})`,
      render(renderData, key: number) {
        return (
          <div>
            <span>{separateWithComma(renderData?.totalAmount)}</span>
          </div>
        );
      },
    },
    {
      title: "Action",
      render(renderData, key: number) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigate(PageLinks.meterReading.details(renderData?._id));
                  },
                },
                canEdit &&
                  !renderData?.isArchived && {
                    name: "Edit",
                    handler: () => {
                      navigate({
                        pathname: PageLinks.meterReading.edit(renderData?._id),
                      });
                    },
                  },
                canDelete && {
                  name: "Delete",
                  handler: () => {
                    deleteHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
                canDelete && {
                  name: renderData?.isArchived ? `Unarchive` : "Archive",
                  handler: () => {
                    archiveHandler(renderData?._id, renderData?.isArchived, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default MeterReadingTableColumns;
