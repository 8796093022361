import React, { useContext } from "react";

import { MySelectField, MyTextField } from "components";
import { OptionsContext } from "context";
import { decodeLedgerGroupOptionsHandler } from "helpers";
import { useFormikContext } from "formik";
import { JournalAccountSubgroupEnum, LedgerNameInterface } from "interfaces";
function LedgerNameForm() {
  const { values } = useFormikContext<LedgerNameInterface>();
  const { ledgerGroupList } = useContext(OptionsContext);
  const isFixedAssets = () => {
    return (
      values?.subgroupValue?.toLowerCase() ===
      JournalAccountSubgroupEnum.TangibleFixedAssets?.toLowerCase()
    );
  };
  let isSubGroupDisable = values?.isSeed || (values?._id && isFixedAssets());
  return (
    <div className={"flex flex-col gap-5"}>
      <div className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5`}>
        <MyTextField
          isRequired
          label={"Name"}
          name="name"
          placeholder={"Eg. Income balance"}
        />
      </div>
      <div className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5`}>
        <MySelectField
          isRequired
          disabled={isSubGroupDisable}
          option={{
            selectOptions: decodeLedgerGroupOptionsHandler(ledgerGroupList),
          }}
          name={"subgroup"}
          label={"Sub-Group"}
        />
      </div>
    </div>
  );
}

export default LedgerNameForm;
