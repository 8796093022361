import React from "react";
import { PageTemplateComponentInterface } from "./interfaces";

function PageTemplateComponent({
  data,
  children,
}: PageTemplateComponentInterface) {
  return (
    <div className="loginPage_container hideScroll grid-cols-2 sm:grid-cols-1 gap-10">
      <div
        className={
          "col-span-1  bg-red-300 overflow-y-scroll hideScroll sm:hidden"
        }
      >
        <img
          className="w-full h-full object-cover"
          src={
            data?.imageType === "primary"
              ? "/img/app/loginBg.png"
              : "/img/app/signUpBg.png"
          }
          alt="login"
        />
      </div>

      <div
        className={
          "grid col-span-1 justify-center overflow-y-scroll hideScroll mr-10"
        }
      >
        <div className="flex flex-col  max-w-[550px] gap-8 pb-20 pt-10 px-2 ">
          <div className={"flex flex-col gap-2"}>
            <p className="font-bold text-[36px]">{data?.title}</p>
            <span className={"text-tLightGray font-normal text-[14px]"}>
              {data?.subTitle}
            </span>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default PageTemplateComponent;
