import React, { useContext, useEffect } from "react";

import { useParams } from "react-router-dom";

import { PageLinks } from "routes";
import { LoanContext } from "context";
import { useDateHook } from "useHooks";
import { PageTemplate } from "templates";
import { ParamsStringEnum } from "interfaces";
import { FormWrapper, NameValueText } from "assets";
import { FileViewCard, MyCheckButton } from "components";
import { getCurrency, separateWithComma } from "helpers";

function LoanAndMortgagesForm() {
  const params = useParams<ParamsStringEnum>();
  const { details, getDetailsHandler, isDetailsLoading } =
    useContext(LoanContext);
  const { getDateHandler, getStaffFullName } = useDateHook();

  useEffect(() => {
    getDetailsHandler(params.id);
  }, [params.id]);

  return (
    <PageTemplate
      scrollAll
      title={"Loans/Mortgages"}
      backNavigation={{
        title: "Loans/Mortgages Details",
        path: PageLinks.loansAndMortgages.list,
      }}
      breadCrumb={[
        {
          name: "Loans/Mortgages",
        },
      ]}
    >
      <div className={"flex flex-col gap-10"}>
        <div className={`grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5`}>
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Property"}
            value={`(${details?.property?.propertyID}) ${details?.property?.address?.address1}`}
          />
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Unit"}
            value={`(${details?.unit?.unitID}) ${details?.unit?.name}`}
          />
        </div>
        {/*finance cost schedule*/}

        <div className={"flex flex-col gap-10"}>
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-5"}
          >
            <span className={"font-bold text-[20px]"}>Finance Cost</span>
            <div className={"text-[18px] font-semibold"}>
              Total Cost:{" "}
              <span className={"font-bold text-primary  text-[20px]"}>
                {getCurrency()}{separateWithComma(details?.financeTotalCost)}
              </span>
            </div>
          </div>
          {details?.financeCost?.map((e, key) => {
            return (
              <div
                key={key}
                className={"grid grid-cols-3 sm:grid-cols-2 items-start gap-5"}
              >
                <NameValueText
                  isLoading={isDetailsLoading}
                  name={"Finance Name"}
                  value={e?.financeName || "-"}
                />
                <NameValueText
                  isLoading={isDetailsLoading}
                  name={`Cost`}
                  value={`${getCurrency()}${separateWithComma(e?.cost)}`}
                />
                <div className={"mt-5 sm:mt-0"}>
                  <MyCheckButton
                    checkIcon={"radio"}
                    isChecked={e?.isIncludedInMortgageLoan}
                    label={"Additional in Mortgage/Loan"}
                  />
                </div>
              </div>
            );
          })}
        </div>
        {/*Loan details*/}
        <FormWrapper
          header={{
            title: {
              name: "Loan Details",
            },
          }}
        >
          <div
            className={"grid grid-cols-6 md:grid-cols-3 sm:grid-cols-2 gap-5"}
          >
            <NameValueText
              isLoading={isDetailsLoading}
              name={"Finance Type"}
              value={details?.financeType?.label}
            />
            <NameValueText
              isLoading={isDetailsLoading}
              name={"Mortgage Type"}
              value={details?.mortgageType?.label}
            />
            <NameValueText
              isLoading={isDetailsLoading}
              name={"Loan Amount"}
              value={`${getCurrency()}${separateWithComma(
                details?.loanAmount
              )}`}
            />
            <NameValueText
              isLoading={isDetailsLoading}
              name={"Start Date"}
              value={getDateHandler({ date: details?.startDate })}
            />
            <NameValueText
              isLoading={isDetailsLoading}
              name={"Total Loan Term"}
              value={details?.totalLoanTermQuantity}
            />
            <NameValueText
              isLoading={isDetailsLoading}
              name={"Frequency"}
              value={details?.totalLoanTermFrequency?.label}
            />
          </div>
        </FormWrapper>

        {/*Interest charge*/}
        <FormWrapper
          header={{
            title: {
              name: "Interest Charge",
            },
          }}
        >
          <div className={"flex flex-col gap-5 sm:gap-10"}>
            {details?.interest?.map((e, key) => {
              return (
                <div
                  key={key}
                  className={"grid grid-cols-4 sm:grid-cols-2 gap-5"}
                >
                  <NameValueText
                    isLoading={isDetailsLoading}
                    name={"Interest Rate (%)"}
                    value={e?.rate}
                  />{" "}
                  <NameValueText
                    isLoading={isDetailsLoading}
                    name={"Interest Type"}
                    value={e?.interestType?.label || "-"}
                  />
                  <NameValueText
                    isLoading={isDetailsLoading}
                    name={"Apply Date"}
                    value={getDateHandler({ date: e?.interestApplyDate })}
                  />
                  <NameValueText
                    isLoading={isDetailsLoading}
                    name={"End Date"}
                    value={getDateHandler({ date: e?.interestEndDate })}
                  />
                </div>
              );
            })}
            <div className={"grid grid-cols-5"}>
              <NameValueText
                isLoading={isDetailsLoading}
                name={"Lender Name"}
                value={getStaffFullName(details?.lender?.name)}
              />
            </div>
          </div>
        </FormWrapper>

        {details?.documents?.length > 0 && (
          <FormWrapper
            header={{
              title: {
                name: "Documents",
              },
            }}
          >
            <div
              className={"grid grid-cols-6 md:grid-cols-3 sm:grid-cols-2 gap-5"}
            >
              {details?.documents?.map((e, key) => {
                return (
                  <div className={"flex flex-col items-start gap-5"}>
                    <NameValueText
                      isLoading={isDetailsLoading}
                      name={"Label"}
                      value={e?.label}
                    />
                    <div>
                      <FileViewCard canDownload filePath={e?.document} />
                    </div>
                  </div>
                );
              })}
            </div>
          </FormWrapper>
        )}
      </div>
    </PageTemplate>
  );
}

export default LoanAndMortgagesForm;
