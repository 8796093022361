import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "routes";
import { OptionsContext, TaskContext } from "context";
import { MyMoreOptionButton, MyStatusSelectButton } from "components";
import { PropertyUnitTableText, StatusText, UserTableProfile } from "assets";
import {
  decodeOptionColorHandler,
  getCurrency,
  separateWithComma,
} from "helpers";
import {
  useAuthorizationHook,
  useDateHook,
  usePaginationQueryHooks,
} from "useHooks";
import {
  FieldTypeEnum,
  SpaceAccessListEnum,
  TableColumnInterface,
  TaskInterface,
} from "interfaces";

function TaskTableColumns(pageQuery): TableColumnInterface<TaskInterface>[] {
  const navigate = useNavigate();
  const {
    archiveHandler,
    getAllDataHandlers,
    deleteHandler,
    editDetailsHandler,
  } = useContext(TaskContext);
  const { getDateHandler, getStaffFullName } = useDateHook();
  const navigateDetailsPage = (itemId) => {
    navigate(PageLinks.task.details(itemId));
  };
  const { calcSNHandler } = usePaginationQueryHooks();
  const { fieldTypeOptions, getOptionsHandler } = useContext(OptionsContext);

  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([SpaceAccessListEnum.TASK_EDIT_TASK]);
  const canDelete = checkCanAccessHandler([SpaceAccessListEnum.TASK_DEL_TASK]);
  const TaskPriorityOptions = fieldTypeOptions[
    FieldTypeEnum.TASK_PRIORITY
  ]?.map((e) => {
    return {
      label: e?.label,
      value: e?._id,
      textColor: decodeOptionColorHandler(e?.value).textColor,
      bgColor: decodeOptionColorHandler(e?.value).bgColor,
    };
  });
  const TaskStatusOptions = fieldTypeOptions[FieldTypeEnum.TASK_STATUS]?.map(
    (e) => {
      return {
        label: e?.label,
        value: e?._id,
        textColor: decodeOptionColorHandler(e?.value).textColor,
        bgColor: decodeOptionColorHandler(e?.value).bgColor,
      };
    }
  );
  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <span>{calcSNHandler(key)}</span>;
      },
    },
    {
      title: "Property",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.property?.propertyID}
            address={renderData?.property?.address}
            propertyID={renderData?.property?._id}
          />
        );
      },
    },
    {
      title: "Unit",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.unit?.unitID}
            type={"unit"}
            name={renderData?.unit?.name}
            propertyID={renderData?.property?._id}
            unitID={renderData?.unit?._id}
          />
        );
      },
    },
    {
      title: "Assigned To",
      render(renderData) {
        return (
          <UserTableProfile
            details={{
              subTitle: getStaffFullName(renderData?.assignee?.name),
              profile: renderData?.assignee?.photo,
              name: renderData?.assignee?.userID || "-",
            }}
          />
        );
      },
    },
    {
      title: `Subject`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>{renderData?.name}</span>
          </div>
        );
      },
    },
    {
      title: `Charge`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>
              {getCurrency()}
              {separateWithComma(renderData?.chargeAmount)}
            </span>
          </div>
        );
      },
    },

    ,
    {
      title: `Due Date`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>{getDateHandler({ date: renderData?.targetDate })}</span>
          </div>
        );
      },
    },
    {
      title: `Priority`,
      render(renderData) {
        return (
          <MyStatusSelectButton
            initialFetchHandler={() =>
              getOptionsHandler(FieldTypeEnum.TASK_PRIORITY)
            }
            onSelectHandler={(value) => {
              canEdit &&
                editDetailsHandler(
                  {
                    _id: renderData?._id,
                    taskPriority: value,
                  },
                  null,
                  {
                    async onSuccess(payload?: any) {
                      await getAllDataHandlers(pageQuery);
                    },
                  }
                );
            }}
            selectedOption={{
              name: renderData?.taskPriority?.label,
              textColor: decodeOptionColorHandler(
                renderData?.taskPriority?.value
              ).textColor,
              bgColor: decodeOptionColorHandler(renderData?.taskPriority?.value)
                .bgColor,
            }}
            title={"Change To"}
            options={TaskPriorityOptions}
          />
        );
      },
    },
    {
      title: `Status`,
      render(renderData) {
        return (
          <MyStatusSelectButton
            initialFetchHandler={() =>
              getOptionsHandler(FieldTypeEnum.TASK_STATUS)
            }
            onSelectHandler={(value) => {
              canEdit &&
                editDetailsHandler(
                  {
                    _id: renderData?._id,
                    taskStatus: value,
                  },
                  null,
                  {
                    async onSuccess(payload?: any) {
                      await getAllDataHandlers(pageQuery);
                    },
                  }
                );
            }}
            selectedOption={{
              name: renderData?.taskStatus?.label,
              textColor: decodeOptionColorHandler(renderData?.taskStatus?.value)
                .textColor,
              bgColor: decodeOptionColorHandler(renderData?.taskStatus?.value)
                .bgColor,
            }}
            title={"Change To"}
            options={TaskStatusOptions}
          />
        );
      },
    },
    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigateDetailsPage(renderData?._id);
                  },
                },
                canEdit &&
                  !renderData?.isArchived && {
                    name: "Edit",
                    handler: () => {
                      navigate(PageLinks.task.edit(renderData?._id));
                    },
                  },
                canDelete && {
                  name: "Delete",
                  handler: () => {
                    deleteHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(pageQuery);
                      },
                    });
                  },
                },
                canDelete && {
                  name: renderData?.isArchived ? `Unarchive` : "Archive",
                  handler: () => {
                    archiveHandler(renderData?._id, renderData?.isArchived, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(pageQuery);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default TaskTableColumns;
