import {
  DynamicListInterface,
  InventoryItemInterface,
  InventoryInterface,
  PropertyInterface,
  UnitInterface,
  CustomerInterface,
} from "interfaces";

export interface RepairRequestCreationBodyInterface {
  name: string;
  property: string;
  unit?: string;
  supplier?: string;
  items: RepairAndMaintenanceInterface[];
}

export interface RepairAndMaintenanceInterface {
  _id: string;
  repairID: string;
  repairIdRef: string;
  name: string;
  nameLowerCase?: string;
  description?: string;

  property: PropertyInterface;
  unit?: UnitInterface;
  supplier?: CustomerInterface;
  isGeneral: boolean;
  roomID: string; // units room id
  roomItemName: string; // dynamic list
  roomSerialNumber: number; // starts from 0 to N-1

  inventoryItemID: InventoryItemInterface;
  inventoryID: InventoryInterface["_id"];
  unitToRepair: number;

  repairStatus: DynamicListInterface; // dynamic list
  repairPriority: DynamicListInterface; // dynamic list
  targetDate: Date;
  notifyTenant: boolean;
  notifySupplier: boolean;

  documents?: string[];
  isDeleted: boolean;
  isArchived: boolean;
}
export interface MaintenanceItemInterface {
  _id?: string;
  description?: string;
  isGeneral?: boolean;

  roomID?: string; // units room id
  roomItemName?: DynamicListInterface; // dynamic list
  roomSerialNumber?: string; // starts from 0 to N-1

  inventoryID?: string;
  inventoryItemID?: Partial<DynamicListInterface>;
  repairStatus?: DynamicListInterface;
  repairPriority?: DynamicListInterface;
  unitToRepair?: number;
  targetDate?: string;
  documents?: number;
}
export interface MaintenanceMultipleFormInterface<
  property = PropertyInterface
> {
  _id: string;
  property: property;
  unit: UnitInterface;
  name: string;
  supplier: CustomerInterface;
  notifyTenant: boolean;
  notifySupplier: boolean;

  items: Partial<MaintenanceItemInterface>[];
}

export enum RepairPriorityEnum {
  EMERGENCY = "EMERGENCY",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
}
export enum RepairStatusEnum {
  OPEN = "OPEN",
  CLOSE = "CLOSE",
  OVERDUE = "OVERDUE",
  CANCELLED = "CANCELLED",
}
