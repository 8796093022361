import React from "react";

import { PropertyTitleDetailsInterface } from "./interfaces";
import { MyIconButton } from "components";
import { AddressText, NameValueText } from "assets";
import Skeleton from "react-loading-skeleton";
import { IconTypeEnum } from "interfaces";

function PropertyTitleDetails({
  details,
  isLoading,
}: PropertyTitleDetailsInterface) {
  return (
    <div className={"flex items-center gap-4 bg-[#F6F6F7] rounded-md p-4"}>
      <MyIconButton
        containerStyle={"w-[44px] h-[44px]"}
        iconSize={18}
        iconColor={"fill-primary"}
        IconType={IconTypeEnum.PROPERTY}
      />
      <div className={"flex flex-col gap-3"}>
        {isLoading ? (
          <Skeleton />
        ) : (
          <span className={"text-[16px] text-primary font-bold"}>
            {details?.propertyID} :
          </span>
        )}

        <div
          className={
            "flex items-center md:items-start md:flex-col md:gap-2 sm:items-start sm:flex-col sm:gap-2 gap-40"
          }
        >
          <NameValueText
            isLoading={isLoading}
            alignDirection={"row"}
            name={"Property Type"}
            valueStyle={"text-[14px]"}
            value={details?.propertyType?.label}
          />
          <NameValueText
            isLoading={isLoading}
            alignDirection={"row"}
            name={"Location"}
            valueStyle={"text-[14px]"}
            value={
              <AddressText
                style={{
                  flexDirection: "row",
                }}
                address={details?.address}
              />
            }
          />
        </div>
      </div>
    </div>
  );
}

export default PropertyTitleDetails;
