import * as yup from "yup";
import {
  MatchInvoiceInterface,
  ReconcileMatchFormikInterface,
} from "./interfaces";
import {
  InvoiceBillInterface,
  ReconciliationInvoiceEnum,
  TaxTypeEnum,
} from "../../interfaces";

export const ReconcileMatchFormik = {
  matchInvoiceValues: (
    values: MatchInvoiceInterface
  ): MatchInvoiceInterface => {
    return {
      invoice: values?.invoice,
      amount: values?.amount,
      isDuplicate: values?.isDuplicate || false,
      uniqueID: values?.uniqueID,
    };
  },
  invoiceBillValues: (
    values: Partial<InvoiceBillInterface<string, string, string>>
  ) => {
    return {
      property: values?.property,
      unit: values?.unit,
      account: values?.account?._id || 0,
      description: values?.description,
      quantity: values?.dueQuantity,
      rate: values?.rate,
      discountPercent: values?.discountPercent,
      taxRate: values?.taxRate,
    };
  },
  initialValues: (values?: ReconcileMatchFormikInterface) => {
    return {
      statement: values?.statement,
      matchInvoices:
        values?.matchInvoices?.map((e) =>
          ReconcileMatchFormik?.matchInvoiceValues(e)
        ) || [],
      bank: values?.bank,
      remarks: values?.remarks,
      newMatch: {
        invoiceType:
          values?.newMatch?.invoiceType || ReconciliationInvoiceEnum.NONE,
        invoiceData: {
          client: values?.newMatch?.invoiceData?.client,
          dueDate: values?.newMatch?.invoiceData?.dueDate,
          invoiceDate: values?.newMatch?.invoiceData?.invoiceDate,
          reference: values?.newMatch?.invoiceData?.reference,
          taxType:
            values?.newMatch?.invoiceData?.taxType || TaxTypeEnum.EXEMPTED,
          invoiceID: values?.newMatch?.invoiceData?.invoiceID,
          billItems: values?.newMatch?.invoiceData?.billItems || [
            ReconcileMatchFormik.invoiceBillValues({}),
          ],
        },
      },
    };
  },
  validationSchema: yup.object().shape({
    matchInvoices: yup.array().of(
      yup.object().shape({
        account: yup.string(),
      })
    ),
  }),
};

export const UploadBankStatementFormik = {
  initialValues: {
    fileName: "",
  },
  validationSchema: yup.object().shape({
    fileName: yup.string().required("required"),
  }),
};
