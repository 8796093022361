import actionTypes from "./config";
import { AppReducerInterface } from "./interfaces";

export const appState: AppReducerInterface = {
  isLoading: false,
  isError: false,
  isActionSuccess: false,
  isAuthenticated: false,
  isAuthLoading: true,
  havePageError: false,
  pageErrorMessage: "",
  hasTenantSpaceSelected: false,
  currentWorkspace: undefined,
  tenantSpaces: [],
  profileDetails: null,
  spaceSettings: null,
  trueLayerSettings: null,
  errorMessage: "",
  successMessage: "",
  twilioSettings: undefined,
  appSettings: undefined,
};
const appReducer = (state: any, action: any) => {
  switch (action.type) {
    case actionTypes.APP_SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case actionTypes.APP_SET_APP_SETTINGS:
      return {
        ...state,
        appSettings: action.payload,
      };
    case actionTypes.APP_SET_PAGE_ERROR:
      return {
        ...state,
        havePageError: action?.payload?.status,
        pageErrorMessage: action?.payload?.message,
      };
    case actionTypes.APP_SET_TWILIO_SETTINGS:
      return {
        ...state,
        twilioSettings: action.payload,
      };
    case actionTypes.APP_SET_CURRENT_WORKSPACE:
      return {
        ...state,
        currentWorkspace: action.payload,
      };
    case actionTypes.APP_SET_SETTING:
      return {
        ...state,
        spaceSettings: action.payload,
      };
    case actionTypes.APP_SET_TRUE_LAYER_SETTING:
      return {
        ...state,
        trueLayerSettings: action.payload,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        isAuthLoading: false,
        isLoading: false,
        profileDetails: null,
        hasTenantSpaceSelected: false,
        currentWorkspace: undefined,
        tenantSpaces: [],
        spaceSettings: null,
        trueLayerSettings: null,
        errorMessage: "",
        successMessage: "",
        twilioSettings: undefined,
      };
    case actionTypes.APP_SET_SWITCH_WORKSPACE:
      return {
        ...state,
        isAuthenticated: true,
        profileDetails: null,
        hasTenantSpaceSelected: false,
      };
    case actionTypes.APP_SET_PROFILE:
      return {
        ...state,
        profileDetails: action.payload,
        isAuthenticated: true,
        hasTenantSpaceSelected: true,
      };
    case actionTypes.APP_SET_TENANT_SPACE_LIST:
      return {
        ...state,
        tenantSpaces: action.payload,
      };

    case actionTypes.APP_SET_SUCCESS_ACTION:
      return {
        ...state,
        isActionSuccess: action?.payload?.flag,
        successMessage: action?.payload?.message,
      };
    case actionTypes.APP_SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case actionTypes.APP_SET_ERROR:
      return {
        ...state,
        isError: action?.payload?.flag,
        errorMessage: action?.payload?.message,
      };
    case actionTypes.APP_SET_AUTH_LOADING:
      return {
        ...state,
        isAuthLoading: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
