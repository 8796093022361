import React, { useContext } from "react";
import {
  ListingUnitInterface,
  SpaceAccessListEnum,
  TableColumnInterface,
} from "interfaces";
import { useLocation } from "react-router-dom";
import {
  useAuthorizationHook,
  useDateHook,
  usePageQueryHooks,
  usePaginationQueryHooks,
} from "useHooks";

import { UserTableProfile } from "assets";
import { MyMoreOptionButton, MyToggleButton } from "components";
import { getCurrency, separateWithComma } from "helpers";

import { QueryStringEnum } from "interfaces";
import { UnitDataContext } from "context";

function ListingTableColumns(): TableColumnInterface<ListingUnitInterface>[] {
  const location = useLocation();
  const { getListingHandler, editListingHandler, deleteListingHandler } =
    useContext(UnitDataContext);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { getDateHandler, getStaffFullName } = useDateHook();
  const { calcSNHandler } = usePaginationQueryHooks();

  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_EDIT_UNIT,
    SpaceAccessListEnum.RENTAL_ADD_UNIT,
  ]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_DEL_UNIT,
  ]);

  return [
    {
      title: "SN",
      render(renderData: ListingUnitInterface, key: number) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },
    {
      title: "Available Date",
      render(renderData: ListingUnitInterface, key: number) {
        return <div>{getDateHandler({ date: renderData?.availableDate })}</div>;
      },
    },
    {
      title: "Contact",
      render(renderData: ListingUnitInterface, key: number) {
        return (
          <div className={"flex items-start "}>
            <UserTableProfile
              details={{
                name: getStaffFullName(renderData?.owner?.name),
                profile: renderData?.owner?.photo,
                subTitle: renderData?.property?.propertyID,
              }}
            />
          </div>
        );
      },
    },
    {
      title: `Asking Rent (${getCurrency()})`,
      render(renderData: ListingUnitInterface, key: number) {
        return (
          <div>
            {getCurrency()}
            {separateWithComma(renderData?.askingRent)}
          </div>
        );
      },
    },
    {
      title: `Deposit (${getCurrency()})`,
      render(renderData: ListingUnitInterface, key: number) {
        return (
          <div>
            {getCurrency()}
            {separateWithComma(renderData?.deposit)}
          </div>
        );
      },
    },

    {
      title: "Status",
      render(renderData: ListingUnitInterface) {
        return (
          <div className={"flex items-start justify-start"}>
            <MyToggleButton
              type={"text"}
              changeHandler={(newValue) => {
                canEdit &&
                  editListingHandler(
                    {
                      isActive: newValue,
                      _id: renderData?._id,
                    },
                    {},
                    {
                      async onSuccess(payload?: any) {
                        await getListingHandler(renderData?.unit?._id);
                      },
                    }
                  );
              }}
              value={renderData?.isActive}
              options={{
                active: {
                  name: "Active",
                  className: "bg-tGreen ",
                },
                inActive: {
                  name: "Expired",
                  className: "bg-tRed",
                },
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Action",
      render(renderData: ListingUnitInterface) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                canEdit && {
                  name: "Edit Listing",
                  handler: () => {
                    navigateWithReturnUrlHandler(
                      location?.pathname + "/modal/listing/edit",
                      `${QueryStringEnum?.ID}=${renderData?._id}`
                    );
                  },
                },
                canDelete && {
                  name: "Delete Listing",
                  handler: () => {
                    deleteListingHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getListingHandler(renderData?.unit?._id);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default ListingTableColumns;
