import React, { useContext, useEffect } from "react";
import { TableCardComponent } from "components";

import { PurchaseTableColumns } from "../components";
import { usePaginationQueryHooks } from "useHooks";
import { SubscriptionContext } from "../context";

function PurchaseHistoryTab() {
  const {
    isPurchaseHistoryLoading,
    purchaseHistory,
    totalPurchaseDocs,
    contextHandlers: { getPurchaseHistoryHandler },
  } = useContext(SubscriptionContext);
  const { currentPage } = usePaginationQueryHooks();
  useEffect(() => {
    getPurchaseHistoryHandler();
  }, [currentPage]);

  const tableCardProps = {
    column: PurchaseTableColumns(),

    loading: {
      isLoading: isPurchaseHistoryLoading,
      loadingRow: 5,
    },
    dataSource: purchaseHistory,
  };
  return (
    <div className={"overflow-x-scroll"}>
      <TableCardComponent
        title={"Purchase List"}
        {...tableCardProps}
        havePagination={{
          totalDocs: totalPurchaseDocs,
        }}
      />
    </div>
  );
}

export default PurchaseHistoryTab;
