import React, { useContext, useEffect, useState } from "react";

import { useParams, useSearchParams } from "react-router-dom";
import { useDateHook, usePageQueryHooks } from "useHooks";
import queryString from "query-string";

import {
  IconTypeEnum,
  InventoryInterface,
  InventoryItemInterface,
  ParamsStringEnum,
  QueryStringEnum,
} from "interfaces";
import {
  EmptyMessageComponent,
  FileViewCard,
  MyButton,
  TabComponent,
} from "components";
import { AppContext, InventoryDataContext, PDFContext } from "context";
import { FormWrapper, NameValueText } from "assets";
import { Api } from "helpers";
import { PageTemplate } from "templates";
import { ApiUrl } from "services";
import { PageLinks } from "routes";

function InventoryDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const { getDateHandler, getStaffFullName } = useDateHook();
  const { downloadInventoryPDF } = useContext(PDFContext);
  const [query] = useSearchParams();
  const [roomTabs, setRoomTabs] = useState<
    { title: string; pathName: string }[]
  >([]);
  const { getApi } = Api();
  let activeTab = query.get(QueryStringEnum.ACTIVE_TAB);
  const roomSerialNumber: any = queryString.parse(activeTab)?.roomSerialNumber;
  const roomID: any = queryString.parse(activeTab)?.roomID;
  if (activeTab) {
    activeTab =
      activeTab.split("&").length > 0 ? activeTab.split("&")[0] : activeTab;
  }
  const {
    details: inventoryDetails,
    isDetailsLoading,
    getDetailsHandler,
  } = useContext(InventoryDataContext);
  const {
    handlers: { setLoading, setError },
  } = useContext(AppContext);
  const getUnitRoomsHandler = async (unitID) => {
    try {
      setLoading(true);
      const res = await getApi(ApiUrl.unit.get_unitDetails + unitID);
      const unitRooms = res?.data?.room;
      let tempTabs = [];

      unitRooms.map((e) => {
        if (e?.quantity > 1) {
          new Array(e?.quantity).fill("").map((_, key) => {
            tempTabs.push({
              title: `${e?.roomType?.label} (${key + 1})`,
              pathName: `${e?.roomType?.value}&roomSerialNumber=${
                key + 1
              }&roomID=${e?._id}`,
            });
          });
        } else {
          tempTabs.push({
            title: e?.roomType?.label,
            pathName: `${e?.roomType?.value}&roomSerialNumber=${1}&roomID=${
              e?._id
            }`,
          });
        }
      });
      setRoomTabs(tempTabs);
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  useEffect(() => {
    (async () => {
      await getDetailsHandler(params?.id);
    })();
  }, [params?.id]);
  useEffect(() => {
    (async () => {
      if (inventoryDetails?.unit?._id) {
        await getUnitRoomsHandler(inventoryDetails?.unit?._id);
      }
    })();
  }, [inventoryDetails?.unit?._id]);

  const tabs = [
    {
      title: "General Information",
      pathName: "",
    },
    ...roomTabs,
    {
      title: "Signature",
      pathName: "signature",
    },
  ];
  const getSelectedRoomValues = (): InventoryItemInterface[] => {
    const itemsValues: any = [...(inventoryDetails?.items || [])];
    let selectedInputValues;
    if (!activeTab) {
      selectedInputValues = itemsValues?.reduce((acc, current, index) => {
        if (current?.isGeneral) {
          acc?.push(current);
        }
        return acc;
      }, []);
    } else {
      selectedInputValues = itemsValues?.reduce((acc, current, index) => {
        if (
          current?.roomID === roomID &&
          parseInt(current?.roomSerialNumber) === parseInt(roomSerialNumber) - 1
        ) {
          acc?.push(current);
        }
        return acc;
      }, []);
    }

    return selectedInputValues;
  };

  const selectedRoomValues = getSelectedRoomValues();

  const BasicDetails = {
    Property: `(${inventoryDetails?.property?.propertyID}) ${inventoryDetails?.property?.address?.postalCode} ${inventoryDetails?.property?.address?.address1}`,
    Unit: `(${inventoryDetails?.unit?.unitID}) ${inventoryDetails?.unit?.name}`,
    Tenant: `(${inventoryDetails?.tenant?.userID || "-"}) ${getStaffFullName(
      inventoryDetails?.tenant?.name
    )}`,
    "Inventory Status": `${inventoryDetails?.inventoryStatus?.label}`,
    "Inspection Date": `${getDateHandler({
      date: inventoryDetails?.inspectionDate,
    })}`,
  };
  let ActiveTabComponent;

  if (!activeTab) {
    ActiveTabComponent = (
      <div className={"flex flex-col gap-10"}>
        <div
          className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1  gap-5"}
        >
          <div className={"col-span-3"}>
            <NameValueText
              alignDirection={"col"}
              name={"Overall comments"}
              value={inventoryDetails?.overallComments}
            />
          </div>
        </div>
        <div
          className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1  gap-5"}
        >
          <div className={"col-span-1"}>
            {inventoryDetails?.documents?.map((e, key) => {
              return <FileViewCard key={key} filePath={e} canDownload />;
            })}
          </div>
        </div>
      </div>
    );
  } else if (activeTab === "signature") {
    ActiveTabComponent = (
      <div className={"flex flex-col gap-10"}>
        <FormWrapper
          header={{
            title: {
              name: "Owner",
            },
          }}
        >
          <div
            className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              alignDirection={"col"}
              name={"Print Name"}
              value={
                typeof inventoryDetails?.ownerSignature?.participant !==
                  "string" &&
                getStaffFullName(
                  inventoryDetails?.ownerSignature?.participant?.name
                )
              }
            />
            {inventoryDetails?.ownerSignature?.signature && (
              <div className={"flex flex-col gap-2"}>
                <NameValueText name={"Image"} value={""} />
                <FileViewCard
                  canDownload
                  filePath={inventoryDetails?.ownerSignature?.signature}
                />
              </div>
            )}
            <NameValueText
              alignDirection={"col"}
              name={"Signed Date"}
              value={getDateHandler({
                date: inventoryDetails?.ownerSignature?.signedDate,
              })}
            />
          </div>
        </FormWrapper>
        <FormWrapper
          header={{
            title: {
              name: "Tenant",
            },
          }}
        >
          <div
            className={"grid grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-5"}
          >
            <NameValueText
              name={"Signed Date"}
              alignDirection={"col"}
              value={
                typeof inventoryDetails?.tenantSignature?.participant !==
                  "string" &&
                getStaffFullName(
                  inventoryDetails?.tenantSignature?.participant?.name
                )
              }
            />
            {inventoryDetails?.tenantSignature?.signature && (
              <div className={"flex flex-col gap-2"}>
                <NameValueText name={"Image"} value={""} />
                <FileViewCard
                  canDownload
                  filePath={inventoryDetails?.tenantSignature?.signature}
                />
              </div>
            )}

            <NameValueText
              name={"Signed Date"}
              alignDirection={"col"}
              value={getDateHandler({
                date: inventoryDetails?.tenantSignature?.signedDate,
              })}
            />
          </div>
        </FormWrapper>
      </div>
    );
  }
  return (
    <PageTemplate
      scrollAll
      title={"Inventory"}
      backNavigation={{
        title: "Inventory Details",
        path: PageLinks.inventory.list,
      }}
      breadCrumb={[
        {
          name: "Inventory",
        },
      ]}
    >
      <div className={"flex flex-col gap-10"}>
        <div className={"grid grid-cols-5 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
          {Object.keys(BasicDetails)?.map((e, key) => {
            return (
              <NameValueText
                alignDirection={"col"}
                isLoading={isDetailsLoading}
                name={e}
                value={BasicDetails[e]}
                key={key}
              />
            );
          })}
        </div>
        {inventoryDetails?._id && (
          <div className={"flex justify-between items-center"}>
            <div>
              <MyButton
                iconType={IconTypeEnum.PDF}
                colorType={"danger"}
                name={"Download Report"}
                onClick={() => {
                  downloadInventoryPDF(inventoryDetails?._id);
                }}
              />
            </div>
            <div>
              <MyButton
                iconType={IconTypeEnum.EDIT}
                name={"Edit Details"}
                onClick={() =>
                  navigateWithReturnUrlHandler(
                    PageLinks.inventory.edit(inventoryDetails?._id)
                  )
                }
              />
            </div>
          </div>
        )}

        <TabComponent tabs={tabs} />
        {activeTab !== "signature" && !isDetailsLoading && (
          <>
            {selectedRoomValues?.length > 0 ? (
              selectedRoomValues?.map((e) => {
                return (
                  <ItemCard
                    inventoryDetails={inventoryDetails}
                    details={e}
                    key={e?._id}
                  />
                );
              })
            ) : (
              <div className={"mt-10"}>
                <EmptyMessageComponent message={"No inventory items"} />
              </div>
            )}
          </>
        )}
        {ActiveTabComponent}
      </div>
    </PageTemplate>
  );
}

const ItemCard = ({
  details,
  inventoryDetails,
}: {
  details: InventoryItemInterface;
  inventoryDetails: InventoryInterface;
}) => {
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const repairAndMaintenanceHandler = () => {
    let path = PageLinks.maintenance.new;
    if (details?.roomID) {
      path =
        PageLinks.maintenance.new +
        `/roomSerialNumber=${details?.roomSerialNumber + 1}&roomID=${
          details?.roomID
        }`;
    }
    navigateWithReturnUrlHandler(
      path,
      `${QueryStringEnum.ACTIVE_TAB}=roomSerialNumber=${
        details?.roomSerialNumber + 1
      }&roomID=${details?.roomID}&${QueryStringEnum.ITEM_ID}=${details._id}&${
        QueryStringEnum.PROPERTY_ID
      }=${inventoryDetails?.property?._id}&${QueryStringEnum.UNIT_ID}=${
        inventoryDetails?.unit?._id
      }`,
      true
    );
  };
  return (
    <div className={"flex flex-col gap-5 border-b-[1px] pb-4"}>
      <div
        className={
          "grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1  gap-5 items-start"
        }
      >
        <NameValueText
          alignDirection={"col"}
          name={"Items"}
          value={details?.item?.label || "-"}
        />{" "}
        <NameValueText
          alignDirection={"col"}
          name={"Quantity"}
          value={details?.unitQuantity || "-"}
        />{" "}
        <NameValueText
          alignDirection={"col"}
          name={"Unit"}
          value={details?.unit?.label || "-"}
        />{" "}
        <NameValueText
          alignDirection={"col"}
          name={"Condition"}
          value={details?.inventoryItemCondition?.label || "-"}
        />
        <NameValueText
          alignDirection={"col"}
          name={"Notes"}
          value={details?.notes || "-"}
        />
      </div>
      <div className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1 gap-5"}>
        {details?.images?.length > 0 && (
          <div className={"flex flex-col gap-2"}>
            <NameValueText name={"Images"} value={""} />
            <div className={"flex items-start gap-5"}>
              {details?.images?.map((e, key) => {
                return <FileViewCard canDownload key={key} filePath={e} />;
              })}
            </div>
          </div>
        )}
        <div className={"mt-8 flex items-start gap-5"}>
          <MyButton
            onClick={repairAndMaintenanceHandler}
            name={"Repair & Maintenance"}
            isOutline
            iconType={IconTypeEnum.ADD}
          />
        </div>
      </div>
    </div>
  );
};

export default InventoryDetailsPage;
