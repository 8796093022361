import React from "react";
import { IconTypeEnum } from "interfaces";
import { getIconHelper } from "helpers";
interface PropsInterface {
  color?: "blue" | "green" | "purple" | "red";
  iconType: IconTypeEnum;
  isRounded?: boolean;
}
function IconComponent({ iconType, color, isRounded = true }: PropsInterface) {
  const Icon = getIconHelper(iconType);
  const getColorHandler = () => {
    let iconColor, bgColor;
    switch (color) {
      case "purple":
        bgColor = "bg-purple-200";
        iconColor = "text-purple-500";
        break;
      case "red":
        bgColor = "bg-red-100";
        iconColor = "text-tRed fill-tRed";
        break;
      case "blue":
        bgColor = "bg-blue-100";
        iconColor = "text-tBlue fill-tBlue";
        break;
      case "green":
        bgColor = "bg-green-100";
        iconColor = "text-green-500 fill-green-500";
        break;
      default:
        bgColor = "";
        iconColor = "";
    }
    return { iconColor, bgColor };
  };
  const { iconColor, bgColor } = getColorHandler();
  return (
    <div
      className={`w-[40px] h-[40px] ${
        isRounded ? "rounded-full" : "rounded-md"
      }  flex items-center justify-center ${bgColor}`}
    >
      <Icon className={`${iconColor} h-[18px] w-[18px]`} />
    </div>
  );
}

export default IconComponent;
