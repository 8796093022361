import React, { useState } from "react";
import { ImageViewCard } from "components";

import { UserTableProfileInterface } from "./interfaces";
import getIconHelpers from "../../helpers/utils/getIconHelpers";

function UserTableProfile({
  details,
  firstLetter,
  onClick,
  badgeIconType,
}: UserTableProfileInterface) {
  const BadgeIcon = getIconHelpers(badgeIconType);
  const [imageError, setImageError] = useState(false);
  return (
    <div
      className={"flex items-center gap-2"}
      onClick={onClick ? onClick : undefined}
    >
      <div className={"relative"}>
        {details?.profile && !imageError ? (
          <ImageViewCard
            onErrorHandler={() => {
              setImageError(true);
            }}
            altText={details?.imageAltText}
            staticPath={details?.isStaticPath}
            fileName={details?.profile}
            containerStyle={"w-[40px] h-[40px] rounded-full"}
          />
        ) : (
          <div
            className={
              "flex items-center justify-center w-[35px] h-[35px] rounded-full bg-primary text-white font-bold uppercase"
            }
          >
            {firstLetter || details?.name?.charAt(0)}
          </div>
        )}
        {badgeIconType && BadgeIcon && (
          <div
            className={
              "absolute bottom-[-5px] right-[-5px]   text-green-500 text-green-500 bg-white rounded-full text-[18px] shadow-md"
            }
          >
            <BadgeIcon />
          </div>
        )}
      </div>

      <div className={"flex flex-col"}>
        <span className={"text-tBlue text-[16px]"}>{details?.name}</span>
        <span className={"text-[#00040e] text-[14px]"}>
          {details?.subTitle}
        </span>
      </div>
    </div>
  );
}

export default UserTableProfile;
