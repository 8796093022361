import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthorizationHook, useDateHook } from "useHooks";
import { IconTypeEnum, SpaceAccessListEnum } from "interfaces";
import { PropertyDataContext } from "context";
import { LeftRightHeader, NameValueText } from "assets";
import { FileViewCard, MyButton, MyCheckButton } from "components";

function DocumentsDetails() {
  const navigate = useNavigate();
  const { details, isDetailsLoading } = useContext(PropertyDataContext);
  const { getDateHandler } = useDateHook();
  const documents = details?.documents;

  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_EDIT_PROPERTY,
    SpaceAccessListEnum.RENTAL_ADD_PROPERTY,
  ]);
  return (
    <div>
      <LeftRightHeader
        title={"Documents"}
        rightChildren={
          canEdit && (
            <MyButton
              isOutline
              onClick={() => navigate("editDocuments")}
              iconType={IconTypeEnum.EDIT}
              name={"Edit Document"}
            />
          )
        }
      />
      <div className={"flex flex-col gap-5 mt-5"}>
        <NameValueText
          isLoading={isDetailsLoading}
          name={"Type"}
          value={documents?.documentType?.label || "-"}
        />
        <div className={"grid grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-5"}>
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Date of Issue"}
            value={
              documents?.dateOfIssue
                ? getDateHandler({ date: documents?.dateOfIssue })
                : "-"
            }
          />{" "}
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Date of Expiry"}
            value={
              documents?.dateOfExpiry
                ? getDateHandler({ date: documents?.dateOfExpiry })
                : "-"
            }
          />
        </div>
        <div className={"grid grid-cols-5 gap-5 md:grid-cols-3 sm:grid-cols-1"}>
          {documents?.documents?.map((e, key) => {
            return <FileViewCard filePath={e} canDownload key={key} />;
          })}
        </div>
        <div>
          <NameValueText
            isLoading={isDetailsLoading}
            name={"Description"}
            value={documents?.description || "-"}
          />
        </div>
        <MyCheckButton
          label={"Share the document with your tenant"}
          isChecked={documents?.sharedToTenant}
        />
      </div>
    </div>
  );
}

export default DocumentsDetails;
