import React, { useContext, useMemo, useState } from "react";
import { FastField, FieldArray, useFormikContext } from "formik";
import { TableVirtuoso } from "react-virtuoso";

import {
  MyButton,
  MyIconButton,
  MySelectField,
  MyTextAreaInput,
  MyTextField,
  MyUploadDocument,
} from "components";
import {
  decodeLedgerNameOptionsHandler,
  decodePropertyOptionsHandler,
  decodeUnitOptionsHandler,
  getCurrency,
} from "helpers";
import {
  IconTypeEnum,
  JournalInterface,
  StaticLedgerNameEnum,
} from "interfaces";
import { OptionsContext } from "context";
import { calculateJournalTotalAccount, JournalFormikForm } from "../../helpers";
import ModalComponent from "components/modal/BoxModal";
import { FormConcludeButtons } from "assets";

function JournalForm({ isOpeningEntries, isLoading }) {
  const { values, setFieldValue } =
    useFormikContext<JournalInterface<string>>();
  const {
    ledgerNameOptions,
    propertyOptions,
    unitOptions,
    getUnitOptionsHandler,
  } = useContext(OptionsContext);
  const entries = useMemo(() => {
    return values?.entries;
  }, [values?.entries]);

  const property = useMemo(() => {
    return propertyOptions;
  }, [propertyOptions]);

  const { debitSubTotal, creditSubTotal } =
    calculateJournalTotalAccount(values);
  return (
    <div
      className={"flex flex-col gap-10 border-t-[1px] mt-2 pt-10 text-[14px]"}
    >
      <div
        className={
          "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start gap-5 border-b-[1px] mb-2 pb-5"
        }
      >
        {!isOpeningEntries && (
          <>
            <MySelectField
              label={"Property"}
              autoFocus
              option={{
                selectOptions: decodePropertyOptionsHandler(property),
                defaultLabel: "Property",
                onChangeCallback(currentValue?: any) {
                  setFieldValue("unit", "");
                },
              }}
              isClearable
              name={"property"}
            />
            <MySelectField
              label={"Unit"}
              disabled={!values?.property}
              fetchOptionsHandler={() =>
                getUnitOptionsHandler(values?.property)
              }
              isClearable
              option={{
                selectOptions: decodeUnitOptionsHandler(unitOptions),
                defaultLabel: "Unit",
              }}
              name={"unit"}
            />
          </>
        )}

        <MyTextField label={"Date"} type={"date"} name={"transactionDate"} />
      </div>
      {!isLoading && (
        <FieldArray
          name={"entries"}
          render={({ remove, insert }) => {
            return (
              <TableVirtuoso
                style={{
                  height: values?.entries?.length > 3 ? 400 : 215,
                  width: "full",
                }}
                data={entries}
                fixedHeaderContent={() => (
                  <>
                    {!isOpeningEntries && (
                      <th
                        className={"border-b-[1px]"}
                        style={{
                          background: "white",
                          textAlign: "left",
                          width: "full",
                        }}
                      >
                        Particular
                      </th>
                    )}

                    <th
                      className={"border-b-[1px]"}
                      style={{
                        background: "white",
                        textAlign: "left",
                        width: "full",
                      }}
                    >
                      Account
                    </th>
                    <th
                      className={"border-b-[1px]"}
                      style={{
                        background: "white",
                        textAlign: "left",
                        width: "full",
                      }}
                    >
                      Dr
                    </th>
                    <th
                      className={"border-b-[1px]"}
                      style={{
                        background: "white",
                        textAlign: "left",
                        width: "full",
                      }}
                    >
                      CR
                    </th>
                    {!isOpeningEntries && (
                      <th
                        className={"border-b-[1px]"}
                        style={{ background: "white", textAlign: "left" }}
                      ></th>
                    )}
                  </>
                )}
                itemContent={(index) => (
                  <BillItemsFormValues
                    insertHandler={insert}
                    removeHandler={remove}
                    index={index}
                    key={index}
                    setFieldValue={setFieldValue}
                    ledgerNameOption={ledgerNameOptions}
                    isOpeningEntry={isOpeningEntries}
                  />
                )}
              />
            );
          }}
        />
      )}

      <div
        className={
          "grid grid-cols-3 md:grid-cols-4 sm:grid-cols-1  gap-5 border-b-[1px] pb-5"
        }
      >
        <div
          className={
            "col-span-2 grid grid-cols-2 md:grid-cols-1 sm:grid-cols-1"
          }
        >
          <div className={"flex flex-col gap-5"}>
            {!isOpeningEntries && (
              <div
                className={
                  "col-span-1 md:col-span-2 sm:col-span-2 flex flex-col gap-10 md:gap-5 sm:gap-5"
                }
              >
                <MyUploadDocument
                  fileType={"image"}
                  label={"Documents"}
                  name={"documents"}
                  fileContainerStyle={"grid grid-cols-2 gap-5"}
                />
                <NoteModal />
              </div>
            )}
          </div>
        </div>
        {/*bills*/}
        <div className={"flex flex-col md:col-span-2 gap-5 text-[14px]"}>
          <div className={"grid grid-cols-4 gap-5"}>
            <span className={"col-span-2"}>SubTotal</span>
            <span>
              {getCurrency()}{""}
              {parseFloat(String(debitSubTotal || 0))?.toFixed(2)}
            </span>
            <span>
              {getCurrency()}{""}
              {parseFloat(String(creditSubTotal || 0))?.toFixed(2)}
            </span>
          </div>
          <div
            className={
              "grid text-[16px] grid-cols-4 pt-5 border-t-[1px]  font-bold "
            }
          >
            <span className={"col-span-2"}>Total Amount</span>
            <span className={"text-tBlue"}>
              {getCurrency()}{""}
              {parseFloat(String(debitSubTotal || 0))?.toFixed(2)}
            </span>
            <span className={"text-tBlue"}>
              {getCurrency()}{""}
              {parseFloat(String(creditSubTotal || 0))?.toFixed(2)}
            </span>
          </div>
          {parseFloat(String(creditSubTotal || 0))?.toFixed(2) !==
            parseFloat(String(debitSubTotal || 0))?.toFixed(2) && (
            <span className={"input_textError text-[14px]"}>
              *
              {creditSubTotal > debitSubTotal
                ? `Credit surplus by ${getCurrency()}${parseFloat(
                    String(creditSubTotal - debitSubTotal)
                  )?.toFixed(2)}`
                : `Debit surplus by ${getCurrency()}${parseFloat(
                    String(debitSubTotal - creditSubTotal)
                  )?.toFixed(2)}`}
            </span>
          )}
        </div>
      </div>
      {!isOpeningEntries && (
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 pb-2 "}>
          <div className={"col-span-2 "}>
            <MyTextAreaInput isRequired name={"name"} label={"Narration"} />
          </div>
        </div>
      )}
    </div>
  );
}

function BillItemsFormValues({
  removeHandler,
  insertHandler,
  ledgerNameOption,
  setFieldValue,
  isOpeningEntry,
  index,
}) {
  return (
    <>
      {!isOpeningEntry && (
        <td className={"w-[400px] px-2 py-3 border-b-[1px] items-start"}>
          <MyTextAreaInput
            placeholder={"Enter Particular"}
            className={" rounded-[5px]  w-[400px] "}
            name={`entries.${index}.name`}
            rows={1}
          />
        </td>
      )}
      <td className={"w-full px-2 py-3 border-b-[1px] items-start"}>
        <FastField
          name={`entries.${index}.account`}
          component={(props) => (
            <MySelectField
              {...props}
              name={`entries.${index}.account`}
              disabled={isOpeningEntry}
              style={{
                borderRadius: "5px",
                minWidth: "300px",
              }}
              option={{
                selectOptions: decodeLedgerNameOptionsHandler(
                  ledgerNameOption,
                  isOpeningEntry
                    ? []
                    : [
                        StaticLedgerNameEnum.AccountReceivable,
                        StaticLedgerNameEnum.AccountPayable,
                      ]
                ),
              }}
            />
          )}
        />
      </td>
      <td className={"w-full px-2 py-3 border-b-[1px] items-start"}>
        <MyTextField
          type={"number"}
          onChangeCallbackHandler={() => {
            setFieldValue(`entries.${index}.credit`, "");
          }}
          placeholder={`${getCurrency()}00.00`}
          className={" rounded-md pl-1"}
          name={`entries.${index}.debit`}
        />
      </td>
      <td className={"w-full px-2 py-3 border-b-[1px] items-start"}>
        <MyTextField
          type={"number"}
          onChangeCallbackHandler={() => {
            setFieldValue(`entries.${index}.debit`, "");
          }}
          placeholder={`${getCurrency()}00.00`}
          className={" rounded-md pl-1"}
          name={`entries.${index}.credit`}
        />
      </td>
      {!isOpeningEntry && (
        <td className={"w-full px-2 py-3 border-b-[1px] items-start"}>
          <div className={"flex gap-2 items-center"}>
            <MyIconButton
              IconType={IconTypeEnum.ADD}
              onClick={() =>
                insertHandler(index + 1, JournalFormikForm.entries())
              }
              iconColor={"text-primary"}
              containerStyle={"border-primary border-[1px] p-1"}
            />
            {index !== 0 && (
              <MyIconButton
                IconType={IconTypeEnum.MINUS}
                onClick={() => removeHandler(index)}
                iconColor={"text-tRed"}
                containerStyle={"border-tRed border-[1px] p-1"}
              />
            )}
          </div>
        </td>
      )}
    </>
  );
}
function NoteModal() {
  const [isOpen, setOpen] = useState(false);
  const { values } = useFormikContext<JournalInterface<number>>();
  if (isOpen) {
    return (
      <ModalComponent title={"Remarks"} closeHandler={() => setOpen(false)}>
        <div className={"flex flex-col gap-10"}>
          <MyTextAreaInput label={"Remark/Note"} name={"notes"} />
          <FormConcludeButtons
            isInModal
            submitButton={{
              title: "Save",
              notSubmit: true,
              handler() {
                setOpen(false);
              },
            }}
            cancelButton={{
              title: "Cancel",
              handler() {
                setOpen(false);
              },
            }}
          />
        </div>
      </ModalComponent>
    );
  }
  return (
    <div>
      <MyButton
        onClick={() => setOpen(true)}
        isOutline
        name={values?.notes ? "Edit Note" : "Add Note"}
        type={"button"}
        iconType={IconTypeEnum.FILE}
      />
    </div>
  );
}

export default JournalForm;
