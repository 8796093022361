import React from "react";
import { Route } from "react-router-dom";

import { ParamsEnum } from "./interfaces";
import { DataContextProvider } from "./context";

import {
  EditItemsPage,
  EditLedgerNamePage,
  EditSubLedgerNamePage,
  LedgerNamePage,
  NameOptionsPage,
  NamePage,
  SubLedgerNamePage,
  SystemDynamicPage,
  SystemEditItemsPage,
  SystemPage,
  PrivilegePage,
  PrivilegeDetailsPage,
  EditPrivilegePage,
} from "./pages";
import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="setup/" element={<DataContextProvider />}>
      {/*  dynamic-list*/}
      <Route path={"dynamic-lists"} element={<NamePage />} />
      <Route path={"dynamic-lists/:p1"} element={<NamePage />} />
      <Route path={"dynamic-lists/:p1/:p2"} element={<NamePage />} />
      <Route path={"dynamic-lists/:p1/:p2/:p3"} element={<NamePage />} />
      <Route
        path={`dynamic-lists/options/:${ParamsStringEnum.TYPE}`}
        element={<NameOptionsPage />}
      />
      <Route
        path={`dynamic-lists/new/:${ParamsStringEnum.TYPE}`}
        element={
          <AccessVerifier
            accessKeys={[
              SpaceAccessListEnum.CONFIGURATION_ADD_ADMINISTRATIVE_SETUP,
            ]}
            isForPage={true}
          >
            <EditItemsPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`dynamic-lists/edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[
              SpaceAccessListEnum.CONFIGURATION_EDIT_ADMINISTRATIVE_SETUP,
            ]}
            isForPage={true}
          >
            <EditItemsPage />
          </AccessVerifier>
        }
      />

      {/*  System*/}
      <Route path={"system/"} element={<SystemPage />} />
      <Route
        path={`system/:${ParamsEnum?.SYSTEM}`}
        element={<SystemDynamicPage />}
      />
      <Route
        path={`system/:${ParamsEnum?.SYSTEM}/edit`}
        element={
          <AccessVerifier
            accessKeys={[
              SpaceAccessListEnum.CONFIGURATION_EDIT_ADMINISTRATIVE_SETUP,
            ]}
            isForPage={true}
          >
            <SystemEditItemsPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`roles/`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.AUTH_READ_ROLE]}
            isForPage
          >
            <PrivilegePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`roles/create`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.AUTH_ADD_ROLE]}
            isForPage
          >
            <EditPrivilegePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`roles/edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.AUTH_ADD_ROLE]}
            isForPage
          >
            <EditPrivilegePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`roles/details/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.AUTH_READ_ROLE]}
            isForPage
          >
            <PrivilegeDetailsPage />
          </AccessVerifier>
        }
      />

      {/*    ledger name*/}
      <Route
        path={`ledgerName/`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.ACCOUNT_READ_LEDGER_NAME]}
            isForPage
          >
            <LedgerNamePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`ledgerName/new`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.ACCOUNT_ADD_LEDGER_NAME]}
            isForPage
          >
            <EditLedgerNamePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`ledgerName/edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.ACCOUNT_EDIT_LEDGER_NAME]}
            isForPage
          >
            <EditLedgerNamePage />
          </AccessVerifier>
        }
      />
      {/*    sub-ledger name*/}
      <Route
        path={`subLedgerName/`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.ACCOUNT_ACCESS_SUB_LEDGER]}
            isForPage
          >
            <SubLedgerNamePage />
          </AccessVerifier>
        }
      />
      <Route
        path={`subLedgerName/edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.ACCOUNT_ACCESS_SUB_LEDGER]}
            isForPage
          >
            <EditSubLedgerNamePage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
