import React from "react";
import { Route } from "react-router-dom";

import {
  ManageNotificationPage,
  EditNotificationPage,
  EditSpaceSettingPage,
  TrueLayerSettingsPage,
  TwilioSettingsPage,
  RetainEarningSetupPage,
  EditBravoSettingPage,
} from "./pages";
import { EditTrueLayerConfigModal, EditTwilioConfigModal } from "./modals";
import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import "./styles.css";
import { AccessVerifier } from "templates";
function Index() {
  return (
    <Route path="settings/">
      <Route
        path={"manageNotification"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.CONFIGURATION_MANAGE_NOTIFICATION]}
            isForPage={true}
          >
            <ManageNotificationPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"brevo"}
        element={
          <AccessVerifier
            accessKeys={[
              SpaceAccessListEnum.CONFIGURATION_MANAGE_BREVO_SETTINGS,
            ]}
            isForPage={true}
          >
            <EditBravoSettingPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"space-settings"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.CONFIGURATION_MANAGE_SETTINGS]}
            isForPage={true}
          >
            <EditSpaceSettingPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"retain-earning"}
        element={
          <AccessVerifier
            accessKeys={[
              SpaceAccessListEnum.CONFIGURATION_ACCESS_RETAIN_EARNING,
            ]}
            isForPage={true}
          >
            <RetainEarningSetupPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`manageNotification/edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.CONFIGURATION_MANAGE_NOTIFICATION]}
            isForPage={true}
          >
            <EditNotificationPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"true-layer"}
        element={
          <AccessVerifier
            accessKeys={[
              SpaceAccessListEnum.CONFIGURATION_MANAGE_TRUE_LAYER_SETTINGS,
            ]}
            isForPage={true}
          >
            <TrueLayerSettingsPage />
          </AccessVerifier>
        }
      >
        <Route
          path={`edit`}
          element={
            <AccessVerifier
              accessKeys={[
                SpaceAccessListEnum.CONFIGURATION_MANAGE_TRUE_LAYER_SETTINGS,
              ]}
              isForPage={true}
            >
              <EditTrueLayerConfigModal />
            </AccessVerifier>
          }
        />
      </Route>
      <Route
        path={"twilio"}
        element={
          <AccessVerifier
            accessKeys={[
              SpaceAccessListEnum.CONFIGURATION_MANAGE_TWILIO_SETTINGS,
            ]}
            isForPage={true}
          >
            <TwilioSettingsPage />
          </AccessVerifier>
        }
      >
        <Route
          path={`edit`}
          element={
            <AccessVerifier
              accessKeys={[
                SpaceAccessListEnum.CONFIGURATION_MANAGE_TWILIO_SETTINGS,
              ]}
              isForPage={true}
            >
              <EditTwilioConfigModal />
            </AccessVerifier>
          }
        />
      </Route>
    </Route>
  );
}

export default Index;
