import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { MyButton, MyIconButton, TableCardComponent } from "components";
import { PageTemplate } from "templates";
import { FieldTypeEnum, IconTypeEnum, QueryStringEnum } from "interfaces";
import { TableFilterCard } from "content";

import { TrashTableColumns } from "../../components";
import { AppContext, TrashContext } from "context";
import { usePaginationQueryHooks } from "useHooks";
import { getIconHelper } from "../../../../helpers";

function TrashPage() {
  const {
    state: { spaceSettings },
  } = useContext(AppContext);
  const CloseIcon = getIconHelper(IconTypeEnum.CLOSE);
  const [isAlertOpen, setAlertOpen] = useState(true);
  const {
    allData,
    getAllDataHandlers,
    totalDocs,
    isLoading,
    emptyTrashHandler,
  } = useContext(TrashContext);
  const [query] = useSearchParams();
  const { currentPage } = usePaginationQueryHooks();
  const unitId = query.get(QueryStringEnum?.UNIT_ID);
  const propertyId = query.get(QueryStringEnum?.PROPERTY_ID);
  const pageQuery = {
    unit: unitId,
    property: propertyId,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [unitId, propertyId, currentPage]);

  const tableCardProps = {
    column: TrashTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      rightChildren: (
        <MyButton
          onClick={() => {
            emptyTrashHandler({
              async onSuccess(payload?: any) {
                await getAllDataHandlers(pageQuery);
              },
            });
          }}
          iconType={IconTypeEnum.DELETE}
          colorType={"danger"}
          name={"Empty Trash"}
        />
      ),
    },
  };
  return (
    <PageTemplate
      title={"Trash"}
      breadCrumb={[
        {
          name: "Trash",
        },
      ]}
    >
      <div className={"flex flex-col gap-10"}>
        {isAlertOpen && (
          <div
            className={
              "flex justify-between items-center bg-[#f6f6f7] rounded-xl p-5 gap-5 cursor-pointer"
            }
          >
            <div className={"flex items-center gap-5"}>
              <MyIconButton
                iconColor={"text-[#f0c000]"}
                iconSize={30}
                containerStyle={"bg-white p-5 text-[22px]"}
                IconType={IconTypeEnum.ALERT}
              />
              <div className={"flex flex-col gap-2"}>
                <b className={"text-[#f0c000]"}>Note:</b>
                <p className={"text-[14px]"}>
                  After {spaceSettings?.autoTrashDeleteDay || 0} days, the files
                  will be permanently deleted.
                </p>
              </div>
            </div>
            <CloseIcon
              className={"text-gray-500 text-[28px]"}
              onClick={() => setAlertOpen(false)}
            />
          </div>
        )}

        <TableCardComponent
          havePagination={{
            totalDocs: totalDocs,
          }}
          {...tableCardProps}
        />
      </div>
    </PageTemplate>
  );
}

export default TrashPage;
