import React, { useContext, useEffect } from "react";
import { MyButton, TableCardComponent } from "components";
import { UserContext } from "context";
import { useParams } from "react-router-dom";
import {
  IconTypeEnum,
  ParamsStringEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
  UserRoleEnum,
} from "interfaces";
import {
  useAuthorizationHook,
  usePageQueryHooks,
  usePaginationQueryHooks,
} from "useHooks";
import { decodeRoleNameHandler } from "helpers";
import { UserTableColumn } from "content";
import { PageLinks } from "routes";
import { FormWrapper } from "assets";

function Tenants() {
  const {
    allData,
    isLoading,
    totalDocs,
    totalArchiveDocs,
    getAllDataHandlers,
  } = useContext(UserContext);
  const params = useParams<ParamsStringEnum>();
  const { isArchive } = usePaginationQueryHooks();
  const userType: any = params.userType;
  const propertyID = params.propertyId;
  const decodedName = decodeRoleNameHandler(userType);

  let pageQuery = {
    propertyID,
    ...(userType !== "none"
      ? {
          userType: UserRoleEnum.TENANT,
        }
      : {}),
  };
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [propertyID, userType]);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAdd = checkCanAccessHandler([SpaceAccessListEnum.AUTH_ADD_USER]);
  const tableCardProps = {
    column: UserTableColumn(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
  };
  return (
    <>
      <FormWrapper
        header={{
          title: { name: "List of Tenants" },
          rightChildren: canAdd && (
            <div>
              <MyButton
                onClick={() => {
                  navigateWithReturnUrlHandler(
                    PageLinks.spaceUser.new,
                    `${QueryStringEnum?.TYPE}=${UserRoleEnum.TENANT}`
                  );
                }}
                iconType={IconTypeEnum.ADD}
                name={`New ${decodedName}`}
              />
            </div>
          ),
        }}
      >
        <TableCardComponent
          {...tableCardProps}
          havePagination={{
            totalDocs: isArchive ? totalArchiveDocs : totalDocs,
          }}
        />
      </FormWrapper>
    </>
  );
}

export default Tenants;
