import React, { useContext, useEffect } from "react";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { FormConcludeButtons } from "assets";
import { PageTemplate } from "templates";
import { ParamsStringEnum } from "interfaces";
import { AppContext, BankContext } from "context";

import { BankFormikValues } from "../helpers";
import { BankForm } from "../components";
import { usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function NewBankPage() {
  const navigate = useNavigate();
  const params = useParams<ParamsStringEnum>();
  const { returnURL } = usePageQueryHooks();
  const {
    handlers: { setLoading },
  } = useContext(AppContext);

  const { details, editDetailsHandler, getDetailsHandler } =
    useContext(BankContext);
  const isEdit = params.id;
  useEffect(() => {
    (async () => {
      setLoading(true);
      if (isEdit) {
        await getDetailsHandler(isEdit);
      }
      setLoading(false);
    })();
  }, [isEdit]);

  const submitHandler = async (values, formikHelpers) => {
    await editDetailsHandler(values, formikHelpers, {
      onSuccess: async (payload) => {
        if (returnURL) {
          navigate(returnURL);
        } else {
          navigate(PageLinks.banks.list);
        }
      },
      onError(error?: any) {},
    });
  };
  return (
    <PageTemplate
      title={"Bank"}
      scrollAll
      breadCrumb={[
        {
          name: `${isEdit ? "Edit" : "New"} Bank`,
        },
      ]}
      backNavigation={{
        title: `${isEdit ? "Edit" : "New"} Bank`,
        path: PageLinks.banks.list,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={BankFormikValues?.initialValues(isEdit && details)}
        validationSchema={BankFormikValues?.validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting, errors }) => {
          return (
            <Form className={"flex flex-col gap-5"}>
              <BankForm />
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default NewBankPage;
