import React, { useContext, useEffect } from "react";

import { useFormikContext } from "formik";
import Skeleton from "react-loading-skeleton";

import {
  PackageCreateOrderPayloadInterface,
  PackagePurchaseType,
  TaxTypeEnum,
} from "interfaces";
import { getCurrency, getTaxNameHandler, separateWithComma } from "helpers";

import { SubscriptionContext } from "../context";
import { useDateHook } from "useHooks";
import { SubscriptionChildPagePropsInterface } from "../interfaces";

function BillingDetailsContainer({
  getPackageStatusHandler,
}: SubscriptionChildPagePropsInterface) {
  const {
    isDetailsLoading,
    validateOrderDetails,
    contextHandlers: {
      validateAndCalculateOrderHandler,
      resetValidateOrderDetails,
    },
  } = useContext(SubscriptionContext);
  const { getDateHandler } = useDateHook();
  const { values } = useFormikContext<PackageCreateOrderPayloadInterface>();
  const isPurchaseTypeProperty =
    values?.purchaseType === PackagePurchaseType.PROPERTY;
  useEffect(() => {
    (async () => {
      if (values?.subscription) {
        const { canSelect, minExtraProperty } = getPackageStatusHandler(
          values?.subscription,
          values
        );
        let temp = { ...values };
        if (minExtraProperty !== values?.propertyQuantity) {
          temp = {
            ...temp,
            propertyQuantity: Math.max(
              minExtraProperty,
              values?.propertyQuantity
            ),
          };
        }
        if (canSelect) {
          await validateAndCalculateOrderHandler(temp);
        } else {
          resetValidateOrderDetails();
        }
      }
    })();
  }, [
    values?.subscription,
    values?.propertyQuantity,
    values?.duration,
    values?.timeUnit,
  ]);
  return (
    <div
      className={
        "flex flex-col gap-4 border-t-4 border-dashed pt-4 text-[16px]"
      }
    >
      {!isPurchaseTypeProperty && (
        <>
          <div className={"flex justify-between items-center"}>
            <span>Subscription Cost </span>
            {isDetailsLoading ? (
              <Skeleton className={"w-[100px]"} />
            ) : (
              <span className={"font-semibold"}>
                {getCurrency()}
                {separateWithComma(validateOrderDetails?.packageCost?.subTotal)}
              </span>
            )}
          </div>
          {validateOrderDetails?.packageCost?.discountPercent > 0 && (
            <div className={"flex justify-between items-center"}>
              <span>
                Discount ({validateOrderDetails?.packageCost?.discountPercent}%)
              </span>

              {isDetailsLoading ? (
                <Skeleton className={"w-[100px]"} />
              ) : (
                <span className={"text-tGreen font-semibold"}>
                  {/*{getCurrency()}*/}-
                  {separateWithComma(
                    validateOrderDetails?.packageCost?.discount
                  )}
                </span>
              )}
            </div>
          )}
          {validateOrderDetails?.packageCost?.tax > 0 &&
            validateOrderDetails?.packageCost?.taxType !==
              TaxTypeEnum.EXEMPTED && (
              <div className={"flex justify-between items-center"}>
                <span>
                  {getTaxNameHandler(
                    validateOrderDetails?.packageCost?.taxType
                  )}{" "}
                  ({validateOrderDetails?.packageCost?.taxRate}%)
                </span>
                {isDetailsLoading ? (
                  <Skeleton className={"w-[100px]"} />
                ) : (
                  <span className={"font-semibold"}>
                    {getCurrency()}
                    {separateWithComma(validateOrderDetails?.packageCost?.tax)}
                  </span>
                )}
              </div>
            )}
        </>
      )}
      {validateOrderDetails?.propertyPurchaseCount > 0 && (
        <div className={"flex justify-between items-center"}>
          <span>
            {isPurchaseTypeProperty ? "Property Cost" : "Extra Property"} (
            <samp className={"text-[14px]"}>X</samp>
            {validateOrderDetails?.propertyPurchaseCount})
          </span>
          {isDetailsLoading ? (
            <Skeleton className={"w-[100px]"} />
          ) : (
            <span className={"font-semibold"}>
              {getCurrency()}
              {separateWithComma(
                validateOrderDetails?.packageCost?.propertyCost
              )}
            </span>
          )}
        </div>
      )}

      {validateOrderDetails?.payableAmount > 0 &&
        validateOrderDetails?.packageCost?.refundAmount > 0 && (
          <div className={"flex justify-between items-center"}>
            <span>Refund Amount</span>
            {isDetailsLoading ? (
              <Skeleton className={"w-[100px]"} />
            ) : (
              <span className={"font-semibold text-tGreen"}>
                -
                {separateWithComma(
                  validateOrderDetails?.packageCost?.refundAmount
                )}
              </span>
            )}
          </div>
        )}

      <div
        className={
          "flex justify-between items-center border-t-4 border-dashed pt-10"
        }
      >
        <span>Total</span>
        {isDetailsLoading ? (
          <Skeleton className={"w-[100px]"} />
        ) : (
          <span className={"font-semibold"}>
            {getCurrency()}
            {separateWithComma(validateOrderDetails?.packageCost?.total)}
          </span>
        )}
      </div>
      {validateOrderDetails?.subscriptionExpiryDate && !isPurchaseTypeProperty && (
        <div className={"text-[14px] text-gray-500 italic"}>
          Subscription Expires on{" "}
          {getDateHandler({
            date: validateOrderDetails?.subscriptionExpiryDate,
          })}
        </div>
      )}
    </div>
  );
}

export default BillingDetailsContainer;
