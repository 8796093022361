import React, { useEffect, useState } from "react";
import { ReconciliationInvoiceInterface } from "../interfaces";
import { BoxModal } from "components";
import { FormConcludeButtons } from "assets";
import { getCurrency } from "helpers";
interface PropsInterface {
  invoice: ReconciliationInvoiceInterface;
  splitHandler(splittingAmount, invoice, parentPosKey, callbackFn);
  unSplitHandler(invoice: ReconciliationInvoiceInterface, callbackFn);
  parentKey: number;
}
function SplitModal({
  unSplitHandler,
  splitHandler,
  invoice,
  parentKey,
}: PropsInterface) {
  const [splittingAmount, setSplittingAmount] = useState(null);
  const [error, setError] = useState("");
  const [isOpen, setOpen] = useState(false);
  const isSplit = invoice?.isDuplicate;
  useEffect(() => {
    setSplittingAmount(null);
  }, []);
  if (isOpen) {
    return (
      <BoxModal
        title={"Split Transactions"}
        closeHandler={() => setOpen(false)}
      >
        <div className={"flex flex-col gap-2 text-[14px]"}>
          <div className={"flex items-center justify-between border-b-2 py-2"}>
            <span>Balance</span>
            <span>
              {getCurrency()}{invoice?.due}
            </span>
          </div>
          <div className={"flex items-center justify-between border-b-2 py-2"}>
            <span>Splitting Amount</span>
            <div>
              <input
                value={splittingAmount}
                autoFocus
                style={{
                  borderColor: "#C0C0C0",
                }}
                className={"rounded-md input_style"}
                max={invoice?.due}
                type={"number"}
                placeholder={`${getCurrency()}00.00`}
                onChange={(e) => {
                  let value: any = e?.target?.value;
                  typeof value === "number"
                    ? (value = value)
                    : (value = parseFloat(value));

                  if (value >= invoice?.due || value < 0) {
                    setError(`invalid amount`);
                  } else {
                    setError("");
                  }
                  setSplittingAmount(value);
                }}
              />
              <span className={"input_textError"}>{error}</span>
            </div>
          </div>
          <div className={"flex items-center justify-between py-2"}>
            <span>Remaining Amount</span>
            <span>
              {getCurrency()}{""}
              {(invoice?.due - splittingAmount)?.toFixed(2) || 0}
            </span>
          </div>
          <FormConcludeButtons
            submitButton={{
              title: "Split",
              notSubmit: true,
              handler() {
                if (splittingAmount > invoice?.due || splittingAmount <= 0) {
                  setError("Invalid amount");
                  return;
                }
                splitHandler(splittingAmount, invoice, parentKey, () =>
                  setOpen(false)
                );
              },
            }}
            cancelButton={{
              title: "Cancel",
              handler() {
                setOpen(false);
              },
            }}
          />
        </div>
      </BoxModal>
    );
  }
  return (
    <div>
      <span
        className={"underline text-primary cursor cursor-pointer"}
        onClick={() =>
          isSplit ? unSplitHandler(invoice, () => {}) : setOpen(true)
        }
      >
        {isSplit ? "UnSplit" : "Split"}
      </span>
    </div>
  );
}

export default SplitModal;
