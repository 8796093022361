import React, { useContext, useEffect, useState } from "react";

import { PageTemplate } from "templates";
import { getBalanceSheetTitleHandler } from "helpers";
import { AccountingContext, AppContext } from "context";
import { useDateHook, useGetStartEndDateRangeHooks } from "useHooks";

import { GetContentRowFormat } from "../helpers";
import {
  BalanceSheetIncomeStatementRenderInterface,
  ContentTypeEnum,
} from "../interfaces";
import { BalanceSheetTemplate } from "../components";
import { ToggleComponent, ContentRowComponent } from "../assets";
import { BalanceSheet_IncomeStatementReport } from "../reports";

function IncomeStatementPage() {
  const [hideZero, setHideZero] = useState(false);
  const { incomeStatement, isDetailsLoading, generateIncomeStatementHandler } =
    useContext(AccountingContext);
  const {
    handlers: { setLoading, setError },
    state: { spaceSettings },
  } = useContext(AppContext);
  const {
    startDate,
    endDate,
    rangeType,
    isAllTime,
    compareStartDate,
    compareEndDate,
    compareRangeType,
    isCompareAllTime,
  } = useGetStartEndDateRangeHooks();
  const { getDateHandler } = useDateHook();
  const isCompared = compareStartDate && compareEndDate ? true : false;
  const { accountDateTitle } = getBalanceSheetTitleHandler({
    startDate,
    endDate,
    rangeType,
    isAllTime,
  });
  const { accountDateTitle: compareTitle } = getBalanceSheetTitleHandler({
    startDate: compareStartDate,
    endDate: compareEndDate,
    rangeType: compareRangeType,
    isAllTime: isCompareAllTime,
    isForBalanceSheet: true,
  });
  useEffect(() => {
    const payload = {
      startDate,
      endDate,
      compare: {
        startDate: compareStartDate,
        endDate: compareEndDate,
      },
    };
    generateIncomeStatementHandler(payload);
  }, [startDate, endDate, compareStartDate, compareEndDate]);
  const SalesValues = [
    new GetContentRowFormat(incomeStatement?.sales, "Sales"),
    new GetContentRowFormat(incomeStatement?.salesReturn, "Sales Return"),
    new GetContentRowFormat(incomeStatement?.salesDiscount, "Sales Discount"),
    new GetContentRowFormat(
      {
        isLess: false,
        amount: incomeStatement?.total?.netSales,
        firstCompareTimeValue: incomeStatement?.firstCompareTimeValue?.netSales,
        secondCompareTimeValue:
          incomeStatement?.secondCompareTimeValue?.netSales,
        deviationCompareValue: incomeStatement?.deviationCompareValue?.netSales,
        percentageCompareValue:
          incomeStatement?.percentageCompareValue?.netSales,
      },
      "Net Sales",
      true
    ),
    new GetContentRowFormat(incomeStatement?.rentalIncome, "Rental Income"),
    new GetContentRowFormat(
      incomeStatement?.rentalIncomeReturn,
      "Rental Income Return"
    ),
    new GetContentRowFormat(
      incomeStatement?.rentalDiscount,
      "Rental Income Discount"
    ),
    new GetContentRowFormat(
      {
        isLess: false,
        amount: incomeStatement?.total?.netRentalIncome,
        firstCompareTimeValue:
          incomeStatement?.firstCompareTimeValue?.netRentalIncome,
        secondCompareTimeValue:
          incomeStatement?.secondCompareTimeValue?.netRentalIncome,
        deviationCompareValue:
          incomeStatement?.deviationCompareValue?.netRentalIncome,
        percentageCompareValue:
          incomeStatement?.percentageCompareValue?.netRentalIncome,
      },
      "Net Rental Income",
      true
    ),
    ...(incomeStatement?.salesRemain?.length > 0
      ? incomeStatement?.salesRemain?.map((e) => new GetContentRowFormat(e))
      : []),
  ];
  const CostOFSalesValues = [
    new GetContentRowFormat(incomeStatement?.purchase, "Purchase"),
    new GetContentRowFormat(incomeStatement?.purchaseReturn, "Purchase Return"),
    new GetContentRowFormat(
      incomeStatement?.purchaseDiscount,
      "Purchase Discount"
    ),
    new GetContentRowFormat(
      {
        isLess: false,
        amount: incomeStatement?.total?.netPurchase,
        firstCompareTimeValue:
          incomeStatement?.firstCompareTimeValue?.netPurchase,
        secondCompareTimeValue:
          incomeStatement?.secondCompareTimeValue?.netPurchase,
        deviationCompareValue:
          incomeStatement?.deviationCompareValue?.netPurchase,
        percentageCompareValue:
          incomeStatement?.percentageCompareValue?.netPurchase,
      },
      "Net Purchase",
      true
    ),
    ...(incomeStatement?.costOfSalesRemain?.length > 0
      ? incomeStatement?.costOfSalesRemain?.map(
          (e) => new GetContentRowFormat(e)
        )
      : []),
  ];
  const AdministrativeValues = [
    ...(incomeStatement?.administrativeExpense?.length > 0
      ? incomeStatement?.administrativeExpense?.map(
          (e) => new GetContentRowFormat(e)
        )
      : []),
  ];
  const OtherIncomeValues = [
    ...(incomeStatement?.otherIncome?.length > 0
      ? incomeStatement?.otherIncome?.map((e) => new GetContentRowFormat(e))
      : []),
  ];
  const TaxationValues = [
    ...(incomeStatement?.taxation?.length > 0
      ? incomeStatement?.taxation?.map((e) => new GetContentRowFormat(e))
      : []),
  ];
  const DataObj: BalanceSheetIncomeStatementRenderInterface = {
    Sales: {
      type: ContentTypeEnum.content,
      data: SalesValues,
    },
    "Total Sales": {
      type: ContentTypeEnum.subTotal,
      data: new GetContentRowFormat(
        {
          isLess: false,
          amount: incomeStatement?.total?.sales,
          firstCompareTimeValue: incomeStatement?.firstCompareTimeValue?.sales,
          secondCompareTimeValue:
            incomeStatement?.secondCompareTimeValue?.sales,
          deviationCompareValue: incomeStatement?.deviationCompareValue?.sales,
          percentageCompareValue:
            incomeStatement?.percentageCompareValue?.sales,
        },
        "Total Sales"
      ),
    },
    "Cost of Sales": {
      type: ContentTypeEnum.content,
      data: CostOFSalesValues,
    },
    "Total Cost of Sales": {
      type: ContentTypeEnum.subTotal,
      data: new GetContentRowFormat(
        {
          amount: incomeStatement?.total?.costOfSales,
          isLess: false,
          firstCompareTimeValue:
            incomeStatement?.firstCompareTimeValue?.costOfSales,
          secondCompareTimeValue:
            incomeStatement?.secondCompareTimeValue?.costOfSales,
          deviationCompareValue:
            incomeStatement?.deviationCompareValue?.costOfSales,
          percentageCompareValue:
            incomeStatement?.percentageCompareValue?.costOfSales,
        },
        "Total Cost of Sales"
      ),
    },
    "Gross Profit/Loss": {
      type: ContentTypeEnum.total,
      data: new GetContentRowFormat(
        {
          amount: incomeStatement?.total?.grossProfit,
          isLess: false,
          firstCompareTimeValue:
            incomeStatement?.firstCompareTimeValue?.grossProfit,
          secondCompareTimeValue:
            incomeStatement?.secondCompareTimeValue?.grossProfit,
          deviationCompareValue:
            incomeStatement?.deviationCompareValue?.grossProfit,
          percentageCompareValue:
            incomeStatement?.percentageCompareValue?.grossProfit,
        },
        "Gross Profit/Loss"
      ),
    },
    "Administrative Expenses": {
      type: ContentTypeEnum.content,
      data: AdministrativeValues,
    },
    "Total Administrative Expenses": {
      type: ContentTypeEnum.subTotal,
      data: new GetContentRowFormat(
        {
          amount: incomeStatement?.total?.administrativeExpense,
          isLess: false,
          firstCompareTimeValue:
            incomeStatement?.firstCompareTimeValue?.administrativeExpense,
          secondCompareTimeValue:
            incomeStatement?.secondCompareTimeValue?.administrativeExpense,
          deviationCompareValue:
            incomeStatement?.deviationCompareValue?.administrativeExpense,
          percentageCompareValue:
            incomeStatement?.percentageCompareValue?.administrativeExpense,
        },
        "Total Administrative Expenses"
      ),
    },
    "Operating Profit": {
      type: ContentTypeEnum.total,
      data: new GetContentRowFormat(
        {
          amount: incomeStatement?.total?.operatingProfit,
          isLess: false,
          firstCompareTimeValue:
            incomeStatement?.firstCompareTimeValue?.operatingProfit,
          secondCompareTimeValue:
            incomeStatement?.secondCompareTimeValue?.operatingProfit,
          deviationCompareValue:
            incomeStatement?.deviationCompareValue?.operatingProfit,
          percentageCompareValue:
            incomeStatement?.percentageCompareValue?.operatingProfit,
        },
        "Operating Profit"
      ),
    },
    "Other Income": {
      type: ContentTypeEnum.content,
      data: OtherIncomeValues,
    },
    "Total Other Income": {
      type: ContentTypeEnum.subTotal,
      data: new GetContentRowFormat(
        {
          amount: incomeStatement?.total?.otherIncome,
          firstCompareTimeValue:
            incomeStatement?.firstCompareTimeValue?.otherIncome,
          secondCompareTimeValue:
            incomeStatement?.secondCompareTimeValue?.otherIncome,
          deviationCompareValue:
            incomeStatement?.deviationCompareValue?.otherIncome,
          percentageCompareValue:
            incomeStatement?.percentageCompareValue?.otherIncome,
        },
        "Total Other Income"
      ),
    },
    "Profit on Ordinary Activities Before Taxation": {
      type: ContentTypeEnum.total,
      data: new GetContentRowFormat(
        {
          amount: incomeStatement?.total?.profitBeforeTaxation,
          firstCompareTimeValue:
            incomeStatement?.firstCompareTimeValue?.profitBeforeTaxation,
          secondCompareTimeValue:
            incomeStatement?.secondCompareTimeValue?.profitBeforeTaxation,
          deviationCompareValue:
            incomeStatement?.deviationCompareValue?.profitBeforeTaxation,
          percentageCompareValue:
            incomeStatement?.percentageCompareValue?.profitBeforeTaxation,
        },
        "Profit on Ordinary Activities Before Taxation"
      ),
    },
    Taxation: {
      type: ContentTypeEnum.content,
      data: TaxationValues,
    },
    "Profit After Taxation/Current Year Earnings": {
      type: ContentTypeEnum.total,
      data: new GetContentRowFormat(
        { amount: incomeStatement?.total?.currentYearEarning },
        "Profit After Taxation/Current Year Earnings"
      ),
    },
  };

  const generateReportHandler = async () => {
    try {
      setLoading(true);
      await BalanceSheet_IncomeStatementReport({
        data: DataObj,
        dateFormatter: getDateHandler,
        isCompared: isCompared,
        compareHeader: compareTitle,
        dateHeader: accountDateTitle,
        organizationName: spaceSettings?.companyName,
      });
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <PageTemplate
      scrollAll
      title={"Accounting"}
      breadCrumb={[
        {
          name: "Income Statement",
        },
      ]}
    >
      <BalanceSheetTemplate
        hideZero={hideZero}
        setHideZero={setHideZero}
        generateReportHandler={generateReportHandler}
        title={"Income Statement"}
        isLoading={isDetailsLoading}
        haveData={incomeStatement !== null}
        totalAmount={incomeStatement?.total?.currentYearEarning}
      >
        {Object?.keys(DataObj)?.map((e, key) => {
          let values = DataObj[e];

          if (
            values?.type === ContentTypeEnum.content &&
            Array.isArray(values?.data) &&
            values?.data?.length > 0
          ) {
            let isEmpty = values?.data?.filter(
              (f) =>
                !(
                  (f?.value === 0 || !f?.value) &&
                  (f?.firstCompareTimeValue === 0 || !f?.firstCompareTimeValue)
                )
            )?.length;
            if (!isEmpty && hideZero) return;
            return (
              <ToggleComponent title={e} key={key}>
                {values?.data?.map((f, fKey) => {
                  if (!e) return;
                  if (
                    f?.value === 0 &&
                    f?.firstCompareTimeValue === 0 &&
                    hideZero
                  ) {
                    return;
                  }
                  return (
                    <>
                      <ContentRowComponent
                        key={fKey}
                        title={f?.title}
                        value={f?.value}
                        haveBackground={fKey % 2 === 0}
                        firstComparedAmount={f?.firstCompareTimeValue}
                        comparedAmount={f?.deviationCompareValue}
                        deviation={f?.percentageCompareValue}
                        isLess={f?.isLess}
                        account={f?.account}
                        isCompared={isCompared}
                        type={ContentTypeEnum.content}
                        haveBorder={f?.showBorder}
                      />
                    </>
                  );
                })}
              </ToggleComponent>
            );
          }
          if (
            (values?.type === ContentTypeEnum.subTotal ||
              values?.type === ContentTypeEnum.total) &&
            !Array.isArray(values?.data)
          ) {
            return (
              <>
                <hr className={"mb-2"} />
                <ContentRowComponent
                  key={key}
                  title={values?.data?.title}
                  value={values?.data?.value}
                  firstComparedAmount={values?.data?.firstCompareTimeValue}
                  comparedAmount={values?.data?.deviationCompareValue}
                  deviation={values?.data?.percentageCompareValue}
                  isCompared={isCompared}
                  type={values?.type}
                />
                <hr className={"mt-2"} />
              </>
            );
          }
        })}
      </BalanceSheetTemplate>
    </PageTemplate>
  );
}

export default IncomeStatementPage;
