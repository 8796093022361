import React from "react";
import {
  BankReconciliationInterface,
  IconTypeEnum,
  TableColumnInterface,
  TransactionTypeEnum,
} from "interfaces";
import { useNavigate } from "react-router-dom";

import { MyMoreOptionButton } from "components";
import { PageLinks } from "routes";
import { usePaginationQueryHooks } from "useHooks";
import { UserTableProfile } from "assets";
import { getCurrency } from "helpers";
import { useDateHook } from "useHooks";

function ReconcileItemsTableColumns(
  pageQuery
): TableColumnInterface<BankReconciliationInterface>[] {
  const navigate = useNavigate();
  const { getDateHandler } = useDateHook();
  const { calcSNHandler } = usePaginationQueryHooks();
  return [
    {
      title: "SN",
      render(renderData, key) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },
    {
      title: "Bank Name",
      render(renderData) {
        return (
          <UserTableProfile
            badgeIconType={renderData?.bank?.isBankLinked && IconTypeEnum.LAYER}
            details={{
              isStaticPath: renderData?.bank?.logo_uri ? true : false,
              name: renderData?.bank?.label,
              subTitle: `${renderData?.bank?.name}`,
              profile: renderData?.bank?.logo_uri || renderData?.bank?.logo,
            }}
          />
        );
      },
    },
    {
      title: "Remarks",
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>{renderData?.statement?.remarks}</span>
          </div>
        );
      },
    },
    {
      title: "Transaction Type",
      render(renderData) {
        return (
          <span>
            {renderData?.transactionType === TransactionTypeEnum.DR
              ? "Debit"
              : "Credit"}
          </span>
        );
      },
    },
    {
      title: "Balance",
      render(renderData) {
        return (
          <span>
            {renderData?.statement?.currency || getCurrency()}{""}
            {renderData?.balance}
          </span>
        );
      },
    },
    {
      title: "Date",
      render(renderData) {
        return (
          <span>{getDateHandler({ date: renderData?.reconciledDate })}</span>
        );
      },
    },
    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View ",
                  handler: () => {
                    navigate(PageLinks.reconciliation.details(renderData?._id));
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default ReconcileItemsTableColumns;
