import React from "react";
import { FormConcludeButtonInterface } from "./interfaces";
import { MyButton } from "components";
import { usePageQueryHooks } from "../../useHooks";
import { useNavigate } from "react-router-dom";

function FormConcludeButtons({
  submitButton,
  cancelButton,
  draftButton,
  size,
  leftChildren,
  saveAndCreateButton,
}: FormConcludeButtonInterface) {
  const { returnURL } = usePageQueryHooks();
  const navigate = useNavigate();
  const SubmitButton = (
    <MyButton
      size={size}
      disabled={submitButton?.disable}
      onClick={
        typeof submitButton?.handler === "function"
          ? submitButton?.handler
          : undefined
      }
      colorType={"primary"}
      isLoading={submitButton?.isLoading}
      name={
        submitButton?.isForce && submitButton?.title
          ? submitButton?.title
          : "Save"
      }
      type={submitButton?.notSubmit ? "button" : "submit"}
    />
  );
  const CancelButton = (
    <MyButton
      size={size}
      colorType={"danger"}
      name={cancelButton?.title || "Cancel"}
      onClick={() => {
        if (typeof cancelButton?.handler == "function") {
          cancelButton.handler();
        } else {
          if (returnURL) {
            navigate(returnURL);
          } else {
            navigate(-1);
          }
        }
      }}
    />
  );
  return (
    <div className={"flex items-center justify-between border-t-2 pt-6"}>
      <div>{leftChildren}</div>
      <div className={"flex gap-5"}>
        {/*{cancelButton && isInModal && CancelButton}*/}

        {submitButton && SubmitButton}

        {saveAndCreateButton && (
          <MyButton
            size={size}
            disabled={saveAndCreateButton?.disable}
            isOutline
            name={saveAndCreateButton?.title || "Save & Create New"}
            onClick={() => saveAndCreateButton?.handler()}
          />
        )}
        {draftButton && (
          <MyButton
            size={size}
            disabled={draftButton?.disable}
            isOutline
            name={draftButton?.title || "Draft"}
            onClick={() => draftButton?.handler()}
          />
        )}
        {cancelButton && CancelButton}
        {/*{submitButton && isInModal && SubmitButton}*/}
      </div>
    </div>
  );
}

export default FormConcludeButtons;
