import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { MyButton, TableCardComponent } from "components";
import { PageTemplate } from "templates";
import { IconTypeEnum, QueryStringEnum } from "interfaces";

import { MessageTableColumns } from "../../components";
import { MessageContext } from "context";
import { FiMessageSquare, FiSend } from "react-icons/fi";
import { usePageQueryHooks, usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function MessagePage() {
  const [query] = useSearchParams();
  const {
    getAllDataHandlers,
    allData,
    isLoading,
    totalArchiveDocs,
    totalDocs,
  } = useContext(MessageContext);

  const navigate = useNavigate();
  const { unitID, propertyID } = usePageQueryHooks();
  const { isArchive, currentPage } = usePaginationQueryHooks();
  const messageStatus = query.get(QueryStringEnum?.MESSAGE_STATUS) || "INBOX";
  const pageQuery = {
    unit: unitID,
    property: propertyID,
    messageStatus,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, unitID, propertyID, messageStatus, currentPage]);

  const tableCardProps = {
    column: MessageTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      centerChildren: <MessageToggleComponent />,
      rightChildren: (
        <MyButton
          onClick={() => {
            navigate(PageLinks.message.new);
          }}
          iconType={IconTypeEnum.ADD}
          name={"Compose Message"}
        />
      ),
    },
  };
  return (
    <PageTemplate
      title={"Messages"}
      breadCrumb={[
        {
          name: "Messages",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

const MessageToggleComponent = () => {
  const [query, setQuery] = useSearchParams();
  const messageStatus = query.get(QueryStringEnum?.MESSAGE_STATUS);

  const dataStatus = [
    {
      title: "Inbox",
      Icon: <FiMessageSquare />,
      status: messageStatus != "SENT",
      handler() {
        query.set(QueryStringEnum?.MESSAGE_STATUS, "INBOX");
        setQuery(query);
      },
    },
    {
      title: "Sent",
      Icon: <FiSend />,
      status: messageStatus === "SENT",
      handler() {
        query.set(QueryStringEnum?.MESSAGE_STATUS, "SENT");
        setQuery(query);
      },
    },
  ];
  return (
    <div className={"flex gap-1 bg-tGray rounded-full p-1"}>
      {dataStatus?.map((e, key) => {
        let Icon = e?.Icon;
        return (
          <div
            key={key}
            onClick={() => {
              e?.handler();
            }}
            className={`flex items-center gap-2 cursor-pointer px-4 py-1 rounded-full text-[14px]  ${
              e?.status ? "bg-white text-black" : "text-gray-500"
            }`}
          >
            {Icon}
            <span>{e?.title}</span>
          </div>
        );
      })}
    </div>
  );
};
export default MessagePage;
