import { useContext, useEffect, useState } from "react";
import { useGetAddressHookInterface } from "./interfaces";
import { Api } from "../helpers";
import { ApiUrl } from "../services";
import { AppContext } from "../context";

export default function useGetAddressHooks({}: useGetAddressHookInterface) {
  const { getApi } = Api();
  const { handlers } = useContext(AppContext);
  const [data, setData] = useState({
    country: [],
    county: [],
  });

  const getCountryHandler = async () => {
    try {
      const res = await getApi(ApiUrl.address.get_allCountryList);
      setData((e) => {
        return {
          ...e,
          country: res.data,
        };
      });
    } catch (error) {
      handlers.setError(true, error?.message);
    }
  };
  const getCountyHandler = async () => {
    try {
      const res = await getApi(ApiUrl.address.get_allCountyList);
      setData((e) => {
        return {
          ...e,
          county: res.data,
        };
      });
    } catch (error) {
      handlers.setError(true, error?.message);
    }
  };

  useEffect(() => {
    getCountryHandler();
    getCountyHandler();
  }, []);

  return {
    countryData: data?.country,
    countyData: data?.county,
    getCountryHandler,
  };
}
