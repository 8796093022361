import React from "react";
import { BillingAddressInterface } from "interfaces";
interface propsInterface {
  details?: BillingAddressInterface;
  isLoading?: boolean;
  onClickHandler?(item: string);
  isSelected?: boolean;
  onChangeHandler?(item: string);
  onEditHandler?(item: string);
}
function BillingAddressCard({
  isLoading,
  onClickHandler,
  details,
  isSelected,
  onChangeHandler,
  onEditHandler,
}: propsInterface) {
  const canChange = typeof onChangeHandler === "function";
  const canEdit = typeof onEditHandler === "function";
  return (
    <div
      onClick={() => onClickHandler(details?._id)}
      className={`flex flex-col gap-2 border-2 rounded-md p-2 text-[14px] cursor-pointer ${
        isSelected && "border-primary "
      }`}
    >
      <div className={"flex justify-between items-center"}>
        <div className={"flex items-center gap-2"}>
          <span>Deliver to:</span>
          <b>{details?.fullName}</b>
        </div>
        <div className={"text-purple-500"}>
          {canChange && (
            <span onClick={() => onChangeHandler(details?._id)}>Change</span>
          )}
          {canEdit && (
            <span onClick={() => onEditHandler(details?._id)}>Edit</span>
          )}
        </div>
      </div>
      <div className={"flex items-center gap-2"}>
        <span>Email to:</span>
        <b>{details?.email}</b>
      </div>
      <b>
        {[
          details?.mobile,
          `${details?.address1 || ""} ${details?.county || ""} ${
            details?.country
          }`,
        ].join(" | ")}
      </b>
    </div>
  );
}

export default BillingAddressCard;
