import * as yup from "yup";
import { BookingInterface, TaxTypeEnum } from "interfaces";
import { validateDateHandler } from "../../helpers";

export const BookingsFormikValues = {
  initialValues: (values?: BookingInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      isDraft: false,
      property: values?.property?._id || "",
      unit: values?.unit?._id || "",
      tenant: values?.tenant?._id || "",
      arrivalDate: values?.arrivalDate || "",
      departureDate: values?.departureDate || "",
      description: values?.description || "",
      //rent
      rentCost: values?.rent?.rentCost || "",
      taxRate: values?.rent?.taxRate?._id || "",
      taxType: values?.rent?.taxType || TaxTypeEnum.INCLUSIVE,
      remarks: values?.rent?.remarks || "",

      // security deposit
      chargeType: values?.securityDeposit?.chargeType?._id || "",
      securityDepositedAmount:
        values?.securityDeposit?.securityDepositedAmount || "",
      securityDepositedOn: values?.securityDeposit?.securityDepositedOn || "",
      securityDepositedNote:
        values?.securityDeposit?.securityDepositedNote || "",

      agreementDocuments: values?.agreementDocuments || [],
      tenantSignature: {
        participant: values?.tenantSignature?.participant,
        signature: values?.tenantSignature?.signature,
        signedDate: values?.tenantSignature?.signedDate,
      },
    };
  },
  validationSchema: yup.object().shape({
    property: yup.string().required("required"),
    unit: yup.string().required("required"),
    tenant: yup.string().required("required"),

    arrivalDate: yup.string().required("required"),
    departureDate: yup
      .string()
      .test("custom-validation", "Invalid field value", function (value) {
        const { message, isError } = validateDateHandler(value, {
          minDate: this.parent.arrivalDate,
        });
        return (
          !isError ||
          this.createError({
            path: "departureDate",
            message: message,
          })
        );
      }),
    description: yup.string(),
    rentCost: yup.number().min(0, "invalid").required("required"),
    taxRate: yup.string().when("taxType", {
      is: TaxTypeEnum.EXEMPTED,
      then: yup.string(),
      otherwise: yup.string().required("required"),
    }),
    remarks: yup.string(),

    chargeType: yup.string().required("required"),
    securityDepositedAmount: yup
      .number()
      .min(0, "invalid")
      .required("required"),
    securityDepositedOn: yup
      .string()
      .test("custom-validation", "Invalid field value", function (value) {
        const { message, isError } = validateDateHandler(value, {
          pastOnly: true,
        });
        return (
          !isError ||
          this.createError({
            path: "securityDepositedOn",
            message: message,
          })
        );
      }),
    securityDepositedNote: yup.string(),

    agreementDocuments: yup.array(),
    tenantSignature: yup.object().shape({
      signature: yup.string().required("required"),
      signedDate: yup.string().required("required"),
      // participant: yup.string().required("required"),
    }),
  }),
};
