const apiUrl = {
  app: {
    get_spaceSettings: "/space-settings/",
    get_appSettings: "/admin/settings/link",
    get_twilioSettings: "/space-settings/twilio",
    post_clientLogging: "/clientLogging/log-client-errors",
    put_spaceSettings: "/space-settings/",
    put_twilioSettings: "/space-settings/twilio",

    put_seedUser: "/space-user/seed",
  },
  dashboard: {
    get_propertyStats: "/statistics/property",
    getMortgageStats: "/statistics/mortgage",
    getBookingStats: "/statistics/booking",
    getUnitStats: "/statistics/unit",
    getInvoiceStats: "/statistics/invoice",
    getTaskStats: "/statistics/task",
    getMaintenanceStats: "/statistics/maintenance",
    getTenancyStats: "/statistics/tenancy",
    getProfitLoss: "/statistics/profit-loss",
    getSales: "/statistics/sales-chart",
    getPurchase: "/statistics/purchase-chart",
    getIncomeStatement: "/statistics/income-expense-chart",
  },
  trueLayer: {
    get_authLink: "/bank/true-layer",
    get_trueLayerSettings: "/space-settings/true-layer",
    put_trueLayerSettings: "/space-settings/true-layer",
    post_setupBankFromCode: "/bank/true-layer/",
    put_syncStatement: "/bank/true-layer/sync-statement",
  },
  billingsAddress: {
    get_billingAddress: "/billing-address/",
    post_billingAddress: "/billing-address/",
    put_billingAddress: "/billing-address/",
    get_billingAddressDetails: (id: string) => `/billing-address/detail/${id}`,
    delete_address: (id: string) => `/billing-address/delete/${id}`,
    put_archiveAddress: (id: string, status: string) =>
      `/billing-address/archive/${id}/${status}`,
  },
  currentYearEarning: {
    get_currentFiscalYearEarning: (year: string) =>
      `/account/earning-fiscal-year/${year}`,
    put_transferCurrentFiscalYearEarning: (year: string) =>
      `/account/transfer_cye_re/${year}`,
  },
  pdf: {
    get_ReconcilePDF: "/pdf/reconcile/",
    get_invoicePDF: "/pdf/invoice/",
    get_inventoryPDF: "/pdf/inventory/",
    get_subscriptionPDF: (itemID, orderID) =>
      `/pdf/subscription/${itemID}/${orderID}`,
  },
  auth: {
    get_logout: "/user/logout",
    get_currentProfile: "/user/current",
    get_tenantSpaceList: "/user/space-list",
    get_loginToTenantSpace: "/user/space-access-token/",
    get_currentWorkspace: "/user/my-space",
    get_newAccessToken: "/user/refresh-space-access-token",
    get_requestOTPForEmailVerification: "/user/request-email-verification",

    post_login: "/user/login",
    post_registerAsLandLord: "/user/register",
    post_requestForgetPassword: "/user/request-forget-password",
    post_validateForgetPasswordOtp: "/user/validate-forget-password-otp",

    put_resetPassword: "/user/forget-password",
    put_validateEmailVerificationOTP: "/user/validate-email-verification-otp",
    put_updatePassword: "/user/change-password",
    put_changePasswordOneTime: "/user/set-password",
    put_updateProfile: "/space-user/edit-profile",
  },
  file: {
    post_uploadFile: "/file",
    get_file: "/file/detail/",
  },
  utils: {
    uploadImage: "/imageUpload/upload",
    get_Image: "/imageUpload/image/",
    get_fiscalYear: "/space-settings/fiscal-year",
  },
  address: {
    get_Locations: "/nested-place/",
    get_allCountyList: "/nested-place/county",
    get_allCountryList: "/nested-place/country",
    get_locationChild: "/nested-place",
    post_editLocation: "/nested-place/",
    delete_Location: "/nested-place/delete/",
  },
  customerSignature: {
    get_signature: "/user/signature/detail/",
    post_saveSignature: "/user/signature",
  },
  subLedgerName: {
    get_list: "/sub-ledger-name/",
    get_all: "/sub-ledger-name/all",
    get_detail: "/sub-ledger-name/detail/",
    put_edit: "/sub-ledger-name",
  },
  ledgerName: {
    get_ledgerName: "/ledger-name",
    get_ledgerNameDetails: "/ledger-name/detail/",
    post_ledgerName: "/ledger-name",
    put_ledgerName: "/ledger-name",
    delete_ledgerName: "/ledger-name/delete/",
  },
  config: {
    get_vehicles: "/configuration/vehicles",
    get_configuration: "/configuration/",
    put_configuration: "/configuration/",
    put_system_off: "/configuration/system-off",
    put_system_on: "/configuration/system-on",
  },
  privilege: {
    get_Privilege: "/privilege",
    get_PrivilegeDetails: "/privilege/detail/",
    get_archivedPrivilege: "/privilege?archived=1",
    post_createPrivilege: "/privilege",
    put_editPrivilege: "/privilege",
    put_archivePrivilege: "/privilege/archive/",
    delete_Privilege: "/privilege/delete/",
  },

  dynamicList: {
    get_constants: "/dynamic-list/constants",
    get_listFromFieldType: "/dynamic-list/",
    get_itemDetail: "/dynamic-list/detail/",
    post_addItem: "/dynamic-list/",
    delete_item: "/dynamic-list/delete/",
  },
  property: {
    get_property: "/property/",
    get_propertyDetails: "/property/detail/",
    get_propertyReportStats: "/property/report-stats/",
    post_addProperty: "/property/",
    put_editProperty: "/property/",
    put_archiveUnArchiveProperty: "/property/archive/",
    delete_Property: "/property/delete/",
  },
  unit: {
    get_unit: "/unit/",
    get_unitDetails: "/unit/detail/",
    post_addUnit: "/unit/",
    put_editUnit: "/unit/",
    put_editRoom: "/unit/room/update",
    put_archiveUnArchiveUnit: "/unit/archive/",
    delete_unit: "/unit/delete/",
  },
  users: {
    get_users: "/space-user/",
    get_archivedUser: "/space-user/archived/",
    get_userDetails: "/space-user/detail/",
    post_addUser: "/space-user/",
    put_editUser: "/space-user/",
    put_archiveUnArchiveUser: "/space-user/archive/",
    delete_user: "/space-user/remove-from-space/",
  },
  loansAndMortgages: {
    get_loans: "/loan-mortgage/",
    get_archivedLoan: "/loan-mortgage/archived/",
    get_loanDetails: "/loan-mortgage/detail/",
    get_loanStats: "/loan-mortgage/stats",
    post_addLoan: "/loan-mortgage/",
    put_editLoan: "/loan-mortgage/",
    put_archiveUnArchiveLoan: "/loan-mortgage/archive/",
    delete_loan: "/loan-mortgage/remove-from-space/",
  },
  journal: {
    get_journal: "/journal/",
    get_archivedJournal: "/journal/archived/",
    get_journalDetails: "/journal/detail/",
    post_addJournal: "/journal/",
    put_editJournal: "/journal/",
    put_archiveUnArchiveJournal: "/journal/archive/",
    delete_journal: "/journal/delete/",
  },
  invoice: {
    get_invoice: "/invoice/",
    put_invoiceCheckUnique: "/invoice/check-unique",
    get_archivedInvoice: "/invoice/",
    get_invoiceDetails: "/invoice/detail/",
    get_generateInvoiceID: "/invoice/unique/",
    post_addInvoice: "/invoice/",
    put_editInvoice: "/invoice/",
    put_makeInvoiceActive: "/invoice/active",
    put_archiveUnArchiveInvoice: "/invoice/archive/",
    delete_invoice: "/invoice/delete/",
  },
  tenancy: {
    get_tenancies: "/tenancy/",
    get_tenanciesByPropertyId: "/tenancy/property/",
    get_tenanciesByUnitId: "/tenancy/unit/",
    get_archivedTenants: "/tenancy/archived/",
    get_tenancyDetails: "/tenancy/detail/",
    post_addTenancy: "/tenancy/",
    put_editTenancy: "/tenancy/",
    put_endTenancy: (id: string) => `/tenancy/terminate/${id}`,
    put_archiveUnArchiveTenancy: "/tenancy/archive/",
    delete_tenancy: "/tenancy/delete/",
  },
  note: {
    get_notes: "/note/",
    get_noteDetails: "/note/detail/",
    post_addNote: "/note/",
    put_editNote: "/note/",
    put_archiveUnArchiveNote: "/note/archive/",
    delete_note: "/note/delete/",
  },
  message: {
    get_messages: "/message/",
    get_messageDetails: "/message/detail/",
    post_addMessage: "/message/",
    put_editMessage: "/message/",
    put_archiveUnArchiveMessage: "/message/archive/",
    delete_message: "/message/delete/",
  },
  candidate: {
    get_candidate: "/candidate/",
    get_candidateDetails: "/candidate/detail/",
    post_addCandidate: "/candidate/",
    put_editCandidate: "/candidate/",
    put_archiveUnArchiveCandidate: "/candidate/archive/",
    delete_candidate: "/candidate/delete/",
  },
  bankStatement: {
    get_statementDetails: "/bank-statement/detail/",
    get_bankStatement: "/bank-statement",
    put_transferStatement: "/bank-statement/bank-transfer",
    put_importExcel: "/bank-statement/import/excel",
    put_updateStatement: "/bank-statement",
    post_insertStatement: "/bank-statement/insert",
    put_accountReset: "/account/reset",
  },
  bankReconciliation: {
    get_bankWithStatement: "/bank/manual/with-stats",
    get_invoiceReconcileList: "/invoice/reconcile-list",
    get_assetsFile: "/file/asset/",
    get_reconciliationStatement:
      "/bank-statement/statement-align-with-invoice/",
    get_reconcile: "/bank-reconcile/",
    get_reconcileDetails: "/bank-reconcile/detail/",
    post_reconcileMatch: "/bank-reconcile/",
  },
  booking: {
    get_booking: "/booking/",
    get_activeBooking: "/booking/active/",
    get_bookingDetails: "/booking/detail/",
    post_addBooking: "/booking/",
    put_editBooking: "/booking/",
    put_archiveUnArchiveBooking: "/booking/archive/",
    delete_booking: "/booking/delete/",
  },
  inventory: {
    get_inventory: "/inventory/",
    get_activeInventory: "/inventory/active/",
    get_inventoryDetails: "/inventory/detail/",
    post_addInventory: "/inventory/",
    put_editInventory: "/inventory/",
    put_archiveUnArchiveInventory: "/inventory/archive/",
    delete_inventory: "/inventory/delete/",
  },
  maintenance: {
    get_maintenance: "/repair-and-maintenance/",
    get_maintenanceDetails: "/repair-and-maintenance/detail/",
    post_addMaintenance: "/repair-and-maintenance/",
    post_addMaintenanceMultiple: "/repair-and-maintenance/multiple",
    put_editMaintenance: "/repair-and-maintenance/",
    put_archiveUnArchiveMaintenance: "/repair-and-maintenance/archive/",
    delete_maintenance: "/repair-and-maintenance/delete/",
  },
  task: {
    get_task: "/task/",
    get_activeTask: "/task/active/",
    get_taskDetails: "/task/detail/",
    post_addTask: "/task/",
    post_addTaskMultiple: "/task/multiple",
    put_editTask: "/task/",
    put_archiveUnArchiveTask: "/task/archive/",
    delete_task: "/task/delete/",
  },
  documents: {
    get_documents: "/document/",
    get_documentsDetails: "/document/detail/",
    post_addDocument: "/document/",
    put_editDocument: "/document/",
    put_archiveUnArchiveDocument: "/document/archive/",
    delete_document: "/document/delete/",
  },
  meterReading: {
    get_meterReading: "/meter-reading/",
    get_meterReadingDetails: "/meter-reading/detail/",
    post_addMeterReading: "/meter-reading/",
    put_editMeterReading: "/meter-reading/",
    put_archiveUnArchiveMeterReading: "/meter-reading/archive/",
    delete_meterReading: "/meter-reading/delete/",
  },
  trip: {
    get_trip: "/trip/",
    get_tripDetails: "/trip/detail/",
    post_addTrip: "/trip/",
    put_editTrip: "/trip/",
    put_archiveUnArchiveTrip: "/trip/archive/",
    delete_trip: "/trip/delete/",
  },
  bank: {
    get_bank: "/bank/manual",
    get_details: "/bank/manual/detail/",
    post_addBank: "/bank/manual",
    put_editBank: "/bank/manual",
    put_archiveUnArchiveBank: "/bank/manual/archive/",
    delete_bank: "/bank/manual/delete/",
  },
  trash: {
    get_trash: "/trash/",
    get_trashDetails: "/trash/detail/",
    put_restore: "/trash/restore/",
    delete_trash: "/trash/delete/",
    delete_emptyBin: "/trash/empty/",
  },
  listing: {
    get_listingFromUnitId: "/listing-unit/",
    get_ListingInSpace: "/listing-unit/",
    get_ListingDetails: "/listing-unit/detail/",
    post_addListingToUnit: "/listing-unit/",
    delete_listing: "/listing-unit/delete/",
  },
  ownership: {
    get_ownershipDetails: "/owner/ownership/detail/",
    get_ownershipFromPropertyId: "/owner/ownership/list/",
    post_addOwnerAndAssignOwner: "/owner/add-and-assign/",
    post_assignOwnerInProperty: "/owner/assign/",
    put_ownership: "/owner/assign/edit/",
    delete_revokeOwnership: "/owner/assign/",
  },
  owner: {
    get_ownerDetail: "/owner/detail/",
    put_inActiveOwner: "/owner/toggle-status/",
    delete_owner: "/owner/delete/",
  },
  manageNotification: {
    get_notification: "/settings/notification",
    get_notificationDetails: "/settings/notification/detail/",
    post_notification: "/settings/notification",
  },
  notification: {
    get_notification: "/notification",
    get_notificationDetails: "/notification/detail/",
    post_notification: "/notification",
    put_markNotification: "/notification/mark-read-unread/",
    put_archiveNotification: "/notification/archive/",
    delete_notification: "/notification/delete/",
  },
  account: {
    put_generateLedger: "/account/ledger/generate",
    put_generateLedgerTransaction: "/account/transactions/generate",
    put_generateIncomeStatement: "/account/income-statement/generate",
    put_generateBalanceSheet: "/account/balance-sheet/generate",
  },
  trialBalance: {
    put_generateTrialBalance: "/account/trial-balance/generate",
  },
  subscription: {
    get_activeSubscription: "/admin/customer/my-active-subscription",
    get_mySubscriptionStats: "/admin/customer/my-subscription-stats",
    get_purchaseHistory: "/admin/customer/my-purchase-history",
    get_purchasableSubscriptionPackage: "/admin/package-subscription/public",
  },
  stripe: {
    get_paymentMethod: "/payment/stripe/list",
    post_validateAndCalculateOrder: "/payment/stripe/validate",
    post_createOrder: "/payment/stripe/create",
    post_confirmOrder: "/payment/stripe/confirm",
    post_attachCard: "/payment/stripe/attach",
    delete_card: `/payment/stripe/detach`,
  },
  events: {
    get_events: "/event",
    get_eventByMonth: "/event/count",
    get_eventDetail: "/event/detail/",
    post_event: "/event/",
    put_archiveEvent: "/trip/archive/",
    delete_event: "/event/delete/",
  },
};

export default apiUrl;
