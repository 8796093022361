import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MyButton, TableCardComponent } from "components";
import { PageTemplate } from "templates";
import { PrivilegeContext } from "context";

import { SpaceAccessListEnum, IconTypeEnum } from "interfaces";
import { useAuthorizationHook, usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";

import { RolesPermissionTableColumns } from "../../components";

function RolesPermissionPage() {
  const {
    isLoading,
    getAllDataHandlers,
    allData,
    totalDocs,
    totalArchiveDocs,
  } = useContext(PrivilegeContext);
  const navigate = useNavigate();
  const { currentPage, isArchive } = usePaginationQueryHooks();
  useEffect(() => {
    getAllDataHandlers();
  }, [currentPage, isArchive]);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAdd = checkCanAccessHandler([SpaceAccessListEnum.AUTH_ADD_ROLE]);

  const tableCardProps = {
    column: RolesPermissionTableColumns(),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      rightChildren: canAdd && (
        <MyButton
          onClick={() => {
            navigate(PageLinks.role.create);
          }}
          name={"Add Role"}
          iconType={IconTypeEnum.ADD}
        />
      ),
    },
  };
  return (
    <PageTemplate
      title={"Roles & Permissions"}
      breadCrumb={[
        {
          name: "Roles",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default RolesPermissionPage;
