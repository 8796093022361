import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "context";
import { EmptyMessageComponent, MyButton } from "components";
import { capitalizeFirstLetter } from "../../helpers";

function Index() {
  const [selectedWorkSpace, setWorkspace] = useState("");
  const { handlers, state } = useContext(AppContext);

  useEffect(() => {
    handlers?.getTenantSpaceListHandler();
  }, []);

  const onSubmit = async () => {
    await handlers?.loginTenantSpaceHandler(selectedWorkSpace);
  };
  return (
    <div className={"grid  h-screen items-center justify-center"}>
      <div
        className={
          "flex flex-col gap-10 w-[500px] min-h-[30vh] bg-white p-5 rounded-md shadow-sm"
        }
      >
        <div className={"flex flex-col gap-2 text-left"}>
          <span className={"text-[22px] font-bold "}>
            Please select a workspace to continue.
          </span>
          {/*<span className={"text-[16px] text-primary"}>Select Workspace</span>*/}
        </div>
        <div className={"flex flex-col gap-5"}>
          {state?.tenantSpaces?.length > 0 ? (
            <>
              <div className={"flex flex-col gap-2 select-none"}>
                {state?.tenantSpaces?.map((e) => {
                  let isActive = e?.tenantID?._id === selectedWorkSpace;
                  return (
                    <div
                      className={`flex flex-col gap-1 border-[1px] rounded-md p-4 cursor-pointer text-[14px] ${
                        isActive && "border-primary text-primary"
                      }`}
                      onClick={() => setWorkspace(e?.tenantID?._id)}
                      key={e?._id}
                      onDoubleClick={() => {
                        setWorkspace(e?.tenantID?._id);
                        onSubmit();
                      }}
                    >
                      <span className={"text-[18px] text-black"}>
                        <b>Role: </b>
                        {capitalizeFirstLetter(e?.customer?.granted?.name)}
                      </span>
                      <div className={"text-black text-[16px]"}>
                        <b>Workspace: </b>
                        {capitalizeFirstLetter(e?.tenantID?.name)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <EmptyMessageComponent message={"No Workspace"} />
          )}
          <div className={"flex flex-col gap-5"}>
            {selectedWorkSpace && (
              <MyButton name={"Continue"} onClick={onSubmit} />
            )}
            <MyButton
              onClick={() => handlers?.logout()}
              name={"Log Out"}
              colorType={"danger"}
              className={"border-none"}
              isOutline
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
