import React, { useContext, useEffect } from "react";
import { OptionsContext } from "context";
import { MySelectField, MyTextAreaInput, MyTextField } from "components";
import {
  decodePropertyOptionsHandler,
  decodeUnitOptionsHandler,
  decodeUserOptionsHandler,
} from "helpers";
import { useFormikContext } from "formik";
import { NoteInterface } from "interfaces";

function NoteForm() {
  const { values } = useFormikContext<NoteInterface<string>>();

  const { propertyOptions, getUnitOptionsHandler, unitOptions, userOptions } =
    useContext(OptionsContext);
  useEffect(() => {
    if (values?.property) {
      getUnitOptionsHandler(values?.property);
    }
  }, [values?.property]);
  return (
    <div className={"flex flex-col gap-10"}>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-10"}>
        <MyTextField
          isRequired
          name={"name"}
          label={"Subject"}
          placeholder={"eg. Note"}
        />
      </div>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-10"}>
        <MySelectField
          label={"Property"}
          name={"property"}
          option={{
            selectOptions: decodePropertyOptionsHandler(propertyOptions),
            defaultLabel: "Select Property",
          }}
        />
        <MySelectField
          label={"Unit"}
          name={"unit"}
          option={{
            selectOptions: decodeUnitOptionsHandler(unitOptions),
            defaultLabel: "Select Unit",
          }}
        />
      </div>
      <div>
        <MySelectField
          label={"Shared To"}
          name={"sharedTo"}
          option={{
            selectOptions: decodeUserOptionsHandler(userOptions),
            defaultLabel: "Select User",
          }}
        />
      </div>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-10"}>
        <div className={"col-span-2"}>
          <MyTextAreaInput label={"Note"} isRequired name={"note"} />
        </div>
      </div>
    </div>
  );
}

export default NoteForm;
