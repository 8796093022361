import React, { useContext, useEffect, useState } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";

import { TabComponent } from "components";
import { PageTemplate } from "templates";
import {
  ParamsStringEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { PropertyDataContext } from "context";
import { PageLinks } from "routes";
import { useAuthorizationHook } from "useHooks";

import { PropertyTitleDetails } from "../assets";

function PropertyDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const [query] = useSearchParams();
  const activeTab = query.get(QueryStringEnum.ACTIVE_TAB);
  const propertyID = params.propertyId;
  const { details, isDetailsLoading, getDetailsHandler } =
    useContext(PropertyDataContext);
  useEffect(() => {
    getDetailsHandler(propertyID);
  }, [propertyID]);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const haveUnitAccess = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_READ_UNIT,
  ]);
  const haveUserAccess = checkCanAccessHandler([
    SpaceAccessListEnum.AUTH_READ_USER,
  ]);
  const haveTenancyAccess = checkCanAccessHandler([
    SpaceAccessListEnum.TENANCY_READ_TENANCY,
  ]);
  const haveDocumentAccess = checkCanAccessHandler([
    SpaceAccessListEnum.DOCUMENT_READ_DOCUMENT,
  ]);
  const tabs = [
    {
      title: "General Information",
      pathName: "",
    },
    {
      title: "Amenities",
      pathName: "amenities",
    },
    haveUserAccess && {
      title: "Owners",
      pathName: "owners",
    },
    haveUnitAccess && {
      title: "Units",
      pathName: "units",
    },
    haveTenancyAccess && {
      title: "Tenancies",
      pathName: "tenancies",
    },
    haveUserAccess && {
      title: "Tenants",
      pathName: "tenants",
    },
    haveDocumentAccess && {
      title: "Documents",
      pathName: "documents",
    },
  ];
  return (
    <PageTemplate
      scrollAll
      title={"Properties"}
      backNavigation={{
        title: "Property Details",
        path: PageLinks.property.list,
      }}
      breadCrumb={[
        {
          name: "Property",
        },
      ]}
    >
      <div className={"flex flex-col gap-10 overflow-x-scroll"}>
        {activeTab && (
          <PropertyTitleDetails
            isLoading={isDetailsLoading}
            details={details}
          />
        )}

        <div className={"flex flex-col gap-10 overflow-x-scroll"}>
          <TabComponent tabs={tabs} />
          <Outlet />
        </div>
      </div>
    </PageTemplate>
  );
}

export default PropertyDetailsPage;
