import React, { useContext, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { FormConcludeButtons } from "assets";
import { PageTemplate } from "templates";
import { FieldTypeEnum, ParamsStringEnum, QueryStringEnum } from "interfaces";
import { TripContext, OptionsContext, AppContext } from "context";

import { TripsFormikValues } from "../../helpers";
import { TripsForm } from "../../components";
import { PageLinks } from "routes";

function NewTripsPage() {
  const navigate = useNavigate();
  const {
    handlers: { setLoading },
  } = useContext(AppContext);
  const { getPropertyOptionsHandler, getOptionsHandler } =
    useContext(OptionsContext);
  const params = useParams<ParamsStringEnum>();

  const { details, editDetailsHandler, getDetailsHandler } =
    useContext(TripContext);
  const isEdit = params?.id;
  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([
        isEdit && getDetailsHandler(isEdit),
        await getOptionsHandler(FieldTypeEnum.METER_TYPE),
        await getPropertyOptionsHandler(),
      ]);

      setLoading(false);
    })();
  }, [isEdit]);
  const submitHandler = async (values, formikHelpers) => {
    await editDetailsHandler(values, formikHelpers, {
      onSuccess: async (payload) => {
        navigate(PageLinks.trips.list);
      },
    });
  };
  return (
    <PageTemplate
      title={"Tools"}
      scrollAll
      breadCrumb={[
        {
          name: "Trips",
        },
        {
          name: `${isEdit ? "Edit" : "New"} Trip`,
        },
      ]}
      backNavigation={{
        title: `${isEdit ? "Edit" : "New"} Trip`,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={TripsFormikValues?.initialValues(isEdit && details)}
        validationSchema={TripsFormikValues?.validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={"flex flex-col gap-5"}>
              <TripsForm />
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                  handler: () => {
                    navigate(-1);
                  },
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default NewTripsPage;
