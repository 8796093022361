import React, { createContext, useContext, useState } from "react";

import { AppContext } from "context";
import { Api, downloadBlobFileHandler } from "helpers";
import { ApiUrl } from "services";
import {
  AssetFileNameEnum,
  BankInterface,
  BankReconciliationInterface,
  BankStatementInterface,
  DataContextInterface,
  HandlerCallbackInterface,
} from "interfaces";
import { useDataContextHooks, usePaginationQueryHooks } from "useHooks";

interface ContextInterface extends DataContextInterface<BankInterface> {
  reconciliationStatements: BankStatementInterface[];
  totalReconciliationStatements: number;
  totalReconcileItems: number;
  reconcileItems: BankReconciliationInterface[];
  reconcileItemDetails: BankReconciliationInterface;
  getReconciliationStatementHandler(itemId: string);
  getReconcileItemDetailsHandler(itemId: string);
  getReconcileItemsHandler(query);
  setReconciliationStatementsHandler(statements: BankStatementInterface[]);
  matchStatementHandler(payload, callback: HandlerCallbackInterface);
  transferBankStatementHandler(payload, callback: HandlerCallbackInterface);
  uploadBankStatementHandler(payload, callback: HandlerCallbackInterface);
  downloadSampleFileHandler(name: AssetFileNameEnum);
}

export const BankReconciliationContext = createContext<ContextInterface>({
  isLoading: false,
  isDetailsLoading: false,
  allData: [],
  totalReconciliationStatements: 0,
  totalReconcileItems: 0,
  reconcileItems: [],
  reconcileItemDetails: null,
  reconciliationStatements: [],
  getAllDataHandlers(query?: any) {},
  getReconcileItemsHandler(query?: any) {},
  getReconcileItemDetailsHandler(itemId?: any) {},
  setReconciliationStatementsHandler(statements: BankStatementInterface[]) {},
  getReconciliationStatementHandler(itemId: string) {},
  matchStatementHandler(payload, callback) {},
  transferBankStatementHandler(payload, callback) {},
  uploadBankStatementHandler(payload, callback) {},
  downloadSampleFileHandler(name: AssetFileNameEnum) {},
});

function DataContextProvider({ children }) {
  const { isArchive, paginationQuery } = usePaginationQueryHooks();
  const [totalReconciliationStatements, setTotalReconciliationStatements] =
    useState(0);
  const [totalReconcileItems, setTotalReconcileItems] = useState(0);
  const [reconciliationStatements, setReconciliationStatements] = useState([]);
  const [reconcileItems, setReconcileItems] = useState([]);
  const [reconcileItemDetails, setReconcileItemDetails] = useState(null);
  const {
    isArchiveLoaded,
    setLoading,
    isLoading,
    isDetailsLoading,
    setDetailsLoading,
    setTotalDocs,
    totalDocs,
    allData,
    setAllData,
  } = useDataContextHooks();

  const { handlers } = useContext(AppContext);
  const { getApi, postApi, putApi, getBlobResApi } = Api();

  //  handler
  const reconcileHandler = {
    getAllDataHandlers: async (query) => {
      try {
        setLoading(true);
        let res: any;
        res = await getApi(ApiUrl.bankReconciliation.get_bankWithStatement, {
          ...query,
          ...paginationQuery,
        });
        setTotalDocs(res?.data?.totalDocs);
        setAllData(res?.data?.docs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getReconcileItemsHandler: async (query) => {
      try {
        setLoading(true);
        let res: any;
        res = await getApi(ApiUrl.bankReconciliation.get_reconcile, {
          ...query,
          ...paginationQuery,
        });
        setReconcileItems(res?.data?.docs);
        setTotalReconcileItems(res?.data?.totalDocs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getReconcileItemDetailsHandler: async (itemID) => {
      try {
        setDetailsLoading(true);
        let res: any;
        res = await getApi(
          ApiUrl.bankReconciliation.get_reconcileDetails + itemID,
          {}
        );
        setReconcileItemDetails(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setDetailsLoading(false);
      }
    },
    setReconciliationStatementsHandler: async (
      statements: BankStatementInterface[]
    ) => {
      setReconciliationStatements(statements);
    },
    getReconciliationStatementHandler: async (itemID: string) => {
      try {
        setDetailsLoading(true);
        const res: any = await getApi(
          ApiUrl.bankReconciliation.get_reconciliationStatement + itemID,
          {
            ...paginationQuery,
          }
        );
        setReconciliationStatements(res?.data?.docs);
        setTotalReconciliationStatements(res?.data?.totalDocs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setDetailsLoading(false);
      }
    },
    matchStatementHandler: async (
      values,
      callback: HandlerCallbackInterface
    ) => {
      try {
        handlers?.setLoading(true);
        let res;

        res = await postApi(
          ApiUrl.bankReconciliation.post_reconcileMatch,
          values
        );
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res?.data);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
    transferBankStatementHandler: async (
      values,
      callback: HandlerCallbackInterface
    ) => {
      try {
        handlers?.setLoading(true);
        let res;

        res = await putApi(ApiUrl.bankStatement.put_transferStatement, values);
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
    uploadBankStatementHandler: async (
      values,
      callback: HandlerCallbackInterface
    ) => {
      try {
        handlers?.setLoading(true);
        let res;

        res = await putApi(ApiUrl.bankStatement.put_importExcel, values);
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
    downloadSampleFileHandler: async (name: AssetFileNameEnum) => {
      try {
        handlers?.setLoading(true);
        let res;
        res = await getBlobResApi(
          ApiUrl.bankReconciliation.get_assetsFile + name
        );
        downloadBlobFileHandler(res, name);
        handlers?.setSuccess(true, "Downloaded Successfully");
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
  };

  const contextValue = {
    totalDocs,
    allData,
    isDetailsLoading,
    isLoading,
    reconciliationStatements,
    totalReconciliationStatements,
    reconcileItemDetails,
    reconcileItems,
    totalReconcileItems,
    ...reconcileHandler,
  };
  return (
    <BankReconciliationContext.Provider value={contextValue}>
      {children}
    </BankReconciliationContext.Provider>
  );
}

export default DataContextProvider;
