import React, { createContext, useContext } from "react";

import { Api } from "helpers";
import { ApiUrl } from "services";
import { HandlerCallbackInterface } from "interfaces";
import { AppContext } from "context";

import { TrueLayerContextInterface } from "./interfaces";

export const TrueLayerContext = createContext<TrueLayerContextInterface>({
  editTrueLayerSettings(values, callback: HandlerCallbackInterface) {},
  getAuthLinkHandler(query, callback: HandlerCallbackInterface) {},
  setupBankFromCodeHandler(query, callback: HandlerCallbackInterface) {},
  syncTrueLayerHandler(query, callback: HandlerCallbackInterface) {},
});

function DataContextProvider({ children }) {
  const { getApi, postApi, putApi } = Api();
  const {
    handlers: { setLoading, setError, setSuccess },
  } = useContext(AppContext);
  //  handler
  const contextHandlers = {
    async editTrueLayerSettings(values, callback: HandlerCallbackInterface) {
      try {
        setLoading(true);
        const res = await putApi(
          ApiUrl.trueLayer.put_trueLayerSettings,
          values
        );
        if (typeof callback.onSuccess === "function") {
          callback.onSuccess(res?.data);
        }
        setSuccess(true, "Updated Successfully");
      } catch (err) {
        if (typeof callback.onError === "function") {
          callback?.onError(err);
        }
      } finally {
        setLoading(false);
      }
    },
    async getAuthLinkHandler(query: any, callback: HandlerCallbackInterface) {
      try {
        setLoading(true);
        const res = await getApi(ApiUrl.trueLayer.get_authLink, query);
        if (typeof callback.onSuccess === "function") {
          callback.onSuccess(res?.data);
        }
      } catch (err) {
        if (typeof callback.onError === "function") {
          callback?.onError(err);
        } else {
          setError(true, err?.message);
        }
      } finally {
        setLoading(false);
      }
    },
    async setupBankFromCodeHandler(
      query: any,
      callback: HandlerCallbackInterface
    ) {
      try {
        const res = await postApi(
          ApiUrl.trueLayer.post_setupBankFromCode,
          query
        );
        if (typeof callback.onSuccess === "function") {
          callback.onSuccess(res?.data);
        }
      } catch (err) {
        if (typeof callback.onError === "function") {
          callback?.onError(err?.message);
        }
      } finally {
      }
    },
    async syncTrueLayerHandler(query: any, callback: HandlerCallbackInterface) {
      try {
        setLoading(true);
        const res: any = await putApi(
          ApiUrl.trueLayer.put_syncStatement,
          query
        );
        if (typeof callback.onSuccess === "function") {
          callback.onSuccess(res?.data);
        }
        setSuccess(true, res?.message);
      } catch (err) {
        if (typeof callback.onError === "function") {
          callback?.onError(err?.message);
        }
        setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
  };

  const contextValue = {
    ...contextHandlers,
  };
  return (
    <TrueLayerContext.Provider value={contextValue}>
      {children}
    </TrueLayerContext.Provider>
  );
}

export default DataContextProvider;
