import { createContext, useContext } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { ApiUrl } from "services";
import { AppContext } from "context";
import { Api } from "helpers";
import { SearchQueryEnum } from "../interfaces";
import { AuthTokenENUM, baseUrlType } from "../../../interfaces";
import { FormikHelpers } from "formik/dist/types";

export const DataContext = createContext({
  loginHandler: (values, actions) => {},
  signUpAsLandlordHandler: (values, actions: FormikHelpers<any>) => {},
  requestForgetPasswordHandler: (values, actions: FormikHelpers<any>) => {},
  validateForgetPasswordOTPHandler: (values, actions: FormikHelpers<any>) => {},
  resetPasswordHandler: (values, actions: FormikHelpers<any>) => {},
});

export default function DataContextProvider() {
  const { postApi } = Api();
  const { handlers } = useContext(AppContext);
  const navigate = useNavigate();
  const { login } = handlers;
  const [params] = useSearchParams();
  const paramHash = decodeURIComponent(params.get(SearchQueryEnum.HASH) || "");
  const paramEmail = decodeURIComponent(
    params.get(SearchQueryEnum.EMAIL) || ""
  );
  const paramToken = decodeURIComponent(params.get(SearchQueryEnum.TOKEN));

  const loginHandler = async (values, { setFieldError, setSubmitting }) => {
    try {
      setSubmitting(true);
      let res: any = await postApi(ApiUrl.auth.post_login, values);
      let refreshToken = res?.data?.refreshToken;

      login(refreshToken);
    } catch (error) {
      setFieldError("error", error?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const signUpAsLandlordHandler = async (
    values,
    { setSubmitting, setFieldError }
  ) => {
    try {
      setSubmitting(true);
      await postApi(ApiUrl.auth.post_registerAsLandLord, values);
      navigate("/login");
    } catch (error) {
      setFieldError("error", error?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const requestForgetPasswordHandler = async (values, action) => {
    try {
      action?.setSubmitting && action?.setSubmitting(true);
      let res: any = await postApi(
        ApiUrl.auth.post_requestForgetPassword,
        values
      );
      const email = encodeURIComponent(res?.data?.email);
      const hash = encodeURIComponent(res?.data?.hash);

      navigate({
        pathname: "/verifyOTP",
        search: `${SearchQueryEnum?.HASH}=${hash}&${SearchQueryEnum.OTP_LENGTH}=${res?.data?.otpLength}&${SearchQueryEnum?.EMAIL}=${email}`,
      });
      handlers?.setSuccess(true, res?.message);
    } catch (error) {
      action?.setFieldError &&
        action?.setFieldError("email", error?.message, true);
    } finally {
      action?.setSubmitting && action?.setSubmitting(false);
    }
  };
  const validateForgetPasswordOTPHandler = async (
    values,
    { setSubmitting, setFieldError }
  ) => {
    try {
      setSubmitting(true);
      let res: any = await postApi(ApiUrl.auth.post_validateForgetPasswordOtp, {
        ...values,
        hash: paramHash,
        email: paramEmail,
      });
      const token = encodeURIComponent(res?.data?.token);
      navigate({
        pathname: "/changePassword",
        search: `${SearchQueryEnum?.TOKEN}=${token}&${SearchQueryEnum?.EMAIL}=${paramEmail}`,
      });
    } catch (error) {
      setFieldError("otp", error?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const resetPasswordHandler = async (
    values,
    { setSubmitting, setFieldError }
  ) => {
    try {
      setSubmitting(true);
      let token = `Bearer ${paramToken}`;
      await postApi(
        ApiUrl.auth.put_resetPassword,
        values,
        AuthTokenENUM.refreshToken,
        baseUrlType.DEFAULT,
        token
      );
      navigate("/login");
      handlers?.setSuccess(true, "Password saved");
    } catch (error) {
      setFieldError("error", error?.message);
    } finally {
      setSubmitting(false);
    }
  };

  let contextValue = {
    loginHandler,
    signUpAsLandlordHandler,
    requestForgetPasswordHandler,
    validateForgetPasswordOTPHandler,
    resetPasswordHandler,
  };
  return (
    <DataContext.Provider value={contextValue}>
      <Outlet />
    </DataContext.Provider>
  );
}
