import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { MySearchInput, MySearchSelect } from "components";
import {
  DateRangeOptionEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
  TransactionTypeEnum,
} from "interfaces";
import {
  decodeBankOptionsHandler,
  decodeDynamicFieldHandler,
  decodeFiscalYearOptionsHandler,
  decodeLedgerNameOptionsHandler,
  decodePropertyOptionsHandler,
  decodeUnitOptionsHandler,
  lastMonthEnd,
  lastMonthStart,
  lastYearEnd,
  lastYearStart,
  thisMonthEnd,
  thisMonthStart,
  thisYearEnd,
  thisYearStart,
} from "helpers";
import { OptionsContext } from "context";
import { TableFilterCardInterface } from "./interface";
import { DateRangeOptions } from "./helpers";
import { useAuthorizationHook, usePaginationQueryHooks } from "../../useHooks";

function TableFilterCard({
  haveDynamicOption,
  haveUnit,
  haveProperty,
  haveDateRange,
  haveLedgerAccountFilter,
  children,
  haveLabel,
  haveBank,
  haveFiscalYear,
  haveTransactionType,
  haveDynamicOption2,
}: TableFilterCardInterface) {
  const [query, setQuery] = useSearchParams();
  const { setCurrentPage } = usePaginationQueryHooks();
  const propertyID = query?.get(QueryStringEnum.PROPERTY_ID);
  const rangeType: DateRangeOptionEnum | any = query?.get(
    QueryStringEnum.RANGE_TYPE
  );
  const { checkCanAccessHandler } = useAuthorizationHook();
  const havePropertyAccess = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_READ_PROPERTY,
  ]);
  const haveUnitAccess = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_READ_UNIT,
  ]);
  const haveBankAccess = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_READ_BANK,
  ]);
  const haveLedgerNameAccess = checkCanAccessHandler([
    SpaceAccessListEnum.ACCOUNT_READ_LEDGER_NAME,
  ]);
  const {
    propertyOptions,
    unitOptions,
    fiscalYearOptions,
    ledgerNameOptions,
    bankOptions,
    fieldTypeOptions,
    getFiscalYearOptionsHandler,
    getPropertyOptionsHandler,
    getUnitOptionsHandler,
    getOptionsHandler,
    getLedgerOptionsHandler,
    getBankOptionsHandler,
  } = useContext(OptionsContext);
  useEffect(() => {
    (async () => {
      await Promise.all([
        haveBank && haveBankAccess && (await getBankOptionsHandler()),
        haveProperty &&
          havePropertyAccess &&
          (await getPropertyOptionsHandler()),
        (haveDateRange || haveFiscalYear) &&
          (await getFiscalYearOptionsHandler()),
        haveLedgerAccountFilter &&
          haveLedgerNameAccess &&
          (await getLedgerOptionsHandler()),
        haveDynamicOption &&
          (await getOptionsHandler(haveDynamicOption?.fieldName)),
        haveDynamicOption2 &&
          (await getOptionsHandler(haveDynamicOption2?.fieldName)),
      ]);
    })();
  }, [haveBankAccess, havePropertyAccess, haveLedgerNameAccess]);
  useEffect(() => {
    if (propertyID && haveUnit && haveUnitAccess) {
      getUnitOptionsHandler(propertyID);
    }
  }, [propertyID, haveUnit, haveUnitAccess]);
  return (
    <div className={"flex gap-5"}>
      {haveProperty && havePropertyAccess && (
        <div className={"w-[250px]"}>
          <MySearchSelect
            label={haveLabel && "Property"}
            isClearable
            paramsName={QueryStringEnum.PROPERTY_ID}
            option={{
              selectOptions: decodePropertyOptionsHandler(propertyOptions),
              defaultLabel: "Select Property",
            }}
            onSelectHandler={(value) => {
              if (!value) {
                query.set(QueryStringEnum?.UNIT_ID, "");
              }
              query.set(QueryStringEnum?.PROPERTY_ID, value);
              setQuery(query);
              setCurrentPage("1");
            }}
          />
        </div>
      )}
      {haveUnit && propertyID && haveUnitAccess && (
        <div className={"w-[250px]"}>
          <MySearchSelect
            label={haveLabel && "Unit"}
            isClearable
            paramsName={QueryStringEnum.UNIT_ID}
            option={{
              selectOptions: decodeUnitOptionsHandler(unitOptions),
              defaultLabel: "Select Unit",
            }}
            onSelectHandler={(value) => {
              query.set(QueryStringEnum?.UNIT_ID, value);
              setQuery(query);
              setCurrentPage("1");
            }}
            fetchOptionsHandler={() => getUnitOptionsHandler(propertyID)}
          />
        </div>
      )}
      {haveBank && haveBankAccess && (
        <div className={"w-[250px]"}>
          <MySearchSelect
            label={haveLabel && "Bank"}
            isClearable
            paramsName={QueryStringEnum.BANK}
            option={{
              selectOptions: decodeBankOptionsHandler(bankOptions),
              defaultLabel: "Banks",
            }}
            onSelectHandler={(value) => {
              query.set(QueryStringEnum?.BANK, value);
              setQuery(query);
              setCurrentPage("1");
            }}
          />
        </div>
      )}{" "}
      {haveTransactionType && (
        <div className={"w-[250px]"}>
          <MySearchSelect
            label={haveLabel && "Transaction Type"}
            isClearable
            paramsName={QueryStringEnum.TRANSACTION_TYPE}
            option={{
              selectOptions: [
                {
                  value: TransactionTypeEnum.DR,
                  label: "Debit",
                },
                {
                  value: TransactionTypeEnum.CR,
                  label: "Credit",
                },
              ],
              defaultLabel: "Transaction Type",
            }}
            onSelectHandler={(value) => {
              query.set(QueryStringEnum?.TRANSACTION_TYPE, value);
              setQuery(query);
              setCurrentPage("1");
            }}
          />
        </div>
      )}
      {haveDynamicOption && (
        <div className={"min-w-[200px]"}>
          <MySearchSelect
            isClearable
            paramsName={haveDynamicOption.queryName}
            option={{
              selectOptions: decodeDynamicFieldHandler(
                fieldTypeOptions[haveDynamicOption?.fieldName]
              ),
              defaultLabel: haveDynamicOption?.placeholder,
            }}
            onSelectHandler={(value) => {
              query.set(haveDynamicOption?.queryName, value);
              setQuery(query);
              setCurrentPage("1");
            }}
            fetchOptionsHandler={() =>
              getOptionsHandler(haveDynamicOption?.fieldName)
            }
          />
        </div>
      )}{" "}
      {haveDynamicOption2 && (
        <div className={"min-w-[200px]"}>
          <MySearchSelect
            isClearable
            paramsName={haveDynamicOption2.queryName}
            option={{
              selectOptions: decodeDynamicFieldHandler(
                fieldTypeOptions[haveDynamicOption2?.fieldName]
              ),
              defaultLabel: haveDynamicOption2?.placeholder,
            }}
            onSelectHandler={(value) => {
              query.set(haveDynamicOption2?.queryName, value);
              setQuery(query);
              setCurrentPage("1");
            }}
            fetchOptionsHandler={() =>
              getOptionsHandler(haveDynamicOption2?.fieldName)
            }
          />
        </div>
      )}
      {haveLedgerAccountFilter && haveLedgerNameAccess && (
        <div className={"w-[250px]"}>
          <MySearchSelect
            option={{
              selectOptions: decodeLedgerNameOptionsHandler(ledgerNameOptions),
              defaultLabel: "Select Account",
            }}
            paramsName={QueryStringEnum.ACCOUNT_TYPE}
            label={haveLabel && "Account"}
            isClearable
          />
        </div>
      )}
      {children}
      {haveFiscalYear && (
        <>
          <MySearchSelect
            isClearable
            label={"Fiscal Year"}
            option={{
              selectOptions: decodeFiscalYearOptionsHandler(fiscalYearOptions),
            }}
            onSelectHandler={(value) => {
              if (!value) {
                query.set(QueryStringEnum.START_DATE, "");
                query.set(QueryStringEnum.END_DATE, "");
                query.set(QueryStringEnum.FISCAL_YEAR, "");
                return;
              }
              const fiscalYear = fiscalYearOptions.find(
                (e) => e?.title == value
              );
              if (!fiscalYear) {
                return;
              }
              query.set(QueryStringEnum.START_DATE, fiscalYear.startFrom);
              query.set(QueryStringEnum.END_DATE, fiscalYear.endTo);
              query.set(QueryStringEnum.FISCAL_YEAR, value);
              setQuery(query);
              setCurrentPage("1");
            }}
            paramsName={QueryStringEnum.FISCAL_YEAR}
          />
        </>
      )}
      {haveDateRange && (
        <div className={"flex items-center gap-5"}>
          <div className={"w-[250px]"}>
            <MySearchSelect
              isClearable
              selectedValue={DateRangeOptionEnum.THIS_MONTH}
              label={haveLabel && "Report Period"}
              option={{
                selectOptions: DateRangeOptions,
                defaultLabel: "Select Date Range Type",
              }}
              onSelectHandler={(value) => {
                query.set(QueryStringEnum.RANGE_TYPE, value);
                if (value === DateRangeOptionEnum.THIS_MONTH) {
                  query.set(QueryStringEnum.START_DATE, thisMonthStart);
                  query.set(QueryStringEnum.END_DATE, thisMonthEnd);
                } else if (value === DateRangeOptionEnum.THIS_YEAR) {
                  query.set(QueryStringEnum.START_DATE, thisYearStart);
                  query.set(QueryStringEnum.END_DATE, thisYearEnd);
                } else if (value === DateRangeOptionEnum.LAST_MONTH) {
                  query.set(QueryStringEnum.START_DATE, lastMonthStart);
                  query.set(QueryStringEnum.END_DATE, lastMonthEnd);
                } else if (value === DateRangeOptionEnum.LAST_YEAR) {
                  query.set(QueryStringEnum.START_DATE, lastYearStart);
                  query.set(QueryStringEnum.END_DATE, lastYearEnd);
                } else {
                  query.set(QueryStringEnum.START_DATE, "");
                  query.set(QueryStringEnum.END_DATE, "");
                }
                setCurrentPage("1");

                setQuery(query);
              }}
              paramsName={QueryStringEnum.RANGE_TYPE}
            />
          </div>
          {rangeType === DateRangeOptionEnum.FISCAL_YEAR && (
            <>
              <MySearchSelect
                isClearable
                label={haveLabel && "Fiscal Year"}
                option={{
                  selectOptions:
                    decodeFiscalYearOptionsHandler(fiscalYearOptions),
                }}
                onSelectHandler={(value) => {
                  if (!value) {
                    query.set(QueryStringEnum.START_DATE, "");
                    query.set(QueryStringEnum.END_DATE, "");
                    query.set(QueryStringEnum.FISCAL_YEAR, "");
                    return;
                  }
                  const fiscalYear = fiscalYearOptions.find(
                    (e) => e?.title == value
                  );
                  if (!fiscalYear) {
                    return;
                  }
                  query.set(QueryStringEnum.START_DATE, fiscalYear.startFrom);
                  query.set(QueryStringEnum.END_DATE, fiscalYear.endTo);
                  query.set(QueryStringEnum.FISCAL_YEAR, value);
                  setQuery(query);
                  setCurrentPage("1");
                }}
                paramsName={QueryStringEnum.FISCAL_YEAR}
              />
            </>
          )}
          {rangeType === DateRangeOptionEnum.CUSTOM && (
            <>
              <MySearchInput
                label={haveLabel && "Start Date"}
                type={"date"}
                paramsName={QueryStringEnum.START_DATE}
              />
              <MySearchInput
                label={haveLabel && "End Date"}
                type={"date"}
                paramsName={QueryStringEnum.END_DATE}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default TableFilterCard;
