import React, { useContext } from "react";
import { MySelectField, MyTextAreaInput, MyTextField } from "components";
import { FormWrapper } from "assets";
import { FieldTypeEnum } from "interfaces";
import { decodeDynamicFieldHandler, getCurrency } from "helpers";
import { OptionsContext } from "context";
function UnitForm() {
  const { fieldTypeOptions } = useContext(OptionsContext);
  return (
    <FormWrapper header={{ title: { name: "Unit Details" } }}>
      <div
        className={`grid grid-cols-3 gap-10 items-start md:grid-cols-2 sm:grid-cols-1`}
      >
        <MyTextField
          name={"name"}
          label={"Unit Name"}
          isRequired
          placeholder={"eg. Apartment 1"}
        />
        <MyTextField
          name={"area.size"}
          label={"Unit Quantity"}
          type={"number"}
          placeholder={"eg. 1250"}
        />
        <MySelectField
          name={"area.unit"}
          label={"Unit Size"}
          option={{
            selectOptions: decodeDynamicFieldHandler(
              fieldTypeOptions[FieldTypeEnum.UNIT_SIZE],
              "Sq. "
            ),
          }}
        />
        {/*</GroupInputComponent>*/}
        <MyTextField
          name={"marketRent"}
          label={`Market Rent (${getCurrency()})`}
          placeholder={"Eg. 1250"}
          type={"number"}
        />
        <MySelectField
          name={"furnishedType"}
          label={"Furnished"}
          isRequired
          option={{
            selectOptions: decodeDynamicFieldHandler(
              fieldTypeOptions[FieldTypeEnum.FURNISHED_TYPE]
            ),
          }}
        />
        <MySelectField
          label={"Select Floor"}
          name={"floor"}
          option={{
            selectOptions: decodeDynamicFieldHandler(
              fieldTypeOptions[FieldTypeEnum.SELECT_FLOOR]
            ),
          }}
        />
      </div>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1"}>
        <div className={"col-span-2"}>
          <MyTextAreaInput name={"description"} label={"Description"} />
        </div>
      </div>
    </FormWrapper>
  );
}

export default UnitForm;
