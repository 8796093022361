import React, { useContext, useEffect } from "react";
import { EmptyMessageComponent } from "components";
import { DynamicListContext } from "context";
import { useParams } from "react-router-dom";
import { DynamicConstantInterface } from "interfaces";
import { PageTemplate } from "templates";
import Skeleton from "react-loading-skeleton";
import { usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function NamePage() {
  const { getConstantListHandler, constantLists, isLoading } =
    useContext(DynamicListContext);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const params = useParams();
  const p1 = params?.p1 ? parseInt(params?.p1) : -1;
  const p2 = params?.p2 ? parseInt(params?.p2) : -1;
  const p3 = params?.p3 ? parseInt(params?.p3) : -1;
  useEffect(() => {
    getConstantListHandler();
  }, []);
  const selectedLists = () => {
    let lists: DynamicConstantInterface[] = constantLists;
    let title;
    if (p1 > -1 && lists?.length > p1) {
      title = lists[p1]?.title;
      lists = lists[p1]?.children;
    }
    if (p2 > -1 && lists?.length > p2) {
      title = lists[p2]?.title;
      lists = lists[p2]?.children;
    }
    if (p3 > -1 && lists?.length > p3) {
      title = lists[p3]?.title;
      lists = lists[p3]?.children;
    }
    return { lists, title };
  };
  const { lists, title } = selectedLists();
  const onClickHandler = (e: DynamicConstantInterface, index) => {
    if (Array.isArray(e?.children)) {
      navigateWithReturnUrlHandler(
        PageLinks.dynamicLists.list(
          p3 > -1
            ? [p1, p2, p3, index]
            : p2 > -1
            ? [p1, p2, index]
            : p1 > -1
            ? [p1, index]
            : [index]
        )
      );
    } else {
      navigateWithReturnUrlHandler(
        PageLinks.dynamicLists.nameOptions(e?.fieldType)
      );
    }
  };
  return (
    <PageTemplate
      title={"Administrative Setup"}
      backNavigation={
        title && {
          title: title,
          path: PageLinks.dynamicLists.list(
            p3 > -1 ? [p1, p2] : p2 > -1 ? [p1] : []
          ),
        }
      }
    >
      <div className={"mt-10"}>
        <div
          className={
            "grid grid-cols-7 md:grid-cols-4 sm:grid-cols-3 sm:grid-cols-2  gap-10"
          }
        >
          {!isLoading && (constantLists?.length < 1 || lists?.length < 1) && (
            <div
              className={
                "col-span-full flex flex-1 items-center justify-center w-full"
              }
            >
              <EmptyMessageComponent message={"No Data found"} />
            </div>
          )}
          {isLoading
            ? new Array(10)?.fill("")?.map((e, key) => {
                return (
                  <ModuleCard
                    name={""}
                    isLoading={true}
                    key={key}
                    image={""}
                    onClickHandler={() => {}}
                  />
                );
              })
            : lists?.map((e, key) => {
                return (
                  <ModuleCard
                    name={e?.title}
                    isLoading={false}
                    key={key}
                    image={e?.icon}
                    onClickHandler={() => onClickHandler(e, key)}
                  />
                );
              })}
        </div>
      </div>
    </PageTemplate>
  );
}

function ModuleCard({ name, image, onClickHandler, isLoading }) {
  return (
    <div
      onClick={onClickHandler}
      className={
        "flex items-center flex-col min-h-[137px]  justify-center cursor-pointer  text-center  text-primary gap-5 p-2 border-[1px] rounded-md text-primary hover:border-primary"
      }
    >
      {image ? (
        <img
          src={image}
          className={"h-[20px] w-[20px] object-contain fill-primary"}
        />
      ) : (
        <div className={"h-[30px] w-[30px]"} />
      )}
      {isLoading ? (
        <Skeleton className={"w-[80px]"} />
      ) : (
        <span className={"text-[14px] text-primary"}>{name}</span>
      )}
    </div>
  );
}

export default NamePage;
