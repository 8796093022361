import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { TableInterface } from "interfaces";
import { TableRowInterface } from "./interfaces";
import "./styles.css";

const Table = ({
  loading,
  column,
  dataSource,
  rowSelections,
}: TableInterface) => {
  return (
    <>
      <table className="table_container table-auto ">
        <thead className="table_head">
          <tr>
            {column.map((e, index) => (
              <th
                scope="col"
                className={`whitespace-normal ${e?.titleStyleClassName || ""}`}
                key={index}
              >
                {e?.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {loading?.isLoading
            ? Array.apply(0, new Array(loading?.loadingRow || 8)).map(
                (e, key) => {
                  return (
                    <TableRow
                      key={key}
                      index={key}
                      column={column}
                      isLoading={true}
                    />
                  );
                }
              )
            : dataSource?.map((item, key) => (
                <TableRow
                  key={key}
                  index={key}
                  column={column}
                  isLoading={false}
                  item={item}
                  rowSelection={rowSelections}
                />
              ))}
        </tbody>
      </table>
      {!loading?.isLoading && dataSource?.length < 1 && (
        <div className="text-center w-full bg-blue-50 p-1 ">No data</div>
      )}
    </>
  );
};

export default Table;

export const TableRow = ({
  index,
  column,
  isLoading,
  item,
}: TableRowInterface) => {
  return (
    <tr className={`table_row `}>
      {column?.map((e, key) => (
        <td key={key}>
          {isLoading ? (
            <Skeleton />
          ) : (
            typeof e?.render === "function" && e?.render(item, index)
          )}
        </td>
      ))}
    </tr>
  );
};
