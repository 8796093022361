import { createContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import {
  appReducer,
  appState,
  logoutAction,
  setAuthenticateAction,
  setAuthLoadingAction,
  setCurrentWorkspaceAction,
  setErrorAction,
  setLoadingAction,
  setPageErrorAction,
  setProfileAction,
  setSpaceSettingAction,
  setSuccessAction,
  setTenantSpaceListAction,
  setTrueLayerSettingAction,
  setTwilioSettingAction,
  switchWorkspaceAction,
  setAppSettingActions,
} from "stores";
import { useAuthorizationHook, useConfirmHook } from "useHooks";

import {
  Api,
  clearAuthToken,
  getAuthToken,
  setAccessTokenAttempted,
  setAuthToken,
} from "helpers";
import {
  AuthTokenENUM,
  CustomerInterface,
  SpaceAccessListEnum,
} from "interfaces";
import { ApiUrl } from "services";
import { Loader } from "components";
import { toast, ToastContainer } from "react-toastify";
import { PageLinks } from "routes";

export const AppContext = createContext({
  state: appState,
  handlers: {
    login: (token: string) => {},
    logout: (askConfim?: boolean, skipApiCall?: boolean) => {},
    getCurrentProfile: (
      successCallback?: (data: CustomerInterface) => {}
    ) => {},
    getCurrentWorkspaceHandler: () => {},
    setError: (flag: boolean, message?: string) => {},
    setLoading: (payload: any) => {},
    setAuthLoading: (payload: any) => {},
    getTenantSpaceListHandler: () => {},
    getSpaceSettingsHandler: () => {},
    getTrueLayerSettingsHandler: () => {},
    editSpaceSettingsHandler: (payload) => {},
    getTwilioSettingsHandler: () => {},
    editTwilioSettingsHandler: (payload) => {},
    loginTenantSpaceHandler: (tenantId: string) => {},
    accountResetHandler: () => {},
    setSuccess: (flag: boolean, message?: string) => {},
    switchWorkspaceHandler: () => {},
    dispatchLogoutActionHandler: () => {},
    setPageErrorHandler: (status: boolean, message?: string) => {},
  },
});

const AppProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(appReducer, appState);
  const navigate = useNavigate();
  const { getApi, putApi, deleteApi } = Api();
  const { confirm } = useConfirmHook();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const haveTrueLayerAccess = checkCanAccessHandler([
    SpaceAccessListEnum.CONFIGURATION_MANAGE_TRUE_LAYER_SETTINGS,
  ]);
  const haveSpaceSettingAccess = checkCanAccessHandler([
    SpaceAccessListEnum.CONFIGURATION_MANAGE_SETTINGS,
  ]);
  const logoutRedirectHandler = () => {
    navigate({
      pathname: "/login",
    });
  };
  const getTenantSpaceListHandler = async () => {
    try {
      setLoading(true);
      const res = await getApi(
        ApiUrl?.auth.get_tenantSpaceList,
        {},
        AuthTokenENUM.loginRefreshToken
      );
      dispatch(setTenantSpaceListAction(res?.data));
    } catch (err) {
      setError(true, err?.message);
      dispatch(setAuthLoadingAction(false));
    } finally {
      setLoading(false);
    }
  };
  const getCurrentWorkspaceHandler = async () => {
    try {
      const res = await getApi(ApiUrl?.auth.get_currentWorkspace);
      dispatch(setCurrentWorkspaceAction(res?.data));
    } catch (err) {
      setError(true, err?.message);
    } finally {
    }
  };
  const getTwilioSettingsHandler = async () => {
    try {
      setLoading(true);
      const res = await getApi(ApiUrl?.app.get_twilioSettings);
      dispatch(setTwilioSettingAction(res?.data));
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  const getTrueLayerSettingsHandler = async () => {
    try {
      const res = await getApi(ApiUrl?.trueLayer.get_trueLayerSettings);
      dispatch(setTrueLayerSettingAction(res?.data));
    } catch (err) {
      setError(true, err?.message);
    } finally {
    }
  };
  const getSpaceSettingsHandler = async () => {
    try {
      const res = await getApi(ApiUrl?.app.get_spaceSettings);
      dispatch(setSpaceSettingAction(res?.data));
    } catch (err) {
      setError(true, err?.message);
    } finally {
    }
  };
  const editSpaceSettingsHandler = async (payload) => {
    try {
      setLoading(true);
      await putApi(ApiUrl?.app.put_spaceSettings, payload);
      setSuccess(true, "Updated Successfully!");
      await getSpaceSettingsHandler();
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  const editTwilioSettingsHandler = async (payload) => {
    try {
      setLoading(true);
      await putApi(ApiUrl?.app.put_twilioSettings, payload);
      setSuccess(true, "Updated Successfully!");
      await getTwilioSettingsHandler();
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  const accountResetHandler = async () => {
    try {
      let isConfirm = await confirm(
        "Are you sure you want to flush entire finance Data?"
      );
      if (!isConfirm) {
        return;
      }
      setLoading(true);
      await deleteApi(ApiUrl?.bankStatement.put_accountReset);
      setSuccess(true, "Reset Successfully!");
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  const seedUserHandler = async () => {
    try {
      setLoading(true);
      const res = await putApi(ApiUrl?.app.put_seedUser, {});
      setSuccess(true, res?.data);
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };
  const getAppSettingsHandler = async () => {
    try {
      const res = await getApi(ApiUrl?.app.get_appSettings, {});
      dispatch(setAppSettingActions(res?.data));
    } catch (err) {
    } finally {
    }
  };
  const initialNavigateHandler = (
    currentData: CustomerInterface,
    ableToGoHome?: boolean
  ) => {
    if (
      state?.isAuthenticated &&
      currentData &&
      currentData?.systemID?.forceChangePassword
    ) {
      navigate(PageLinks.profile.setPasswordForFirstTime);
      return;
    }
    if (state?.isAuthenticated && currentData && !currentData?.subscription) {
      navigate(PageLinks.subscription.subscriptionList);
      return;
    }

    if (ableToGoHome) {
      navigate("/");
      return;
    }
  };

  const loginTenantSpaceHandler = async (tenantId: string) => {
    try {
      setAuthLoading(true);
      const res = await getApi(
        ApiUrl?.auth.get_loginToTenantSpace,
        { tenant: tenantId },
        AuthTokenENUM.loginRefreshToken
      );
      setAuthToken(res?.data?.accessToken, AuthTokenENUM.accessToken);
      setAuthToken(res?.data?.refreshToken, AuthTokenENUM.refreshToken);
      await Promise.all([
        getCurrentProfile((data) => {
          initialNavigateHandler(data, true);
        }),
        getCurrentWorkspaceHandler(),
        haveTrueLayerAccess && getTrueLayerSettingsHandler(),
        haveSpaceSettingAccess && getSpaceSettingsHandler(),
      ]);
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setAuthLoading(false);
    }
  };
  const getCurrentProfile = async (successCallback?: any) => {
    try {
      const res = await getApi(ApiUrl.auth.get_currentProfile);
      const data: CustomerInterface = res?.data;
      dispatch(setProfileAction(data));
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (error) {
      setError(true, error?.message);
      clearAuthToken(AuthTokenENUM.refreshToken);
      clearAuthToken(AuthTokenENUM.accessToken);
      clearAuthToken(AuthTokenENUM.loginRefreshToken);
      logoutRedirectHandler();
    } finally {
    }
  };

  const authCheckHandler = async () => {
    try {
      if (
        getAuthToken(AuthTokenENUM.refreshToken) &&
        getAuthToken(AuthTokenENUM.accessToken) &&
        getAuthToken(AuthTokenENUM.loginRefreshToken)
      ) {
        setAuthLoading(true);
        await Promise.all([
          getCurrentProfile(),
          getCurrentWorkspaceHandler(),
          getSpaceSettingsHandler(),
          haveTrueLayerAccess && getTrueLayerSettingsHandler(),
        ]);
      } else if (getAuthToken(AuthTokenENUM.loginRefreshToken)) {
        setAuthenticateHandler(true);
      } else {
        // logoutRedirectHandler();
      }
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setAuthLoading(false);
    }
  };
  useEffect(() => {
    (async () => {
      await authCheckHandler();
      await getAppSettingsHandler();
    })();
  }, []);

  // dispatch handlers
  const login = async (token: string) => {
    setAuthToken(token, AuthTokenENUM.loginRefreshToken);
    setAuthenticateHandler(true);
    setAccessTokenAttempted(false);
    await authCheckHandler();
  };

  useEffect(() => {
    if (state?.profileDetails?.systemID?.forceLogout) {
      logout(false, true);
    }
  }, [state?.profileDetails]);

  useEffect(() => {
    if (state?.isActionSuccess) {
      toast.success(state?.successMessage, {
        onClose: () => setSuccess(false, ""),
      });
    }
    if (state?.isError) {
      toast.error(state?.errorMessage, {
        onClose: () => setError(false, ""),
      });
    }
  }, [state?.isError, state.isActionSuccess]);

  const logout = async (
    askConfirm: boolean = true,
    skipApiCall: boolean = false
  ) => {
    try {
      if (askConfirm) {
        let isConfirm = await confirm(
          "Are you  sure you want to log out?",
          "Log Out",
          "Stay Logged In"
        );
        if (!isConfirm) {
          return;
        }
      }
      setLoading(true);
      if (getAuthToken(AuthTokenENUM.accessToken) && !skipApiCall) {
        await getApi(ApiUrl.auth.get_logout, {}, AuthTokenENUM.accessToken);
      }
      dispatch(logoutAction({}));
      clearAuthToken(AuthTokenENUM.accessToken);
      clearAuthToken(AuthTokenENUM.refreshToken);
      clearAuthToken(AuthTokenENUM.loginRefreshToken);
      logoutRedirectHandler();
    } catch (error) {
      dispatch(logoutAction({}));
      clearAuthToken(AuthTokenENUM.accessToken);
      clearAuthToken(AuthTokenENUM.refreshToken);
      clearAuthToken(AuthTokenENUM.loginRefreshToken);
      logoutRedirectHandler();
    } finally {
      setLoading(false);
    }
  };
  const switchWorkspaceHandler = async () => {
    try {
      let isConfirm = await confirm(
        "You are about to switch to a different workspace. Are you sure you want to proceed?",
        "Switch Workspace",
        "Cancel"
      );
      if (!isConfirm) {
        return;
      }
      setLoading(true);
      await getApi(ApiUrl.auth.get_logout, {}, AuthTokenENUM.accessToken);
      dispatch(switchWorkspaceAction());
      clearAuthToken(AuthTokenENUM.accessToken);
      clearAuthToken(AuthTokenENUM.refreshToken);
      navigate(PageLinks.workSpace);
    } catch (error) {
      setError(true, error?.message);
    } finally {
      setLoading(false);
    }
  };

  const setAuthenticateHandler = (payload: boolean) => {
    dispatch(setAuthenticateAction(payload));
  };
  const dispatchLogoutActionHandler = () => {
    dispatch(logoutAction({}));
  };

  const setError = (flag: boolean, message?: string) => {
    dispatch(setErrorAction(flag, message));
  };
  const setLoading = (payload: any) => {
    dispatch(setLoadingAction(payload));
  };

  const setSuccess = (flag: boolean, message?: string) => {
    dispatch(setSuccessAction(flag, message));
  };
  const setAuthLoading = (flag: boolean) => {
    dispatch(setAuthLoadingAction(flag));
  };
  const setPageErrorHandler = (status: boolean, message?: string) => {
    dispatch(setPageErrorAction(status, message));
  };

  let contextValue = {
    state: state,
    handlers: {
      login,
      logout,
      setError,
      setLoading,
      setSuccess,
      setAuthLoading,
      getCurrentProfile,
      accountResetHandler,
      getTenantSpaceListHandler,
      getCurrentWorkspaceHandler,
      getTwilioSettingsHandler,
      editTwilioSettingsHandler,
      loginTenantSpaceHandler,
      getSpaceSettingsHandler,
      editSpaceSettingsHandler,
      getTrueLayerSettingsHandler,
      switchWorkspaceHandler,
      dispatchLogoutActionHandler,
      setPageErrorHandler,
    },
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
      {state.isLoading && <Loader />}
      {state.isAuthLoading && <Loader />}
      <ToastContainer limit={2} autoClose={5000} position={"bottom-right"} />
    </AppContext.Provider>
  );
};

export default AppProvider;
