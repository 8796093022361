import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  BookingInterface,
  FieldTypeEnum,
  SpaceAccessListEnum,
  TableColumnInterface,
} from "interfaces";
import { MyMoreOptionButton, MyStatusSelectButton } from "components";

import { BookingDataContext, OptionsContext } from "context";
import {
  decodeOptionColorHandler,
  getCurrency,
  separateWithComma,
} from "helpers";
import {
  useAuthorizationHook,
  useDateHook,
  usePaginationQueryHooks,
} from "useHooks";
import { PropertyUnitTableText, UserTableProfile } from "assets";
import { PageLinks } from "routes";

function BookingTableColumns(query): TableColumnInterface<BookingInterface>[] {
  const navigate = useNavigate();
  const {
    archiveHandler,
    getAllDataHandlers,
    deleteHandler,
    editDetailsHandler,
  } = useContext(BookingDataContext);
  const { fieldTypeOptions, getOptionsHandler } = useContext(OptionsContext);
  const { getDateHandler, getStaffFullName } = useDateHook();
  const { calcSNHandler } = usePaginationQueryHooks();
  const bookingsStatusOptions = fieldTypeOptions[
    FieldTypeEnum.BOOKING_STATUS
  ]?.map((e) => {
    return {
      label: e?.label,
      value: e?._id,
      textColor: decodeOptionColorHandler(e?.value).textColor,
      bgColor: decodeOptionColorHandler(e?.value).bgColor,
    };
  });

  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.BOOKING_EDIT_BOOKING,
  ]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.BOOKING_DEL_BOOKING,
  ]);

  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <span>{calcSNHandler(key)}</span>;
      },
    },
    {
      title: "Property",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.property?.propertyID}
            address={renderData?.property?.address}
            propertyID={renderData?.property?._id}
          />
        );
      },
    },
    {
      title: "Unit",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.unit?.unitID}
            type={"unit"}
            name={renderData?.unit?.name}
            propertyID={renderData?.property?._id}
            unitID={renderData?.unit?._id}
          />
        );
      },
    },
    {
      title: "Tenant",
      render(renderData) {
        return (
          <UserTableProfile
            details={{
              name: renderData?.tenant?.userID,
              profile: renderData?.tenant?.photo,
              subTitle: getStaffFullName(renderData?.tenant?.name),
            }}
            firstLetter={renderData?.tenant?.name?.first?.charAt(0)}
          />
        );
      },
    },
    {
      title: `Amount`,
      render(renderData) {
        return (
          <div>
            <span>
              {getCurrency()}
              {separateWithComma(renderData?.rent?.rentCost)}
            </span>
          </div>
        );
      },
    },

    {
      title: "Status",
      render(renderData) {
        return (
          <MyStatusSelectButton
            initialFetchHandler={() =>
              getOptionsHandler(FieldTypeEnum.CANDIDATE_STATUS)
            }
            onSelectHandler={(value) => {
              canEdit &&
                editDetailsHandler(
                  {
                    _id: renderData?._id,
                    status: value,
                  },
                  null,
                  {
                    async onSuccess(payload?: any) {
                      await getAllDataHandlers(query);
                    },
                  }
                );
            }}
            selectedOption={{
              name: renderData?.status?.label,
              textColor: decodeOptionColorHandler(renderData?.status?.value)
                .textColor,
              bgColor: decodeOptionColorHandler(renderData?.status?.value)
                .bgColor,
            }}
            title={"Change To"}
            options={bookingsStatusOptions}
          />
        );
      },
    },
    {
      title: `Duration`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>{getDateHandler({ date: renderData?.arrivalDate })}</span>
            <span>{getDateHandler({ date: renderData?.departureDate })}</span>
          </div>
        );
      },
    },
    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigate(PageLinks.bookings.details(renderData?._id));
                  },
                },
                canEdit &&
                  !renderData?.isArchived && {
                    name: "Edit",
                    handler: () => {
                      navigate(PageLinks.bookings.edit(renderData?._id));
                    },
                  },
                canDelete && {
                  name: "Delete",
                  handler: () => {
                    deleteHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
                canDelete && {
                  name: renderData?.isArchived ? `Unarchive` : "Archive",
                  handler: () => {
                    archiveHandler(renderData?._id, renderData?.isArchived, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default BookingTableColumns;
