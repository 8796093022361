import { FastField, FastFieldProps } from "formik";
import { MyTextFieldInterface } from "./interfaces";
import { getInputDateFormat } from "helpers";
import "./styles.css";

export default function MyFastTextField({
  label,
  isRequired,
  isFastField,
  max,
  onChangeCallbackHandler,
  inputSize,
  labelStyle,
  ...props
}: MyTextFieldInterface) {
  return (
    <div className="input_container w-full ">
      {label && (
        <label
          className={`input_label ${
            inputSize === "sm"
              ? "text-[12px]"
              : inputSize === "md"
              ? "text-[12px]"
              : "text-[14px]"
          } ${labelStyle} `}
        >
          {label}
          {isRequired && <span className={"text-tRed "}>*</span>}
        </label>
      )}
      <FastField name={props?.name}>
        {({ field, meta, form }: FastFieldProps) => {
          let isError =
            meta.touched && meta.error && typeof meta?.error === "string";
          const onChangeHandler = async (e) => {
            e?.preventDefault();
            const value = e?.target?.value;
            await form?.setFieldValue(field?.name, value);
            if (max && value > max) {
              await form.setFieldTouched(field?.name, true, true);
              form?.setFieldError(
                field?.name,
                `should be less than or equal to ${max}`
              );
              return;
            }
            if (typeof onChangeCallbackHandler === "function") {
              onChangeCallbackHandler(value);
            }

            form?.setFieldValue(field?.name, value);
          };
          return (
            <>
              <input
                formNoValidate={true}
                {...field}
                {...props}
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                onChange={onChangeHandler}
                onBlur={async (e) => {
                  const value = e?.target?.value;
                  if (max && value > max) {
                    await form.setFieldTouched(field?.name, true, true);
                    form?.setFieldError(
                      field?.name,
                      `should be less than or equal to ${max}`
                    );
                    form?.setFieldValue(field?.name, "");
                  }
                }}
                type={props?.type || "text"}
                className={` rounded-full outline-none  focus:border-[#2680EB] ${
                  isError ? "border-tRed" : "border-[#C0C0C0]"
                } ${
                  inputSize === "sm"
                    ? "text-[12px] pl-2  h-[35px]  min-h-[35px]"
                    : inputSize === "md"
                    ? "text-[14px] pl-2 h-[35px]"
                    : "text-[16px] pl-6 min-h-[48px]"
                } ${props?.className} `}
                value={
                  props?.type === "date" || props?.type === "datetime-local"
                    ? getInputDateFormat(field?.value, props?.type)
                    : field.value
                }
              />
              {isError && meta?.error?.trim() && (
                <span className="input_textError">* {meta.error}</span>
              )}
            </>
          );
        }}
      </FastField>
    </div>
  );
}
