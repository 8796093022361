import React from "react";
import { MySelectField, MyTextField } from "components";
import { FormWrapper } from "assets";
import { useGetAddressHooks } from "useHooks";

import { AddressFormInterface } from "./interfaces";
import { NestedPlaceInterface } from "interfaces";
import { useFormikContext } from "formik";

function AddressForm({
  address2,
  address1,
  country,
  county,
  postalCode,
  city,
  title,
}: AddressFormInterface) {
  const { setFieldValue } = useFormikContext();
  const { countryData, countyData } = useGetAddressHooks({});
  const decodeToOptions = (data: NestedPlaceInterface[]) => {
    return data?.map((e) => {
      return {
        value: e?._id,
        label: e?.name,
        parent: e?.parent,
      };
    });
  };

  return (
    <FormWrapper header={{ title: { name: title || "Address Details" } }}>
      <div className={"flex flex-col gap-10"}>
        <div
          className={`grid items-start grid-cols-3 gap-10 md:grid-cols-2 sm:grid-cols-1`}
        >
          <MyTextField
            placeholder={"Eg. 109 Olney Street"}
            isRequired={true}
            label={"Address Line 1"}
            name={address1?.name || "address1"}
          />
          <MyTextField
            placeholder={"Eg. 109 Olney Street 2"}
            label={"Address Line 2"}
            name={address2?.name || "address2"}
          />
          <MyTextField
            isRequired={true}
            label={"Town/City"}
            placeholder={"Eg. Manchester"}
            name={city?.name || "city"}
          />
          <MyTextField
            isRequired={true}
            label={"Postcode"}
            placeholder={"Enter postal code"}
            name={postalCode?.name || "postalCode"}
          />
          <MySelectField
            isRequired={true}
            label={"County"}
            name={county?.name || "county"}
            placeholder={"Select County"}
            option={{
              selectOptions: decodeToOptions(countyData),
              onChangeCallback(currentValue?: any) {
                setFieldValue(country?.name || "country", currentValue?.parent);
              },
            }}
          />
          <MySelectField
            isRequired={true}
            label={"Country"}
            name={country?.name || "country"}
            placeholder={"Select Country"}
            option={{
              selectOptions: decodeToOptions(countryData),
              onChangeCallback(currentValue?: any) {
                setFieldValue(county?.name || "county", "");
              },
            }}
          />
        </div>
      </div>
    </FormWrapper>
  );
}

export default AddressForm;
