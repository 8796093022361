import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  NotificationSettingsInterface,
  TableColumnInterface,
} from "interfaces";
import { MyMoreOptionButton, MyToggleButton } from "components";

import { ManageNotificationContext } from "context";
import { usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";
import { capitalizeAfterSpace } from "../../../helpers";

function ManageNotificationTableColumns(): TableColumnInterface<NotificationSettingsInterface>[] {
  const navigate = useNavigate();
  const { getAllDataHandlers, editDetailsHandler } = useContext(
    ManageNotificationContext
  );
  const { calcSNHandler } = usePaginationQueryHooks();

  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <span>{calcSNHandler(key)}</span>;
      },
    },
    {
      title: "Notification",
      render(renderData, key: number) {
        return <span>{capitalizeAfterSpace(renderData?.title)}</span>;
      },
    },
    {
      title: "Module",
      render(renderData, key: number) {
        return <span>{capitalizeAfterSpace(renderData?.module)}</span>;
      },
    },
    {
      title: "Notification To",
      render(renderData, key: number) {
        return (
          <span>
            {renderData?.notificationTo
              ?.map((e) => capitalizeAfterSpace(e))
              .join(", ")}
          </span>
        );
      },
    },
    {
      title: "Mail",
      titleStyleClassName: "text-center",
      render(renderData, key: number) {
        return (
          <MyToggleButton
            type={"button"}
            buttonType={"toggle"}
            changeHandler={() => {
              editDetailsHandler(
                {
                  ...renderData,
                  enableEmailNotification: !renderData?.enableEmailNotification,
                },
                null,
                {
                  onSuccess(payload?: any): any {
                    getAllDataHandlers();
                  },
                }
              );
            }}
            value={renderData?.enableEmailNotification}
          />
        );
      },
    },
    {
      title: "Mobile",
      titleStyleClassName: "text-center",
      render(renderData, key: number) {
        return (
          <MyToggleButton
            type={"button"}
            buttonType={"toggle"}
            changeHandler={() => {
              editDetailsHandler(
                {
                  ...renderData,
                  enablePushNotification: !renderData?.enablePushNotification,
                },
                null,
                {
                  onSuccess(payload?: any): any {
                    getAllDataHandlers();
                  },
                }
              );
            }}
            value={renderData?.enablePushNotification}
          />
        );
      },
    },
    {
      title: "SMS",
      titleStyleClassName: "text-center",
      render(renderData, key: number) {
        return (
          <MyToggleButton
            type={"button"}
            buttonType={"toggle"}
            changeHandler={() => {
              editDetailsHandler(
                {
                  ...renderData,
                  enableSmsNotification: !renderData?.enableSmsNotification,
                },
                null,
                {
                  onSuccess(payload?: any): any {
                    getAllDataHandlers();
                  },
                }
              );
            }}
            value={renderData?.enableSmsNotification}
          />
        );
      },
    },

    {
      title: "Action",
      render(renderData, key: number) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "Edit",
                  handler: () => {
                    navigate({
                      pathname: PageLinks.manageNotification.edit(
                        renderData?.title
                      ),
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default ManageNotificationTableColumns;
