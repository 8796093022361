import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { MyButton, MyPasswordInput } from "components";
import { ProfileContext } from "context";

import { editChangePasswordFormik } from "../helpers";
import { PageTemplate } from "templates";

function ChangePasswordPage() {
  const {
    contextHandlers: { updatePasswordHandler },
  } = useContext(ProfileContext);
  const submitHandler = (values, props) => {
    props?.setSubmitting(false);
    if (values?.oldPassword === values?.password) {
      props?.setFieldError(
        "oldPassword",
        "Old password and new password cannot be same",
        true
      );
      props?.setFieldError(
        "password",
        "Old password and new password cannot be same",
        true
      );
      return;
    }
    updatePasswordHandler(values, props);
  };
  return (
    <PageTemplate
      backNavigation={{
        title: "Change Password",
      }}
      title={"Password"}
    >
      <div className={"flex justify-center-center  "}>
        <div className={" flex-1   p-5 rounded-md "}>
          <div
            className={
              "grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1  lg:gap-20 gap-10 "
            }
          >
            <Formik
              validationSchema={editChangePasswordFormik?.validationSchema}
              initialValues={editChangePasswordFormik.initialValues}
              onSubmit={submitHandler}
            >
              {({ isSubmitting }) => {
                return (
                  <Form className={"flex flex-col gap-5"}>
                    <MyPasswordInput
                      name={"oldPassword"}
                      label={"Old Password"}
                      placeholder={"*******"}
                    />
                    <MyPasswordInput
                      name={"password"}
                      placeholder={"*******"}
                      label={"Password"}
                    />
                    <MyButton
                      isLoading={isSubmitting}
                      type={"submit"}
                      name={"Update Password"}
                      colorType={"primary"}
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

export default ChangePasswordPage;
