import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  TableColumnInterface,
  RepairAndMaintenanceInterface,
  SpaceAccessListEnum,
  FieldTypeEnum,
} from "interfaces";
import { MyMoreOptionButton, MyStatusSelectButton } from "components";

import { MaintenanceContext, OptionsContext } from "context";
import {
  useAuthorizationHook,
  useDateHook,
  usePaginationQueryHooks,
} from "useHooks";
import { PropertyUnitTableText, StatusText, UserTableProfile } from "assets";
import { PageLinks } from "routes";
import { decodeOptionColorHandler } from "../../../../helpers";

function MaintenanceTableColumns(
  query: any
): TableColumnInterface<RepairAndMaintenanceInterface>[] {
  const navigate = useNavigate();
  const { calcSNHandler } = usePaginationQueryHooks();
  const {
    archiveHandler,
    getAllDataHandlers,
    deleteHandler,
    editDetailsHandler,
  } = useContext(MaintenanceContext);
  const { fieldTypeOptions, getOptionsHandler } = useContext(OptionsContext);

  const { getDateHandler, getStaffFullName } = useDateHook();

  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_EDIT_REPAIR_AND_MAINTENANCE,
  ]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_DEL_REPAIR_AND_MAINTENANCE,
  ]);
  const RepairPriorityOptions = fieldTypeOptions[
    FieldTypeEnum.REPAIR_PRIORITY
  ]?.map((e) => {
    return {
      label: e?.label,
      value: e?._id,
      textColor: decodeOptionColorHandler(e?.value).textColor,
      bgColor: decodeOptionColorHandler(e?.value).bgColor,
    };
  });
  const RepairStatusOptions = fieldTypeOptions[
    FieldTypeEnum.REPAIR_STATUS
  ]?.map((e) => {
    return {
      label: e?.label,
      value: e?._id,
      textColor: decodeOptionColorHandler(e?.value).textColor,
      bgColor: decodeOptionColorHandler(e?.value).bgColor,
    };
  });
  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <span>{calcSNHandler(key)}.</span>;
      },
    },
    {
      title: "Property",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.property?.propertyID}
            address={renderData?.property?.address}
            propertyID={renderData?.property?._id}
          />
        );
      },
    },
    {
      title: "Unit",
      render(renderData) {
        return (
          <PropertyUnitTableText
            ID={renderData?.unit?.unitID}
            type={"unit"}
            name={renderData?.unit?.name}
            propertyID={renderData?.property?._id}
            unitID={renderData?.unit?._id}
          />
        );
      },
    },
    {
      title: "Assigned To",
      render(renderData) {
        return (
          <UserTableProfile
            details={{
              subTitle: getStaffFullName(renderData?.supplier?.name),
              profile: renderData?.supplier?.photo,
              name: renderData?.supplier?.userID || "-",
            }}
          />
        );
      },
    },
    {
      title: `Subject`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span className={"text-tBlue"}>{renderData?.repairID}</span>
            <span className={"text-black"}>
              {renderData?.inventoryItemID?.item?.label}
            </span>
          </div>
        );
      },
    },

    {
      title: `Target Date`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>{getDateHandler({ date: renderData?.targetDate })}</span>
          </div>
        );
      },
    },
    {
      title: `Priority`,
      render(renderData) {
        return (
          <MyStatusSelectButton
            initialFetchHandler={() =>
              getOptionsHandler(FieldTypeEnum.REPAIR_PRIORITY)
            }
            onSelectHandler={(value) => {
              canEdit &&
                editDetailsHandler(
                  {
                    _id: renderData?._id,
                    repairPriority: value,
                  },
                  null,
                  {
                    async onSuccess(payload?: any) {
                      await getAllDataHandlers(query);
                    },
                  }
                );
            }}
            selectedOption={{
              name: renderData?.repairPriority?.label,
              textColor: decodeOptionColorHandler(
                renderData?.repairPriority?.value
              ).textColor,
              bgColor: decodeOptionColorHandler(
                renderData?.repairPriority?.value
              ).bgColor,
            }}
            title={"Change To"}
            options={RepairPriorityOptions}
          />
        );
      },
    },
    {
      title: `Status`,
      render(renderData) {
        return (
          <MyStatusSelectButton
            initialFetchHandler={() =>
              getOptionsHandler(FieldTypeEnum.REPAIR_STATUS)
            }
            onSelectHandler={(value) => {
              canEdit &&
                editDetailsHandler(
                  {
                    _id: renderData?._id,
                    repairStatus: value,
                  },
                  null,
                  {
                    async onSuccess(payload?: any) {
                      await getAllDataHandlers(query);
                    },
                  }
                );
            }}
            selectedOption={{
              name: renderData?.repairStatus?.label,
              textColor: decodeOptionColorHandler(
                renderData?.repairStatus?.value
              ).textColor,
              bgColor: decodeOptionColorHandler(renderData?.repairStatus?.value)
                .bgColor,
            }}
            title={"Change To"}
            options={RepairStatusOptions}
          />
        );
      },
    },
    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigate(PageLinks.maintenance.details(renderData?._id));
                  },
                },
                canEdit &&
                  !renderData?.isArchived && {
                    name: "Edit",
                    handler: () => {
                      navigate(PageLinks.maintenance.edit(renderData?._id));
                    },
                  },

                canDelete && {
                  name: renderData?.isArchived ? `Unarchive` : "Archive",
                  handler: () => {
                    archiveHandler(renderData?._id, renderData?.isArchived, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
                canDelete && {
                  name: "Delete",
                  handler: () => {
                    deleteHandler(renderData?._id, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default MaintenanceTableColumns;
