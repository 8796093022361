import {
  UserTimestampSchemaInterface,
  ModuleListType,
  TaxTypeEnum,
  UserRoleEnum,
} from "interfaces";

export interface TimePackageCostInterface {
  enable: boolean;
  cost: number;
  taxRate: number;
  discountPercent: number;
  taxType: TaxTypeEnum;
  flatPropertyCost: number;
}

export enum PaymentModeEnum {
  STRIPE = "STRIPE",
  FREE = "FREE",
}

export interface SubscriptionPackageInterface
  extends UserTimestampSchemaInterface {
  _id: string;
  purchaseDisable: boolean;
  name: string;
  description: string;
  modulesExclude: ModuleListType[];
  allowUserType: UserRoleEnum[];
  allowBooking: boolean;
  trial: {
    enable: boolean;
    days: number;
    text: string;
  };
  annualCost: TimePackageCostInterface;
  monthlyCost: TimePackageCostInterface;
  maxProperty: number; //10
  propertyIncludedInBasePrice: number;
  isMaxPropertyUnlimited: boolean;
  storage: {
    base: number;
    perProperty: number;
  };
  allowBankIntegration: boolean;
  allowSMS: boolean;
  enableMail: boolean;
  enableMobileLogin: boolean;
  allowMessageModule: boolean;
  allowSupplierUser: boolean;

  specifications: { label?: string; feature: string; isIncluded: boolean }[];
  subtitle: string;
  isDeleted: boolean;
  isArchived: boolean;

  isSeed: boolean;
  isFreeForever: boolean;
  canDelete: boolean; // false for Free
  enableTask: boolean;
  enableMaintenance: boolean;
  enableInventory: boolean;
}
