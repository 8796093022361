import React, { useState } from "react";

import Popup from "reactjs-popup";
import { BiSquareRounded, BiChevronDown } from "react-icons/bi";

import { MySearchInput } from "components";
import { IconTypeEnum, QueryStringEnum } from "interfaces";

import { SelectOptionsInterface, TableCardInterface } from "./interfaces";
import TableComponent from "./TableComponent";
import PaginationComponent from "../cards/PaginationComponent";
import { MyButton } from "../buttons";

export default function TableCardComponent({
  title,
  loading,
  column,
  dataSource,
  rowSelection,
  searchInput,
  children,
  havePagination,
  exportXlsx,
}: TableCardInterface) {
  let selectRow = rowSelection?.selectRow;
  let key = rowSelection?.key;
  const [selectedRows, setSelectedRows] = useState([]);
  const onSelectChange = (newSelectedRow: any[]) => {
    setSelectedRows(newSelectedRow);
  };
  const haveTopBorder =
    children?.leftChildren || children?.rightChildren || title || searchInput;
  return (
    <div className={"flex flex-col gap-5 overflow-x-scroll hideScroll"}>
      {/*  table header*/}
      <div
        className={`flex items-center justify-between gap-5 ${
          haveTopBorder && "border-b-[1px] mb-5 pb-10"
        } `}
      >
        <div className={"flex items-center gap-5"}>
          {title && (
            <span className={" font-bold text-[22px] whitespace-nowrap"}>
              {title}
            </span>
          )}
          <div className={"flex items-center gap-5 p-1"}>
            {searchInput && (
              <MySearchInput
                paramsName={QueryStringEnum.Search}
                placeholder={searchInput?.placeholder || "Search By Keyword"}
              />
            )}
            {children?.leftChildren}
          </div>
        </div>
        {children?.centerChildren}
        <div className={"flex items-center gap-5"}>
          {children?.rightChildren}
          {typeof exportXlsx?.generateHandler === "function" && (
            <div className={`${exportXlsx?.havePaddingTop && "mt-8"}`}>
              <MyButton
                iconType={IconTypeEnum.DOWNLOAD}
                isOutline
                onClick={exportXlsx?.generateHandler}
                name={"Export .xlsx"}
              />
            </div>
          )}
        </div>
      </div>
      {children?.aboveTableChildren && (
        <div className={"mt-2 pb-5"}>{children?.aboveTableChildren}</div>
      )}
      {/*  table*/}
      <div className={"overflow-x-scroll"}>
        <TableComponent
          rowSelections={{
            selectRow,
            selectedRows,
            onSelectChange,
            key,
          }}
          column={column}
          dataSource={dataSource}
          loading={loading}
        />
      </div>

      {havePagination && (
        <PaginationComponent totalDocs={havePagination?.totalDocs} />
      )}
    </div>
  );
}

const SelectOptions = ({
  selectedRows,
  rowSelection,
}: SelectOptionsInterface) => {
  let isSelected = selectedRows?.length > 0;
  return (
    <Popup
      disabled={!isSelected}
      position="bottom right"
      closeOnDocumentClick
      contentStyle={{ width: "200px" }}
      trigger={
        <button className="button">
          <div
            className={`flex items-center gap-2 bg-primary rounded-full px-4 text-white min-h-[40px] ${
              !isSelected && "opacity-40 cursor-not-allowed"
            }`}
          >
            <BiSquareRounded />
            <BiChevronDown />
          </div>
        </button>
      }
    >
      <div className={"flex flex-col "}>
        {rowSelection?.actions?.map((e, key) => {
          let Icon = e?.Icon;
          return (
            <div
              onClick={() => e?.handler(selectedRows)}
              key={key}
              className={
                "flex gap-1 items-center text-primary text-[16px] cursor-pointer border-b-2 p-2 hover:bg-gray-100"
              }
            >
              <Icon fontSize={18} /> {e?.name}
            </div>
          );
        })}
      </div>
    </Popup>
  );
};
