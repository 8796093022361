import { ContentTypeEnum } from "../interfaces";
import { getCurrency, separateWithBracket } from "helpers";
import React from "react";
import { useGetStartEndDateRangeHooks, usePageQueryHooks } from "useHooks";
import { QueryStringEnum } from "interfaces";
import { PageLinks } from "routes";
import { Link } from "react-router-dom";

const ContentRowComponent = ({
  title,
  value,
  firstComparedAmount,
  comparedAmount,
  deviation,
  type = ContentTypeEnum.content,
  isLess,
  isCompared,
  haveBorder,
  haveBackground,
  account,
}: {
  title: string;
  value: number | string;
  firstComparedAmount?: number | string;
  comparedAmount?: number | string;
  deviation?: number | string;
  type: ContentTypeEnum;
  isLess?: boolean;
  isCompared?: boolean;
  haveBorder?: boolean;
  haveBackground?: boolean;
  account?: string;
}) => {
  let isTypeTitle = type == ContentTypeEnum.title;
  const havePadding =
    type == ContentTypeEnum.total ||
    type == ContentTypeEnum.subTotal ||
    type == ContentTypeEnum.title;
  const isTitleTextRightAlign =
    type == ContentTypeEnum.subTotal ||
    type == ContentTypeEnum.total ||
    haveBorder;
  const {
    startDate,
    endDate,
    rangeType,
    fiscalYear,
    compareStartDate,
    compareEndDate,
    compareRangeType,
    compareFiscalYear,
  } = useGetStartEndDateRangeHooks();
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const getQueryProps = (id: string, forCompare?: boolean) => {
    return `${QueryStringEnum.ACCOUNT_TYPE}=${id}&${
      QueryStringEnum.START_DATE
    }=${forCompare ? compareStartDate : startDate}&${
      QueryStringEnum.END_DATE
    }=${forCompare ? compareEndDate : endDate}&${QueryStringEnum.RANGE_TYPE}=${
      forCompare ? compareRangeType : rangeType
    }&${QueryStringEnum.FISCAL_YEAR}=${
      forCompare ? compareFiscalYear : fiscalYear || ""
    }`;
  };

  return (
    <div
      className={`flex items-center text-[14px]
       ${haveBackground && "bg-gray-100"}
       ${type == "title" && "text-[18px]"} ${havePadding ? "p-6" : "p-2"} ${
        type == ContentTypeEnum.total &&
        "bg-[#2680EB] bg-opacity-[0.25] text-[#2680EB] font-semibold"
      } ${
        type == ContentTypeEnum.subTotal &&
        "bg-[#F6F6F7] bg-opacity-[1] font-semibold "
      } text-black `}
    >
      <div
        className={`${isTypeTitle && "font-bold text-[18px]"} ${
          isCompared ? "w-[40%]" : "w-[80%]"
        } ${isTitleTextRightAlign && "text-right pr-24"}`}
      >
        <span>
          {isLess && "Less:"} {title}
        </span>
      </div>
      <div
        className={`${isTypeTitle && "font-bold text-[18px]"} ${
          account && value > 0 && "text-primary cursor-pointer hover:underline"
        } ${isCompared ? `w-[20%]` : "w-[20%]"}`}
        style={{ textAlign: "right" }}
      >
        {account ? (
          <Link
            to={{
              pathname: PageLinks.ledger.listLedgerTransaction,
              search: getQueryProps(account),
            }}
          >
            {!isTypeTitle && getCurrency()}
            {!isTypeTitle ? separateWithBracket(value) || "-" : value}
          </Link>
        ) : (
          <div>
            {!isTypeTitle && getCurrency()}
            {!isTypeTitle ? separateWithBracket(value) || "-" : value}
          </div>
        )}
      </div>
      {isCompared && (
        <>
          <div
            className={`${isTypeTitle && "font-bold text-[16px] ml-2"}  ${
              account &&
              firstComparedAmount > 0 &&
              "text-primary cursor-pointer hover:underline"
            } w-[22%]`}
            style={{ textAlign: "right" }}
          >
            {account ? (
              <Link
                to={{
                  pathname: PageLinks.ledger.listLedgerTransaction,
                  search: getQueryProps(account, true),
                }}
              >
                {!isTypeTitle && getCurrency()}
                {!isTypeTitle
                  ? separateWithBracket(firstComparedAmount) || "-"
                  : firstComparedAmount}
              </Link>
            ) : (
              <div>
                {!isTypeTitle && getCurrency()}
                {!isTypeTitle
                  ? separateWithBracket(firstComparedAmount) || "-"
                  : firstComparedAmount}
              </div>
            )}
          </div>{" "}
          <div
            className={`w-[16%] ${isTypeTitle && "font-bold text-[16px]"}`}
            style={{ textAlign: "right" }}
          >
            {!isTypeTitle && getCurrency()}
            {!isTypeTitle
              ? separateWithBracket(comparedAmount) || "-"
              : comparedAmount}
          </div>
          <div
            className={`w-[16%] ${isTypeTitle && "font-bold text-[16px]"}`}
            style={{ textAlign: "right" }}
          >
            {isTypeTitle ? (
              <>{deviation}</>
            ) : (
              <>
                {firstComparedAmount !== 0 ? (deviation || "0.00") + "%" : "-"}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ContentRowComponent;
