import React, { useContext, useEffect, useState } from "react";
import { MyCheckButton, MyRadioInput, MySelectField } from "components";
import { AppContext, OptionsContext } from "context";
import { useFormikContext } from "formik";
import {
  CustomerInterface,
  PropertyInterface,
  UnitInterface,
} from "interfaces";
import { Api, decodePrivilegeOptionsHandler } from "helpers";
import { ApiUrl } from "services";
import { FormWrapper } from "assets";

function UserRoleForm() {
  const { values, setFieldValue } = useFormikContext<CustomerInterface>();
  const [propertyUnitRes, setPropertyUnitRes] = useState<
    { property: PropertyInterface; units: UnitInterface[] }[]
  >([]);

  const { getApi } = Api();
  const { getPrivilegeOptionsHandler, privilegeOptions } =
    useContext(OptionsContext);
  const {
    handlers: { setLoading, setError },
  } = useContext(AppContext);
  const currentRoleDetails = privilegeOptions.find(
    (e) => e?.role === values?.role
  );

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       if (propertyUnitRes?.length > 0) {
  //         return;
  //       }
  //       if (!currentRoleDetails?.isSeed) {
  //         setLoading(true);
  //         const propertyRes = await getApi(ApiUrl.property?.get_property);
  //         const unitRes = await getApi(ApiUrl.unit?.get_unit);
  //         let tempResData = [];
  //         propertyRes?.data?.docs?.forEach((e: PropertyInterface) => {
  //           let propertyUnits = [];
  //           unitRes?.data?.docs?.forEach((u: UnitInterface) => {
  //             if (e?._id === u?.property?._id) {
  //               propertyUnits.push(u);
  //             }
  //           });
  //           tempResData.push({
  //             property: e,
  //             units: propertyUnits,
  //           });
  //         });
  //         setPropertyUnitRes(tempResData);
  //       }
  //     } catch (err) {
  //       setError(true, err?.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   })();
  // }, [!currentRoleDetails?.isSeed]);
  const checkHandler = (id, type: "unit" | "property") => {
    let isTypeProperty = type === "property";
    let tempData = isTypeProperty
      ? [...values?.properties]
      : [...values?.units];
    let index = tempData?.findIndex((e) => e === id);
    if (index > -1) {
      tempData.splice(index, 1);
    } else {
      tempData?.push(id);
    }
    setFieldValue(isTypeProperty ? "properties" : "units", tempData);
  };
  return (
    <div className={"flex flex-col gap-5"}>
      <div className={`grid grid-cols-3  gap-5`}>
        <MySelectField
          label={"Select Role"}
          option={{
            selectOptions: decodePrivilegeOptionsHandler(privilegeOptions),
            onChangeCallback(currentValue) {
              const selectedRoleDetails = privilegeOptions?.find(
                (e) => e?._id === currentValue?.value
              );
              if (selectedRoleDetails?.isSeed) {
                setFieldValue("role", selectedRoleDetails?.role);
              } else {
                setFieldValue("role", "custom");
              }
            },
          }}
          fetchOptionsHandler={getPrivilegeOptionsHandler}
          name={"customRole"}
        />
      </div>
      {values?.customRole && !currentRoleDetails && (
        <MyRadioInput
          name={"ALL_PROPERTY_UNIT_ACCESS"}
          label={"All Property and Unit Access"}
        />
      )}

      {!values?.ALL_PROPERTY_UNIT_ACCESS &&
        values?.customRole &&
        !currentRoleDetails && (
          <FormWrapper
            header={{
              title: {
                name: "Assign Permissions",
              },
            }}
          >
            <div>
              <PropertyUnitDetailComponent
                titleChildren={
                  <div className={"pt-5"}>
                    <span className={"input_label "}>Property</span>
                  </div>
                }
              >
                <span className={"input_label"}>Units</span>
              </PropertyUnitDetailComponent>
              <>
                {propertyUnitRes?.map((e, key) => {
                  const isPropertyChecked = values?.properties?.some(
                    (s) => s === e?.property?._id
                  );
                  return (
                    <PropertyUnitDetailComponent
                      key={key}
                      titleChildren={
                        <MyCheckButton
                          changeHandler={() =>
                            checkHandler(e?.property?._id, "property")
                          }
                          isChecked={isPropertyChecked}
                          label={`(${e?.property?.propertyID}) ${e?.property?.address?.address1}`}
                        />
                      }
                    >
                      <>
                        {e?.units?.map((u, key) => {
                          let isChecked = values?.units?.some(
                            //@ts-ignore
                            (s) => s === u?._id
                          );
                          return (
                            <MyCheckButton
                              //@ts-ignore
                              isChecked={isChecked}
                              changeHandler={() => checkHandler(u?._id, "unit")}
                              key={key}
                              label={`(${u?.unitID}) ${u?.name}`}
                            />
                          );
                        })}
                      </>
                    </PropertyUnitDetailComponent>
                  );
                })}
              </>
            </div>
          </FormWrapper>
        )}
    </div>
  );
}

const PropertyUnitDetailComponent = ({ titleChildren, children }) => {
  return (
    <div className={"grid grid-cols-7 border-b-2 "}>
      <div className={"grid col-span-2 "}>{titleChildren}</div>
      <div className={"col-span-5 grid grid-cols-3 gap-5 p-5"}>{children}</div>
    </div>
  );
};
export default UserRoleForm;
