import React, { useContext, useEffect } from "react";

import { FileViewCard, MyIconButton } from "components";
import { getCurrency, separateWithComma } from "helpers";

import { JournalContext } from "context";
import { NameValueText, PropertyUnitTableText } from "assets";
import { useDateHook } from "useHooks";
import { useParams } from "react-router-dom";
import { IconTypeEnum, ParamsStringEnum } from "interfaces";
import { PageTemplate } from "templates";

import Skeleton from "react-loading-skeleton";
import { PageLinks } from "routes";
import { usePageQueryHooks } from "useHooks";

function JournalDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const { isDetailsLoading, details, getDetailsHandler } =
    useContext(JournalContext);
  const { getDateHandler } = useDateHook();

  const { returnURL, navigateWithReturnUrlHandler } = usePageQueryHooks();
  useEffect(() => {
    getDetailsHandler(params.id);
  }, [params.id]);

  return (
    <PageTemplate
      scrollAll
      title={"Accounting"}
      backNavigation={{
        title: "Journal Details",
        path: PageLinks.journal.list,
        overRideReturnURL:
          returnURL && returnURL === PageLinks.ledger.listLedgerTransaction,
      }}
    >
      <div className={"flex flex-col gap-10 overflow-x-scroll"}>
        <div
          className={
            "flex w-full items-center gap-4 sm:gap-2 bg-tGray rounded-md p-4"
          }
        >
          <MyIconButton
            iconSize={20}
            iconColor={"text-primary"}
            IconType={IconTypeEnum.JOURNAL}
          />
          <div className={"w-full"}>
            {isDetailsLoading ? (
              <>
                <Skeleton width={"200px"} />
                <Skeleton width={"100px"} />
              </>
            ) : (
              <div className={"flex flex-col gap-2"}>
                <div
                  className={
                    "grid grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-5 sm:gap-2 w-full"
                  }
                >
                  <NameValueText
                    alignDirection={"row"}
                    name={"Journal No."}
                    value={details?.journalID}
                  />
                  <NameValueText
                    alignDirection={"row"}
                    name={"Date"}
                    value={getDateHandler({ date: details?.transactionDate })}
                  />
                  <NameValueText
                    alignDirection={"row"}
                    name={"Invoice"}
                    value={
                      `${
                        details?.invoiceReference?.invoiceID || ""
                      } (${getDateHandler({
                        date: details?.invoiceReference?.invoiceDate,
                      })})` || "-"
                    }
                    onValueClickHandler={() =>
                      details?.invoiceReference?._id &&
                      navigateWithReturnUrlHandler(
                        PageLinks.invoice.details(
                          details?.invoiceReference?._id
                        )
                      )
                    }
                  />
                </div>
                <div
                  className={
                    "grid grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-5 sm:gap-2"
                  }
                >
                  <NameValueText
                    alignDirection={"row"}
                    name={"Property"}
                    value={
                      <PropertyUnitTableText
                        isOneLine
                        propertyID={details?.property?._id}
                        address={details?.property?.address}
                        ID={details?.property?.propertyID || "-"}
                      />
                    }
                  />
                  <NameValueText
                    alignDirection={"row"}
                    name={"Unit"}
                    value={
                      <PropertyUnitTableText
                        propertyID={details?.property?._id}
                        unitID={details?.unit?._id}
                        isOneLine
                        type={"unit"}
                        name={details?.unit?.name}
                        ID={details?.unit?.unitID || "-"}
                      />
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={"grid overflow-y-scroll"}>
          <table className="table_container table-auto ">
            <thead className="table_head">
              <tr>
                <th>SN</th>
                <th>Ledger Number</th>
                <th>Account</th>
                <th></th>
                <th></th>
                <th></th>
                <th className={"text-right"}>Dr</th>
                <th className={"text-right"}>Cr</th>
              </tr>
            </thead>
            <tbody>
              {details?.entries?.map((e, key) => {
                return (
                  <tr key={key} className={"table_row"}>
                    <td>{key + 1}.</td>
                    <td>{e?.account?.ledgerCode}</td>
                    <td>{e?.account?.name}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      className={"text-right"}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {getCurrency()}{separateWithComma(e?.debit || 0)}
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                      }}
                      className={"text-right"}
                    >
                      {getCurrency()}{separateWithComma(e?.credit || 0)}
                    </td>
                  </tr>
                );
              })}
              <tr className={"table_head bg-gray-50 mt-2"}>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th className={"text-right"}>
                  {getCurrency()}{separateWithComma(details?.totalDr)}
                </th>
                <th className={"text-right"}>
                  {getCurrency()}{separateWithComma(details?.totalCr)}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 "}>
          <div className={"col-span-2"}>
            <NameValueText
              name={"Narration"}
              value={details?.name}
              isLoading={isDetailsLoading}
            />
          </div>
        </div>
        {details?.documents?.length > 0 && (
          <div className={"input_container"}>
            <span className={"input_label font-bold"}>Attachments</span>
            <div
              className={"grid grid-cols-5 gap-5 md:grid-cols-3 sm:grid-cols-1"}
            >
              {details?.documents?.map((e, key) => {
                return <FileViewCard filePath={e} canDownload key={key} />;
              })}
            </div>
          </div>
        )}
      </div>
    </PageTemplate>
  );
}

export default JournalDetailsPage;
