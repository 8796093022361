export enum baseUrlType {
  DEFAULT = "default",
}

export enum AuthTokenENUM {
  accessToken = "accessToken",
  refreshToken = "refreshToken",
  loginRefreshToken = "loginRefreshToken",
}
export enum DateRangeOptionEnum {
  FISCAL_YEAR = "FISCAL_YEAR",
  THIS_MONTH = "THIS_MONTH",
  THIS_YEAR = "THIS_YEAR",
  LAST_MONTH = "LAST_MONTH",
  LAST_YEAR = "LAST_YEAR",
  ALL_TIME = "ALL_TIME",
  CUSTOM = "CUSTOM",
}
export enum QueryStringEnum {
  Archive = "archive", // for normal and archive
  Search = "q", // for searching
  PROPERTY_ID = "property",
  BANK = "BANK",
  TRANSACTION_TYPE = "TRANSACTION_TYPE",
  CODE = "code",
  INVOICE_ID = "invoiceID",
  DATE = "d",
  ACCOUNT_TYPE = "ACCOUNT_TYPE",
  USER_ID = "userID",
  UNIT_ID = "unit",
  ITEM_ID = "itemID",
  ID = "id",
  ACTIVE_TAB = "activeTab",
  EDIT = "edit",
  STATUS = "status",
  TYPE = "type",
  RANGE_TYPE = "rangeType",
  FISCAL_YEAR = "fiscalYear",
  RETURN_URL = "returnURL",
  CURRENT_PAGE = "currentPage",
  MESSAGE_STATUS = "messageStatus",
  START_DATE = "startDate",
  END_DATE = "endDate",
  COMPARE_RANGE_TYPE = "compareRangeType",
  COMPARE_FISCAL_YEAR = "compareFiscalYear",
  COMPARE_START_DATE = "compareStartDate",
  COMPARE_END_DATE = "compareEndDate",
}
export enum CompareTypeEnum {
  YEAR = "year",
  MONTH = "month",
}

export enum ParamsStringEnum {
  PROPERTY_ID = "propertyId",
  UNIT_ID = "unitId",
  USER_ID = "userId",
  TENANCY_ID = "tenancyId",
  ID = "id",
  TYPE = "type",
  USER_TYPE = "userType",
}

export enum IconTypeEnum {
  SEARCH = "SEARCH",
  RIGHT_BOLD = "RIGHT_BOLD",
  UNSETTLED = "UNSETTLED",
  UN_ORDER_LIST = "UN_ORDER_LIST",
  ARCHIVE = "ARCHIVE",
  OPERATION_PROFIT = "OPERATION_PROFIT",
  PROPERTY_TOTAL = "PROPERTY_TOTAL",
  PDF = "PDF",
  FILE = "FILE",
  INVOICE = "INVOICE",
  IN_ACTIVE_INVOICE = "IN_ACTIVE_INVOICE",
  FINANCE = "FINANCE",
  IN_ACTIVE_FINANCE = "IN_ACTIVE_FINANCE",
  INVENTORY = "INVENTORY",
  IN_ACTIVE_INVENTORY = "IN_ACTIVE_INVENTORY",
  ATTACHMENT = "ATTACHMENT",
  ALERT = "ALERT",
  INFO = "INFO",
  RENTAL = "RENTAL",
  ADD = "ADD",
  KEY = "KEY",
  USERS = "USERS",
  DOLLAR = "DOLLAR",
  EYE_OPEN = "EYE_OPEN",
  EYE_CLOSE = "EYE_CLOSE",
  SELECTED_RADIO = "SELECTED_RADIO",
  UN_SELECTED_RADIO = "UN_SELECTED_RADIO",
  UP = "UP",
  MINUS = "MINUS",
  DOT = "DOT",
  MAIL = "MAIL",
  CHECK_ALL = "CHECK_ALL",
  DOWN_ARROW = "DOWN_ARROW",
  DOWNLOAD = "DOWNLOAD",
  DASHBOARD_SETTING = "DASHBOARD_SETTING",
  DASHBOARD_HAND_HOME = "DASHBOARD_HAND_HOME",
  UP_ARROW = "UP_ARROW",
  BOOKING_CANCELLED = "BOOKING_CANCELLED",
  LAYER = "LAYER",
  DASHBOARD = "DASHBOARD",
  BOOKING_CONFIRM = "BOOKING_CONFIRM",
  BOOKING_PENDING = "BOOKING_PENDING",
  SALES_SETTLED = "SALES_SETTLED",
  SALES_STATUS_DRAFT = "SALES_STATUS_DRAFT",
  UNIT_OCCUPIED = "UNIT_OCCUPIED",
  UNIT_VACANT = "UNIT_VACANT",
  HOME = "HOME",
  PROPERTY_VALUE = "PROPERTY_VALUE",
  RENTAL_INCOME_TOTAL = "RENTAL_INCOME_TOTAL",
  RENTAL_PROFIT = "RENTAL_PROFIT",
  RELOAD = "RELOAD",
  KEYS = "KEYS",
  GRAPH = "GRAPH",
  STAT_TAGS = "STAT_TAGS",
  SETTLED = "SETTLED",
  CANCEL = "CANCEL",
  UNIT = "UNIT",
  PROPERTY = "PROPERTY",
  DOWN = "DOWN",
  JOURNAL = "JOURNAL",
  Images = "Images",
  SETTING = "SETTING",
  EDIT = "EDIT",
  CLOSE = "CLOSE",
  BUILDING = "BUILDING",
  CALENDER = "CALENDER",
  NOTIFICATION = "NOTIFICATION",
  CLOCK = "CLOCK",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  ADDRESS = "ADDRESS",
  DELETE = "DELETE",
  QUESTION = "QUESTION",
  SQUARE_FILL = "SQUARE_FILL",
  SQUARE_CHECK = "SQUARE_CHECK",
  TOGGLE_ON = "TOGGLE_ON",
  TOGGLE_OFF = "TOGGLE_OFF",
  CHECK = "CHECK",
  CHECK_FILL = "CHECK_FILL",
  UN_CHECK = "UN_CHECK",
  DISCOUNT = "DISCOUNT",
}

export enum InvoiceStatusEnum {
  ONLY_SALES = "ONLY_SALES",
  ONLY_SALES_RETURN = "ONLY_SALES_RETURN",
  SALES = "SALES",
  PURCHASE = "PURCHASE",
  ONLY_PURCHASE = "ONLY_PURCHASE",
  ONLY_PURCHASE_RETURN = "ONLY_PURCHASE_RETURN",
  SETTLED = "SETTLED",
  //  just for input
  SALES_RETURN = "SALES_RETURN",
  PURCHASE_RETURN = "PURCHASE_RETURN",
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
}

export enum InputSizeEnum {
  SM = "sm",
  MD = "md",
  LG = "lg",
}
// for dashboard stats
export enum DashboardApiTypeEnum {
  "property" = "property",
  "mortgage" = "mortgage",
  "booking" = "booking",
  "unit" = "unit",
  "tenancy" = "tenancy",
  "invoice" = "invoice",
  "task" = "task",
  "maintenance" = "maintenance",
  "profitLoss" = "profitLoss",
  "purchaseChart" = "purchaseChart",
  "salesChart" = "salesChart",
  "IncomeExpensesChart" = "IncomeExpensesChart",
  "reconciliation" = "reconciliation",
}

export enum AssetFileNameEnum {
  RECONCILE_STATEMENT_IMPORT_FILE = "bank_valid_bank_statement.xlsx",
}
