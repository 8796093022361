import React, { createContext, useContext } from "react";
import { AuthTokenENUM, HandlerCallbackInterface } from "interfaces";
import { Api, setAuthToken } from "helpers";
import { ApiUrl } from "services";
import { AppContext } from "context";

export const ProfileContext = createContext({
  contextHandlers: {
    updatePasswordHandler(values, props) {},
    updateProfileHandler(values, props, callback) {},
    changePasswordFirstTimeHandler(
      values,
      props,
      callback: HandlerCallbackInterface
    ) {},
    requestEmailOtpHandler(values, callback: HandlerCallbackInterface) {},
    verifyEmailOTPHandler(values, props, callback: HandlerCallbackInterface) {},
  },
});

function ProfileModuleContextProvider({ children }) {
  const { putApi, getApi } = Api();
  const { handlers } = useContext(AppContext);

  const contextHandlers = {
    async updatePasswordHandler(values, props) {
      try {
        props?.setSubmitting(true);
        const res = await putApi(ApiUrl.auth?.put_updatePassword, values);
        setAuthToken(res?.data?.accessToken, AuthTokenENUM.accessToken);
        setAuthToken(res?.data?.refreshToken, AuthTokenENUM.refreshToken);
        if (props?.resetForm) {
          props?.resetForm({
            values: {
              oldPassword: "",
              password: "",
            },
          });
        }
        handlers?.setSuccess(true, "Password changed!");
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        props?.setSubmitting(false);
      }
    },
    async changePasswordFirstTimeHandler(
      values,
      props,
      callback: HandlerCallbackInterface
    ) {
      try {
        props?.setSubmitting(true);
        const res = await putApi(
          ApiUrl.auth?.put_changePasswordOneTime,
          values
        );
        setAuthToken(res?.data?.accessToken, AuthTokenENUM.accessToken);
        setAuthToken(res?.data?.refreshToken, AuthTokenENUM.refreshToken);
        if (props?.resetForm) {
          props?.resetForm({
            values: {
              password: "",
            },
          });
        }
        handlers?.setSuccess(true, "Password changed!");
        if (typeof callback?.onSuccess === "function") {
          callback?.onSuccess();
        }
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        props?.setSubmitting(false);
      }
    },
    async updateProfileHandler(
      values,
      props,
      callback: HandlerCallbackInterface
    ) {
      try {
        handlers?.setLoading(true);
        if (typeof props?.setSubmitting === "function") {
          props?.setSubmitting(true);
        }
        const res = await putApi(ApiUrl.auth?.put_updateProfile, values);
        handlers?.setSuccess(true, "Profile updated");
        await handlers?.getCurrentProfile();
        if (typeof callback?.onSuccess == "function") {
          callback?.onSuccess(res);
        }
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        if (typeof props?.setSubmitting === "function") {
          props?.setSubmitting(false);
        }
        handlers?.setLoading(false);
      }
    },
    async requestEmailOtpHandler(values, callback: HandlerCallbackInterface) {
      try {
        handlers?.setLoading(true);
        const res = await getApi(
          ApiUrl.auth?.get_requestOTPForEmailVerification,
          values
        );
        if (typeof callback?.onSuccess == "function") {
          callback?.onSuccess(res?.data);
        }
        handlers?.setSuccess(true, "OTP has been sent to your email address");
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
    async verifyEmailOTPHandler(
      values,
      props,
      callback: HandlerCallbackInterface
    ) {
      try {
        handlers?.setLoading(true);
        const res = await putApi(
          ApiUrl.auth?.put_validateEmailVerificationOTP,
          values
        );
        await handlers?.getCurrentProfile();
        handlers?.setSuccess(true, "Email address verified.");

        if (typeof callback?.onSuccess == "function") {
          callback?.onSuccess(res);
        }
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
  };

  const values = {
    contextHandlers,
  };
  return (
    <ProfileContext.Provider value={values}>{children}</ProfileContext.Provider>
  );
}
export default ProfileModuleContextProvider;
