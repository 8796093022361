import React, { useContext } from "react";
import { BoxModal, MyTextField } from "components";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "routes";
import { Form, Formik } from "formik";
import { AppContext } from "context";
import { FormConcludeButtons } from "assets";

function EditTwilioConfigModal() {
  const navigate = useNavigate();
  const {
    state: { twilioSettings },
    handlers: { editTwilioSettingsHandler },
  } = useContext(AppContext);

  const onSubmitHandler = (values) => {
    editTwilioSettingsHandler(values);
  };
  return (
    <BoxModal
      isCloseAuto
      title={"Configure Twilio"}
      closeHandler={() => navigate(PageLinks.settings.twilioSettings)}
    >
      <Formik
        initialValues={{
          accountID: twilioSettings?.accountID,
          senderPhone: twilioSettings?.senderPhone,
          authToken: twilioSettings?.authToken,
          enable: twilioSettings?.enable || false,
        }}
        onSubmit={onSubmitHandler}
      >
        <Form className={"flex flex-col  gap-10"}>
          <div className={"grid grid-cols-1 gap-5"}>
            <MyTextField
              name={"accountID"}
              label={"Account ID"}
              placeholder={"Enter account id"}
            />
            <MyTextField
              name={"authToken"}
              placeholder={"Enter auth token"}
              label={"Auth Token"}
            />{" "}
            <MyTextField
              name={"senderPhone"}
              placeholder={"Enter sender phone"}
              label={"Sender Phone"}
            />
          </div>
          <FormConcludeButtons
            submitButton={{
              title: "Submit",
            }}
          />
        </Form>
      </Formik>
    </BoxModal>
  );
}

export default EditTwilioConfigModal;
