import React from "react";
import { Route } from "react-router-dom";
import { L_MPage, EditL_MPage, L_MDetailsPage } from "./pages";
import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="loan-mortgage/">
      {/*loan-mortgage*/}
      <Route
        path={""}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_READ_LOAN_AND_MORTGAGE]}
            isForPage={true}
          >
            <L_MPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"new"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_ADD_LOAN_AND_MORTGAGE]}
            isForPage={true}
          >
            <EditL_MPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`edit/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_EDIT_LOAN_AND_MORTGAGE]}
            isForPage={true}
          >
            <EditL_MPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`details/:${ParamsStringEnum.ID}/`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_READ_LOAN_AND_MORTGAGE]}
            isForPage={true}
          >
            <L_MDetailsPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
