import React, { useContext } from "react";

import { Route, Routes } from "react-router-dom";

import { PrivateRouteComponent, PublicRouteComponent } from "./components";
import {
  TenancyRoute,
  PropertyRoutes,
  AuthRoutes,
  AdministrativeSetupRoutes,
  ToolRoutes,
  SpaceUserRotes,
  L_MRoutes,
  UnitRoutes,
  ProfileRoutes,
  InventoryRoutes,
  EventRoutes,
  SettingRoutes,
  AccountingRoutes,
  BookingRoutes,
  InvoiceRoutes,
  BankRoutes,
  ReconciliationRoutes,
  DashboardRoutes,
  ReportRoutes,
  NotificationRoutes,
  TaskRoutes,
  MaintenanceRoutes,
  SubscriptionRoutes,
} from "modules";
import {
  Page404,
  TenantWorkSpacePage,
  TrueLayerVerificationPage,
  SubscriptionPackagePage,
  ShortCutLinkPage,
} from "pages";
import { EmailVerificationPage } from "../modules/profileModule";
import { AppContext } from "../context";

export default function AuthRoute() {
  const { state } = useContext(AppContext);
  return (
    <>
      <Routes>
        <Route path="/" element={<PublicRouteComponent />}>
          {AuthRoutes()}
        </Route>
        <Route path="/" element={<PrivateRouteComponent />}>
          {DashboardRoutes()}
          {SubscriptionRoutes()}
          {NotificationRoutes()}
          {TenancyRoute()}
          {BankRoutes()}
          {BookingRoutes()}
          {InvoiceRoutes()}
          {AccountingRoutes()}
          {SettingRoutes()}
          {InventoryRoutes()}
          {EventRoutes()}
          {UnitRoutes()}
          {PropertyRoutes()}
          {AdministrativeSetupRoutes()}
          {ToolRoutes()}
          {TaskRoutes()}
          {MaintenanceRoutes()}
          {SpaceUserRotes()}
          {L_MRoutes()}
          {ProfileRoutes()}
          {ReconciliationRoutes()}
          {ReportRoutes()}
          <Route path={"/workspace"} element={<TenantWorkSpacePage />} />
          <Route path={"/short-cut"} element={<ShortCutLinkPage />} />
          <Route
            path={"/subscription-lists"}
            element={<SubscriptionPackagePage />}
          />
          <Route
            path={"/true-layer-verification"}
            element={<TrueLayerVerificationPage />}
          />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
      {/*{state?.isAuthenticated &&*/}
      {/*  state?.profileDetails &&*/}
      {/*  !state?.profileDetails?.systemID?.isEmailVerified &&*/}
      {/*  !state?.profileDetails?.systemID?.forceChangePassword && (*/}
      {/*    <EmailVerificationPage />*/}
      {/*  )}*/}
    </>
  );
}
