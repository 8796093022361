import React, { useContext, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { InventoryDataContext } from "context";
import { InventoryTableColumn } from "content";
import { MyButton, TableCardComponent } from "components";
import { useAuthorizationHook, usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";
import { LeftRightHeader } from "assets";
import {
  IconTypeEnum,
  ParamsStringEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
} from "interfaces";

function InventoryTab() {
  const params = useParams<ParamsStringEnum>();
  const { getAllDataHandlers, isLoading, allData, totalDocs } =
    useContext(InventoryDataContext);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const property = params?.propertyId;
  const unit = params.unitId;
  let pageQuery = {
    property,
    unit,
  };
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAdd = checkCanAccessHandler([
    SpaceAccessListEnum.INVENTORY_ADD_INVENTORY,
  ]);

  useEffect(() => {
    if (property && unit) {
      getAllDataHandlers(pageQuery);
    }
  }, [property, unit]);
  const tableCardProps = {
    column: InventoryTableColumn(),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
  };
  return (
    <div className={"flex flex-col gap-5"}>
      <LeftRightHeader
        title={"Inventory"}
        rightChildren={
          canAdd && (
            <MyButton
              iconType={IconTypeEnum.ADD}
              onClick={() => {
                navigateWithReturnUrlHandler(
                  PageLinks.inventory.new,
                  `${QueryStringEnum.PROPERTY_ID}=${property}&${QueryStringEnum.UNIT_ID}=${unit}`,
                  true
                );
              }}
              name={"Add Inventory"}
            />
          )
        }
      />
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: totalDocs,
        }}
      />
      <Outlet />
    </div>
  );
}

export default InventoryTab;
