import React from "react";

import { useNavigate } from "react-router-dom";

import { MyMoreOptionButton, TableCardComponent } from "components";
import { PageTemplate } from "templates";

import { SystemInterface } from "../../interfaces";
import { SystemData } from "../../config";
import { FieldTypeEnum } from "interfaces";
import { usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function SystemPage() {
  const navigate = useNavigate();
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const viewDetailsHandler = (key) => {
    if (key === FieldTypeEnum.COUNTRY) {
      navigate({
        pathname: `/setup/system/${key}`,
      });
    } else {
      navigateWithReturnUrlHandler(PageLinks.dynamicLists.nameOptions(key));
    }
  };
  return (
    <PageTemplate title={"Administrative Setup"}>
      <TableCardComponent
        loading={{
          isLoading: false,
        }}
        dataSource={SystemData}
        column={[
          {
            title: "SN.",
            render(renderData: SystemInterface, key: number) {
              return <div>{key + 1}.</div>;
            },
          },
          {
            title: "Entity",
            render(renderData: SystemInterface) {
              return (
                <div
                  onClick={() => viewDetailsHandler(renderData?.key)}
                  className={"text-tBlue cursor-pointer text-left"}
                >
                  {renderData?.name}
                </div>
              );
            },
          },
          {
            title: "Details",
            render(renderData: SystemInterface) {
              return <div>{renderData?.description}</div>;
            },
          },
          {
            title: "Action",
            render(renderData: SystemInterface) {
              return (
                <MyMoreOptionButton
                  options={[
                    {
                      name: `View ${renderData?.name} List`,
                      handler() {
                        viewDetailsHandler(renderData?.key);
                      },
                    },
                  ]}
                />
              );
            },
          },
        ]}
      />
    </PageTemplate>
  );
}

export default SystemPage;
