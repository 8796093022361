import React, { useContext, useEffect, useState } from "react";
import { FastField, FieldArray, useFormikContext } from "formik";

import {
  FileViewCard,
  MyButton,
  MyFastTextField,
  MyRadioInput,
  MySelectField,
  MyTextAreaInput,
  MyTextField,
  MyUploadDocument,
} from "components";
import {
  Api,
  decodeDynamicFieldHandler,
  decodePropertyOptionsHandler,
  decodeUnitOptionsHandler,
  decodeUserOptionsHandler,
  getCurrency,
} from "helpers";
import {
  FieldTypeEnum,
  IconTypeEnum,
  RepairAndMaintenanceInterface,
} from "interfaces";
import { AppContext, OptionsContext } from "context";
import { NameValueText } from "assets";
import { TaskFormikValues } from "../../helpers";
import { ApiUrl } from "services";

function TaskForm({ isLoading }: { isLoading: boolean }) {
  const { values, setFieldValue } = useFormikContext<any>();
  const [maintenanceItems, setMaintenanceItems] = useState<
    RepairAndMaintenanceInterface[]
  >([]);
  const {
    propertyOptions,
    unitOptions,
    userOptions,
    fieldTypeOptions,
    getUnitOptionsHandler,
  } = useContext(OptionsContext);
  const {
    handlers: { setLoading, setError },
  } = useContext(AppContext);
  const { getApi } = Api();
  useEffect(() => {
    if (values?.property) {
      getUnitOptionsHandler(values?.property);
    }
  }, [values?.property]);
  useEffect(() => {
    (async () => {
      try {
        if (
          !values?.unit ||
          !values?.property ||
          !values?.isRepairAndMaintenanceTask
        ) {
          return;
        }

        setLoading(true);
        const res = await getApi(ApiUrl.maintenance.get_maintenance, {
          property: values.property,
          unit: values?.unit,
        });
        setMaintenanceItems(res?.data?.docs || []);
      } catch (err) {
        setError(true, err);
      } finally {
        setLoading(false);
      }
    })();
  }, [values?.unit, values?.property, values?.isRepairAndMaintenanceTask]);

  const getRepairOptions = () => {
    const repairItems: RepairAndMaintenanceInterface[] =
      maintenanceItems?.reduce((acc, current) => {
        const valuesItems: any = values?.items;
        const isUsed = valuesItems?.some((e) => e?.repair === current?._id);
        if (!isUsed) {
          acc?.push(current);
        }
        return acc;
      }, []);

    return repairItems?.map((e) => {
      return {
        label: `${e?.inventoryItemID?.roomItemName?.label || e?.name} (${
          e?.repairID
        })`,
        value: e?._id,
      };
    });
  };
  const getItemDetails = (repairItemId) => {
    if (!repairItemId) {
      return;
    }
    return maintenanceItems?.find((e) => e?._id === repairItemId);
  };
  const repairItemOptions = getRepairOptions();

  return (
    <div className={"flex flex-col gap-10 sm:gap-5 md:gap-5"}>
      <div
        className={`grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 items-start gap-5`}
      >
        <MySelectField
          name={"property"}
          label={"Property"}
          isRequired
          isClearable
          option={{
            selectOptions: decodePropertyOptionsHandler(propertyOptions),
            onChangeCallback(currentValue?: any) {
              setFieldValue("unit", "", false);
            },
          }}
        />
        <MySelectField
          disabled={!values?.property}
          name={"unit"}
          label={"Unit"}
          isRequired
          isClearable
          option={{
            selectOptions: decodeUnitOptionsHandler(unitOptions),
          }}
        />
        <MySelectField
          name={"assignee"}
          label={"Assign To"}
          isRequired
          isClearable
          option={{
            selectOptions: decodeUserOptionsHandler(userOptions),
          }}
        />
        {!values?.isRepairAndMaintenanceTask && (
          <MyTextField
            name={"chargeAmount"}
            label={`Charge Amount (${getCurrency()})`}
            placeholder={`${getCurrency()}00.00`}
          />
        )}
      </div>
      <div
        className={`grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 items-start  gap-5`}
      >
        <MyTextField
          name={"name"}
          label={`Subject/Title`}
          isRequired
          placeholder={`eg. Paint living room`}
        />
        {!values?.isRepairAndMaintenanceTask && (
          <MyTextField
            name={"targetDate"}
            label={`Due Date`}
            isRequired
            type={"date"}
          />
        )}{" "}
        {!values?.isRepairAndMaintenanceTask && (
          <MySelectField
            isClearable
            name={"taskPriority"}
            label={"Priority"}
            isRequired
            option={{
              selectOptions: decodeDynamicFieldHandler(
                fieldTypeOptions[FieldTypeEnum.TASK_PRIORITY]
              ),
            }}
          />
        )}{" "}
        {!values?.isRepairAndMaintenanceTask && (
          <MySelectField
            name={"taskStatus"}
            label={"Status"}
            isRequired
            option={{
              selectOptions: decodeDynamicFieldHandler(
                fieldTypeOptions[FieldTypeEnum.TASK_STATUS]
              ),
            }}
          />
        )}
      </div>
      <div
        className={`grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 items-start gap-5`}
      >
        {values?.unit && (
          <MyRadioInput
            name={"isRepairAndMaintenanceTask"}
            label={"Repair and Maintenance"}
          />
        )}
      </div>
      {!isLoading && values?.unit && values?.isRepairAndMaintenanceTask && (
        <FieldArray
          name={"items"}
          render={({ push, remove, insert }) => {
            return (
              <div className={"flex flex-col gap-10"}>
                {values?.items?.map((e, key) => {
                  const repairItemDetails: RepairAndMaintenanceInterface =
                    getItemDetails(e?.repair);
                  return (
                    <ItemForm
                      addItemHandler={(index) =>
                        insert(index + 1, TaskFormikValues.ItemValues())
                      }
                      key={key}
                      itemDetails={repairItemDetails}
                      parentName={"items"}
                      index={key}
                      removeHandler={remove}
                      repairItemOptions={repairItemOptions}
                    />
                  );
                })}
                {!values?.items && (
                  <div>
                    <MyButton
                      name={"Add Item"}
                      onClick={() => {
                        push(TaskFormikValues.ItemValues());
                      }}
                      iconType={IconTypeEnum.ADD}
                    />
                  </div>
                )}
              </div>
            );
          }}
        />
      )}
      <div className={"grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1"}>
        <div className={"col-span-2"}>
          <MyTextAreaInput name={"description"} label={"Overall Comments"} />
        </div>
      </div>
      <div className={"grid grid-cols-4 md:grid-cols-3 sm:grid-cols-1"}>
        <MyUploadDocument
          fileType={"image"}
          fileContainerStyle={"grid grid-cols-1"}
          label={"Images"}
          name={"documents"}
        />
      </div>
    </div>
  );
}

const ItemForm = ({
  parentName,
  index,
  removeHandler,
  repairItemOptions,
  itemDetails,
  addItemHandler,
}) => {
  const { fieldTypeOptions } = useContext(OptionsContext);
  const details: RepairAndMaintenanceInterface = itemDetails;
  return (
    <div className={"flex flex-col gap-5 "}>
      <div
        className={
          "grid grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-5 items-start"
        }
      >
        <MySelectField
          isRequired
          isClearable
          label={"Items"}
          option={{
            selectOptions: repairItemOptions,
            defaultLabel: "Select Item",
          }}
          name={`${parentName}.${index}.repair`}
        />
      </div>{" "}
      {itemDetails && (
        <div
          className={
            "grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1  gap-5 items-start"
          }
        >
          <NameValueText
            name={"Items"}
            value={details?.inventoryItemID?.item?.label}
          />
          <NameValueText
            name={"Unit"}
            value={details?.inventoryItemID?.unit?.label}
          />
          <NameValueText
            name={"Repair Unit/QTY"}
            value={details?.unitToRepair}
          />
          <NameValueText
            name={"Condition"}
            value={details?.inventoryItemID?.inventoryItemCondition?.label}
          />
          <NameValueText
            name={"Notes"}
            value={details?.inventoryItemID?.notes}
          />
        </div>
      )}
      {itemDetails && (
        <>
          {details?.inventoryItemID?.images?.length > 0 && (
            <div className={"input_container"}>
              <NameValueText name={"Inventory Images"} value={""} />
              <div className={"grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2"}>
                {details?.inventoryItemID?.images?.map((e, key) => {
                  return <FileViewCard key={key} filePath={e} canDownload />;
                })}
              </div>
            </div>
          )}

          <div
            className={
              "grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1  items-start gap-5"
            }
          >
            <FastField
              name={`${parentName}.${index}.taskPriority`}
              component={(props) => (
                <MySelectField
                  isClearable
                  label={"Priority"}
                  option={{
                    selectOptions: decodeDynamicFieldHandler(
                      fieldTypeOptions[FieldTypeEnum.TASK_PRIORITY]
                    ),
                    defaultLabel: "Select Priority",
                  }}
                  isRequired
                  name={`${parentName}.${index}.taskPriority`}
                />
              )}
            />
            <FastField
              name={`${parentName}.${index}.taskStatus`}
              component={(props) => (
                <MySelectField
                  {...props}
                  isClearable
                  label={"Status"}
                  option={{
                    selectOptions: decodeDynamicFieldHandler(
                      fieldTypeOptions[FieldTypeEnum.TASK_STATUS]
                    ),
                    defaultLabel: "Select Status",
                  }}
                  isRequired
                  name={`${parentName}.${index}.taskStatus`}
                />
              )}
            />

            <MyFastTextField
              type={"number"}
              label={`Charge (${getCurrency()})`}
              name={`${parentName}.${index}.chargeAmount`}
              placeholder={`${getCurrency()}00.00`}
            />
            <div className={"col-span-2"}>
              <MyFastTextField
                label={"Description"}
                name={`${parentName}.${index}.description`}
                placeholder={"enter description"}
              />
            </div>
          </div>
        </>
      )}
      <div
        className={
          "grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1  items-start gap-5"
        }
      >
        {itemDetails && (
          <MyFastTextField
            label={"Target Date"}
            isRequired
            name={`${parentName}.${index}.targetDate`}
            type={"date"}
          />
        )}
        <div className={"col-span-1 flex gap-2 mt-8 sm:mt-0"}>
          <MyButton
            isOutline
            onClick={() => addItemHandler(index)}
            colorType={"primary"}
            name={"Add Items"}
            iconType={IconTypeEnum.ADD}
          />
          <MyButton
            isOutline
            onClick={() => removeHandler(index)}
            colorType={"danger"}
            name={"Delete Item"}
            iconType={IconTypeEnum.DELETE}
          />
        </div>
      </div>
    </div>
  );
};

export default TaskForm;
