import React, { useContext } from "react";
import { BankStatementInterface } from "interfaces";
import "./styles.css";
import { MySelectField, MyTextField } from "components";
import { decodeBankOptionsHandler } from "helpers";
import { OptionsContext } from "context";
interface PropsInterface {
  statement: BankStatementInterface;
  setBankTransfer?(state: boolean);
  isBankTransfer?: boolean;
}
function StatementDetails({
  statement,
  isBankTransfer,
  setBankTransfer,
}: PropsInterface) {
  const { bankOptions } = useContext(OptionsContext);
  return (
    <div className={"flex items-start w-full h-full"}>
      <table
        className={
          "table w-full h-full border-collapse statementTable  border border-slate-400 "
        }
      >
        <thead>
          <tr>
            <td
              onClick={() => setBankTransfer(false)}
              className={`${
                isBankTransfer ? "text-gray-200" : "text-primary"
              } cursor-pointer`}
            >
              Match
            </td>
            <th className={"w-[90%] text-left px-2"}>Bank Transfer</th>
          </tr>
        </thead>
        <tbody>
          <tr className={""}>
            <td
              onClick={() => setBankTransfer(true)}
              className={`${
                !isBankTransfer ? "text-gray-200" : "text-primary"
              } cursor-pointer`}
            >
              Bank Transfer
            </td>
            <td>
              <div className={"grid grid-cols-2 gap-5"}>
                <MySelectField
                  option={{
                    selectOptions: decodeBankOptionsHandler(bankOptions),
                    defaultLabel: "Select Bank Account",
                  }}
                  name={"bank"}
                  label={"Bank Account"}
                  style={{
                    fontSize: "12px",
                    height: "0px",
                    padding: "0px 10px",
                    minHeight: "10px",
                  }}
                  labelClassName={"text-[12px]"}
                />
                <MyTextField
                  fontSize={"12px"}
                  className={"h-[30px]"}
                  name={"remarks"}
                  label={"Remarks"}
                  placeholder={"eg: F91455"}
                  labelStyle={"text-[12px]"}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default StatementDetails;
