import { ChartResponseInterface } from "interfaces";
import { getCurrency } from "../../helpers";

export const getGraphData = (payload: ChartResponseInterface) => {
  return {
    labels: payload?.labels ? payload?.labels?.map((e) => [e?.subtitle]) : [],
    datasets:
      payload?.datasets?.length == 2
        ? [
            {
              label: `${payload?.datasets[0]?.label} ${getCurrency()}`,
              data: payload?.datasets[0]?.data,
              fill: false,
              borderColor:
                payload?.datasets[0]?.label === "Income"
                  ? "#41C810"
                  : "#ff7700",
              backgroundColor:
                payload?.datasets[0]?.label === "Income"
                  ? "#41C810"
                  : "#ff7700",
            },
            {
              fill: false,
              label: `${payload?.datasets[1]?.label} ${getCurrency()}`,
              data: payload?.datasets[1]?.data,
              borderColor:
                payload?.datasets[1]?.label === "Income"
                  ? "rgb(255, 99, 132,0.3)"
                  : "#ff7700",
              backgroundColor:
                payload?.datasets[1]?.label === "Income"
                  ? "rgb(255, 99, 132,0.3)"
                  : "#ff7700",
            },
          ]
        : payload?.datasets?.length == 1
        ? [
            {
              label: `${payload?.datasets[0]?.label} ${getCurrency()}`,
              data: payload?.datasets[0]?.data,
              borderColor:
                payload?.datasets[0]?.label === "Purchase"
                  ? "rgb(255, 99, 132)"
                  : "rgb(53, 162, 235)",
            },
          ]
        : [],
  };
};

export const ChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
      display: true,
      labels: {
        generateLabels: function (chart) {
          const data = chart?.data;
          if (data.labels?.length && data?.datasets?.length) {
            return data?.datasets.map(function (label, index) {
              const dataset = data?.datasets[index];
              const backgroundColor =
                dataset?.backgroundColor || dataset?.borderColor;
              return {
                text: dataset?.label?.replace(getCurrency(), ""),
                fillStyle: backgroundColor,
              };
            });
          }
          return [];
        },
      },
    },
    title: {
      display: false,
    },
  },
};
