export const decodeOptionColorHandler = (value: string) => {
  let textColor, bgColor;
  switch (value?.toLowerCase()) {
    case "pending":
      textColor = "text-tBlue";
      bgColor = "bg-blue-100";
      break;
    case "shortlisted":
      textColor = "text-purple-500";
      bgColor = "bg-purple-100";
      break;
    case "rejected":
      textColor = "text-tRed";
      bgColor = "bg-red-100";
      break;
    case "overdue":
      textColor = "text-orange-500";
      bgColor = "bg-orange-200";
      break;
    case "cancelled":
      textColor = "text-tRed";
      bgColor = "bg-red-100";
      break;
    case "confirmed":
      textColor = "text-green-500";
      bgColor = "bg-green-200";
      break;
    case "active":
      textColor = "text-green-500";
      bgColor = "bg-green-100";
      break;
    case "high":
      textColor = "text-purple-500";
      bgColor = "bg-purple-300";
      break;
    case "low":
      textColor = "text-green-500";
      bgColor = "bg-green-100";
      break;
    case "normal":
      textColor = "text-green-500";
      bgColor = "bg-green-100";
      break;
    case "emergency":
      textColor = "text-tRed";
      bgColor = "bg-red-100";
      break;
    case "close":
      textColor = "text-gray-500";
      bgColor = "bg-gray-200";
      break;
    case "expired":
      textColor = "text-tRed";
      bgColor = "bg-red-100";
      break;
    case "open":
      textColor = "text-white";
      bgColor = "bg-green-500";
      break;
    case "checkin":
      textColor = "text-green-500";
      bgColor = "bg-green-100";
      break;
    default:
      textColor = "text-gray-500";
      bgColor = "bg-gray-100";
  }
  return {
    textColor,
    bgColor,
  };
};
