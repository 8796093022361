import React, { useContext, useEffect } from "react";
import { FastField, FieldArray, Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { FormConcludeButtons, FormWrapper } from "assets";
import {
  MyButton,
  MyFastTextField,
  MySelectField,
  MyTextAreaInput,
  MyTextField,
} from "components";
import { PageTemplate } from "templates";

import { AppContext, OptionsContext, UnitDataContext } from "context";
import {
  decodeDynamicFieldHandler,
  decodePropertyOptionsHandler,
} from "helpers";

import { newUnitFormikValues } from "../helpers";
import { UnitForm } from "../components";
import { PageLinks } from "routes";
import { usePageQueryHooks } from "useHooks";
import { FieldTypeEnum, IconTypeEnum, ParamsStringEnum } from "interfaces";

function NewUnitPage() {
  const navigate = useNavigate();
  const { editDetailsHandler, getDetailsHandler, details } =
    useContext(UnitDataContext);
  const params = useParams<ParamsStringEnum>();
  const editID = params.id;
  const { handlers } = useContext(AppContext);
  const {
    propertyOptions,
    fieldTypeOptions,
    getPropertyOptionsHandler,
    getOptionsHandler,
  } = useContext(OptionsContext);
  const maxRoomNumber: any = process.env.REACT_APP_MAX_UNIT_ROOM || 0;
  const { returnURL, propertyID } = usePageQueryHooks();
  useEffect(() => {
    (async () => {
      handlers?.setLoading(true);
      await Promise.all([
        editID && getDetailsHandler(editID),
        !editID && getPropertyOptionsHandler(),
        getOptionsHandler(FieldTypeEnum.UNIT_SIZE),
        getOptionsHandler(FieldTypeEnum.FURNISHED_TYPE),
        getOptionsHandler(FieldTypeEnum.SELECT_FLOOR),
        getOptionsHandler(FieldTypeEnum.ROOM_TYPE),
      ]);
      handlers?.setLoading(false);
    })();
  }, [editID]);
  const submitHandler = async (values, formikHelpers) => {
    await editDetailsHandler(values, formikHelpers, {
      onSuccess: async (payload) => {
        if (returnURL) {
          navigate(returnURL);
        } else {
          // navigate(PageLinks.unit.details(pID,uID));
          navigate(-1);
        }
      },
    });
  };
  return (
    <PageTemplate
      scrollAll
      title={"Unit"}
      backNavigation={{
        title: editID ? "Edit Unit" : "New Unit",
        path: PageLinks.unit.list,
      }}
      breadCrumb={[
        {
          name: "Units",
          path: PageLinks.unit.list,
        },
        {
          name: editID ? "Edit Unit" : "New Unit",
        },
      ]}
    >
      <Formik
        enableReinitialize
        initialValues={newUnitFormikValues?.initialValues(
          editID
            ? details
            : {
                property: {
                  _id: propertyID,
                },
              }
        )}
        validationSchema={
          editID
            ? newUnitFormikValues?.validationSchema
            : newUnitFormikValues?.validationSchema.concat(
                newUnitFormikValues.roomValidationSchema(maxRoomNumber)
              )
        }
        onSubmit={submitHandler}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form className={"flex flex-col gap-10"}>
              {!editID && (
                <div
                  className={
                    "grid grid-cols-3  gap-10  md:grid-cols-2 sm:grid-cols-1"
                  }
                >
                  <MySelectField
                    autoFocus
                    name={"property"}
                    label={"Select Property"}
                    isRequired
                    option={{
                      selectOptions:
                        decodePropertyOptionsHandler(propertyOptions),
                    }}
                  />
                </div>
              )}

              {values?.property && <UnitForm />}
              {values?.property && (
                <FormWrapper
                  header={{
                    title: {
                      name: "Rooms",
                    },
                  }}
                >
                  <FieldArray
                    name={"room"}
                    render={({ remove, push }) => {
                      return (
                        <div className={"flex flex-col gap-10"}>
                          {values?.room?.map((e, index) => {
                            const remainingInventoryOptions = () => {
                              const roomOptions =
                                fieldTypeOptions[FieldTypeEnum.ROOM_TYPE];
                              let filterOptions = roomOptions?.filter(
                                (e) =>
                                  !values?.room?.some(
                                    (f) => f?.roomType === e?._id
                                  ) || e?._id === values?.room[index]?.roomType
                              );
                              return filterOptions;
                            };
                            return (
                              <div
                                key={index}
                                className={
                                  "grid grid-cols-3 items-start gap-10 md:grid-cols-2 sm:grid-cols-1"
                                }
                              >
                                <MySelectField
                                  isClearable
                                  isCreatable={{
                                    type: FieldTypeEnum.ROOM_TYPE,
                                  }}
                                  label={"Room Type"}
                                  name={`room.${index}.roomType`}
                                  option={{
                                    selectOptions: decodeDynamicFieldHandler(
                                      remainingInventoryOptions()
                                    ),
                                  }}
                                />
                                <MyTextField
                                  placeholder={"eg: 3"}
                                  label={"Quantity"}
                                  isRequired
                                  isNumberOnly
                                  maxChar={maxRoomNumber}
                                  name={`room.${index}.quantity`}
                                />
                                <div
                                  className={"mt-8 md:mt-0 sm:mt-0 flex gap-2"}
                                >
                                  <div>
                                    <MyButton
                                      isOutline
                                      name={"Delete Item"}
                                      onClick={() => {
                                        remove(index);
                                      }}
                                      colorType={"danger"}
                                      iconType={IconTypeEnum.DELETE}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          <div>
                            <MyButton
                              isOutline
                              name={"Add More Room"}
                              onClick={() => {
                                push(newUnitFormikValues.roomValues());
                              }}
                              iconType={IconTypeEnum.ADD}
                            />
                          </div>
                        </div>
                      );
                    }}
                  />
                </FormWrapper>
              )}

              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                  handler: () => {
                    navigate(PageLinks.unit.list);
                  },
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default NewUnitPage;
