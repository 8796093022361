import React, { useContext } from "react";
import Stripe from "@stripe/stripe-js";
import { getIconHelper, getPaymentCardImageHelper } from "helpers";
import { IconTypeEnum, PackageCreateOrderPayloadInterface } from "interfaces";
import { useFormikContext } from "formik";
import { CardCvcElement, CardElement } from "@stripe/react-stripe-js";

import { SubscriptionContext } from "../context";
import Skeleton from "react-loading-skeleton";
import { MyTextField } from "components";

function PaymentMethodContainer() {
  const {
    stripePaymentMethod,
    validateOrderDetails,
    isStripePaymentMethodLoading,
  } = useContext(SubscriptionContext);
  const { values, setFieldValue, errors, setErrors } =
    useFormikContext<PackageCreateOrderPayloadInterface>();
  const onPaymentChangeHandler = (id: string) => {
    setFieldValue("paymentMethod", id);
    setFieldValue("isNewCard", false);
  };
  const selectedCardDetails = stripePaymentMethod?.find(
    (e) => e?.id === values?.paymentMethod
  );
  const canSelectPaymentMethod =
    values?.subscription && validateOrderDetails?.payableAmount > 0;
  const onSelectNewCard = () => {
    setFieldValue("isNewCard", true);
    setFieldValue("paymentMethod", "");
  };
  if (!canSelectPaymentMethod) {
    return;
  }
  return (
    <div className={"flex flex-col gap-2"}>
      {isStripePaymentMethodLoading ? (
        new Array(2).fill("").map((e, key) => {
          return <Skeleton key={key} className={"h-[80px] rounded-md"} />;
        })
      ) : (
        <>
          {stripePaymentMethod?.map((e, key) => {
            let isSelected = values?.paymentMethod === e?.id;
            return (
              <PaymentCard
                key={key}
                details={e}
                isSelected={isSelected}
                onSelectHandler={onPaymentChangeHandler}
              />
            );
          })}
          <NewCardComponent
            isSelected={values?.isNewCard}
            onSelectHandler={onSelectNewCard}
          />
        </>
      )}

      <div className={"flex flex-col gap-5 mt-5"}>
        {!values?.isNewCard && selectedCardDetails && (
          <>
            {/*<div className={"flex flex-col gap-2"}>*/}
            {/*  <span className={"input_label"}>Email Address</span>*/}
            {/*  <div*/}
            {/*    className={*/}
            {/*      "border-2 py-2 px-5 cursor-pointer rounded-full bg-gray-100"*/}
            {/*    }*/}
            {/*  >*/}
            {/*    {selectedCardDetails?.billing_details?.email || "-"}*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={"flex flex-col gap-2"}>
              <span className={"input_label"}>Credit Card Number</span>
              <div
                className={
                  "border-2 py-2 px-5 cursor-pointer rounded-full bg-gray-100"
                }
              >
                **** **** **** {selectedCardDetails?.card?.last4 || "****"}
              </div>
            </div>
            <div className={"flex flex-col gap-2"}>
              <label className={"input_label"}>
                Cvc/Cvv <span className={"text-tRed "}>*</span>
              </label>
              <div
                className={
                  "cvcInput border-2 py-2 px-5 cursor-pointer rounded-full"
                }
              >
                <CardCvcElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        letterSpacing: "1px",
                      },
                    },
                    placeholder: "Enter Cvc/Cvv",
                  }}
                  onChange={() => {
                    setErrors({});
                  }}
                />
              </div>
            </div>
          </>
        )}
        {values?.isNewCard && (
          <>
            <MyTextField
              name={"newCardDetails.name"}
              label={"Cardholder Name"}
              placeholder={"Enter Card Holder Name"}
            />

            <div className={"flex flex-col gap-2"}>
              <label className={"input_label"}>Card Details</label>
              <div
                className={
                  "cvcInput border-2 py-2 px-5 cursor-pointer rounded-full"
                }
              >
                <CardElement
                  // ref={card}
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        letterSpacing: "1px",
                      },
                    },
                    hidePostalCode: true,
                  }}
                  onChange={(event) => {
                    if (!event?.complete) {
                      setErrors({
                        paymentMethod: "Invalid card details",
                      });
                    } else {
                      setErrors({});
                    }
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>

      {errors?.paymentMethod && (
        <p className={"input_textError"}>*{errors?.paymentMethod}</p>
      )}
    </div>
  );
}

function PaymentCard({
  details,
  isSelected,
  onSelectHandler,
}: {
  details: Stripe.PaymentMethod;
  isSelected: boolean;
  onSelectHandler(id: string);
}) {
  const {
    contextHandlers: { removeCardHandler, getStripePaymentMethodHandler },
  } = useContext(SubscriptionContext);
  const PaymentCardImage = getPaymentCardImageHelper(details?.card?.brand);
  const SelectedIcon = getIconHelper(IconTypeEnum.CHECK_FILL);
  const UnSelectedIcon = getIconHelper(IconTypeEnum.UN_CHECK);
  const onDeleteHandler = () => {
    removeCardHandler(details?.id, {
      onSuccess(payload?: any): any {
        getStripePaymentMethodHandler();
      },
    });
  };
  return (
    <div
      className={`flex items-start gap-2 bg-white cursor-pointer border-2 rounded-lg p-4 ${
        isSelected && "border-tBlue bg-blue-50"
      }`}
      onClick={() => onSelectHandler(details?.id)}
    >
      <div className={"w-[35px] h-[25px] border-2 px-1 bg-white rounded-sm"}>
        <img
          className={"object-contain w-full h-full"}
          src={PaymentCardImage}
        />
      </div>
      <div className={`flex flex-1 flex-col gap-2`}>
        <div className={"flex justify-between items-center gap-2"}>
          <b className={"text-[14px]"}>
            {details?.card?.brand} ending with {details?.card?.last4}
          </b>
          <div className={"text-[22px]"}>
            {isSelected ? (
              <SelectedIcon className={"text-tBlue"} />
            ) : (
              <UnSelectedIcon className={"text-gray-500"} />
            )}
          </div>
        </div>
        <div className={"flex items-center justify-between"}>
          <span className={"text-[12px]"}>
            Expiry {details?.card?.exp_month}/{details?.card?.exp_year}
          </span>
          <b
            className={"text-[12px] cursor-pointer text-tBlue"}
            onClick={onDeleteHandler}
          >
            remove
          </b>
        </div>
      </div>
    </div>
  );
}

function NewCardComponent({
  isSelected,
  onSelectHandler,
}: {
  isSelected: boolean;
  onSelectHandler();
}) {
  const SelectedIcon = getIconHelper(IconTypeEnum.CHECK_FILL);
  const UnSelectedIcon = getIconHelper(IconTypeEnum.UN_CHECK);
  return (
    <div
      className={`flex justify-between items-start bg-white cursor-pointer border-2 rounded-lg p-4 ${
        isSelected && "border-tBlue bg-blue-50"
      }`}
      onClick={() => onSelectHandler()}
    >
      <div className={"flex flex-col"}>
        <b className={"text-[16px]"}>Add new card</b>
        <span className={"text-[12px]"}>Add your new credit card details</span>
      </div>
      <div className={"text-[22px]"}>
        {isSelected ? (
          <SelectedIcon className={"text-tBlue"} />
        ) : (
          <UnSelectedIcon className={"text-gray-500"} />
        )}
      </div>
    </div>
  );
}

export default PaymentMethodContainer;
