import React from "react";
import { Route } from "react-router-dom";

import { BankDetailsPage, BankPage, NewBankPage } from "./pages";
import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import { AccessVerifier } from "templates";

function Index() {
  return (
    <Route path="banks/">
      <Route
        path={""}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_READ_BANK]}
            isForPage
          >
            <BankPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`details/:${ParamsStringEnum.ID}`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_READ_BANK]}
            isForPage
          >
            <BankDetailsPage />
          </AccessVerifier>
        }
      />
      <Route
        path={"new"}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_ADD_BANK]}
            isForPage
          >
            <NewBankPage />
          </AccessVerifier>
        }
      />
      <Route
        path={`edit/:${ParamsStringEnum.ID}/*`}
        element={
          <AccessVerifier
            accessKeys={[SpaceAccessListEnum.FINANCE_EDIT_BANK]}
            isForPage
          >
            <NewBankPage />
          </AccessVerifier>
        }
      />
    </Route>
  );
}

export default Index;
