import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  JournalInterface,
  SpaceAccessListEnum,
  TableColumnInterface,
} from "interfaces";
import { MyMoreOptionButton } from "components";

import { JournalContext } from "context";
import { getCurrency, separateWithComma } from "helpers";
import {
  useAuthorizationHook,
  useDateHook,
  usePaginationQueryHooks,
} from "useHooks";
import { StatusText } from "assets";
import { PageLinks } from "routes";

function JournalTableColumns(
  query?: any
): TableColumnInterface<JournalInterface>[] {
  const navigate = useNavigate();
  const { archiveHandler, getAllDataHandlers, deleteHandler } =
    useContext(JournalContext);
  const { calcSNHandler } = usePaginationQueryHooks();
  const { getDateHandler } = useDateHook();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.ACCOUNT_EDIT_JOURNAL,
  ]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.ACCOUNT_DEL_JOURNAL,
  ]);
  return [
    {
      title: "SN",
      render(renderData, key) {
        return <span>{calcSNHandler(key)}.</span>;
      },
    },
    {
      title: "JID",
      render(renderData) {
        return <span>{renderData?.journalID}</span>;
      },
    },
    {
      title: "Narration",
      render(renderData) {
        return <span>{renderData?.name}</span>;
      },
    },

    {
      title: `Date`,
      render(renderData) {
        return (
          <span>{getDateHandler({ date: renderData?.transactionDate })}</span>
        );
      },
    },

    {
      title: `Amount (Dr)`,
      titleStyleClassName: "text-right",
      render(renderData) {
        return (
          <div className={"text-right"}>
            {getCurrency()}{separateWithComma(renderData?.totalDr)}
          </div>
        );
      },
    },
    {
      title: `Amount (Cr)`,
      titleStyleClassName: "text-right",
      render(renderData) {
        return (
          <div className={"text-right"}>
            {getCurrency()}{separateWithComma(renderData?.totalCr)}
          </div>
        );
      },
    },
    {
      title: `Status`,
      render(renderData) {
        return (
          <StatusText
            status={renderData?.isDraft ? "pending" : "confirmed"}
            label={renderData?.isDraft ? "Draft" : "Posted"}
          />
        );
      },
    },
    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigate(PageLinks.journal.details(renderData?._id));
                  },
                },
                (canEdit && renderData?.isManual) &&
                  !renderData?.isArchived && {
                    name: "Edit",
                    handler: () => {
                      navigate(PageLinks.journal.edit(renderData?._id));
                    },
                  },
                (canDelete && renderData?.isManual) &&
                  renderData?.isDraft && {
                    name: "Delete",
                    handler: () => {
                      deleteHandler(renderData?._id, {
                        async onSuccess(payload?: any) {
                          await getAllDataHandlers(query);
                        },
                      });
                    },
                  },
                (canDelete && renderData?.isManual) &&
                  renderData?.isDraft && {
                    name: renderData?.isArchived ? `Unarchive` : "Archive",
                    handler: () => {
                      archiveHandler(renderData?._id, renderData?.isArchived, {
                        async onSuccess(payload?: any) {
                          await getAllDataHandlers(query);
                        },
                      });
                    },
                  },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default JournalTableColumns;
