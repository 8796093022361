import React, { useContext, useEffect, useState } from "react";
import { PageTemplate } from "templates";
import {
  BookingStatsContent,
  IncomeExpensesStatsContent,
  InvoicesStatsContent,
  MaintenanceStatsContent,
  MortgageStatsContent,
  ProfileLossStatsContent,
  PropertyStatsContent,
  PurchaseStatsContent,
  ReconciliationContent,
  SalesStatsContent,
  TaskStatsContent,
  TenanciesContent,
  UnitStatsContent,
} from "../content";
import { OptionsContext } from "context";
import { MyButton } from "components";
import { IconTypeEnum, SpaceAccessListEnum } from "interfaces";
import { useAuthorizationHook } from "../../../useHooks";

function DashboardPage() {
  const { getPropertyOptionsHandler, getFiscalYearOptionsHandler } =
    useContext(OptionsContext);
  const [isAllToggle, setAllToggle] = useState(false);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAccessDashboardPage = checkCanAccessHandler([
    SpaceAccessListEnum.MISC_ACCESS_DASHBOARD,
  ]);
  useEffect(() => {
    (async () => {
      await Promise.all([
        getFiscalYearOptionsHandler(),
        getPropertyOptionsHandler(),
      ]);
    })();
  }, []);
  if (!canAccessDashboardPage) {
    return;
  }
  return (
    <PageTemplate
      scrollAll
      isBgTransparent
      title={"Dashboard"}
      breadCrumb={[{ name: "View Dashboard" }]}
    >
      <div className={"flex flex-col gap-10"}>
        <div
          className={"grid grid-cols-10 md:grid-cols-3 sm:grid-cols-1 gap-5"}
        >
          <div className={"col-span-7 sm:col-span-1"}>
            <PropertyStatsContent />
          </div>
          <div className={"col-span-3 md:col-span-3 sm:col-span-1"}>
            <div>
              <MortgageStatsContent />
            </div>
          </div>
        </div>
        <div className={"flex justify-end"}>
          <div>
            <MyButton
              isOutline
              name={`${isAllToggle ? "Collapse All" : "Expand All"}`}
              iconType={isAllToggle ? IconTypeEnum.UP : IconTypeEnum.DOWN}
              onClick={() => setAllToggle((e) => !e)}
            />
          </div>
        </div>
        <div
          className={"grid grid-cols-5 md:grid-cols-2 sm:grid-cols-1 gap-10"}
        >
          <div className={"col-span-3 md:col-span-2 sm:grid-cols-1"}>
            <BookingStatsContent isToggle={isAllToggle} />
          </div>
          <div className={"col-span-2 sm:grid-cols-1"}>
            <UnitStatsContent isToggle={isAllToggle} />
          </div>
        </div>
        <div>
          <TenanciesContent isToggle={isAllToggle} />
        </div>
        <div>
          <ReconciliationContent isToggle={isAllToggle} />
        </div>
        <div>
          <InvoicesStatsContent isToggle={isAllToggle} />
        </div>
        <div
          className={"grid  grid-cols-5 md:grid-cols-2 sm:grid-cols-1 gap-10"}
        >
          <div className={"col-span-2"}>
            <ProfileLossStatsContent isToggle={isAllToggle} />
          </div>
          <div className={"col-span-3 md:col-span-2 sm:grid-cols-1"}>
            <IncomeExpensesStatsContent isToggle={isAllToggle} />
          </div>
        </div>
        <div
          className={"grid grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-10"}
        >
          <div>
            <SalesStatsContent isToggle={isAllToggle} />
          </div>
          <div>
            <PurchaseStatsContent isToggle={isAllToggle} />
          </div>
        </div>
        <div
          className={"grid grid-cols-2  md:grid-cols-1 sm:grid-cols-1 gap-10"}
        >
          <div>
            <TaskStatsContent isToggle={isAllToggle} />
          </div>
          <div>
            <MaintenanceStatsContent isToggle={isAllToggle} />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

export default DashboardPage;
