import * as yup from "yup";
import {
  InvoiceBillInterface,
  InvoiceInterface,
  InvoicePaymentInterface,
  InvoiceStatusEnum,
  TaxTypeEnum,
} from "interfaces";

export const InvoiceFormikForm = {
  billItemValue: (value?: Partial<InvoiceBillInterface>) => ({
    ...(value?._id && {
      _id: value?._id,
    }),
    property: value?.property?._id || "",
    unit: value?.unit?._id || "",
    account: value?.account?._id || "",
    description: value?.description || "",
    quantity: value?.quantity || "",
    rate: value?.rate || "",
    discountPercent: value?.discountPercent || "",
    taxRate: value?.taxRate?.value || "",
  }),
  paymentValue: (value: Partial<InvoicePaymentInterface>) => ({
    amount: value?.amount,
    paymentOn: value?.paymentOn,
    mode: value?.mode?._id,
    bankName: value?.bankName?._id, // if not cash
    agents: value?.agents, // online payment
    transactionNo: value?.transactionNo, // bank and card
    chequeNo: value?.chequeNo, // bank
  }),
  initialValues: (values?: Partial<InvoiceInterface>) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      isDraft: values?.isDraft,
      invoiceType: values?.invoiceType,
      taxType: values?.taxType || TaxTypeEnum.INCLUSIVE,
      client: values?.client?._id,
      invoiceDate: values?.invoiceDate || new Date(),
      billType: values?.billType?._id,
      dueDate: values?.dueDate,
      invoiceID: values?.invoiceID || "", // creditNote for return
      reference: values?.reference,
      invoiceReferenceID: values?.invoiceReferenceID?._id,
      billItems: values?.billItems?.map((e) =>
        InvoiceFormikForm?.billItemValue(e)
      ) || [InvoiceFormikForm?.billItemValue({})],
      documents: values?.documents || [],
      description: values?.description,
      payments:
        values?.payments?.map((e) => {
          return InvoiceFormikForm.paymentValue(e);
        }) || [],
    };
  },
  validationSchema: yup.object().shape({
    client: yup.string().required("required"),
    invoiceType: yup.string().required("required"),
    invoiceDate: yup.string().required("required"),
    invoiceID: yup.string().required("required"),
    invoiceReferenceID: yup
      .string()
      .when("invoiceType", (invoiceType, schema) => {
        if (
          invoiceType === InvoiceStatusEnum.PURCHASE_RETURN ||
          invoiceType === InvoiceStatusEnum.SALES_RETURN
        ) {
          return schema.required("required");
        }
        return schema;
      }),
    billItems: yup.array().of(
      yup.object().shape({
        property: yup.string(),
        unit: yup.string(),
        account: yup.string().required(" "),
        quantity: yup.number().min(0, "").required(" "),
        rate: yup.number().min(0, "").required(" "),
        discountPercent: yup.number().min(0, "").max(100, " "),
      })
    ),
  }),
};
// for invoice
export const calcBillAccount = (
  qty: number = 0,
  rate: number = 0,
  disRate: number = 0,
  taxRate: any = 0,
  taxType: TaxTypeEnum
) => {
  const isTaxExempted = taxType === TaxTypeEnum.EXEMPTED;
  const isTaxInclusive = taxType === TaxTypeEnum.INCLUSIVE;

  const subTotal = qty * rate;
  const disAmount = subTotal * (disRate / 100);
  const totalAfterDiscount = subTotal - disAmount;
  let taxableAmount = isTaxInclusive
    ? totalAfterDiscount / (1 + taxRate / 100)
    : totalAfterDiscount;

  let taxAmount = taxableAmount * (parseFloat(taxRate) / 100);
  if (isTaxExempted) {
    taxableAmount = totalAfterDiscount;
    taxAmount = 0;
  }
  const total = taxableAmount + taxAmount;
  return {
    total,
    subTotal: totalAfterDiscount,
    taxAmount,
    disAmount,
  };
};

export const checkInvoiceStatus = (status) => {
  const isSales = status === InvoiceStatusEnum.SALES;
  const isSalesReturn = status === InvoiceStatusEnum.SALES_RETURN;
  const isPurchase = status === InvoiceStatusEnum.PURCHASE;
  const isPurchaseReturn = status === InvoiceStatusEnum.PURCHASE_RETURN;
  return { isSalesReturn, isSales, isPurchaseReturn, isPurchase };
};

export const getInvoicePageTitle = (status) => {
  let title;
  switch (status) {
    case InvoiceStatusEnum.PURCHASE:
      title = "Purchase Order";
      break;
    case InvoiceStatusEnum.PURCHASE_RETURN:
      title = "Purchase Return Invoice";
      break;
    case InvoiceStatusEnum.SALES:
      title = "Sales Invoice";
      break;
    case InvoiceStatusEnum.SALES_RETURN:
      title = "Sales Return (Credit Note)";
      break;
    default:
      title = "Error Page";
  }
  return title;
};
