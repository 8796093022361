import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { MyButton, MyPasswordInput } from "components";

import { changePasswordFormik } from "../helpers";
import { PageTemplate } from "templates";
import { AppContext, ProfileContext } from "context";
import { useNavigate } from "react-router-dom";

function ChangePasswordFirstTimePage() {
  const navigate = useNavigate();
  const {
    contextHandlers: { changePasswordFirstTimeHandler },
  } = useContext(ProfileContext);
  const {
    handlers: { getCurrentProfile },
  } = useContext(AppContext);
  const submitHandler = (values, props) => {
    changePasswordFirstTimeHandler(values, props, {
      onSuccess(payload) {
        getCurrentProfile();
        navigate("/");
      },
    });
  };
  return (
    <PageTemplate title={"Change Password"}>
      <div className={"flex justify-center-center  "}>
        <div className={" flex-1   p-5 rounded-md "}>
          <div
            className={
              "grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1  lg:gap-20 gap-10 "
            }
          >
            <Formik
              validationSchema={changePasswordFormik?.validationSchema}
              initialValues={changePasswordFormik.initialValues}
              onSubmit={submitHandler}
            >
              {({ isSubmitting }) => {
                return (
                  <Form className={"flex flex-col gap-5"}>
                    <MyPasswordInput
                      name={"password"}
                      placeholder={"*******"}
                      label={"Password"}
                    />
                    <MyButton
                      isLoading={isSubmitting}
                      type={"submit"}
                      name={"Confirm"}
                      colorType={"primary"}
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

export default ChangePasswordFirstTimePage;
