import React, { useContext, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { MyButton, TableCardComponent } from "components";
import { TableFilterCard } from "content";
import { PageTemplate } from "templates";
import {
  IconTypeEnum,
  ParamsStringEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
  UserRoleEnum,
} from "interfaces";

import { UserTableColumn } from "content";
import { ReportContext, UserContext } from "context";
import { decodeRoleNameHandler } from "helpers";
import {
  useAuthorizationHook,
  usePageQueryHooks,
  usePaginationQueryHooks,
} from "useHooks";
import { PageLinks } from "routes";

function UserPage({ isFromSupplier }) {
  const {
    allData,
    totalArchiveDocs,
    isLoading,
    totalDocs,
    getAllDataHandlers,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const params = useParams<ParamsStringEnum>();
  const { isArchive, currentPage } = usePaginationQueryHooks();
  const [query] = useSearchParams();
  const { unitID, propertyID, searchKeyword } = usePageQueryHooks();
  const showArchive = query.get(QueryStringEnum?.Archive);
  let userType: any = params.userType;
  if (isFromSupplier) {
    userType = UserRoleEnum.SUPPLIER;
  }
  const decodedName = decodeRoleNameHandler(userType, true);
  const { generateUserReportHandler } = useContext(ReportContext);
  let getQuery = {
    unit: unitID,
    property: propertyID,
    searchKeyword,
    ...(userType !== "none"
      ? {
          userType: userType,
        }
      : {}),
  };
  useEffect(() => {
    getAllDataHandlers(getQuery);
  }, [showArchive, unitID, propertyID, userType, currentPage, searchKeyword]);

  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAdd = checkCanAccessHandler([SpaceAccessListEnum.AUTH_ADD_USER]);
  const tableCardProps = {
    column: UserTableColumn(getQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      leftChildren: <TableFilterCard haveUnit={true} haveProperty={true} />,
      rightChildren: canAdd && userType !== UserRoleEnum.LANDLORD && (
        <MyButton
          onClick={() => {
            navigate({
              pathname: PageLinks.spaceUser.new,
              search: `${QueryStringEnum?.TYPE}=${userType}`,
            });
          }}
          iconType={IconTypeEnum.ADD}
          name={`Add ${decodedName}`}
        />
      ),
    },
  };
  return (
    <PageTemplate
      title={`${decodedName}`}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
      breadCrumb={[
        {
          name: decodedName,
        },
      ]}
    >
      <TableCardComponent
        {...tableCardProps}
        searchInput={{}}
        exportXlsx={{
          generateHandler: () => generateUserReportHandler(getQuery),
        }}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default UserPage;
