import React from "react";
import { Outlet } from "react-router-dom";
import { TabComponent } from "components";
import { PageTemplate } from "templates";

function BillingAndSubscriptionPage() {
  const tabs = [
    {
      title: "Active Subscription",
      pathName: "",
    },
    {
      title: "Purchase List",
      pathName: "purchase",
    },
  ];
  return (
    <PageTemplate
      scrollAll
      title={"Settings"}
      breadCrumb={[
        {
          name: "Subscription",
        },
      ]}
    >
      <div className={"flex flex-col gap-10 overflow-x-scroll hideScroll"}>
        <div className={"flex flex-col gap-5 overflow-x-scroll hideScroll"}>
          <TabComponent tabs={tabs} />
          <Outlet />
        </div>
      </div>
    </PageTemplate>
  );
}

export default BillingAndSubscriptionPage;
