import React, { useContext } from "react";

import {
  FileViewCard,
  MyFastTextField,
  MySelectField,
  MyTextField,
  MyUploadDocument,
} from "components";
import { NameValueText } from "assets";
import { FieldTypeEnum } from "interfaces";
import { MaintenanceContext, OptionsContext } from "context";
import { decodeDynamicFieldHandler, decodeUserOptionsHandler } from "helpers";

function EditMaintenanceForm() {
  const { fieldTypeOptions, userOptions } = useContext(OptionsContext);
  const { details: maintenanceDetails, isDetailsLoading } =
    useContext(MaintenanceContext);

  return (
    <div className={"flex flex-col gap-10 sm:gap-5"}>
      <div className={`grid grid-cols-5  gap-5 md:grid-cols-3 sm:grid-cols-1`}>
        <NameValueText
          valueStyle={"mt-5 sm:mt-0 "}
          isLoading={isDetailsLoading}
          name={"Property"}
          value={`(${maintenanceDetails?.property?.propertyID}) ${maintenanceDetails?.property?.address?.address1}`}
        />
        <NameValueText
          valueStyle={"mt-5 sm:mt-0 "}
          isLoading={isDetailsLoading}
          name={"Unit"}
          value={`(${maintenanceDetails?.unit?.unitID}) ${maintenanceDetails?.unit?.name}`}
        />

        <MySelectField
          name={"supplier"}
          label={"Assign To"}
          option={{
            selectOptions: decodeUserOptionsHandler(userOptions),
          }}
        />
      </div>
      <div className={"grid grid-cols-5  md:grid-cols-3 sm:grid-cols-1 gap-5"}>
        <NameValueText
          isLoading={isDetailsLoading}
          name={"Item"}
          value={maintenanceDetails?.inventoryItemID?.item?.label}
        />

        <NameValueText
          isLoading={isDetailsLoading}
          alignDirection={"col"}
          name={"Unit"}
          value={maintenanceDetails?.inventoryItemID?.unit?.label}
        />
        <NameValueText
          isLoading={isDetailsLoading}
          alignDirection={"col"}
          name={"Quantity"}
          value={maintenanceDetails?.inventoryItemID?.unitQuantity}
        />
        <NameValueText
          isLoading={isDetailsLoading}
          alignDirection={"col"}
          name={"Condition"}
          value={
            maintenanceDetails?.inventoryItemID?.inventoryItemCondition?.label
          }
        />
        <NameValueText
          isLoading={isDetailsLoading}
          alignDirection={"col"}
          name={"Notes"}
          value={maintenanceDetails?.inventoryItemID?.notes}
        />
      </div>
      {maintenanceDetails?.inventoryItemID?.images?.length > 0 && (
        <div className={"flex flex-col gap-2"}>
          <NameValueText name={"Images"} value={""} />
          <div
            className={"grid grid-cols-5 gap-5 sm:grid-cols-2 md:grid-cols-3"}
          >
            {maintenanceDetails?.inventoryItemID?.images?.map((e, key) => {
              return <FileViewCard key={key} filePath={e} canDownload />;
            })}
          </div>
        </div>
      )}

      <div
        className={
          "grid grid-cols-5  md:grid-cols-3 sm:grid-cols-1  items-start gap-5"
        }
      >
        <MySelectField
          label={"Priority"}
          option={{
            selectOptions: decodeDynamicFieldHandler(
              fieldTypeOptions[FieldTypeEnum.REPAIR_PRIORITY]
            ),
            defaultLabel: "Select Priority",
          }}
          isRequired
          isClearable
          name={`repairPriority`}
        />
        <MySelectField
          label={"Status"}
          option={{
            selectOptions: decodeDynamicFieldHandler(
              fieldTypeOptions[FieldTypeEnum.REPAIR_STATUS]
            ),
            defaultLabel: "Select Status",
          }}
          isRequired
          isClearable
          name={`repairStatus`}
        />
        <MyFastTextField
          isRequired
          max={maintenanceDetails?.inventoryItemID?.unitQuantity}
          label={"Unit to Repair"}
          name={`unitToRepair`}
          type={"number"}
          placeholder={"Eg. 5"}
        />
        <MyTextField
          isRequired
          label={"Description"}
          name={`description`}
          placeholder={"enter description"}
        />
      </div>
      <div
        className={
          "grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1 items-start gap-5"
        }
      >
        <MyUploadDocument
          fileType={"image"}
          label={"Images"}
          name={`documents`}
          fileContainerStyle={"grid grid-cols-1"}
        />
      </div>
      <div
        className={
          "grid grid-cols-5  md:grid-cols-3 sm:grid-cols-1 items-start gap-5"
        }
      >
        <MyTextField
          label={"Target Date"}
          isRequired
          name={`targetDate`}
          type={"date"}
        />
      </div>
    </div>
  );
}

export default EditMaintenanceForm;
