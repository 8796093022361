import React, { createContext, useContext, useState } from "react";

import { AppContext } from "context";
import {
  Api,
  generateBookingReport,
  generateInvoiceReport,
  generateJournalReport,
  generateTaskReport,
  generateTenancyReport,
  generateUserReport,
} from "helpers";
import { ApiUrl } from "services";
import { PropertyReportStatsInterface } from "interfaces";
import { useDateHook, usePaginationQueryHooks } from "useHooks";
import generateReconcileReport from "../../helpers/reports/generateReconcileReport";

interface ReportContextInterface {
  propertyReport: PropertyReportStatsInterface;
  getPropertyReportsHandler(propertyID);
  // report generate
  generateTenancyReportHandler(query?: any);
  generateInvoiceReportHandler(query?: any);
  generateBookingReportHandler(query?: any);
  generateUserReportHandler(query?: any);
  generateTaskReportHandler(query?: any);
  generateReconcileReportHandler(query?: any);
  generateJournalReportHandler(query?: any);
}
export const ReportContext = createContext<ReportContextInterface>({
  propertyReport: null,
  getPropertyReportsHandler(propertyID?: string) {},
  generateTenancyReportHandler(query?: any) {},
  generateInvoiceReportHandler(query?: any) {},
  generateBookingReportHandler(query?: any) {},
  generateUserReportHandler(query?: any) {},
  generateTaskReportHandler(query?: any) {},
  generateReconcileReportHandler(query?: any) {},
  generateJournalReportHandler(query?: any) {},
});

function DataContextProvider({ children }) {
  const [propertyReport, setPropertyReport] = useState(undefined);
  const { handlers } = useContext(AppContext);
  const { getApi } = Api();
  const { getDateHandler } = useDateHook();
  const { isArchive } = usePaginationQueryHooks();

  //  handler
  const reportHandler = {
    getPropertyReportsHandler: async (propertyID) => {
      try {
        handlers.setLoading(true);
        setPropertyReport(null);
        let res: any;
        res = await getApi(
          ApiUrl.property.get_propertyReportStats + propertyID
        );

        setPropertyReport(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers.setLoading(false);
      }
    },
    async generateTenancyReportHandler(query?: any) {
      try {
        handlers?.setLoading(true);
        let res = await getApi(ApiUrl.tenancy.get_tenancies, {
          ...query,
        });
        await generateTenancyReport({
          title: query?.status || "Active",
          data: res?.data?.docs || [],
          dateFormatter: getDateHandler,
        });
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
    async generateReconcileReportHandler(query?: any) {
      try {
        handlers?.setLoading(true);
        let res: any;
        res = await getApi(ApiUrl.bankReconciliation.get_reconcile, {
          ...query,
        });
        await generateReconcileReport({
          data: res?.data?.docs || [],
          dateFormatter: getDateHandler,
        });
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
    async generateInvoiceReportHandler(query?: any) {
      try {
        handlers?.setLoading(true);
        let res: any;
        if (isArchive) {
          res = await getApi(ApiUrl.invoice.get_archivedInvoice, {
            ...query,
          });
        } else {
          res = await getApi(ApiUrl.invoice.get_invoice, {
            ...query,
          });
        }
        await generateInvoiceReport({
          data: res?.data?.docs,
          title: query?.invoiceStatus || query?.invoiceActive || "All",
          dateFormatter: getDateHandler,
        });
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
    async generateBookingReportHandler(query?: any) {
      try {
        handlers?.setLoading(true);
        let res: any;
        res = await getApi(ApiUrl.booking.get_booking, {
          ...query,
        });
        await generateBookingReport({
          data: res?.data?.docs,
          dateFormatter: getDateHandler,
        });
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
    async generateUserReportHandler(query?: any) {
      try {
        handlers?.setLoading(true);
        let res = await getApi(ApiUrl.users.get_users, {
          ...query,
        });
        await generateUserReport({
          data: res?.data?.docs || [],
        });
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
    async generateTaskReportHandler(query?: any) {
      try {
        handlers?.setLoading(true);
        let res = await getApi(ApiUrl.task.get_task, {
          ...query,
        });
        await generateTaskReport({
          data: res?.data?.docs || [],
          dateFormatter: getDateHandler,
        });
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
    async generateJournalReportHandler(query?: any) {
      try {
        handlers?.setLoading(true);
        let res = await getApi(ApiUrl.journal.get_journal, {
          ...query,
        });
        await generateJournalReport({
          data: res?.data?.docs || [],
          dateFormatter: getDateHandler,
        });
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        handlers?.setLoading(false);
      }
    },
  };

  const contextValue = {
    propertyReport,
    ...reportHandler,
  };
  return (
    <ReportContext.Provider value={contextValue}>
      {children}
    </ReportContext.Provider>
  );
}

export default DataContextProvider;
