import React, { useContext } from "react";

import {
  InvoiceInterface,
  InvoiceStatusEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
  TableColumnInterface,
} from "interfaces";
import { MyMoreOptionButton } from "components";

import { InvoiceContext } from "context";
import { getCurrency, separateWithComma } from "helpers";
import { useAuthorizationHook, useDateHook } from "useHooks";
import { usePageQueryHooks, usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function InvoiceTableColumns(query): TableColumnInterface<InvoiceInterface>[] {
  const {
    archiveHandler,
    getAllDataHandlers,
    deleteHandler,
    makeInvoiceActiveHandler,
  } = useContext(InvoiceContext);
  const { calcSNHandler } = usePaginationQueryHooks();
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { getDateHandler, getStaffFullName } = useDateHook();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_EDIT_INVOICE,
  ]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_DEL_INVOICE,
  ]);

  return [
    {
      title: "S.N",
      render(renderData, key) {
        return <span>{calcSNHandler(key)}</span>;
      },
    },
    {
      title: "Invoice Number",
      render(renderData) {
        return (
          <div className={"flex flex-col text-tBlue font-semibold"}>
            <span>{renderData?.invoiceID}</span>
            <samp>{renderData?.invoiceType?.replaceAll("_", " ")}</samp>
          </div>
        );
      },
    },
    {
      title: "Reference Number",
      render(renderData) {
        return <span>{renderData?.reference}</span>;
      },
    },
    {
      title: "Client Name",
      render(renderData) {
        return <span>{getStaffFullName(renderData?.client?.name)}</span>;
      },
    },

    {
      title: `Invoice Date`,
      render(renderData) {
        return <span>{getDateHandler({ date: renderData?.invoiceDate })}</span>;
      },
    },
    {
      title: `Due Date`,
      render(renderData) {
        return <span>{getDateHandler({ date: renderData?.dueDate })}</span>;
      },
    },
    {
      title: `Paid`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>
              {getCurrency()}{separateWithComma(renderData?.paid)}
            </span>
          </div>
        );
      },
    },
    {
      title: `Grand Total`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>
              {getCurrency()}{separateWithComma(renderData?.grandTotal)}
            </span>
          </div>
        );
      },
    },
    {
      title: `Status`,
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>{renderData?.isDraft ? "Draft" : "Active"}</span>
          </div>
        );
      },
    },
    // {
    //   title: `Sent`,
    //   render(renderData) {
    //     return (
    //       <div className={"flex flex-col"}>
    //         <span> {renderData?.isSettled && "Sent"}</span>
    //       </div>
    //     );
    //   },
    // },

    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View Details",
                  handler: () => {
                    navigateWithReturnUrlHandler(
                      PageLinks.invoice.details(renderData?._id)
                    );
                  },
                },
                canEdit &&
                  renderData?.isDraft &&
                  !renderData?.isArchived && {
                    name: "Edit",
                    handler: () => {
                      navigateWithReturnUrlHandler(
                        PageLinks.invoice.edit(
                          renderData?.invoiceType,
                          renderData?._id
                        )
                      );
                    },
                  },
                renderData?.invoiceType === InvoiceStatusEnum.SALES &&
                  !renderData?.isDraft && {
                    name: "Sales Return (Credit Note)",
                    handler: () => {
                      navigateWithReturnUrlHandler(
                        PageLinks.invoice.new(InvoiceStatusEnum.SALES_RETURN),
                        `${QueryStringEnum.INVOICE_ID}=${renderData?._id}`
                      );
                    },
                  },
                renderData?.invoiceType === InvoiceStatusEnum.PURCHASE &&
                  !renderData?.isDraft && {
                    name: "Purchase Return",
                    handler: () => {
                      navigateWithReturnUrlHandler(
                        PageLinks.invoice.new(
                          InvoiceStatusEnum.PURCHASE_RETURN
                        ),
                        `${QueryStringEnum.INVOICE_ID}=${renderData?._id}`
                      );
                    },
                  },
                canEdit &&
                  renderData?.isDraft && {
                    name: "Make Active",
                    handler: async () => {
                      try {
                        await makeInvoiceActiveHandler(
                          { invoiceID: renderData?._id },
                          {
                            async onSuccess(payload?: any) {
                              await getAllDataHandlers(query);
                            },
                          }
                        );
                      } catch (err) {
                      } finally {
                      }
                    },
                  },
                canDelete &&
                  renderData?.isDraft && {
                    name: "Delete",
                    handler: () => {
                      deleteHandler(renderData?._id, {
                        async onSuccess(payload?: any) {
                          await getAllDataHandlers(query);
                        },
                      });
                    },
                  },
                canDelete && {
                  name: renderData?.isArchived ? `Unarchive` : "Archive",
                  handler: () => {
                    archiveHandler(renderData?._id, renderData?.isArchived, {
                      async onSuccess(payload?: any) {
                        await getAllDataHandlers(query);
                      },
                    });
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default InvoiceTableColumns;
