import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { LedgerTransactionInterface } from "interfaces";
import { isDR } from "helpers";
import React from "react";

interface GenerateLedgerTransactionReportInterface {
  data?: LedgerTransactionInterface;
  dateHeader?: string;
  organizationName: string;
  accountType: string;
  dateFormatter?({ date }: { date: Date });
}
async function generateLedgerTransactionReport({
  data,
  dateHeader,
  organizationName,
  dateFormatter,
  accountType,
}: GenerateLedgerTransactionReportInterface) {
  try {
    //    create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet("Ledger Transaction", {});
    worksheet.properties.defaultRowHeight = 20;
    worksheet.properties.defaultColWidth = 20;
    // setting Account header
    worksheet.getCell("A2").value = organizationName;
    worksheet.getCell("A3").value = `${accountType} Transaction`;
    worksheet.getCell("A4").value = dateHeader;
    worksheet.mergeCells("A2:H2");
    worksheet.mergeCells("A3:H3");
    worksheet.mergeCells("A4:H4");
    // style header style
    worksheet.getCell("A2").alignment = {
      horizontal: "center",
    };
    worksheet.getCell("A2").font = {
      size: 14,
    };
    worksheet.getCell("A3").alignment = {
      horizontal: "center",
    };
    worksheet.getCell("A3").font = {
      bold: true,
      size: 16,
    };
    worksheet.getCell("A4").alignment = {
      horizontal: "center",
    };
    worksheet.getCell("A4").font = {
      size: 14,
    };
    const headers = [
      [],
      [
        "Date",
        "Source",
        "Description",
        "Reference",
        "Debit",
        "Credit",
        "Running",
        "Gross",
      ],
      [
        "Opening Balance",
        "",
        "",
        "",
        isDR(data?.openingBalance) ? data?.openingBalance : 0,
        !isDR(data?.openingBalance) && data?.openingBalance != 0
          ? Math.abs(data?.openingBalance)
          : 0,
        0,
        data?.openingBalance < 0
          ? data?.openingBalance
          : data?.openingBalance || 0,
      ],
    ];
    worksheet.addRows(headers).forEach((row) => {
      row.font = {
        bold: true,
        size: 14,
      };
    });
    let totalDr = 0,
      totalCr = 0,
      preBalance = data?.openingBalance || 0;
    data?.transactions?.forEach((e, key) => {
      totalCr += e?.entry?.credit;
      totalDr += e?.entry?.debit;
      preBalance += e?.entry?.debit > 0 ? e?.entry?.debit : -e?.entry?.credit;
      let row = [
        dateFormatter({ date: e?.transactionDate }),
        e?.invoice ? "From Invoice" : "From Journal",
        e?.description,
        `${e?.invoiceID ? e?.invoiceID : ""} ${
          e?.journalID ? `, ${e?.journalID}` : ""
        }`,
        e?.entry?.debit,
        e?.entry?.credit,
        preBalance,
        e?.entry?.debit > 0 ? e?.entry?.debit : e?.entry?.credit,
      ];
      worksheet.addRow(row).font = {
        size: 14,
      };
    });
    let footerHeaders = [
      [
        `Total ${accountType}`,
        "",
        "",
        "",
        totalDr,
        totalCr,
        preBalance,
        preBalance,
      ],
      [
        `Closing Balance`,
        "",
        "",
        "",
        data?.closingBalance,
        data?.closingBalance,
        "",
        "",
      ],
      [`Total`, "", "", "", totalDr, totalCr, preBalance, preBalance],
    ];
    worksheet.addRows(footerHeaders).forEach((row) => {
      row.font = {
        size: 14,
        bold: true,
      };
    });
    worksheet.views = [
      {
        state: "frozen",
        xSplit: 0,
        ySplit: 7,
      },
    ];
    //    save the workbook to a file
    const bufferFile = await workbook.xlsx.writeBuffer();
    await FileSaver.saveAs(
      new Blob([bufferFile], { type: "application/octet-stream" }),
      `${Date.now()}-ledger-transaction.xlsx`
    );
  } catch (err) {
    throw err;
  }
}

export default generateLedgerTransactionReport;
