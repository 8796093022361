import React, { useContext, useEffect } from "react";
import {
  MyTextAreaInput,
  MyTextField,
  MyUploadDocument,
  MySelectField,
} from "components";
import {
  decodePropertyOptionsHandler,
  decodeUnitOptionsHandler,
  getCurrency,
} from "helpers";
import { FormWrapper, NameValueText } from "assets";
import { useFormikContext } from "formik";
import { TripInterface } from "interfaces";
import { OptionsContext } from "context";

function TripsForm() {
  const { values } = useFormikContext<TripInterface<string, string>>();

  const { propertyOptions, unitOptions, getUnitOptionsHandler } =
    useContext(OptionsContext);
  useEffect(() => {
    if (values?.property) {
      getUnitOptionsHandler(values?.property);
    }
  }, [values?.property]);
  const totalAmount = values?.distanceCovered * values?.costPerDistance;
  return (
    <div className={"flex flex-col gap-5"}>
      <div
        className={
          "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5 items-start"
        }
      >
        <MySelectField
          label={"Property"}
          isRequired
          name={"property"}
          autoFocus
          option={{
            selectOptions: decodePropertyOptionsHandler(propertyOptions),
            defaultLabel: "Select Property",
          }}
        />
        <MySelectField
          disabled={!values?.property}
          label={"Unit"}
          name={"unit"}
          option={{
            selectOptions: decodeUnitOptionsHandler(unitOptions),
            defaultLabel: "Select Unit",
          }}
        />
      </div>

      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <MyTextField
          label={"Name"}
          isRequired
          placeholder={"enter name"}
          name={"name"}
        />
        <MyTextField
          type={"date"}
          label={"Date"}
          isRequired
          name={"tripDate"}
        />
      </div>

      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <MyTextField
          label={"From"}
          placeholder={"From"}
          isRequired
          name={"from"}
        />
        <MyTextField label={"To"} placeholder={"To"} isRequired name={"to"} />
      </div>
      <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}>
        <MyTextField
          label={"Distance"}
          isRequired
          type={"number"}
          placeholder={"eg. 2500"}
          name={"distanceCovered"}
        />
        <MyTextField
          label={`Unit Price (${getCurrency()})`}
          isRequired
          type={"number"}
          placeholder={"eg. 2500"}
          name={"costPerDistance"}
        />
        <NameValueText
          name={`Total Amount (${getCurrency()})`}
          value={totalAmount}
          valueStyle={"mt-3"}
        />

        <div className={"col-span-2"}>
          <MyTextAreaInput label={`Description`} name={"description"} />
        </div>
      </div>

      <FormWrapper
        header={{
          title: {
            name: "Documents",
          },
        }}
      >
        <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1"}>
          <div className={"col-span-2"}>
            <MyUploadDocument name={"documents"} />
          </div>
        </div>
      </FormWrapper>
    </div>
  );
}

export default TripsForm;
