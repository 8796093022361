import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../context";
import { SpaceAccessListEnum, UserRoleEnum } from "../interfaces";
import dot from "dot-object";

function UseAuthorizationHook() {
  const {
    state: { profileDetails },
  } = useContext(AppContext);
  const [granted, setGranted] = useState(profileDetails?.granted?.granted);
  useEffect(() => {
    setGranted(profileDetails?.granted?.granted);
  }, [profileDetails?.granted]);

  const checkCanAccessHandler = (
    accessKeys: SpaceAccessListEnum[],
    role?: UserRoleEnum[]
  ): boolean => {
    if (role?.length && !role.includes(profileDetails?.role)) {
      return false;
    }
    if (accessKeys?.length === 0) {
      return true;
    }

    return accessKeys.some((e) => {
      let value = dot.pick(e, granted);
      return value;
    });
  };
  return {
    checkCanAccessHandler,
  };
}

export default UseAuthorizationHook;
