import React, { useContext, useEffect } from "react";
import { TabComponent } from "components";

import { UnitDataContext } from "context";
import { PageTemplate } from "templates";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import {
  ParamsStringEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { PageLinks } from "routes";

import { UnitTitleDetails } from "../assets";
import { useAuthorizationHook } from "useHooks";

function UnitDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const unitId = params?.unitId;
  const [query] = useSearchParams();
  const activeTab = query.get(QueryStringEnum.ACTIVE_TAB);
  const { details, getDetailsHandler, isDetailsLoading } =
    useContext(UnitDataContext);
  useEffect(() => {
    getDetailsHandler(unitId);
  }, [params?.unitId]);

  const { checkCanAccessHandler } = useAuthorizationHook();
  const canReadDocument = checkCanAccessHandler([
    SpaceAccessListEnum.DOCUMENT_READ_DOCUMENT,
  ]);
  const canReadInventory = checkCanAccessHandler([
    SpaceAccessListEnum.INVENTORY_READ_INVENTORY,
  ]);
  const canReadLoan = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_READ_LOAN_AND_MORTGAGE,
  ]);
  let tabs = [
    {
      title: "General Information",
      pathName: "",
    },
    {
      title: "Amenities",
      pathName: "amenities",
    },
    {
      title: "Listing",
      pathName: "listing",
    },
    canReadDocument && {
      title: "Documents",
      pathName: "documents",
    },
    canReadInventory && {
      title: "Inventories",
      pathName: "inventory",
    },
    canReadLoan && {
      title: "Loans/Mortgages",
      pathName: "loan",
    },
  ];
  return (
    <>
      <PageTemplate
        scrollAll
        title={"Unit"}
        backNavigation={{
          title: "Unit Details",
          path: PageLinks.unit.list,
        }}
      >
        <div>
          <div className={"flex flex-col gap-10"}>
            {activeTab && (
              <UnitTitleDetails
                isLoading={isDetailsLoading}
                details={details}
              />
            )}
            <TabComponent tabs={tabs} />
            <Outlet />
          </div>
        </div>
      </PageTemplate>
    </>
  );
}

export default UnitDetailsPage;
