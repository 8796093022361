import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdOutlineDeleteOutline } from "react-icons/md";

import { MyButton, TableCardComponent } from "components";
import { PageTemplate } from "templates";
import { IconTypeEnum, QueryStringEnum } from "interfaces";
import { TableFilterCard } from "content";

import { NotesTableColumns } from "../../components";
import { NotesContext } from "context";
import { usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function NotesPage() {
  const {
    allData,
    totalArchiveDocs,
    totalDocs,
    isLoading,
    getAllDataHandlers,
  } = useContext(NotesContext);
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const { isArchive, currentPage } = usePaginationQueryHooks();
  const unitID = query.get(QueryStringEnum?.UNIT_ID);
  const propertyID = query.get(QueryStringEnum?.PROPERTY_ID);
  const pageQuery = {
    unit: unitID,
    property: propertyID,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, unitID, propertyID, currentPage]);

  const tableCardProps = {
    rowSelection: {
      selectRow: true,
      key: "_id",
      actions: [
        {
          name: "Delete",
          Icon: MdOutlineDeleteOutline,
          handler: (selectedRows: string[]) => {
            console.log(selectedRows);
          },
        },
      ],
    },
    column: NotesTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      leftChildren: <TableFilterCard haveProperty haveUnit />,
      rightChildren: (
        <MyButton
          onClick={() => {
            navigate(PageLinks.notes.new);
          }}
          iconType={IconTypeEnum.ADD}
          name={"Add Note"}
        />
      ),
    },
  };
  return (
    <PageTemplate
      title={"Notes"}
      breadCrumb={[
        {
          name: "Notes",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default NotesPage;
