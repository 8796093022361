import React, { useContext, useEffect, useState } from "react";
import { PageTemplate } from "templates";
import { Outlet, useNavigate } from "react-router-dom";
import { AppContext, TrueLayerContext } from "context";
import { MyButton, MyCheckButton } from "components";
import { NameValueText } from "assets";
import { PageLinks } from "routes";
import { IconTypeEnum } from "interfaces";
import { getIconHelper } from "helpers";

function TrueLayerSettingsPage() {
  const navigate = useNavigate();
  const {
    state: { trueLayerSettings },
    handlers: { setSuccess, setError, getTrueLayerSettingsHandler },
  } = useContext(AppContext);
  const [showSecret, setShowSecret] = useState(false);
  const EyeOpenIcon = getIconHelper(IconTypeEnum.EYE_OPEN);
  const EyeCloseIcon = getIconHelper(IconTypeEnum.EYE_CLOSE);
  const { editTrueLayerSettings } = useContext(TrueLayerContext);
  const redirectLink = window.location.host + PageLinks.trueLayerVerification;
  useEffect(() => {
    getTrueLayerSettingsHandler();
  }, []);

  const handleCopyClick = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      setSuccess(true, "Copied!");
    } catch (error) {
      setError(true, "failed to copy!");
    }
  };
  return (
    <PageTemplate
      title={"Settings"}
      titleRightChildren={
        <div>
          {trueLayerSettings?.enableTrueLayer && (
            <MyButton
              isOutline
              iconType={IconTypeEnum.SETTING}
              name={"Configure"}
              onClick={() => navigate(PageLinks.settings.editTrueLayerSettings)}
            />
          )}
        </div>
      }
      breadCrumb={[
        {
          name: "True Layer Settings",
        },
      ]}
    >
      <div className={"flex flex-col gap-10 items-start"}>
        <MyCheckButton
          value={trueLayerSettings?.enableTrueLayer}
          changeHandler={(newValue) => {
            editTrueLayerSettings(
              {
                ...trueLayerSettings,
                enableTrueLayer: newValue,
              },
              {
                onSuccess(payload?: any): any {
                  getTrueLayerSettingsHandler();
                },
              }
            );
          }}
          label={"Enable True Layer"}
          isChecked={trueLayerSettings?.enableTrueLayer}
        />
        <div className={"grid grid-cols-2 gap-10 w-full"}>
          <NameValueText
            value={trueLayerSettings?.clientID || "-"}
            name={"Client ID"}
          />
          <div className={"flex items-center gap-5"}>
            <NameValueText
              value={
                showSecret
                  ? trueLayerSettings?.clientSecretKey || "N/A"
                  : "********-****-****-****-************"
              }
              name={"Client Secret Key"}
            />
            <div
              className={"cursor-pointer"}
              onClick={() => setShowSecret((e) => !e)}
            >
              {showSecret ? <EyeOpenIcon /> : <EyeCloseIcon />}
            </div>
          </div>
        </div>
        <div className={"flex items-center  gap-2 "}>
          <NameValueText
            value={redirectLink}
            name={"Redirect URL"}
            valueStyle={"bg-gray-100 p-2 rounded-md"}
          />
          <samp
            className={"text-[14px] text-tRed cursor-pointer"}
            onClick={() => handleCopyClick(redirectLink)}
          >
            Copy URL
          </samp>
        </div>

        <Outlet />
      </div>
    </PageTemplate>
  );
}

export default TrueLayerSettingsPage;
