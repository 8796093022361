import { SystemInterface } from "../interfaces";
import { FieldTypeEnum } from "interfaces";

export const SystemData: SystemInterface[] = [
  {
    name: "Country",
    description: "Country",
    key: FieldTypeEnum.COUNTRY,
  },
  // {
  //   name: "Document Type",
  //   description: "Document Type",
  //   key: FieldTypeEnum.DOCUMENT_TYPE,
  // },
  {
    name: "Charge Type",
    description: "Charge Type",
    key: FieldTypeEnum.CHARGE_TYPE,
  },
];
