import { QueryStringEnum, UserRoleEnum } from "../interfaces";

const PageLinks = {
  workSpace: "/workspace",
  trueLayerVerification: "/true-layer-verification",
  property: {
    list: "/rental/property/",
    new: `/rental/property/new`,
    details: (id: string) => `/rental/property/details/${id}`,
    edit: (id: string) => `/rental/property/edit/${id}`,
    addOwner: (pId: string) =>
      `/rental/property/details/${pId}/owners/modal/add`,
    assignOwner: (pId: string) =>
      `/rental/property/details/${pId}/owners/modal/assignOwner`,
    editOwnership: (pId: string, itemId: string) =>
      `/rental/property/details/${pId}/owners/modal/assignOwner/edit/${itemId}`,
    viewOwnership: (pId: string, itemId: string) =>
      `/rental/property/details/${pId}/owners/modal/ownership/details/${itemId}`,
  },
  role: {
    list: "/setup/roles",
    details: (itemID: string) => `/setup/roles/details/${itemID}`,
    edit: (itemID: string) => `/setup/roles/edit/${itemID}`,
    create: `/setup/roles/create`,
  },
  task: {
    list: "/services/tasks/",
    new: `/services/tasks/new`,
    details: (id) => `/services/tasks/details/${id}`,
    edit: (id) => `/services/tasks/edit/${id}`,
  },
  subscription: {
    activeSubscription: "/subscription/",
    purchaseHistory: "/subscription/purchase",
    subscriptionList: "/subscription-lists",
    checkout: (type: string) => `/subscription/checkout/${type}`,
  },
  report: {
    reportPage: "/report",
    propertyReport: "/report/property",
  },
  maintenance: {
    list: "/services/maintenance/",
    new: `/services/maintenance/new`,
    details: (id) => `/services/maintenance/details/${id}`,
    edit: (id) => `/services/maintenance/edit/${id}`,
  },
  loansAndMortgages: {
    list: "/loan-mortgage/",
    new: `/loan-mortgage/new`,
    details: (id) => `/loan-mortgage/details/${id}`,
    edit: (id) => `/loan-mortgage/edit/${id}`,
  },
  unit: {
    list: "/rental/unit/",
    new: `/rental/unit/new`,
    details: (propertyID, unitID) =>
      `/rental/unit/details/${propertyID}/${unitID}`,
    edit: (id) => `/rental/unit/edit/${id}`,
  },
  spaceUser: {
    list: (type: UserRoleEnum) => `/spaceUser/users/${type}`,
    new: `/spaceUser/users/new`,
    details: (id) => `/spaceUser/users/details/${id}`,
    edit: (id) => `/spaceUser/users/edit/${id}`,
  },
  tenancy: {
    list: `/rental/tenancy`,
    new: `/rental/tenancy/new`,
    details: (id) => `/rental/tenancy/details/${id}`,
    edit: (id) => `/rental/tenancy/edit/${id}`,
  },
  bookings: {
    list: `/services/bookings`,
    new: `/services/bookings/new`,
    details: (id) => `/services/bookings/details/${id}`,
    edit: (id) => `/services/bookings/edit/${id}`,
  },
  inventory: {
    list: `/services/inventory`,
    new: `/services/inventory/new`,
    details: (id) => `/services/inventory/details/${id}`,
    edit: (id) => `/services/inventory/edit/${id}`,
  },
  events: {
    list: `/events`,
    new: `/events/new`,
    details: (id) => `/events/details/${id}`,
    edit: (id) => `/events/edit/${id}`,
  },
  ledgerName: {
    list: `/setup/ledgerName`,
    new: `/setup/ledgerName/new`,
    edit: (id) => `/setup/ledgerName/edit/${id}`,
  },
  subLedgerName: {
    list: `/setup/subLedgerName`,
    edit: (id) => `/setup/subLedgerName/edit/${id}`,
  },
  manageNotification: {
    list: `/settings/manageNotification`,
    edit: (id) => `/settings/manageNotification/edit/${id}`,
  },
  notification: {
    list: `/notification`,
    details: (id) => `/notification/details/${id}`,
  },
  settings: {
    spaceSettings: `/settings/space-settings`,
    trueLayerSettings: "/settings/true-layer",
    editTrueLayerSettings: "/settings/true-layer/edit",
    twilioSettings: "/settings/twilio",
    brevoSettings: "/settings/brevo",
    editTwilioLayerSettings: "/settings/twilio/edit",
    retainEarning: "/settings/retain-earning",
  },
  dashboard: {
    list: `/`,
  },
  journal: {
    list: `/accounting/journal`,
    new: `/accounting/journal/new`,
    opening: `/accounting/journal/opening`,
    details: (id) => `/accounting/journal/details/${id}`,
    edit: (id) => `/accounting/journal/edit/${id}`,
  },
  invoice: {
    list: `/finance/invoice`,
    new: (type) => `/finance/invoice/new/${type}`,
    details: (id) => `/finance/invoice/details/${id}`,
    edit: (type, id) => `/finance/invoice/edit/${type}/${id}`,
  },
  ledger: {
    list: `/accounting/ledger`,
    listLedgerTransaction: `/accounting/ledger-transaction`,
  },
  account: {
    trialBalance: "/accounting/trial-balance",
    incomeStatement: "/accounting/income-statement",
    balanceSheet: "/accounting/balance-sheet",
  },
  profile: {
    details: "/profile",
    edit: "/profile/edit",
    changePassword: "/profile/changePassword",
    setPasswordForFirstTime: "/profile/set-password",
  },
  notes: {
    list: "/tools/notes",
    new: "/tools/notes/new",
    edit: (id) => `/tools/notes/edit/${id}`,
    details: (id) => `/tools/notes/details/${id}`,
  },
  message: {
    list: "/tools/messages",
    new: "/tools/messages/new",
    edit: (id) => `/tools/messages/edit/${id}`,
    details: (id) => `/tools/messages/details/${id}`,
  },
  meterReading: {
    list: "/tools/meter-reading",
    new: "/tools/meter-reading/new",
    edit: (id) => `/tools/meter-reading/edit/${id}`,
    details: (id) => `/tools/meter-reading/details/${id}`,
  },
  trips: {
    list: "/tools/trips",
    new: "/tools/trips/new",
    edit: (id) => `/tools/trips/edit/${id}`,
    details: (id) => `/tools/trips/details/${id}`,
  },
  candidates: {
    list: "/tools/candidates",
    new: "/tools/candidates/new",
    edit: (id) => `/tools/candidates/edit/${id}`,
    details: (id) => `/tools/candidates/details/${id}`,
  },
  documents: {
    list: "/tools/documents",
    new: "/tools/documents/new",
    edit: (id) => `/tools/documents/edit/${id}`,
    details: (id) => `/tools/documents/details/${id}`,
  },
  dynamicLists: {
    list: (ids?: any[]) => `/setup/dynamic-lists/${ids?.join("/") || ""}`,
    nameOptions: (type) => `/setup/dynamic-lists/options/${type}`,
    new: (type) => `/setup/dynamic-lists/new/${type}`,
    edit: (id) => `/setup/dynamic-lists/edit/${id}`,
  },
  banks: {
    list: "/banks/",
    new: "/banks/new",
    edit: (id) => `/banks/edit/${id}`,
    details: (id) => `/banks/details/${id}`,
  },
  reconciliation: {
    list: "/bank-reconciliation/",
    statements: (id) =>
      `/bank-reconciliation/statements/details?${QueryStringEnum?.ID}=${id}`,
    uploadStatement: "/bank-reconciliation/uploadStatement",
    details: (id) => `/bank-reconciliation/details/${id}`,
    reconciledLists: `/bank-reconciliation/reconciled/`,
  },
  trash: {
    list: "/tools/trash",
  },
  shortCut: {
    list: "/short-cut",
  },
};

export default PageLinks;
