import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconTypeEnum,
  LedgerNameInterface,
  SpaceAccessListEnum,
  TableColumnInterface,
} from "interfaces";
import { MyIconButton } from "components";

import { LedgerNameContext } from "context";
import { PageLinks } from "routes";
import { useAuthorizationHook, usePaginationQueryHooks } from "useHooks";

function LedgerNameTableCols(
  pageQuery?: any
): TableColumnInterface<LedgerNameInterface>[] {
  const navigate = useNavigate();
  const { deleteHandler, getAllDataHandlers } = useContext(LedgerNameContext);
  const { calcSNHandler } = usePaginationQueryHooks();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.ACCOUNT_EDIT_LEDGER_NAME,
  ]);
  const canDelete = checkCanAccessHandler([
    SpaceAccessListEnum.ACCOUNT_DEL_LEDGER_NAME,
  ]);
  return [
    {
      title: "SN",
      render(renderData, key: number) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },
    {
      title: "Name",
      render(renderData, key: number) {
        return <span className={""}>{renderData?.name}</span>;
      },
    },
    {
      title: "Group",
      render(renderData, key: number) {
        return <span className={""}>{renderData?.group}</span>;
      },
    },
    {
      title: "Sub-Group",
      render(renderData, key: number) {
        return <span className={""}>{renderData?.subgroup?.name}</span>;
      },
    },
    {
      title: "Action",
      render(renderData, key: number) {
        return (
          <div className={"flex flex-row  gap-4 items-center"}>
            <MyIconButton
              iconSize={14}
              onClick={() => {
                if (renderData?.canEdit && canEdit) {
                  navigate(PageLinks.ledgerName.edit(renderData?._id));
                }
              }}
              containerStyle={`${
                renderData?.canEdit && canEdit
                  ? "text-primary border-primary"
                  : "text-blue-100 border-blue-100"
              }  border-2  p-2`}
              IconType={IconTypeEnum.EDIT}
            />
            <MyIconButton
              iconSize={14}
              onClick={async () => {
                if (renderData?.canDelete && canDelete) {
                  await deleteHandler(renderData?._id, {
                    onSuccess: async (payload) => {
                      await getAllDataHandlers(pageQuery);
                    },
                  });
                }
                return;
              }}
              containerStyle={`${
                (renderData?.canDelete && canDelete)
                  ? "text-tRed border-tRed"
                  : "text-red-100 border-red-100"
              }  border-2  p-2`}
              IconType={IconTypeEnum.DELETE}
            />
          </div>
        );
      },
    },
  ];
}

export default LedgerNameTableCols;
