import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AppContext } from "context";
import { getAuthToken } from "helpers";
import { AuthTokenENUM } from "interfaces";
import { PageLinks } from "../index";

export default function PublicRouteComponent() {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (state?.isAuthenticated) {
      if (
        getAuthToken(AuthTokenENUM.refreshToken) &&
        getAuthToken(AuthTokenENUM.accessToken) &&
        getAuthToken(AuthTokenENUM.loginRefreshToken)
      ) {
        navigate(PageLinks.dashboard.list);
      } else if (getAuthToken(AuthTokenENUM.loginRefreshToken)) {
        navigate(PageLinks.workSpace);
      }
    }
  }, [state?.isAuthenticated]);

  return <Outlet />;
}
