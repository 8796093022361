import React, { useContext, useEffect } from "react";
import { LoanContext } from "context";
import { getCurrency, getIconHelper, separateWithComma } from "helpers";
import { IconTypeEnum } from "interfaces";
import Skeleton from "react-loading-skeleton";

function L_MStatsContainer({
  property,
  unit,
}: {
  property?: string;
  unit?: string;
}) {
  const { getStatsHandler, loanAndMortgageStats, isStatsLoading } =
    useContext(LoanContext);
  let pageQuery = {
    property,
    unit,
  };
  useEffect(() => {
    getStatsHandler(pageQuery);
  }, [property, unit]);

  const data = [
    {
      name: "Total Loan Amount",
      value: loanAndMortgageStats?.totalLoanAmount,
      textColor: "text-[#00040E]",
      bgColor: "bg-[#f6f6f7] bg-opacity-[1] ",
    },
    {
      name: "Total Outstanding Amount",
      value: loanAndMortgageStats?.outstandingLoanAmount,
      textColor: "text-[#41C810]",
      bgColor: "bg-[#EBFAE6] bg-opacity-[1]",
    },
    {
      name: "Total Loan Amount Paid",
      value: loanAndMortgageStats?.loanPaid,
      textColor: "text-[#2680eb]",
      bgColor: "bg-[#2680EB] bg-opacity-[0.1]",
    },
    {
      name: "Total Interest Cost",
      value: loanAndMortgageStats?.interestCost,
      textColor: "text-[#c8102e]",
      bgColor: "bg-[#C8102E] bg-opacity-[0.1]",
    },
  ];
  return (
    <div className={"grid grid-cols-4 gap-8 cursor-pointer "}>
      {data?.map((e, key) => {
        return (
          <div
            key={key}
            className={` ${e?.bgColor}  rounded-md p-5 md:p-2 flex flex-col gap-5`}
          >
            <div
              className={
                "flex items-center justify-center h-[50px] w-[50px] md:h-[40px] md:w-[40px] rounded-full bg-white"
              }
            >
              <span className={`${e?.textColor} font-bold`}>
                {getCurrency()}
              </span>
            </div>
            <div className={"flex flex-col gap-1"}>
              <b className={"text-[14px] md:text-[12px]"}>{e?.name}</b>
              <div
                className={`${e?.textColor} flex items-center gap-2 text-[28px] md:text-[22px] font-semibold`}
              >
                {getCurrency()}
                {isStatsLoading ? (
                  <Skeleton className={"w-[80px]"} />
                ) : (
                  separateWithComma(e?.value)
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default L_MStatsContainer;
