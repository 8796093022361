import React, { useContext, useState } from "react";
import { UploadStatementLogInterface } from "../interfaces";
import {
  BoxModal,
  MyButton,
  MyUploadDocument,
  TableComponent,
} from "components";

import { Form, Formik } from "formik";
import { UploadBankStatementFormik } from "../helpers";
import { BankReconciliationContext } from "context";
import { TableColumnInterface, TransactionTypeEnum } from "interfaces";
import { useNavigate } from "react-router-dom";
import { PageLinks } from "routes";
import PaginationComponent from "components/cards/PaginationComponent";
import { useDateHook, usePaginationQueryHooks } from "useHooks";
import { separateWithComma } from "../../../helpers";

function UploadBankStatementModal() {
  const { uploadBankStatementHandler } = useContext(BankReconciliationContext);
  const [uploadLogs, setUploadLogs] = useState<UploadStatementLogInterface[]>(
    []
  );
  const [totalDocs, setTotalDocs] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { pageLimit } = usePaginationQueryHooks();
  const startIndex = (currentPage - 1) * pageLimit;
  const endIndex = startIndex + pageLimit;
  const displayedItems = uploadLogs.slice(startIndex, endIndex);
  const navigate = useNavigate();
  const onSubmitHandler = (values, props) => {
    uploadBankStatementHandler(values, {
      onError(error?: any) {
        setUploadLogs([]);
        setTotalDocs(0);
        setCurrentPage(1);
      },
      onSuccess(payload?: any): any {
        if (Array.isArray(payload)) {
          setUploadLogs(payload?.filter((e) => !e?.success));
          setTotalDocs(payload?.length);
          props?.resetForm({});
          setCurrentPage(1);
        }
      },
    });
  };
  const BankStatementCols = BankStatementLog(currentPage);
  return (
    <BoxModal
      containerStyle={"min-w-[700px]"}
      title={"Upload Bank Statement"}
      closeHandler={() => {
        navigate(PageLinks.reconciliation.list);
      }}
    >
      <div className={"flex flex-col gap-5"}>
        <Formik
          initialValues={UploadBankStatementFormik.initialValues}
          validationSchema={UploadBankStatementFormik.validationSchema}
          onSubmit={onSubmitHandler}
        >
          <Form className={"flex flex-col gap-2"}>
            <MyUploadDocument
              fileContainerStyle={"grid grid-cols-1"}
              accept={".xlsx, .csv"}
              name={"fileName"}
            />
            <MyButton type={"submit"} name={"Upload"} />
          </Form>
        </Formik>
        {totalDocs > 0 && (
          <div className={"flex flex-col gap-5"}>
            <b className={"text-[18px]"}>Statement Logs</b>
            <div className={"grid grid-cols-2 gap-5"}>
              <div
                className={
                  "flex flex-col gap-2 bg-opacity-[0.1] text-tGreen bg-tGreen rounded-md p-5"
                }
              >
                <span className={"text-black  font-semibold text-[14px]"}>
                  Success Count
                </span>
                <b className={"text-[22px] font-bold"}>
                  {totalDocs - uploadLogs?.length}
                </b>
              </div>
              <div
                className={
                  "flex flex-col gap-2 bg-opacity-[0.1] text-tRed bg-tRed rounded-md p-5"
                }
              >
                <span className={"text-black  font-semibold text-[14px]"}>
                  Failed Count
                </span>
                <b className={"text-[22px] font-bold"}>{uploadLogs?.length}</b>
              </div>
            </div>
            {uploadLogs?.length > 0 && (
              <>
                <b>Failed Statements</b>
                <TableComponent
                  column={BankStatementCols}
                  dataSource={displayedItems}
                />
                <PaginationComponent
                  setCustomCurrentPage={setCurrentPage}
                  customCurrentPage={currentPage}
                  totalDocs={uploadLogs?.length}
                />
              </>
            )}
          </div>
        )}
      </div>
    </BoxModal>
  );
}
function BankStatementLog(
  currentPage
): TableColumnInterface<UploadStatementLogInterface>[] {
  const { calcSNHandler } = usePaginationQueryHooks(currentPage);
  const { getDateHandler } = useDateHook();
  return [
    {
      title: "SN",
      render(renderData, key) {
        return <span>{calcSNHandler(key)}.</span>;
      },
    },
    {
      title: `Statement Code`,
      render(renderData) {
        return <span>{renderData?.payload?.statementCode || "-"}</span>;
      },
    },
    {
      title: `Date`,
      render(renderData) {
        return (
          <span>
            {getDateHandler({ date: renderData?.payload?.transactionDate }) ||
              "-"}
          </span>
        );
      },
    },
    {
      title: `Amount`,
      render(renderData) {
        return (
          <span>
            {renderData?.payload?.transactionType === TransactionTypeEnum.DR
              ? ""
              : "-"}
            {separateWithComma(renderData?.payload?.balance) || "-"}
          </span>
        );
      },
    },

    {
      title: "Remarks",
      render(renderData) {
        return (
          <div className={"w-[120px] overflow-hidden truncate"}>
            {renderData?.payload?.remarks}
          </div>
        );
      },
    },
    {
      title: "Message",
      render(renderData) {
        return <span>{renderData?.message}</span>;
      },
    },
  ];
}

export default UploadBankStatementModal;
