import React, { useContext, useEffect, useState } from "react";
import {
  Api,
  decodeLedgerNameOptionsHandler,
  decodePropertyOptionsHandler,
  decodeUnitOptionsHandler,
  getCurrency,
  separateWithComma,
} from "helpers";
import {
  MyFastTextField,
  MyIconButton,
  MySelectField,
  MyTextAreaInput,
  MyTextField,
} from "components";
import { AppContext, OptionsContext } from "context";
import { ApiUrl } from "services";
import {
  FieldTypeEnum,
  IconTypeEnum,
  InputSizeEnum,
  InvoiceInterface,
  InvoiceStatusEnum,
  TaxTypeEnum,
} from "interfaces";
import { FastField, useFormikContext } from "formik";

import { calcBillAccount, InvoiceFormikForm } from "../helpers";

function InvoiceFormListTable({
  taxType,
  removeHandler,
  insertHandler,
  value,
  index,
  setFieldValue,
  invoiceIDAndQty,
}) {
  const { values: invoiceFormValues } = useFormikContext<InvoiceInterface>();
  const {
    handlers: { setError },
  } = useContext(AppContext);
  const [unitOptions, setUnitOptions] = useState([]);
  const { getApi } = Api();
  const { propertyOptions, ledgerNameOptions, fieldTypeOptions } =
    useContext(OptionsContext);
  const { taxAmount, total } = calcBillAccount(
    value?.quantity,
    value?.rate,
    value?.discountPercent,
    value?.taxRate || 0,
    taxType
  );
  let haveLimit =
    invoiceFormValues?.invoiceType === InvoiceStatusEnum.PURCHASE_RETURN ||
    invoiceFormValues?.invoiceType === InvoiceStatusEnum.SALES_RETURN;

  useEffect(() => {
    if (value?.property) {
      (async () => {
        try {
          let res = await getApi(ApiUrl.unit.get_unit, {
            property: value?.property,
          });
          setUnitOptions(res?.data?.docs);
        } catch (err) {
          setError(true, err?.message);
          setUnitOptions([]);
        } finally {
        }
      })();
    }
  }, [value?.property]);
  return (
    <>
      <td className={"table_data min-w-[200px] w-[12%] border-b-2 px-1"}>
        <FastField
          name={`billItems.${index}.property`}
          component={(props) => (
            <MySelectField
              {...props}
              disabled={haveLimit}
              name={`billItems.${index}.property`}
              style={{
                borderRadius: "5px",
              }}
              inputSize={InputSizeEnum.MD}
              option={{
                selectOptions: decodePropertyOptionsHandler(propertyOptions),
                defaultLabel: "Property",
                onChangeCallback(currentValue?: any) {
                  setFieldValue(`billItems.${index}.unit`, "");
                },
              }}
            />
          )}
        />
      </td>
      <td className={"table_data border-b-2 px-1 min-w-[150px] w-[9%]"}>
        <MySelectField
          disabled={haveLimit || !value?.property}
          style={{
            borderRadius: "5px",
          }}
          inputSize={InputSizeEnum.MD}
          isClearable
          option={{
            selectOptions: decodeUnitOptionsHandler(unitOptions),
            defaultLabel: "Unit",
          }}
          name={`billItems.${index}.unit`}
        />
      </td>
      <td className={"table_data border-b-2 px-1 min-w-[150px] w-[9%]"}>
        <FastField
          disabled={haveLimit}
          name={`billItems.${index}.account`}
          component={(props) => {
            return (
              <MySelectField
                {...props}
                name={`billItems.${index}.account`}
                style={{
                  borderRadius: "5px",
                }}
                inputSize={InputSizeEnum.MD}
                option={{
                  selectOptions:
                    decodeLedgerNameOptionsHandler(ledgerNameOptions),
                  defaultLabel: "Account",
                }}
              />
            );
          }}
        />
      </td>
      <td className={"table_data border-b-2 px-1 min-w-[180px] w-[10%]"}>
        <MyTextAreaInput
          style={{
            fontSize: "12px",
            borderRadius: "5px",
            padding: "5px 10px",
          }}
          rows={2}
          placeholder={"type here"}
          name={`billItems.${index}.description`}
        />
      </td>
      <td className={"table_data border-b-2 px-1 max-w-[120px] w-[6%]"}>
        <MyFastTextField
          max={
            haveLimit && invoiceIDAndQty[value?._id]
              ? invoiceIDAndQty[value?._id]
              : null
          }
          inputSize={"md"}
          type={"number"}
          placeholder={"Eg: 10"}
          className={" rounded-md"}
          name={`billItems.${index}.quantity`}
        />
      </td>
      <td
        className={"table_data border-b-2 px-1 w-[6%]"}
        style={{ maxWidth: "120px" }}
      >
        <MyFastTextField
          disabled={haveLimit}
          type={"number"}
          placeholder={"Eg: 10"}
          inputSize={"md"}
          className={"  rounded-md"}
          name={`billItems.${index}.rate`}
        />
      </td>
      <td className={"table_data border-b-2 px-1 max-w-[120px] w-[6%]"}>
        <MyFastTextField
          disabled={haveLimit}
          type={"number"}
          inputSize={"md"}
          placeholder={"Eg: 10"}
          className={"rounded-md  px-1"}
          name={`billItems.${index}.discountPercent`}
        />
      </td>
      {taxType !== TaxTypeEnum.EXEMPTED && (
        <td
          className={
            "table_data border-b-2 px-1 text-center min-w-[120px] max-w-[150px] w-[9%]"
          }
        >
          <FastField
            name={`billItems.${index}.taxRate`}
            component={(props) => {
              return (
                <MySelectField
                  {...props}
                  style={{
                    borderRadius: "5px",
                  }}
                  inputSize={InputSizeEnum.MD}
                  disabled={haveLimit}
                  option={{
                    selectOptions: fieldTypeOptions[FieldTypeEnum.TAX]?.map(
                      (e) => {
                        return {
                          label: e?.label,
                          value: e?.value,
                        };
                      }
                    ),
                    defaultLabel: "Tax",
                  }}
                  name={`billItems.${index}.taxRate`}
                />
              );
            }}
          />
        </td>
      )}
      {taxType === TaxTypeEnum.INCLUSIVE && (
        <td className={"table_data  border-b-2  text-center w-[6%]"}>
          <span className={"whitespace-nowrap"}>
            {getCurrency()}{parseFloat(String(taxAmount || 0)).toFixed(2)}
          </span>
        </td>
      )}
      <td className={"table_data border-b-2  text-center  w-[6%] "}>
        <span className={"whitespace-nowrap"}>
          {getCurrency()}{separateWithComma(total)}
        </span>
      </td>
      <td className={"table_data border-b-2 text-center min-w-[100px] w-[6%]"}>
        <div className={"flex gap-3 items-center"}>
          {!haveLimit && (
            <MyIconButton
              IconType={IconTypeEnum.ADD}
              iconColor={"text-primary"}
              containerStyle={"border-primary border-[1px] p-1"}
              onClick={() =>
                insertHandler(index + 1, InvoiceFormikForm.billItemValue())
              }
            />
          )}
          {(haveLimit || index !== 0) && (
            <MyIconButton
              IconType={IconTypeEnum.MINUS}
              onClick={() => removeHandler(index)}
              iconColor={"text-tRed"}
              containerStyle={"border-tRed border-[1px] p-1"}
            />
          )}
        </div>
      </td>
    </>
  );
}

export default InvoiceFormListTable;
