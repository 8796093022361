import React, { useContext, useEffect } from "react";

import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";

import { FormConcludeButtons } from "assets";
import { PageTemplate } from "templates";
import { FieldTypeEnum, ParamsStringEnum, UserRoleEnum } from "interfaces";
import { AppContext, LoanContext, OptionsContext } from "context";
import { usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";

import { LoanFormikForm } from "../helpers";
import { L_MForm } from "../components";

function EditL_MPage() {
  const navigate = useNavigate();
  const { returnURL } = usePageQueryHooks();
  const {
    getPropertyOptionsHandler,
    getOptionsHandler,
    getUserOptionsHandler,
  } = useContext(OptionsContext);
  const {
    handlers: { setLoading },
  } = useContext(AppContext);
  const params = useParams<ParamsStringEnum>();
  const { propertyID, unitID } = usePageQueryHooks();
  const { details, getDetailsHandler, editDetailsHandler } =
    useContext(LoanContext);
  const isEdit = params.id;
  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([
        isEdit && (await getDetailsHandler(isEdit)),
        getPropertyOptionsHandler(),
        getUserOptionsHandler(UserRoleEnum.LENDER),
        getOptionsHandler(FieldTypeEnum.ANNUAL_FREQUENCY),
        getOptionsHandler(FieldTypeEnum.YEAR_FREQUENCY),
        getOptionsHandler(FieldTypeEnum.YEARLY_FREQUENCY),
        getOptionsHandler(FieldTypeEnum.FINANCE_TYPE),
        getOptionsHandler(FieldTypeEnum.INTEREST_TYPE),
        getOptionsHandler(FieldTypeEnum.MORTGAGE_TYPE),
        getOptionsHandler(FieldTypeEnum.TENANCY_PAYMENT_TERMS),
      ]);

      setLoading(false);
    })();
  }, [isEdit]);
  const submitHandler = async (values, formikHelpers) => {
    await editDetailsHandler(values, formikHelpers, {
      onSuccess: async (payload) => {
        if (returnURL) {
          navigate(returnURL);
        } else {
          navigate(PageLinks?.loansAndMortgages?.details(payload?.data));
        }
      },
    });
  };
  return (
    <PageTemplate
      title={"Loans/Mortgages"}
      scrollAll
      backNavigation={{
        title: `${isEdit ? "Edit" : "New"} Loans/Mortgages`,
        path: PageLinks.loansAndMortgages.list,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={LoanFormikForm?.initialValues(
          isEdit
            ? details
            : {
                property: {
                  _id: propertyID,
                },
                unit: {
                  _id: unitID,
                },
              }
        )}
        validationSchema={LoanFormikForm?.validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={"flex flex-col gap-5"}>
              <L_MForm />
              <FormConcludeButtons
                submitButton={{
                  title: "Save ",
                  isLoading: isSubmitting,
                }}
                cancelButton={{
                  title: "Cancel",
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </PageTemplate>
  );
}

export default EditL_MPage;
