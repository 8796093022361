import React, { useContext, useEffect } from "react";
import { FieldArray, useFormikContext } from "formik";

import {
  MyButton,
  MyCheckInput,
  MyIconButton,
  MySelectField,
  MyTextField,
  MyUploadDocument,
} from "components";
import {
  decodeDynamicFieldHandler,
  decodePropertyOptionsHandler,
  decodeUnitOptionsHandler,
  decodeUserOptionsHandler,
  getCurrency,
  separateWithComma,
} from "helpers";
import {
  FieldTypeEnum,
  IconTypeEnum,
  LoanAndMortgageInterface,
} from "interfaces";
import { OptionsContext } from "context";
import { FormWrapper } from "assets";
import { LoanFormikForm } from "../helpers";

function L_MForm() {
  const { values, setFieldValue } =
    useFormikContext<LoanAndMortgageInterface<string, string, string>>();
  const calculateTotalFinanceCost = () => {
    let cost = 0,
      additionalLoanCost = 0;
    values?.financeCost?.forEach((e) => {
      const costValue =
        typeof e?.cost === "string" ? parseInt(e?.cost) || 0 : e?.cost || 0;
      cost += costValue;
      const isIncludedInMortgageLoan =
        typeof e?.isIncludedInMortgageLoan !== "boolean"
          ? e?.isIncludedInMortgageLoan === "true"
            ? true
            : false
          : e.isIncludedInMortgageLoan;
      if (!isIncludedInMortgageLoan) {
        additionalLoanCost += costValue;
      }
    });
    return { cost, additionalLoanCost };
  };
  const { cost, additionalLoanCost } = calculateTotalFinanceCost();
  const financeCost = cost || 0;
  const totalBalance =
    (typeof values?.loanAmount === "string"
      ? parseInt(values?.loanAmount) || 0
      : values?.loanAmount || 0) + (additionalLoanCost || 0);
  const {
    propertyOptions,
    unitOptions,
    userOptions,
    fieldTypeOptions,
    getUnitOptionsHandler,
  } = useContext(OptionsContext);

  useEffect(() => {
    if (values?.property) {
      getUnitOptionsHandler(values?.property);
    }
  }, [values?.property]);
  return (
    <div className={"flex flex-col gap-10 mt-5"}>
      <div
        className={`grid grid-cols-4 md:grid-cols-3 sm:grid-cols-1 items-start gap-5`}
      >
        <MySelectField
          name={"property"}
          label={"Property"}
          isRequired
          isClearable
          option={{
            selectOptions: decodePropertyOptionsHandler(propertyOptions),
            onChangeCallback(currentValue?: any) {
              setFieldValue("unit", "", false);
            },
          }}
        />
        <MySelectField
          disabled={!values?.property}
          name={"unit"}
          label={"Unit"}
          option={{
            selectOptions: decodeUnitOptionsHandler(unitOptions),
          }}
        />
      </div>
      {/*finance cost schedule*/}
      <div className={"flex flex-col gap-10"}>
        <div
          className={
            "grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2 items-center gap-5 w-full"
          }
        >
          <div className={"font-bold text-[20px]"}>Finance Cost Schedule</div>
          <div className={"text-[24px] "}>
            Total Cost:{"   "}
            <span
              className={"font-bold text-primary font-semibold text-[24px]"}
            >
              {getCurrency()}{financeCost}
            </span>
          </div>
        </div>
        <FieldArray
          name={"financeCost"}
          render={({ remove, insert }) => {
            return (
              <div className={"flex flex-col gap-5"}>
                {values?.financeCost?.map((e, index) => {
                  return (
                    <FinanceCostScheduleForm
                      key={index}
                      insertHandler={(index) =>
                        insert(index + 1, LoanFormikForm.financeCostValue())
                      }
                      index={index}
                      removeHandler={() => remove(index)}
                    />
                  );
                })}
              </div>
            );
          }}
        />
      </div>
      {/*Loan details*/}
      <FormWrapper
        header={{
          title: {
            name: "Loan Details",
          },
        }}
      >
        <div
          className={
            "grid grid-cols-7 md:grid-cols-4 sm:grid-cols-2 items-start gap-5"
          }
        >
          <MySelectField
            isRequired
            option={{
              selectOptions: decodeDynamicFieldHandler(
                fieldTypeOptions[FieldTypeEnum.FINANCE_TYPE]
              ),
            }}
            isCreatable={{
              type: FieldTypeEnum.FINANCE_TYPE,
            }}
            name={"financeType"}
            label={"Finance Type"}
          />
          <MySelectField
            isRequired
            isCreatable={{
              type: FieldTypeEnum.MORTGAGE_TYPE,
            }}
            label={"Mortgage Type"}
            option={{
              selectOptions: decodeDynamicFieldHandler(
                fieldTypeOptions[FieldTypeEnum.MORTGAGE_TYPE]
              ),
            }}
            name={"mortgageType"}
          />
          <MyTextField
            isRequired
            name={"loanAmount"}
            label={"Loan Amount"}
            isNumberOnly
            placeholder={`${getCurrency()}00.00`}
          />
          <div className={"input_container"}>
            <span className={"input_label"}>Total Balance</span>
            <div
              className={`border-[1px] font-bold text-primary bg-gray-100 flex items-center rounded-full outline-none border-[#C0C0C0] text-[16px] pl-6 h-[48px]  `}
            >
              {getCurrency()}{separateWithComma(totalBalance)}
            </div>
          </div>
          <MyTextField
            isRequired
            name={"startDate"}
            label={"Start Date"}
            type={"date"}
          />
          <MyTextField
            isRequired
            placeholder={"Eg.25"}
            name={"totalLoanTermQuantity"}
            label={"Total Loan Term"}
            type={"number"}
          />
          <MySelectField
            isRequired
            label={"Frequency"}
            option={{
              selectOptions: decodeDynamicFieldHandler(
                fieldTypeOptions[FieldTypeEnum.YEAR_FREQUENCY]
              ),
            }}
            name={"totalLoanTermFrequency"}
          />
        </div>
      </FormWrapper>

      {/*Interest charge*/}
      <FormWrapper
        header={{
          title: {
            name: "Interest Charge",
          },
        }}
      >
        <div className={"flex flex-col gap-5"}>
          <FieldArray
            name={"interest"}
            render={({ insert, remove }) => {
              return (
                <div className={"flex flex-col gap-5"}>
                  {values?.interest?.map((e, index) => {
                    return (
                      <InterestForm
                        insertHandler={(index) =>
                          insert(index + 1, LoanFormikForm.interestValue())
                        }
                        key={index}
                        index={index}
                        removeHandler={() => remove(index)}
                      />
                    );
                  })}
                </div>
              );
            }}
          />
          <div className={"grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1"}>
            <div className={"col-span-2"}>
              <MySelectField
                label={"Lender Name"}
                isRequired
                option={{
                  selectOptions: decodeUserOptionsHandler(userOptions),
                }}
                name={`lender`}
              />
            </div>
          </div>
        </div>
      </FormWrapper>
      <FormWrapper header={{ title: { name: "Documents" } }}>
        <FieldArray
          name={"documents"}
          render={({ push, remove, insert }) => {
            return (
              <>
                <div className={"flex flex-col gap-5"}>
                  {values?.documents?.map((e, key) => {
                    return (
                      <div
                        className={
                          "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"
                        }
                      >
                        <MyTextField
                          label={"Document Name"}
                          name={`documents.${key}.label`}
                          placeholder={"eg. Finance Doc"}
                        />
                        <MyUploadDocument
                          fileType={"image"}
                          fileContainerStyle={"flex"}
                          label={"Document"}
                          name={`documents.${key}.document`}
                        />
                        <div
                          className={
                            "flex items-center gap-2 mt-5 md:mt-0 sm:mt-0"
                          }
                        >
                          <MyIconButton
                            iconColor={"text-primary"}
                            containerStyle={"border-[1px] border-primary p-3"}
                            IconType={IconTypeEnum.ADD}
                            onClick={() => insert(key + 1, {})}
                          />
                          <MyIconButton
                            iconColor={"text-tRed"}
                            containerStyle={"border-[1px] border-tRed p-3"}
                            IconType={IconTypeEnum.CLOSE}
                            onClick={() => remove(key)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                {!values?.documents?.length && (
                  <div className={" "}>
                    <MyButton
                      isOutline
                      iconType={IconTypeEnum.ADD}
                      onClick={() => {
                        push({});
                      }}
                      name={"Add Document"}
                    />
                  </div>
                )}
              </>
            );
          }}
        />
      </FormWrapper>
    </div>
  );
}

const FinanceCostScheduleForm = ({ index, removeHandler, insertHandler }) => {
  return (
    <div
      className={
        "grid grid-cols-4 md:grid-cols-3 sm:grid-cols-2  items-start gap-5"
      }
    >
      <MyTextField
        isRequired
        placeholder={"Eg. Loan arrangement fees"}
        label={"Finance Name"}
        name={`financeCost.${index}.financeName`}
      />

      <MyTextField
        isRequired
        placeholder={`${getCurrency()}00.00`}
        label={"Cost"}
        isNumberOnly
        name={`financeCost.${index}.cost`}
      />
      <div className={"flex flex-col gap-2 mt-5 sm:mt-0"}>
        <MyCheckInput
          iconType={"radio"}
          defaultValue={true}
          name={`financeCost.${index}.isIncludedInMortgageLoan`}
          label={"Include in Loans/Mortgage."}
        />
        <MyCheckInput
          iconType={"radio"}
          label={"Additional in Loans/Mortgage."}
          defaultValue={false}
          name={`financeCost.${index}.isIncludedInMortgageLoan`}
        />
      </div>

      <div className={"flex items-center gap-5 mt-8 md:mt-0 sm:mt-0"}>
        <MyIconButton
          iconColor={"text-primary"}
          containerStyle={"border-[1px] border-primary p-3"}
          IconType={IconTypeEnum.ADD}
          onClick={() => insertHandler(index)}
        />
        {index !== 0 && (
          <MyIconButton
            iconColor={"text-tRed"}
            containerStyle={"border-[1px] border-tRed p-3"}
            IconType={IconTypeEnum.MINUS}
            onClick={removeHandler}
          />
        )}
      </div>
    </div>
  );
};

const InterestForm = ({ index, removeHandler, insertHandler }) => {
  const { fieldTypeOptions } = useContext(OptionsContext);
  return (
    <div
      className={
        "grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1 gap-5 items-start"
      }
    >
      <MyTextField
        isRequired
        label={"Interest Rate (%)"}
        name={`interest.${index}.rate`}
        placeholder={"Eg. 2.9%"}
        type={"number"}
      />
      <MySelectField
        isRequired
        label={"Interest Type"}
        option={{
          selectOptions: decodeDynamicFieldHandler(
            fieldTypeOptions[FieldTypeEnum.INTEREST_TYPE]
          ),
        }}
        name={`interest.${index}.interestType`}
      />

      <MyTextField
        isRequired
        label={"Start Date"}
        type={"date"}
        name={`interest.${index}.interestApplyDate`}
      />
      <MyTextField
        isRequired
        label={"End Date"}
        type={"date"}
        name={`interest.${index}.interestEndDate`}
      />
      <div className={"mt-8 sm:mt-0 flex items-center gap-2"}>
        <div>
          <MyButton
            isOutline
            name={"Add Interest"}
            onClick={() => {
              insertHandler(index);
            }}
            iconType={IconTypeEnum.ADD}
          />
        </div>

        {index !== 0 && (
          <MyIconButton
            containerStyle={"border-[1px] border-tRed p-3"}
            iconColor={"text-tRed"}
            IconType={IconTypeEnum.DELETE}
            onClick={() => removeHandler()}
          />
        )}
      </div>
    </div>
  );
};

export default L_MForm;
