import { useField } from "formik";
import { useContext, useState } from "react";
import CreatableSelect from "react-select/creatable";
import Select, { Props } from "react-select";

import { AppContext, OptionsContext } from "context";
import { ApiUrl } from "services";
import { Api, capitalizeFirstLetter, DynamicListConstantList } from "helpers";

import { MySelectFieldInterface } from "./interfaces";
import "./styles.css";
import { InputSizeEnum } from "interfaces";
export default function MySelectField({
  label,
  option,
  isRequired,
  fetchOptionsHandler,
  style,
  isClearable,
  isCreatable,
  isFastField,
  labelClassName,
  inputSize,
  ...props
}: MySelectFieldInterface) {
  const [field, meta, helpers] = useField(props);
  const [isLoading, setLoading] = useState(false);
  const { getOptionsHandler } = useContext(OptionsContext);
  const { postApi } = Api();
  const { handlers } = useContext(AppContext);
  let isError = meta.touched && meta.error && typeof meta?.error === "string";
  const isMultiple = Array.isArray(field?.value);
  const canCreateItem = DynamicListConstantList[isCreatable?.type];

  const onFocusHandler = async () => {
    try {
      setLoading(true);
      if (typeof fetchOptionsHandler === "function") {
        await fetchOptionsHandler();
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  let multiSelectValues = [];
  let selectedValue = {};
  const isOptionTypeGroup =
    option?.selectOptions?.length > 0 && option?.selectOptions[0]?.options;
  if (isMultiple) {
    if (isOptionTypeGroup) {
      multiSelectValues = (() => {
        let foundOption = [];
        field?.value?.map((value) => {
          for (const el of option?.selectOptions) {
            let result = el?.options?.find((f) => {
              return f?.value == value;
            });
            if (result) {
              foundOption.push(result);
            }
          }
        });
        return foundOption;
      })();
    } else {
      multiSelectValues = option?.selectOptions?.filter((e) => {
        return field.value?.some((value) => e?.value === value);
      });
    }
  } else {
    if (isOptionTypeGroup) {
      selectedValue = (() => {
        let foundOption = null;
        for (const el of option?.selectOptions) {
          let result = el?.options?.find((f) => {
            return f?.value == field?.value;
          });
          if (result) {
            foundOption = result;
            break;
          }
        }
        return foundOption;
      })();
    } else {
      selectedValue = option?.selectOptions?.find((e) => {
        return e?.value === field.value;
      });
    }
  }
  const onCreateHandler = async (value) => {
    try {
      handlers?.setLoading(true);
      await postApi(ApiUrl.dynamicList.post_addItem, {
        fieldType: isCreatable?.type,
        label: value,
        description: value,
      });
      await getOptionsHandler(isCreatable?.type);
      handlers?.setSuccess(true, "Created Successfully");
    } catch (err) {
      handlers?.setError(true, err?.message);
    } finally {
      handlers?.setLoading(false);
    }
  };

  let SelectProps: Props = {
    isClearable: true,
    isLoading: isLoading,
    isMulti: isMultiple,
    isDisabled: props?.disabled,
    className: "react-select-container",
    classNamePrefix: "custom-react-select",
    styles: {
      control: (provided, state) => ({
        ...provided,
        border: isError
          ? "1px solid #C8102E"
          : state.isFocused
          ? "2px solid #2680EB"
          : "1px solid #C0C0C0",
        borderRadius: style?.borderRadius || "30px",
        boxShadow: "none",
        width: style?.width,
        height:
          inputSize === InputSizeEnum.SM
            ? "35px"
            : inputSize === InputSizeEnum.MD
            ? "35px"
            : "48px",
        minHeight:
          inputSize === InputSizeEnum.SM
            ? "35px"
            : inputSize === InputSizeEnum.MD
            ? "35px"
            : "48px",
        transition: "none",
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        padding:
          style?.padding || inputSize === InputSizeEnum.SM
            ? "0 0px 0px 10px"
            : inputSize === InputSizeEnum.MD
            ? "0 0px 0px 10px"
            : "0 0px 0px 20px",
        fontSize:
          inputSize === InputSizeEnum.SM
            ? "12px"
            : inputSize === InputSizeEnum.MD
            ? "12px"
            : "16px",
        transition: "none",
      }),

      input: (provided, state) => ({
        ...provided,
        margin: "0px",
        outline: "none",
        boxShadow: "none",
        transition: "none",
      }),
      placeholder: (provided, state) => ({
        ...provided,
        whiteSpace: "nowrap",
      }),
      indicatorSeparator: (state) => ({
        ...state,
        display: "none",
        padding: "0px 0px",
      }),
      clearIndicator: (state) => ({
        ...state,
        padding: "0px 0px",
      }),
      dropdownIndicator: (state) => ({
        ...state,
        padding: "0px  10px 0px 0px",
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        minHeight:
          style?.minHeight || inputSize === InputSizeEnum.SM
            ? "35px"
            : inputSize === InputSizeEnum.MD
            ? "35px"
            : "48px",
        height:
          style?.minHeight || inputSize === InputSizeEnum.SM
            ? "35px"
            : inputSize === InputSizeEnum.MD
            ? "35px"
            : "48px",
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 999,
        fontSize:
          inputSize === InputSizeEnum.SM
            ? "12px"
            : inputSize === InputSizeEnum.MD
            ? "12px"
            : "16px",
      }),
      option: (base, { isSelected }) => ({
        ...base,
        borderBottom: "1px solid #D0D0D0",
        borderRadius: "5px 5px 0px 0px",
        color: isSelected ? "white" : "#00040E",
        backgroundColor: isSelected ? "#2680EB" : "",
      }),
      menuList: (base) => ({
        ...base,
        padding: 5,
      }),
      menu: (base, {}) => ({
        ...base,
        borderRadius: 20,
        border: "1px solid #C0C0C0",
        overflow: "hidden",
        padding: 5,
        boxShadow: "none",
        backgroundColor: "white",
      }),
    },
    theme: (theme) => ({
      ...theme,
      colors: {
        ...theme.colors,
        neutral50: "#C0C0C0",
      },
    }),
    autoFocus: props?.autoFocus,
    value: field?.value ? (isMultiple ? multiSelectValues : selectedValue) : "",
    onFocus: onFocusHandler,
    options: option?.selectOptions,
    name: props.name,
    placeholder:
      capitalizeFirstLetter(option?.defaultLabel) ||
      `Select ${isMultiple ? "Options" : "Option"}`,
    onChange: (newValue) => {
      if (Array.isArray(newValue)) {
        helpers.setValue(
          newValue?.map((e) => e?.value),
          false
        );
      } else {
        // @ts-ignore
        helpers.setValue(newValue?.value || "", true);
      }
      typeof option?.onChangeCallback === "function" &&
        option?.onChangeCallback(newValue);
    },
  };

  return (
    <div className="flex flex-col gap-[14px] justify-end input_container ">
      {label && (
        <label
          className={`input_label ${
            inputSize === "sm"
              ? "text-[12px]"
              : inputSize === "md"
              ? "text-[12px]"
              : "text-[14px]"
          } ${labelClassName} `}
        >
          <span>{label}</span>
          {isRequired && <span className={"text-tRed text-[12px]"}>*</span>}
        </label>
      )}
      {isCreatable && canCreateItem ? (
        <CreatableSelect
          maxMenuHeight={200}
          onCreateOption={onCreateHandler}
          {...SelectProps}
        />
      ) : (
        <Select
          menuPortalTarget={document.body}
          maxMenuHeight={200}
          {...SelectProps}
        />
      )}

      {isError && meta?.error?.trim() && (
        <span className="input_textError">* {meta.error}</span>
      )}
    </div>
  );
}
