import PageLinks from "routes/PageLinks";
import {
  IconTypeEnum,
  InvoiceStatusEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
  UserRoleEnum,
} from "interfaces";
import { ReportPageLinkInterface } from "./interfaces";
import { useAuthorizationHook } from "../../useHooks";
import { FiKey } from "react-icons/fi";
import getIconHelpers from "../../helpers/utils/getIconHelpers";
import { BiReceipt } from "react-icons/bi";
import { CgMenuBoxed } from "react-icons/cg";
import { IoBedOutline } from "react-icons/io5";
export const AccessibleReportLinks = (): ReportPageLinkInterface[] => {
  let links = reportDetailLinks;
  const { checkCanAccessHandler } = useAuthorizationHook();
  const accLink: ReportPageLinkInterface[] = links?.reduce(
    (acc, currentValue) => {
      const childFiltered = currentValue?.reports?.reduce(
        (childAcc, childCurrent) => {
          if (checkCanAccessHandler(childCurrent?.access)) {
            return [...childAcc, childCurrent];
          }
          return childAcc;
        },
        []
      );
      if (!childFiltered?.length) {
        return [...acc];
      }
      return [
        ...acc,
        {
          ...currentValue,
          reports: childFiltered,
        },
      ];
    },
    []
  );

  return accLink;
};
const RentalIcon = getIconHelpers(IconTypeEnum.RENTAL);
const InvoiceIcon = getIconHelpers(IconTypeEnum.INVOICE);
const FinanceIcon = getIconHelpers(IconTypeEnum.FINANCE);
const UsersIcon = getIconHelpers(IconTypeEnum.USERS);

const reportDetailLinks: ReportPageLinkInterface[] = [
  {
    name: "Property Reports",
    Icon: RentalIcon,
    reports: [
      {
        name: "Property Details",
        pageLink: PageLinks.report.propertyReport,
        access: [SpaceAccessListEnum.RENTAL_READ_PROPERTY],
      },
      {
        name: "Property List",
        pageLink: PageLinks.property.list,
        access: [SpaceAccessListEnum.RENTAL_READ_PROPERTY],
      },
      {
        name: "Unit List",
        pageLink: PageLinks.unit.list,
        access: [SpaceAccessListEnum.RENTAL_READ_UNIT],
      },
      {
        name: "Inventory List",
        pageLink: PageLinks.inventory.list,
        access: [SpaceAccessListEnum.INVENTORY_READ_INVENTORY],
      },
      {
        name: "Repair and Maintenance List",
        pageLink: PageLinks.maintenance.list,
        access: [
          SpaceAccessListEnum.REPAIR_AND_MAINTENANCE_READ_REPAIR_AND_MAINTENANCE,
        ],
      },
    ],
  },
  {
    name: "Tenant Reports",
    Icon: FiKey,
    reports: [
      {
        name: "Tenant List",
        pageLink: PageLinks.spaceUser.list(UserRoleEnum.TENANT),
        access: [SpaceAccessListEnum.AUTH_READ_USER],
      },
      {
        name: "Tenant History",
        pageLink: PageLinks.spaceUser.list(UserRoleEnum.TENANT),
        access: [SpaceAccessListEnum.AUTH_READ_USER],
      },
      {
        name: "Tenancy List",
        pageLink: PageLinks.tenancy.list,
        access: [SpaceAccessListEnum.TENANCY_READ_TENANCY],
      },
      {
        name: "Booking List",
        pageLink: PageLinks.bookings.list,
        access: [SpaceAccessListEnum.BOOKING_READ_BOOKING],
      },
    ],
  },
  {
    name: "Invoice",
    Icon: InvoiceIcon,
    reports: [
      {
        name: "Invoice List",
        access: [SpaceAccessListEnum.FINANCE_READ_INVOICE],
        pageLink: PageLinks.invoice.list,
      },
      {
        name: "Invoice Draft",
        access: [SpaceAccessListEnum.FINANCE_READ_INVOICE],
        pageLink:
          PageLinks.invoice.list +
          `/${InvoiceStatusEnum.DRAFT}?${QueryStringEnum.ACTIVE_TAB}=${InvoiceStatusEnum.DRAFT}`,
      },
      {
        name: "Sales List",
        access: [SpaceAccessListEnum.FINANCE_READ_INVOICE],
        pageLink:
          PageLinks.invoice.list +
          `/${InvoiceStatusEnum.SALES}?${QueryStringEnum.ACTIVE_TAB}=${InvoiceStatusEnum.SALES}`,
      },
      {
        name: "Purchase List",
        access: [SpaceAccessListEnum.FINANCE_READ_INVOICE],
        pageLink:
          PageLinks.invoice.list +
          `/${InvoiceStatusEnum.PURCHASE}?${QueryStringEnum.ACTIVE_TAB}=${InvoiceStatusEnum.PURCHASE}`,
      },
      {
        name: "Invoice Settled",
        access: [SpaceAccessListEnum.FINANCE_READ_INVOICE],
        pageLink:
          PageLinks.invoice.list +
          `/${InvoiceStatusEnum.SETTLED}?${QueryStringEnum.ACTIVE_TAB}=${InvoiceStatusEnum.SETTLED}`,
      },
    ],
  },
  {
    name: "Accounting Reports",
    Icon: BiReceipt,
    reports: [
      {
        name: "Journal",
        pageLink: PageLinks.journal.list,
        access: [SpaceAccessListEnum.ACCOUNT_ADD_JOURNAL],
      },
      {
        name: "Trial Balance",
        pageLink: PageLinks.account.trialBalance,
        access: [SpaceAccessListEnum.ACCOUNT_ACCESS_TRIAL_BALANCE],
      },
      {
        name: "Ledger",
        pageLink: PageLinks.ledger.list,
        access: [SpaceAccessListEnum.ACCOUNT_ACCESS_LEDGER],
      },

      {
        name: "Income Statement",
        pageLink: PageLinks.account.incomeStatement,
        access: [SpaceAccessListEnum.ACCOUNT_ACCESS_INCOME_STATEMENT],
      },
      {
        name: "Balance Sheet",
        pageLink: PageLinks.account.balanceSheet,
        access: [SpaceAccessListEnum.ACCOUNT_ACCESS_BALANCE_SHEET],
      },
    ],
  },
  {
    name: "Finance",
    Icon: FinanceIcon,
    reports: [
      {
        name: "Loans/Mortgages",
        pageLink: PageLinks.loansAndMortgages.list,
        access: [SpaceAccessListEnum.FINANCE_ADD_LOAN_AND_MORTGAGE],
      },
      {
        name: "Reconciled Statement",
        pageLink: PageLinks.reconciliation.reconciledLists,
        access: [SpaceAccessListEnum.FINANCE_READ_RECONCILE_STATEMENT],
      },
    ],
  },
  {
    name: "Other Reports",
    Icon: CgMenuBoxed,
    reports: [
      {
        name: "Tasks List",
        pageLink: PageLinks.task.list,
        access: [SpaceAccessListEnum.TASK_READ_TASK],
      },
      {
        name: "Document Lists",
        pageLink: PageLinks.documents.list,
        access: [SpaceAccessListEnum.DOCUMENT_READ_DOCUMENT],
      },
      {
        name: "Notes",
        pageLink: PageLinks.notes.list,
        access: [],
      },
      {
        name: "Candidates List",
        pageLink: PageLinks.candidates.list,
        access: [SpaceAccessListEnum.CANDIDATE_READ_CANDIDATE],
      },
    ],
  },
  {
    name: "Tools Reports",
    Icon: IoBedOutline,
    reports: [
      {
        name: "Meter Reading",
        pageLink: PageLinks.meterReading.list,
        access: [SpaceAccessListEnum.TOOLS_READ_METER_READING],
      },
      {
        name: "Trips",
        pageLink: PageLinks.trips.list,
        access: [SpaceAccessListEnum.TOOLS_READ_TRIP],
      },
    ],
  },
  {
    name: "Users Reports",
    Icon: UsersIcon,
    reports: [
      {
        name: "All Users",
        pageLink: PageLinks.spaceUser.list(UserRoleEnum.NONE),
        access: [SpaceAccessListEnum.AUTH_READ_USER],
      },
      {
        name: "Owners",
        pageLink: PageLinks.spaceUser.list(UserRoleEnum.OWNER),
        access: [SpaceAccessListEnum.AUTH_READ_USER],
      },
      {
        name: "Suppliers",
        pageLink: PageLinks.spaceUser.list(UserRoleEnum.SUPPLIER),
        access: [SpaceAccessListEnum.AUTH_READ_USER],
      },
      {
        name: "Lenders",
        pageLink: PageLinks.spaceUser.list(UserRoleEnum.LENDER),
        access: [SpaceAccessListEnum.AUTH_READ_USER],
      },
      {
        name: "Landlords",
        pageLink: PageLinks.spaceUser.list(UserRoleEnum.LANDLORD),
        access: [SpaceAccessListEnum.AUTH_READ_USER],
      },
    ],
  },
];
