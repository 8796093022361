import React, { useContext } from "react";
import { AppContext } from "context";
import { TableFilterCard } from "content";
import {
  getCurrency,
  separateWithBracket,
  getBalanceSheetTitleHandler,
} from "helpers";

import { ContentTypeEnum } from "../interfaces";
import {
  ContentRowComponent,
  MessageComponent,
  CompareFilterComponent,
} from "../assets";
import { useGetStartEndDateRangeHooks } from "useHooks";

import { IconTypeEnum } from "interfaces";
import { MyButton, MyToggleButton } from "components";
interface PropsInterface {
  isLoading: boolean;
  haveData: boolean;
  totalAmount: number;
  children: any;
  title: string;
  haveTitle?: boolean;
  isForBalanceSheet?: boolean;
  generateReportHandler?();
  hideZero?: boolean;
  setHideZero?: any;
}
function BalanceSheetTemplate({
  isLoading,
  haveData,
  totalAmount,
  children,
  title,
  generateReportHandler,
  isForBalanceSheet,
  haveTitle = true,
  hideZero,
  setHideZero,
}: PropsInterface) {
  const {
    startDate,
    endDate,
    rangeType,
    isAllTime,
    compareStartDate,
    compareEndDate,
    isCompareAllTime,
    compareRangeType,
  } = useGetStartEndDateRangeHooks();
  const isCompared = compareStartDate && compareEndDate ? true : false;
  const isComparable = startDate && endDate;
  const { accountDateTitle } = getBalanceSheetTitleHandler({
    startDate,
    endDate,
    rangeType,
    isAllTime,
    isForBalanceSheet,
  });
  const { accountDateTitle: compareTitle } = getBalanceSheetTitleHandler({
    startDate: compareStartDate,
    endDate: compareEndDate,
    rangeType: compareRangeType,
    isAllTime: isCompareAllTime,
    isForBalanceSheet,
  });
  const {
    state: { spaceSettings },
  } = useContext(AppContext);
  return (
    <div className={"flex flex-col gap-5"}>
      <div className={"flex flex-col gap-5"}>
        <TableFilterCard haveLabel haveDateRange />
        {isComparable && <CompareFilterComponent />}
      </div>
      {isLoading && <MessageComponent message={"Please wait.."} />}
      {!isLoading && !haveData && (
        <MessageComponent message={"Failed to generate"} />
      )}
      {!isLoading && haveData && (
        <div className={" border-2 rounded-md"}>
          <div
            className={
              "flex justify-between items-center gap-5 p-5 border-b-2 text-[20px] "
            }
          >
            {haveTitle ? (
              <div>
                <span>Total: </span>
                <b className={"text-primary"}>
                  {getCurrency()}{separateWithBracket(totalAmount)}
                </b>
              </div>
            ) : (
              <div></div>
            )}

            <div className={"flex items-center justify-center gap-5"}>
              <MyToggleButton
                value={hideZero}
                type={"button"}
                buttonType={"toggle"}
                label={"Hide Zero"}
                changeHandler={() => setHideZero((e) => !e)}
              />
              <MyButton
                iconType={IconTypeEnum.DOWNLOAD}
                isOutline
                onClick={generateReportHandler}
                name={"Export .xlsx"}
              />
            </div>
          </div>

          <div className={"flex items-center justify-center py-4"}>
            <div className={"flex flex-col gap-3 text-[20px] text-center"}>
              <span className={"text-gray-500 text-[30px]"}>
                {spaceSettings?.companyName}
              </span>
              <b>{title}</b>
              <span className={"text-gray-500"}>{accountDateTitle}</span>
            </div>
          </div>
          <hr />
          <ContentRowComponent
            isCompared={isCompared}
            title={"Particulars"}
            value={"Amount"}
            firstComparedAmount={compareTitle}
            deviation={"Compared %"}
            comparedAmount={"Compared Amount"}
            type={ContentTypeEnum.title}
          />
          <hr />
          {children}
        </div>
      )}
    </div>
  );
}

export default BalanceSheetTemplate;
