import React from "react";
import { IconComponent, ToggleContentWrapper } from "../components";
import {
  DashboardApiTypeEnum,
  IconTypeEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
  UnitDashboardStatsInterface,
} from "interfaces";
import { usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";
import { AccessVerifier } from "templates";

function UnitStatsContent({ isToggle }) {
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  return (
    <ToggleContentWrapper
      toggle={isToggle}
      title={"Units Status"}
      getDataType={DashboardApiTypeEnum.unit}
      haveDateFilter={false}
      havePropertyFilter={false}
      isViewAll={{
        path: PageLinks.unit.list,
      }}
    >
      {(data: UnitDashboardStatsInterface) => {
        return (
          <div
            className={
              "grid grid-cols-2 bg-[#F6F6FF] rounded-md mt-5 p-5 divide-x divide-x-1"
            }
          >
            <div className={"flex items-center gap-5  cursor-pointer"}>
              <IconComponent
                color={"purple"}
                iconType={IconTypeEnum.UNIT_OCCUPIED}
              />
              <div className={"flex flex-col  text-[14px] text-gray-500"}>
                <span className={"font-bold text-[20px]"}>
                  {data?.occupied || "-"}
                </span>
                <AccessVerifier
                  accessKeys={[SpaceAccessListEnum.RENTAL_READ_UNIT]}
                  isForPage={false}
                >
                  <span
                    className={"underline text-[12px] cursor-pointer"}
                    onClick={() =>
                      navigateWithReturnUrlHandler(
                        PageLinks.unit.list,
                        `${QueryStringEnum.STATUS}=occupied`
                      )
                    }
                  >
                    View
                  </span>
                </AccessVerifier>

                <span>Occupied</span>
              </div>
            </div>
            <div className={"flex items-center gap-5 pl-8 cursor-pointer"}>
              <IconComponent
                color={"red"}
                iconType={IconTypeEnum.UNIT_VACANT}
              />
              <div className={"flex flex-col  text-[14px] text-gray-500"}>
                <span className={"font-bold text-[20px]"}>
                  {data?.vacant || "-"}
                </span>

                <AccessVerifier
                  accessKeys={[SpaceAccessListEnum.RENTAL_READ_UNIT]}
                  isForPage={false}
                >
                  <span
                    className={"underline text-[12px] cursor-pointer"}
                    onClick={() =>
                      navigateWithReturnUrlHandler(
                        PageLinks.unit.list,
                        `${QueryStringEnum.STATUS}=vacant`
                      )
                    }
                  >
                    View
                  </span>
                </AccessVerifier>

                <span>Vacant</span>
              </div>
            </div>
          </div>
        );
      }}
    </ToggleContentWrapper>
  );
}

export default UnitStatsContent;
