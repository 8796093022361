import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MyButton, TableCardComponent } from "components";
import { AccessVerifier, PageTemplate } from "templates";
import {
  FieldTypeEnum,
  IconTypeEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { TableFilterCard } from "content";
import { ReportContext, TaskContext } from "context";

import { TaskTableColumns } from "../components";
import { usePageQueryHooks, usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function TaskPage() {
  const {
    allData,
    isLoading,
    getAllDataHandlers,
    totalArchiveDocs,
    totalDocs,
  } = useContext(TaskContext);
  const { generateTaskReportHandler } = useContext(ReportContext);
  const navigate = useNavigate();
  const { isArchive, currentPage } = usePaginationQueryHooks();
  const { unitID, propertyID, status, type } = usePageQueryHooks();

  const pageQuery = {
    unit: unitID,
    property: propertyID,
    taskStatus: status,
    taskPriority: type,
  };
  useEffect(() => {
    getAllDataHandlers({
      ...pageQuery,
    });
  }, [isArchive, unitID, propertyID, status, type, currentPage]);

  const tableCardProps = {
    column: TaskTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      leftChildren: (
        <TableFilterCard
          haveDynamicOption={{
            fieldName: FieldTypeEnum.TASK_PRIORITY,
            placeholder: "Select Priority",
            queryName: QueryStringEnum.TYPE,
          }}
          haveDynamicOption2={{
            fieldName: FieldTypeEnum.TASK_STATUS,
            placeholder: "Select Status",
            queryName: QueryStringEnum.STATUS,
          }}
          haveProperty
          haveUnit
        />
      ),
      rightChildren: (
        <AccessVerifier
          accessKeys={[SpaceAccessListEnum.TASK_ADD_TASK]}
          isForPage={false}
        >
          <MyButton
            onClick={() => {
              navigate(PageLinks.task.new);
            }}
            iconType={IconTypeEnum.ADD}
            name={"Add Task"}
          />
        </AccessVerifier>
      ),
    },
  };
  return (
    <PageTemplate
      title={"Task"}
      breadCrumb={[
        {
          name: "Task",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        exportXlsx={{
          generateHandler: () => generateTaskReportHandler(pageQuery),
        }}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default TaskPage;
