import React, { useContext } from "react";
import { FormWrapper, LeftRightHeader } from "assets";
import { EmptyMessageComponent, MyButton, MyCheckButton } from "components";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { IconTypeEnum, SpaceAccessListEnum } from "interfaces";
import { PropertyDataContext } from "context";
import { useAuthorizationHook } from "useHooks";

function AmenitiesDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { details } = useContext(PropertyDataContext);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_EDIT_PROPERTY,
    SpaceAccessListEnum.RENTAL_ADD_PROPERTY,
  ]);
  return (
    <>
      <FormWrapper
        header={{
          title: { name: "List of Amenities" },
          rightChildren: canEdit && (
            <div>
              <MyButton
                onClick={() =>
                  navigate(location?.pathname + "/modal/amenities/edit")
                }
                isOutline
                iconType={IconTypeEnum.ADD}
                name={"Add/Edit Amenities"}
              />
            </div>
          ),
        }}
      >
        {details?.amenities?.length == 0 && (
          <div className={"mt-5"}>
            <EmptyMessageComponent message={"No Amenities added"} />
          </div>
        )}

        <div
          className={
            "grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-start justify-start gap-5"
          }
        >
          {details?.amenities?.map((e, key) => {
            return (
              <div className={"flex items-start"} key={key}>
                <MyCheckButton
                  value={e?.value}
                  label={e?.label}
                  isChecked={true}
                />
              </div>
            );
          })}
        </div>
      </FormWrapper>

      <Outlet />
    </>
  );
}

export default AmenitiesDetails;
