import React from "react";

function TableIdValueText({ id, value }: { id?: string; value?: string }) {
  return (
    <div className={"flex flex-col items-start text-[14px]"}>
      <span className={"text-tBlue font-semibold "}>{id}</span>
      <span className={"text-gray-500 "}>{value}</span>
    </div>
  );
}

export default TableIdValueText;
