import React from "react";
import { ToggleContentWrapper } from "../components";
import {
  IncomeExpenseProfitDashboardStatsInterface,
  DashboardApiTypeEnum,
} from "interfaces";
import {
  getCurrency,
  separateWithComma,
  getBalanceSheetTitleHandler,
} from "helpers";
function ProfitLossStatsContent({ isToggle }) {
  return (
    <ToggleContentWrapper
      toggle={isToggle}
      title={"Profit and Loss"}
      getDataType={DashboardApiTypeEnum.profitLoss}
      haveDateFilter={true}
      havePropertyFilter={false}
    >
      {(
        data: IncomeExpenseProfitDashboardStatsInterface,
        isLoading,
        error,
        dateFilter
      ) => {
        const { accountDateTitle } = getBalanceSheetTitleHandler({
          endDate: dateFilter?.endDate,
          startDate: dateFilter?.startDate,
          rangeType: dateFilter?.type,
        });
        const total = data?.income + data?.expense;
        const incomeRatio = ((data?.income / total) * 100)?.toFixed(2);
        const expRatio = ((data?.expense / total) * 100)?.toFixed(2);
        return (
          <div className={"flex flex-col gap-4 py-4"}>
            <div className={"flex flex-col gap-1"}>
              <span className={"text-[22px] text-tGreen font-bold"}>
                {getCurrency()}{separateWithComma(data?.profit) || 0}
              </span>
              <span className={"text-[14px]"}>
                Net income {accountDateTitle}
              </span>
            </div>
            <div className={"flex flex-col gap-1"}>
              <b className={"text-[18px]"}>
                {getCurrency()}{separateWithComma(data?.income) || 0}
              </b>
              <span className={"text-[14px]"}>Income</span>
              <div
                className={
                  "w-full bg-tGray h-[30px] rounded-xl relative overflow-hidden"
                }
              >
                <div
                  style={{
                    width: incomeRatio + "%",
                  }}
                  className={`absolute top-0 left-0 bottom-0 h-[30px]  bg-[#41C810]`}
                ></div>
              </div>
            </div>
            <div className={"flex flex-col gap-1 "}>
              <b className={"text-[18px]"}>
                {getCurrency()}{separateWithComma(data?.expense) || 0}
              </b>
              <span className={"text-[14px]"}>Expenses</span>
              <div
                className={
                  "w-full bg-tGray h-[30px] rounded-xl relative overflow-hidden"
                }
              >
                <div
                  style={{
                    width: expRatio + "%",
                  }}
                  className={`absolute top-0 left-0 h-[30px] bg-[#ff7700]`}
                ></div>
              </div>
            </div>
          </div>
        );
      }}
    </ToggleContentWrapper>
  );
}

export default ProfitLossStatsContent;
