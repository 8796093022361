import React, { useState } from "react";
import { getIconHelper } from "helpers";
import { IconTypeEnum, QueryStringEnum } from "interfaces";
import { usePageQueryHooks } from "useHooks";

interface PropsInterface {
  title: {
    name: string;
    count: number;
    color: string;
  };
  openCount: number;
  closeCount: number;
  cancelledCount: number;
  overdueCount: number;
  pageLink?: string;
  canAccessRead: boolean;
  query?: {
    closeID?: string;
    openID?: string;
    overDueID?: string;
    cancelledID?: string;
    statusID?: string;
    title: string;
  };
}
function TaskMaintenanceCardDetails({
  cancelledCount,
  openCount,
  overdueCount,
  closeCount,
  title,
  pageLink,
  query,
  canAccessRead,
}: PropsInterface) {
  const [isToggle, setToggle] = useState(false);
  const AddIcon = getIconHelper(IconTypeEnum.ADD);
  const MinusIcon = getIconHelper(IconTypeEnum.MINUS);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  return (
    <div className={"border-2 rounded-lg p-2"}>
      <div className={"flex justify-between "}>
        <div className={"flex items-center gap-2"}>
          <div
            className={`${title?.color} w-[10px] h-[10px] rounded-full`}
          ></div>
          <span>{title?.name}</span>
          <div
            onClick={() =>
              canAccessRead &&
              query?.statusID &&
              navigateWithReturnUrlHandler(
                pageLink,
                `${QueryStringEnum.TYPE}=${query?.statusID}`
              )
            }
            className={
              "bg-gray-200 cursor-pointer rounded-full px-2 text-[12px] border-2 border-gray-200"
            }
          >
            {title?.count || 0} {query?.title}
          </div>
        </div>
        <div
          onClick={() => setToggle((e) => !e)}
          className={"bg-gray-200 rounded-full p-2 cursor-pointer"}
        >
          {!isToggle ? (
            <AddIcon className={"w-[16px] h-[16px]"} />
          ) : (
            <MinusIcon className={"w-[16px] h-[16px]"} />
          )}
        </div>
      </div>
      {isToggle && (
        <div className={"flex flex-col gap-2 px-2 border-t-2 pt-2 mt-2"}>
          <div className={"flex items-center gap-2"}>
            <div
              className={`bg-green-500 w-[10px] h-[10px] rounded-full`}
            ></div>
            <span>Open</span>
            <div
              onClick={() => {
                canAccessRead &&
                  query?.statusID &&
                  query?.openID &&
                  navigateWithReturnUrlHandler(
                    pageLink,
                    `${QueryStringEnum.TYPE}=${query?.statusID}&${QueryStringEnum.STATUS}=${query?.openID}`
                  );
              }}
              className={
                "bg-gray-200 rounded-full px-2 text-[12px] border-2 border-gray-200 cursor-pointer"
              }
            >
              {openCount || 0} {query?.title}
            </div>
          </div>
          <div className={"flex items-center gap-2"}>
            <div className={`bg-gray-500 w-[10px] h-[10px] rounded-full`}></div>
            <span>Close</span>
            <div
              onClick={() => {
                canAccessRead &&
                  query?.statusID &&
                  query?.closeID &&
                  navigateWithReturnUrlHandler(
                    pageLink,
                    `${QueryStringEnum.STATUS}=${query?.statusID}&${QueryStringEnum.TYPE}=${query?.closeID}`
                  );
              }}
              className={
                "bg-gray-200 rounded-full px-2 text-[12px] border-2 border-gray-200 cursor-pointer"
              }
            >
              {closeCount || 0} {query?.title}
            </div>
          </div>{" "}
          <div className={"flex items-center gap-2"}>
            <div
              className={`bg-orange-500 w-[10px] h-[10px] rounded-full`}
            ></div>
            <span>Cancelled</span>
            <div
              onClick={() => {
                canAccessRead &&
                  query?.statusID &&
                  query?.cancelledID &&
                  navigateWithReturnUrlHandler(
                    pageLink,
                    `${QueryStringEnum.STATUS}=${query?.statusID}&${QueryStringEnum.TYPE}=${query?.cancelledID}`
                  );
              }}
              className={
                "bg-gray-200 rounded-full px-2 text-[12px] border-2 border-gray-200 cursor-pointer"
              }
            >
              {cancelledCount || 0} {query?.title}
            </div>
          </div>
          <div className={"flex items-center gap-2"}>
            <div className={`bg-red-500 w-[10px] h-[10px] rounded-full`}></div>
            <span>Overdue</span>
            <div
              onClick={() => {
                canAccessRead &&
                  query?.statusID &&
                  query?.overDueID &&
                  navigateWithReturnUrlHandler(
                    pageLink,
                    `${QueryStringEnum.STATUS}=${query?.statusID}&${QueryStringEnum.TYPE}=${query?.overDueID}`
                  );
              }}
              className={
                "bg-gray-200 rounded-full px-2 text-[12px] border-2 border-gray-200 cursor-pointer"
              }
            >
              {overdueCount || 0} {query?.title}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TaskMaintenanceCardDetails;
