import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  DynamicListInterface,
  FieldTypeEnum,
  IconTypeEnum,
  ParamsStringEnum,
  SpaceAccessListEnum,
} from "interfaces";
import { AccessVerifier, PageTemplate } from "templates";

import { MyButton, MyIconButton, TableCardComponent } from "components";
import { PageLinks } from "routes";
import { DynamicListContext } from "context";
import { useAuthorizationHook, usePageQueryHooks } from "useHooks";
import { capitalizeAfterSpace, DynamicListConstantList } from "helpers";

function NameOptionsPage() {
  const params = useParams<ParamsStringEnum>();
  const { getAllDataHandlers, isLoading, allData, deleteHandler } =
    useContext(DynamicListContext);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canEdit = checkCanAccessHandler([
    SpaceAccessListEnum.CONFIGURATION_EDIT_ADMINISTRATIVE_SETUP,
  ]);
  const canDel = checkCanAccessHandler([
    SpaceAccessListEnum.CONFIGURATION_DEL_ADMINISTRATIVE_SETUP,
  ]);
  const title = capitalizeAfterSpace(params.type);
  const canAddItem = DynamicListConstantList[params.type];
  useEffect(() => {
    getAllDataHandlers(params?.type);
  }, [params.type]);
  return (
    <PageTemplate
      title={"Administrative Setup"}
      breadCrumb={[]}
      backNavigation={{
        title: title || "Options",
        path: PageLinks.dynamicLists.list(),
      }}
    >
      <TableCardComponent
        loading={{
          isLoading: isLoading,
        }}
        children={{
          rightChildren: (
            <AccessVerifier
              accessKeys={[
                SpaceAccessListEnum.CONFIGURATION_ADD_ADMINISTRATIVE_SETUP,
              ]}
              isForPage={false}
            >
              {canAddItem && (
                <MyButton
                  iconType={IconTypeEnum.ADD}
                  onClick={() => {
                    navigateWithReturnUrlHandler(
                      PageLinks.dynamicLists.new(params?.type)
                    );
                  }}
                  name={`Add ${title}`}
                />
              )}
            </AccessVerifier>
          ),
        }}
        dataSource={allData}
        column={[
          {
            title: "SN.",
            render(renderData: DynamicListInterface, key: number) {
              return <div>{key + 1}.</div>;
            },
          },
          {
            title: "Label",
            render(renderData: DynamicListInterface) {
              return (
                <div className={" cursor-pointer text-left"}>
                  {renderData?.label}
                </div>
              );
            },
          },
          {
            title: "Value",
            render(renderData: DynamicListInterface) {
              return <div>{renderData?.value}</div>;
            },
          },
          {
            title: "Description",
            render(renderData: DynamicListInterface) {
              return <div>{renderData?.description || "-"}</div>;
            },
          },
          (canEdit || canDel) && {
            title: "Action",
            render(renderData: DynamicListInterface) {
              return (
                <div className={"flex items-center gap-4 "}>
                  {renderData?.isValueEditable && (
                    <>
                      {canEdit && (
                        <MyIconButton
                          onClick={() => {
                            navigateWithReturnUrlHandler(
                              PageLinks.dynamicLists.edit(renderData?._id)
                            );
                          }}
                          IconType={IconTypeEnum.EDIT}
                          containerStyle={
                            "text-primary border-[1px] border-primary p-2"
                          }
                        />
                      )}
                      {canDel && (
                        <MyIconButton
                          onClick={() => {
                            deleteHandler(renderData?._id, {
                              onSuccess(payload?: any): any {
                                getAllDataHandlers(params?.type);
                              },
                            });
                          }}
                          containerStyle={
                            "text-tRed border-[1px] border-tRed p-2"
                          }
                          IconType={IconTypeEnum.DELETE}
                        />
                      )}
                    </>
                  )}
                </div>
              );
            },
          },
        ]}
      />
    </PageTemplate>
  );
}

export default NameOptionsPage;
