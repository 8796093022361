import { createContext, useContext, useState } from "react";

import { Api } from "helpers";
import {
  BankSourceEnum,
  FieldTypeEnum,
  InvoiceStatusEnum,
  SpaceAccessListEnum,
  UserRoleEnum,
} from "interfaces";
import { OptionsContextInterface, OptionsDataEnum } from "./interfaces";
import { ApiUrl } from "services";
import { AppContext } from "context";
import { useAuthorizationHook } from "../../useHooks";

export const OptionsContext = createContext<OptionsContextInterface>({
  fieldTypeOptions: {
    [FieldTypeEnum.OWNER_TYPE]: [],
    [FieldTypeEnum.DOCUMENT_TYPE]: [],
    [FieldTypeEnum.PROPERTY_TYPE]: [],
    [FieldTypeEnum.UNIT_SIZE]: [],
  },

  fiscalYearOptions: [],
  ledgerNameOptions: [],
  privilegeOptions: [],
  propertyOptions: [],
  unitOptions: [],
  ownerOptions: [],
  userOptions: [],
  documentOptions: [],
  ledgerGroupList: [],
  invoiceOptions: [],
  bankOptions: [],
  loanOptions: [],
  getLoanOptionsHandler(property: string, unit: string) {},
  getInvoiceHandler(status) {},
  getFiscalYearOptionsHandler() {},
  getBankOptionsHandler(source?: BankSourceEnum) {},
  getLedgerGroupListHandler() {},
  getDocumentOptionsHandler() {},
  getLedgerOptionsHandler() {},
  getUserOptionsHandler(role) {},
  getOptionsHandler: (optionType: FieldTypeEnum) => {},
  getUnitOptionsHandler(propertyID) {},
  getPropertyOptionsHandler() {},
  getOwnerOptionsHandler() {},
  getPrivilegeOptionsHandler() {},
});

const AppProvider = ({ children }: any) => {
  const { handlers } = useContext(AppContext);
  const [fieldTypeOptions, setDynamicFieldOptions] = useState({});
  const [options, setOptions] = useState({
    propertyOptions: [],
    unitOptions: [],
    ownerOptions: [],
    userOptions: [],
    privilegeOptions: [],
    ledgerNameOptions: [],
    documentOptions: [],
    fiscalYearOptions: [],
    ledgerGroupList: [],
    invoiceOptions: [],
    bankOptions: [],
    loanOptions: [],
  });
  const { checkCanAccessHandler } = useAuthorizationHook();
  const havePropertyReadAccess = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_READ_PROPERTY,
  ]);
  const haveUnitReadAccess = checkCanAccessHandler([
    SpaceAccessListEnum.RENTAL_READ_UNIT,
  ]);
  const haveUserReadAccess = checkCanAccessHandler([
    SpaceAccessListEnum.AUTH_READ_USER,
  ]);
  const haveLedgerNameReadAccess = checkCanAccessHandler([
    SpaceAccessListEnum.ACCOUNT_READ_LEDGER_NAME,
  ]);
  const haveInvoiceReadAccess = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_READ_INVOICE,
  ]);
  const haveBankReadAccess = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_READ_BANK,
  ]);
  const haveRoleReadAccess = checkCanAccessHandler([
    SpaceAccessListEnum.AUTH_READ_ROLE,
  ]);
  const haveDocumentReadAccess = checkCanAccessHandler([
    SpaceAccessListEnum.DOCUMENT_READ_DOCUMENT,
  ]);
  const haveLoanMortgageReadAccess = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_READ_LOAN_AND_MORTGAGE,
  ]);
  const canAccessSuppliers = checkCanAccessHandler([
    SpaceAccessListEnum.AUTH_ACCESS_SUPPLIER,
  ]);
  const setOptionsDataHandler = (type: OptionsDataEnum, value) => {
    setOptions((e) => {
      return {
        ...e,
        [type]: value,
      };
    });
  };

  const { getApi } = Api();

  const getOptionsHandler = async (optionType: FieldTypeEnum) => {
    try {
      const res = await getApi(
        ApiUrl.dynamicList.get_listFromFieldType + optionType
      );
      //  getting form api
      setDynamicFieldOptions((e) => {
        return {
          ...e,
          [optionType]: res?.data?.docs,
        };
      });
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };
  const getInvoiceHandler = async (status: InvoiceStatusEnum) => {
    try {
      if (!haveInvoiceReadAccess) {
        return;
      }

      const res = await getApi(ApiUrl.invoice.get_invoice, {
        invoiceStatus: status,
        invoiceActive: InvoiceStatusEnum.ACTIVE,
      });
      setOptionsDataHandler(OptionsDataEnum.invoiceOptions, res?.data?.docs);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };
  const getLoanOptionsHandler = async (property, unit) => {
    try {
      if (!haveLoanMortgageReadAccess) {
        return;
      }

      const res = await getApi(ApiUrl.loansAndMortgages.get_loans, {
        property,
        unit,
      });
      setOptionsDataHandler(OptionsDataEnum.loanOptions, res?.data?.docs);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };
  const getBankOptionsHandler = async (source?: BankSourceEnum) => {
    try {
      if (!haveBankReadAccess) {
        return;
      }

      const res = await getApi(ApiUrl.bank.get_bank, {
        source,
      });
      setOptionsDataHandler(OptionsDataEnum.bankOptions, res?.data?.docs);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };
  const getFiscalYearOptionsHandler = async () => {
    try {
      const res = await getApi(ApiUrl.utils.get_fiscalYear);
      setOptionsDataHandler(OptionsDataEnum.fiscalYearOptions, res?.data);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };
  const getLedgerOptionsHandler = async (query) => {
    try {
      if (!haveLedgerNameReadAccess) {
        return;
      }
      const res = await getApi(ApiUrl.ledgerName.get_ledgerName, {
        ...query,
      });
      setOptionsDataHandler(OptionsDataEnum.ledgerNameOptions, res?.data?.docs);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };
  const getLedgerGroupListHandler = async () => {
    try {
      const res = await getApi(ApiUrl.subLedgerName.get_all);
      setOptionsDataHandler(OptionsDataEnum.ledgerGroupList, res?.data);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };
  const getDocumentOptionsHandler = async () => {
    try {
      if (!haveDocumentReadAccess) {
        return;
      }
      const res = await getApi(ApiUrl.documents?.get_documents);
      setOptionsDataHandler(OptionsDataEnum.documentOptions, res?.data?.docs);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };

  const getPropertyOptionsHandler = async () => {
    try {
      if (!havePropertyReadAccess) {
        return;
      }
      const res = await getApi(ApiUrl.property.get_property);
      setOptionsDataHandler(OptionsDataEnum.propertyOptions, res?.data?.docs);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };
  const getUnitOptionsHandler = async (property) => {
    try {
      if (!haveUnitReadAccess) {
        return;
      }
      const res = await getApi(ApiUrl.unit.get_unit, {
        property: property,
      });
      setOptionsDataHandler(OptionsDataEnum.unitOptions, res?.data?.docs);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };
  const getOwnerOptionsHandler = async () => {
    try {
      if (!haveUserReadAccess) {
        return;
      }
      const res = await getApi(ApiUrl.users?.get_users, {
        userType: UserRoleEnum.OWNER,
      });
      setOptionsDataHandler(OptionsDataEnum.ownerOptions, res?.data?.docs);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };
  const getPrivilegeOptionsHandler = async () => {
    try {
      if (!haveRoleReadAccess) {
        return;
      }
      const res = await getApi(ApiUrl.privilege.get_Privilege);
      let data = res?.data?.docs;
      if (!canAccessSuppliers) {
        data = data?.filter(
          (e) => e?.role !== UserRoleEnum.SUPPLIER && e?.isSeed
        );
      }
      setOptionsDataHandler(OptionsDataEnum.privilegeOptions, data);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };

  const getUserOptionsHandler = async (role?: UserRoleEnum) => {
    try {
      if (!haveUserReadAccess) {
        return;
      }
      const res = await getApi(ApiUrl.users.get_users, {
        userType: role,
      });
      setOptionsDataHandler(OptionsDataEnum.userOptions, res?.data?.docs);
    } catch (error) {
      handlers?.setError(true, error?.message);
    }
  };

  let contextValue = {
    fieldTypeOptions,
    ...options,
    getOptionsHandler,
    getPropertyOptionsHandler,
    getUnitOptionsHandler,
    getOwnerOptionsHandler,
    getUserOptionsHandler,
    getPrivilegeOptionsHandler,
    getLedgerOptionsHandler,
    getDocumentOptionsHandler,
    getLedgerGroupListHandler,
    getFiscalYearOptionsHandler,
    getInvoiceHandler,
    getBankOptionsHandler,
    getLoanOptionsHandler,
  };

  return (
    <OptionsContext.Provider value={contextValue}>
      {children}
    </OptionsContext.Provider>
  );
};

export default AppProvider;
