import React, { createContext, useContext } from "react";

import { AppContext } from "context";
import { Api } from "helpers";
import { ApiUrl } from "services";
import { DataContextInterface, JournalInterface } from "interfaces";
import {
  useConfirmHook,
  useDataContextHooks,
  usePaginationQueryHooks,
} from "useHooks";

import { HandlerCallbackInterface } from "interfaces";
import { FormikHelpers } from "formik/dist/types";

export const JournalContext = createContext<
  DataContextInterface<JournalInterface>
>({
  isLoading: false,
  allData: [],
  isDetailsLoading: false,
  totalArchiveDocs: 0,
  details: null,
  totalDocs: 0,
  getAllDataHandlers(query?: any) {},
  getDetailsHandler(itemId: string, isFromEdit?: boolean) {},

  archiveHandler(
    itemId: string,
    status: boolean,
    callback?: HandlerCallbackInterface
  ) {},
  deleteHandler(itemId: string, callback?: HandlerCallbackInterface) {},

  editDetailsHandler(
    values,
    actions: FormikHelpers<unknown>,
    callback?: HandlerCallbackInterface
  ) {},
});

function DataContextProvider({ children }) {
  const { confirm } = useConfirmHook();
  const { isArchive, paginationQuery } = usePaginationQueryHooks();
  const {
    isArchiveLoaded,
    totalArchiveDocs,
    setTotalArchiveDocs,
    isDetailsLoading,
    setDetailsLoading,
    details,
    setLoading,
    isLoading,
    setTotalDocs,
    totalDocs,
    setDetails,
    allData,
    setAllData,
  } = useDataContextHooks();

  const { handlers } = useContext(AppContext);
  const { getApi, postApi, putApi, deleteApi } = Api();

  //  handler
  const journalHandlers = {
    getAllDataHandlers: async (query) => {
      try {
        setLoading(true);
        let res: any;
        res = await getApi(ApiUrl.journal.get_journal, {
          ...query,
          ...paginationQuery,
        });
        if (isArchive) {
          setTotalArchiveDocs(res?.data?.totalDocs);
        } else {
          setTotalDocs(res?.data?.totalDocs);
        }
        setAllData(res?.data?.docs);
      } catch (err) {
        handlers?.setError(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getDetailsHandler: async (itemID: string, isFromEdit) => {
      try {
        if (!isFromEdit) {
          setDetailsLoading(true);
          setDetails(null);
        }
        const res: any = await getApi(
          ApiUrl.journal.get_journalDetails + itemID
        );
        setDetails(res?.data);
      } catch (err) {
        handlers?.setError(true, err?.message);
        handlers.setPageErrorHandler(true, err?.message);
      } finally {
        setDetailsLoading(false);
      }
    },
    editDetailsHandler: async (
      values,
      props,
      callback: HandlerCallbackInterface
    ) => {
      try {
        handlers?.setLoading(true);
        const shouldEdit = values?._id;
        let res;
        if (shouldEdit) {
          res = await putApi(ApiUrl.journal.put_editJournal, values);
        } else {
          res = await postApi(ApiUrl.journal.post_addJournal, values);
        }
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
    deleteHandler: async (itemsId, callback: HandlerCallbackInterface) => {
      try {
        const isConfirm = await confirm(
          "Are you sure you want to delete this item?",
          "Delete",
          "Cancel"
        );
        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res: any = await deleteApi(
          ApiUrl.journal.delete_journal + itemsId,
          {}
        );
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
    archiveHandler: async (
      itemsId,
      status: boolean,
      callback: HandlerCallbackInterface
    ) => {
      try {
        const isConfirm = await confirm(
          `Are you sure you want to ${
            !status ? "archive" : "unarchive"
          } the selected items?`,
          !status ? "Archive" : "Unarchive",
          "Cancel"
        );
        if (!isConfirm) return;
        handlers?.setLoading(true);
        const res: any = await putApi(
          ApiUrl.journal.put_archiveUnArchiveJournal +
            `${itemsId}/${status ? "off" : "on"}`,
          {}
        );
        if (isArchiveLoaded) {
          // isAlready archived
          if (status) {
            setTotalArchiveDocs(totalArchiveDocs - 1);
            setTotalDocs(totalDocs + 1);
          } else {
            setTotalArchiveDocs(totalArchiveDocs + 1);
          }
        }
        if (typeof callback.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccess(true, res?.message);
      } catch (err) {
        handlers?.setError(true, err?.message);
        if (typeof callback.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoading(false);
      }
    },
  };

  const contextValue = {
    totalDocs,
    totalArchiveDocs,
    allData,
    details,
    isDetailsLoading,
    isLoading,
    ...journalHandlers,
  };
  return (
    <JournalContext.Provider value={contextValue}>
      {children}
    </JournalContext.Provider>
  );
}

export default DataContextProvider;
