import React, { useContext, useEffect, useMemo, useState } from "react";

import { useDateHook } from "useHooks";
import Popup from "reactjs-popup";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import { v4 as uuid } from "uuid";
import {
  BankStatementInterface,
  IconTypeEnum,
  InputSizeEnum,
  ReconciliationInvoiceEnum,
  UnitInterface,
} from "interfaces";
import {
  MyButton,
  MyCheckButton,
  MySelectField,
  MyTextField,
} from "components";
import {
  Api,
  decodePropertyOptionsHandler,
  decodeUnitOptionsHandler,
  getCurrency,
  getIconHelper,
} from "helpers";
import { ApiUrl } from "services";
import { AppContext, OptionsContext } from "context";
import {
  ReconcileMatchFormikInterface,
  ReconciliationInvoiceInterface,
} from "../interfaces";
import { ReconcileMatchFormik } from "../helpers";
import { SplitModal } from "../modal";
import PaginationComponent from "components/cards/PaginationComponent";
import "./styles.css";
import { usePaginationQueryHooks } from "../../../useHooks";

interface propsInterface {
  statement: BankStatementInterface;
  setFindMatchInvoice(state: boolean);
}
function FindMatchInvoiceComponent({
  statement,
  setFindMatchInvoice,
}: propsInterface) {
  const { getApi } = Api();
  const { values, setFieldValue } =
    useFormikContext<ReconcileMatchFormikInterface>();
  const {
    handlers: { setLoading, setError },
  } = useContext(AppContext);
  const { propertyOptions } = useContext(OptionsContext);
  const [invoices, setInvoices] = useState<ReconciliationInvoiceInterface[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [invoicePayload, setInvoicePayload] = useState(null);
  const { pageLimit } = usePaginationQueryHooks();
  const startIndex = (currentPage - 1) * pageLimit;

  const endIndex = startIndex + pageLimit;
  const displayedItems = invoices.slice(startIndex, endIndex);
  const [unitOptions, setUnitOptions] = useState<UnitInterface[]>([]);
  const totalInvoiceAmount = useMemo(() => {
    let totalAmount = 0;
    values?.matchInvoices?.forEach((e) => {
      totalAmount += e?.amount;
    });
    return totalAmount;
  }, [values?.matchInvoices]);
  const { getDateHandler } = useDateHook();
  const canReconcile = totalInvoiceAmount === statement?.balance;
  useEffect(() => {}, [invoicePayload, currentPage]);
  const onSubmitHandler = async (payload) => {
    try {
      setLoading(true);
      setInvoicePayload(payload);
      const res = await getApi(
        ApiUrl.bankReconciliation.get_invoiceReconcileList,
        {
          ...invoicePayload,
          transactionType: statement.transactionType,
        }
      );
      setInvoices(
        res?.data?.docs?.map((e) => ({
          ...e,
          uniqueID: uuid(),
          isDuplicate: false,
        }))
      );
    } catch (err) {
      setError(true, err?.message);
    } finally {
      setLoading(false);
    }
  };

  const getUnitOptionsHandler = async (pID) => {
    try {
      const res = await getApi(ApiUrl.unit.get_unit, {
        propertyID: pID,
      });
      setUnitOptions(res?.data?.docs);
    } catch (error) {
      setError(true, error?.message);
    }
  };
  return (
    <div className={"flex flex-col gap-2 text-[12px] border p-2 flex-1 w-full"}>
      <b>Find Match Invoice</b>
      <Formik
        onSubmit={onSubmitHandler}
        initialValues={{
          property: "",
          unit: "",
          searchKeyword: "",
        }}
      >
        {({ values, submitForm, setFieldValue }) => {
          return (
            <Form className={"flex flex-col gap-2"}>
              <div className={"grid grid-cols-2 gap-2"}>
                <MySelectField
                  inputSize={InputSizeEnum.SM}
                  option={{
                    defaultLabel: "select property",
                    selectOptions:
                      decodePropertyOptionsHandler(propertyOptions),
                    onChangeCallback(currentValue?: any) {
                      setFieldValue("unit", "");
                    },
                  }}
                  label={"Property"}
                  name={"property"}
                  isClearable
                />
                <MySelectField
                  inputSize={InputSizeEnum.SM}
                  option={{
                    defaultLabel: "select unit",
                    selectOptions: decodeUnitOptionsHandler(unitOptions),
                  }}
                  isClearable
                  fetchOptionsHandler={() =>
                    getUnitOptionsHandler(values?.property)
                  }
                  labelClassName={"text-[12px]"}
                  label={"Unit"}
                  name={"unit"}
                />
                <MyTextField
                  placeholder={`eg. inv-1`}
                  name={"searchKeyword"}
                  label={"Name of Reference"}
                  inputSize={"sm"}
                />
                <MyTextField
                  placeholder={`${getCurrency()}00.00`}
                  name={"amount"}
                  label={"Amount"}
                  inputSize={"sm"}
                  min={0}
                  type={"number"}
                />
              </div>
              <div className={"flex items-start justify-start gap-2"}>
                <div>
                  <MyButton size={"sm"} name={"Submit"} onClick={submitForm} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      <FieldArray name={"matchInvoices"}>
        {({ push, remove, replace }) => {
          const toggleInvoiceItemsHandler = (
            item: ReconciliationInvoiceInterface
          ) => {
            const valueIndex = values?.["matchInvoices"]?.findIndex(
              (e) => e?.invoice === item?._id && e?.uniqueID === item?.uniqueID
            );

            if (valueIndex > -1) {
              remove(valueIndex);
            } else {
              push(
                ReconcileMatchFormik.matchInvoiceValues({
                  isDuplicate: item?.isDuplicate,
                  invoice: item?._id,
                  amount: item?.due,
                  uniqueID: item?.uniqueID,
                })
              );
            }
          };

          const onSplitHandler = (
            splittingAmount,
            invoice: ReconciliationInvoiceInterface,
            parentPosKey,
            callbackFn
          ) => {
            /*
             *  steps
             * 1. Generate the uniqueId for new duplicate invoice
             * 2. Create the payload for new invoice
             * 3. add this duplicate invoice to formik state & invoices state (index = parentPosKey +1)
             * 4. calc new parent invoice total amount
             * 5. update the parent invoice,[due amount]
             * 6. if parent added to formik state -> update its amount
             * */
            let tempInvoices = [...invoices];

            // step 1:
            const invoiceUniqueID = uuid();
            // step 2:
            const newInvoicePayload: ReconciliationInvoiceInterface = {
              ...invoice,
              uniqueID: invoiceUniqueID,
              isDuplicate: true,
              due: splittingAmount,
            };
            // step 3:
            push(
              ReconcileMatchFormik.matchInvoiceValues({
                isDuplicate: newInvoicePayload?.isDuplicate,
                invoice: newInvoicePayload?._id,
                amount: newInvoicePayload?.due,
                uniqueID: newInvoicePayload?.uniqueID,
              })
            );
            // adding to invoice (index = parentIndex+1);
            tempInvoices.splice(parentPosKey + 1, 0, newInvoicePayload);

            // step 4:
            const parentDueAmount =
              tempInvoices[parentPosKey].due - parseFloat(splittingAmount);
            // step 5:
            tempInvoices[parentPosKey].due = parentDueAmount;
            setInvoices(tempInvoices);

            // step 6:
            let isParentSelectedInFormik = values?.matchInvoices?.findIndex(
              (e) => e?.invoice === invoice?._id && !e?.isDuplicate
            );
            if (isParentSelectedInFormik > -1) {
              replace(
                isParentSelectedInFormik,
                ReconcileMatchFormik.matchInvoiceValues({
                  ...values?.matchInvoices[isParentSelectedInFormik],
                  amount: parentDueAmount,
                })
              );
            }
            callbackFn();
          };

          const unSplitHandler = (
            invoice: ReconciliationInvoiceInterface,
            callbackFn
          ) => {
            /*
              1. calc. the new amount of parent invoice
              2. update the parent amount in invoice
              3. if parent added in formik state-> update its amount value
              4. remove the duplicate invoice from invoices.
              5. if duplicate value is added in formik state-> remove it.
             */
            // step 1:
            let tempInvoices: ReconciliationInvoiceInterface[] = [...invoices];
            const parentIndex = tempInvoices?.findIndex(
              (e) => e?._id === invoice?._id && !e?.isDuplicate
            );
            let parentDetails: Partial<ReconciliationInvoiceInterface> = {};
            if (parentIndex === -1) {
              return;
            }
            parentDetails = tempInvoices[parentIndex];
            const newParentTotalAmount = invoice.due + parentDetails?.due;
            parentDetails.due = newParentTotalAmount;
            // step 2:
            tempInvoices[parentIndex].due = newParentTotalAmount;
            // step 3:
            let isParentAddedInFormikState = values?.matchInvoices?.findIndex(
              (e) => e?.invoice === invoice?._id && !e?.isDuplicate
            );
            if (isParentAddedInFormikState > -1) {
              replace(
                isParentAddedInFormikState,
                ReconcileMatchFormik.matchInvoiceValues({
                  isDuplicate: parentDetails?.isDuplicate,
                  invoice: parentDetails?._id,
                  amount: parentDetails?.due,
                  uniqueID: parentDetails?.uniqueID,
                })
              );
            }

            // step 4:
            const duplicateInvoiceIndex = tempInvoices?.findIndex(
              (e) => e?._id === invoice?._id && e?.isDuplicate
            );
            tempInvoices.splice(duplicateInvoiceIndex, 1);

            setInvoices(tempInvoices);

            // 5.
            const duplicateFormikIndex = values?.matchInvoices?.findIndex(
              (e) =>
                e?.invoice === invoice?._id && e?.uniqueID === invoice?.uniqueID
            );

            if (duplicateFormikIndex > -1) {
              remove(duplicateFormikIndex);
            }
            callbackFn();
          };

          return (
            <table className={"findMatchInvoiceTable table"}>
              <thead>
                <tr>
                  <th></th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Invoice ID#</th>
                  <th></th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {displayedItems?.map((e, key) => {
                  const isChecked = values?.["matchInvoices"]?.some(
                    (f) => f?.invoice === e?._id && f?.uniqueID === e?.uniqueID
                  );
                  return (
                    <tr key={key * Math.random()}>
                      <td>
                        <MyCheckButton
                          label={""}
                          isChecked={isChecked}
                          changeHandler={() => toggleInvoiceItemsHandler(e)}
                        />
                      </td>
                      <td>{getDateHandler({ date: e?.invoiceDate })}</td>
                      <td>{e?.client?.name?.first}</td>
                      <td>{e?.invoiceID}</td>
                      <td>
                        <SplitModal
                          key={key * Math.random()}
                          invoice={e}
                          parentKey={key}
                          splitHandler={onSplitHandler}
                          unSplitHandler={unSplitHandler}
                        />
                      </td>
                      <td>
                        {getCurrency()}
                        {e?.due}
                      </td>
                    </tr>
                  );
                })}
                {invoices?.length < 1 && (
                  <tr>
                    <td colSpan={7}>
                      <div className={"text-center text-primary"}>
                        No invoice Found
                      </div>
                    </td>
                  </tr>
                )}
                {invoices?.length > 0 && (
                  <tr>
                    <td colSpan={7}>
                      <PaginationComponent
                        styles={{ height: "35px", width: "35px" }}
                        totalDocs={invoices?.length}
                        customCurrentPage={currentPage}
                        setCustomCurrentPage={(e) => setCurrentPage(e)}
                      />
                    </td>
                  </tr>
                )}

                <tr>
                  <td colSpan={7}>
                    Sub Total Amount {getCurrency()}
                    {totalInvoiceAmount}
                  </td>
                </tr>
                <tr>
                  <td colSpan={7}>
                    <div className={"flex justify-between items-center"}>
                      <MoreOptionsButton
                        options={[
                          {
                            name: "Add Invoice",
                            handler() {
                              setFieldValue(
                                "newMatch.invoiceType",
                                ReconciliationInvoiceEnum.INVOICE
                              );
                            },
                          },
                          {
                            name: "Direct Payment",
                            handler() {
                              setFieldValue(
                                "newMatch.invoiceType",
                                ReconciliationInvoiceEnum.DIRECT
                              );
                            },
                          },
                          {
                            name: "Advance Payment",
                            handler() {
                              setFieldValue(
                                "newMatch.invoiceType",
                                ReconciliationInvoiceEnum.ADVANCE
                              );
                            },
                          },
                        ]}
                      />
                      {values?.newMatch?.invoiceType ===
                        ReconciliationInvoiceEnum.NONE && (
                        <div className={"flex items-center gap-2"}>
                          {canReconcile && (
                            <MyButton
                              size={"sm"}
                              name={"Reconcile"}
                              type={"submit"}
                            />
                          )}
                          <MyButton
                            isOutline
                            size={"sm"}
                            name={"Cancel"}
                            onClick={() => setFindMatchInvoice(false)}
                          />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          );
        }}
      </FieldArray>
    </div>
  );
}

export default FindMatchInvoiceComponent;

interface MoreOptionsButtonInterface {
  options: { name: string; handler() }[];
}
function MoreOptionsButton({ options }: MoreOptionsButtonInterface) {
  const DownIcon = getIconHelper(IconTypeEnum.DOWN);
  return (
    <Popup
      position="bottom right"
      closeOnDocumentClick
      contentStyle={{ width: "200px" }}
      trigger={
        <button
          type={"button"}
          className={`flex items-center gap-2 text-[12px] underline text-tBlue `}
        >
          <span>Add Invoice</span>
          <DownIcon />
        </button>
      }
    >
      <div className={"flex flex-col "}>
        {options?.map((e, key) => {
          if (!e?.name) return;
          return (
            <div
              className={
                "flex items-center gap-2 border-b-2 p-2 text-black text-[12px] cursor-pointer hover:bg-gray-100"
              }
              key={key}
              onClick={() => e?.handler()}
            >
              <span>{e?.name}</span>
            </div>
          );
        })}
      </div>
    </Popup>
  );
}
