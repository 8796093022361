import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { PageTemplate } from "templates";

import { BankReconciliationContext } from "context";
import { MyButton, MyMoreOptionButton, TableCardComponent } from "components";
import { PageLinks } from "routes";
import { AssetFileNameEnum, IconTypeEnum } from "interfaces";
import { usePaginationQueryHooks } from "useHooks";

import { BankStatementTableColumns } from "../components";

function BankReconciliationPage() {
  const {
    allData,
    isLoading,
    getAllDataHandlers,
    totalDocs,
    downloadSampleFileHandler,
  } = useContext(BankReconciliationContext);
  const { currentPage } = usePaginationQueryHooks();
  const navigate = useNavigate();
  const pageQuery = {};
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [currentPage]);

  const tableCardProps = {
    column: BankStatementTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      rightChildren: (
        <div className={"flex items-center gap-5"}>
          <MyButton
            onClick={() => {
              navigate(PageLinks.reconciliation.uploadStatement);
            }}
            iconType={IconTypeEnum.ADD}
            name={`Import Bank Statement`}
          />
          <MyMoreOptionButton
            width={"300px"}
            name={"Sample Files"}
            type={"button"}
            options={[
              {
                name: AssetFileNameEnum.RECONCILE_STATEMENT_IMPORT_FILE,
                handler() {
                  downloadSampleFileHandler(
                    AssetFileNameEnum.RECONCILE_STATEMENT_IMPORT_FILE
                  );
                },
              },
            ]}
          />
        </div>
      ),
    },
  };
  return (
    <PageTemplate
      scrollAll
      title={"Bank Reconciliation"}
      breadCrumb={[
        {
          name: "Bank Reconciliation",
        },
      ]}
    >
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: totalDocs,
        }}
      />
      <Outlet />
    </PageTemplate>
  );
}

export default BankReconciliationPage;
