import * as yup from "yup";
import { CustomerInterface } from "interfaces";
import { addressInitialValues, addressValidationSchema } from "../../content";

export const UserFormikForm = {
  initialValues: (values?: Partial<CustomerInterface>) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      email: values?.email || "",
      phone: values?.phone || "",
      photo: values?.photo || "",
      role: values?.role || "",
      ALL_PROPERTY_UNIT_ACCESS: values?.ALL_PROPERTY_UNIT_ACCESS || false,
      firstName: values?.name?.first || "",
      lastName: values?.name?.last || "",
      companyName: values?.companyName || "",
      vatRegistration: values?.vatRegistration || "",
      companyRegistration: values?.companyRegistration || "",
      customRole: values?.granted?._id,
      properties: values?.properties?.map((e) => e?._id) || [],
      units: values?.units?.map((e) => e?._id) || [],
      ...addressInitialValues(values?.address),
    };
  },
  validationSchema: yup
    .object()
    .shape({
      email: yup.string().email("invalid email").required("required"),
      phone: yup.string().required("required"),
      firstName: yup.string().required("required"),
      lastName: yup.string().required("required"),
      companyName: yup.string(),
      vatRegistration: yup.string(),
      companyRegistration: yup.string(),
    })
    .concat(addressValidationSchema),
};
