import React, { useContext, useEffect, useState } from "react";

import { Outlet, useLocation, useParams } from "react-router-dom";

import { MyButton, TabComponent, TableCardComponent } from "components";

import {
  IconTypeEnum,
  ParamsStringEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
  TenancyStatusEnum,
  UserRoleEnum,
} from "interfaces";
import { TenancyTableColumn } from "content";
import { FormWrapper, LeftRightHeader } from "assets";
import { TenancyDataContext } from "context";
import { useAuthorizationHook, usePageQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function TenancyDetails() {
  const params = useParams<ParamsStringEnum>();
  const property = params?.propertyId;
  const { allData, isLoading, getAllDataHandlers, totalDocs } =
    useContext(TenancyDataContext);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();

  const location = useLocation();
  const [activeTab, setActiveTab] = useState(TenancyStatusEnum.ACTIVE);
  const pageQuery = {
    property: property,
    ...(activeTab ? { status: activeTab } : {}),
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [property, activeTab]);

  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAdd = checkCanAccessHandler([
    SpaceAccessListEnum.TENANCY_ADD_TENANCY,
  ]);
  const canRead = checkCanAccessHandler(
    [SpaceAccessListEnum.TENANCY_READ_TENANCY],
    [UserRoleEnum.CUSTOM, UserRoleEnum.LANDLORD]
  );

  const Tabs = [
    {
      title: `Active`,
      pathName: TenancyStatusEnum.ACTIVE,
    },
    {
      title: `Future Tenancies`,
      pathName: TenancyStatusEnum.FUTURE,
    },
    {
      title: `Expired Tenancies`,
      pathName: TenancyStatusEnum.EXPIRED,
    },
    canRead && {
      title: `Draft`,
      pathName: TenancyStatusEnum.DRAFT,
    },
    {
      title: `All`,
      pathName: "",
    },
  ];

  const tableCardProps = {
    column: TenancyTableColumn(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 5,
    },
    dataSource: allData,
    children: {
      aboveTableChildren: (
        <TabComponent
          control={"state"}
          stateControl={{
            setActiveTabHandler(name: TenancyStatusEnum) {
              setActiveTab(name);
            },
            activeTab: activeTab,
          }}
          tabs={Tabs}
        />
      ),
    },
  };
  return (
    <div className={"flex flex-col gap-10"}>
      <FormWrapper
        header={{
          title: { name: "List of Tenancies" },
          rightChildren: canAdd && (
            <div>
              <MyButton
                iconType={IconTypeEnum.ADD}
                onClick={() => {
                  navigateWithReturnUrlHandler(
                    PageLinks.tenancy.new,
                    `${QueryStringEnum.RETURN_URL}=${location?.pathname}&${QueryStringEnum?.PROPERTY_ID}=${property}`
                  );
                }}
                name={"New Tenancy"}
              />
            </div>
          ),
        }}
      >
        <TableCardComponent
          {...tableCardProps}
          havePagination={{
            totalDocs: totalDocs,
          }}
        />
      </FormWrapper>

      <Outlet />
    </div>
  );
}

export default TenancyDetails;
