import React, { useContext } from "react";
import {
  BankInterface,
  BankSourceEnum,
  IconTypeEnum,
  TableColumnInterface,
} from "interfaces";
import { useNavigate } from "react-router-dom";

import { MyMoreOptionButton } from "components";
import { PageLinks } from "routes";
import { usePaginationQueryHooks } from "useHooks";
import { UserTableProfile } from "assets";
import { getCurrency, navigateLinkHandler, separateWithComma } from "helpers";
import { useDateHook } from "useHooks";
import { TrueLayerContext, BankReconciliationContext } from "context";

function BankStatementTableColumns(
  pageQuery
): TableColumnInterface<BankInterface>[] {
  const { getDateHandler } = useDateHook();
  const navigate = useNavigate();
  const { syncTrueLayerHandler, getAuthLinkHandler } =
    useContext(TrueLayerContext);
  const { getAllDataHandlers } = useContext(BankReconciliationContext);
  const { calcSNHandler } = usePaginationQueryHooks();

  return [
    {
      title: "SN",
      render(renderData, key) {
        return <div>{calcSNHandler(key)}.</div>;
      },
    },
    {
      title: "Bank Name",
      render(renderData) {
        return (
          <UserTableProfile
            badgeIconType={
              renderData?.source === BankSourceEnum.TrueLayer &&
              IconTypeEnum.LAYER
            }
            onClick={() =>
              navigate(PageLinks.reconciliation.statements(renderData?._id))
            }
            details={{
              isStaticPath: renderData?.logo_uri ? true : false,
              name: renderData?.label,
              subTitle: `${renderData?.name}`,
              profile: renderData?.logo_uri || renderData?.logo,
              imageAltText: "bank image",
            }}
          />
        );
      },
    },
    {
      title: "Bank Statement",
      render(renderData) {
        return (
          <div className={"flex flex-col"}>
            <span>
              {getCurrency()}
              {renderData?.trueLayer?.balance || 0}
            </span>
            {renderData?.trueLayer?.lastSynced && (
              <span className={"text-[12px] text-gray-400"}>
                last synced:
                {getDateHandler({ date: renderData?.trueLayer?.lastSynced })}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "Bank Book Balance",
      render(renderData) {
        return (
          <span>
            {getCurrency()}
            {separateWithComma(renderData?.closingBalance) || 0}
          </span>
        );
      },
    },
    {
      title: "Reconciled Items",
      render(renderData) {
        return <span>{renderData?.reconciledItemCount || 0}</span>;
      },
    },
    {
      title: "Action",
      render(renderData) {
        return (
          <div>
            <MyMoreOptionButton
              options={[
                {
                  name: "View ",
                  handler: () => {
                    navigate(
                      PageLinks.reconciliation.statements(renderData?._id)
                    );
                  },
                },
                renderData?.isBankLinked && {
                  name: "Sync",
                  handler: async () => {
                    syncTrueLayerHandler(
                      { bankID: renderData?._id },
                      {
                        onSuccess(payload?: any): any {
                          getAllDataHandlers(pageQuery);
                        },
                      }
                    );
                  },
                },
                renderData?.isBankLinked && {
                  name: "Reconnect",
                  handler: async () => {
                    getAuthLinkHandler(
                      { bankID: renderData?._id },
                      {
                        onSuccess(payload?: any): any {
                          navigateLinkHandler(payload);
                        },
                      }
                    );
                  },
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
}

export default BankStatementTableColumns;
