import React from "react";

import ExcelJS from "exceljs";
import FileSaver from "file-saver";
import { BankReconciliationInterface, TransactionTypeEnum } from "interfaces";
import { getCurrency } from "../HandlerHelpers";

interface PropsInterface {
  data?: BankReconciliationInterface[];
  dateFormatter?({ date }: { date: Date });
}
async function generateReconcileReport({
  data,
  dateFormatter,
}: PropsInterface) {
  try {
    //    create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Reconcile Report", {});
    worksheet.properties.defaultRowHeight = 20;
    worksheet.properties.defaultColWidth = 20;
    let tableHeaders = [
      {
        name: "SN.",
      },
      {
        name: "Bank Name",
      },
      {
        name: "Remarks",
      },
      {
        name: "TransactionType",
      },
      {
        name: "Balance",
      },
      {
        name: "Date",
      },
    ];
    let tableRows = data?.map((e, index) => {
      return [
        index + 1,
        e?.bank?.label,
        e?.statement?.remarks,
        e?.transactionType === TransactionTypeEnum.DR ? "Debit" : "Credit",
        `${e?.statement?.currency || getCurrency()}${e?.balance}`,
        dateFormatter({ date: e?.reconciledDate }),
      ];
    });
    // add a table to a sheet
    worksheet.addTable({
      name: "Reconcile Report",
      ref: "A1",
      headerRow: true,
      totalsRow: false,
      style: {
        showRowStripes: false,
      },
      columns: tableHeaders,
      rows: tableRows,
    });
    //    save the workbook to a file
    const bufferFile = await workbook.xlsx.writeBuffer();
    await FileSaver.saveAs(
      new Blob([bufferFile], { type: "application/octet-stream" }),
      `reconcile.xlsx`
    );
  } catch (err) {
    throw err;
  }
}

export default generateReconcileReport;
