import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MyButton, TableCardComponent } from "components";
import { AccessVerifier, PageTemplate } from "templates";
import { IconTypeEnum, SpaceAccessListEnum } from "interfaces";
import { InventoryTableColumn, TableFilterCard } from "content";
import { InventoryDataContext } from "context";

import { usePageQueryHooks, usePaginationQueryHooks } from "useHooks";
import { PageLinks } from "routes";

function InventoryPage() {
  const {
    allData,
    isLoading,
    totalArchiveDocs,
    totalDocs,
    getAllDataHandlers,
  } = useContext(InventoryDataContext);

  const navigate = useNavigate();
  const { isArchive } = usePaginationQueryHooks();
  const { unitID, propertyID } = usePageQueryHooks();

  const pageQuery = {
    property: propertyID,
    unit: unitID,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, unitID, propertyID]);

  const tableCardProps = {
    column: InventoryTableColumn(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 10,
    },
    dataSource: allData,
    children: {
      leftChildren: <TableFilterCard haveProperty haveUnit />,
      rightChildren: (
        <AccessVerifier
          accessKeys={[SpaceAccessListEnum.INVENTORY_ADD_INVENTORY]}
          isForPage={false}
        >
          <MyButton
            onClick={() => {
              navigate(PageLinks.inventory.new);
            }}
            iconType={IconTypeEnum.ADD}
            name={"Add Inventories"}
          />
        </AccessVerifier>
      ),
    },
  };
  return (
    <PageTemplate
      title={"Inventories"}
      breadCrumb={[
        {
          name: "Inventories",
        },
      ]}
      dataTypeToggle={{
        isDataType: true,
        badgeValue: [totalDocs, totalArchiveDocs],
      }}
    >
      <TableCardComponent
        {...tableCardProps}
        havePagination={{
          totalDocs: isArchive ? totalArchiveDocs : totalDocs,
        }}
      />
    </PageTemplate>
  );
}

export default InventoryPage;
