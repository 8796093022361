import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AppContext } from "context";
import { Loader } from "components";

export default function PrivateRouteComponent() {
  const { state } = useContext(AppContext);

  if (state.isAuthLoading) {
    return <Loader />;
  }

  return state.isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}
