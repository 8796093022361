import React from "react";
import { SpaceAccessListEnum, UserRoleEnum } from "../interfaces";
import { useAuthorizationHook } from "../useHooks";
import { useNavigate } from "react-router-dom";

interface PropsInterface {
  children: any;
  accessKeys: SpaceAccessListEnum[];
  isForPage: boolean;
  role?: UserRoleEnum[];
}
function AccessVerifier({
  accessKeys,
  children,
  isForPage = true,
  role = [],
}: PropsInterface) {
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canAccess = checkCanAccessHandler(accessKeys, role);
  const navigate = useNavigate();
  if (canAccess) {
    return children;
  }
  if (!isForPage) {
    return <></>;
  }
  return (
    <div className={"flex flex-col gap-8 justify-center items-center "}>
      <div className={"text-center flex flex-col gap-1"}>
        <b className={"text-[32px] text-primary font-bold"}>403</b>
        <b className={"text-[32px] text-primary"}>Access Denied</b>
      </div>

      <div className={"text-center text-[16px] text-gray-400"}>
        <p>Sorry, you don't have permission to access this page.</p>
        <p>
          You can go back to{" "}
          <b
            className={"cursor-pointer text-primary"}
            onClick={() => navigate(-1)}
          >
            Previous Page
          </b>
        </p>
      </div>
    </div>
  );
}

export default AccessVerifier;
