import React, { useContext, useEffect } from "react";

import { PageTemplate } from "templates";
import { useParams } from "react-router-dom";
import { IconTypeEnum, ParamsStringEnum, UserRoleEnum } from "interfaces";

import { FormWrapper, NameValueText } from "assets";
import { decodeRoleNameHandler } from "helpers";
import { ImageViewCard, MyButton } from "components";
import { UserContext } from "context";
import { SignatureForm } from "content";
import { Formik } from "formik";
import { usePageQueryHooks } from "../../../useHooks";
import { PageLinks } from "../../../routes";
import useDateHooks from "../../../useHooks/useDateHooks";

function UserDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const {
    details: userDetails,
    isDetailsLoading,
    getDetailsHandler,
  } = useContext(UserContext);
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { getStaffFullName } = useDateHooks();

  useEffect(() => {
    getDetailsHandler(params?.userId);
  }, [params?.userId]);
  const PageTitle = decodeRoleNameHandler(userDetails?.role);

  const GeneralDetails = {
    Address1: userDetails?.address?.address1 || "-",
    Address2: userDetails?.address?.address2 || "-",
    "Town/city": userDetails?.address?.city || "-",
    "Zip/Post Code": userDetails?.address?.postalCode || "-",
    County: userDetails?.address?.county?.name || "-",
    Country: userDetails?.address?.country?.name || "-",
  };
  return (
    <PageTemplate
      scrollAll
      title={PageTitle}
      backNavigation={{
        title: `${PageTitle} Details`,
        path: PageLinks.spaceUser.list(userDetails?.role || UserRoleEnum.NONE),
      }}
      titleRightChildren={
        <div>
          {userDetails?._id && (
            <MyButton
              iconType={IconTypeEnum.EDIT}
              isOutline
              name={"Edit Details"}
              onClick={() =>
                navigateWithReturnUrlHandler(
                  PageLinks.spaceUser.edit(userDetails?._id)
                )
              }
            />
          )}
        </div>
      }
    >
      <div className={"flex flex-col gap-10"}>
        <FormWrapper header={{ title: { name: "General" } }}>
          <div className={"flex flex-col gap-5"}>
            <div
              className={
                "grid grid-cols-3  gap-5 md:grid-cols-2 sm:grid-cols-1"
              }
            >
              <NameValueText
                alignDirection={"row"}
                isLoading={isDetailsLoading}
                name={`${PageTitle} ID`}
                value={userDetails?.userID}
              />
            </div>
            <div
              className={
                "grid grid-cols-3  gap-5 md:grid-cols-2 sm:grid-cols-1"
              }
            >
              <NameValueText
                alignDirection={"row"}
                isLoading={isDetailsLoading}
                name={"Full Name"}
                value={getStaffFullName(userDetails?.name)}
              />
              <NameValueText
                alignDirection={"row"}
                isLoading={isDetailsLoading}
                name={"Email ID"}
                value={userDetails?.email}
              />
              <NameValueText
                alignDirection={"row"}
                isLoading={isDetailsLoading}
                name={"Mobile Number"}
                value={userDetails?.phone}
              />
            </div>
            {userDetails?.photo && (
              <div className={"grid grid-cols-3"}>
                <div className={"flex flex-col gap-2  gap-5"}>
                  <NameValueText
                    name={"Profile Picture"}
                    value={<ImageViewCard fileName={userDetails?.photo} />}
                  />
                </div>
              </div>
            )}

            <div
              className={
                "grid grid-cols-3  gap-5 md:grid-cols-2 sm:grid-cols-1"
              }
            >
              <NameValueText
                alignDirection={"row"}
                isLoading={isDetailsLoading}
                name={"Company Name"}
                value={userDetails?.companyName}
              />
              <NameValueText
                alignDirection={"row"}
                isLoading={isDetailsLoading}
                name={"Company Registration Number"}
                value={userDetails?.companyRegistration}
              />
              <NameValueText
                alignDirection={"row"}
                isLoading={isDetailsLoading}
                name={"VAT Registration Number"}
                value={userDetails?.vatRegistration}
              />
            </div>
          </div>
        </FormWrapper>
        <FormWrapper header={{ title: { name: "Billing Address" } }}>
          <div
            className={"grid grid-cols-3 gap-5 md:grid-cols-2 sm:grid-cols-1"}
          >
            {Object.keys(GeneralDetails).map((e, key) => {
              return (
                <NameValueText
                  alignDirection={"row"}
                  key={key}
                  name={e}
                  value={GeneralDetails[e]}
                  isLoading={isDetailsLoading}
                />
              );
            })}
          </div>
        </FormWrapper>
        <FormWrapper header={{ title: { name: "Signature" } }}>
          <>
            <div
              className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5"}
            >
              <Formik initialValues={{ signature: "" }} onSubmit={() => {}}>
                <SignatureForm
                  populateFieldName={"signature"}
                  customerID={userDetails?._id}
                />
              </Formik>
            </div>
          </>
        </FormWrapper>

        <FormWrapper header={{ title: { name: "Roles & Permissions" } }}>
          <>
            <div className={"grid grid-cols-3 md:grid-cols-2 sm:grid-cols-1"}>
              <NameValueText
                alignDirection={"row"}
                isLoading={isDetailsLoading}
                name={"Role"}
                value={userDetails?.granted?.name}
              />
            </div>
          </>
        </FormWrapper>
      </div>
    </PageTemplate>
  );
}

export default UserDetailsPage;
