import React, { useContext, useEffect } from "react";
import { TabComponent } from "components";
import { AddressText } from "assets";

import { PageTemplate } from "templates";
import { Outlet, useParams } from "react-router-dom";
import { TenancyDataContext } from "context";
import { ParamsStringEnum, SpaceAccessListEnum } from "interfaces";
import { PageLinks } from "routes";
import { useAuthorizationHook } from "useHooks";

function TenancyDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const { details, getDetailsHandler } = useContext(TenancyDataContext);
  const id = params.id;
  useEffect(() => {
    getDetailsHandler(id);
  }, [id]);
  const { checkCanAccessHandler } = useAuthorizationHook();
  const canReadInvoice = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_READ_INVOICE,
  ]);
  let tabs = [
    {
      title: "General Information",
      pathName: "",
    },
    canReadInvoice && {
      title: "Invoices",
      pathName: "invoice",
    },
  ];
  return (
    <>
      <PageTemplate
        scrollAll
        title={"Tenancies"}
        backNavigation={{
          title: "Tenancy Details",
          path: PageLinks.tenancy.list,
        }}
      >
        <div className={"flex flex-col gap-10"}>
          <div className={"flex flex-col gap-4"}>
            <div className={"flex gap-2 items-center"}>
              <span className={"text-tBlue font-bold text-[14px]"}>
                {details?.property?.propertyID}
              </span>
              <AddressText isOneLine address={details?.property?.address} />
            </div>
            <div className={"flex items-center gap-5"}>
              <div className={"flex gap-2 items-center"}>
                <span className={"text-tBlue font-bold text-[14px]"}>
                  {details?.unit?.unitID}
                </span>
                <span>{details?.unit?.name}</span>
              </div>
            </div>
          </div>

          <div className={"flex flex-col gap-10"}>
            <TabComponent tabs={tabs} />
            <Outlet />
          </div>
        </div>
      </PageTemplate>
    </>
  );
}

export default TenancyDetailsPage;
