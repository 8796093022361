import React, { useContext, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { PageTemplate } from "templates";
import { Loader, MyTextField } from "components";
import { FormConcludeButtons } from "assets";
import { AppContext } from "context";

import { ParamsEnum, QueryEnum } from "../../interfaces";
import { systemItemFormikValues } from "../../helpers";
import { DataContext } from "../../context";

function SystemEditItemsPage() {
  const params = useParams<ParamsEnum>();
  const [query] = useSearchParams();
  const { state, handlers } = useContext(AppContext);

  const itemId = query?.get(QueryEnum?.ID);
  const parentID = query?.get(QueryEnum?.PARENT_ID);
  const [payload, setPayload] = useState({
    name: "",
    _id: "",
  });
  const navigate = useNavigate();
  const { editSystemItemsHandler, getSystemTypeDataHandler, systemData } =
    useContext(DataContext);
  const title = parentID ? "County" : "Country";
  const getDetailsHandler = async () => {
    try {
      handlers?.setLoading(true);
      await getSystemTypeDataHandler(params?.SYSTEM, parentID);
      let data = systemData?.find((e) => e?._id === query?.get(QueryEnum?.ID));
      setPayload({
        name: data?.name || "",
        _id: data?._id || "",
      });
    } catch (err) {
      handlers?.setError(true, err?.message);
    } finally {
      handlers?.setLoading(false);
    }
  };
  useEffect(() => {
    if (itemId) {
      getDetailsHandler();
    }
  }, [itemId]);
  if (state?.isLoading && itemId) {
    return <Loader />;
  }
  return (
    <PageTemplate
      title={"Administrative Setup"}
      backNavigation={{
        title: itemId ? `Edit ${title}` : `Add ${title}`,
      }}
    >
      <div className={"flex flex-col gap-5"}>
        {/*<ItemsDetailsCard />*/}
        <Formik
          onSubmit={editSystemItemsHandler}
          initialValues={systemItemFormikValues?.initialValues({
            _id: payload?._id,
            name: payload?.name,
            parent: parentID,
          })}
          validationSchema={systemItemFormikValues.validationSchema}
        >
          <Form className={"flex flex-col gap-10"}>
            <div className={"grid grid-cols-3 gap-5"}>
              <MyTextField
                name={"name"}
                label={`${title}`}
                placeholder={`eg. ${parentID ? "London" : "United Kingdom"}`}
              />
            </div>
            <FormConcludeButtons
              submitButton={{
                title: "Submit",
              }}
              cancelButton={{
                title: "Cancel",
                handler() {
                  navigate(-1);
                },
              }}
            />
          </Form>
        </Formik>
      </div>
    </PageTemplate>
  );
}

export default SystemEditItemsPage;
