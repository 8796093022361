import React, { useContext, useEffect } from "react";
import { MyButton } from "components";
import { AddressText, NameValueText } from "assets";

import { PageTemplate } from "templates";
import { useNavigate, useParams } from "react-router-dom";
import { NotesContext } from "context";
import { IconTypeEnum, ParamsStringEnum } from "interfaces";
import { PageLinks } from "routes";
import useDateHooks from "../../../../useHooks/useDateHooks";

function NotesDetailsPage() {
  const params = useParams<ParamsStringEnum>();
  const {
    details: notesDetails,
    isDetailsLoading,
    getDetailsHandler,
  } = useContext(NotesContext);
  const navigate = useNavigate();
  useEffect(() => {
    getDetailsHandler(params?.id);
  }, [params?.id]);
  const { getStaffFullName } = useDateHooks();

  return (
    <PageTemplate
      title={"Notes"}
      backNavigation={{
        title: "Note Details",
        path: PageLinks.notes.list,
      }}
      breadCrumb={[
        {
          name: "Notes",
        },
      ]}
      titleRightChildren={
        <div>
          {notesDetails?._id && (
            <MyButton
              isOutline
              iconType={IconTypeEnum.EDIT}
              name={"Edit Details"}
              onClick={() => {
                navigate(PageLinks.notes.edit(notesDetails?._id));
              }}
            />
          )}
        </div>
      }
    >
      <div className={"flex flex-col gap-10"}>
        <div className={"grid grid-cols-3 gap-5 md:grid-cols-2 sm:grid-cols-1"}>
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"Subject"}
            value={notesDetails?.name}
          />
        </div>
        <div className={"grid grid-cols-3 gap-5 md:grid-cols-2 sm:grid-cols-1"}>
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"Property"}
            value={
              <div className={"flex gap-2 items-center"}>
                ({notesDetails?.property?.propertyID})
                <AddressText
                  isOneLine
                  address={notesDetails?.property?.address}
                />
              </div>
            }
          />
          <NameValueText
            alignDirection={"col"}
            name={"Unit"}
            isLoading={isDetailsLoading}
            value={` (${notesDetails?.unit?.unitID}) ${notesDetails?.unit?.name}`}
          />
        </div>
        <NameValueText
          isLoading={isDetailsLoading}
          alignDirection={"col"}
          name={"Shared To"}
          value={
            notesDetails?.sharedTo
              ?.map((e) => getStaffFullName(e?.name))
              ?.join(" , ") || "-"
          }
        />
        <div className={"grid "}>
          <NameValueText
            isLoading={isDetailsLoading}
            alignDirection={"col"}
            name={"Comments"}
            value={notesDetails?.note}
          />
        </div>
      </div>
    </PageTemplate>
  );
}

export default NotesDetailsPage;
