import { BankInterface } from "interfaces";
import * as yup from "yup";

export const BankFormikValues = {
  initialValues: (values?: BankInterface) => {
    return {
      ...(values?._id && {
        _id: values?._id,
      }),
      bankCode: values?.bankCode,
      label: values?.label,
      name: values?.name,
      description: values?.description,
      logo: values?.logo,
      qrImage: values?.qrImage,
      documents: values?.documents || [],
      branch: values?.branch,
      ifscCode: values?.ifscCode,
      swiftCode: values?.swiftCode,
      accountNumber: values?.accountNumber,
    };
  },
  validationSchema: yup.object().shape({
    bankCode: yup.string().required("required"),
    label: yup.string().required("required"),
    name: yup.string().required("required"),
    description: yup.string(),
    logo: yup.string(),
    branch: yup.string().required("required"),
    ifscCode: yup.string().required("required"),
    swiftCode: yup.string().required("required"),
    accountNumber: yup.string().required("required"),
  }),
};
