import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  MyMoreOptionButton,
  TabComponent,
  TableCardComponent,
} from "components";
import { InvoiceTableColumns } from "content";
import { InvoiceContext, TenancyDataContext } from "context";
import { PageLinks } from "routes";
import {
  useAuthorizationHook,
  usePageQueryHooks,
  usePaginationQueryHooks,
} from "useHooks";
import {
  IconTypeEnum,
  InvoiceStatusEnum,
  ParamsStringEnum,
  QueryStringEnum,
  SpaceAccessListEnum,
  UserRoleEnum,
} from "interfaces";
import { FormWrapper } from "../../../assets";

function InvoiceTab() {
  const {
    getAllDataHandlers,
    allData,
    isLoading,
    totalDocs,
    totalArchiveDocs,
  } = useContext(InvoiceContext);
  const { details } = useContext(TenancyDataContext);
  const params = useParams<ParamsStringEnum>();
  const id = params.id;
  const { currentPage, isArchive, paginationQuery } = usePaginationQueryHooks();
  const { navigateWithReturnUrlHandler } = usePageQueryHooks();
  const { checkCanAccessHandler } = useAuthorizationHook();

  const canAddInvoice = checkCanAccessHandler([
    SpaceAccessListEnum.FINANCE_ADD_INVOICE,
  ]);
  const canReadInvoice = checkCanAccessHandler(
    [SpaceAccessListEnum.FINANCE_READ_INVOICE],
    [UserRoleEnum.LANDLORD, UserRoleEnum.CUSTOM]
  );
  const [activeTab, setActiveTab] = useState("");
  let invoiceStatus, invoiceActive;
  if (activeTab !== InvoiceStatusEnum.DRAFT) {
    invoiceStatus = activeTab;
    // invoiceActive = InvoiceActiveEnum.ACTIVE;
  }
  if (activeTab == InvoiceStatusEnum.DRAFT) {
    invoiceActive = InvoiceStatusEnum.DRAFT;
  }

  const tabOptions = [
    {
      title: `All`,
      pathName: "",
    },
    canReadInvoice && {
      title: `Draft`,
      pathName: InvoiceStatusEnum.DRAFT,
    },
    {
      title: `Sales`,
      pathName: InvoiceStatusEnum.SALES,
    },
    {
      title: `Purchase`,
      pathName: InvoiceStatusEnum.PURCHASE,
    },
    {
      title: `Settled`,
      pathName: InvoiceStatusEnum.SETTLED,
    },
  ];

  const pageQuery = {
    ...paginationQuery,
    invoiceStatus,
    invoiceActive,
    customerID: id,
  };
  useEffect(() => {
    getAllDataHandlers(pageQuery);
  }, [isArchive, activeTab, currentPage]);

  const tableCardProps = {
    column: InvoiceTableColumns(pageQuery),
    loading: {
      isLoading: isLoading,
      loadingRow: 5,
    },
    dataSource: allData,
    children: {
      aboveTableChildren: (
        <TabComponent
          stateControl={{
            activeTab: activeTab,
            setActiveTabHandler(name: string) {
              setActiveTab(name);
            },
          }}
          control={"state"}
          tabs={tabOptions}
        />
      ),
    },
  };
  return (
    <div>
      <FormWrapper
        header={{
          title: { name: "" },
          rightChildren: canAddInvoice && (
            <div className={"flex items-center gap-2"}>
              <MyMoreOptionButton
                type={"button"}
                iconType={IconTypeEnum.ADD}
                options={[
                  {
                    name: "Sales Invoice",
                    handler() {
                      navigateWithReturnUrlHandler(
                        PageLinks.invoice.new(InvoiceStatusEnum.SALES),
                        `${QueryStringEnum.USER_ID}=${details?.tenancy?.tenant?._id}`
                      );
                    },
                  },
                  {
                    name: "Sales Return",
                    handler() {
                      navigateWithReturnUrlHandler(
                        PageLinks.invoice.new(InvoiceStatusEnum.SALES_RETURN),
                        `${QueryStringEnum.USER_ID}=${details?.tenancy?.tenant?._id}`
                      );
                    },
                  },
                ]}
                name={"Add Sales"}
              />
              <MyMoreOptionButton
                iconType={IconTypeEnum.ADD}
                isOutline
                type={"button"}
                options={[
                  {
                    name: "Add Purchase",
                    handler() {
                      navigateWithReturnUrlHandler(
                        PageLinks.invoice.new(InvoiceStatusEnum.PURCHASE),
                        `${QueryStringEnum.USER_ID}=${details?.tenancy?.tenant?._id}`
                      );
                    },
                  },
                  {
                    name: "Purchase Return",
                    handler() {
                      navigateWithReturnUrlHandler(
                        PageLinks.invoice.new(
                          InvoiceStatusEnum.PURCHASE_RETURN
                        ),
                        `${QueryStringEnum.USER_ID}=${details?.tenancy?.tenant?._id}`
                      );
                    },
                  },
                ]}
                name={"Add Purchase"}
              />
            </div>
          ),
        }}
      >
        <TableCardComponent
          havePagination={{
            totalDocs: isArchive ? totalArchiveDocs : totalDocs,
          }}
          {...tableCardProps}
        />
      </FormWrapper>
    </div>
  );
}

export default InvoiceTab;
